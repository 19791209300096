import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import DatePickerWrapper from 'theme/atoms/datePickerWrapper';
import styles from './ExpirationDate.module.scss';

interface ExpirationDateProps {
  error: string;
  onChange: () => void;
}

const ExpirationDate = ({ error, onChange }: ExpirationDateProps): JSX.Element => {
  const { consignmentFormStore } = useStores();
  const { consignmentForm, setExpireAt } = consignmentFormStore;

  const handleExpireAtChange = (date: string): void => {
    onChange();
    setExpireAt(date);
  };

  return (
    <div className={styles.root}>
      <DatePickerWrapper
        value={consignmentForm.expireAt ? new Date(consignmentForm.expireAt) : ''}
        onChange={handleExpireAtChange}
        label="Contract expiration date"
        isLabelAbove
        placeholder="Select date"
        error={error}
        minDate={new Date()}
      />
    </div>
  );
};

export default observer(ExpirationDate);
