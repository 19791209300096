import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { routes } from 'app/routes/paths.const';
import { DEFAULT_ARTWORK_DRAFT_CATEGORY } from 'artworks/artworkRegistration/constants';
import { DEFAULT_BANK_INVOICES_CATEGORY } from 'bankPerspective/bankInvoices/constants';
import { DEFAULT_BANK_TRANSACTIONS_CATEGORY } from 'bankPerspective/bankTransactions/constants';
import { DEFAULT_CONSIGNMENT_CATEGORY } from 'consignments/constants';
import { DEFAULT_NETWORK_CATEGORY } from 'network/networkList/constants';
import UserCard from 'theme/atoms/userCard';
import { DEFAULT_INVOICE_CATEGORY } from 'transactions/invoices/constants';
import { DEFAULT_TRANSACTION_CATEGORY } from 'transactions/transactions/types';
import { useUserContext } from 'utils/hooks';
import Dropup from '../theme/atoms/Dropup/Dropup';
import styles from './Sidebar.module.scss';
import { ReactComponent as ConsignmentsIcon } from './assets/agreements.svg';
import { ReactComponent as CatalogIcon } from './assets/catalog.svg';
// import { ReactComponent as MessagesIcon } from './assets/messages.svg';
import { ReactComponent as NetworkIcon } from './assets/network.svg';
// import { ReactComponent as NotificationsIcon } from './assets/notifications.svg';
import { ReactComponent as SettingsIcon } from './assets/settings.svg';
import { ReactComponent as TransactionsIcon } from './assets/transactions.svg';
import SidebarItem from './components/SidebarItem/SidebarItem';
import { SidebarItemType } from './types';

const getCommonSidebarLinks = (isBank: boolean): SidebarItemType[][] => [
  [
    {
      icon: <SettingsIcon />,
      name: 'Settings',
      number: 0,
      matchingRoutes: ['/settings'],
      goTo: isBank ? routes.SettingsSecurity.path() : routes.SettingsMyAccount.path(),
    },
  ],
];

const getIndividualSidebarLinks = (): SidebarItemType[][] => [
  [
    {
      icon: <CatalogIcon />,
      name: 'Catalog',
      number: 0,
      goTo: routes.Artworks.path(),
      matchingRoutes: ['/artworks'],
      subcategories: [
        {
          name: 'Artwork registration',
          goTo: routes.ArtworkRegistration.path(DEFAULT_ARTWORK_DRAFT_CATEGORY),
          matchingRoutes: ['/artwork-registration'],
        },
      ],
    },
    {
      icon: <ConsignmentsIcon />,
      name: 'Consignments',
      number: 0,
      goTo: routes.Consignments.path(DEFAULT_CONSIGNMENT_CATEGORY),
      matchingRoutes: ['/consignments'],
    },
    {
      icon: <TransactionsIcon />,
      name: 'Transactions',
      number: 0,
      goTo: routes.Transactions.path(DEFAULT_TRANSACTION_CATEGORY),
      matchingRoutes: ['/transactions'],
      subcategories: [
        {
          name: 'Invoices',
          goTo: routes.Invoices.path(DEFAULT_INVOICE_CATEGORY),
          matchingRoutes: ['/invoices'],
        },
      ],
    },
    {
      icon: <NetworkIcon />,
      name: 'Network',
      number: 0,
      goTo: routes.Network.path(DEFAULT_NETWORK_CATEGORY),
      matchingRoutes: ['/network'],
    },
    // ],
    // [
    //   {
    //     icon: <MessagesIcon />,
    //     name: 'Messages',
    //     number: 2,
    //     goTo: HOME_PATH,
    //   },
    //   {
    //     icon: <NotificationsIcon />,
    //     name: 'Notifications',
    //     goTo: HOME_PATH,
    //   },
  ],
];

const getBankSidebarLinks = (): SidebarItemType[][] => [
  [
    {
      icon: <TransactionsIcon />,
      name: 'Transactions',
      number: 0,
      goTo: routes.BankTransactions.path(DEFAULT_BANK_TRANSACTIONS_CATEGORY),
      matchingRoutes: ['/bank-transactions'],
    },
    {
      icon: <ConsignmentsIcon />,
      name: 'Invoices',
      number: 0,
      goTo: routes.BankInvoices.path(DEFAULT_BANK_INVOICES_CATEGORY),
      matchingRoutes: ['/bank-invoices'],
    },
  ],
];

const Sidebar = (): JSX.Element => {
  const onlyOneRole = true;
  const { userDetails, organizationSituation, homePath, organizations } = useUserContext();
  const [selectedCategory, setSelectedCategory] = useState('');
  const location = useLocation();

  const specificLinks = [
    ...(organizationSituation.organizationIsNotVerified
      ? []
      : organizationSituation.organizationIsBank
      ? getBankSidebarLinks()
      : getIndividualSidebarLinks()),
  ];

  const sidebarLinks = [...specificLinks, ...getCommonSidebarLinks(organizationSituation.organizationIsBank)];

  const isActiveSidebarItem = (matchingRoutes: string[]): boolean => {
    return matchingRoutes.some((route) => location.pathname.startsWith(route));
  };

  useEffect(() => {
    sidebarLinks.forEach((sidebarLinksGroup) => {
      sidebarLinksGroup.forEach((category) => {
        if (isActiveSidebarItem(category.matchingRoutes)) setSelectedCategory(category.name);

        category.subcategories?.forEach((subcategory) => {
          if (isActiveSidebarItem(subcategory.matchingRoutes)) setSelectedCategory(category.name);
        });
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const { id, firstname, lastname } = userDetails || { id: '', firstname: '', lastname: '' };

  const userDetailsRender = (
    // TODO add profile image support when ready
    <UserCard id={id} isMedium>
      <UserCard.NameAndTitle
        name={`${firstname} ${lastname}`}
        title={userDetails?.roles?.join(', ') || ''}
        numberOfLinesInName={4}
      />
    </UserCard>
  );

  const dropupRender = onlyOneRole ? (
    userDetailsRender
  ) : (
    <Dropup
      buttonContent={userDetailsRender}
      items={[
        {
          id: '1',
          label: 'aaa',
          goTo: homePath,
        },
        { id: '2', label: 'bbb', function: () => console.log('bbb') },
      ]}
    />
  );

  return (
    <div className={styles.root}>
      <div>
        {/* <img
            className={styles.pic1}
            src="https://marcinbiodrowski.com/wp-content/uploads/2018/03/dlaczego-warto-miec-dobre-zdjecie-w-CV.jpg"
            alt=""
          /> */}
        {/* TODO remove placeholder and use image above when it is ready */}
        <div className={styles.imagePlaceholder} />
        <div className={styles.items}>
          {organizations &&
            sidebarLinks.map((sidebarLinksGroup, i) => {
              return (
                <div key={i}>
                  {sidebarLinksGroup.map((category) => (
                    <SidebarItem
                      key={category.name}
                      icon={category.icon}
                      name={category.name}
                      goTo={category.goTo}
                      onClick={() => setSelectedCategory(category.name)}
                      number={category.number}
                      subcategories={category.subcategories}
                      isSelected={selectedCategory === category.name}
                    />
                  ))}
                  {i !== sidebarLinks.length - 1 && <div className={styles.line}></div>}
                </div>
              );
            })}
        </div>
      </div>
      {userDetails ? <div className={styles.userSection}>{dropupRender}</div> : null}
    </div>
  );
};

export default observer(Sidebar);
