import { ContactConnectionId } from 'domain/types/Contact.types';
import { TransactionStatus } from 'domain/types/Transactions.types';
import { TransactionWrapper } from './requests.types';

export enum TransactionRole {
  SellerRepresentative = 'sellerRepresentative',
  BuyerRepresentative = 'buyerRepresentative',
  SellerOwner = 'sellerOwner',
  BuyerOwner = 'buyerOwner',
  None = 'none',
}

export const transactionToRoles = (transactionWrapper: TransactionWrapper): TransactionRole[] => {
  const { transaction, artwork } = transactionWrapper;
  const userIsOwner = artwork?.isOwner;
  const userIsSellerRepresentative = Boolean(
    transaction?.sellerInfo && transaction.sellerInfo.representativeContact?.self
  );
  const userIsBuyerRepresentative = transaction?.buyerInfo && transaction.buyerInfo.representativeContact?.self;
  const userIsBuyer =
    transaction?.buyerInfo &&
    transaction.buyerInfo.buyers &&
    transaction.buyerInfo.buyers.find((el) => el.contact.self);

  return [
    ...(userIsOwner ? [TransactionRole.SellerOwner] : []),
    ...(userIsSellerRepresentative ? [TransactionRole.SellerRepresentative] : []),
    ...(userIsBuyerRepresentative ? [TransactionRole.BuyerRepresentative] : []),
    ...(userIsBuyer ? [TransactionRole.BuyerOwner] : []),
  ];
};

export enum TransactionActions {
  Publish = 'publish',
  ApproveAsSellerOwner = 'approve-as-seller-owner',
  ApproveAsBuyer = 'approve-as-buyer',
  RejectAsSellerOwner = 'reject-as-seller-owner',
  RejectAsBuyer = 'reject-as-buyer',
  RejectAsBuyerRepresentative = 'reject-as-buyer-representative',
  RejectAsSellerRepresentative = 'reject-as-seller-representative',
  Cancel = 'cancel',
  SeeSellerSide = 'see-seller-side',
  SeeBuyerSide = 'see-buyer-side',
  EditSellerSide = 'edit-seller-side',
  EditBuyerSide = 'edit-buyer-side',
  SendToExecution = 'send-to-execution',
}

export const transactionStatusesAllowingRejection = [
  TransactionStatus.PendingOwnersAgreement,
  TransactionStatus.SentToBuyerRepresentative,
  TransactionStatus.PendingBuyersAgreement,
];

export const shouldThisOrgSeeTheTransaction = (transactionWrapper: TransactionWrapper): boolean => {
  return transactionToRoles(transactionWrapper).length > 0;
};

export const shouldThisOrgEditTheTransactionNow = (transactionWrapper: TransactionWrapper): boolean => {
  const transactionRole = transactionToRoles(transactionWrapper);
  return (
    (transactionRole.includes(TransactionRole.SellerRepresentative) &&
      transactionWrapper.transaction.status === TransactionStatus.Draft) ||
    (transactionRole.includes(TransactionRole.BuyerRepresentative) &&
      transactionWrapper.transaction.status === TransactionStatus.SentToBuyerRepresentative)
  );
};

export const shouldThisOrgHavePermissionToEditTheTransaction = (transactionWrapper: TransactionWrapper): boolean => {
  const { transaction } = transactionWrapper;
  const transactionRole = transactionToRoles(transactionWrapper);
  const status = transaction.status;

  const isSellerRepresentative = transactionRole.includes(TransactionRole.SellerRepresentative);
  const isBuyerRepresentative = transactionRole.includes(TransactionRole.BuyerRepresentative);
  const isSellerOwner = transactionRole.includes(TransactionRole.SellerOwner);
  const isBuyerOwner = transactionRole.includes(TransactionRole.BuyerOwner);

  if (isSellerRepresentative) {
    return status === TransactionStatus.Draft;
  } else if (isBuyerRepresentative) {
    return status === TransactionStatus.SentToBuyerRepresentative;
  } else if (isSellerOwner) {
    const sellerVotes = transaction.sellerInfo?.ownersVotes || [];
    const sellerHasAgreed = sellerVotes.some((owner) => owner.contact.self && owner.agreement);
    return status === TransactionStatus.PendingOwnersAgreement && !sellerHasAgreed;
  } else if (isBuyerOwner) {
    const buyerVotes = transaction.buyerInfo?.buyersVotes || [];
    const buyerHasAgreed = buyerVotes.some((buyer) => buyer.contact.self && buyer.agreement);
    return status === TransactionStatus.PendingBuyersAgreement && !buyerHasAgreed;
  } else return false;
};

export const getContactsIdsToExcludeBasedOnRole = (transactionWrapper: TransactionWrapper): ContactConnectionId[] => {
  const { transaction, artwork } = transactionWrapper;
  const buyersContactIds =
    transaction.buyerInfo?.buyers?.map((contact) => contact.contact?.contactConnectionId || '') || [];
  const ownersContactIds = artwork.owners.map((contact) => contact.contact?.contactConnectionId || '');
  const sellerRepresentativeContactId = transaction.sellerInfo?.representativeContact?.contactConnectionId || '';
  const buyerRepresentativeContactId = transaction.buyerInfo?.representativeContact?.contactConnectionId || '';
  const commissionReceiversOnBuyerSide =
    transaction.buyerInfo?.commissionReceivers?.map((contact) => contact.contact?.contactConnectionId || '') || [];
  const commissionReceiversOnSellerSide =
    transaction.sellerInfo?.commissionReceivers?.map((contact) => contact.contact?.contactConnectionId || '') || [];

  const transactionRoles = transactionToRoles(transactionWrapper);
  const isSellerRepresentative = transactionRoles.includes(TransactionRole.SellerRepresentative);
  const isBuyerRepresentative = transactionRoles.includes(TransactionRole.BuyerRepresentative);

  const contactsToExclude = [
    ...(isBuyerRepresentative
      ? [...buyersContactIds, ...ownersContactIds, sellerRepresentativeContactId, ...commissionReceiversOnBuyerSide]
      : []),
    ...(isSellerRepresentative
      ? [...ownersContactIds, buyerRepresentativeContactId, ...commissionReceiversOnSellerSide]
      : []),
  ];

  return contactsToExclude;
};

export const transactionRoleToActions = (role: TransactionRole): TransactionActions[] => {
  return [
    ...(role === TransactionRole.SellerRepresentative
      ? [
          TransactionActions.EditSellerSide,
          TransactionActions.SeeSellerSide,
          TransactionActions.Cancel,
          TransactionActions.Publish,
          TransactionActions.RejectAsSellerRepresentative,
        ]
      : []),
    ...(role === TransactionRole.BuyerRepresentative
      ? [
          TransactionActions.EditBuyerSide,
          TransactionActions.RejectAsBuyerRepresentative,
          TransactionActions.SeeBuyerSide,
        ]
      : []),
    ...(role === TransactionRole.SellerOwner
      ? [
          TransactionActions.ApproveAsSellerOwner,
          TransactionActions.RejectAsSellerOwner,
          TransactionActions.SeeSellerSide,
        ]
      : []),
    ...(role === TransactionRole.BuyerOwner
      ? [TransactionActions.ApproveAsBuyer, TransactionActions.RejectAsBuyer, TransactionActions.SeeBuyerSide]
      : []),
  ];
};

export enum TransactionDirection {
  Sale = 'Sale',
  Purchase = 'Purch.',
}
