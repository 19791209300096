import styles from './FormError.module.scss';

const FORM_ERROR_MESSAGE = 'There are error(s) on the form, please fix the error(s) before submitting.';

interface FormErrorProps {
  showError: boolean;
}

const FormError = ({ showError }: FormErrorProps): JSX.Element => {
  if (!showError) return <></>;
  return <span className={styles.root}>{FORM_ERROR_MESSAGE}</span>;
};

export default FormError;
