import { FormEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import styles from 'auth/Auth.module.scss';
import { EMAIL_REGEX } from 'domain/regex';
import { FormValidation } from 'domain/types';
import { Button, Input, TitleBlack } from 'theme/atoms';
import Breadcrumbs, { Breadcrumb } from 'theme/atoms/Breadcrumbs';
import Topbar from 'theme/atoms/Topbar';
import { useFormValidation, useTitle } from 'utils/hooks';

const FORM_VALIDATIONS: FormValidation = {
  emailRequired: '',
  emailInvalid: '',
  emailSameAsBefore: '',
};

const BREADCRUMBS: Breadcrumb[] = [
  {
    label: 'Change email',
  },
];

function SignupChangeEmail(): JSX.Element {
  useTitle('Sign up - change email');

  const navigate = useNavigate();

  const { createUserStore } = useStores();
  const { createUser, submitting, userRegistrationData } = createUserStore;

  const [email, setEmail] = useState('');

  useEffect(() => {
    if (!userRegistrationData?.password) {
      navigate(routes.Login.path());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { cleanValidationErrors, formValidationMessages, handleValidation, validation } =
    useFormValidation(FORM_VALIDATIONS);

  const handleEmailChange = (value: string): void => {
    cleanValidationErrors(['emailRequired', 'emailInvalid', 'emailSameAsBefore']);
    setEmail(value);
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>): void | undefined => {
    event.preventDefault();
    handleValidation('emailRequired', formValidationMessages.EMAIL_REQUIRED, !email);
    handleValidation('emailInvalid', formValidationMessages.EMAIL_INVALID, !!email && !EMAIL_REGEX.test(email));
    handleValidation(
      'emailSameAsBefore',
      formValidationMessages.EMAIL_SAME_AS_BEFORE,
      !!email && email === userRegistrationData?.email
    );

    if (!email || !EMAIL_REGEX.test(email) || email === userRegistrationData?.email) return;

    createUser(email).then((isSuccessfull) => isSuccessfull && navigate(routes.SignupEmailChanged.path()));
  };

  return (
    <div className={clsx(styles.root, styles.oneColumnLayout)}>
      <Topbar>
        <Breadcrumbs breadcrumbs={BREADCRUMBS} />
      </Topbar>
      <div className={styles.innerWrapper}>
        <TitleBlack text="Change email address" />
        <form onSubmit={onSubmit} className={clsx('form', styles.form)}>
          <Input
            label="Email"
            name="email"
            onChange={(e) => handleEmailChange(e.target.value)}
            placeholder="Enter new email address"
            value={email}
            error={validation.emailRequired || validation.emailInvalid || validation.emailSameAsBefore}
          />
          <Button maxWidth text="Continue" loading={submitting} type="submit" />
        </form>
      </div>
    </div>
  );
}

export default observer(SignupChangeEmail);
