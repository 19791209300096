import { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { AssetWithKey } from 'domain/types/Asset.types';
import { ReactComponent as AssetIcon } from 'theme/assets/svg/file.svg';
import { Button, Spinner } from 'theme/atoms';
import SectionHeader from 'theme/atoms/Titles/SectionHeader';
import { icons } from 'theme/atoms/iconComponent';
import IconComponent from 'theme/atoms/iconComponent/IconComponent';
import useAssetUpload from 'theme/cells/assetUpload';
import { EXTENSIONS_DOCUMENTS, EXTENSIONS_IMAGES } from 'theme/cells/assetUpload/extensions';
import { getMB } from 'theme/cells/assetUpload/utils';
import { downloadAs, formatDate } from 'utils/helpers';
import styles from './AssetsUpload.module.scss';

interface AssetsUploadProps {
  assetsFromParent: AssetWithKey[];
  onUpdateAssets?: (assets: AssetWithKey[]) => void;
  instruction?: string;
  title?: string;
  onClickAdd?: () => void;
  validationError?: JSX.Element;
  assetsType?: 'document' | 'image';
  showAsList?: boolean;
}

function AssetsUpload({
  assetsFromParent,
  onUpdateAssets,
  onClickAdd,
  instruction,
  title,
  validationError,
  assetsType,
  showAsList = false,
}: AssetsUploadProps): JSX.Element {
  const [assets, setAssets] = useState<AssetWithKey[]>(assetsFromParent);
  const sectionRef = useRef<HTMLDivElement | null>(null);
  const [isUploadingInThisSection, setIsUploadingInThisSection] = useState(false);

  useEffect(() => {
    setAssets(assetsFromParent);
  }, [assetsFromParent]);

  const updateAssets = (assets: AssetWithKey[]): void => {
    setAssets(assets);
    onUpdateAssets && onUpdateAssets(assets);
  };

  const handleAssetsUploadSuccess = (addedAssets: AssetWithKey | AssetWithKey[]): void => {
    updateAssets([...assets, ...(addedAssets as AssetWithKey[])]);
  };

  const handleAssetUploadDelete = (asset: AssetWithKey): void => {
    updateAssets(assets.filter((el) => el.key !== asset.key));
  };

  const handleClickAddButton = (event): void => {
    if (sectionRef.current && sectionRef.current.contains(event.target as Node)) setIsUploadingInThisSection(true);
    triggerInput();
    onClickAdd && onClickAdd();
  };

  const { isLoading, triggerInput, InputElement, assetsToDisplay, removeElement } = useAssetUpload({
    onUploadSuccess: handleAssetsUploadSuccess,
    onDeleteSuccess: handleAssetUploadDelete,
    assets: assets,
    extensions: assetsType === 'document' ? EXTENSIONS_DOCUMENTS : EXTENSIONS_IMAGES,
  });

  useEffect(() => {
    if (!isLoading) setIsUploadingInThisSection(false);
  }, [isLoading]);

  const loader = (): JSX.Element => {
    return isLoading && isUploadingInThisSection ? (
      <div className={styles.spinnerWrapper}>
        <Spinner small />
      </div>
    ) : (
      <></>
    );
  };

  const image = (asset: AssetWithKey): JSX.Element => (
    <div key={asset.key} className={styles.imageWrapper}>
      <img src={asset.presignedUrl} alt="artwork" className={styles.image} />
      {removeElement(asset) && <div className={styles.remove}>{removeElement(asset)}</div>}
    </div>
  );

  const document = (asset: AssetWithKey): JSX.Element => {
    return (
      <div key={asset.key} className={styles.asset}>
        <button
          className={clsx(styles.assetDetails, styles.left)}
          onClick={() => downloadAs(asset.presignedUrl, asset.name)}
        >
          <AssetIcon />
          <span>{asset.name}</span>
        </button>
        <div className={clsx(styles.assetDetails, styles.right)}>
          <span>{formatDate(asset.lastModified)}</span>
          <span>{getMB(asset.size).toFixed(2)} MB</span>
          {removeElement(asset) && <div className={styles.remove}>{removeElement(asset)}</div>}
        </div>
      </div>
    );
  };

  const assetsList = (
    <div
      className={clsx(styles.assetsList, {
        [styles.hideList]: isLoading && isUploadingInThisSection,
      })}
    >
      {!(assetsToDisplay as AssetWithKey[])?.length ? (
        <div className={styles.instruction}>
          {instruction}
          {validationError}
        </div>
      ) : (
        (assetsToDisplay as AssetWithKey[]).map((asset) => {
          return assetsType === 'document' || showAsList ? document(asset) : image(asset);
        })
      )}
    </div>
  );

  return (
    <>
      <div className={styles.section} ref={sectionRef}>
        {title && <SectionHeader title={title} />}
        {!isLoading && (
          <Button
            text="Add"
            iconStart={<IconComponent icon={icons.add} />}
            buttonType="tertiary"
            onClick={(e) => handleClickAddButton(e)}
          />
        )}
      </div>
      <div className={styles.content}>
        {loader()}
        {assetsList}
      </div>
      {InputElement}
    </>
  );
}

export default observer(AssetsUpload);
