import { useState, FormEvent, useMemo } from 'react';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
// import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { useStores } from 'RootStore';
import styles from 'auth/Auth.module.scss';
import { FormValidation } from 'domain/types';
import { Button, TitleBlack, Subtitle } from 'theme/atoms';
import Breadcrumbs, { Breadcrumb } from 'theme/atoms/Breadcrumbs';
import Topbar from 'theme/atoms/Topbar';
import { useFormValidation, useTitle } from 'utils/hooks';

const FORM_VALIDATIONS: FormValidation = {
  phoneRequired: '',
  phoneInvalid: '',
};

const preferredCountries = ['gb', 'fr', 'de', 'be', 'nl', 'lu', 'us', 'ca', 'au'];
const minimalNumberOfDigitsInPhoneNumber = 5;
const BREADCRUMBS: Breadcrumb[] = [
  {
    label: 'Sign up',
    route: 'SignupCredentials',
  },
  { label: 'Add phone number' },
];

function SignupAddPhoneNumber(): JSX.Element {
  // const navigate = useNavigate();
  const { authStore } = useStores();
  const { submitting } = authStore;

  const [rawPhone, setRawPhone] = useState('');
  // const [formattedPhone, setFormattedPhone] = useState('');
  const [phoneFormat, setPhoneFormat] = useState(''); // eg. '+. (...) ...-....'

  const phoneFormatError = useMemo(() => {
    return phoneFormat
      ? rawPhone.replace(/[^0-9]/g, '').length !== phoneFormat.match(/\./g)?.length // checking if the number of phone digits is equal to number of dots from phoneFormat as they represent phone digits
      : rawPhone.length < minimalNumberOfDigitsInPhoneNumber;
  }, [phoneFormat, rawPhone]);

  useTitle('Sign up - verify phone number');

  const { cleanValidationErrors, formValidationMessages, handleValidation, validation } =
    useFormValidation(FORM_VALIDATIONS);

  const validationError = useMemo(() => {
    return validation.phoneRequired || validation.phoneInvalid;
  }, [validation.phoneRequired, validation.phoneInvalid]);

  const handlePhoneChange = (unformattedPh: string, formattedPh: string, phFormat: CountryData): void => {
    cleanValidationErrors(['phoneRequired', 'phoneInvalid']);
    setRawPhone(unformattedPh);
    // setFormattedPhone(formattedPh);
    setPhoneFormat(phFormat.format);
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>): void | undefined => {
    event.preventDefault();
    handleValidation('phoneRequired', formValidationMessages.PHONE_REQUIRED, !rawPhone);
    handleValidation('phoneInvalid', formValidationMessages.PHONE_INVALID, phoneFormatError);

    if (!rawPhone || phoneFormatError) return;

    // setUserPhone(rawPhone, formattedPhone);
    // sendSecurityCode(rawPhone).then(() => navigate(routes.SignupVerifyPhoneNumber.path()));
  };

  return (
    <div className={clsx(styles.root, styles.oneColumnLayout)}>
      <Topbar>
        <Breadcrumbs breadcrumbs={BREADCRUMBS} />
      </Topbar>
      <div className={styles.innerWrapper}>
        <TitleBlack text="What is your phone number?" />
        <Subtitle text="We will send you a SMS message to verify your phone number" />

        <form onSubmit={onSubmit} className="form">
          <div className={styles.phoneFormWrapper}>
            <label className={styles.label}>Phone</label>
            <PhoneInput
              buttonClass={styles.button}
              inputClass={styles.phoneInput}
              containerClass={clsx(styles.phoneWrapper, validationError && styles.phoneWrapperWithError)}
              placeholder="Enter phone number"
              inputProps={{ required: true, autoFocus: true }}
              onChange={(value, country, e, formattedValue) => {
                handlePhoneChange(value, formattedValue, country as CountryData);
              }}
              value={rawPhone}
              preferredCountries={preferredCountries}
              preserveOrder={['preferredCountries']}
            />
            {validationError && <span className={styles.phoneError}>{validationError}</span>}
          </div>
          <Button text="Continue" loading={submitting} type="submit" maxWidth />
        </form>
      </div>
    </div>
  );
}

export default observer(SignupAddPhoneNumber);
