import Big from 'big.js';

export const USER_LANGUAGE = navigator.language;

type ReturnAsParts<T extends boolean> = T extends false ? string : Intl.NumberFormatPart[];

export const formatPrice = <T extends boolean = false>(
  price: Big,
  currency: string,
  maximumFractionDigits = 0,
  returnAsParts?: T
): ReturnAsParts<T> => {
  Big.PE = 140;
  const priceFormatter = new Intl.NumberFormat(USER_LANGUAGE, { style: 'currency', currency, maximumFractionDigits });
  const priceAsNumber = Big(price).toNumber();

  return returnAsParts
    ? (priceFormatter.formatToParts(priceAsNumber) as ReturnAsParts<T>)
    : (priceFormatter.format(priceAsNumber) as ReturnAsParts<T>);
};

export const formatDate = (date: Date | string | number): string => {
  return new Intl.DateTimeFormat(USER_LANGUAGE).format(new Date(date));
};
