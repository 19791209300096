import { FormEvent, useState } from 'react';
import { useStores } from 'RootStore';
import { EMAIL_REGEX } from 'domain/regex';
import { FormValidation } from 'domain/types';
import { ReactComponent as HiddenIcon } from 'theme/assets/svg/hidden1.svg';
import { ReactComponent as ShowIcon } from 'theme/assets/svg/shown1.svg';
import { Input, Button } from 'theme/atoms';
import { useFormValidation } from 'utils/hooks';
import styles from './UserVerificationForm.module.scss';

const FORM_VALIDATIONS: FormValidation = {
  emailRequired: '',
  emailInvalid: '',
  passwordRequired: '',
};

interface UserVerificationFormProps {
  onSubmit: (trimmedEmail: string, password: string) => void;
}

const UserVerificationForm = ({ onSubmit }: UserVerificationFormProps): JSX.Element => {
  const { authStore } = useStores();
  const { submitting } = authStore;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const { cleanValidationErrors, formValidationMessages, handleValidation, validation } =
    useFormValidation(FORM_VALIDATIONS);

  const handleEmailChange = (value: string): void => {
    cleanValidationErrors(['emailRequired', 'emailInvalid']);
    setEmail(value);
  };

  const handlePasswordChange = (value: string): void => {
    cleanValidationErrors(['passwordRequired']);
    setPassword(value);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void | undefined => {
    const trimmedEmail = email.trim();
    event.preventDefault();
    handleValidation('emailRequired', formValidationMessages.EMAIL_REQUIRED, !email);
    handleValidation('emailInvalid', formValidationMessages.EMAIL_INVALID, !!email && !EMAIL_REGEX.test(trimmedEmail));
    handleValidation('passwordRequired', formValidationMessages.PASSWORD_REQUIRED, !password);

    if (!email || !EMAIL_REGEX.test(trimmedEmail) || !password) return;

    onSubmit(trimmedEmail, password);
  };

  return (
    <form onSubmit={handleSubmit} className="form">
      <Input
        label="Email"
        name="email"
        onChange={(e) => handleEmailChange(e.target.value)}
        placeholder="Enter your email"
        value={email}
        error={validation.emailRequired || validation.emailInvalid}
      />
      <Input
        label="Password"
        name="password"
        placeholder="Enter your password"
        value={password}
        onChange={(e) => handlePasswordChange(e.target.value)}
        error={validation.passwordRequired}
        type={isPasswordVisible ? 'text' : 'password'}
        endIcon={
          <div className={styles.passwordIcon} onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
            {isPasswordVisible ? <ShowIcon /> : <HiddenIcon />}
          </div>
        }
      />
      <Button
        text="Continue"
        loading={submitting}
        type="submit"
        disabled={submitting}
        className={styles.submitButton}
      />
    </form>
  );
};

export default UserVerificationForm;
