import { ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import { FilterItemType, SortAndFilterStoresWithPanel } from 'domain/SortAndFilterBasicStore/types';
import Checkbox from 'theme/atoms/Checkbox';
import NoDataToDisplay from 'theme/atoms/noDataToDisplay';
import styles from './FilterChecklist.module.scss';

type ListItemWithElement = { value: string | boolean; label: string; element?: ReactNode; count?: number | undefined };
interface FilterChecklistProps {
  name: string;
  listItems: ListItemWithElement[];
  store: SortAndFilterStoresWithPanel;
}

const FilterChecklist = ({ name, listItems, store }: FilterChecklistProps): JSX.Element => {
  const { selectedFilteringItems, updateSelectedFilteringItems, resetActivePage } = store;

  const handleFilterItemChange = (isChecked: boolean, item: FilterItemType): void => {
    const newItems = isChecked
      ? [...selectedFilteringItems, item]
      : selectedFilteringItems.filter((f) => f.value !== item.value);
    updateSelectedFilteringItems(newItems);
    resetActivePage();
  };

  const list = (
    <>
      {listItems.map((item: ListItemWithElement) => {
        return (
          <div
            key={item.label.replaceAll(' ', '') ? item.label : (item.value as string)} // for cases where label is organization name verified by KYC so equals '  '
            className={styles.itemWrapper}
          >
            <Checkbox
              label={item.element || item.label}
              name={item.label}
              checked={Boolean(
                selectedFilteringItems.filter((f) => f.key === name).find((f) => f.value === item.value)
              )}
              onChange={(e) => handleFilterItemChange(e.target.checked, { key: name, value: item.value })}
              labelClassName={styles.checkboxLabel}
            />
            {item.count ? <div className={styles.number}>{item.count}</div> : null}
          </div>
        );
      })}
    </>
  );

  return (
    <div className={styles.root}>{!listItems.length ? <NoDataToDisplay copy="No items to display." /> : list}</div>
  );
};

export default observer(FilterChecklist);
