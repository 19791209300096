import { ReactNode } from 'react';
import {
  AddArtworkAuthor,
  AddArtworkDetails,
  AddArtworkOwnership,
  AddArtworkSummary,
  EditArtwork,
} from 'artworks/addArtwork/steps';
import ApproveArtwork from 'artworks/approveArtwork';
import Artwork from 'artworks/artwork';
import ArtworkRegistration from 'artworks/artworkRegistration';
import Artworks from 'artworks/artworks';
import Login from 'auth/AuthMain';
import AddAuthMethod from 'auth/flows/FirstTimeLogin/AddAuthMethod';
import UnverifiedEmail from 'auth/flows/FirstTimeLogin/UnverifiedEmail';
import LoginAddAuthApp from 'auth/flows/FirstTimeLogin/authApp/AddAuthApp';
import LoginWithAuthApp from 'auth/flows/FirstTimeLogin/authApp/LoginWithAuthApp';
import LoginAddPhone from 'auth/flows/FirstTimeLogin/phone/AddPhone';
import LoginWithPhone from 'auth/flows/FirstTimeLogin/phone/LoginWithPhone';
import LoginVerifyPhone from 'auth/flows/FirstTimeLogin/phone/VerifyPhone';
import LoginAccessRecovery from 'auth/flows/FirstTimeLogin/recoveryCodes/AccessRecovery';
import LoginRecoveryCodes from 'auth/flows/FirstTimeLogin/recoveryCodes/RecoveryCodes';
import LoginWithEmailToken from 'auth/flows/LoginWithEmailToken';
import ResetPasswordGetEmail from 'auth/flows/ResetPassword/1getEmail';
import ResetPasswordEmailSent from 'auth/flows/ResetPassword/2emailSent';
import ResetPasswordSetNewPassword from 'auth/flows/ResetPassword/3setNewPassword';
import ResetPasswordPasswordChanged from 'auth/flows/ResetPassword/4passwordChanged';
import SignupCredentials from 'auth/flows/Signup/1credentials';
import SignupAddPhoneNumber from 'auth/flows/Signup/2addPhoneNumber';
import SignupVerifyPhoneNumber from 'auth/flows/Signup/3verifyPhoneNumber';
import SignupSuccessful from 'auth/flows/Signup/5signupSuccessful';
import SignupVerifyEmailIssues from 'auth/flows/Signup/6verifyEmailIssues';
import SignupChangeEmail from 'auth/flows/Signup/7changeEmail';
import SignupEmailChanged from 'auth/flows/Signup/8emailChanged';
import BankInvoices from 'bankPerspective/bankInvoices';
import BankInvoice from 'bankPerspective/bankInvoices/components/bankInvoice';
import BankTransactions from 'bankPerspective/bankTransactions';
import BankTransaction from 'bankPerspective/bankTransactions/components/bankTransaction';
import Billing from 'billing';
import Consignment from 'consignments/consignment';
import Consignments from 'consignments/consignments';
import CreateOrganizationWrapper from 'createOrganization/CreateOrganizationWrapper';
import ContactInvitation from 'network/networkContact/contactInvitation';
import ContactProfile from 'network/networkContact/contactProfile';
import NetworkList from 'network/networkList/NetworkList';
import OrganizationProfileShare from 'organization/profile/share';
import SettingsKYC from 'settings/settingsKYC';
import SettingsMyAccount from 'settings/settingsMyAccount';
import SettingsOrganizationProfile from 'settings/settingsOrganizationProfile/SettingsOrganizationProfile';
import SettingsSecurity from 'settings/settingsSecurity';
import SettingsSecurityAuthApp from 'settings/settingsSecurity/components/authApp/AuthApp';
import SettingsSecurityPhone from 'settings/settingsSecurity/components/phone/Phone';
import SettingsSecurityRecoveryCodes from 'settings/settingsSecurity/components/recoveryCodes/RecoveryCodes';
import SettingsSecurityVerification from 'settings/settingsSecurity/components/verification';
import Invoice from 'transactions/invoice';
import Invoices from 'transactions/invoices/Invoices';
import Transaction from 'transactions/transaction';
import { TransactionArtwork } from 'transactions/transaction/transactionArtwork/TransactionArtwork';
import Transactions from 'transactions/transactions';

export const NETWORK_CONTACT_INVITATION_BASE_PATH = '/network/contact-invitation';

export const URL_PARAM = {
  artworkId: ':artworkId',
  categoryId: ':categoryId',
  consignmentId: ':consignmentId',
  contactId: ':contactId',
  invitationId: ':invitationId',
  invoiceId: ':invoiceId',
  transactionId: ':transactionId',
};

type Route = {
  path: (...args: string[]) => string;
  component: ReactNode;
  pageType: 'public' | 'private' | 'publicAndPrivate';
};

type Routes = Record<string, Route>;

export const routes: Routes = {
  Login: {
    path: () => '/auth/login',
    component: <Login />,
    pageType: 'public',
  },
  LoginWithEmailToken: {
    path: () => '/auth/sign-in-by-token',
    component: <LoginWithEmailToken />,
    pageType: 'publicAndPrivate',
  },
  LoginAddAuthMethod: {
    path: () => '/auth/login/add-method',
    component: <AddAuthMethod />,
    pageType: 'publicAndPrivate',
  },
  LoginAddAuthApp: {
    path: () => '/auth/login/add-method/auth-app',
    component: <LoginAddAuthApp />,
    pageType: 'publicAndPrivate',
  },
  LoginAddPhone: {
    path: () => '/auth/login/add-method/phone',
    component: <LoginAddPhone />,
    pageType: 'publicAndPrivate',
  },
  LoginVerifyPhone: {
    path: () => '/auth/login/add-method/phone/verify',
    component: <LoginVerifyPhone />,
    pageType: 'publicAndPrivate',
  },
  LoginWithAuthApp: {
    path: () => '/auth/login/auth-app',
    component: <LoginWithAuthApp />,
    pageType: 'publicAndPrivate',
  },
  LoginWithPhone: {
    path: () => '/auth/login/phone',
    component: <LoginWithPhone />,
    pageType: 'publicAndPrivate',
  },
  LoginRecoveryCodes: {
    path: () => '/auth/login/recovery-codes',
    component: <LoginRecoveryCodes />,
    pageType: 'publicAndPrivate',
  },
  LoginAccessRecovery: {
    path: () => '/auth/login/access-recovery',
    component: <LoginAccessRecovery />,
    pageType: 'publicAndPrivate',
  },
  UnverifiedEmail: {
    path: () => '/auth/initial-login/unverified-email',
    component: <UnverifiedEmail />,
    pageType: 'public',
  },
  SignupCredentials: {
    path: () => '/auth/signup/credentials',
    component: <SignupCredentials />,
    pageType: 'public',
  },
  SignupAddPhoneNumber: {
    path: () => '/auth/signup/add-phone-number',
    component: <SignupAddPhoneNumber />,
    pageType: 'public',
  },
  SignupVerifyPhoneNumber: {
    path: () => '/auth/signup/verify-phone-number',
    component: <SignupVerifyPhoneNumber />,
    pageType: 'public',
  },
  SignupSuccessful: {
    path: () => '/auth/signup/successful',
    component: <SignupSuccessful />,
    pageType: 'public',
  },
  SignupVerifyEmailIssues: {
    path: () => '/auth/signup/verify-email-issues',
    component: <SignupVerifyEmailIssues />,
    pageType: 'public',
  },
  SignupChangeEmail: {
    path: () => '/auth/signup/change-email',
    component: <SignupChangeEmail />,
    pageType: 'public',
  },
  SignupEmailChanged: {
    path: () => '/auth/signup/email-changed',
    component: <SignupEmailChanged />,
    pageType: 'public',
  },
  ResetPasswordGetEmail: {
    path: () => '/auth/reset-password/get-email',
    component: <ResetPasswordGetEmail />,
    pageType: 'publicAndPrivate',
  },
  ResetPasswordEmailSent: {
    path: () => '/auth/reset-password/email-sent',
    component: <ResetPasswordEmailSent />,
    pageType: 'publicAndPrivate',
  },
  ResetPasswordSetNewPassword: {
    path: () => '/reset-password/set-new-password',
    component: <ResetPasswordSetNewPassword />,
    pageType: 'publicAndPrivate',
  },
  ResetPasswordPasswordChanged: {
    path: () => '/auth/reset-password/password-changed',
    component: <ResetPasswordPasswordChanged />,
    pageType: 'publicAndPrivate',
  },
  CreateOrganizationWrapper: {
    path: () => '/create-organization',
    component: <CreateOrganizationWrapper />,
    pageType: 'private',
  },
  Artwork: {
    path: (artworkId) => `/artworks/${artworkId}`,
    component: <Artwork />,
    pageType: 'private',
  },
  AddArtworkAuthor: {
    path: (artworkId) => `/artworks/add-artwork/step-1/${artworkId}`,
    component: <AddArtworkAuthor />,
    pageType: 'private',
  },
  AddArtworkDetails: {
    path: (artworkId) => `/artworks/add-artwork/step-2/${artworkId}`,
    component: <AddArtworkDetails />,
    pageType: 'private',
  },
  AddArtworkOwnership: {
    path: (artworkId) => `/artworks/add-artwork/step-3/${artworkId}`,
    component: <AddArtworkOwnership />,
    pageType: 'private',
  },
  AddArtworkSummary: {
    path: (artworkId) => `/artworks/add-artwork/step-4/${artworkId}`,
    component: <AddArtworkSummary />,
    pageType: 'private',
  },
  EditArtwork: {
    path: (artworkId) => `/artworks/edit-artwork/${artworkId}`,
    component: <EditArtwork />,
    pageType: 'private',
  },
  ArtworkDraft: {
    path: (artworkId) => `/artworks/artwork-draft/${artworkId}`,
    component: <ApproveArtwork />,
    pageType: 'private',
  },
  Artworks: {
    path: () => '/artworks/search-results',
    component: <Artworks />,
    pageType: 'private',
  },
  ArtworkRegistration: {
    path: (categoryId) => `/artwork-registration/${categoryId}`,
    component: <ArtworkRegistration />,
    pageType: 'private',
  },
  Billing: {
    path: () => '/wallet',
    component: <Billing />,
    pageType: 'private',
  },
  Consignments: {
    path: (categoryId) => `/consignments/lists/${categoryId}`,
    component: <Consignments />,
    pageType: 'private',
  },
  Consignment: {
    path: (consignmentId) => `/consignments/${consignmentId}`,
    component: <Consignment />,
    pageType: 'private',
  },
  Transactions: {
    path: (categoryId) => `/transactions/lists/${categoryId}`,
    component: <Transactions />,
    pageType: 'private',
  },
  Transaction: {
    path: (transactionId, consignmentId, artworkId) => {
      if (consignmentId) {
        return `/transactions/${transactionId}?consignmentId=${consignmentId}`;
      } else if (artworkId) {
        return `/transactions/${transactionId}?artworkId=${artworkId}`;
      } else {
        return `/transactions/${transactionId}`;
      }
    },
    component: <Transaction />,
    pageType: 'private',
  },
  TransactionArtwork: {
    path: (transactionId) => `/transactions/${transactionId}/artwork-details`,
    component: <TransactionArtwork />,
    pageType: 'private',
  },
  Invoices: {
    path: (categoryId) => `/invoices/lists/${categoryId}`,
    component: <Invoices />,
    pageType: 'private',
  },
  Invoice: {
    path: (invoiceId) => `/invoices/${invoiceId}`,
    component: <Invoice />,
    pageType: 'private',
  },
  Network: {
    path: (categoryId) => `/network/lists/${categoryId}`,
    component: <NetworkList />,
    pageType: 'private',
  },
  ContactInvitation: {
    path: (invitationId) => `${NETWORK_CONTACT_INVITATION_BASE_PATH}/${invitationId}`,
    component: <ContactInvitation />,
    pageType: 'private',
  },
  ContactProfile: {
    path: (contactId) => `/network/user-profile/${contactId}`,
    component: <ContactProfile />,
    pageType: 'private',
  },
  SettingsKYC: {
    path: () => '/settings/kyc',
    component: <SettingsKYC />,
    pageType: 'private',
  },
  SettingsSecurity: {
    path: () => '/settings/security',
    component: <SettingsSecurity />,
    pageType: 'private',
  },
  SettingsSecurityVerification: {
    path: () => '/settings/security/verification',
    component: <SettingsSecurityVerification />,
    pageType: 'private',
  },
  SettingsSecurityAuthApp: {
    path: () => '/settings/security/auth-app',
    component: <SettingsSecurityAuthApp />,
    pageType: 'private',
  },
  SettingsSecurityPhone: {
    path: () => '/settings/security/phone',
    component: <SettingsSecurityPhone />,
    pageType: 'private',
  },
  SettingsSecurityRecoveryCodes: {
    path: () => '/settings/security/recovery-codes',
    component: <SettingsSecurityRecoveryCodes />,
    pageType: 'private',
  },
  SettingsMyAccount: {
    path: () => '/settings/my-account',
    component: <SettingsMyAccount />,
    pageType: 'private',
  },
  SettingsOrganizationProfile: {
    path: () => '/settings/organization-profile',
    component: <SettingsOrganizationProfile />,
    pageType: 'private',
  },
  OrganizationProfileShare: {
    path: () => '/organization/profile/share',
    component: <OrganizationProfileShare />,
    pageType: 'private',
  },
  BankInvoices: {
    path: (categoryId) => `/bank-invoices/list/${categoryId}`,
    component: <BankInvoices />,
    pageType: 'private',
  },
  BankInvoice: {
    path: (invoiceId) => `/bank-invoices/invoice-details/${invoiceId}`,
    component: <BankInvoice />,
    pageType: 'private',
  },
  BankTransactions: {
    path: (categoryId) => `/bank-transactions/list/${categoryId}`,
    component: <BankTransactions />,
    pageType: 'private',
  },
  BankTransaction: {
    path: (transactionId) => `/bank-transactions/transaction-details/${transactionId}`,
    component: <BankTransaction />,
    pageType: 'private',
  },
};
