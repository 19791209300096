import clsx from 'clsx';
import styles from './DropIndicator.module.scss';

interface DropIndicatorProps {
  isActive: boolean;
  className?: string;
}

const DropIndicator = ({ isActive, className }: DropIndicatorProps): JSX.Element => {
  return (
    <div className={clsx(styles.root, { [styles.isActive]: isActive }, className)}>
      <div className={styles.leftTip}></div>
      <div className={styles.rightTip}></div>
    </div>
  );
};

export default DropIndicator;
