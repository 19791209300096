import { useRef } from 'react';
import Big from 'big.js';
import { Consignment } from 'consignments/types';
import SectionHeader from 'theme/atoms/Titles/SectionHeader';
import Billing from 'theme/atoms/billing';
import { formatPrice } from 'utils/helpers';
import { formatPriceRange } from 'utils/helpers/formatRangePrice';
import { useElementWidth } from 'utils/hooks';
import styles from './Pricing.module.scss';

interface PricingProps {
  consignment: Consignment;
}

const Pricing = ({ consignment }: PricingProps): JSX.Element => {
  const { askingPrice, maxCommission, currency } = consignment;
  const parentRef = useRef<HTMLDivElement | null>(null);
  const parentWidth = useElementWidth(parentRef);

  const maxCommissionPercent = Big(maxCommission)
    .div(askingPrice || 1)
    .times(100);
  const askingPriceAndCommission = Big(askingPrice).plus(maxCommission);

  const artworkPriceRange = formatPriceRange(askingPrice, askingPriceAndCommission, currency);

  const shouldADisplayInTwoLines =
    (parentWidth <= 510 && artworkPriceRange.length > 30) || (parentWidth <= 470 && artworkPriceRange.length > 20);

  return (
    <>
      <SectionHeader title="Pricing details" className={styles.header} />
      <div className={styles.content} ref={parentRef}>
        <Billing>
          <Billing.Row isDark>
            <Billing.Cell>Asking price:</Billing.Cell>
            <Billing.Cell>{formatPrice(askingPrice, currency)}</Billing.Cell>
          </Billing.Row>

          <Billing.Row isLight>
            <Billing.Cell>Commission:</Billing.Cell>
            <Billing.Cell isSplit>
              <div>{maxCommissionPercent.round(2).toString()}% (max)</div>
              <div>{formatPrice(maxCommission, currency)}</div>
            </Billing.Cell>
          </Billing.Row>

          <Billing.Divider />

          <Billing.Subheader isTwoLines={shouldADisplayInTwoLines}>
            <Billing.Cell>Artwork price range:</Billing.Cell>
            <Billing.Cell>{artworkPriceRange}</Billing.Cell>
          </Billing.Subheader>
        </Billing>
      </div>
    </>
  );
};

export default Pricing;
