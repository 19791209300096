import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import SumsubWebSdk from '@sumsub/websdk-react';
import { useStores } from 'RootStore';
import Big from 'big.js';
import { KycStatus } from 'organization/types';
import { Button } from 'theme/atoms';
import SlidingPanel from 'theme/atoms/slidingPanel';
import { formatPrice } from 'utils/helpers';
import styles from './Websdk.module.scss';

interface WebsdkProps {
  onClose: () => void;
  isOpen: boolean;
  kycStatus: KycStatus;
}

const Websdk = ({ isOpen, onClose, kycStatus }: WebsdkProps): JSX.Element => {
  const { organizationStore, billingStore } = useStores();
  const {
    fetchKycToken,
    kycToken,
    loadingKycToken,
    loadedKycToken,
    fetchOrganizations,
    resetKycStatus,
    resetingKycStatus,
  } = organizationStore;
  const { fetchProducts, products, loadingProducts } = billingStore;

  const [applicantId, setApplicantId] = useState('');
  const [hideSumsub, setHideSumsub] = useState(false);

  const kycPrice = products?.KYC && formatPrice(Big(products.KYC.amount).div(100), products.KYC.currency, 2);

  useEffect(() => {
    !kycToken && fetchKycToken();
    !products && kycStatus === KycStatus.Verified && fetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const accessTokenExpirationHandler = async (): Promise<string | undefined> => {
    const data = await fetchKycToken();
    return data?.token;
  };

  const handleMessage = (type: string, payload): void => {
    console.log(type, payload);
    if (type === 'idCheck.onApplicantSubmitted') {
      setTimeout(() => {
        fetchOrganizations();
        onClose();
      }, 1000);
    } else if (type === 'idCheck.onApplicantLoaded') {
      setApplicantId(payload.applicantId);
    }
  };

  const handleResetKycStatus = (): void => {
    resetKycStatus(applicantId).then(() => {
      setHideSumsub(true);
      setTimeout(() => {
        setHideSumsub(false);
      }, 1000);
    });
  };

  const infoForVerifiedUser = (
    <div className={styles.infoForVerifiedUser}>
      <div className={styles.resetStatusCopy}>
        <div className={clsx({ [styles.hideResetStatusCopyInner]: resetingKycStatus })}>
          A change in data results in the need for a repeat of KYC verification. It will reset your verification status
          and require you to{' '}
          <span className={styles.highlightedText}>go through the KYC verification process again</span>.
          {kycPrice ? (
            <p>It will cost you {kycPrice}.</p>
          ) : (
            <div className={styles.btnWrapper}>
              <Button
                text="Check price"
                onClick={() => fetchProducts(true)}
                loading={loadingProducts}
                buttonType="secondary"
              />
            </div>
          )}
          <p>Are you sure you want to proceed?</p>
        </div>
      </div>
      <div className={styles.btnWrapper}>
        <Button
          text="Reset status and start re-verification"
          onClick={handleResetKycStatus}
          loading={resetingKycStatus}
          buttonType="primary"
        />
      </div>
    </div>
  );

  return (
    <SlidingPanel isOpen={isOpen} onClose={onClose} title="KYC verification" blockClosing>
      <div className={styles.root}>
        {!kycToken && loadedKycToken ? (
          <div className={styles.fetchIdBtnWrapper}>
            <Button
              text="Fetch your verification id"
              onClick={() => fetchKycToken()}
              loading={loadingKycToken}
              buttonType="secondary"
            />
          </div>
        ) : kycToken && !hideSumsub ? (
          <SumsubWebSdk
            accessToken={kycToken.token}
            config={{ theme: 'light' }}
            expirationHandler={accessTokenExpirationHandler}
            onMessage={handleMessage}
          />
        ) : (
          <></>
        )}
        {kycStatus === KycStatus.Verified && kycToken && applicantId && infoForVerifiedUser}
      </div>
    </SlidingPanel>
  );
};

export default observer(Websdk);
