import { useLocation, useNavigate } from 'react-router-dom';
import { routes } from 'app/routes/paths.const';
import IconComponent, { iconSizes, icons } from '../iconComponent/IconComponent';
import styles from './Breadcrumbs.module.scss';

export interface Breadcrumb {
  label: string;
  route?: string;
  param?: string;
}

interface BreadcrumbsProps {
  breadcrumbs?: Breadcrumb[];
}

const Breadcrumbs = ({ breadcrumbs: breadcrumbsFromProps }: BreadcrumbsProps): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const breadcrumbsFromUrlLabels: string[] = location.pathname.split('/').slice(1);

  const breadcrumbs: Breadcrumb[] = breadcrumbsFromProps
    ? breadcrumbsFromProps.map(({ label, route: routeKey, param }) => {
        return { label, route: routeKey && routes[routeKey]?.path(param || '') };
      })
    : breadcrumbsFromUrlLabels.map((label, index) => {
        return {
          label,
          route: `/${breadcrumbsFromUrlLabels.slice(0, index + 1).join('/')}`,
        };
      });

  return (
    <nav className={styles.root}>
      <ol className={styles.breadcrumbsWrapper}>
        {breadcrumbs.map(({ label, route }, index): JSX.Element => {
          const isLastItem = index === breadcrumbs.length - 1;
          return (
            <li key={label + index} className={styles.breadcrumb}>
              {index > 0 && <IconComponent icon={icons.next} className={styles.icon} size={iconSizes.medium} />}
              {route && !isLastItem ? (
                <button className={styles.label} onClick={() => navigate(route)}>
                  {label}
                </button>
              ) : (
                <div className={styles.label}>{label}</div>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};
export default Breadcrumbs;
