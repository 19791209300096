// import { ReactComponent as FavoriteIcon } from 'theme/assets/svg/heart.svg';
// import { ReactComponent as ShareIcon } from 'theme/assets/svg/share.svg';
import ActionButtons, { ActionButton } from 'theme/atoms/ActionButtons';
import Breadcrumbs, { Breadcrumb } from 'theme/atoms/Breadcrumbs';
import Topbar from 'theme/atoms/Topbar';

const BREADCRUMBS: Breadcrumb[] = [{ label: 'Catalog', route: 'Artworks' }, { label: 'Artwork details' }];

const ArtworkTopbar = (): JSX.Element => {
  const actionButtons: ActionButton[] = [
    // {
    //   text: 'Share',
    //   icon: <ShareIcon />,
    //   /* TODO: add action to button */
    //   onClick: () => console.log('Share'),
    // },
    // {
    //   // TODO: edit button only for certificated artworks - draft should redirect in handleRowClick
    //   text: 'Edit',
    //   icon: <EditIcon />,
    //   onClick: () => console.log('Edit'),
    // },
    // {
    //   text: 'Add to favorites',
    //   icon: <FavoriteIcon />,
    //   /* TODO: add action to button */
    //   onClick: () => console.log('Add to favorites'),
    // },
  ];

  return (
    <Topbar>
      <Breadcrumbs breadcrumbs={BREADCRUMBS} />
      <ActionButtons buttons={actionButtons} />
    </Topbar>
  );
};

export default ArtworkTopbar;
