import { FormEvent, ReactNode, useState } from 'react';
import styles from 'auth/Auth.module.scss';
import { AUTH_APP_CODE_LENGTH } from 'auth/constants';
import { formValidationMessages } from 'domain/formValidationMessages';
import { AUTH_APP_CODE_REGEX } from 'domain/regex';
import { Input } from 'theme/atoms';
import { useZodFormValidation } from 'utils/hooks';
import { z } from 'zod';

const AddAuthAppSchema = z.object({
  authAppCode: z
    .string()
    .min(1, { message: formValidationMessages.AUTH_APP_CODE_REQUIRED })
    .regex(AUTH_APP_CODE_REGEX, { message: formValidationMessages.AUTH_APP_CODE_INVALID }),
});

interface AuthAppFormProps {
  onSubmit: (authAppCode: string) => void;
  submitButton: ReactNode;
}

const AuthAppForm = ({ onSubmit, submitButton }: AuthAppFormProps): React.JSX.Element => {
  const [authAppCode, setAuthAppCode] = useState<string>('');
  const { validateData, errors, clearErrors } = useZodFormValidation(AddAuthAppSchema, { authAppCode });

  const handleChange = (value: string): void => {
    clearErrors();
    setAuthAppCode(value);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    if (validateData()) {
      setAuthAppCode('');
      onSubmit(authAppCode);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Input
        label="One-time password"
        type="number"
        name="verificationCode"
        placeholder={`Enter ${AUTH_APP_CODE_LENGTH}-digit verification code`}
        value={authAppCode}
        onChange={(e) => handleChange(e.target.value)}
        error={errors.authAppCode && errors.authAppCode[0]}
        className={styles.authAppCodeInput}
        autoFocus
      />
      {submitButton}
    </form>
  );
};

export default AuthAppForm;
