import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { Input } from 'theme/atoms';
import styles from './MaxCommission.module.scss';

interface MaxCommissionProps {
  maxCommissionRequireError: string;
  onChange: () => void;
}

const MaxCommission = ({ maxCommissionRequireError, onChange }: MaxCommissionProps): JSX.Element => {
  const { consignmentFormStore } = useStores();
  const { consignmentForm, setMaxCommission } = consignmentFormStore;
  const [error, setError] = useState('');

  const validateInputFormat = (value: string): void => {
    // Regex for positive numbers with optional % at the end
    const validInputRegex = /^(\d+%?)?$/;

    if (validInputRegex.test(value)) {
      const numericValue = Number(value.replace('%', ''));

      if (value.includes('%') && numericValue > 100) {
        setError('% cannot be greater than 100.');
      } else {
        setMaxCommission(value);
        setError('');
      }
    } else {
      setError('Invalid input. Please enter a positive number or percentage.');
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    validateInputFormat(e.target.value);
    onChange();
  };

  return (
    <Input
      name="maxCommission"
      label="Max commission in % or $"
      placeholder="Enter the commission in % or $"
      onChange={handleInputChange}
      value={consignmentForm.maxCommission || ''}
      className={styles.root}
      type="text"
      error={maxCommissionRequireError || error}
      errorClassName={styles.error}
    />
  );
};

export default observer(MaxCommission);
