import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import { Consignment, ConsignmentStatus } from 'consignments/types';
import { Button } from 'theme/atoms';
import SectionHeader from 'theme/atoms/Titles/SectionHeader';
import IconComponent, { icons } from 'theme/atoms/iconComponent';
import { useDebounceIsPanelOpen } from 'theme/atoms/slidingPanel/useDebounceIsPanelOpen';
import UserCard from 'theme/atoms/userCard';
import styles from './StatusOwnedConsignedView.module.scss';

interface StatusOwnedConsignedViewProps {
  setSelectedConsignment: (consignment: Consignment) => void;
  consignments?: Consignment[];
  isConsignmentSelected: boolean;
  hasAcceptedTransaction: boolean;
}

const StatusOwnedConsignedView = ({
  consignments,
  setSelectedConsignment,
  isConsignmentSelected,
  hasAcceptedTransaction,
}: StatusOwnedConsignedViewProps): JSX.Element => {
  const { consignmentFormStore } = useStores();
  const { setConsigneePickerIsOpen, consigneePickerIsOpen } = consignmentFormStore;
  const navigate = useNavigate();

  const debouncedIsConsignmentSelected = useDebounceIsPanelOpen(isConsignmentSelected);
  const debouncedConsigneePickerIsOpen = useDebounceIsPanelOpen(consigneePickerIsOpen);

  const hasExclusiveConsignment = Boolean(
    consignments
      ?.filter((consignment) => consignment.status === ConsignmentStatus.Accepted)
      .find((consignment) => consignment.showArtwork === 'Exclusive')
  );

  const displayActionForPendingOwnersAgreement = (consignment: Consignment): JSX.Element | null => {
    return consignment.status === ConsignmentStatus.PendingOwnersAgreement && !consignment.artworkOwnerAnswer ? (
      <Button
        className={styles.action}
        text="Take action"
        onClick={() => {
          navigate(routes.Consignment.path(consignment.id));
        }}
      />
    ) : null;
  };

  const displayAwaitingApprovalsInfo = (consignment: Consignment): JSX.Element => {
    const { artworkOwnersAwaitingApprovals } = consignment;

    return consignment.status === ConsignmentStatus.PendingOwnersAgreement ? (
      <div className={styles.awaitingApproval}>
        Awaiting approvals {artworkOwnersAwaitingApprovals?.awaiting}/{artworkOwnersAwaitingApprovals?.total}
      </div>
    ) : (
      <></>
    );
  };

  const displayConsignee = (consignment: Consignment): JSX.Element => {
    return (
      <UserCard id={consignment.id}>
        <UserCard.Consignment
          name={consignment.consigneeContact?.organizationName || 'Undisclosed'}
          isExclusive={consignment.showArtwork === 'Exclusive'}
          isPending={consignment.status === ConsignmentStatus.PendingConsigneeAgreement}
          hasMandateToSell={consignment.sellArtwork}
        />
      </UserCard>
    );
  };

  const displayConsignmentDetails = (consignment: Consignment): JSX.Element => {
    return (
      <div key={consignment.id} className={styles.consignmentItem}>
        <div
          className={clsx(styles.rowWrapper, {
            [styles.pendingOwnersAgreement]: consignment.status === ConsignmentStatus.PendingOwnersAgreement,
          })}
          onClick={() => debouncedIsConsignmentSelected && setSelectedConsignment(consignment)}
        >
          {displayConsignee(consignment)}
          <IconComponent icon={icons.next} />
        </div>
        {displayAwaitingApprovalsInfo(consignment)}
        {displayActionForPendingOwnersAgreement(consignment)}
      </div>
    );
  };

  const getConsignmentsToDisplay = (): JSX.Element[] | undefined => {
    return consignments?.map(displayConsignmentDetails);
  };

  return (
    <div className={styles.root}>
      <div className={styles.rowWrapper}>
        <SectionHeader title="Consigned representatives" />
        <Button
          disabled={hasExclusiveConsignment || hasAcceptedTransaction}
          text="Add"
          iconStart={<IconComponent icon={icons.add} />}
          buttonType="tertiary"
          onClick={() => {
            if (!hasExclusiveConsignment && debouncedConsigneePickerIsOpen) {
              setConsigneePickerIsOpen(true);
            }
          }}
        />
      </div>
      {getConsignmentsToDisplay()}
    </div>
  );
};

export default observer(StatusOwnedConsignedView);
