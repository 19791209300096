import api from 'api';
import { ListResult } from 'api/types';
import { Artwork, ArtworkId, ArtworksRequestParams, TagsListReturn } from './types';

export const fetchArtworks = (params: ArtworksRequestParams): ListResult<Artwork> => {
  return api.get('/artworks', { params });
};

export const fetchArtwork = (id: ArtworkId): ListResult<Artwork> => {
  const params = {
    id,
  };
  return api.get('/artworks', { params });
};

export const fetchTagsList = (): TagsListReturn => {
  return api.get('/artworks/tags');
};
