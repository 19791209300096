import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { ConsignmentShowArtwork } from 'consignments/types';
import { SELL_ARTWORK_COPY } from 'domain/constants';
import Checkbox from 'theme/atoms/Checkbox';
import RadioButton from 'theme/atoms/radioButton';
import styles from './Permissions.module.scss';

const Permissions = (): JSX.Element => {
  const { consignmentFormStore } = useStores();
  const { consignmentForm, setSellArtwork, setShowArtwork } = consignmentFormStore;

  return (
    <>
      <div className={styles.section}>
        <p className={styles.label}>Permission to show the artwork:</p>
        {Object.keys(ConsignmentShowArtwork).map((key) => {
          return (
            <RadioButton
              key={key}
              name={key}
              label={ConsignmentShowArtwork[key]}
              checked={consignmentForm.showArtwork === key}
              onChange={() => setShowArtwork(key as keyof typeof ConsignmentShowArtwork)}
            />
          );
        })}
      </div>
      <div className={styles.section}>
        <p className={styles.label}>Permission to sell:</p>
        <Checkbox
          label={SELL_ARTWORK_COPY}
          labelClassName={styles.checkboxLabel}
          name="sellArtwork"
          checked={consignmentForm.sellArtwork}
          onChange={(e) => setSellArtwork(e.target.checked)}
        />
      </div>
    </>
  );
};

export default observer(Permissions);
