import { ReactNode, useRef } from 'react';
import clsx from 'clsx';
import { SELL_ARTWORK_COPY } from 'domain/constants';
import { OrganizationKind } from 'organization/types';
import { ReactComponent as LocationIcon } from 'theme/assets/svg/location.svg';
import { ReactComponent as TickSmallIcon } from 'theme/assets/svg/tickSmall.svg';
import { ReactComponent as TickWowIcon } from 'theme/assets/svg/tickWow.svg';
import { useIsOverflow } from 'utils/hooks/useIsOverflow';
import Avatar from '../avatar';
import Tooltip from '../tooltip';
import styles from './UserCard.module.scss';

interface UserCardCommonProps {
  id: string;
  children: ReactNode;
  isInactive?: boolean;
  type?: OrganizationKind;
}

interface BigUserCardProps extends UserCardCommonProps {
  isMedium?: never;
  isSmall?: never;
  isBig?: boolean;
}

interface MediumUserCardProps extends UserCardCommonProps {
  isMedium?: boolean;
  isSmall?: never;
  isBig?: never;
}

interface SmallUserCardProps extends UserCardCommonProps {
  isMedium?: never;
  isSmall?: boolean;
  isBig?: never;
}

type UserCardProps = MediumUserCardProps | SmallUserCardProps | BigUserCardProps;

const UserCard = ({ id, children, isInactive, isMedium, isSmall, isBig, type }: UserCardProps): JSX.Element => {
  const sizeClasses = clsx({ [styles.small]: isSmall, [styles.medium]: isMedium, [styles.big]: isBig });

  return (
    <div className={clsx(styles.root, sizeClasses, { [styles.inactive]: isInactive })}>
      <div className={clsx(styles.avatar, sizeClasses)}>
        <Avatar id={id} isInstitutional={type === OrganizationKind.Institutional} />
      </div>

      {children}
    </div>
  );
};

interface UserCardNameProps {
  name: string;
  numberOfLinesInName?: number;
}

const UserCardName = ({ name, numberOfLinesInName }: UserCardNameProps): JSX.Element => {
  return (
    <div className={styles.contentName}>
      <div className={styles.name} style={{ WebkitLineClamp: numberOfLinesInName }}>
        {name}
      </div>
    </div>
  );
};

interface UserCardNameAndRoleProps extends UserCardNameProps {
  title: string;
}

const UserCardNameAndTitle = ({ name, title, numberOfLinesInName }: UserCardNameAndRoleProps): JSX.Element => {
  const tooltipTriggerRef = useRef<HTMLDivElement>(null);
  const isOverflow = useIsOverflow(tooltipTriggerRef);

  return (
    <div className={styles.contentNameAndTitle}>
      <Tooltip contentText={name} isDisabled={!isOverflow}>
        <div ref={tooltipTriggerRef} className={clsx(styles.name)} style={{ WebkitLineClamp: numberOfLinesInName }}>
          {name}
        </div>
      </Tooltip>
      <div className={styles.title}>{title}</div>
    </div>
  );
};

interface UserCardContactProps extends UserCardNameProps {
  title?: string;
  address?: string;
  hasLocationIcon?: boolean;
}

const UserCardContact = ({
  name,
  title,
  address,
  hasLocationIcon,
  numberOfLinesInName,
}: UserCardContactProps): JSX.Element => {
  return (
    <div className={styles.contentContact}>
      <div className={styles.name} style={{ WebkitLineClamp: numberOfLinesInName }}>
        {name}
      </div>
      {title && <div className={styles.title}>{title}</div>}
      {address && (
        <div className={styles.address}>
          {hasLocationIcon && <LocationIcon className={styles.icon} />}
          {address}
        </div>
      )}
    </div>
  );
};

interface UserCardConsignmentProps {
  name: string;
  isPending?: boolean;
  isExclusive?: boolean;
  hasMandateToSell?: boolean;
}

const UserCardConsignment = ({
  name,
  isPending,
  isExclusive,
  hasMandateToSell,
}: UserCardConsignmentProps): JSX.Element => {
  const exclusive = isExclusive ? (
    <>
      exclusive
      <TickWowIcon className={styles.tickWowIcon} />
    </>
  ) : (
    'non-exclusive'
  );

  return (
    <div className={styles.contentConsignment}>
      <div className={styles.name}>
        {name}
        {isPending && <span className={styles.pending}>(pending)</span>}
      </div>
      <div className={styles.exclusive}>{exclusive}</div>
      {hasMandateToSell && (
        <div className={styles.mandateToSell}>
          <TickSmallIcon className={styles.tickSmallIcon} />
          {SELL_ARTWORK_COPY}
        </div>
      )}
    </div>
  );
};

interface UserCardProfileProps extends UserCardContactProps {
  description?: string;
  url?: string;
}

const UserCardProfile = ({ description, url, ...contactProps }: UserCardProfileProps): JSX.Element => {
  return (
    <div className={styles.profile}>
      <div className={styles.header}>
        <UserCardContact {...contactProps} />
        {url && <div className={styles.url}>{url}</div>}
      </div>

      <div className={styles.description}>{description}</div>
    </div>
  );
};

UserCard.Name = UserCardName;
UserCard.NameAndTitle = UserCardNameAndTitle;
UserCard.Contact = UserCardContact;
UserCard.Consignment = UserCardConsignment;
UserCard.Profile = UserCardProfile;

export default UserCard;
