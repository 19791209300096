import { AxiosResponse } from 'axios';
import { TimestampsAndTraces } from 'api/types';
import { Address } from 'domain/types/Contact.types';
import { Role } from 'domain/types/Domain.types';

export enum OrganizationKind {
  Individual = 'Individual',
  Institutional = 'Institutional',
  Bank = 'Bank',
  Undefined = '',
}

export enum KycStatus {
  Unverified = 'Unverified',
  Pending = 'Pending',
  TemporaryRejected = 'TemporaryRejected',
  Rejected = 'Rejected',
  Verified = 'Verified',
}

export type OrganizationId = string;

export interface RawOrganization {
  address: Address;
  id: string;
  name: string;
  roles: Role[];
  title: string;
  type: OrganizationKind;
  taxId: string;
  url?: string;
  profileDescription?: string;
  kycStatus: KycStatus;
}

export interface Organization extends RawOrganization, TimestampsAndTraces {
  isBank: boolean;
}

export type OrganizationReturn = Promise<AxiosResponse<Organization>>;
export type OrganizationsReturn = Promise<AxiosResponse<Organization[]>>;

export interface KycToken {
  token: string;
  userId: string;
}

export type KycTokenReturn = Promise<AxiosResponse<KycToken>>;
