import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { useStores } from 'RootStore';
import { NETWORK_CONTACT_INVITATION_BASE_PATH } from 'app/routes/paths.const';
import { QRCodeSVG } from 'qrcode.react';
import { ReactComponent as ContentCopyIcon } from 'theme/assets/svg/content-copy.svg';
import Avatar from 'theme/atoms/avatar';
import Loader from 'theme/atoms/loader';
import { useOrganizationKinds } from 'utils/hooks/useOrganizationKinds';
import styles from './OrganizationProfileShare.module.scss';

const OrganizationProfileShare = (): JSX.Element => {
  const CONTACT_INVITATION_BASE_URL = `${location.origin}${NETWORK_CONTACT_INVITATION_BASE_PATH}`;

  const { organizationProfileShareStore, organizationStore, toastsStore } = useStores();
  const { contactInvitation, createContactInvitation, loading } = organizationProfileShareStore;
  const { selectedOrganization, fetchOrganizations } = organizationStore;
  const { addToast } = toastsStore;
  const { isInstitutional } = useOrganizationKinds();

  const [contactInvitationUrl, setContactInvitationUrl] = useState<string | null>(null);

  useEffect(() => {
    if (!selectedOrganization) fetchOrganizations();
    createContactInvitation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (contactInvitation) {
      setContactInvitationUrl(`${CONTACT_INVITATION_BASE_URL}/${contactInvitation.id}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactInvitation]);

  const handleCopyUrl = (): void => {
    if (!contactInvitationUrl) return;

    navigator.clipboard.writeText(contactInvitationUrl);
    addToast('Sharing link copied to clipboard', 'success', 3000);
  };

  const avatarRender = selectedOrganization ? (
    <Avatar id={selectedOrganization.id} isInstitutional={isInstitutional} />
  ) : null;
  const qrCodeRender =
    !loading && contactInvitationUrl ? <QRCodeSVG value={contactInvitationUrl} size={275} /> : <Loader />;

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div className={styles.avatar}>{avatarRender}</div>

        <div className={styles.qrCodeBox}>
          <div className={styles.qrCode}>{qrCodeRender}</div>
          <h2 className={styles.organizationName}>{selectedOrganization?.name}</h2>
          <div className={styles.organizationTitle}>{selectedOrganization?.title}</div>
        </div>

        <div className={clsx(styles.linkBox, { [styles.isHidden]: loading || !contactInvitationUrl })}>
          <input type="text" readOnly className={styles.linkInput} value={contactInvitationUrl || ''} />
          <div className={styles.icon}>
            <ContentCopyIcon onClick={handleCopyUrl} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(OrganizationProfileShare);
