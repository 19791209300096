import { Option } from 'theme/atoms/Select/Select';

export const TRANSACTION_NOTE =
  'The buyer is responsible for all duties and taxes that apply. The costs of delivery and insurance of the work while in transit are the responsibility of the buyer.';

export const SELL_ARTWORK_COPY = 'mandate to sell artwork';

export const CURRENCIES = ['USD', 'EUR'] as const;
export const CURRENCY_OPTIONS: Option[] = CURRENCIES.map((currency) => ({ label: currency, value: currency }));

export const ORGANIZATION_DATA_UNKNOWN = 'N/A';

export const BANK_ACCOUNT_FIELDS = [
  { name: 'iban', label: 'IBAN' },
  { name: 'bankName', label: 'Bank name' },
  { name: 'bankAddress', label: 'Bank address' },
  { name: 'swift', label: 'BIC/SWIFT code' },
];
