import { KindYearsNumber } from '../radioButtonYearFilter/types';

export enum RangeEndMin {
  After = 'After',
  From = 'From',
}
export enum RangeEndMax {
  Before = 'Before',
  To = 'To',
}
export type RangeEnd = RangeEndMin & RangeEndMax;

export enum KindDates {
  created = 'created',
  expire = 'expire',
}

export type Kind = KindDates & KindYearsNumber;
