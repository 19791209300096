import { Contact } from 'domain/types/Contact.types';
import { getAddress } from 'network/utils';
import IconComponent, { icons } from 'theme/atoms/iconComponent';
import UserCard from 'theme/atoms/userCard';
import styles from './ConsignedRepresentative.module.scss';

interface ConsignedRepresentativeProps {
  consigneeContact: Contact;
  onClick?: () => void;
  withIcon?: boolean;
}

const ConsignedRepresentative = ({
  consigneeContact,
  onClick,
  withIcon,
}: ConsignedRepresentativeProps): JSX.Element => {
  return (
    <div className={styles.root} onClick={() => onClick && onClick()}>
      <UserCard id={consigneeContact?.contactConnectionId || ''}>
        <UserCard.Contact
          name={consigneeContact?.organizationName || 'Undisclosed'}
          title={consigneeContact?.organizationTitle}
          address={getAddress(consigneeContact?.organizationAddress)}
        />
      </UserCard>
      {withIcon && <IconComponent icon={icons.next} />}
    </div>
  );
};

export default ConsignedRepresentative;
