import { NumberFormatValues } from 'react-number-format';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import Big from 'big.js';
import { CURRENCY_OPTIONS } from 'domain/constants';
import { Currency } from 'domain/types/Domain.types';
import Select from 'theme/atoms/Select';
import CurrencyInputWrapper from 'theme/atoms/currencyInputWrapper';
import styles from './AskingPrice.module.scss';

interface AskingPriceProps {
  askingPriceError: string;
  onChange: () => void;
}

const AskingPrice = ({ askingPriceError, onChange }: AskingPriceProps): JSX.Element => {
  const { consignmentFormStore } = useStores();
  const { consignmentForm, setCurrency, setAskingPrice } = consignmentFormStore;

  const handleAskingPriceChange = (values: NumberFormatValues): void => {
    if (!values || values.value === '') {
      setAskingPrice(null);
    } else {
      setAskingPrice(Big(values.value));
    }
    onChange();
  };

  return (
    <div className={styles.root}>
      <CurrencyInputWrapper
        name="askingPrice"
        label="Asking price"
        placeholder="Enter the price"
        value={consignmentForm.askingPrice?.toString() || ''}
        onValueChange={handleAskingPriceChange}
        currency={consignmentForm.currency}
        error={askingPriceError}
      />
      <Select
        label="Currency"
        className={styles.currency}
        options={CURRENCY_OPTIONS}
        value={{
          value: consignmentForm.currency || '',
          label: consignmentForm.currency || '',
        }}
        onChange={(option) => setCurrency(option.label as Currency)}
      />
    </div>
  );
};

export default observer(AskingPrice);
