import clsx from 'clsx';
import styles from './ToggleSwitch.module.scss';

interface ToggleSwitchProps {
  label: string;
  isChecked: boolean;
  onChange: () => void;
  className?: string;
}

const ToggleSwitch = ({ label, isChecked, onChange, className = '' }: ToggleSwitchProps): JSX.Element => {
  return (
    <div className={clsx(styles.root, className)}>
      <label htmlFor={label} className={styles.label}>
        {label}
      </label>
      <input id={label} type="checkbox" className={styles.switch} checked={isChecked} onChange={onChange} />
    </div>
  );
};

export default ToggleSwitch;
