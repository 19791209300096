import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import ContactPicker from 'network/contactPicker/ContactPicker';
import { ContactConnection } from 'network/types';

const ConsigneePicker = (): JSX.Element => {
  const { artworkId } = useParams();
  const { consignmentFormStore, artworkStore } = useStores();
  const { consigneesIdsWithActiveConsignment, artworkOwnersContactIds } = artworkStore;
  const { initializeCreatingNewConsignment, consigneePickerIsOpen, setConsigneePickerIsOpen } = consignmentFormStore;

  const onPickRepresentative = (contact: ContactConnection): void => {
    setConsigneePickerIsOpen(false);
    artworkId && initializeCreatingNewConsignment(contact, artworkId);
  };

  return (
    <ContactPicker
      title="Add representative"
      isOpen={consigneePickerIsOpen}
      onPickContact={onPickRepresentative}
      onClose={() => setConsigneePickerIsOpen(false)}
      contactsToExclude={[...consigneesIdsWithActiveConsignment, ...artworkOwnersContactIds]}
    />
  );
};

export default observer(ConsigneePicker);
