import { AxiosResponse } from 'axios';
import api from 'api';
import { ListResult } from 'api/types';
import { InvoiceState } from 'domain/types/Invoices.types';
import { RawBankInvoice } from './requests.types';

export interface FetchBankInvoicesParams {
  invoiceState?: InvoiceState;
  id?: string;
  pageSize?: number;
  pageNumber?: number;
}

export const fetchBankInvoices = (params: FetchBankInvoicesParams): ListResult<RawBankInvoice> => {
  return api.get('/bank/invoices', { params });
};

export const fetchBankInvoice = (invoiceId: string): ListResult<RawBankInvoice> => {
  return api.get('/bank/invoices', { params: { id: invoiceId } });
};

export const acceptInvoice = (invoiceId: string, invoiceLastModifiedAt: Date): Promise<AxiosResponse> => {
  return api.post(`/bank/invoices/${invoiceId}/accept`, { invoiceLastModifiedAt });
};

export const markAsPaid = (invoiceId: string, invoiceLastModifiedAt: Date): Promise<AxiosResponse> => {
  return api.post(`/bank/invoices/${invoiceId}/mark-as-paid`, { invoiceLastModifiedAt });
};

export const rejectInvoice = (
  invoiceId: string,
  invoiceLastModifiedAt: Date,
  note?: string
): Promise<AxiosResponse> => {
  return api.post(`/bank/invoices/${invoiceId}/reject`, { invoiceLastModifiedAt, note });
};
