import { makeAutoObservable, runInAction } from 'mobx';
import { RootStore } from 'RootStore';
import {
  getAuthDetailsFromLocalStorage,
  getRefreshTokenFromLocalStorage,
  parseJwt,
  removeAuthDetailsFromLocalStorage,
  removeRefreshTokenFromLocalStorage,
  saveAuthDetailsInLocalStorage,
  saveRefreshTokenInLocalStorage,
} from 'api';
import { routes } from 'app/routes/paths.const';
import { getMessageFromApiErrorResponse } from 'domain/getMessageFromApiErrorResponse';
import { StoreActionReturn } from 'domain/types/StoreActionReturn';
import { toastMessages } from 'toasts/toastMessages';
import { isAccessTokenValid } from 'utils/helpers';
import {
  MINIMAL_AUTHENTICATION_LEVEL,
  USER_IDENTITY_TYPES_AVAILABLE_AS_MFA_METHODS,
  userIdentityTypeToRouteMap,
} from './constants';
import * as requests from './requests';
import {
  Auth,
  AuthUser,
  AuthUserIdentity,
  LoginReturnedTokens,
  LoginWithTokenParams,
  UserIdentityType,
  UserIdentityWithTokens,
} from './types';

class AuthStore {
  rootStore: RootStore;

  submitting = false;
  submittingLogout = false;
  submittingLoginWithRefreshToken = false;

  accessToken: Auth | null = null;
  refreshToken: string | null = null;

  userNeedsVerification = true;

  queuedRequests: ((accessToken: string) => void)[] = [];

  userIdentities: AuthUserIdentity[] | null = null;
  emailFromLogin = '';

  TOTP: { secret: string; qr: string } | null = null;

  recoveryCodes: string[] | null = null;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.init();
    makeAutoObservable(this);
  }

  get loggedInUser(): AuthUser | null {
    return this.accessToken?.user || null;
  }

  get isUserAuthenticated(): boolean {
    if (!this.accessToken || !this.refreshToken) return false;
    const parsedRefreshToken = parseJwt(this.refreshToken);

    return Boolean(
      this.accessToken.user.authedBy.length >= MINIMAL_AUTHENTICATION_LEVEL &&
        parsedRefreshToken.authedBy.length >= MINIMAL_AUTHENTICATION_LEVEL
    );
  }

  getMFAUserIdentityTypesToVerify = (userIdentities?: AuthUserIdentity[]): UserIdentityType[] => {
    const userIdentitiesToFilter = userIdentities || this.userIdentities;
    if (!userIdentitiesToFilter) return [];

    const userIdentitiesAddedAsMFAMethod: AuthUserIdentity[] = userIdentitiesToFilter.filter(
      (identity) => identity.confirmed && USER_IDENTITY_TYPES_AVAILABLE_AS_MFA_METHODS.includes(identity.type)
    );
    const userIdentityTypesToVerify = userIdentitiesAddedAsMFAMethod.map((identity) => identity.type);

    return userIdentityTypesToVerify;
  };

  private init = (): void => {
    const authDetails = getAuthDetailsFromLocalStorage();
    this.saveAccessToken(authDetails && JSON.parse(authDetails));
    const refreshToken = getRefreshTokenFromLocalStorage();
    this.saveRefreshToken(refreshToken);

    this.submitting = false;
  };

  private saveAccessToken = (data: Auth | null): void => {
    runInAction(() => (this.accessToken = data));
  };

  private saveRefreshToken = (token: string | null): void => {
    runInAction(() => (this.refreshToken = token));
  };

  saveTokensToStore = (loginReturnedTokens: LoginReturnedTokens): void => {
    const user: AuthUser = parseJwt(loginReturnedTokens.accessToken);
    const accessToken = loginReturnedTokens.accessToken;
    const refreshToken = loginReturnedTokens.refreshToken;

    this.saveAccessToken({ token: accessToken, user });
    this.saveRefreshToken(refreshToken);
  };

  saveTokensToLocalStorage = (loginReturnedTokens?: LoginReturnedTokens): void => {
    if (loginReturnedTokens) {
      saveAuthDetailsInLocalStorage(loginReturnedTokens.accessToken);
      saveRefreshTokenInLocalStorage(loginReturnedTokens.refreshToken);
    } else {
      if (this.accessToken) saveAuthDetailsInLocalStorage(this.accessToken.token);
      if (this.refreshToken) saveRefreshTokenInLocalStorage(this.refreshToken);
    }
  };

  setUserNeedsVerification = (userNeedsVerification: boolean): void => {
    this.userNeedsVerification = userNeedsVerification;
  };

  setQueuedRequests = (newReq: (accessToken: string) => void): void => {
    this.queuedRequests = [...this.queuedRequests, newReq];
  };

  login = (email: string, password: string): Promise<StoreActionReturn | void> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.submitting = true));

    this.emailFromLogin = email;

    return requests
      .login(email, password)
      .then(async ({ data }: { data: LoginReturnedTokens }) => {
        runInAction(() => {
          this.saveTokensToStore(data);
        });

        const identities = await this.getUserIdentities();
        const MFAUserIdentityTypesToVerify = this.getMFAUserIdentityTypesToVerify(identities);

        return {
          success: true,
          redirectUrl:
            MFAUserIdentityTypesToVerify.length > 0
              ? routes[userIdentityTypeToRouteMap[MFAUserIdentityTypesToVerify[0]]?.login || 'Login'].path()
              : routes.LoginAddAuthMethod.path(),
        };
      })
      .catch((err) => {
        if (err.response?.status === 403) {
          return { success: false, redirectUrl: routes.UnverifiedEmail.path() };
        } else if (err.response?.status === 401) {
          addToast(toastMessages.AUTH.LOGIN_ERROR, 'error');
        } else {
          addToast(toastMessages.DEFAULT.ERROR, 'error');
        }
      })
      .finally(() => runInAction(() => (this.submitting = false)));
  };

  verifyUser = (username: string, password: string): Promise<StoreActionReturn | void> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.submitting = true));

    return requests
      .login(username, password)
      .then(({ data }: { data: LoginReturnedTokens }) => {
        runInAction(() => {
          this.saveTokensToStore(data);
          this.saveTokensToLocalStorage(data);
        });

        return {
          success: true,
        };
      })
      .catch((err) => {
        addToast(toastMessages.DEFAULT.ERROR, 'error');
        return { success: false };
      })
      .finally(() => runInAction(() => (this.submitting = false)));
  };

  loginWithRefreshToken = (refreshToken: string): Promise<void> => {
    runInAction(() => (this.submittingLoginWithRefreshToken = true));
    return requests
      .loginWithRefreshToken(refreshToken)
      .then(({ data }: { data: LoginReturnedTokens }) => {
        runInAction(() => {
          this.saveTokensToStore(data);
          this.saveTokensToLocalStorage(data);
        });

        Promise.all(this.queuedRequests.map((req) => req(data.accessToken)));
      })
      .catch((error) => {
        if (error.response?.status === 401) this.logout(true);
      })
      .finally(() =>
        runInAction(() => {
          this.submittingLoginWithRefreshToken = false;
          this.queuedRequests = [];
        })
      );
  };

  loginWithToken = ({
    token,
    email,
    phoneNumber,
    userIdentityId,
  }: LoginWithTokenParams): Promise<
    StoreActionReturn & {
      MFAUserIdentityTypesToVerify?: UserIdentityType[];
    }
  > => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.submitting = true));

    return requests
      .loginWithToken({ token, email, phoneNumber, userIdentityId })
      .then(async ({ data }: { data: LoginReturnedTokens }) => {
        runInAction(() => {
          this.saveTokensToStore(data);
        });

        const identities = await this.getUserIdentities();
        const MFAUserIdentityTypesToVerify = this.getMFAUserIdentityTypesToVerify(identities);

        return { success: true, MFAUserIdentityTypesToVerify };
      })
      .catch((err) => {
        addToast(toastMessages.DEFAULT.ERROR, 'error');
        return {
          success: false,
          message: getMessageFromApiErrorResponse(err.response?.data.type),
        };
      })
      .finally(() => runInAction(() => (this.submitting = false)));
  };

  loginWithTOTPCode = (authAppCode: string): Promise<StoreActionReturn | void> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.submitting = true));

    const userIdentityId = this.accessToken?.user.identities.find(
      (identity) => identity.type === UserIdentityType.TimeBasedOneTimePassword
    )?.id;

    if (!userIdentityId) return Promise.reject();

    return requests
      .loginWithTOTPCode(userIdentityId, authAppCode)
      .then(({ data }: { data: LoginReturnedTokens }) => {
        runInAction(() => {
          this.saveTokensToStore(data);
        });
        return { success: true };
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          addToast('Invalid Authenticator App code', 'error');
        } else {
          addToast(toastMessages.DEFAULT.ERROR, 'error');
        }

        return { success: false };
      })
      .finally(() => runInAction(() => (this.submitting = false)));
  };

  resetAllInAllStores = (): void => {
    this.rootStore.userStore.resetAllInAllStoresExceptUserAndAuth();
    this.rootStore.userStore.resetAll();
    this.rootStore.organizationStore.resetAllOrganizationsData();
    this.rootStore.bankStore.resetAllBankData();
    this.resetAll();
  };

  logout = (didLoginWithRefreshTokenFail?: boolean): void => {
    const { addToast } = this.rootStore.toastsStore;
    const refreshToken = getRefreshTokenFromLocalStorage();
    if (refreshToken && !didLoginWithRefreshTokenFail) {
      runInAction(() => (this.submittingLogout = true));
      requests
        .invalidateRefreshToken()
        .catch((err) => addToast('err ' + err.response?.status, 'error'))
        .finally(() => {
          this.resetAllInAllStores();
          runInAction(() => (this.submittingLogout = false));
        });
    } else {
      this.resetAllInAllStores();
    }
  };

  resetSubmitting = (): void => {
    runInAction(() => (this.submitting = false));
  };

  sendLinkToResetPassword = (email: string): Promise<void | boolean> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.submitting = true));

    return requests
      .getToken({ email })
      .then(() => {
        return true;
      })
      .catch(() => addToast(toastMessages.DEFAULT.ERROR, 'error'))
      .finally(() => runInAction(() => (this.submitting = false)));
  };

  reloginAndResetPassword = (currentPassword: string, newPassword: string): Promise<StoreActionReturn | void> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.submitting = true));

    const userEmail = this.accessToken?.user.identities.find(
      (identity) => identity.type === UserIdentityType.MailAddress
    )?.value;

    if (userEmail) {
      return requests
        .login(userEmail, currentPassword)
        .then(({ data }: { data: LoginReturnedTokens }) => {
          runInAction(() => {
            this.saveTokensToStore(data);
            this.saveTokensToLocalStorage(data);
          });

          return this.resetPassword(newPassword);
        })
        .catch(() => {
          addToast('Invalid current password', 'error');
          runInAction(() => (this.submitting = false));
        });
    } else {
      addToast(toastMessages.DEFAULT.ERROR, 'error');
      runInAction(() => (this.submitting = false));
      return Promise.resolve();
    }
  };

  resetPassword = (newPassword: string, unauthedUser?: boolean): Promise<StoreActionReturn | void> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.submitting = true));

    const userIdentityId = this.accessToken?.user.identities.find(
      (identity) => identity.type === UserIdentityType.MailAddress
    )?.id;

    if (!userIdentityId) {
      addToast(toastMessages.DEFAULT.ERROR, 'error');
      runInAction(() => (this.submitting = false));
      return Promise.resolve();
    }

    return requests
      .resetPassword(userIdentityId, newPassword)
      .then(() => {
        addToast(toastMessages.RESET_PASSWORD.UPDATE_SUCCESS, 'success');
        if (unauthedUser) {
          runInAction(() => {
            this.accessToken = null;
            this.refreshToken = null;
          });
        }
        return { success: true };
      })
      .catch((err) => {
        addToast(getMessageFromApiErrorResponse(err.response?.data.type), 'error');
      })
      .finally(() => runInAction(() => (this.submitting = false)));
  };

  getUserIdentities = (): Promise<AuthUserIdentity[]> => {
    const { addToast } = this.rootStore.toastsStore;
    runInAction(() => (this.submitting = true));

    return requests
      .fetchUserIdentities()
      .then(({ data: identities }) => {
        runInAction(() => (this.userIdentities = identities));
        return identities;
      })
      .catch(() => {
        addToast(toastMessages.DEFAULT.ERROR, 'error');
        return [];
      })
      .finally(() => runInAction(() => (this.submitting = false)));
  };

  addUserIdentity = ({
    type,
    value,
    verifyToken,
  }: {
    type: UserIdentityType;
    value: string;
    verifyToken?: string;
  }): Promise<{
    success?: boolean;
    data: UserIdentityWithTokens;
  } | void> => {
    const { addToast } = this.rootStore.toastsStore;

    if (!isAccessTokenValid(this.accessToken) || !this.accessToken?.user.fresh) {
      addToast('Verify your identity', 'error');
      return Promise.reject({ success: false });
    }

    runInAction(() => {
      this.submitting = true;
    });

    return requests
      .addUserIdentity({ type, value, main: false, verifyToken })
      .then(({ data }: { data: UserIdentityWithTokens }) => {
        return { success: true, data };
      })
      .catch((err) => {
        return Promise.reject(err);
      })
      .finally(() => {
        runInAction(() => {
          this.submitting = false;
        });
      });
  };

  confirmUserIdentity = (
    userIdentityType: UserIdentityType,
    confirmationCode: string
  ): Promise<{ success?: boolean; data: UserIdentityWithTokens } | void> => {
    const { addToast } = this.rootStore.toastsStore;
    runInAction(() => (this.submitting = true));

    const userIdentity = this.userIdentities?.find((identity) => identity.type === userIdentityType);
    if (!userIdentity) return Promise.reject({ success: false });

    return requests
      .confirmUserIdentity(userIdentity.id, confirmationCode)
      .then(({ data }: { data: UserIdentityWithTokens }) => {
        addToast('Authentication method successfully added', 'success');
        return { success: true, data };
      })
      .catch(() => {
        addToast('Confirmation code is invalid.', 'error');
      })
      .finally(() => runInAction(() => (this.submitting = false)));
  };

  sendUserIdentityConfirmationCode = (userIdentityId: string): Promise<StoreActionReturn | void> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.submitting = true));

    return requests
      .sendUserIdentityConfirmationCode(userIdentityId)
      .then(() => {
        return { success: true };
      })
      .catch(() => addToast(toastMessages.DEFAULT.ERROR, 'error'))
      .finally(() => runInAction(() => (this.submitting = false)));
  };

  deleteUserIdentity = (userIdentityId: string): Promise<void> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => {
      this.submitting = true;
    });

    return requests
      .deleteUserIdentity(userIdentityId)
      .then(() => {
        if (this.refreshToken) this.loginWithRefreshToken(this.refreshToken);
      })
      .catch(() => {
        addToast(toastMessages.DEFAULT.ERROR, 'error');
      })
      .finally(() => {
        runInAction(() => {
          this.submitting = false;
        });
      });
  };

  generateAuthAppQRCode = (): Promise<void> => {
    const { addToast } = this.rootStore.toastsStore;
    runInAction(() => (this.submitting = true));

    return requests
      .loginToTOTP()
      .then(({ data }) => {
        runInAction(() => {
          this.TOTP = data;
        });
      })
      .catch(() => addToast('Could not get Authenticator App QR Code', 'error'))
      .finally(() => runInAction(() => (this.submitting = false)));
  };

  sendSMSCodeForLogin = (userIdentityId: string): Promise<StoreActionReturn | void> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.submitting = true));

    return requests
      .getToken({ userIdentityId })
      .then(() => {
        return { success: true };
      })
      .catch(() => addToast(toastMessages.DEFAULT.ERROR, 'error'))
      .finally(() => runInAction(() => (this.submitting = false)));
  };

  fetchRecoveryCodes = (): Promise<StoreActionReturn | void> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.submitting = true));

    return requests
      .fetchRecoveryCodes()
      .then(({ data }) => {
        runInAction(() => {
          this.recoveryCodes = data;
        });
        return { success: true };
      })
      .catch(() => {
        addToast(toastMessages.DEFAULT.ERROR, 'error');
        return Promise.reject({ success: false });
      })
      .finally(() => runInAction(() => (this.submitting = false)));
  };

  clearRecoveryCodes = (): void => {
    runInAction(() => (this.recoveryCodes = null));
  };

  resetAll = (): void => {
    runInAction(() => {
      this.saveAccessToken(null);
      removeAuthDetailsFromLocalStorage();
      this.saveRefreshToken(null);
      removeRefreshTokenFromLocalStorage();
      this.userNeedsVerification = true;
      this.userIdentities = null;
    });
  };
}

export default AuthStore;
