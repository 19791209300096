import { AxiosResponse } from 'axios';
import api from 'api';
import { ListResult } from 'api/types';
import { BankAccountResult, RawBankAccount } from 'domain/types/BankAccount.types';
import { RawBankTransaction } from './requests.types';

export interface FetchBankTransactionsParams {
  id?: string;
  pageSize?: number;
  pageNumber?: number;
}

export const fetchBankTransactions = (params: FetchBankTransactionsParams): ListResult<RawBankTransaction> => {
  return api.get('/bank/transactions', { params });
};

export const fetchBankTransaction = (transactionId: string): ListResult<RawBankTransaction> => {
  return api.get('/bank/transactions', { params: { id: transactionId } });
};

export const sendTransactionToExecution = (transactionId: string): Promise<AxiosResponse> => {
  return api.post(`/bank/transactions/${transactionId}/send-to-execution`);
};

export const fetchEscrowAccountDetails = (transactionId: string): Promise<AxiosResponse<BankAccountResult>> => {
  return api.get(`/bank/transactions/${transactionId}/escrow-account`);
};

export const createEscrowAccount = (
  transactionId: string,
  accountDetails: RawBankAccount
): Promise<AxiosResponse<RawBankAccount>> => {
  return api.post(`/bank/transactions/${transactionId}/escrow-account`, accountDetails);
};

export const editEscrowAccount = (
  transactionId: string,
  accountDetails: RawBankAccount
): Promise<AxiosResponse<RawBankAccount>> => {
  return api.patch(`/bank/transactions/${transactionId}/escrow-account`, accountDetails);
};
