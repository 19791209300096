import { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { AssetWithKey } from 'domain/types/Asset.types';
import { getKeyFromUrl } from 'theme/cells/assetUpload/utils';
import AssetsUpload from '../assetsUpload';
import styles from './Certificate.module.scss';

interface CertificateProps {
  onAdd: () => void;
  error: string;
}

const Certificate = ({ onAdd, error }: CertificateProps): JSX.Element => {
  const { addArtworkStore } = useStores();
  const { artworkForm, updateArtworkForm } = addArtworkStore;

  const assetsFromParent = useMemo(() => {
    return artworkForm.certificationUrls?.map((file) => ({
      ...file,
      key: getKeyFromUrl(file.presignedUrl),
    }));
  }, [artworkForm.certificationUrls]);

  return (
    <AssetsUpload
      assetsFromParent={assetsFromParent}
      onUpdateAssets={(files: AssetWithKey[]) => updateArtworkForm('certificationUrls', files)}
      instruction="Upload artwork certificate"
      title="Certificate"
      onClickAdd={onAdd}
      validationError={<span className={styles.error}>{error}</span>}
      assetsType="document"
      showAsList
    />
  );
};

export default observer(Certificate);
