import { RefObject, useLayoutEffect, useState } from 'react';

export const useIsOverflow = (ref: RefObject<HTMLElement>): boolean | undefined => {
  const [isOverflow, setIsOverflow] = useState<boolean | undefined>(undefined);

  useLayoutEffect(() => {
    const { current } = ref;

    const trigger = (): void => {
      if (current) {
        const hasOverflow = current.scrollHeight > current.clientHeight;
        setIsOverflow(hasOverflow);
      }
    };

    if (current) {
      if ('ResizeObserver' in window) {
        new ResizeObserver(trigger).observe(current);
      }

      trigger();
    }
  }, [ref]);

  return isOverflow;
};
