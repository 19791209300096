import { makeAutoObservable, runInAction } from 'mobx';
import { RootStore } from 'RootStore';
import * as networkRequests from 'network/requests';
import { ContactConnection, ContactInvitation } from 'network/types';

class ContactInvitationStore {
  rootStore: RootStore;

  loadingInvitation = false;
  loadingConnection = false;
  contactInvitation: null | ContactInvitation = null;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  getContactInvitation = (id: string): Promise<void> => {
    const { addToast } = this.rootStore.toastsStore;
    runInAction(() => (this.loadingInvitation = true));

    return networkRequests
      .fetchContactInvitation(id)
      .then(({ data }) => {
        runInAction(() => {
          this.contactInvitation = data;
        });
      })
      .catch(() => addToast('Failed to fetch invitation', 'error'))
      .finally(() => runInAction(() => (this.loadingInvitation = false)));
  };

  createContactConnection = (invitationId: string): Promise<void | string | ContactConnection> => {
    const { addToast } = this.rootStore.toastsStore;
    runInAction(() => (this.loadingConnection = true));

    return networkRequests
      .createContactConnection(invitationId)
      .then(({ data }) => {
        if (data?.status === 'Requested') addToast('The invitation has been sent', 'success');
        return data;
      })
      .catch((error) => {
        if (error.response?.status === 409) {
          addToast('The invitation has already been sent', 'error');
          return 'Connected';
        } else {
          addToast('Failed to send invitation', 'error');
        }
      })
      .finally(() => runInAction(() => (this.loadingConnection = false)));
  };

  resetAll = (): void => {
    runInAction(() => {
      this.contactInvitation = null;
    });
  };
}

export default ContactInvitationStore;
