import { useState, FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import styles from 'auth/Auth.module.scss';
import { isPasswordInvalid } from 'auth/helpers';
import { EMAIL_REGEX } from 'domain/regex';
import { FormValidation } from 'domain/types';
import { ReactComponent as HiddenIcon } from 'theme/assets/svg/hidden1.svg';
import { ReactComponent as ShowIcon } from 'theme/assets/svg/shown1.svg';
import { Button, Input, TitleWhite, Subtitle } from 'theme/atoms';
import Breadcrumbs, { Breadcrumb } from 'theme/atoms/Breadcrumbs';
import Topbar from 'theme/atoms/Topbar';
import { useFormValidation, useTitle } from 'utils/hooks';

const FORM_VALIDATIONS: FormValidation = {
  emailRequired: '',
  emailInvalid: '',
  passwordInvalid: '',
  passwordRequired: '',
};

const BREADCRUMBS: Breadcrumb[] = [
  {
    label: 'Sign up',
  },
];

function SignupCredentials(): JSX.Element {
  const navigate = useNavigate();
  const { createUserStore } = useStores();
  const { createUser, submitting, updateUserRegistrationData } = createUserStore;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  useTitle('Sign up');

  const { cleanValidationErrors, formValidationMessages, handleValidation, validation } =
    useFormValidation(FORM_VALIDATIONS);

  const handleEmailChange = (value: string): void => {
    cleanValidationErrors(['emailRequired', 'emailInvalid']);
    setEmail(value);
  };

  const handlePasswordChange = (value: string): void => {
    cleanValidationErrors(['passwordRequired', 'passwordInvalid']);
    setPassword(value);
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>): void | undefined => {
    event.preventDefault();
    handleValidation('emailRequired', formValidationMessages.EMAIL_REQUIRED, !email);
    handleValidation('emailInvalid', formValidationMessages.EMAIL_INVALID, !!email && !EMAIL_REGEX.test(email));
    handleValidation('passwordRequired', formValidationMessages.PASSWORD_REQUIRED, !password);
    handleValidation('passwordInvalid', formValidationMessages.PASSWORD_INVALID, isPasswordInvalid(password));

    if (!email || !EMAIL_REGEX.test(email) || !password || isPasswordInvalid(password)) return;

    updateUserRegistrationData({ email, password });
    createUser().then((isSuccessful) => isSuccessful && navigate(routes.SignupSuccessful.path()));
  };

  return (
    <div className={clsx(styles.root, styles.twoColumnLayout)}>
      <div className={styles.headerWrapper}>
        <Topbar>
          <Breadcrumbs breadcrumbs={BREADCRUMBS} />
        </Topbar>

        <TitleWhite text="Register new&nbsp;account" />
      </div>

      <div className={clsx('card', styles.formWrapper)}>
        <div className={styles.logo}></div>
        <form onSubmit={onSubmit} className={clsx('form', styles.form)}>
          <h2 className={styles.header}>Register new account</h2>
          <Input
            label="Email"
            name="email"
            onChange={(e) => handleEmailChange(e.target.value)}
            placeholder="Enter your email address"
            value={email}
            error={validation.emailRequired || validation.emailInvalid}
          />
          <Input
            label="Password"
            name="password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => handlePasswordChange(e.target.value)}
            error={validation.passwordRequired || validation.passwordInvalid}
            type={isPasswordVisible ? 'text' : 'password'}
            endIcon={
              <div className={styles.passwordIcon} onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
                {isPasswordVisible ? <ShowIcon /> : <HiddenIcon />}
              </div>
            }
            className={clsx(styles.passwordInput, { [styles.passwordInputInvalid]: validation.passwordInvalid })}
          />
          <Subtitle>
            <span>
              By creating a new account, you accept <Button to="#" text="Terms of Service" buttonType="textButton" />{' '}
              and <Button to="#" text="Privacy Policy" buttonType="textButton" />.
            </span>
          </Subtitle>
          <Button maxWidth text="Create account" loading={submitting} type="submit" />
        </form>
        <Subtitle center>
          <span>
            Already have an account? <Button to={routes.Login.path()} text="Log in" buttonType="textButton" />
          </span>
        </Subtitle>
      </div>
    </div>
  );
}

export default observer(SignupCredentials);
