import { ReactElement } from 'react';
import { Navigate, Route } from 'react-router-dom';
import AuthWrapper from 'auth/AuthWrapper/AuthWrapper';
import { URL_PARAM, routes } from '../paths.const';

export const getRoutesForUnauthenticatedUser = (): ReactElement => {
  return (
    <Route element={<AuthWrapper />}>
      {Object.entries(routes)
        .filter(([_key, view]) => view.pageType === 'publicAndPrivate')
        .map(([key, view]) => (
          <Route key={key} path={view.path()} element={view.component} />
        ))}
      {Object.entries(routes)
        .filter(([key, view]) => view.pageType === 'public')
        .map(([key, view]) => (
          <Route key={key} path={view.path()} element={view.component} />
        ))}
      <Route
        path={routes.ContactInvitation.path(URL_PARAM.invitationId)}
        element={<Navigate to={routes.Login.path()} state={location.pathname} />}
      />
      <Route path="*" element={<Navigate to={routes.Login.path()} replace />} />
    </Route>
  );
};
