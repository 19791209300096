import { useDrag } from 'react-dnd';
import clsx from 'clsx';
import DraggableField from 'theme/atoms/DraggableField';
import ToggleSwitch from 'theme/atoms/ToggleSwitch';
import { DRAGGABLE_TYPES } from '../../constants';
import { DataFieldType, DraggedItem } from '../../types';
import styles from './DataField.module.scss';
import FieldDropZone from './fieldDropZone';
import NestedDataField from './nestedDataField/NestedDataField';

interface DataFieldProps {
  index: number;
  field: DataFieldType;
  onChange: (fieldId: string, isChecked: boolean) => void;
  onDrop: (item: DraggedItem, position: number, zoneId: string) => void;
  onDragStart: () => void;
}

const DataField = ({ index, field, onChange, onDrop, onDragStart }: DataFieldProps): JSX.Element | null => {
  const { id, label, isChecked, child } = field;

  const [{ isDragging }, dragHandle, drag] = useDrag({
    type: DRAGGABLE_TYPES.FIELD,
    item: (): DraggedItem => {
      onDragStart();
      return { id, index, hasChild: Boolean(child) };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <div className={styles.root}>
      {isDragging ? (
        <div className={clsx(styles.dragOverlay, { [styles.withChild]: child })} />
      ) : (
        <DraggableField elementDragRef={drag} handleRef={dragHandle}>
          <FieldDropZone position={index} zoneId={id} hasNestedField={Boolean(child)} onDrop={onDrop}>
            <ToggleSwitch
              label={label}
              isChecked={isChecked}
              onChange={() => onChange(id, !isChecked)}
              className={styles.toggleSwitch}
            />
            {child ? (
              <NestedDataField
                field={child}
                parentId={id}
                index={index}
                onChange={onChange}
                onDragStart={onDragStart}
              />
            ) : null}
          </FieldDropZone>
        </DraggableField>
      )}
    </div>
  );
};

export default DataField;
