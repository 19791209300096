import clsx from 'clsx';
import styles from './Loader.module.scss';

interface LoaderProps {
  className?: string;
  small?: boolean;
  white?: boolean;
  minHeight?: number;
}

const Loader = ({ className = '', small = false, white = false, minHeight }: LoaderProps): JSX.Element => {
  return (
    <div className={clsx(styles.root)} style={{ minHeight: minHeight + 'px' }}>
      <div className={clsx(styles.loaderWrapper, className)}>
        <span className={clsx(styles.loader, { [styles.small]: small, [styles.white]: white })} />
      </div>
    </div>
  );
};

export default Loader;
