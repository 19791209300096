import clsx from 'clsx';
import { LayoutType } from 'artworks/artworksComponents/viewOptions/types';
import { getLayoutSizeClass } from 'artworks/artworksDisplaying/utils';
import styles from './ImageDraft.module.scss';

interface ImageDraftProps {
  className?: string;
  size?: number;
  layout?: LayoutType;
}

const ImageDraft = ({ className, size = 1, layout = 'list' }: ImageDraftProps): JSX.Element => {
  return <div className={clsx(styles.root, styles[getLayoutSizeClass(size, layout)], className)}>Draft</div>;
};

export default ImageDraft;
