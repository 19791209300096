import { FilterItemType, FilterItemValueType } from 'domain/SortAndFilterBasicStore/types';
import { formatDate } from 'utils/helpers';
import styles from './SortAndFilterAccordionHeader.module.scss';

const formatValue = (
  isDate: boolean | undefined,
  fromTo: FilterItemType | undefined
): FilterItemValueType | undefined => {
  return fromTo
    ? isDate
      ? formatDate((fromTo as FilterItemType).value as string)
      : (fromTo as FilterItemType).value
    : undefined;
};

export const getStringToDisplay = (
  from: FilterItemType | undefined,
  to: FilterItemType | undefined,
  like?: FilterItemType | undefined,
  isDate?: boolean
): string => {
  const fromValue = formatValue(isDate, from);
  const toValue = formatValue(isDate, to);

  switch (true) {
    case !!fromValue && !!toValue:
      return `${fromValue} - ${toValue}`;
    case !!fromValue:
      return `from ${fromValue}`;
    case !!toValue:
      return `to ${toValue}`;
    case !!like:
      return (like as FilterItemType).value.toString();
    default:
      return '';
  }
};

export const defineItemsAndGetStringToDisplay = ({
  key,
  selectedFilteringItems,
  minValueToExclude,
  maxValueToExclude,
  isDate,
}: {
  key: string;
  selectedFilteringItems: FilterItemType[];
  minValueToExclude?: number | string;
  maxValueToExclude?: number | string;
  isDate?: boolean;
}): string => {
  const yearItems = selectedFilteringItems.filter((el) => el.key.startsWith(key));
  const yearFrom = yearItems
    .filter((el) => (minValueToExclude !== undefined ? el.value !== minValueToExclude : true))
    .find((el) => el.key === `${key}${isDate ? 'After' : 'From'}`);
  const yearTo = yearItems
    .filter((el) => (maxValueToExclude !== undefined ? el.value !== maxValueToExclude : true))
    .find((el) => el.key === `${key}${isDate ? 'Before' : 'To'}`);
  const yearLike = yearItems.find((el) => el.key === `${key}Like`);
  return getStringToDisplay(yearFrom, yearTo, yearLike, isDate);
};

interface SortAndFilterAccordionHeaderProps {
  label: string;
  items?: string[];
  stringToDisplay?: string;
  showItems: boolean;
}

const SortAndFilterAccordionHeader = ({
  label,
  items,
  stringToDisplay,
  showItems,
}: SortAndFilterAccordionHeaderProps): JSX.Element => {
  return (
    <div className={styles.root}>
      {label}
      {showItems && <div className={styles.items}>{items ? items.join(', ') : stringToDisplay}</div>}
    </div>
  );
};

export default SortAndFilterAccordionHeader;
