import { Asset } from 'domain/types/Asset.types';
import styles from './ImagesList.module.scss';

interface ImageListProps {
  imageUrls: Asset[];
  artworkName: string;
}

const ImagesList = ({ imageUrls, artworkName }: ImageListProps): JSX.Element => {
  const imagesList = imageUrls?.map((imageUrl, index) => (
    <img
      src={imageUrl.presignedUrl}
      alt={'Image of ' + artworkName}
      className={styles.image}
      key={artworkName + index}
    />
  ));
  return <div className={styles.imagesList}>{imagesList}</div>;
};

export default ImagesList;
