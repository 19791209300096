import { ReactNode, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { CategoryItem } from 'consignments/consignments/sortAndFilter/constants';
import Input from 'theme/atoms/Input';
import { useOnClickOutside } from 'utils/hooks';
import IconComponent, { iconSizes, icons } from '../iconComponent/IconComponent';
import styles from './Select.module.scss';

export type Option = {
  value: string;
  label: string;
  category?: CategoryItem;
};

interface SelectProps {
  withSearchbox?: boolean;
  options: Option[];
  value: Option;
  onChange: (option: Option) => void;
  label?: string;
  sideLabel?: boolean;
  placeholder?: string;
  className?: string;
  asFormElement?: boolean;
  error?: string;
  isButtonAsText?: boolean;
  maxHeight?: number;
  endIcon?: ReactNode;
  onFocus?: (event?) => void;
  onBlur?: (event?) => void;
}

function Select({
  withSearchbox,
  options,
  value,
  placeholder,
  onChange,
  label,
  sideLabel = false,
  className = '',
  asFormElement,
  error = '',
  isButtonAsText,
  maxHeight,
  endIcon,
  onFocus,
  onBlur,
}: SelectProps): JSX.Element {
  const elementRefs = useRef({});

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [optionsToDisplay, setOptionsToDisplay] = useState(options);
  const [cursor, setCursor] = useState(-1);
  const [navigatingByArrows, setNavigatingByArrows] = useState(false);

  useEffect(() => {
    !isDropdownOpen && onBlur ? onBlur() : onFocus && onFocus();
    setSearch('');
    resetCursor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDropdownOpen]);

  useEffect(() => {
    resetCursor();
    setOptionsToDisplay(options.filter((option) => option.label.toLowerCase().includes(search.toLowerCase())));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const selectRef = useRef<HTMLDivElement>(null);
  useOnClickOutside({
    ref: selectRef,
    handler: () => {
      setIsDropdownOpen(false);
    },
  });

  const handleChange = (option: Option): void => {
    onChange(option);
    setIsDropdownOpen(false);
  };

  const resetCursor = (): void => {
    setCursor(-1);
  };

  const handleArrowUp = (): void => {
    if (cursor > 0) {
      setCursor((prevState) => prevState - 1);
      const prevItem = elementRefs.current[cursor - 1];
      prevItem && prevItem.scrollIntoView({ block: 'center' });
    } else {
      setCursor(optionsToDisplay.length - 1);
      const prevItem = elementRefs.current[optionsToDisplay.length - 1];
      prevItem && prevItem.scrollIntoView({ block: 'center' });
    }
  };

  const handleArrowDown = (): void => {
    if (cursor < optionsToDisplay.length - 1) {
      setCursor((prevState) => prevState + 1);
      const nextItem = elementRefs.current[cursor + 1];
      nextItem && nextItem.scrollIntoView({ block: 'center' });
    } else {
      setCursor(0);
      const nextItem = elementRefs.current[0];
      nextItem && nextItem.scrollIntoView({ block: 'center' });
    }
  };

  const handleEnter = (e): void => {
    if (optionsToDisplay[cursor]) {
      handleChange(optionsToDisplay[cursor]);
    }
    if (isDropdownOpen) {
      e.preventDefault();
    }
  };

  const handleKeyDown = (e): void => {
    const keyArrowUp = e.keyCode === 38;
    const keyArrowDown = e.keyCode === 40;
    const keyEnter = e.keyCode === 13;
    const keyEscape = e.keyCode === 27;

    if (keyArrowUp || keyArrowDown) {
      e.preventDefault(); // prevent window from scrolling
      setNavigatingByArrows(true);
    }

    if (keyArrowUp) {
      handleArrowUp();
    } else if (keyArrowDown) {
      handleArrowDown();
    } else if (keyEnter) {
      handleEnter(e);
    } else if (keyEscape) {
      setIsDropdownOpen(false);
    }
  };

  const handleMouseMoveOverDropdown = (): void => {
    resetCursor();
    setNavigatingByArrows(false);
  };

  const dropdownOpenerOrSearchbox = (): JSX.Element => {
    return isDropdownOpen && withSearchbox ? (
      <Input
        name="select search"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        autoFocus
        onKeyDown={handleKeyDown}
        noAutocomplete
        className={styles.inputWrapper}
      />
    ) : (
      <button
        onFocus={onFocus}
        type="button"
        onClick={() => {
          setIsDropdownOpen(!isDropdownOpen);
        }}
        className={clsx(styles.button, {
          [styles.open]: isDropdownOpen,
          [styles.errorButton]: error,
          [styles.asText]: isButtonAsText,
        })}
        onKeyDown={handleKeyDown}
      >
        {value.label ? (
          <div className={clsx(styles.selectedValue, { [styles.asFormElement]: asFormElement })}>{value.label}</div>
        ) : (
          <div className={styles.placeholder}>{placeholder}</div>
        )}

        <div className={clsx(styles.iconOpen, { [styles.iconClose]: !isDropdownOpen })}>
          <IconComponent icon={icons.expand} className={styles.icon} />
        </div>
        {endIcon && <div>{endIcon}</div>}
      </button>
    );
  };

  return (
    <div
      className={clsx(
        styles.root,
        className,
        { [styles.asText]: isButtonAsText },
        { [styles.hasSideLabel]: sideLabel }
      )}
      ref={selectRef}
    >
      {label && <div className={clsx(styles.label, { [styles.onSideLabel]: sideLabel })}>{label}</div>}
      {dropdownOpenerOrSearchbox()}
      {isDropdownOpen && (
        <div
          className={clsx(styles.dropdown, {
            [styles.withoutLabel]: !label,
            [styles.asText]: isButtonAsText,
            [styles.noItems]: optionsToDisplay.length === 0,
          })}
          style={{ maxHeight }}
          onMouseMove={handleMouseMoveOverDropdown}
        >
          {optionsToDisplay.map((option, i) => (
            <button
              type="button"
              key={option.value}
              className={clsx(styles.option, {
                [styles.selected]: value?.value === option.value,
                [styles.asText]: isButtonAsText,
                [styles.highlighted]: i === cursor,
                [styles.navigatingByArrows]: navigatingByArrows,
              })}
              onClick={() => handleChange(option)}
              ref={(ref) => {
                elementRefs.current = { ...elementRefs.current, [i]: ref };
              }}
            >
              <span>{option.label}</span>
              {value?.value === option.value ? (
                <IconComponent icon={icons.tick} size={iconSizes.small} className={styles.icon} />
              ) : null}
            </button>
          ))}
        </div>
      )}
      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
}

export default Select;
