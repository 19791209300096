import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import ArtworksSortAndFilterStore from 'artworks/artworksComponents/sortAndFilter/ArtworksSortAndFilterStore';
import { RangeEndMax, RangeEndMin } from 'theme/atoms/datePickerRange/types';
import Input from '../Input';
import DatePickerRange from '../datePickerRange';
import RadioButton from '../radioButton/RadioButton';
import styles from './RadioButtonYearFilter.module.scss';
import { KindYearsNumber, KindYearsLike, KindsYears } from './types';

interface RadioButtonYearFilterProps {
  kind: KindYearsNumber;
  kindLike: KindYearsLike;
  store: ArtworksSortAndFilterStore;
}

const RadioButtonYearFilter = ({ kind, kindLike, store }: RadioButtonYearFilterProps): JSX.Element => {
  const { updateSelectedFilteringItems, selectedFilteringItems } = store;
  const [URLSearchParams] = useSearchParams();

  const [selectedFilter, setSelectedFilter] = useState(URLSearchParams.has(kindLike) ? kindLike : kind);

  const handleFilterItemLikeChange = (text: string): void => {
    if (text.length > 0) {
      setSelectedFilter(kindLike);
    }
    const newItems = selectedFilteringItems.find((el) => el.key === kindLike)
      ? selectedFilteringItems.map((el) => (el.key === kindLike ? { key: kindLike, value: text } : el))
      : [
          ...selectedFilteringItems.filter((f) => ![`${kind}From`, `${kind}To`]?.includes(f.key)),
          { key: kindLike, value: text },
        ];
    updateSelectedFilteringItems(newItems);
  };

  const handleRadioButtonChange = (type: KindsYears, keysToClear: string[]): void => {
    setSelectedFilter(type);
    const newItems = selectedFilteringItems.filter((f) => !keysToClear?.includes(f.key));
    updateSelectedFilteringItems(newItems);
  };

  const likeItem = (
    <div className={styles.likeItem}>
      <div className={styles.text}>text search</div>
      <Input
        className={styles.search}
        placeholder="Type searched text"
        type="text"
        value={(selectedFilteringItems.find((el) => el.key === kindLike)?.value as string) || ''}
        name={`${kindLike}Input`}
        onChange={(e) => handleFilterItemLikeChange(e.target.value)}
      />
    </div>
  );

  return (
    <div className={styles.root}>
      <RadioButton
        key={kind}
        name={kind}
        label={
          <DatePickerRange
            kind={kind as KindsYears}
            store={store}
            showYearPicker
            minLabel={RangeEndMin.From}
            maxLabel={RangeEndMax.To}
          />
        }
        checked={selectedFilter === kind}
        onChange={() => handleRadioButtonChange(kind as KindsYears, [kindLike])}
      />
      <RadioButton
        key={kindLike}
        name={kindLike}
        label={likeItem}
        checked={selectedFilter === kindLike}
        onChange={() => handleRadioButtonChange(kindLike as KindsYears, [`${kind}From`, `${kind}To`])}
      />
    </div>
  );
};

export default observer(RadioButtonYearFilter);
