import { Link, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import AddPhoneForm from 'auth/components/forms/AddPhoneForm';
import { AuthMethodAction, UserIdentityType } from 'auth/types';
import { Button, TitleBlack } from 'theme/atoms';
import styles from '../../../SettingsSecurity.module.scss';

interface AddPhoneProps {
  setIsPhoneAdded: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddPhone = ({ setIsPhoneAdded }: AddPhoneProps): React.JSX.Element => {
  const navigate = useNavigate();
  const { authStore } = useStores();
  const { submitting, addUserIdentity } = authStore;

  const handleSubmit = (phoneNumber: string): void => {
    addUserIdentity({ type: UserIdentityType.Phone, value: phoneNumber })
      .then((response) => {
        if (response?.success) {
          setIsPhoneAdded(true);
        }
      })
      .catch((err) => {
        if (err?.status === 409) {
          setIsPhoneAdded(true);
          navigate(routes.SettingsSecurityPhone.path(), {
            state: { action: AuthMethodAction.Verify },
          });
          return;
        }

        navigate(routes.SettingsSecurity.path());
      });
  };

  const submitButton = (
    <Button type="submit" text="Continue" className={styles.submitButton} loading={submitting} disabled={submitting} />
  );

  return (
    <>
      <TitleBlack text="What is your phone number?" />
      <p className={styles.paragraph}>We will send you a SMS message to verify your phone number</p>

      <div className={styles.formContainer}>
        <AddPhoneForm onSubmit={handleSubmit} submitButton={submitButton} />
      </div>

      <Link
        to={routes.SettingsSecurity.path()}
        state={{ isAuthenticationMethodsOpen: true }}
        className={styles.backLink}
      >
        Back
      </Link>
    </>
  );
};

export default observer(AddPhone);
