import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { ReactComponent as NextIcon } from '../../assets/svg/next.svg';
import { ReactComponent as PreviousIcon } from '../../assets/svg/previous.svg';
import { ReactComponent as ToEndIcon } from '../../assets/svg/toEnd.svg';
import { ReactComponent as ToStartIcon } from '../../assets/svg/toStart.svg';
import styles from './Pagination.module.scss';

interface PaginationButtonsProps {
  onChange: (page: number) => void;
  pages: number;
  range: number;
  page: number;
}

interface PaginationPageSelectProps {
  onChange: (page: number) => void;
  pages: number;
  page: number;
}
export interface PaginationProps {
  pages: number;
  activePage: number;
  range?: number;
  onChangeActivePage: (page: number) => void;
}

const PaginationButtons = ({ onChange, page, pages, range }: PaginationButtonsProps): JSX.Element => {
  const pageRange = range < pages ? range : pages;
  const side = Math.floor(pageRange / 2);
  const startPage = page - side < 1 ? 1 : page > pages - side ? pages - pageRange + 1 : page - side;
  const visiblePages = Array.from({ length: pageRange }, (_, i) => i + startPage);

  return (
    <div className={styles.wrapper}>
      <button onClick={() => onChange(1)} disabled={page === 1} className={styles.arrowBtn}>
        <ToStartIcon />
      </button>
      <button onClick={() => onChange(page - 1)} disabled={page === 1} className={styles.arrowBtn}>
        <PreviousIcon />
      </button>
      <button onClick={() => onChange(startPage - 1)} disabled={visiblePages[0] === 1} className={styles.pageBtn}>
        ...
      </button>
      {visiblePages.map((p) => (
        <button key={p} onClick={() => onChange(p)} className={clsx(styles.pageBtn, { [styles.active]: p === page })}>
          {p}
        </button>
      ))}
      <button
        onClick={() => onChange(startPage + pageRange + 1)}
        disabled={page >= pages - pageRange}
        className={styles.pageBtn}
      >
        ...
      </button>
      <button onClick={() => onChange(page + 1)} disabled={page === pages} className={styles.arrowBtn}>
        <NextIcon />
      </button>
      <button onClick={() => onChange(pages)} disabled={page === pages} className={styles.arrowBtn}>
        <ToEndIcon />
      </button>
    </div>
  );
};

const PaginationPageSelect = ({ page, onChange, pages }: PaginationPageSelectProps): JSX.Element => {
  const [emptyPage, setEmptyPage] = useState(false);

  useEffect(() => {
    setEmptyPage(false);
  }, [page]);

  const handleOnChange = (value: string): void => {
    if (!value) {
      setEmptyPage(true);
    } else if (parseInt(value, 10) <= pages) {
      onChange(+value);
      setEmptyPage(false);
    }
  };

  return (
    <div>
      <span className={styles.text}>Jump to page</span>
      <input
        type="number"
        value={emptyPage ? '' : page}
        min="1"
        max={`${pages}`}
        onChange={(e) => handleOnChange(e.target.value)}
        className={styles.input}
      />
    </div>
  );
};

const Pagination = ({ pages, activePage, range = 3, onChangeActivePage }: PaginationProps): JSX.Element => {
  return (
    <div className={styles.root}>
      <PaginationButtons onChange={onChangeActivePage} pages={pages} range={range} page={activePage} />
      <PaginationPageSelect page={activePage} onChange={onChangeActivePage} pages={pages} />
    </div>
  );
};

export default Pagination;
