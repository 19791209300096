import { AxiosResponse } from 'axios';
import { UserAccountInfo } from 'domain/types/Domain.types';
import { OrganizationKind } from 'organization/types';
import { RadioTileType } from 'theme/cells/radioTiles/types';

export interface UserRegistrationData {
  email: string;
  password: string;
}

export enum UserIdentityType {
  MailAddress = 'MailAddress',
  TimeBasedOneTimePassword = 'TimeBasedOneTimePassword',
  Phone = 'Phone',
  AdminHackLogin = 'AdminHackLogin',
  CryptoVerification = 'CryptoVerification',
}

export type AuthUserIdentity = {
  id: string;
  userId: string;
  type: UserIdentityType;
  value: string;
  main: boolean;
  confirmed: boolean;
  deviceId?: string;
  createdAt: Date;
};

export interface AuthUser {
  authedBy: { id: string; type: string }[];
  exp: number;
  fresh: boolean;
  iat: number;
  id: string;
  identities: AuthUserIdentity[];
  lastAuthedAt: Date;
  roles: unknown[];
}

export interface Auth {
  token: string;
  user: AuthUser;
}

export interface AuthMethodTileProps extends RadioTileType {
  userIdentityType: UserIdentityType;
}

export interface OrganizationTileProps extends RadioTileType {
  organizationKind: OrganizationKind;
}

export enum AuthMethodAction {
  Add,
  Verify,
  Delete,
}

export type UserIdentityParams = {
  type: UserIdentityType;
  value: string;
  main: boolean;
  deviceId?: string;
  verifyToken?: string;
};

export interface TOTPSecretAndQRCode {
  secret: string;
  qr: string;
}

export type LoginWithTokenParams = {
  token: string;
  email?: string;
  phoneNumber?: string;
  userIdentityId?: string;
};

export type LoginReturnedTokens = {
  accessToken: string;
  refreshToken: string;
};

export type UserIdentityWithTokens = { identity: AuthUserIdentity; accessToken: string; refreshToken: string };

export type LoginReturn = Promise<AxiosResponse<LoginReturnedTokens>>;
export type UserReturn = Promise<AxiosResponse<UserAccountInfo>>;
export type LoginToTOTPReturn = Promise<AxiosResponse<TOTPSecretAndQRCode>>;
export type UserIdentityReturn = Promise<AxiosResponse<UserIdentityWithTokens>>;
