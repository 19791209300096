import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import ConsignmentDetails from 'consignments/consignment/components/consignmentDetails';
import { isOwnerApprovalNeeded } from 'consignments/domain';
import Loader from 'theme/atoms/loader';
import NoDataToDisplay from 'theme/atoms/noDataToDisplay';
import SlidingPanel from 'theme/atoms/slidingPanel';
import ConsignedRepresentative from '../consignedRepresentative/ConsignedRepresentative';
import styles from './ConsignmentPanel.module.scss';

interface ConsignmentPanelProps {
  isOpen: boolean;
  onClose: () => void;
}

const ConsignmentPanel = ({ isOpen, onClose }: ConsignmentPanelProps): JSX.Element => {
  const { consignmentStore } = useStores();
  const { consignment, loading, shouldPanelBeBlockedFromClosing } = consignmentStore;

  const noConsignmentRender = loading ? (
    <Loader minHeight={200} />
  ) : (
    <NoDataToDisplay copy="No consignment to display." height={100} />
  );

  return (
    <SlidingPanel
      title="Consignment details"
      isOpen={isOpen}
      onClose={onClose}
      maxWidth
      blockClosing={shouldPanelBeBlockedFromClosing}
    >
      {!consignment || loading ? (
        noConsignmentRender
      ) : (
        <>
          <div className={styles.consignee}>
            {!consignment.consigneeContact?.self && (
              <ConsignedRepresentative consigneeContact={consignment.consigneeContact} />
            )}
          </div>

          <div className={styles.background}>
            <div className={styles.consignmentDetails}>
              {/* TODO: temporary not implemented consignee side  */}
              <ConsignmentDetails withoutActions={isOwnerApprovalNeeded(consignment)} />
            </div>
          </div>
        </>
      )}
    </SlidingPanel>
  );
};

export default observer(ConsignmentPanel);
