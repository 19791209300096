import { FormEvent, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import { FormValidation } from 'domain/types';
import { AssetWithKey } from 'domain/types/Asset.types';
import { Button, TitleBlack } from 'theme/atoms';
import FormError from 'theme/atoms/formError';
import Loader from 'theme/atoms/loader';
import { getKeyFromUrl } from 'theme/cells/assetUpload/utils';
import { useFormValidation } from 'utils/hooks';
import styles from '../AddArtwork.module.scss';
import {
  AddArtworkSteps,
  AddArtworkTopbar,
  ArtworkDetailsForm,
  CancelAddArtwork,
  UndisclosedDocuments,
} from '../components';
import AssetsUpload from '../components/assetsUpload';
import { ARTWORK_STEPS, REQUIRED_FORM_FIELDS } from '../constants';
import NoArtworkFound from './components/NoArtworkFound';

const CURRENT_STEP = ARTWORK_STEPS[1];
const NEXT_STEP = ARTWORK_STEPS[2];

const FORM_VALIDATIONS: FormValidation = REQUIRED_FORM_FIELDS.reduce(
  (prevFields, currentField) => {
    return { ...prevFields, [`${currentField}Required`]: '' };
  },
  { imageUrlsRequired: '', artistResellRightsIsPercentages: '' }
);

const AddArtworkDetails = (): JSX.Element => {
  const { addArtworkStore } = useStores();
  const {
    fetching,
    artworkForm,
    saveDraft,
    updateArtworkForm,
    setImages,
    fetchArtworkAndSetItToEdition,
    artworkToEdit,
    setArtworkToEdit,
  } = addArtworkStore;
  const navigate = useNavigate();
  const { artworkId } = useParams();

  useEffect(() => {
    setArtworkToEdit(true);
    if (artworkId && artworkId !== 'new') fetchArtworkAndSetItToEdition(artworkId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artworkId]);

  const formIsNotValid = [...REQUIRED_FORM_FIELDS, 'imageUrls'].filter((field) => !artworkForm[field]).length > 0;

  const { cleanValidationErrors, formValidationMessages, handleValidation, validation, areThereAnyValidationErrors } =
    useFormValidation(FORM_VALIDATIONS);

  if (!artworkToEdit) return <NoArtworkFound />;

  const validateForm = (): boolean => {
    let isValid = true;
    const ARRisNotValid = Boolean(
      artworkForm.artistResellRightsPercent &&
        (artworkForm.artistResellRightsPercent < 0 || artworkForm.artistResellRightsPercent > 100)
    );
    handleValidation(`imageUrlsRequired`, ' (required)', artworkForm.imageUrls.length === 0);
    handleValidation(
      'artistResellRightsIsPercentages',
      'Artist Resell Right should be number between 0 and 100',
      ARRisNotValid
    );
    isValid = isValid && artworkForm.imageUrls.length > 0;

    REQUIRED_FORM_FIELDS.forEach((field) => {
      const isEmpty = artworkForm[field] === null || artworkForm[field].length === 0;
      handleValidation(`${field}Required`, formValidationMessages.REQUIRED, isEmpty);

      isValid = isValid && !isEmpty && !ARRisNotValid;
    });

    return isValid;
  };

  const onContinue = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    if (validateForm() && artworkId) {
      updateArtworkForm('draftCreationStep', NEXT_STEP.id);
      saveDraft(artworkId).then((id) => {
        if (id) navigate(routes.AddArtworkOwnership.path(id));
      });
    } else return;
  };

  const updateForm = (name: string, value: string | number): void => {
    updateArtworkForm(name, value);
    cleanValidationErrors([
      `${name}Required`,
      ...(name === 'artistResellRightsPercent' ? [`artistResellRightsIsPercentages`] : []),
    ]);
  };

  const images = (
    <AssetsUpload
      assetsFromParent={artworkForm.imageUrls?.map((file) => {
        return {
          ...file,
          key: getKeyFromUrl(file.presignedUrl),
        };
      })}
      onUpdateAssets={(images: AssetWithKey[]) => setImages(images)}
      instruction="Upload artwork visual representation"
      title="Artwork cover images"
      assetsType="image"
      onClickAdd={() => cleanValidationErrors(['imageUrlsRequired'])}
      validationError={<span className={styles.error}>{validation.imageUrlsRequired}</span>}
    />
  );

  const buttons = (
    <div className={styles.buttonsWrapper}>
      <div className={styles.formError}>
        <FormError showError={areThereAnyValidationErrors} />
      </div>
      <Button text="Continue to ownership info" maxWidth onClick={onContinue} />
      <CancelAddArtwork artworkId={artworkId || 'new'} currentStep={formIsNotValid ? CURRENT_STEP.id : undefined} />
      <Button
        text="Back"
        maxWidth
        onClick={() => artworkId && navigate(routes.AddArtworkAuthor.path(artworkId))}
        buttonType="secondary"
      />
    </div>
  );

  return (
    <>
      {fetching ? (
        <Loader minHeight={150} />
      ) : (
        <>
          <AddArtworkTopbar step={CURRENT_STEP.label} />
          <TitleBlack text="Register artwork" />
          <div className={styles.root}>
            <div className={styles.mainColumn}>
              <ArtworkDetailsForm onChange={updateForm} validation={validation} />
              {images}
              <UndisclosedDocuments />
              {buttons}
              <div>Info about cost of the service will be here.</div>
            </div>
            <AddArtworkSteps step={CURRENT_STEP.label} />
          </div>
        </>
      )}
    </>
  );
};

export default observer(AddArtworkDetails);
