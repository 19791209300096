import { SortItemType } from 'domain/SortAndFilterBasicStore/types';
import { Option } from 'theme/atoms/Select/Select';

export const SORT_OPTIONS: Option[] = [
  {
    label: 'Artwork author',
    value: 'ArtworkAuthor',
  },
  {
    label: 'Artwork name',
    value: 'ArtworkTitle',
  },
  {
    label: 'Transaction created at',
    value: 'CreatedAt',
  },
];

export const DEFAULT_SORTING_ITEM: SortItemType = { ...SORT_OPTIONS[0], dir: 'Asc', checked: true };
