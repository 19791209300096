import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import Big from 'big.js';
import { ReactComponent as DownloadIcon } from 'theme/assets/svg/export.svg';
import Chip from 'theme/atoms/chip';
import NoDataToDisplay from 'theme/atoms/noDataToDisplay';
import { formatDate, formatPrice } from 'utils/helpers';
import styles from './BillingHistory.module.scss';

const BillingHistory = (): JSX.Element => {
  const { billingStore } = useStores();
  const { recentInvoices, loadingInvoices } = billingStore;

  if (loadingInvoices) return <></>;

  const InvoicesList = (): JSX.Element => {
    if (!recentInvoices || !recentInvoices?.length) {
      return <NoDataToDisplay copy="No invoices to display." height={100} />;
    } else {
      return (
        <div className={styles.items}>
          {recentInvoices.map((el) => {
            return (
              <>
                <div key={el.id + 'numberAndDate'} className={styles.numberAndDate}>
                  <div className={styles.number}>Invoice {el.number || el.id}</div>
                  <div className={styles.date}>Created {formatDate(el.created * 1000)}</div>
                </div>
                <div key={el.id + 'isPaid'} className={styles.isPaid}>
                  <Chip variant={el.paid ? undefined : 'error'}>{el.paid ? 'Paid' : 'Unpaid'}</Chip>
                </div>
                <div key={el.id + 'price'} className={styles.price}>
                  {formatPrice(Big(el.amount_due).div(100), el.currency, 2)}
                </div>
                <div key={el.id + 'downloadIcon'} className={styles.downloadIcon}>
                  <a href={el.invoice_pdf}>
                    <DownloadIcon />
                  </a>
                </div>
              </>
            );
          })}
        </div>
      );
    }
  };

  return (
    <div className={styles.root}>
      <h2>Billing history</h2>
      <InvoicesList />
    </div>
  );
};

export default observer(BillingHistory);
