import { ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import styles from './RadioButton.module.scss';

interface RadioButtonProps {
  name: string;
  label: ReactNode;
  checked: boolean;
  disabled?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const RadioButton = observer(({ name, checked, disabled, label, onChange }: RadioButtonProps): JSX.Element => {
  return (
    <div className={styles.inputGroup}>
      <input
        type="radio"
        id={name}
        name={name}
        value={name}
        className={styles.input}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
      />
      <label htmlFor={name} className={styles.label}>
        {label}
      </label>
    </div>
  );
});

export default RadioButton;
