import { action, computed, makeObservable } from 'mobx';
import { RootStore } from 'RootStore';
import SortAndFilterBasicStore, {
  SortAndFilterBasicStoreProps as BasicStoreProps,
} from 'domain/SortAndFilterBasicStore/SortAndFilterBasicStore';
import { NetworkStatusEnum } from 'network/types';
import { NETWORK_CATEGORIES_WITH_STATUSES } from './constants';
import { FilterParams } from './types';

const ExtendedStoreProps = {
  networkCategoryStatuses: computed,
  filterByAsParams: action,
};

class NetworkSortAndFilterStore extends SortAndFilterBasicStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    super(rootStore);
    this.rootStore = rootStore;
    makeObservable(this, { ...BasicStoreProps, ...ExtendedStoreProps });
  }

  get networkCategoryStatuses(): readonly (keyof typeof NetworkStatusEnum)[] {
    const categoryWithStatuses = NETWORK_CATEGORIES_WITH_STATUSES.find((category) => category.id === this.categoryId);

    return categoryWithStatuses?.status || [];
  }

  filterByAsParams = (): FilterParams => {
    const filterParams = {
      status: this.networkCategoryStatuses as (keyof typeof NetworkStatusEnum)[],
    };

    Object.keys(filterParams).forEach((key) => {
      if (!filterParams[key]) delete filterParams[key];
    });

    return filterParams;
  };
}

export default NetworkSortAndFilterStore;
