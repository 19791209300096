import dayjs from 'dayjs';
import { VIEW_OPTIONS_STORAGE_PREFIX } from './artworksComponents/viewOptions/constants';
import { ViewOptionsData } from './artworksComponents/viewOptions/types';

const LOCAL_STORAGE_VIEW_OPTIONS = `${VIEW_OPTIONS_STORAGE_PREFIX}${location.hostname}`;

export const saveArtworksViewOptions = (viewOptionsData: ViewOptionsData): void => {
  localStorage.setItem(LOCAL_STORAGE_VIEW_OPTIONS, JSON.stringify(viewOptionsData));
};

export const getArtworksViewOptions = (): ViewOptionsData | null => {
  const viewOptionsData = localStorage.getItem(LOCAL_STORAGE_VIEW_OPTIONS);
  return viewOptionsData ? JSON.parse(viewOptionsData) : null;
};

export const getParsedYearsFromUnixTimestamp = (yearUnixTimestamp: number): string => {
  return dayjs.unix(yearUnixTimestamp).year().toString();
};
