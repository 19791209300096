import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { ConsignmentShowArtwork } from 'consignments/types';
import { SortItemType } from 'domain/SortAndFilterBasicStore/types';
import { SELL_ARTWORK_COPY } from 'domain/constants';
import Accordion from 'theme/atoms/Accordion';
import DatePickerRange from 'theme/atoms/datePickerRange';
import { Kind, KindDates, RangeEndMax, RangeEndMin } from 'theme/atoms/datePickerRange/types';
import FilterChecklist from 'theme/atoms/filterChecklist';
import SlidingPanel from 'theme/atoms/slidingPanel';
import SortAndFilterAccordionHeader, {
  defineItemsAndGetStringToDisplay,
} from 'theme/atoms/sortAndFilterAccordionHeader/SortAndFilterAccordionHeader';
import SortItem from 'theme/atoms/sortItem';
import SortAndFilterAccordionForBEBasedList from 'theme/cells/sortAndFilterAccordionForBEBasedList';
import styles from './SortAndFilter.module.scss';
import { CATEGORIES, CategoryItem, SORT_OPTIONS } from './constants';

export const getDefaultSortingItem = (category: CategoryItem): SortItemType => {
  return { ...SORT_OPTIONS.filter((el) => el.category === category)[0], dir: 'Asc', checked: true };
};

interface SortAndFilterPanelProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  updateSelectedSortingItems: (index: number, updatedItem: SortItemType) => void;
  removeSelectedSortingItem: (updatedItem: SortItemType) => void;
}

const SortAndFilterPanel = ({
  isOpen,
  onClose,
  onSubmit,
  updateSelectedSortingItems,
  removeSelectedSortingItem,
}: SortAndFilterPanelProps): JSX.Element => {
  const { consignmentsSortAndFilterStore } = useStores();
  const {
    counts,
    selectedSortingItems,
    selectedFilteringItems,
    resetSelectedFilteringAndSortingItems,
    resetGroupBy,
    resetActivePage,
  } = consignmentsSortAndFilterStore;

  const itemsOpenByDefault = CATEGORIES.map((category) => `sort${category}By`);
  const [openAccordionItems, setOpenAccordionItems] = useState<string[]>(itemsOpenByDefault);

  const getSortByElements = (): JSX.Element[] => {
    return CATEGORIES.map((cat: CategoryItem) => (
      <Accordion.Item
        id={`sort${cat}By`}
        header={
          <SortAndFilterAccordionHeader
            label={`Sort ${cat} by`}
            showItems={!openAccordionItems.includes(`sort${cat}By`)}
            items={selectedSortingItems.filter((el) => el.category === cat && el.checked).map((el) => el.label)}
          />
        }
        key={cat}
      >
        {selectedSortingItems
          .filter((s) => s.category === cat)
          .map((item, index) => {
            return (
              <SortItem
                key={item.value}
                item={item}
                onChange={(item) => updateSelectedSortingItems(index, item)}
                onRemove={() => removeSelectedSortingItem(item)}
                options={SORT_OPTIONS.filter((o) => o.category === cat)}
                store={consignmentsSortAndFilterStore}
                defaultItem={getDefaultSortingItem(cat)}
                category={cat}
              />
            );
          })}
        {/* <Button
          text="add sort"
          onClick={() => addEmptySelectedSortingItem(cat)}
          disabled={Boolean(selectedSortingItems.find((s) => s.category === cat))}
          buttonType="tertiary"
          iconStart={<AddIcon />}
          uppercase={false}
        /> */}
      </Accordion.Item>
    ));
  };

  const permissionToShowElement = counts?.showArtworkCounts && counts.showArtworkCounts.length > 1 && (
    <Accordion.Item
      id="permissionToShow"
      header={
        <SortAndFilterAccordionHeader
          label="permission to show"
          showItems={!openAccordionItems.includes('permissionToShow')}
          items={Object.entries(ConsignmentShowArtwork)
            .filter(([key]) => Boolean(selectedFilteringItems.find((f) => f.value === key)))
            .map(([_key, value]) => value)}
        />
      }
    >
      <FilterChecklist
        name="showArtwork"
        listItems={Object.entries(ConsignmentShowArtwork).map(([key, value]) => ({
          value: key,
          label: value,
          count: counts?.showArtworkCounts.find((c) => c.showArtwork === key)?.count,
        }))}
        store={consignmentsSortAndFilterStore}
      />
    </Accordion.Item>
  );

  const sellArtworkElement = counts?.sellArtworkCounts && counts.sellArtworkCounts.length > 1 && (
    <Accordion.Item
      id="sellArtwork"
      header={
        <SortAndFilterAccordionHeader
          label={SELL_ARTWORK_COPY}
          showItems={!openAccordionItems.includes('sellArtwork')}
          items={selectedFilteringItems.filter((f) => f.key === 'sellArtwork').map((el) => (el.value ? 'yes' : 'no'))}
        />
      }
    >
      <FilterChecklist
        name="sellArtwork"
        listItems={[
          {
            value: true,
            label: 'yes',
            count: counts?.sellArtworkCounts.find((el) => el.sellArtwork)?.count,
          },
          {
            value: false,
            label: 'no',
            count: counts?.sellArtworkCounts.find((el) => !el.sellArtwork)?.count,
          },
        ]}
        store={consignmentsSortAndFilterStore}
      />
    </Accordion.Item>
  );

  const creationDateElement = (): JSX.Element => {
    const datesToDislpay = defineItemsAndGetStringToDisplay({
      key: 'created',
      selectedFilteringItems,
      isDate: true,
    });
    return (
      <Accordion.Item
        id="creationDate"
        header={
          <SortAndFilterAccordionHeader
            label="Creation Date"
            showItems={!openAccordionItems.includes('creationDate')}
            stringToDisplay={datesToDislpay}
          />
        }
      >
        <DatePickerRange
          kind={KindDates.created as Kind}
          store={consignmentsSortAndFilterStore}
          minLabel={RangeEndMin.After}
          maxLabel={RangeEndMax.Before}
        />
      </Accordion.Item>
    );
  };

  const expirationDateElement = (): JSX.Element => {
    const datesToDislpay = defineItemsAndGetStringToDisplay({
      key: 'expire',
      selectedFilteringItems,
      isDate: true,
    });
    return (
      <Accordion.Item
        id="expirationDate"
        header={
          <SortAndFilterAccordionHeader
            label="Expiration Date"
            showItems={!openAccordionItems.includes('expirationDate')}
            stringToDisplay={datesToDislpay}
          />
        }
      >
        <DatePickerRange
          kind={KindDates.expire as Kind}
          store={consignmentsSortAndFilterStore}
          minLabel={RangeEndMin.After}
          maxLabel={RangeEndMax.Before}
        />
      </Accordion.Item>
    );
  };

  return (
    <SlidingPanel
      title="Sort and filter"
      onClose={onClose}
      onReset={() => {
        resetSelectedFilteringAndSortingItems();
        resetGroupBy();
        resetActivePage();
      }}
      isOpen={isOpen}
      onSubmit={onSubmit}
      btnSubmitText="Show results"
    >
      <div className={styles.root}>
        <Accordion itemsOpenByDefault={itemsOpenByDefault} onChange={setOpenAccordionItems}>
          {/* <Accordion.Item id='groupBy' header="Group by"> // TODO not implemented on BE yet
          <Tiles list={GROUP_BY} selectedItem={groupBy} setSelectedItem={setGroupBy} />
        </Accordion.Item> */}

          {getSortByElements()}
          <SortAndFilterAccordionForBEBasedList
            field="consignee"
            name="consigneeContactId"
            counts={counts}
            openAccordionItems={openAccordionItems}
            store={consignmentsSortAndFilterStore}
          />
          <SortAndFilterAccordionForBEBasedList
            field="consignor"
            name="consignorContactId"
            counts={counts}
            openAccordionItems={openAccordionItems}
            store={consignmentsSortAndFilterStore}
          />
          {permissionToShowElement}
          {sellArtworkElement}

          {/* <Accordion.Item id="price" header="Price"> // TODO not implemented on BE yet
          <PriceRange store={consignmentsSortAndFilterStore} />
        </Accordion.I> */}

          {creationDateElement()}
          {expirationDateElement()}
        </Accordion>
      </div>
    </SlidingPanel>
  );
};

export default observer(SortAndFilterPanel);
