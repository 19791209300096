import { SortItemType } from 'domain/SortAndFilterBasicStore/types';
import { Option } from 'theme/atoms/Select/Select';

export const SORT_OPTIONS: Option[] = [
  {
    label: 'Author',
    value: 'author',
  },
  {
    label: 'Title',
    value: 'name',
  },
];

export const DEFAULT_SORTING_ITEM: SortItemType = { ...SORT_OPTIONS[0], dir: 'Asc', checked: true };

export const SIZE_MIN_VALUE = 0;
export const SIZE_MAX_VALUE = 1000;
export const WEIGHT_MIN_VALUE = 0;
export const WEIGHT_MAX_VALUE = 1000;
