import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import Big from 'big.js';
import { Currency } from 'domain/types/Domain.types';
import { ReactComponent as CheckedIcon } from 'theme/assets/svg/checkMark.svg';
import Avatar from 'theme/atoms/avatar/Avatar';
import { formatPrice } from 'utils/helpers';
import styles from './BillingCard.module.scss';

interface BillingCardCommonProps {
  className?: string;
  children?: ReactNode;
}

const BillingCard = ({ className, children }: BillingCardCommonProps): JSX.Element => {
  return <div className={clsx(styles.root, className)}>{children}</div>;
};

interface BillingCardHeaderProps extends BillingCardCommonProps {
  title: string;
}

const BillingCardHeader = ({ children, title, className }: BillingCardHeaderProps): JSX.Element => {
  return (
    <div className={clsx(styles.billingCardHeader, className)}>
      <p className={styles.title}>{title}</p>
      {children}
    </div>
  );
};

interface BillingCardTransactionSiteAvatarProps extends BillingCardCommonProps {
  organizationId: string;
  organizationName: string;
  label?: string;
}

const BillingCardTransactionSiteAvatar = ({
  organizationId,
  organizationName,
  label,
  className,
}: BillingCardTransactionSiteAvatarProps): JSX.Element => {
  return (
    <div className={clsx(styles.avatar, className)}>
      {label && <p>{label}</p>}
      <Avatar id={organizationId} />
      <div className={styles.organizationName}>{organizationName}</div>
    </div>
  );
};

interface BillingCardTransactionIDProps extends BillingCardCommonProps {
  id: string;
  navigateTo?: string;
}

const BillingCardTransactionID = ({ id, navigateTo, className }: BillingCardTransactionIDProps): JSX.Element => {
  return (
    <div className={clsx(styles.transactionId, className)}>
      <p>Transaction:</p>
      {navigateTo ? (
        <Link to={navigateTo}>
          <span>{id}</span>
        </Link>
      ) : (
        <span>{id}</span>
      )}
    </div>
  );
};

const BillingCardBody = ({ children, className }: BillingCardCommonProps): JSX.Element => {
  return <div className={className}>{children}</div>;
};

interface BillingSummaryRowProps {
  label: string;
  value: Big | undefined | null;
  currency: Currency;
  className?: string;
  withPaidIcon?: boolean;
}

const BillingSummaryRow = ({
  label,
  value,
  currency,
  className,
  withPaidIcon = false,
}: BillingSummaryRowProps): JSX.Element => {
  return (
    <div className={clsx(styles.billingSummaryWrapper, className)}>
      <p>{label}</p>
      <span>
        {value ? formatPrice(Big(value), currency, 2) : '0'} {withPaidIcon && <CheckedIcon />}
      </span>
    </div>
  );
};

interface BillingSummaryWrapperProps extends BillingCardCommonProps {
  amount: Big;
  childrenClassName?: string;
  currency: Currency;
  label?: string;
  sumupTitle: string;
  withPaidIcon?: boolean;
}

const BillingSummaryWrapper = ({
  amount,
  children,
  className,
  childrenClassName,
  currency,
  label,
  sumupTitle,
  withPaidIcon,
}: BillingSummaryWrapperProps): JSX.Element => {
  return (
    <div className={clsx(styles.billingSummary, className)}>
      {label && <h2 className={styles.billingLabel}>{label}</h2>}
      {children && <div className={clsx(styles.childrenContainer, childrenClassName)}>{children}</div>}
      <BillingSummaryRow
        label={sumupTitle}
        value={amount}
        currency={currency}
        withPaidIcon={withPaidIcon}
        className={styles.wrapper}
      />
    </div>
  );
};

BillingCard.Header = BillingCardHeader;
BillingCard.Wrapper = BillingSummaryWrapper;
BillingCard.TransactionSiteAvatar = BillingCardTransactionSiteAvatar;
BillingCard.Body = BillingCardBody;
BillingCard.TransactionID = BillingCardTransactionID;
BillingCard.SummaryRow = BillingSummaryRow;

export default BillingCard;
