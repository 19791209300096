import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { routes } from 'app/routes/paths.const';
import styles from 'auth/Auth.module.scss';
import { Button, Subtitle, TitleBlack } from 'theme/atoms';
import Breadcrumbs, { Breadcrumb } from 'theme/atoms/Breadcrumbs';
import Topbar from 'theme/atoms/Topbar';
import { useTitle } from 'utils/hooks';
import SendNewEmail from '../../components/SendNewEmail';

const BREADCRUMBS: Breadcrumb[] = [
  {
    label: 'Success',
  },
];

function SignupEmailChanged(): JSX.Element {
  useTitle('Sign up - success');

  return (
    <div className={clsx(styles.root, styles.oneColumnLayout)}>
      <Topbar>
        <Breadcrumbs breadcrumbs={BREADCRUMBS} />
      </Topbar>
      <div className={styles.innerWrapper}>
        <TitleBlack text="Email changed" />
        <Subtitle text="Check your email and verify your email address to log in to your account."></Subtitle>
        <Button text="Log in now" buttonType="textButton" to={routes.Login.path()} />
        <SendNewEmail />
      </div>
    </div>
  );
}

export default observer(SignupEmailChanged);
