import { Consignment, ConsignmentShowArtwork } from 'consignments/types';
import { SELL_ARTWORK_COPY } from 'domain/constants';
import SectionHeader from 'theme/atoms/Titles/SectionHeader';
import IconComponent, { icons, iconSizes } from 'theme/atoms/iconComponent';
import styles from './Permissions.module.scss';

interface PermissionsProps {
  consignment: Consignment;
}

const getPermissionElement = (text: string): JSX.Element => {
  return (
    <div className={styles.permission}>
      <IconComponent icon={icons.tick} size={iconSizes.small} />
      {text}
    </div>
  );
};

const Permissions = ({ consignment }: PermissionsProps): JSX.Element => {
  const { showArtwork, sellArtwork } = consignment;

  const showArtworkRender = getPermissionElement(
    `${ConsignmentShowArtwork[showArtwork]} permission to show the artwork`
  );

  const sellArtworkRender = sellArtwork && getPermissionElement(SELL_ARTWORK_COPY);

  return (
    <>
      <SectionHeader title="Permissions" />
      <div className={styles.permissions}>
        {showArtworkRender}
        {sellArtworkRender}
      </div>
    </>
  );
};

export default Permissions;
