import clsx from 'clsx';
import { Asset } from 'domain/types/Asset.types';
import { ReactComponent as AssetIcon } from 'theme/assets/svg/file.svg';
import { getMB } from 'theme/cells/assetUpload/utils';
import { downloadAs, formatDate } from 'utils/helpers';
import styles from './AssetsList.module.scss';

interface AssetListProps {
  assetsUrls: Asset[] | undefined;
}

const AssetsList = ({ assetsUrls }: AssetListProps): JSX.Element => {
  if (!assetsUrls) return <></>;

  const displayDocument = ({ name, lastModified, size, presignedUrl }: Asset): JSX.Element => (
    <div key={name} className={styles.asset}>
      <button className={clsx(styles.assetDetails, styles.left)} onClick={() => downloadAs(presignedUrl, name)}>
        <AssetIcon />
        <span>{name}</span>
      </button>
      <div className={clsx(styles.assetDetails, styles.right)}>
        <span>{formatDate(lastModified)}</span>
        <span>{getMB(size).toFixed(2)} MB</span>
      </div>
    </div>
  );

  return <div className={styles.root}>{assetsUrls.map((asset) => displayDocument(asset))}</div>;
};

export default AssetsList;
