import { ArtworkState } from 'artworks/types';

export const ARTWORK_DRAFT_CATEGORIES_WITH_STATUSES_AND_STATES = [
  { id: 'all', label: 'All', state: [], status: [] },
  {
    id: 'action-required',
    label: 'Action required',
    state: [],
    status: ['Draft', 'DraftAwaitingApproval'],
  },
  {
    id: 'awaiting-approval',
    label: 'Awaiting for others',
    state: [ArtworkState.DraftAwaitingApproval],
    status: ['DraftApproved'],
  },
  {
    id: 'closed',
    label: 'Closed',
    state: [ArtworkState.Certified, ArtworkState.DraftRejected],
    status: [],
  },
] as const;

export const DEFAULT_ARTWORK_DRAFT_CATEGORY = ARTWORK_DRAFT_CATEGORIES_WITH_STATUSES_AND_STATES[0].id;
