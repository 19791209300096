import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import styles from 'auth/Auth.module.scss';
import VerifyPhoneForm from 'auth/components/forms/VerifyPhoneForm';
import { UserIdentityType } from 'auth/types';
import { StoreActionReturn } from 'domain/types/StoreActionReturn';
import { TitleBlack, Subtitle, Button } from 'theme/atoms';
import Breadcrumbs, { Breadcrumb } from 'theme/atoms/Breadcrumbs';
import Topbar from 'theme/atoms/Topbar';
import { useIsAccessTokenFresh } from 'utils/hooks';

const BREADCRUMBS: Breadcrumb[] = [{ label: 'Authentication method' }, { label: 'Step 2: SMS Code' }];

const LoginVerifyPhone = (): JSX.Element => {
  const navigate = useNavigate();
  const isTokenFresh = useIsAccessTokenFresh();

  const { authStore } = useStores();
  const {
    submitting,
    getUserIdentities,
    userIdentities,
    confirmUserIdentity,
    sendUserIdentityConfirmationCode,
    saveTokensToStore,
    saveTokensToLocalStorage,
  } = authStore;

  const userIdentity = userIdentities?.find((identity) => identity.type === UserIdentityType.Phone);

  useEffect(() => {
    if (!isTokenFresh) {
      navigate(routes.Login.path());
      return;
    }

    getUserIdentities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (confirmationCode: string): void => {
    confirmUserIdentity(UserIdentityType.Phone, confirmationCode).then((response) => {
      if (response?.success) {
        saveTokensToStore(response.data);
        saveTokensToLocalStorage(response.data);
        navigate(routes.LoginRecoveryCodes.path(), { state: { canGenerateRecoveryCodes: true } });
      }
    });
  };

  const handleResendCode = (): Promise<StoreActionReturn | void> => {
    if (!userIdentity) return Promise.reject();
    return sendUserIdentityConfirmationCode(userIdentity.id);
  };

  return (
    <div className={clsx(styles.root, styles.oneColumnLayout)}>
      <Topbar>
        <Breadcrumbs breadcrumbs={BREADCRUMBS} />
      </Topbar>
      <div className={styles.innerWrapper}>
        <TitleBlack text="SMS authentication code" />
        <Subtitle>
          Enter your one-time security code we sent to your
          <br />
          phone number: <span className={styles.inlinePhoneNumber}>{userIdentity?.value}</span>
        </Subtitle>

        <VerifyPhoneForm
          submitButton={<Button text="Continue" loading={submitting} disabled={submitting} type="submit" maxWidth />}
          onSubmit={handleSubmit}
          onResendCode={handleResendCode}
        />
      </div>
    </div>
  );
};

export default observer(LoginVerifyPhone);
