import { DataFieldType } from './types';

export const VIEW_OPTIONS_STORAGE_PREFIX = 'art_network__view_options_';

export const LAYOUTS = [
  // 'tree', // TODO uncomment when ready
  'list',
  // 'details', // TODO uncomment when ready
  'grid',
] as const;

export const INITIAL_LAYOUT = LAYOUTS[0];

export const SIZES_LABELS = ['small', 'medium', 'large'];

export const INITIAL_SIZE_INDEX = 1;

export const INITIAL_DATA_FIELDS: readonly DataFieldType[] = [
  { id: 'image', label: 'Image', isChecked: true },
  {
    id: 'author',
    label: 'Author',
    isChecked: true,
    child: {
      id: 'nameYear',
      label: 'Title, year',
      isChecked: true,
    },
  },

  { id: 'id', label: 'ID', isChecked: true },
  // { id: 'tags', label: 'Tags', isChecked: true }, // TODO uncomment when we support adding tags
  { id: 'visibilityIcon', label: 'Visibility icon', isChecked: false },
  { id: 'statusIcon', label: 'Status icon', isChecked: false },
  { id: 'status', label: 'Status', isChecked: false },
] as const;

export const INITIAL_DATA_FIELDS_TOTAL_NUMBER = INITIAL_DATA_FIELDS.reduce(
  (totalFields, field) => totalFields + (field.child ? 2 : 1),
  0
);

export const DRAGGABLE_TYPES = {
  FIELD: 'FIELD',
  NESTED_FIELD: 'NESTED_FIELD',
};
