import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { InvoiceState } from 'domain/types/Invoices.types';
import { ReactComponent as InvoiceAcceptedIcon } from 'theme/atoms/invoiceStateCheckIcon/assets/invoice-accepted.svg';
import { ReactComponent as InvoicePendingUploadIcon } from 'theme/atoms/invoiceStateCheckIcon/assets/invoice-not-uploaded.svg';
import { ReactComponent as InvoicePaidIcon } from 'theme/atoms/invoiceStateCheckIcon/assets/invoice-paid.svg';
import { ReactComponent as InvoiceRejectedIcon } from 'theme/atoms/invoiceStateCheckIcon/assets/invoice-rejected.svg';
import { ReactComponent as InvoicePendingBankDecisionIcon } from 'theme/atoms/invoiceStateCheckIcon/assets/invoice-uploaded-not-accepted.svg';
import Tooltip from 'theme/atoms/tooltip';
import { formatDate } from 'utils/helpers';
import styles from './InvoiceStateCheckIcon.module.scss';

const STATE_TEXT_AND_ICON_MAP = {
  [InvoiceState.Accepted]: {
    icon: InvoiceAcceptedIcon,
    text: 'Accepted',
  },
  [InvoiceState.PendingUpload]: {
    icon: InvoicePendingUploadIcon,
    text: 'Waiting for upload',
  },
  [InvoiceState.PendingBankDecision]: {
    icon: InvoicePendingBankDecisionIcon,
    text: 'In verification',
  },
  [InvoiceState.Rejected]: {
    icon: InvoiceRejectedIcon,
    text: 'Rejected',
  },
  [InvoiceState.Paid]: {
    icon: InvoicePaidIcon,
    text: 'Paid',
  },
};

const iconMiddlePoint = (state: InvoiceState): number => {
  switch (true) {
    case state === InvoiceState.PendingUpload:
      return 0.423;
    case state === InvoiceState.PendingBankDecision:
      return 0.35;
    case state === InvoiceState.Rejected:
    case state === InvoiceState.Accepted:
    case state === InvoiceState.Paid:
      return 0.3;
    default:
      return 0.1;
  }
};

interface InvoiceStateCheckIconProps {
  className?: string;
  createdDate?: Date;
  isStateTextUnderIcon?: boolean;
  state: InvoiceState;
  withStateText?: boolean;
  timeLineLeftElement?: JSX.Element;
  timeLineRightElement?: JSX.Element;
  firstInTimeline?: boolean;
  lastInTimeline?: boolean;
  withoutTooltip?: boolean;
}

const InvoiceStateCheckIcon = ({
  className,
  createdDate,
  isStateTextUnderIcon = false,
  state,
  withStateText = false,
  timeLineLeftElement,
  timeLineRightElement,
  firstInTimeline = false,
  lastInTimeline = false,
  withoutTooltip = false,
}: InvoiceStateCheckIconProps): JSX.Element | null => {
  const [spacerWidth, setSpacerWidth] = useState(0);
  const textRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    if (textRef.current && (lastInTimeline || firstInTimeline)) {
      setSpacerWidth(textRef.current.offsetWidth * iconMiddlePoint(state));
    }
  }, [firstInTimeline, lastInTimeline, state]);

  const mappedItem = STATE_TEXT_AND_ICON_MAP[state];
  if (!mappedItem) return null;

  const IconComponent = mappedItem.icon;
  const infoText = mappedItem.text;

  const iconContainerClass = clsx({
    [styles.iconAndInfoContainerRegular]: !isStateTextUnderIcon,
    [styles.iconAndInfoContainerIsUnder]: isStateTextUnderIcon,
    [styles.firstInTimeline]: firstInTimeline,
    [styles.lastInTimeline]: lastInTimeline,
  });

  const stateTextDisplay = withStateText && (
    <p ref={textRef} className={clsx(styles.infoText, { [styles.infoTextInColumn]: isStateTextUnderIcon })}>
      {infoText} <span>{createdDate && formatDate(createdDate)}</span>
    </p>
  );

  const spacerElement = <div style={{ width: spacerWidth, visibility: 'hidden' }} />;

  const iconDisplay =
    isStateTextUnderIcon && (timeLineLeftElement || timeLineRightElement) ? (
      <>
        <div className={styles.timelineContainer}>
          {timeLineLeftElement}
          {firstInTimeline && spacerElement}
          <IconComponent />
          {timeLineRightElement}
          {lastInTimeline && spacerElement}
        </div>
      </>
    ) : (
      <IconComponent />
    );

  const contentDisplay = (
    <div className={clsx(iconContainerClass, className)}>
      {iconDisplay}
      {stateTextDisplay}
    </div>
  );

  const iconStatusDisplay = withoutTooltip ? (
    contentDisplay
  ) : (
    <Tooltip contentText={infoText} isDisabled={withStateText}>
      {contentDisplay}
    </Tooltip>
  );

  return <>{iconStatusDisplay}</>;
};

export default InvoiceStateCheckIcon;
