import { useState } from 'react';
import { ReactComponent as CheckOnRounded } from 'theme/assets/svg/CheckRounded.svg';
import { ReactComponent as CheckOffRounded } from 'theme/assets/svg/checkOffRounded.svg';
import Button from 'theme/atoms/Button';
import { SpecialMarking } from 'theme/atoms/Button/Button';
import ApprovalFromPeople from 'theme/atoms/approvalFromPeople';
import Modal from 'theme/atoms/modal';
import UserCard from 'theme/atoms/userCard';
import styles from './Approval.module.scss';

export interface OtherApprovalsNeeded {
  name: string;
  title: string;
  approved: boolean;
  imageUrl?: string;
}

export interface ApprovalAction {
  label: string;
  onClick: () => void;
  loading?: boolean;
  specialMarking?: SpecialMarking;
}

export interface ApprovalProps {
  actions: ApprovalAction[];
  isSellerSide?: boolean;
  otherApprovalsNeeded?: OtherApprovalsNeeded[];
  children?: JSX.Element;
}

const Approval = ({ actions, otherApprovalsNeeded, isSellerSide, children }: ApprovalProps): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const modal = (
    <Modal
      isOpen={!!isModalOpen}
      onClose={() => setIsModalOpen(false)}
      title="Required approvals"
      btnConfirm={{ label: 'Close', function: () => setIsModalOpen(false) }}
    >
      <div className={styles.otherApprovalWrapper}>
        {otherApprovalsNeeded?.map((contact, i) => (
          <div key={contact.name + i} className={styles.otherApproval}>
            <div className={styles.contactInfo}>
              <UserCard id={contact.name}>
                <div className={styles.nameAndTitle}>
                  <UserCard.NameAndTitle name={contact.name} title={contact.title} numberOfLinesInName={2} />
                </div>
              </UserCard>
            </div>
            <div className={styles.approvalInfo}>
              {contact.approved ? <CheckOnRounded /> : <CheckOffRounded />}
              {contact.approved ? 'Approved' : 'Approval needed'}
            </div>
          </div>
        ))}
      </div>
    </Modal>
  );

  return (
    <div className={styles.root}>
      <ApprovalFromPeople
        peopleNumber={(otherApprovalsNeeded && otherApprovalsNeeded.length) || 0}
        variant={isSellerSide ? 'transactionSellerSide' : 'noAdditionalInfo'}
        onClick={() => setIsModalOpen(true)}
      />
      {children}
      <div className={styles.actions}>
        {actions.map((action, i) => (
          <Button
            key={action.label}
            text={action.label}
            onClick={action.onClick}
            loading={action.loading}
            buttonType={i === 0 ? 'primary' : 'secondary'}
            maxWidth
            specialMarking={action.specialMarking}
          />
        ))}
      </div>
      {modal}
    </div>
  );
};

export default Approval;
