import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { ContactConnection } from 'network/types';
import { Button } from 'theme/atoms';
import Loader from 'theme/atoms/loader';
import SlidingPanel from 'theme/atoms/slidingPanel';
import { ContactPickerNetworkList, ContactPickerSearchbar, MeAsContact } from './components';
import ContactPickerAdditionalInfo from './components/contactPickerAdditionalInfo';
import { ContactPickerAdditionalInfoType } from './components/contactPickerAdditionalInfo/ContactPickerAdditionalInfo';
import { MeAsContactRole } from './components/meAsContact/MeAsContact';

interface ContactPickerProps {
  title: string;
  isOpen: boolean;
  btnText?: string;
  onPickContact: (contact: ContactConnection) => void;
  onClose: () => void;
  contactsToExclude?: string[];
  onPickMeAsContact?: () => void;
  withMeAsContact?: MeAsContactRole;
  additionalInfo?: ContactPickerAdditionalInfoType;
}

const ContactPicker = ({
  title,
  onClose,
  isOpen = true,
  btnText = 'Add',
  onPickContact,
  contactsToExclude,
  onPickMeAsContact,
  withMeAsContact,
  additionalInfo,
}: ContactPickerProps): JSX.Element => {
  const { contactPickerStore } = useStores();

  const {
    fetchingMore,
    contacts,
    hasMoreContacts,
    searchQuery,
    activePage,
    fetchItems,
    getNonExcludedContacts,
    resetActivePage,
    setActivePage,
    resetPaginationData,
    setSearchQuery,
  } = contactPickerStore;

  useEffect(() => {
    resetActivePage();
    fetchItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  useEffect(() => {
    searchQuery && resetPaginationData();
    setSearchQuery(undefined);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleShowMore = (): void => {
    setActivePage(activePage + 1);
    fetchItems();
  };

  const showMoreElement = fetchingMore ? (
    <Loader small />
  ) : (
    <Button onClick={handleShowMore} text="show more" buttonType="tertiary" />
  );

  return (
    <SlidingPanel title={title} onClose={onClose} isOpen={isOpen}>
      {additionalInfo && <ContactPickerAdditionalInfo additionalInfo={additionalInfo} />}
      <ContactPickerSearchbar />
      {onPickMeAsContact && <MeAsContact onPickMe={onPickMeAsContact} btnText={btnText} role={withMeAsContact} />}
      <ContactPickerNetworkList
        contacts={contactsToExclude ? getNonExcludedContacts(contactsToExclude) : contacts}
        btnText={btnText}
        onPickContact={onPickContact}
      />
      {hasMoreContacts && showMoreElement}
    </SlidingPanel>
  );
};

export default observer(ContactPicker);
