import styles from '../SearchTable.module.scss';
import { FilterStore } from '../constants';

interface NumberIconProps {
  filterStore: FilterStore;
}

export const NumberIcon = ({ filterStore }: NumberIconProps): JSX.Element | null => {
  const { confirmedFilteringAndSortingItemsWithoutDefaultsCount } = filterStore;

  const numberToDisplay =
    confirmedFilteringAndSortingItemsWithoutDefaultsCount < 10
      ? confirmedFilteringAndSortingItemsWithoutDefaultsCount
      : '9+';

  return confirmedFilteringAndSortingItemsWithoutDefaultsCount > 0 ? (
    <div className={styles.redSymbol}>{numberToDisplay}</div>
  ) : null;
};
