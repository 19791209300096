import { useState, useRef } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { useReactToPrint } from 'react-to-print';
import clsx from 'clsx';
import Spinner from 'theme/atoms/Spinner/Spinner';
import { ReactComponent as PrintIcon } from 'theme/atoms/pdfViewer/assets/printer.svg';
import { ReactComponent as SaveIcon } from 'theme/atoms/pdfViewer/assets/save-file.svg';
import Select from '../Select';
import { Option } from '../Select/Select';
import Tooltip from '../tooltip';
import styles from './PDFViewer.module.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const ZOOM_OPTIONS = [
  { value: '0.25', label: '25%' },
  { value: '0.5', label: '50%' },
  { value: '1.0', label: '100%' },
  { value: '1.5', label: '150%' },
  { value: '2', label: '200%' },
];

interface PDFToolbarProps {
  onPrint: () => void;
  onSave: () => void;
  onZoomChange: (zoomScale: number) => void;
  zoomScale: number;
}

const PDFToolbar = ({ onPrint, onSave, onZoomChange, zoomScale }: PDFToolbarProps): JSX.Element => {
  const defaultZoomOption: Option = { value: '1.0', label: '100%' };
  const zoomScaleOption = ZOOM_OPTIONS.find((o) => o.value === String(zoomScale)) || defaultZoomOption;

  const handleZoomChange = (option: Option): void => {
    onZoomChange(Number(option.value));
  };

  return (
    <div className={styles.toolbar}>
      <div className={styles.toolbarIconsContainer}>
        <Tooltip contentText="Download file">
          <SaveIcon className={styles.toolbarIcon} onClick={onSave} />
        </Tooltip>
        <Tooltip contentText="Print file">
          <PrintIcon className={styles.toolbarIcon} onClick={onPrint} />
        </Tooltip>
      </div>
      <Select
        className={styles.zoomSelect}
        label="File zoom:"
        sideLabel
        options={ZOOM_OPTIONS}
        value={zoomScaleOption}
        onChange={handleZoomChange}
        placeholder="Select zoom"
        isButtonAsText
        maxHeight={180}
      />
    </div>
  );
};

interface PDFReaderProps {
  className?: string;
  downloadFileName?: string;
  isPDFRejected?: boolean;
  url: string;
}

const PDFReader = ({ className = '', downloadFileName, url, isPDFRejected = false }: PDFReaderProps): JSX.Element => {
  const [zoom, setZoom] = useState(1.0);
  const printRef = useRef(null);

  const handleZoomChange = (newZoom: number): void => {
    setZoom(newZoom);
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onBeforeGetContent: () => {
      document.title = downloadFileName ? downloadFileName : 'Printed document';
    },
  });

  const handleSave = (): void => {
    const link = document.createElement('a');
    link.href = url;
    link.download = downloadFileName ? downloadFileName : 'Downloaded_file.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className={clsx(styles.root, className)}>
      {isPDFRejected && <div className={styles.rejectedInfoContainer}>Rejected</div>}
      <PDFToolbar zoomScale={zoom} onZoomChange={handleZoomChange} onPrint={handlePrint} onSave={handleSave} />
      <div className={styles.iframeContainer}>
        <Document ref={printRef} file={url} loading={<Spinner small />} className={styles.pdfView}>
          <Page pageNumber={1} scale={zoom} />
        </Document>
      </div>
    </div>
  );
};

export default PDFReader;
