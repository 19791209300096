import { ChangeEvent } from 'react';
import clsx from 'clsx';
import styles from './RadioTile.module.scss';

interface RadioTileProps {
  id: string;
  title: string;
  description: string;
  checked: boolean;
  onChange: (id: string) => void;
}

const RadioTile = ({ id, title, description, checked, onChange }: RadioTileProps): React.JSX.Element => {
  return (
    <label className={clsx(styles.root, { [styles.checked]: checked })}>
      <div>
        <div className={styles.title}>{title}</div>
        <div className={styles.description}>{description}</div>
      </div>
      <input
        type="radio"
        className={styles.radioButton}
        name={id}
        checked={checked}
        onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.name)}
      />
      <div className={styles.customInput} />
    </label>
  );
};
export default RadioTile;
