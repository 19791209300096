import { useState, useEffect } from 'react';

const useElementWidth = (elementRef: React.RefObject<HTMLElement>): number => {
  const [elementWidth, setElementWidth] = useState(0);

  useEffect(() => {
    const handleResize = (): void => {
      if (elementRef.current) {
        setElementWidth(elementRef.current.offsetWidth);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [elementRef]);

  return elementWidth;
};

export default useElementWidth;
