import { useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { useOnClickOutside } from 'utils/hooks';
import Button from '../Button/Button';
import styles from './Dropup.module.scss';
import { ReactComponent as IconArrow } from './arrow.svg';

interface DropdownItem {
  id: string;
  label: string;
  function?: () => void;
  goTo?: string;
}

interface DropupProps {
  items: DropdownItem[];
  buttonContent: JSX.Element | string;
}

const Dropup = observer(({ items, buttonContent }: DropupProps): JSX.Element | null => {
  const ref = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useOnClickOutside({ ref, handler: () => setIsMenuOpen(false) });

  const onButtonClick = (func: () => void): void => {
    setIsMenuOpen(false);
    func();
  };

  return (
    <div className={clsx(styles.root)} ref={ref}>
      <button className={styles.button} onClick={() => setIsMenuOpen(!isMenuOpen)}>
        <div className={styles.buttonContent}>{buttonContent}</div>
        <div className={clsx(styles.arrow, isMenuOpen && styles.turnedArrow)}>
          <IconArrow />
        </div>
      </button>
      <div className={clsx(styles.dropupWrapper, isMenuOpen && styles.showDropup)}>
        <div className={styles.dropup}>
          {items.map((item) =>
            item.function ? (
              <Button
                key={item.id}
                onClick={() => item.function && onButtonClick(item.function)}
                buttonType="textButton"
                className={styles.item}
                text={item.label}
              />
            ) : (
              <Button
                key={item.id}
                to={item.goTo}
                buttonType="textButton"
                className={styles.item}
                onClick={() => setIsMenuOpen(false)}
                text={item.label}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
});
export default Dropup;
