import { PropsWithChildren, ReactNode } from 'react';
import clsx from 'clsx';
import styles from './Billing.module.scss';

interface BillingProps {
  children: ReactNode;
  className?: string;
}

const Billing = ({ children, className }: BillingProps): JSX.Element => {
  return (
    <div role="table" className={className}>
      {children}
    </div>
  );
};

interface DarkRowProps {
  children: ReactNode;
  isDark?: boolean;
  isLight?: never;
  isIndented?: boolean;
  isTwoLines?: boolean;
}
interface LightRowProps {
  children: ReactNode;
  isDark?: never;
  isLight?: boolean;
  isIndented?: boolean;
  isTwoLines?: boolean;
}

const Row = ({
  children,
  isDark,
  isLight,
  isIndented,
  isTwoLines = false,
}: DarkRowProps | LightRowProps): JSX.Element => {
  return (
    <div
      role="row"
      className={clsx(styles.row, {
        [styles.dark]: isDark,
        [styles.light]: isLight,
        [styles.indented]: isIndented,
        [styles.twoLines]: isTwoLines,
      })}
    >
      {children}
    </div>
  );
};

interface CellProps {
  children: ReactNode;
  isBold?: boolean;
  isSplit?: boolean;
}

const Cell = ({ children, isBold, isSplit }: CellProps): JSX.Element => {
  return (
    <div role="cell" className={clsx(styles.cell, { [styles.bold]: isBold, [styles.split]: isSplit })}>
      {children}
    </div>
  );
};

const Header = ({ children }: PropsWithChildren): JSX.Element => {
  return (
    <div role="row" className={styles.header}>
      <h2>{children}</h2>
    </div>
  );
};

interface SubheaderProps {
  children: ReactNode;
  isTwoLines?: boolean;
}

const Subheader = ({ children, isTwoLines = false }: SubheaderProps): JSX.Element => {
  return (
    <div role="row" className={clsx(styles.subheader, { [styles.twoLines]: isTwoLines })}>
      {children}
    </div>
  );
};

const Divider = (): JSX.Element => {
  return <div className={styles.divider} />;
};

Billing.Row = Row;
Billing.Cell = Cell;
Billing.Header = Header;
Billing.Subheader = Subheader;
Billing.Divider = Divider;

export default Billing;
