import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import NoArtworkFound from 'artworks/addArtwork/steps/components/NoArtworkFound';
import AssetsList from 'artworks/components/assetsList';
import ImagesList from 'artworks/components/imagesList/ImagesList';
import { getAddress } from 'network/utils';
import { Spinner, TitleBlack } from 'theme/atoms';
import Breadcrumbs, { Breadcrumb } from 'theme/atoms/Breadcrumbs';
import SectionHeader from 'theme/atoms/Titles/SectionHeader';
import Topbar from 'theme/atoms/Topbar';
import UserCard from 'theme/atoms/userCard';
import { SummaryArtworkDetails } from '../addArtwork/components';
import styles from './ApproveArtwork.module.scss';
import Actions from './components/actions';
import OwnershipSummary from './components/ownershipSummary';

const ApproveArtwork = (): JSX.Element => {
  const { approveArtworkStore } = useStores();
  const { artworkToApprove: artwork, iAmTheAuthor, fetchArtwork, loadingArtwork } = approveArtworkStore;
  const { artworkId } = useParams();

  const breadcrumbs: Breadcrumb[] = [{ label: `Approve artwork` }];

  useEffect(() => {
    artworkId && fetchArtwork(artworkId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artworkId]);

  if (loadingArtwork) {
    return (
      <div className={styles.loaderWrapper}>
        <Spinner small />
      </div>
    );
  }
  if (!artwork) {
    return <NoArtworkFound />;
  }

  const pageTitle = artwork.isEditable
    ? 'Artwork Draft'
    : artwork.isOwner && !iAmTheAuthor
    ? 'Approve artwork ownership'
    : 'Approve artwork';

  const authorCard = artwork.author && (
    <UserCard id={artwork.author.id || ''}>
      <UserCard.Contact
        name={artwork.author.name}
        title={artwork.author.title}
        address={artwork.author.address && getAddress(artwork.author.address)}
      />
    </UserCard>
  );

  return (
    <>
      <Topbar>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      </Topbar>
      <TitleBlack dontLimitWidth text={pageTitle} />
      <div className={styles.root}>
        <SectionHeader title="Author of the artwork" />
        {authorCard}
        <AssetsList assetsUrls={artwork.assets.certificationUrls} />
        <SummaryArtworkDetails artwork={artwork} />
        <ImagesList imageUrls={artwork.assets.imageUrls} artworkName={artwork.name} />
        <OwnershipSummary />
        {artwork.assets.additionalDocUrls?.length ? (
          <>
            <SectionHeader title="Undisclosed documents" />
            <AssetsList assetsUrls={artwork.assets.additionalDocUrls} />
          </>
        ) : null}
        <Actions />
      </div>
    </>
  );
};

export default observer(ApproveArtwork);
