import { useEffect, useState } from 'react';
import { ReactComponent as InstitutionalIcon } from 'theme/assets/svg/city-buildings.svg';
import { ReactComponent as IndividualIcon } from 'theme/assets/svg/person.svg';
import styles from './Avatar.module.scss';

export const NUMBER_OF_BACKGROUND_COLOR_VARIANTS = 6;

interface ImageAvatarProps {
  imageUrl: string | null;
  userName: string;
  id?: never;
  isInstitutional?: never;
}

interface PlaceholderAvatarProps {
  imageUrl?: never;
  userName?: never;
  id: string;
  isInstitutional?: boolean;
}

// create hash (min: 1, max: NUMBER_OF_BACKGROUND_VARIANTS) based on some id
const generateHashBasedOnUserId = (id: string): number => {
  if (!id) return 1;

  let h = 0;

  for (let i = 0; i < id.length; i++) {
    h = Math.imul(31, h) + id.charCodeAt(i);
  }

  return Math.abs(h % NUMBER_OF_BACKGROUND_COLOR_VARIANTS) + 1;
};

const Avatar = ({
  imageUrl,
  userName,
  id = '',
  isInstitutional = false,
}: ImageAvatarProps | PlaceholderAvatarProps): JSX.Element => {
  const [bgImage, setBgImage] = useState<string | null>(null);

  useEffect(() => {
    if (!imageUrl) {
      import(`./assets/bg${generateHashBasedOnUserId(id)}.png`).then((image) => setBgImage(image.default));
    }
  }, [imageUrl, id]);

  const image = imageUrl ? (
    <img className={styles.image} src={imageUrl} alt={userName} />
  ) : (
    <div className={styles.placeholder} style={{ backgroundImage: `url(${bgImage})` }}>
      {isInstitutional ? <InstitutionalIcon /> : <IndividualIcon />}
    </div>
  );

  return <div className={styles.root}>{image}</div>;
};

export default Avatar;
