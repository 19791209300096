import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import AssetsList from 'artworks/components/assetsList';
import { ArtworkOwner } from 'artworks/types';
import { getAddress } from 'network/utils';
import SectionHeader from 'theme/atoms/Titles/SectionHeader';
import UserCard from 'theme/atoms/userCard';
import styles from './OwnershipSummary.module.scss';

const OwnershipSummary = (): JSX.Element => {
  const { approveArtworkStore } = useStores();
  const { artworkToApprove: artwork } = approveArtworkStore;

  if (!artwork) return <></>;

  const ownershipList = artwork.owners.map(({ contact, participationPercent }: ArtworkOwner, index): JSX.Element => {
    if (contact) {
      return (
        <div className={styles.sectionWrapper} key={contact.organizationName + index}>
          <UserCard id={contact.contactConnectionId || ''}>
            <UserCard.Contact
              name={contact.organizationName || 'Undisclosed'}
              title={contact.organizationTitle}
              numberOfLinesInName={2}
              address={contact.organizationAddress && getAddress(contact.organizationAddress)}
            />
          </UserCard>
          <div>{participationPercent}%</div>
        </div>
      );
    } else return <></>;
  });

  return (
    <>
      <SectionHeader title="Current ownership" />
      <div className={styles.root}>{ownershipList}</div>
      <AssetsList assetsUrls={artwork.assets.ownershipProofUrls} />
    </>
  );
};

export default observer(OwnershipSummary);
