import { FunctionComponent, SVGProps } from 'react';
import { ReactComponent as Add } from 'theme/assets/svg/add.svg';
import { ReactComponent as Close } from 'theme/assets/svg/close.svg';
import { ReactComponent as ExclamationMark } from 'theme/assets/svg/exclamation-mark.svg';
import { ReactComponent as Expand } from 'theme/assets/svg/expand.svg';
import { ReactComponent as Next } from 'theme/assets/svg/next.svg';
import { ReactComponent as QuestionMark } from 'theme/assets/svg/questionMark.svg';
import { ReactComponent as Sad } from 'theme/assets/svg/sad.svg';
import { ReactComponent as Tick } from 'theme/assets/svg/tick.svg';

export const icons = {
  close: Close,
  exclamationMark: ExclamationMark,
  sad: Sad,
  tick: Tick,
  expand: Expand,
  next: Next,
  add: Add,
  questionMark: QuestionMark,
};

export const iconSizes = {
  small: {
    width: 20,
    height: 20,
  },
  medium: {
    width: 24,
    height: 24,
  },
  large: {
    width: 36,
    height: 36,
  },
};

interface IconComponentProps {
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  className?: string;
  ariaLabel?: string;
  size?: (typeof iconSizes)[keyof typeof iconSizes];
}

const IconComponent = ({ icon, className, ariaLabel, size = iconSizes.medium }: IconComponentProps): JSX.Element => {
  const Component = icon;

  return <Component height={size.height} className={className} width={size.width} aria-label={ariaLabel} />;
};

export default IconComponent;
