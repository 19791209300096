import { useNavigate, useParams } from 'react-router-dom';
import { routes } from 'app/routes/paths.const';
import { ReactComponent as ArtworkIcon } from 'theme/assets/svg/image.svg';
import ActionButtons, { ActionButton } from 'theme/atoms/ActionButtons';
import Breadcrumbs, { Breadcrumb } from 'theme/atoms/Breadcrumbs';
import Topbar from 'theme/atoms/Topbar';
import { DEFAULT_TRANSACTION_CATEGORY } from 'transactions/transactions/types';

interface TransactionTopbarProps {
  artworkIdIfItsSellerSide?: string;
}

const TransactionTopbar = ({ artworkIdIfItsSellerSide }: TransactionTopbarProps): JSX.Element => {
  const { transactionId } = useParams();
  const navigate = useNavigate();

  if (!transactionId) return <></>;

  const BREADCRUMBS: Breadcrumb[] = [
    { label: 'Transactions', route: 'Transactions', param: DEFAULT_TRANSACTION_CATEGORY },
    { label: `Offer ${transactionId}` },
  ];

  const ACTION_BUTTONS: ActionButton[] = [
    {
      text: 'Artwork details',
      icon: <ArtworkIcon />,
      onClick: () =>
        navigate(
          artworkIdIfItsSellerSide
            ? routes.Artwork.path(artworkIdIfItsSellerSide)
            : routes.TransactionArtwork.path(transactionId)
        ),
    },
  ];

  return (
    <Topbar>
      <Breadcrumbs breadcrumbs={BREADCRUMBS} />
      <ActionButtons buttons={ACTION_BUTTONS} />
    </Topbar>
  );
};

export default TransactionTopbar;
