declare global {
  interface Window {
    env: { [key: string]: string };
  }
}

export type Env = {
  backendUrl: string;
  publicUrl: string;
};

function fetchEnv(name: string, defaultValue = ''): string {
  return process.env[`REACT_APP_${name}`] ?? (window['env'] ? window['env'][name] : null) ?? defaultValue;
}

const env: Env = {
  backendUrl: fetchEnv('API_BASE_URL'),
  publicUrl: fetchEnv('PUBLIC_URL'),
};

export default env;
