import React from 'react';
import clsx from 'clsx';
import styles from './Button.module.scss';
import { ButtonElement, ExternalLinkElement, LinkElement } from './components';

export type ButtonType = 'primary' | 'secondary' | 'tertiary' | 'textButton';

export interface Classes {
  className: string;
}

export type SpecialMarkingKind = 'blocked' | 'paid';

export interface SpecialMarking {
  kind: SpecialMarkingKind;
  tooltip: string;
  suffix?: string;
  maxWidth?: number;
}

export interface ButtonProps {
  buttonType?: ButtonType;
  className?: string;
  disabled?: boolean;
  iconStart?: React.ReactNode;
  iconEnd?: React.ReactNode;
  loading?: boolean;
  onClick?: (event?) => void;
  text?: string;
  to?: string;
  externalHref?: string;
  type?: 'button' | 'submit';
  maxWidth?: boolean;
  uppercase?: boolean;
  light?: boolean;
  specialMarking?: SpecialMarking;
}

const Button = (props: ButtonProps): JSX.Element => {
  const {
    buttonType = 'primary',
    className = '',
    to = '',
    externalHref = '',
    maxWidth,
    loading,
    uppercase = true,
  } = props;

  const regularButton = buttonType !== 'textButton';

  const classes: Classes = {
    className: clsx(
      styles.root,
      styles[buttonType],
      {
        [styles.maxWidth]: maxWidth && regularButton,
        [styles.loading]: loading && !to && regularButton,
        [styles.withoutUpperCase]: !uppercase,
        [styles.specialMarkingBlocked]: props.specialMarking?.kind === 'blocked',
        [styles.specialMarkingPaid]: props.specialMarking?.kind === 'paid',
      },
      className
    ),
  };

  return (
    <>
      {to && <LinkElement {...props} classes={classes} />}
      {externalHref && <ExternalLinkElement {...props} classes={classes} regularButton={regularButton} />}
      {!to && !externalHref && <ButtonElement {...props} classes={classes} regularButton={regularButton} />}
    </>
  );
};

export default Button;
