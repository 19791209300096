import { ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import { BANK_ACCOUNT_FIELDS } from 'domain/constants';
import { RawBankAccount } from 'domain/types/BankAccount.types';
import InfoCardDetailsList from 'theme/atoms/card/infoCardDetailsList';
import InfoCard from 'theme/atoms/infoCard';
import { formatIBAN } from 'utils/helpers';

interface BankAccountInfoCardProps {
  className?: string;
  data: RawBankAccount | undefined;
  children?: ReactNode;
  label?: string;
  loading?: boolean;
}

const BankAccountInfoCard = ({ data, className, children, label, loading }: BankAccountInfoCardProps): JSX.Element => {
  const bankAccountDetailsAsObject = BANK_ACCOUNT_FIELDS.reduce((acc, { name, label }) => {
    const value = data?.[name] || '';
    const formattedIbanValue = name === 'iban' && value && formatIBAN(value);
    return {
      ...acc,
      [label]: { value: formattedIbanValue || value },
    };
  }, {});

  const detailsElement = <InfoCardDetailsList fieldsMap={bankAccountDetailsAsObject} loadingData={loading} />;

  return (
    <InfoCard className={className} details={detailsElement} label={label}>
      {children}
    </InfoCard>
  );
};

export default observer(BankAccountInfoCard);
