import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { TRANSACTION_NOTE } from 'domain/constants';
import { ReactComponent as EditIcon } from 'theme/assets/svg/edit.svg';
import { Button } from 'theme/atoms';
import Billing from 'theme/atoms/billing';
import { useDebounceIsPanelOpen } from 'theme/atoms/slidingPanel/useDebounceIsPanelOpen';
import { TransactionWrapper } from 'transactions/requests.types';
import { useTransactionRoles } from 'utils/hooks';
import EditBillingPanel from '../editBillingPanel/EditBillingPanel';
import styles from './TransactionBilling.module.scss';
import AmountToPay from './components/AmountToPay';
import ArtistResellRights from './components/ArtistResellRights';
import Commission from './components/Commission';
import CommissionReceivers from './components/CommissionReceivers';
import InitialPrice from './components/InitialPrice';
import TransactionBillingWhenBeingCreatedByArtwork from './components/TransactionBillingWhenBeingCreatedByArtwork';

interface BillingProps {
  isSummaryView?: boolean;
}

const TransactionBilling = ({ isSummaryView = false }: BillingProps): JSX.Element => {
  const [isEditBillingOpen, setIsEditBillingOpen] = useState(false);
  const debouncedIsEditBillingOpen = useDebounceIsPanelOpen(isEditBillingOpen);
  const { transactionFormStore } = useStores();
  const transactionWrapper = transactionFormStore.transactionWrapper as TransactionWrapper;

  const { isSellerSide, isSellerOwnerAsRepresentative } = useTransactionRoles({ transactionWrapper });

  if (isSellerOwnerAsRepresentative) {
    return <TransactionBillingWhenBeingCreatedByArtwork isSummaryView={isSummaryView} />;
  }

  return (
    <>
      <div className={styles.root}>
        <Billing>
          <div className={styles.billingHeader}>
            <Billing.Header>Billing</Billing.Header>
            {isSellerSide && !isSummaryView && (
              <Button
                text="Edit"
                iconStart={<EditIcon />}
                buttonType="tertiary"
                onClick={() => debouncedIsEditBillingOpen && setIsEditBillingOpen(true)}
              />
            )}
          </div>

          <InitialPrice isSummaryView={isSummaryView} />
          {!isSellerSide && <ArtistResellRights isSummaryView={isSummaryView} />}
          {!isSellerOwnerAsRepresentative ? (
            <>
              <Commission isSummaryView={isSummaryView} />
              <CommissionReceivers isSummaryView={isSummaryView} />
            </>
          ) : (
            <></>
          )}
          <Billing.Divider />
          <AmountToPay isSummaryView={isSummaryView} />
          <p className={styles.note}>{TRANSACTION_NOTE}</p>
          {isSellerSide && !isSummaryView && <ArtistResellRights isSummaryView={isSummaryView} />}
        </Billing>
      </div>
      {isSellerSide && <EditBillingPanel isOpen={isEditBillingOpen} onClose={() => setIsEditBillingOpen(false)} />}
    </>
  );
};

export default observer(TransactionBilling);
