import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { addMeToOrganizationName } from 'domain/utils';
import { getAddress } from 'network/utils';
import { Button } from 'theme/atoms';
import UserCard from 'theme/atoms/userCard';
import styles from './MeAsContact.module.scss';

export type MeAsContactRole = 'author' | 'owner';

interface MeAsContactProps {
  onPickMe: () => void;
  btnText: string;
  role?: MeAsContactRole;
}

const MeAsContact = ({ onPickMe, btnText, role }: MeAsContactProps): JSX.Element => {
  const {
    organizationStore: { selectedOrganization },
  } = useStores();

  return (
    <div className={styles.root}>
      <h4 className={styles.header}>{!role ? 'Me' : "I'm the " + role}:</h4>
      <div className={styles.row}>
        <div>
          {selectedOrganization && (
            <UserCard id={selectedOrganization.id}>
              <UserCard.Contact
                name={addMeToOrganizationName({ organizationName: selectedOrganization.name }, true)}
                title={selectedOrganization.title}
                address={selectedOrganization.address ? getAddress(selectedOrganization.address) : ''}
              />
            </UserCard>
          )}
        </div>
        <Button text={btnText} onClick={onPickMe} />
      </div>
    </div>
  );
};

export default observer(MeAsContact);
