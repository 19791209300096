import AssetsList from 'artworks/components/assetsList';
import ImagesList from 'artworks/components/imagesList';
import { Artwork } from 'artworks/types';
import SectionHeader from 'theme/atoms/Titles/SectionHeader';
import styles from './ArtworkDetails.module.scss';

interface ArtworkDetailsProps {
  artwork: Artwork;
}

const ArtworkDetails = ({ artwork }: ArtworkDetailsProps): JSX.Element => {
  const certificate = artwork.assets.certificationUrls && artwork.assets.certificationUrls.length > 0 && (
    <>
      <SectionHeader title="Certificate" />
      <AssetsList assetsUrls={artwork.assets.certificationUrls} />
    </>
  );

  const undisclosedDocuments = artwork.assets.additionalDocUrls && artwork.assets.additionalDocUrls.length > 0 && (
    <>
      <SectionHeader title="Undisclosed documents" />
      <AssetsList assetsUrls={artwork.assets.additionalDocUrls} />
    </>
  );

  return (
    <div className={styles.root}>
      <SectionHeader title="Description" />
      {artwork.description && <div>{artwork.description}</div>}
      {artwork.weight && (
        <div>
          <span className={styles.label}>Weight:</span>
          {artwork.weight}
        </div>
      )}
      {artwork.carbonDating && (
        <div>
          <div>Carbon dating:</div>
          <div>{artwork.carbonDating}</div>
        </div>
      )}
      <div>
        <span className={styles.label}>Artist Resell Rights:</span>
        {artwork.artistResellRightsPercent}%
      </div>
      <ImagesList imageUrls={artwork.assets.imageUrls} artworkName={artwork.name} />
      {certificate}
      {undisclosedDocuments}
    </div>
  );
};

export default ArtworkDetails;
