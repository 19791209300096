import { FormEvent, useEffect } from 'react';
import { NumberFormatValues } from 'react-number-format';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import Big from 'big.js';
import { FormValidation } from 'domain/types';
import { Currency } from 'domain/types/Domain.types';
import { Button } from 'theme/atoms';
import SlidingPanel from 'theme/atoms/slidingPanel';
import { TransactionWrapper } from 'transactions/requests.types';
import { useFormValidation } from 'utils/hooks';
import styles from './EditBillingPanel.module.scss';
import ConsignmentDetails from './components/consignmentsDetails/ConsignmentDetails';
import EditBilling from './components/editBilling';

const FORM_VALIDATIONS: FormValidation = {
  commissionAmountRequired: '',
  commissionAmountMustBeLessThanMax: '',
  commissionPercentRequired: '',
  commissionPercentMustBeLessThanMax: '',
  artworkPriceRequired: '',
  artworkPriceMustBeLessThanMax: '',
  artworkPriceCannotBeSmallerThanAskingPrice: '',
};

interface EditBillingPanelProps {
  isOpen: boolean;
  onClose: () => void;
}

const EditBillingPanel = ({ isOpen, onClose }: EditBillingPanelProps): JSX.Element => {
  const { editBillingPanelStore, transactionFormStore } = useStores();
  const {
    isCommissionAmountHigherThanMax,
    isArtworkPriceHigherThanMax,
    isArtworkPriceSmallerThanAskingPrice,
    isCommissionPercentHigherThanMax,
    isMaxCommissionHigherThanSelectedCommissionPercent,
    selectedConsignmentDetails,
    selectedBillingDetails,
    setInitialConsignmentDetails,
    setInitialBillingDetailsWhenTransactionCreatedByConsignment,
    updateSelectedBillingDetails,
    updateSelectedConsignmentDetails,
    recalculateCommissionAmountAndArtworkPrice,
    recalculateCommissionPercentAndArtworkPrice,
    recalculateCommissionAmountAndCommissionPercent,
  } = editBillingPanelStore;

  const { updateTotalCommissionAndAssignItToRepresentative, updateSellerPriceAndCurrency } = transactionFormStore;
  const transactionWrapper = transactionFormStore.transactionWrapper as TransactionWrapper;
  const { transaction } = transactionWrapper;
  const { sellerInfo } = transaction;

  useEffect(() => {
    sellerInfo && setInitialBillingDetailsWhenTransactionCreatedByConsignment(sellerInfo);
    setInitialConsignmentDetails(transactionWrapper);
    cleanAllValidationErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const { cleanValidationErrors, formValidationMessages, handleValidation, validation, areThereAnyValidationErrors } =
    useFormValidation(FORM_VALIDATIONS);

  const cleanAllValidationErrors = (): void => {
    cleanValidationErrors([
      'commissionAmountRequired',
      'commissionAmountMustBeLessThanMax',
      'commissionPercentRequired',
      'commissionPercentMustBeLessThanMax',
      'artworkPriceRequired',
      'artworkPriceMustBeLessThanMax',
      'artworkPriceCannotBeSmallerThanAskingPrice',
    ]);
  };

  const commissionPercentIsNotValid = (): boolean | void => {
    handleValidation(
      'commissionPercentMustBeLessThanMax',
      'You have entered a higher value than allowed by consignment.',
      isCommissionPercentHigherThanMax
    );

    return isCommissionPercentHigherThanMax;
  };

  const commissionAmountIsNotValid = (): boolean => {
    handleValidation(
      'commissionAmountMustBeLessThanMax',
      'You have entered a higher value than allowed by consignment.',
      isCommissionAmountHigherThanMax
    );
    return isCommissionAmountHigherThanMax;
  };

  const artworkPriceIsNotValid = (): boolean => {
    handleValidation(
      'artworkPriceMustBeLessThanMax',
      'You have entered a higher value than allowed by consignment.',
      isArtworkPriceHigherThanMax
    );
    handleValidation(
      'artworkPriceCannotBeSmallerThanAskingPrice',
      'You have entered a smaller value than allowed by consignment.',
      isArtworkPriceSmallerThanAskingPrice
    );

    return isArtworkPriceHigherThanMax || isArtworkPriceSmallerThanAskingPrice;
  };

  const isPercentValueNotValid = (value: string): boolean => {
    return !isNaN(+value) && (+value > 100 || +value < 0);
  };

  const handleChangeMaxCommissionPercent = (values: NumberFormatValues): void => {
    cleanAllValidationErrors();
    if (values.value && isPercentValueNotValid(values.value)) return;
    if (selectedConsignmentDetails) {
      updateSelectedConsignmentDetails({
        ...selectedConsignmentDetails,
        selectedMaxCommissionPercent: !values.value || values.value === '' ? null : Big(values.value || 0),
      });
    }
  };
  const handleChangeMaxCommissionPercentOnBlur = (): void => {
    if (isMaxCommissionHigherThanSelectedCommissionPercent)
      selectedBillingDetails &&
        updateSelectedBillingDetails({
          ...selectedBillingDetails,
          selectedCommissionPercent: Big(selectedConsignmentDetails?.selectedMaxCommissionPercent || 0),
        });
    recalculateCommissionAmountAndArtworkPrice();
    commissionPercentIsNotValid();
  };

  const handleChangeAskingPrice = (values: NumberFormatValues): void => {
    cleanAllValidationErrors();
    selectedConsignmentDetails &&
      updateSelectedConsignmentDetails({
        ...selectedConsignmentDetails,
        selectedAskingPrice: !values || values.value === '' ? null : Big(values.value),
      });
  };

  const handleChangeAskingPriceOnBlur = (): void => {
    recalculateCommissionAmountAndArtworkPrice();
    !commissionPercentIsNotValid() && cleanValidationErrors([]);
  };

  const handleChangeCurrency = (currency: Currency): void => {
    selectedConsignmentDetails &&
      updateSelectedConsignmentDetails({
        ...selectedConsignmentDetails,
        selectedCurrency: currency,
      });
  };

  const handleChangeCommissionPercent = (values: NumberFormatValues): void => {
    cleanAllValidationErrors();
    if (values.value && isPercentValueNotValid(values.value)) return;

    selectedBillingDetails &&
      updateSelectedBillingDetails({
        ...selectedBillingDetails,
        selectedCommissionPercent: !values || values.value === '' ? null : Big(values.value || 0),
      });
  };

  const handleChangeCommissionPercentOnBlur = (): void => {
    if (commissionPercentIsNotValid()) return;
    recalculateCommissionAmountAndArtworkPrice();
    cleanValidationErrors(['commissionAmountMustBeLessThanMax']);
  };

  const handleChangeCommissionAmount = (values: NumberFormatValues): void => {
    cleanAllValidationErrors();
    selectedBillingDetails &&
      updateSelectedBillingDetails({
        ...selectedBillingDetails,
        selectedCommissionAmount: !values || values.value === '' ? null : Big(values.value),
      });
  };

  const handleChangeCommissionAmountOnBlur = (): void => {
    if (commissionAmountIsNotValid()) return;
    recalculateCommissionPercentAndArtworkPrice();
    cleanValidationErrors(['commissionPercentMustBeLessThanMax']);
  };

  const handleChangeArtworkPrice = (values: NumberFormatValues): void => {
    cleanAllValidationErrors();
    selectedBillingDetails &&
      updateSelectedBillingDetails({
        ...selectedBillingDetails,
        selectedArtworkPrice: !values || values.value === '' ? null : Big(values.value),
      });
  };

  const handleChangeArtworkPriceOnBlur = (): void => {
    if (artworkPriceIsNotValid()) return;
    recalculateCommissionAmountAndCommissionPercent();
  };

  const handleSaveChanges = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    handleValidation(
      'commissionPercentRequired',
      formValidationMessages.REQUIRED,
      !selectedBillingDetails?.selectedCommissionPercent
    );
    handleValidation(
      'commissionAmountRequired',
      formValidationMessages.REQUIRED,
      !selectedBillingDetails?.selectedCommissionAmount
    );
    handleValidation(
      'artworkPriceRequired',
      formValidationMessages.REQUIRED,
      !selectedBillingDetails?.selectedArtworkPrice
    );
    if (
      !selectedBillingDetails?.selectedCommissionPercent ||
      !selectedBillingDetails.selectedCommissionAmount ||
      !selectedBillingDetails.selectedArtworkPrice ||
      !selectedConsignmentDetails?.selectedAskingPrice ||
      !sellerInfo ||
      commissionPercentIsNotValid() ||
      commissionAmountIsNotValid() ||
      artworkPriceIsNotValid()
    )
      return;

    updateTotalCommissionAndAssignItToRepresentative('sellerInfo', selectedBillingDetails.selectedCommissionAmount);
    updateSellerPriceAndCurrency(
      selectedConsignmentDetails.selectedAskingPrice,
      selectedConsignmentDetails.selectedCurrency
    );
    onClose();
  };

  return (
    <SlidingPanel title="" onClose={onClose} isOpen={isOpen} maxWidth>
      <form className={styles.root}>
        {selectedConsignmentDetails && (
          <ConsignmentDetails
            currency={selectedConsignmentDetails?.selectedCurrency}
            handlers={{
              handleChangeMaxCommissionPercent,
              handleChangeMaxCommissionPercentOnBlur,
              handleChangeAskingPrice,
              handleChangeAskingPriceOnBlur,
              handleChangeCurrency,
            }}
            selectedConsignmentDetails={selectedConsignmentDetails}
            consignmentFromTransaction={transactionWrapper?.consignment}
          />
        )}
        <div className={styles.background}>
          {selectedBillingDetails && (
            <EditBilling
              currency={selectedConsignmentDetails?.selectedCurrency}
              validation={validation}
              handlers={{
                handleChangeCommissionPercent,
                handleChangeCommissionPercentOnBlur,
                handleChangeCommissionAmount,
                handleChangeCommissionAmountOnBlur,
                handleChangeArtworkPrice,
                handleChangeArtworkPriceOnBlur,
              }}
              selectedBillingDetails={selectedBillingDetails}
            />
          )}
          <Button maxWidth text="Save changes" onClick={handleSaveChanges} disabled={areThereAnyValidationErrors} />
        </div>
      </form>
    </SlidingPanel>
  );
};

export default observer(EditBillingPanel);
