import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import { KycStatus, Organization } from 'organization/types';
import Banner from 'theme/atoms/banner';
import { useUserContext } from 'utils/hooks';

const getCopy = (organizationSituation, selectedOrganization: Organization, noPaymentMethods: boolean): JSX.Element => {
  switch (true) {
    case !organizationSituation.organizationIsNotSet && selectedOrganization.kycStatus === KycStatus.Rejected:
      return (
        <>
          Your organization has been rejected. See <Link to={routes.SettingsKYC.path()}>KYC details</Link>.
        </>
      );
    case !organizationSituation.organizationIsNotSet &&
      noPaymentMethods &&
      organizationSituation.organizationIsNotVerified:
      return (
        <>
          Resolve identity verification and payment issues before proceeding. See{' '}
          <Link to={routes.SettingsKYC.path()}>KYC details</Link> and{' '}
          <Link to={routes.Billing.path()}>Billing page</Link>.
        </>
      );
    case !organizationSituation.organizationIsNotSet && selectedOrganization.kycStatus === KycStatus.Pending:
      return (
        <>
          Your organization is being verified. See <Link to={routes.SettingsKYC.path()}>KYC details</Link>.
        </>
      );
    case !organizationSituation.organizationIsNotSet &&
      [KycStatus.Unverified, KycStatus.TemporaryRejected].includes(selectedOrganization.kycStatus):
      return (
        <>
          Resolve identity verification issues before proceeding. See{' '}
          <Link to={routes.SettingsKYC.path()}>KYC details</Link>.
        </>
      );
    case !organizationSituation.organizationIsNotSet && noPaymentMethods:
      return (
        <>
          Resolve payment issues before proceeding. See <Link to={routes.Billing.path()}>Billing page</Link> in
          Settings.
        </>
      );
    default:
      return <></>;
  }
};

export const KycOrPaymentIssueBanner = observer(() => {
  const {
    userStore: { noPaymentMethods },
  } = useStores();

  const { organizationSituation, selectedOrganization } = useUserContext();

  if (!selectedOrganization) return <></>;

  return (
    <Banner
      show={
        !organizationSituation.organizationIsNotSet &&
        (noPaymentMethods || organizationSituation.organizationIsNotVerified)
      }
      text={getCopy(organizationSituation, selectedOrganization, noPaymentMethods)}
    />
  );
});
