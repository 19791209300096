import { useDrag } from 'react-dnd';
import DraggableField from 'theme/atoms/DraggableField';
import ToggleSwitch from 'theme/atoms/ToggleSwitch';
import { DRAGGABLE_TYPES } from '../../../constants';
import { DataFieldType, DraggedItem } from '../../../types';
import styles from './NestedDataField.module.scss';

interface NestedDataFieldProps {
  index: number;
  field: DataFieldType;
  parentId: string;
  onChange: (fieldId: string, isChecked: boolean) => void;
  onDragStart: () => void;
}

const NestedDataField = ({
  index,
  field,
  parentId,
  onChange,
  onDragStart,
}: NestedDataFieldProps): JSX.Element | null => {
  const { id, label, isChecked } = field;

  const [{ isDragging }, dragHandle, drag] = useDrag({
    type: DRAGGABLE_TYPES.NESTED_FIELD,
    item: (): DraggedItem => {
      onDragStart();
      return { id, index, hasChild: false, isChild: true, parentId };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <div className={styles.root}>
      {isDragging ? (
        <div className={styles.dragOverlay} />
      ) : (
        <DraggableField elementDragRef={drag} handleRef={dragHandle} className={styles.root}>
          <ToggleSwitch
            label={label}
            isChecked={isChecked}
            onChange={() => onChange(id, !isChecked)}
            className={styles.toggleSwitch}
          />
        </DraggableField>
      )}
    </div>
  );
};

export default NestedDataField;
