import { Ref, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { Button } from 'theme/atoms';
import Accordion from 'theme/atoms/Accordion';
import Tiles, { TileType } from 'theme/atoms/tiles/Tiles';
import { initialDataFields, initialSizes } from './ArtworksViewOptionsStore';
import styles from './ViewOptions.module.scss';
// import { ReactComponent as DetailsIcon } from './assets/details.svg';
// import { ReactComponent as FolderTreeIcon } from './assets/folderTree.svg';
import { ReactComponent as GridIcon } from './assets/grid.svg';
import { ReactComponent as ListIcon } from './assets/list.svg';
import DataFieldList from './components/dataFields/dataFieldList';
import Slider from './components/slider';
import { INITIAL_DATA_FIELDS_TOTAL_NUMBER, INITIAL_LAYOUT, LAYOUTS, SIZES_LABELS } from './constants';
import { DataFieldIdsByLayout, DataFieldType, DataFieldsType, LayoutType, Sizes } from './types';

const LAYOUTS_MAP: TileType[] = [
  // { layout: 'tree', title: 'Folder tree', icon: <FolderTreeIcon /> }, // TODO uncomment when ready
  { id: 'list', label: 'List', icon: <ListIcon /> },
  // { layout: 'details', title: 'Details', icon: <DetailsIcon /> }, // TODO uncomment when ready
  { id: 'grid', label: 'Grid', icon: <GridIcon /> },
];

interface ViewOptionsProps {
  closePanel: () => void;
}

export type ViewOptionsRef = {
  initializeStateWithStoreData: () => void;
  resetViewOptions: () => void;
};

const ViewOptions = ({ closePanel }: ViewOptionsProps, ref: Ref<ViewOptionsRef>): JSX.Element => {
  useImperativeHandle(ref, () => ({ initializeStateWithStoreData, resetViewOptions }));

  const { artworksViewOptionsStore } = useStores();
  const {
    layout: storeLayout,
    sizes: storeSizes,
    dataFields: storeDataFields,
    saveViewOptions,
    setShowUncheckingLastFieldError,
  } = artworksViewOptionsStore;

  const [layout, setLayout] = useState<LayoutType>(storeLayout);
  const [sizes, setSizes] = useState<Sizes>(storeSizes);
  const [dataFields, setDataFields] = useState<DataFieldsType>(storeDataFields);

  const initialViewMoreButtonsStates = LAYOUTS.reduce(
    (viewMoreButtonStates, layout) => ({ ...viewMoreButtonStates, [layout]: true }),
    {}
  ) as { [k in LayoutType]: boolean };

  const getVisibleDataFieldIdsByLayout = (dataFields: DataFieldsType): DataFieldIdsByLayout => {
    return LAYOUTS.reduce((visibleDataFields, layout) => {
      const checkedDataFields = dataFields[layout].filter((field) => field.isChecked);

      const idsOfCheckedFields: string[] = [];

      checkedDataFields.forEach((field) => {
        if (field.isChecked) idsOfCheckedFields.push(field.id);
        if (field.child) idsOfCheckedFields.push(field.child.id);
      });

      return {
        ...visibleDataFields,
        [layout]: idsOfCheckedFields,
      };
    }, {}) as DataFieldIdsByLayout;
  };

  const [showViewMoreButtons, setShowViewMoreButtons] = useState(initialViewMoreButtonsStates);
  const [visibleDataFieldIdsByLayout, setVisibleDataFieldIdsByLayout] = useState(
    getVisibleDataFieldIdsByLayout(initialDataFields)
  );

  const shouldShowViewMoreButton =
    showViewMoreButtons[layout] && visibleDataFieldIdsByLayout[layout].length !== INITIAL_DATA_FIELDS_TOTAL_NUMBER;

  useEffect(() => {
    setShowUncheckingLastFieldError(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout, sizes, dataFields]);

  const initializeStateWithStoreData = (): void => {
    setLayout(storeLayout);
    setSizes(storeSizes);
    setDataFields(storeDataFields);
    setShowViewMoreButtons(initialViewMoreButtonsStates);
    setVisibleDataFieldIdsByLayout(getVisibleDataFieldIdsByLayout(storeDataFields));
  };

  const resetViewOptions = (): void => {
    setLayout(INITIAL_LAYOUT);
    setSizes(initialSizes);
    setDataFields(initialDataFields);
    setShowViewMoreButtons(initialViewMoreButtonsStates);
    setVisibleDataFieldIdsByLayout(getVisibleDataFieldIdsByLayout(initialDataFields));
  };

  const updateStore = (): void => {
    saveViewOptions(layout, sizes, dataFields);
    closePanel();
  };

  const handleUpdateDataFieldsAfterSort = (newFields: DataFieldType[]): void => {
    setDataFields((prevFields) => ({
      ...prevFields,
      [layout]: newFields,
    }));
  };

  return (
    <div className={styles.root}>
      <Accordion itemsOpenByDefault={['layoutType', 'size', 'dataFields']}>
        <Accordion.Item id="layoutType" header="Layout type">
          <Tiles list={LAYOUTS_MAP} selectedItem={layout} setSelectedItem={setLayout} />
        </Accordion.Item>

        <Accordion.Item id="size" header="Size">
          <Slider
            value={sizes[layout]}
            onChange={(newSize) => setSizes({ ...sizes, [layout]: newSize })}
            labels={SIZES_LABELS}
          />
        </Accordion.Item>

        <Accordion.Item id="dataFields" header="Data fields">
          <DataFieldList
            fields={dataFields[layout]}
            setFields={handleUpdateDataFieldsAfterSort}
            showViewMoreButton={shouldShowViewMoreButton}
            onViewMoreClick={() => setShowViewMoreButtons((prev) => ({ ...prev, [layout]: false }))}
            initialVisibleDataFieldIds={visibleDataFieldIdsByLayout[layout]}
          />
        </Accordion.Item>
      </Accordion>

      <Button text="Save" onClick={updateStore} maxWidth />
    </div>
  );
};

export default observer(forwardRef(ViewOptions));
