import { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { AssetWithKey } from 'domain/types/Asset.types';
import { getKeyFromUrl } from 'theme/cells/assetUpload/utils';
import AssetsUpload from '../assetsUpload';

const UndisclosedDocuments = (): JSX.Element => {
  const { addArtworkStore } = useStores();
  const { artworkForm, updateArtworkForm } = addArtworkStore;

  const assetsFromParent = useMemo(() => {
    return artworkForm.additionalDocUrls?.map((file) => ({
      ...file,
      key: getKeyFromUrl(file.presignedUrl),
    }));
  }, [artworkForm.additionalDocUrls]);

  return (
    <AssetsUpload
      assetsFromParent={assetsFromParent}
      onUpdateAssets={(files: AssetWithKey[]) => updateArtworkForm('additionalDocUrls', files)}
      instruction="Upload condition report or other documents with limited access (artwork owner, consignee, authorized persons)"
      title="Undisclosed document"
      assetsType="document"
      showAsList
    />
  );
};

export default observer(UndisclosedDocuments);
