import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { useStores } from 'RootStore';
import { ArtworkStatusEnum, ExternalExploringVisibility } from 'artworks/types';
import { SortItemType } from 'domain/SortAndFilterBasicStore/types';
import { ReactComponent as AddIcon } from 'theme/assets/svg/add.svg';
import { Button } from 'theme/atoms';
import Accordion from 'theme/atoms/Accordion';
import FilterChecklist from 'theme/atoms/filterChecklist';
import RadioButtonYearFilter from 'theme/atoms/radioButtonYearFilter';
import { KindYearsNumber, KindYearsLike } from 'theme/atoms/radioButtonYearFilter/types';
import RangeSlider from 'theme/atoms/rangeSlider';
import { RangeSliderProps } from 'theme/atoms/rangeSlider/RangeSlider';
import { RangeEndMax, RangeEndMin, Kind } from 'theme/atoms/rangeSlider/types';
// import PriceRange from 'theme/atoms/priceRange';
import SlidingPanel from 'theme/atoms/slidingPanel';
import SortAndFilterAccordionHeader, {
  defineItemsAndGetStringToDisplay,
  getStringToDisplay,
} from 'theme/atoms/sortAndFilterAccordionHeader/SortAndFilterAccordionHeader';
import SortItem from 'theme/atoms/sortItem';
import styles from './SortAndFilter.module.scss';
import {
  DEFAULT_SORTING_ITEM,
  SIZE_MAX_VALUE,
  SIZE_MIN_VALUE,
  SORT_OPTIONS,
  WEIGHT_MAX_VALUE,
  WEIGHT_MIN_VALUE,
} from './constants';

const itemsOpenByDefault = ['sortBy'];

interface SortAndFilterPanelProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  updateSelectedSortingItems: (index: number, updatedItem: SortItemType) => void;
  removeSelectedSortingItem: (updatedItem: SortItemType) => void;
}

const SortAndFilterPanel = ({
  isOpen,
  onClose,
  onSubmit,
  updateSelectedSortingItems,
  removeSelectedSortingItem,
}: SortAndFilterPanelProps): JSX.Element => {
  const { artworksSortAndFilterStore } = useStores();
  const {
    selectedSortingItems,
    selectedFilteringItems,
    addEmptySelectedSortingItem,
    resetSelectedFilteringAndSortingItems,
    resetActivePage,
  } = artworksSortAndFilterStore;

  const [openAccordionItems, setOpenAccordionItems] = useState<string[]>(itemsOpenByDefault);

  // useEffect(() => {
  //   getTagsList(); // TODO uncomment when we support adding tags
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const sortByElement = (
    <Accordion.Item
      id="sortBy"
      header={
        <SortAndFilterAccordionHeader
          label="Sort by"
          showItems={!openAccordionItems.includes('sortBy')}
          items={selectedSortingItems.filter((el) => el.checked).map((el) => el.label)}
        />
      }
    >
      {selectedSortingItems.map((item, index) => {
        return (
          <SortItem
            key={item.value}
            item={item}
            onChange={(item) => updateSelectedSortingItems(index, item)}
            onRemove={() => removeSelectedSortingItem(item)}
            options={SORT_OPTIONS}
            store={artworksSortAndFilterStore}
            defaultItem={DEFAULT_SORTING_ITEM}
          />
        );
      })}
      <Button
        text="add sort"
        onClick={() => addEmptySelectedSortingItem()}
        disabled={
          Boolean(selectedSortingItems.find((s) => s.value === '')) ||
          selectedSortingItems.length >= SORT_OPTIONS.length
        }
        buttonType="tertiary"
        iconStart={<AddIcon />}
        uppercase={false}
      />
    </Accordion.Item>
  );

  const visibilityElement = (
    <Accordion.Item
      id="visibility"
      header={
        <SortAndFilterAccordionHeader
          label="visibility"
          showItems={!openAccordionItems.includes('visibility')}
          items={Object.entries(ExternalExploringVisibility)
            .filter(([key]) => Boolean(selectedFilteringItems.find((f) => f.value === key)))
            .map(([_key, value]) => value)}
        />
      }
    >
      <FilterChecklist
        listItems={Object.entries(ExternalExploringVisibility).map(([key, val]) => ({ value: key, label: val }))}
        name="externalExploringVisibility"
        store={artworksSortAndFilterStore}
      />
    </Accordion.Item>
  );

  const yearElement = (
    <Accordion.Item
      id="year"
      header={
        <SortAndFilterAccordionHeader
          label="year"
          showItems={!openAccordionItems.includes('year')}
          stringToDisplay={defineItemsAndGetStringToDisplay({ key: 'year', selectedFilteringItems })}
        />
      }
    >
      <RadioButtonYearFilter
        kind={KindYearsNumber.year}
        store={artworksSortAndFilterStore}
        kindLike={KindYearsLike.yearLike}
      />
    </Accordion.Item>
  );

  const executedYearElement = (
    <Accordion.Item
      id="executedYear"
      header={
        <SortAndFilterAccordionHeader
          label="executed year"
          showItems={!openAccordionItems.includes('executedYear')}
          stringToDisplay={defineItemsAndGetStringToDisplay({ key: 'executedYear', selectedFilteringItems })}
        />
      }
    >
      <RadioButtonYearFilter
        kind={KindYearsNumber.executedYear}
        store={artworksSortAndFilterStore}
        kindLike={KindYearsLike.executedYearLike}
      />
    </Accordion.Item>
  );

  const getSizesToDisplay = (): string => {
    const size1From = selectedFilteringItems.find((el) => el.key === 'sizeNumber1From' && el.value !== SIZE_MIN_VALUE);
    const size1To = selectedFilteringItems.find((el) => el.key === 'sizeNumber1To' && el.value !== SIZE_MAX_VALUE);
    const size2From = selectedFilteringItems.find((el) => el.key === 'sizeNumber2From' && el.value !== SIZE_MIN_VALUE);
    const size2To = selectedFilteringItems.find((el) => el.key === 'sizeNumber2To' && el.value !== SIZE_MAX_VALUE);
    const size1ToDisplay = getStringToDisplay(size1From, size1To);
    const size2ToDisplay = getStringToDisplay(size2From, size2To);

    return [size1ToDisplay, size2ToDisplay]
      .map((el, i) => [`dimension ${i + 1}:`, el])
      .filter((el) => el[1])
      .map((el) => el.join(' '))
      .join(', ');
  };

  const getRangeSliderProps = (kind: Kind): RangeSliderProps => {
    return {
      kind,
      store: artworksSortAndFilterStore,
      minLabel: RangeEndMin.From,
      maxLabel: RangeEndMax.To,
      gap: 25,
      step: 1,
      minValue: SIZE_MIN_VALUE,
      maxValue: SIZE_MAX_VALUE,
    };
  };

  const sizeElement = (
    <Accordion.Item
      id="size"
      header={
        <SortAndFilterAccordionHeader
          label="size"
          showItems={!openAccordionItems.includes('size')}
          stringToDisplay={getSizesToDisplay()}
        />
      }
    >
      <div className={clsx(styles.accordionMenuSubtitle, styles.firstElement)}>First dimension</div>
      <RangeSlider {...getRangeSliderProps(Kind.sizeNumber1)} />
      <div className={styles.accordionMenuSubtitle}>Second dimension</div>
      <RangeSlider {...getRangeSliderProps(Kind.sizeNumber2)} />
    </Accordion.Item>
  );

  const weightElement = (): JSX.Element => {
    const weightToDislpay = defineItemsAndGetStringToDisplay({
      key: 'weightNumber',
      selectedFilteringItems,
      minValueToExclude: WEIGHT_MIN_VALUE,
      maxValueToExclude: WEIGHT_MAX_VALUE,
    });
    return (
      <Accordion.Item
        id="weight"
        header={
          <SortAndFilterAccordionHeader
            label="weight"
            showItems={!openAccordionItems.includes('weight')}
            stringToDisplay={weightToDislpay}
          />
        }
      >
        <RangeSlider {...getRangeSliderProps(Kind.weightNumber)} />
      </Accordion.Item>
    );
  };

  const statusElement = (
    <Accordion.Item
      id="status"
      header={
        <SortAndFilterAccordionHeader
          label="Status"
          showItems={!openAccordionItems.includes('status')}
          items={Object.keys(ArtworkStatusEnum)
            .filter((status) => !status.includes('Draft'))
            .filter((status) => Boolean(selectedFilteringItems.find((f) => f.value === status)))
            .map((status) => ArtworkStatusEnum[status])}
        />
      }
    >
      <FilterChecklist
        listItems={Object.keys(ArtworkStatusEnum)
          .filter((status) => !status.includes('Draft'))
          .map((status) => ({
            value: status,
            label: ArtworkStatusEnum[status],
          }))}
        name="status"
        store={artworksSortAndFilterStore}
      />
    </Accordion.Item>
  );

  return (
    <SlidingPanel
      title="Sort and filter"
      onClose={onClose}
      onReset={() => {
        resetSelectedFilteringAndSortingItems();
        resetActivePage();
      }}
      isOpen={isOpen}
      onSubmit={onSubmit}
      btnSubmitText="Show results"
    >
      <div className={styles.root}>
        <Accordion itemsOpenByDefault={openAccordionItems} onChange={setOpenAccordionItems}>
          {sortByElement}

          {/* <Accordion.Item id="tags" header="Tags"> // TODO uncomment when we support adding tags
            <FilterChecklist
              listItems={tags.map((el) => ({ value: el, label: el }))}
              name="tag"
              store={artworksSortAndFilterStore}
            />
          </Accordion.Item> */}

          {visibilityElement}
          {yearElement}
          {executedYearElement}
          {sizeElement}
          {weightElement()}
          {statusElement}

          {/* TODO: uncomment when ready */}
          {/* <Accordion.Item id="priceRange" header="Price range">
            <RangeSlider store={artworksSortAndFilterStore} gap={5000} step={1000} maxValue={100000} symbol="$" />
          </Accordion.Item> */}
        </Accordion>
      </div>
    </SlidingPanel>
  );
};

export default observer(SortAndFilterPanel);
