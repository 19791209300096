import { AxiosResponse } from 'axios';
import api from 'api';
import { ArtworkFormDto, UpdatedArtworkResponse } from './types';

export const createArtwork = (): Promise<AxiosResponse<UpdatedArtworkResponse>> => {
  return api.post(`/artworks`);
};

export const updateArtwork = (id: string, artwork: ArtworkFormDto): Promise<AxiosResponse<UpdatedArtworkResponse>> => {
  return api.patch(`/artworks/${id}`, artwork);
};

export const deleteArtwork = (id: string, artworkLastModifiedAt: Date): Promise<void> => {
  return api.delete(`/artworks/${id}`, { params: { artworkLastModifiedAt } });
};

export const confirmArtwork = (id: string, artworkLastModifiedAt: Date): Promise<void> => {
  return api.post(`/artworks/${id}/confirm`, { artworkLastModifiedAt });
};
