import ConsignmentsSortAndFilterStore from 'consignments/consignments/sortAndFilter/ConsignmentsSortAndFilterStore';
import { ConsignmentCounts } from 'consignments/types';
import Accordion from 'theme/atoms/Accordion';
import FilterChecklist from 'theme/atoms/filterChecklist';
import SortAndFilterAccordionHeader from 'theme/atoms/sortAndFilterAccordionHeader';
import UserCard from 'theme/atoms/userCard';
import TransactionsSortAndFilterStore from 'transactions/transactions/sortAndFilter/TransactionsSortAndFilterStore';
import { TransactionCounts } from 'transactions/transactions/types';

interface SortAndFilterAccordionForBEBasedListProps {
  field: string;
  label?: string;
  name: string;
  counts: ConsignmentCounts | TransactionCounts | null;
  openAccordionItems: string[];
  store: ConsignmentsSortAndFilterStore | TransactionsSortAndFilterStore;
}

const SortAndFilterAccordionForBEBasedList = ({
  field,
  label,
  name,
  counts,
  openAccordionItems,
  store,
}: SortAndFilterAccordionForBEBasedListProps): JSX.Element => {
  const countsKind = field + 'Counts';
  const list = counts && counts[countsKind].filter((el) => el[field].contactConnectionId); // TODO można usunąć ".filter((el) => el.consignee.contactConnectionId)" jak dodawanie samego siebie do kontaktów będzie zablokowane

  if (!list || list.length <= 1) return <></>;

  const filterChecklistItems = list.map((el) => {
    return {
      value: el[field].contactConnectionId || '',
      label: el[field].organizationName,
      element: (
        <UserCard id={el[field].contactConnectionId || ''} isSmall>
          <UserCard.Name name={el[field].organizationName} />
        </UserCard>
      ),
      count: el.count,
    };
  });

  const headerItems = counts[countsKind]
    .filter((el) =>
      Boolean(
        store.selectedFilteringItems
          .filter((f) => f.key === name)
          .find((f) => f.value === el[field].contactConnectionId)
      )
    )
    .map((el) => el[field].organizationName);

  return (
    <Accordion.Item
      id={field}
      header={
        <SortAndFilterAccordionHeader
          label={label || field}
          items={headerItems}
          showItems={!openAccordionItems.includes(field)}
        />
      }
    >
      <FilterChecklist name={name} listItems={filterChecklistItems} store={store} />
    </Accordion.Item>
  );
};

export default SortAndFilterAccordionForBEBasedList;
