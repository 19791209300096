import { makeAutoObservable, runInAction } from 'mobx';
import { RootStore } from 'RootStore';
import Big from 'big.js';
import { Contact } from 'domain/types/Contact.types';
import { Currency } from 'domain/types/Domain.types';
import { TransactionPrice } from 'domain/types/Transactions.types';
import { ContactConnection } from 'network/types';
import { TransactionActions, TransactionRole, transactionRoleToActions, transactionToRoles } from 'transactions/domain';
import {
  Buyer,
  TransactionCreateRequest,
  TransactionUpdateRequest,
  TransactionWrapper,
  TransactionCommissionReceiver,
} from 'transactions/requests.types';

export enum ValidationError {
  MissingBuyerRepresentative = 'MissingBuyerRepresentative',
  MissingBuyers = 'MissingBuyers',
  BuyersPaying0 = 'BuyersPaying0',
  CommissionReceiversReceiving0 = 'CommissionReceiversReceiving0',
  CommissionReceiversAreAddedAlthoughTotalCommissionIs0 = 'CommissionReceiversAreAddedAlthoughTotalCommissionIs0',
  NoPrice = 'NoPrice',
}

class TransactionFormStore {
  rootStore: RootStore;
  transactionWrapper: TransactionWrapper | null = null;
  validationErrors: ValidationError[] = [];

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  get totalSellerCommission(): Big | undefined {
    if (!this.transactionWrapper) return undefined;
    return this.transactionWrapper.transaction.sellerInfo?.totalCommission;
  }

  get totalBuyerCommission(): Big | undefined {
    if (!this.transactionWrapper) return undefined;
    return this.transactionWrapper.transaction.buyerInfo?.totalCommission;
  }

  setTransactionWrapper = (transactionWrapper: TransactionWrapper | null): void => {
    this.transactionWrapper = transactionWrapper;
  };

  canUserPerformAction = (action: TransactionActions): boolean => {
    if (!this.transactionWrapper) return false;

    const transactionRoles = transactionToRoles(this.transactionWrapper);
    const transactionActions = transactionRoles.flatMap(transactionRoleToActions);
    return !!transactionActions.includes(action);
  };

  setOrRemoveBuyerRepresentative = (contact?: ContactConnection): void => {
    if (!this.transactionWrapper) return;

    this.removeValidationError(ValidationError.MissingBuyerRepresentative);
    const representativeContact = contact
      ? {
          self: !contact.id,
          contactConnectionId: contact.id,
          organizationName: contact.contactOrganizationName,
          organizationTitle: contact.contactOrganizationTitle,
          organizationAddress: contact.contactOrganizationAddress,
        }
      : undefined;

    const buyerInfo = this.transactionWrapper.transaction.buyerInfo
      ? {
          ...this.transactionWrapper.transaction.buyerInfo,
          representativeContact,
        }
      : {
          buyers: [],
          buyersVotes: [],
          commissionReceivers: [],
          representativeContact,
        };

    this.transactionWrapper = {
      ...this.transactionWrapper,
      transaction: {
        ...this.transactionWrapper.transaction,
        buyerInfo,
      },
    };
  };

  setSellerCommissionReceivers = (sellerCommissionReceivers: TransactionCommissionReceiver[]): void => {
    this.removeValidationError(ValidationError.CommissionReceiversReceiving0);
    this.removeValidationError(ValidationError.CommissionReceiversAreAddedAlthoughTotalCommissionIs0);
    if (!this.transactionWrapper || !this.transactionWrapper.transaction.sellerInfo) return;

    this.transactionWrapper = {
      ...this.transactionWrapper,
      transaction: {
        ...this.transactionWrapper.transaction,
        sellerInfo: {
          ...this.transactionWrapper.transaction.sellerInfo,
          commissionReceivers: sellerCommissionReceivers,
        },
      },
    };
  };

  setBuyerCommissionReceivers = (buyerCommissionReceivers: TransactionCommissionReceiver[]): void => {
    this.removeValidationError(ValidationError.CommissionReceiversReceiving0);
    this.removeValidationError(ValidationError.CommissionReceiversAreAddedAlthoughTotalCommissionIs0);
    if (!this.transactionWrapper || !this.transactionWrapper.transaction.buyerInfo) return;

    this.transactionWrapper = {
      ...this.transactionWrapper,
      transaction: {
        ...this.transactionWrapper.transaction,
        buyerInfo: {
          ...this.transactionWrapper.transaction.buyerInfo,
          commissionReceivers: buyerCommissionReceivers,
        },
      },
    };
  };

  updateVote = (
    who: 'owner' | 'buyer' | 'buyerRepresentative' | 'sellerRepresentative',
    agrees: boolean
  ): Promise<boolean | void> => {
    return this.rootStore.transactionStore.updateVote(who, agrees);
  };

  createTransaction = (): Promise<void | boolean> => {
    if (!this.transactionWrapper) return Promise.reject();

    const tcdto: TransactionCreateRequest = {
      artworkId: this.transactionWrapper.transaction.artworkId,
      currency: this.transactionWrapper.transaction.currency,
      consignmentId: this.transactionWrapper.transaction.consignmentId,
      sellerInfo: {
        price: this.transactionWrapper.transaction.sellerInfo?.price,
        // user that is posting will automatically be assigned as representative
        commissionReceivers: this.transactionWrapper.transaction.sellerInfo?.commissionReceivers.map((x) => {
          return {
            iAmTheCommissionReceiver: Boolean(x.contact.self),
            contactId: x.contact.contactConnectionId,
            commission: Big(x.commission || 0),
          };
        }),
      },
      buyerInfo: {
        representativeContactId:
          this.transactionWrapper.transaction.buyerInfo &&
          this.transactionWrapper.transaction.buyerInfo.representativeContact?.contactConnectionId,
      },
    };
    return this.rootStore.transactionStore.createTransaction(tcdto);
  };

  updateTransaction = (): Promise<void | boolean> => {
    if (!this.transactionWrapper) return Promise.reject();

    const tudto: TransactionUpdateRequest = {
      buyerInfo: {
        ...this.transactionWrapper.transaction.buyerInfo,
        commissionReceivers:
          this.transactionWrapper.transaction.buyerInfo &&
          this.transactionWrapper.transaction.buyerInfo.commissionReceivers.length > 0
            ? this.transactionWrapper.transaction.buyerInfo.commissionReceivers.map((receiver) => {
                return {
                  iAmTheCommissionReceiver: Boolean(receiver.contact.self),
                  contactId: receiver.contact.contactConnectionId,
                  commission: Big(receiver.commission || 0),
                };
              })
            : [
                {
                  iAmTheCommissionReceiver: true,
                  contactId: undefined,
                  commission: Big(this.transactionWrapper.transaction.buyerInfo?.totalCommission || 0),
                },
              ],
        buyers: this.transactionWrapper.transaction.buyerInfo?.buyers.map((buyer) => ({
          iAmTheBuyer: Boolean(buyer.contact.self),
          contactId: buyer.contact.contactConnectionId,
          participationPercent: buyer.participationPercent,
        })),
      },
      transactionLastModifiedAt: this.transactionWrapper.transaction.modifiedAt || '',
    };
    return this.rootStore.transactionStore.patchTransaction(tudto);
  };

  addBuyer = (contact?: ContactConnection): void => {
    this.removeValidationError(ValidationError.MissingBuyers);
    this.removeValidationError(ValidationError.BuyersPaying0);

    const { selectedOrganization } = this.rootStore.organizationStore;
    if (!selectedOrganization || !this.transactionWrapper) return;

    const newContact: Contact = {
      self: !contact,
      organizationName: contact ? contact.contactOrganizationName : selectedOrganization.name,
      organizationTitle: contact ? contact.contactOrganizationTitle : selectedOrganization.title,
      organizationAddress: contact ? contact.contactOrganizationAddress : selectedOrganization.address,
      contactConnectionId: contact ? contact.id : undefined,
    };

    if (this.transactionWrapper.transaction.buyerInfo) {
      this.transactionWrapper = {
        ...this.transactionWrapper,
        transaction: {
          ...this.transactionWrapper.transaction,
          buyerInfo: {
            ...this.transactionWrapper.transaction.buyerInfo,
            buyers: [
              ...this.transactionWrapper.transaction.buyerInfo.buyers,
              {
                contact: {
                  ...newContact,
                },
                participationPercent:
                  this.transactionWrapper.transaction.buyerInfo.buyers.length === 0 ? 100 : undefined,
              },
            ],
          },
        },
      };
    }
  };

  removeBuyer = (contact: Contact): void => {
    this.removeValidationError(ValidationError.BuyersPaying0);

    if (!this.transactionWrapper) return;

    if (this.transactionWrapper.transaction.buyerInfo) {
      this.transactionWrapper = {
        ...this.transactionWrapper,
        transaction: {
          ...this.transactionWrapper.transaction,
          buyerInfo: {
            ...this.transactionWrapper.transaction.buyerInfo,
            buyers: this.transactionWrapper.transaction.buyerInfo.buyers.filter(
              (buyer) => buyer.contact.contactConnectionId !== contact.contactConnectionId
            ),
          },
        },
      };
      if (
        this.transactionWrapper.transaction.buyerInfo?.buyers &&
        this.transactionWrapper.transaction.buyerInfo.buyers.length === 1
      )
        this.updateBuyer(this.transactionWrapper.transaction.buyerInfo?.buyers[0].contact, 100);
    }
  };

  private updateBuyer = (buyerToUpdate: Contact, share: number): void => {
    if (!this.transactionWrapper) return;

    if (this.transactionWrapper.transaction.buyerInfo) {
      this.transactionWrapper = {
        ...this.transactionWrapper,
        transaction: {
          ...this.transactionWrapper.transaction,
          buyerInfo: {
            ...this.transactionWrapper.transaction.buyerInfo,
            buyers: this.transactionWrapper.transaction.buyerInfo.buyers.map((buyer) =>
              buyer.contact.contactConnectionId === buyerToUpdate.contactConnectionId
                ? { contact: buyerToUpdate, participationPercent: share }
                : buyer
            ),
          },
        },
      };
    }
  };

  updateAllBuyers = (buyers: Buyer[]): void => {
    this.removeValidationError(ValidationError.BuyersPaying0);

    if (!this.transactionWrapper) return;

    if (this.transactionWrapper.transaction.buyerInfo) {
      this.transactionWrapper = {
        ...this.transactionWrapper,
        transaction: {
          ...this.transactionWrapper.transaction,
          buyerInfo: {
            ...this.transactionWrapper.transaction.buyerInfo,
            buyers,
          },
        },
      };
    }
  };

  updateSellerPriceAndCurrency = (price: TransactionPrice, currency: Currency): void => {
    if (!this.transactionWrapper) return;

    if (this.transactionWrapper.transaction.sellerInfo) {
      this.transactionWrapper = {
        ...this.transactionWrapper,
        transaction: {
          ...this.transactionWrapper.transaction,
          currency: currency,
          sellerInfo: {
            ...this.transactionWrapper.transaction.sellerInfo,
            price: price,
          },
        },
      };
    }
  };

  updateTotalCommissionAndAssignItToRepresentative = (
    side: 'sellerInfo' | 'buyerInfo',
    updatedCommission: TransactionPrice
  ): void => {
    if (!this.transactionWrapper) return;

    if (this.transactionWrapper.transaction[side]) {
      this.transactionWrapper = {
        ...this.transactionWrapper,
        transaction: {
          ...this.transactionWrapper.transaction,
          [side]: {
            ...this.transactionWrapper.transaction[side],
            totalCommission: updatedCommission,
            commissionReceivers: this.transactionWrapper.transaction[side]?.commissionReceivers?.map((receiver) => {
              return { ...receiver, commission: receiver.contact.self ? updatedCommission : Big(0) };
            }),
          },
        },
      };
    }
  };

  checkValidations = (side: 'sellerRepresentative' | 'buyerRepresentative'): ValidationError[] => {
    if (!this.transactionWrapper) return [];

    const sellerInfo = this.transactionWrapper.transaction.sellerInfo;
    const buyerInfo = this.transactionWrapper.transaction.buyerInfo;

    const totalCommission =
      side === 'sellerRepresentative' && sellerInfo
        ? Big(sellerInfo.totalCommission || 0)
        : side === 'buyerRepresentative' && buyerInfo
        ? Big(buyerInfo.totalCommission || 0)
        : 0;
    const commissionReceivers =
      side === 'sellerRepresentative'
        ? this.transactionWrapper.transaction.sellerInfo?.commissionReceivers
        : this.transactionWrapper.transaction.buyerInfo?.commissionReceivers;

    const atLeastOneCommissionReceiverHasCommission0 =
      commissionReceivers &&
      commissionReceivers.length > 1 &&
      commissionReceivers.find(
        (el) => ((el.commission || el.commission === 0) && Big(el.commission).eq(0)) || !el.commission
      );

    const buyers = buyerInfo?.buyers;

    const atLeastOneBuyerPays0 =
      buyers &&
      buyers.length > 1 &&
      buyers.find(
        (el) =>
          ((el.participationPercent || el.participationPercent === 0) && Big(el.participationPercent).eq(0)) ||
          !el.participationPercent
      );

    if (Big(totalCommission).eq(0) && atLeastOneCommissionReceiverHasCommission0) {
      this.addValidationError(ValidationError.CommissionReceiversAreAddedAlthoughTotalCommissionIs0);
    } else if (atLeastOneCommissionReceiverHasCommission0) {
      this.addValidationError(ValidationError.CommissionReceiversReceiving0);
    }

    if (side === 'sellerRepresentative') {
      if (!buyerInfo || !buyerInfo?.representativeContact) {
        this.addValidationError(ValidationError.MissingBuyerRepresentative);
      }
    } else if (side === 'buyerRepresentative') {
      if (!buyers?.length) {
        this.addValidationError(ValidationError.MissingBuyers);
      } else if (atLeastOneBuyerPays0) {
        this.addValidationError(ValidationError.BuyersPaying0);
      }
    }

    const transactionRoles = this.transactionWrapper && transactionToRoles(this.transactionWrapper);
    const isSellerOwnerAsRepresentative =
      transactionRoles?.includes(TransactionRole.SellerOwner) &&
      transactionRoles?.includes(TransactionRole.SellerRepresentative);

    if (isSellerOwnerAsRepresentative && this.transactionWrapper.transaction.sellerInfo?.price.eq(0)) {
      this.addValidationError(ValidationError.NoPrice);
    }

    return this.validationErrors;
  };

  private addValidationError = (val: ValidationError): void => {
    runInAction(() => {
      this.validationErrors = [...this.validationErrors, val];
    });
  };

  removeValidationError = (val: ValidationError): void => {
    runInAction(() => {
      this.validationErrors = this.validationErrors.filter((el) => el !== val);
    });
  };

  resetAll = (): void => {
    runInAction(() => {
      this.transactionWrapper = null;
      this.validationErrors = [];
    });
  };
}

export default TransactionFormStore;
