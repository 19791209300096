import { AUTH_APP_CODE_LENGTH, PHONE_CODE_LENGTH, QR_SECRET_CHUNK_LENGTH, RECOVERY_CODE_LENGTH } from 'auth/constants';

export const EMAIL_REGEX = /^[a-zA-Z0-9_!#$%&'*+/=?`{|}~^.-]+@[a-zA-Z0-9.-]+$/;
export const UUID_REGEX = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;
export const AUTH_APP_CODE_REGEX = new RegExp(`^[0-9]{${AUTH_APP_CODE_LENGTH}}$`);
export const SINGLE_DIGIT_REGEX = /^[0-9]$/;
export const QR_SECRET_CHUNK_REGEX = new RegExp(`.{${QR_SECRET_CHUNK_LENGTH}}`, 'g');
export const RECOVERY_CODE_REGEX = new RegExp(`^[0-9]{${RECOVERY_CODE_LENGTH}}$`);
export const PHONE_CODE_REGEX = new RegExp(`^[0-9]{${PHONE_CODE_LENGTH}}$`);
export const SWIFT_CODE_REGEX = new RegExp(`^[A-Za-z]{6}[A-Za-z0-9]{2}([A-Za-z0-9]{3})?$`);
export const IBAN_CODE_REGEX = /^[A-Za-z]{2}[0-9]{2}[A-Za-z0-9]{11,30}$/;
