import { useNavigate, useParams } from 'react-router-dom';
import { routes } from 'app/routes/paths.const';
import { DEFAULT_CONSIGNMENT_CATEGORY } from 'consignments/constants';
import { ReactComponent as ArtworkIcon } from 'theme/assets/svg/image.svg';
import ActionButtons, { ActionButton } from 'theme/atoms/ActionButtons';
import Breadcrumbs, { Breadcrumb } from 'theme/atoms/Breadcrumbs';
import Topbar from 'theme/atoms/Topbar';

interface ConsignmentTopbarProps {
  artworkId: string;
  hasAccessToArtwork?: boolean;
}

const ConsignmentTopbar = ({ artworkId, hasAccessToArtwork }: ConsignmentTopbarProps): JSX.Element => {
  const { consignmentId } = useParams();
  const navigate = useNavigate();

  const BREADCRUMBS: Breadcrumb[] = [
    { label: 'Consignments', route: 'Consignments', param: DEFAULT_CONSIGNMENT_CATEGORY },
    { label: `consignment ${consignmentId}` },
  ];

  const ACTION_BUTTONS: ActionButton[] = [
    ...(hasAccessToArtwork
      ? [
          {
            text: 'Artwork details',
            icon: <ArtworkIcon />,
            onClick: () => navigate(routes.Artwork.path(artworkId)),
          },
        ]
      : []),
  ];

  return (
    <Topbar>
      <Breadcrumbs breadcrumbs={BREADCRUMBS} />
      <ActionButtons buttons={ACTION_BUTTONS} />
    </Topbar>
  );
};

export default ConsignmentTopbar;
