export const EXTENSIONS_IMAGES = ['png', 'jpg', 'jpeg', 'webp'];
export const EXTENSIONS_VIDEO = ['mpg', 'mpeg', 'mp4', 'mkv'];
export const EXTENSIONS_DOCUMENTS = ['doc', 'docx', 'pdf', 'odt', 'txt', 'ods'];

export const getUploadFileExtensionsInfo = (extensions: string[]): string => {
  return `File upload failed. Please upload a valid file. Accepted file extensions: ${extensions.join(', ')}.`;
};

export const isExtensionOk = (file: File, extensions: string[]): boolean => {
  const filenameDivided = file.name.split('.');
  const extension = filenameDivided && filenameDivided[filenameDivided.length - 1];
  return extensions.includes(extension);
};
