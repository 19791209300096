import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import AssetsList from 'artworks/components/assetsList';
import { ArtworkOwner } from 'artworks/types';
import { getAddress } from 'network/utils';
import UserCard from 'theme/atoms/userCard';
import styles from './ArtworkOwnership.module.scss';

const UNDISCLOSED = 'Undisclosed';

const getOwnerDetailElement = (owner: ArtworkOwner, index: number): React.JSX.Element => {
  const { participationPercent, contact } = owner;

  const contactConnectionId = contact?.contactConnectionId || index;
  const name = contact ? contact.organizationName : UNDISCLOSED;
  const title = contact ? contact.organizationTitle : UNDISCLOSED;
  const address = contact ? getAddress(contact.organizationAddress) : UNDISCLOSED;

  return (
    <div key={contactConnectionId} className={styles.ownerRow}>
      <UserCard id={name}>
        <UserCard.Contact name={name} title={title} address={address} />
      </UserCard>

      <div className={styles.participationPercent}>{participationPercent}%</div>
    </div>
  );
};

export const ArtworkOwnership = (): JSX.Element | null => {
  const { artworkStore } = useStores();
  const { artwork } = artworkStore;

  if (!artwork) return null;

  return (
    <div className={styles.root}>
      {artwork.owners?.map((owner, index) => getOwnerDetailElement(owner, index))}
      <AssetsList assetsUrls={artwork.assets.ownershipProofUrls} />
    </div>
  );
};

export default observer(ArtworkOwnership);
