import { action, makeObservable } from 'mobx';
import { RootStore } from 'RootStore';
import PaginationBasicStore, {
  PaginationBasicStoreProps as BasicStoreProps,
} from 'domain/PaginationBasicStore/PaginationBasicStore';
import * as requests from './requests';

export const BankInvoicesStoreProps = {
  fetchItems: action,
};

class BankInvoicesStore extends PaginationBasicStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    super(rootStore);
    this.rootStore = rootStore;
    makeObservable(this, { ...BankInvoicesStoreProps, ...BasicStoreProps });
  }

  fetchItems = async (): Promise<void> => {
    const { stateByCategoryId } = this.rootStore.bankInvoicesSortAndFilterStore;
    this.handleFetchItems(requests.fetchBankInvoices, { invoiceState: stateByCategoryId });
  };
}

export default BankInvoicesStore;
