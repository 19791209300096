import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { ReactComponent as SortAlfabeticallyIcon } from 'artworks/assets/sortAlfabetically.svg';
import { ReactComponent as SortAlfabeticallyDescIcon } from 'artworks/assets/sortAlfabeticallyDesc.svg';
import { CategoryItem } from 'consignments/consignments/sortAndFilter/constants';
import { SortAndFilterStoresWithPanel, SortItemType } from 'domain/SortAndFilterBasicStore/types';
import { ReactComponent as RemoveIcon } from 'theme/assets/svg/close.svg';
import Checkbox from 'theme/atoms/Checkbox';
import Select from 'theme/atoms/Select';
import { Option } from 'theme/atoms/Select/Select';
import styles from './SortItem.module.scss';

interface SortItemProps {
  item: SortItemType;
  onChange: (item: SortItemType) => void;
  onRemove: () => void;
  options: Option[];
  store: SortAndFilterStoresWithPanel;
  defaultItem: SortItemType;
  category?: CategoryItem;
}

const SortItem = ({ item, onChange, onRemove, options, store, defaultItem, category }: SortItemProps): JSX.Element => {
  const { selectedSortingItems } = store;

  const items = category ? selectedSortingItems.filter((el) => el.category === category) : selectedSortingItems;
  const dontAllowRemoving = items.length === 1;
  const dontAllowUnchecking = items.filter((el) => el.checked && el.value).length === 1 && item.checked;

  useEffect(() => {
    const itemToSet = item.value ? item : defaultItem;
    dontAllowRemoving && onChange({ ...itemToSet, checked: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dontAllowRemoving]);

  const selectedOptions = selectedSortingItems.map((item) => item.value);
  const availableOptions = options.filter(
    (option) => !selectedOptions.filter((el) => el !== item.value).includes(option.value)
  );

  return (
    <div className={styles.root}>
      <div className={clsx({ [styles.unclickable]: dontAllowUnchecking })}>
        <Checkbox
          name={item.value}
          checked={item.checked}
          onChange={(e) => !dontAllowUnchecking && onChange({ ...item, checked: e.target.checked })}
        />
      </div>
      <Select
        options={availableOptions}
        value={{ value: item.value, label: item.label }}
        onChange={(option) => onChange({ ...item, ...option, checked: true })}
        className={styles.select}
        maxHeight={180}
      />
      <button onClick={() => onChange({ ...item, dir: item.dir === 'Desc' ? 'Asc' : 'Desc' })}>
        {item.dir === 'Asc' ? <SortAlfabeticallyIcon /> : <SortAlfabeticallyDescIcon />}
      </button>
      <div className={clsx({ [styles.unclickable]: dontAllowRemoving })}>
        <button onClick={() => !dontAllowRemoving && onRemove()}>
          <RemoveIcon />
        </button>
      </div>
    </div>
  );
};

export default observer(SortItem);
