import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import styles from 'auth/Auth.module.scss';
import RecoveryCodeForm from 'auth/components/forms/RecoveryCodeForm';
import { TitleBlack, Subtitle, Button } from 'theme/atoms';
import Breadcrumbs, { Breadcrumb } from 'theme/atoms/Breadcrumbs';
import Topbar from 'theme/atoms/Topbar';
import { useUserContext } from 'utils/hooks';

const BREADCRUMBS: Breadcrumb[] = [{ label: 'Access recovery' }];

const LoginAccessRecovery = (): React.JSX.Element => {
  const navigate = useNavigate();
  const { homePath } = useUserContext();
  const {
    authStore: { loginWithToken, saveTokensToLocalStorage },
  } = useStores();

  const handleSubmit = (recoveryCode: string): void => {
    loginWithToken({ token: recoveryCode }).then((response) => {
      if (response.success) {
        saveTokensToLocalStorage();
        navigate(homePath);
      }
    });
  };

  return (
    <div className={clsx(styles.root, styles.oneColumnLayout)}>
      <Topbar>
        <Breadcrumbs breadcrumbs={BREADCRUMBS} />
      </Topbar>
      <div className={styles.innerWrapper}>
        <TitleBlack>Access recovery</TitleBlack>
        <Subtitle>
          Enter one of the recovery codes that were shown to you when you set up multi-factor authentication for the
          first time. These are each one-time use only.
        </Subtitle>

        <RecoveryCodeForm onSubmit={handleSubmit} />

        <div className={styles.useRecoveryCode}>
          <span>Still having problems? Contact</span>
          <Button
            buttonType="textButton"
            text="customer support."
            to={routes.LoginAccessRecovery.path()}
            className={styles.recoveryCodesButton}
          />
        </div>
      </div>
    </div>
  );
};

export default LoginAccessRecovery;
