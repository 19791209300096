import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { useStores } from 'RootStore';
import Big from 'big.js';
import NoDataToDisplay from 'theme/atoms/noDataToDisplay';
import { formatPrice } from 'utils/helpers';
import styles from './CurrentPeriod.module.scss';

const CurrentPeriod = (): JSX.Element => {
  const { billingStore } = useStores();
  const { upcomingInvoices, loadingInvoices } = billingStore;
  const lines = upcomingInvoices?.lines;

  if (loadingInvoices) return <></>;

  const Content = (): JSX.Element => {
    if (!lines || !lines?.length) {
      return <NoDataToDisplay copy="No data to display." height={100} />;
    } else {
      return (
        <div className={styles.items}>
          <div className={clsx(styles.heading, styles.headingDesc)}>Description</div>
          <div className={clsx(styles.heading, styles.headingQuantity)}>Qty</div>
          <div className={clsx(styles.heading, styles.headingUnitPrice)}>Unit price</div>
          <div className={clsx(styles.heading, styles.headingAmount)}>Amount</div>
          {lines.map((el) => {
            return (
              <>
                <div key={el.id + 'desc'} className={styles.desc}>
                  {el.description}
                </div>
                <div key={el.id + 'quantity'} className={styles.quantity}>
                  {el.quantity}
                </div>
                <div key={el.id + 'unitPrice'} className={styles.unitPrice}>
                  {formatPrice(Big(el.price.unit_amount).div(100), el.price.currency, 2)}
                </div>
                <div key={el.id + 'amount'} className={styles.amount}>
                  {formatPrice(Big(el.amount).div(100), el.currency, 2)}
                </div>
              </>
            );
          })}
        </div>
      );
    }
  };

  return (
    <div className={styles.root}>
      <h2>Current billing period usage</h2>
      <Content />
    </div>
  );
};

export default observer(CurrentPeriod);
