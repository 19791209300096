import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { routes } from 'app/routes/paths.const';
import styles from 'auth/Auth.module.scss';
import { Button, Subtitle, TitleBlack } from 'theme/atoms';
import Breadcrumbs, { Breadcrumb } from 'theme/atoms/Breadcrumbs';
import Topbar from 'theme/atoms/Topbar';
import { useTitle } from 'utils/hooks';

const BREADCRUMBS: Breadcrumb[] = [
  {
    label: 'Reset password',
  },
];

function ResetPasswordPasswordChanged(): JSX.Element {
  useTitle('Password changed successfully');

  return (
    <div className={clsx(styles.root, styles.oneColumnLayout)}>
      <Topbar>
        <Breadcrumbs breadcrumbs={BREADCRUMBS} />
      </Topbar>
      <div className={styles.innerWrapper}>
        <TitleBlack text="Password&nbsp;changed" />
        <Subtitle text="Your password has been successfully changed."></Subtitle>

        <Button text="Log in now" buttonType="textButton" to={routes.Login.path()} />
      </div>
    </div>
  );
}

export default observer(ResetPasswordPasswordChanged);
