import { InvoiceState } from 'domain/types/Invoices.types';

export const BANK_INVOICES_CATEGORIES_MAP = {
  verification: {
    label: 'In verification',
    condition: [InvoiceState.PendingBankDecision],
    id: 'verification',
  },
  payment: {
    label: 'Ready for payment',
    condition: [InvoiceState.Accepted],
    id: 'payment',
  },
  paid: {
    label: 'Paid',
    condition: [InvoiceState.Paid],
    id: 'paid',
  },
} as const;

export const DEFAULT_BANK_INVOICES_CATEGORY = BANK_INVOICES_CATEGORIES_MAP.verification.id;
