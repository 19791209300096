import { action, observable, makeObservable, runInAction } from 'mobx';
import { RootStore } from 'RootStore';
import PaginationBasicStore, {
  PaginationBasicStoreProps as BasicStoreProps,
} from 'domain/PaginationBasicStore/PaginationBasicStore';
import * as requests from './requests';

export const BankTransactionsStoreProps = {
  fetchItems: action,
  resetSelectedTransactions: action,
  selectedTransactions: observable,
  setSelectedTransactions: action,
  toggleTransactionSelection: action,
};

class BankTransactionsStore extends PaginationBasicStore {
  rootStore: RootStore;

  selectedTransactions = new Set<string>();

  constructor(rootStore: RootStore) {
    super(rootStore);
    this.rootStore = rootStore;
    makeObservable(this, { ...BankTransactionsStoreProps, ...BasicStoreProps });
  }

  fetchItems = async (): Promise<void> => {
    const { statusesByCategoryId } = this.rootStore.bankTransactionsSortAndFilterStore;

    this.handleFetchItems(requests.fetchBankTransactions, { status: statusesByCategoryId });
  };

  setSelectedTransactions = (transactions: Set<string>): void => {
    runInAction(() => {
      this.selectedTransactions = transactions;
    });
  };

  toggleTransactionSelection = (transactionId: string): void => {
    if (this.selectedTransactions.has(transactionId)) {
      runInAction(() => {
        this.selectedTransactions.delete(transactionId);
      });
    } else {
      runInAction(() => {
        this.selectedTransactions.add(transactionId);
      });
    }
  };

  resetSelectedTransactions = (): void => {
    this.setSelectedTransactions(new Set<string>());
  };

  //metoda do updatowania zaznaczen checkbox tylko do tych wyfiltrowanych traksakcji

  // updateSelectedTransactions = (): void => {
  //   const updatedSelected = new Set(
  //     Array.from(this.selectedTransactions).filter((transaction) =>
  //       this.filteredTransactions.some((transaction) => filteredTransaction.id === transaction)
  //     )
  //   );
  //   this.setSelectedTransactions(updatedSelected);
  // };

  resetAll = (): void => {
    runInAction(() => {
      this.selectedTransactions = new Set<string>();
    });
  };
}

export default BankTransactionsStore;
