import { useState, FormEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { OrganizationKind } from 'organization/types';
import { Button, Input, TitleBlack } from 'theme/atoms';
import Select from 'theme/atoms/Select';
import FormError from 'theme/atoms/formError';
import { useFormValidation } from 'utils/hooks';
import styles from './CreateOrganization.module.scss';
import { OrganizationCreateForm } from './types';
import { useOrganizationForm } from './useOrganizationForm';

const capitalizeFirstLetter = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const OrganizationDetails = (): JSX.Element => {
  const { organizationStore, userStore } = useStores();
  const { creatingOrganizationKind, createOrganization, setCreatingOrganizationKind, loading } = organizationStore;
  const { updateUserProfile } = userStore;
  const isIndividual = creatingOrganizationKind === OrganizationKind.Individual; // not from useOrganizationKinds because it won't update there
  const {
    requiredFields,
    formValidationsAsArray,
    formValidationsAsObject,
    FIELDS_OF_INDIVIDUAL,
    FIELDS_OF_INSTITUTIONAL_1,
    FIELDS_OF_INSTITUTIONAL_2,
  } = useOrganizationForm();
  const [profileInfo, setProfileInfo] = useState<Partial<OrganizationCreateForm> | null>(null);

  const { cleanValidationErrors, handleValidation, validation, areThereAnyValidationErrors } =
    useFormValidation(formValidationsAsObject);

  const handleDataChange = (field: keyof OrganizationCreateForm, value: string): void => {
    cleanValidationErrors([`${field}Required`]);
    setProfileInfo({ ...profileInfo, [field]: value });
  };

  const onSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void | undefined> => {
    event.preventDefault();

    formValidationsAsArray.forEach((el) =>
      handleValidation(el, 'Required', !profileInfo || !profileInfo[el.split('Required')[0]])
    );

    if (!profileInfo || !!requiredFields.find((el) => !profileInfo[el])) {
      return;
    } else {
      const dtoForCreateOrg = {
        ...(profileInfo as OrganizationCreateForm),
        type: creatingOrganizationKind as OrganizationKind,
        address: {
          country: profileInfo.country as string,
          city: profileInfo.city as string,
          addressLine: profileInfo.address || '',
        },
        name: isIndividual ? profileInfo.firstname + ' ' + profileInfo.lastname : (profileInfo.companyName as string),
      };
      const fieldsToDelete = ['firstname', 'lastname', 'companyName', 'country', 'city', 'addressLine'];
      fieldsToDelete.forEach((el) => delete dtoForCreateOrg[el]);

      const dtoForUpdateUser = {
        firstname: profileInfo.firstname,
        lastname: profileInfo.lastname,
        country: profileInfo.country,
        city: profileInfo.city,
      };

      updateUserProfile(dtoForUpdateUser, true);
      createOrganization(dtoForCreateOrg);
    }
  };

  const handleChangeOrgType = (): void => {
    cleanValidationErrors(formValidationsAsArray);
    setCreatingOrganizationKind(isIndividual ? OrganizationKind.Institutional : OrganizationKind.Individual);
  };

  const displayFormElement = (el): JSX.Element => {
    return el.optionsForSelect ? (
      <Select
        withSearchbox
        key={el.value}
        options={el.optionsForSelect}
        value={{
          value: profileInfo?.[el.value] || '',
          label: profileInfo?.[el.value] || '',
        }}
        onChange={(option) => handleDataChange(el.value, option.value)}
        placeholder={el.placeholder || `Choose your ${el.label || el.value}`}
        label={capitalizeFirstLetter(el.title || el.label || el.value)}
        error={validation[el.value + 'Required']}
        asFormElement
      />
    ) : (
      <Input
        key={el.value}
        label={capitalizeFirstLetter(el.title || el.label || el.value)}
        name={el.value}
        onChange={(e) => handleDataChange(el.value, e.target.value)}
        placeholder={el.placeholder || `Enter your ${el.label || el.value}`}
        value={profileInfo?.[el.value] || ''}
        error={validation[el.value + 'Required']}
      />
    );
  };

  const formContentOfIndividual = (): JSX.Element => {
    return isIndividual ? <>{FIELDS_OF_INDIVIDUAL.map(displayFormElement)}</> : <></>;
  };

  const formContentOfInstitutional = (): JSX.Element => {
    if (isIndividual) return <></>;
    return (
      <>
        <div className={styles.formSubtitle}>Your details</div>
        {FIELDS_OF_INSTITUTIONAL_1.map(displayFormElement)}
        <div className={styles.formSubtitle}>Company details</div>
        {FIELDS_OF_INSTITUTIONAL_2.map(displayFormElement)}
      </>
    );
  };

  const changeOrgType = (
    <div className={styles.changeOrgType}>
      Don't want to have an {isIndividual ? 'individual' : 'institutional'} account?
      <Button
        text={`Create an ${isIndividual ? 'institutional' : 'individual'} account instead`}
        buttonType="textButton"
        onClick={handleChangeOrgType}
      />
    </div>
  );

  return (
    <div className={styles.innerWrapper}>
      <TitleBlack text={isIndividual ? 'Fill out your profile information' : 'Fill out institution information'} />
      <form onSubmit={onSubmit} className={styles.form}>
        {formContentOfIndividual()}
        {formContentOfInstitutional()}
        <FormError showError={areThereAnyValidationErrors} />
        <Button text="Continue" loading={loading} type="submit" maxWidth className={styles.submitBtn} />
      </form>
      {changeOrgType}
    </div>
  );
};

export default observer(OrganizationDetails);
