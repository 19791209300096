import { makeAutoObservable, runInAction } from 'mobx';
import { RootStore } from 'RootStore';
import { Artwork, Author } from 'artworks/types';
import Big from 'big.js';
import { Asset, AssetWithKey } from 'domain/types/Asset.types';
import { Contact } from 'domain/types/Contact.types';
import { ContactConnection } from 'network/types';
import { ARTWORK_STEPS, ASSETS_FIELDS, FORM_FIELDS } from './constants';
import * as requests from './requests';
import { ArtworkForm, ArtworkFormDto, AuthorWrapper, OwnerWrapper } from './types';

const INIT_ARTWORK_FORM: ArtworkForm = FORM_FIELDS.reduce((prevFields, currentField) => {
  if (currentField === 'author')
    return {
      ...prevFields,
      [currentField]: {
        iAmTheAuthor: false,
      },
    };
  else
    return {
      ...prevFields,
      [currentField]: ASSETS_FIELDS.includes(currentField) || currentField === 'owners' ? [] : null,
    };
}, {} as ArtworkForm);

class AddArtworkStore {
  rootStore: RootStore;
  fetching = false;
  loading = false;
  artworkForm: ArtworkForm = INIT_ARTWORK_FORM;
  ownersToDisplay: Contact[] = [];
  authorToDisplay: Author | null = null;
  artworkToEdit = true;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  get totalShares(): Big {
    return this.artworkForm.owners.reduce((total, owner) => Big(total).plus(owner.participationPercent || 0), Big(0));
  }

  get ownersWithEmptyParticipationPercent(): OwnerWrapper[] {
    return this.artworkForm.owners.filter((owner) => Big(owner.participationPercent || 0).eq(0));
  }

  get iAmTheOwner(): boolean {
    return Boolean(this.ownersToDisplay.find((contact) => contact.self));
  }

  get contactsToExclude(): string[] {
    return this.ownersToDisplay.map((owner) => owner.contactConnectionId || '');
  }

  initializeCreatingNewArtwork = (): void => {
    this.authorToDisplay = null;
    this.ownersToDisplay = [];

    this.artworkForm = {
      ...INIT_ARTWORK_FORM,
    };
  };

  setArtworkToEdit = (val: boolean): void => {
    this.artworkToEdit = val;
  };

  fetchArtworkAndSetItToEdition = async (id: string): Promise<void> => {
    runInAction(() => (this.fetching = true));
    const artwork = await this.fetchArtwork(id);
    runInAction(() => (this.fetching = false));

    if (!artwork) {
      this.artworkToEdit = false;
    } else {
      this.setArtworkFormFields(artwork);
      this.setAuthorAndOwnersFromArtwork(artwork);
    }
  };

  updateArtworkForm = (
    name: string,
    value:
      | string
      | number
      | boolean
      | null
      | (typeof ARTWORK_STEPS)[number]['id']
      | AssetWithKey[]
      | AuthorWrapper
      | OwnerWrapper[]
  ): void => {
    if (name === 'artistResellRightsPercent') {
      const valueAsNumber = Number(value);
      this.artworkForm = {
        ...this.artworkForm,
        [name]: !isNaN(valueAsNumber) ? valueAsNumber : null,
      };
    }
    this.artworkForm = {
      ...this.artworkForm,
      [name]: value,
    };
  };

  saveDraft = async (artworkId: string, showToastOnSuccess = false): Promise<void | string> => {
    if (artworkId === 'new') {
      return this.createArtwork().then((id) => {
        if (id) return this.updateArtwork(id, showToastOnSuccess);
      });
    } else {
      return this.updateArtwork(artworkId, showToastOnSuccess);
    }
  };

  saveAndConfirm = async (artworkId: string): Promise<void> => {
    await this.saveDraft(artworkId);
    await this.confirmArtworkDraft(artworkId);
  };

  confirmArtworkDraft = (id: string): Promise<void | string> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.loading = true));

    return requests
      .confirmArtwork(id, this.artworkForm.artworkLastModifiedAt)
      .then(() => {
        addToast(toastMessages.ARTWORKS.UPDATE_SUCCESS, 'success');
      })
      .catch((err) => {
        this.rootStore.userStore.checkIfUserHasNoPaymentMethods(err.response?.data.type);
        addToast(toastMessages.ARTWORKS.UPDATE_ERROR, 'error');
      })
      .finally(() => runInAction(() => (this.loading = false)));
  };

  setImages = (images: AssetWithKey[]): void => {
    this.updateArtworkForm('imageUrls', images);
  };

  setContactAsAuthorOrOwnerInStoreAndUpdateForm = (
    isMe: boolean,
    contactType: 'author' | 'owners',
    contact?: ContactConnection
  ): void => {
    const { selectedOrganization } = this.rootStore.organizationStore;
    if (!selectedOrganization) return;

    const newContact: Contact = {
      self: isMe,
      organizationName: contact ? contact.contactOrganizationName : selectedOrganization.name,
      organizationTitle: contact ? contact.contactOrganizationTitle : selectedOrganization.title,
      organizationAddress: contact ? contact.contactOrganizationAddress : selectedOrganization.address,
      contactConnectionId: contact ? contact.id : '',
    };

    if (contactType === 'author') {
      this.authorToDisplay = {
        name: newContact.organizationName,
        address: newContact.organizationAddress,
        title: newContact.organizationTitle,
        id: newContact.contactConnectionId,
        self: newContact.self,
      };
      this.artworkForm = {
        ...this.artworkForm,
        [contactType]: isMe ? { iAmTheAuthor: true } : { contactConnectionAuthor: contact?.id, iAmTheAuthor: false },
      };
    } else {
      this.artworkForm = {
        ...this.artworkForm,
        [contactType]: isMe
          ? [
              ...this.artworkForm.owners,
              {
                iAmTheOwner: true,
                participationPercent: null,
              },
            ]
          : [
              ...this.artworkForm.owners,
              {
                contactConnectionOwner: contact?.id,
                iAmTheOwner: false,
                participationPercent: null,
              },
            ],
      };
      this.ownersToDisplay = [...this.ownersToDisplay, newContact];
    }
  };

  removeAuthorFromStore = (): void => {
    this.authorToDisplay = null;
  };

  setOwnersToDisplay = (owners: Contact[]): void => {
    this.ownersToDisplay = owners;
  };

  deleteOwnerFromStoreAndUpdateForm = (contact: Contact): void => {
    const filteredOwners = this.ownersToDisplay?.filter(
      (owner) =>
        (contact.self && !owner.self) || (!contact.self && owner.contactConnectionId !== contact.contactConnectionId)
    );

    const formFilteredOwners = this.artworkForm.owners.filter(
      (owner) =>
        (contact.self && !owner.iAmTheOwner) ||
        (!contact.self && owner.contactConnectionOwner !== contact.contactConnectionId)
    );

    this.setOwnersToDisplay(filteredOwners);
    this.updateArtworkForm('owners', formFilteredOwners);
  };

  deleteArtworkDraft = (id: string): Promise<void> => {
    runInAction(() => (this.loading = true));

    return requests
      .deleteArtwork(id, this.artworkForm.artworkLastModifiedAt)
      .finally(() => runInAction(() => (this.loading = false)));
  };

  private createArtwork = (): Promise<string> => {
    runInAction(() => (this.loading = true));

    return requests.createArtwork().then(({ data }) => {
      runInAction(() => (this.artworkForm = { ...this.artworkForm, artworkLastModifiedAt: data.metadata.modifiedAt }));
      return data.id;
    });
  };

  private getArtworkFormWithUrlsAdjustedAsDto = (): ArtworkFormDto => {
    return Object.keys(this.artworkForm).reduce((prevFields, currentField) => {
      if (ASSETS_FIELDS.includes(currentField)) {
        return { ...prevFields, [currentField]: this.artworkForm[currentField]?.map((el: Asset) => el.url) };
      } else if (currentField === 'owners' && this.artworkForm[currentField].length === 0) {
        return { ...prevFields };
      } else {
        return { ...prevFields, [currentField]: this.artworkForm[currentField] };
      }
    }, {} as ArtworkFormDto);
  };

  private fetchArtwork = (id: string): Promise<Artwork | void> => {
    return this.rootStore.artworkStore.fetchArtwork(id);
  };

  private setArtworkFormFields = (artwork: Artwork): void => {
    this.artworkForm = FORM_FIELDS.reduce((prevFields, currentField) => {
      if (ASSETS_FIELDS.includes(currentField)) {
        return { ...prevFields, [currentField]: artwork.assets[currentField] || [] };
      } else if (currentField === 'author') {
        return { ...prevFields };
      } else if (currentField === 'existedBefore' || currentField === 'artistResellRightsPercent') {
        return { ...prevFields, [currentField]: artwork[currentField] };
      } else if (currentField === 'artworkLastModifiedAt') {
        return { ...prevFields, [currentField]: artwork.modifiedAt || '' };
      } else if (currentField === 'owners') {
        return {
          ...prevFields,
          [currentField]: artwork.owners.map((owner) =>
            owner.contact?.self
              ? { iAmTheOwner: true, participationPercent: Big(owner.participationPercent || 0) }
              : {
                  iAmTheOwner: false,
                  contactConnectionOwner: owner.contact?.contactConnectionId,
                  participationPercent: Big(owner.participationPercent || 0),
                }
          ),
        };
      } else {
        return { ...prevFields, [currentField]: artwork[currentField] || null };
      }
    }, {} as ArtworkForm);
  };

  private setAuthorAndOwnersFromArtwork = (artwork: Artwork): void => {
    if (!artwork) return;

    if (artwork.author !== null) {
      const iAmTheAuthor = artwork.author.id === this.rootStore.organizationStore.selectedOrganization?.id;

      if (iAmTheAuthor) {
        this.setContactAsAuthorOrOwnerInStoreAndUpdateForm(true, 'author');
      } else {
        this.authorToDisplay = {
          name: artwork.author.name,
          address: artwork.author.address,
          title: artwork.author.title,
        };
      }
    }
    this.ownersToDisplay = artwork.owners.map((owner): Contact => {
      return {
        self: owner.contact && owner.contact.self,
        contactConnectionId: owner.contact ? owner.contact?.contactConnectionId : '',
        organizationName: owner.contact ? owner.contact.organizationName : '',
        organizationTitle: owner.contact ? owner.contact.organizationTitle : '',
        organizationAddress: owner.contact ? owner.contact.organizationAddress : { country: '', city: '' },
      };
    });
  };

  private updateArtwork = (id: string, withToast: boolean): Promise<void | string> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.loading = true));

    return requests
      .updateArtwork(id, this.getArtworkFormWithUrlsAdjustedAsDto())
      .then(({ data }) => {
        if (withToast) addToast(toastMessages.ARTWORKS.UPDATE_SUCCESS, 'success');
        runInAction(
          () => (this.artworkForm = { ...this.artworkForm, artworkLastModifiedAt: data.metadata.modifiedAt })
        );
        return data.id;
      })
      .catch(() => addToast(toastMessages.ARTWORKS.UPDATE_ERROR, 'error'))
      .finally(() => runInAction(() => (this.loading = false)));
  };

  resetAll = (): void => {
    runInAction(() => {
      this.artworkForm = INIT_ARTWORK_FORM;
      this.ownersToDisplay = [];
      this.authorToDisplay = null;
      this.artworkToEdit = true;
    });
  };
}

export default AddArtworkStore;
