import clsx from 'clsx';
import { ArtworkStatusEnum } from 'artworks/types';
import { ReactComponent as CrossIcon } from 'theme/assets/svg/close.svg';
import { ReactComponent as EditIcon } from 'theme/assets/svg/edit.svg';
import { ReactComponent as WarnIcon } from 'theme/assets/svg/warn.svg';
import IconComponent, { icons } from '../iconComponent';
import styles from './StatusIcon.module.scss';

interface StatusIconComponentProps {
  status: ArtworkStatusEnum;
}

const StatusIcon = ({ status }: StatusIconComponentProps): JSX.Element | null => {
  switch (status) {
    case ArtworkStatusEnum.Owned:
    case ArtworkStatusEnum.OnConsignment:
      return <div className={clsx(styles.dot, styles.green)} />;
    case ArtworkStatusEnum.OwnedReserved:
    case ArtworkStatusEnum.OnConsignmentReserved:
      return <div className={clsx(styles.dot, styles.yellow)} />;
    case ArtworkStatusEnum.OwnedTransactionAccepted:
      return <div className={clsx(styles.dot, styles.violet)} />;
    case ArtworkStatusEnum.OwnedConsigned:
    case ArtworkStatusEnum.OwnedConsignedAwaitingConsigning:
    case ArtworkStatusEnum.ThirdPartyAwaitingConsigning:
      return <div className={clsx(styles.dot, styles.redEmpty)} />;
    case ArtworkStatusEnum.ThirdParty:
      return <div className={clsx(styles.dot, styles.red)} />;
    case ArtworkStatusEnum.Draft:
      return <EditIcon />;
    case ArtworkStatusEnum.DraftAwaitingApproval:
      return <WarnIcon />;
    case ArtworkStatusEnum.DraftRejected:
      return <CrossIcon />;
    case ArtworkStatusEnum.DraftApproved:
      return <IconComponent icon={icons.tick} />;
    default:
      return null;
  }
};

export default StatusIcon;
