import { useStores } from 'RootStore';
import { FormValidation } from 'domain/types';
import { OrganizationKind } from 'organization/types';
import { Option } from 'theme/atoms/Select/Select';
import { COUNTRIES } from './countriesConstants';
import { OrganizationCreateForm } from './types';

type FieldData = {
  value: string;
  required?: boolean;
  optionsForSelect?: Option[];
  label?: string;
  title?: string;
  placeholder?: string;
};

const FIELDS_OF_INDIVIDUAL: FieldData[] = [
  { required: true, value: 'firstname', label: 'first name' },
  { required: true, value: 'lastname', label: 'last name' },
  { required: true, value: 'country', optionsForSelect: COUNTRIES.sort((a, b) => a.value.localeCompare(b.value)) },
  { required: true, value: 'city' },
  { required: true, value: 'taxId', label: 'tax ID' },
];

const FIELDS_OF_INSTITUTIONAL_1: FieldData[] = [
  { required: true, value: 'firstname', label: 'first name' },
  { required: true, value: 'lastname', label: 'last name' },
  { required: true, value: 'userTitle', label: 'role', title: 'Your role' }, // TODO Select z rolami z BE
];

const FIELDS_OF_INSTITUTIONAL_2: FieldData[] = [
  { required: true, value: 'companyName', label: 'company name', placeholder: 'Enter company name' },
  { required: true, value: 'address', placeholder: 'Enter company address' },
  { required: true, value: 'city', placeholder: 'Enter company location' },
  {
    required: true,
    value: 'country',
    optionsForSelect: COUNTRIES.sort((a, b) => a.value.localeCompare(b.value)),
    placeholder: 'Select country',
  },
  { value: 'url', label: 'website address', title: 'WWW', placeholder: 'Enter website address' },
  {
    value: 'profileDescription',
    label: "company's profile",
    title: "Company's profile",
    placeholder: "Describe company's profile",
  },
  { value: 'title', label: 'company category', title: 'Company category, e.g. gallery' },
  { required: true, value: 'taxId', label: 'tax ID' },
];

type UseOrganizationFormReturn = {
  requiredFields: string[];
  formValidationsAsArray: string[];
  formValidationsAsObject: Partial<OrganizationCreateForm>;
  FIELDS_OF_INDIVIDUAL: FieldData[];
  FIELDS_OF_INSTITUTIONAL_1: FieldData[];
  FIELDS_OF_INSTITUTIONAL_2: FieldData[];
};

export const useOrganizationForm = (): UseOrganizationFormReturn => {
  const {
    organizationStore: { creatingOrganizationKind },
  } = useStores();
  const isIndividual = creatingOrganizationKind === OrganizationKind.Individual;

  const requiredFields = (
    isIndividual ? FIELDS_OF_INDIVIDUAL : [...FIELDS_OF_INSTITUTIONAL_1, ...FIELDS_OF_INSTITUTIONAL_2]
  )
    .filter((el) => el.required)
    .map((el) => el.value);

  const formValidationsAsArray = requiredFields.map((el) => el + 'Required');

  const formValidationsAsObject: FormValidation = formValidationsAsArray.reduce((prevFields, currentField) => {
    return { ...prevFields, [currentField]: '' };
  }, {});

  return {
    requiredFields,
    formValidationsAsArray,
    formValidationsAsObject,
    FIELDS_OF_INDIVIDUAL,
    FIELDS_OF_INSTITUTIONAL_1,
    FIELDS_OF_INSTITUTIONAL_2,
  };
};
