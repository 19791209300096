import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { SidebarItemType } from 'sidebar/types';
import UncutNameWrapper from '../UncutNameWrapper/UncutNameWrapper';
import styles from './SidebarItem.module.scss';

interface ItemDataProps {
  icon?: JSX.Element;
  name: string;
  isCut?: boolean;
  goTo: string;
}

const urlsDependentOfItems = [{ itemGoTo: '/network/list/network', dependentUrls: ['/network/list/invitations'] }]; // dependent urls will also make Sidebar element seen as active

function ItemData({ icon, name, isCut, goTo }: ItemDataProps): JSX.Element {
  return (
    <div
      className={clsx(styles.itemDesc, {
        [styles.activeItem]:
          location.pathname.startsWith('/' + goTo.split('/')[1]) ||
          urlsDependentOfItems.find((el) => el.itemGoTo === goTo && el.dependentUrls.includes(location.pathname)),
      })}
    >
      <div className={styles.icon}>{icon}</div>
      <div className={clsx(styles.name, isCut && styles.isCut)}>{name}</div>
    </div>
  );
}

interface SidebarItemProps {
  icon?: JSX.Element;
  name: string;
  number?: number;
  goTo: string;
  subcategories?: SidebarItemType[];
  onClick: () => void;
  isSelected: boolean;
}

function SidebarItem({ icon, name, number, goTo, subcategories, onClick, isSelected }: SidebarItemProps): JSX.Element {
  const navigate = useNavigate();

  const handleSelectCategory = (): void => {
    navigate(goTo);
    onClick();
  };

  const itemContent = (name: string, goTo: string, icon?: JSX.Element, number?: number): JSX.Element => {
    return (
      <UncutNameWrapper uncutName={<ItemData icon={icon} name={name} goTo={goTo} />}>
        <div className={styles.item}>
          <ItemData icon={icon} name={name} isCut goTo={goTo} />
          {!!number && <div className={styles.number}>{number < 10 ? number : '9+'}</div>}
        </div>
      </UncutNameWrapper>
    );
  };

  return (
    <>
      <button className={styles.root} onClick={handleSelectCategory}>
        {itemContent(name, goTo, icon, number)}
      </button>
      {subcategories &&
        subcategories.map((s) => (
          <button
            className={clsx(styles.root, styles.subcategory, { [styles.isActive]: isSelected })}
            onClick={() => navigate(s.goTo)}
            key={s.name}
          >
            {itemContent(s.name, s.goTo, s.icon, s.number)}
          </button>
        ))}
    </>
  );
}

export default SidebarItem;
