import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import { FormValidation } from 'domain/types';
import { ReactComponent as WarnIcon } from 'theme/assets/svg/warn.svg';
import { Button } from 'theme/atoms';
import Checkbox from 'theme/atoms/Checkbox';
import { useFormValidation } from 'utils/hooks';
import styles from './Actions.module.scss';

const FORM_VALIDATIONS: FormValidation = {
  checkboxRequired: '',
};

const Actions = (): JSX.Element => {
  const { approveArtworkStore } = useStores();
  const {
    shouldIApproveArtwork,
    iAmTheAuthor,
    artworkToApprove: artwork,
    approveArtwork,
    rejectArtwork,
    deleteArtwork,
  } = approveArtworkStore;

  const navigate = useNavigate();
  const [checkboxIsChecked, setCheckboxIsChecked] = useState(false);
  const { cleanValidationErrors, handleValidation, validation } = useFormValidation(FORM_VALIDATIONS);

  if (!artwork) return <></>;

  const handleApprove = (): void => {
    handleValidation('checkboxRequired', '(required)', !checkboxIsChecked);
    if (!checkboxIsChecked || !artwork.id) return;
    approveArtwork(artwork.id);
  };

  const handleReject = (): void => {
    if (!artwork.id) return;
    rejectArtwork(artwork.id);
  };

  const handleDelete = (): void => {
    deleteArtwork(artwork.id).then(() => navigate(routes.Artworks.path()));
  };

  const handleChangeCheckbox = (): void => {
    cleanValidationErrors(['checkboxRequired']);
    setCheckboxIsChecked(!checkboxIsChecked);
  };

  const awaitingApprovals =
    artwork.artworkAwaitingApprovals &&
    `Awaiting approvals ${artwork.artworkAwaitingApprovals.awaiting}/${artwork.artworkAwaitingApprovals.total}`;

  const getLabelOfCheckbox = (): string | void => {
    if (artwork.isOwner && iAmTheAuthor) {
      return `I declare I'm the owner and I confirm that this is the artwork I created.`;
    } else if (artwork.isOwner && !iAmTheAuthor) {
      return `I declare I'm the owner`;
    } else if (!artwork.isOwner && iAmTheAuthor) {
      return 'I confirm that this is the artwork I created.';
    }
  };

  return (
    <>
      {shouldIApproveArtwork && (
        <>
          <Checkbox
            name="confirm"
            checked={checkboxIsChecked}
            onChange={handleChangeCheckbox}
            label={getLabelOfCheckbox() || ''}
            error={validation.checkboxRequired}
          />
          <div className={styles.buttonsWrapper}>
            <Button text="Approve" maxWidth onClick={handleApprove} />
            <Button text="Reject" buttonType="secondary" maxWidth onClick={handleReject} />
          </div>
        </>
      )}
      {artwork.isEditable && (
        <>
          {artwork.artworkAwaitingApprovals && (
            <div className={styles.status}>
              <WarnIcon />
              {awaitingApprovals}
            </div>
          )}
          <div className={styles.buttonsWrapper}>
            <Button
              text="Edit"
              buttonType="secondary"
              maxWidth
              onClick={() => navigate(routes.EditArtwork.path(artwork.id))}
            />
            <Button text="Delete" buttonType="secondary" maxWidth onClick={handleDelete} />
          </div>
        </>
      )}
    </>
  );
};

export default observer(Actions);
