import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { useStores } from 'RootStore';
import {
  TransactionActions,
  shouldThisOrgEditTheTransactionNow,
  shouldThisOrgHavePermissionToEditTheTransaction,
} from 'transactions/domain';
import { TransactionWrapper } from 'transactions/requests.types';
import { useTransactionRoles } from 'utils/hooks';
import TransactionTopbar from '../components/TransactionTopbar';
import Actions from '../components/actions';
import Header from '../components/header';
import TransactionBilling from '../components/transactionBilling/TransactionBilling';
import TransactionInvoices from '../components/transactionInvoices/TransactionInvoices';
import styles from './TransactionForm.module.scss';
import BankAccountForBuyer from './sections/bankAccountForBuyer';
import BuyerRepresentative from './sections/buyerRepresentative';
import Buyers from './sections/buyers/Buyers';
import SplitCommission from './sections/splitCommission/SplitCommission';

export const TransactionForm = (): JSX.Element => {
  const { transactionFormStore } = useStores();
  const transactionWrapper = transactionFormStore.transactionWrapper as TransactionWrapper;

  const [isSummaryView, setIsSummaryView] = useState<boolean>(false);
  const { isSellerSide, isSellerOwnerAsRepresentative } = useTransactionRoles({ transactionWrapper });

  useEffect(() => {
    if (
      !shouldThisOrgHavePermissionToEditTheTransaction(transactionWrapper) ||
      !shouldThisOrgEditTheTransactionNow(transactionWrapper)
    )
      setIsSummaryView(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionWrapper]);

  const topbar = (
    <TransactionTopbar artworkIdIfItsSellerSide={isSellerSide ? transactionWrapper.artwork.id : undefined} />
  );

  // const timeline = <TransactionTimeline transaction={props.transaction} />;

  return (
    <>
      {topbar}
      <Header isSummaryView={isSummaryView} />
      <div className={clsx(styles.transactionData, { [styles.withBackground]: isSummaryView || !isSellerSide })}>
        <div className={styles.transactionDetails}>
          {!isSummaryView && transactionFormStore.canUserPerformAction(TransactionActions.EditSellerSide) && (
            <BuyerRepresentative />
          )}
          {!isSummaryView && transactionFormStore.canUserPerformAction(TransactionActions.EditBuyerSide) && <Buyers />}
          <TransactionBilling isSummaryView={isSummaryView} />
          {!isSummaryView && !isSellerOwnerAsRepresentative && <SplitCommission />}
          <BankAccountForBuyer />
          <TransactionInvoices />
          <Actions isSummaryView={isSummaryView} setIsSummaryView={setIsSummaryView} />
        </div>

        {/* TODO uncomment when ready */}
        {/* <div className={styles.timeline}>{timeline}</div> */}
      </div>
    </>
  );
};

export default observer(TransactionForm);
