import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import styles from 'auth/Auth.module.scss';
import AuthAppForm from 'auth/components/forms/AuthAppForm';
import { UserIdentityType } from 'auth/types';
import { Button, TitleBlack } from 'theme/atoms';
import Breadcrumbs, { Breadcrumb } from 'theme/atoms/Breadcrumbs';
import Topbar from 'theme/atoms/Topbar';
import { useIsAccessTokenFresh } from 'utils/hooks';
import SetupAuthApp from './components/SetupAuthApp';

const BREADCRUMBS: Breadcrumb[] = [{ label: 'Authentication method' }, { label: 'Step 2: Authenticator' }];

const LoginAddAuthApp = (): JSX.Element => {
  const navigate = useNavigate();
  const isTokenFresh = useIsAccessTokenFresh();

  const {
    authStore: {
      submitting,
      accessToken,
      TOTP,
      generateAuthAppQRCode,
      addUserIdentity,
      saveTokensToStore,
      saveTokensToLocalStorage,
    },
  } = useStores();

  useEffect(() => {
    if (!isTokenFresh) {
      navigate(routes.Login.path());
      return;
    }

    if (accessToken) generateAuthAppQRCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (authAppCode: string): void => {
    if (!TOTP) return;

    addUserIdentity({ type: UserIdentityType.TimeBasedOneTimePassword, value: TOTP.secret, verifyToken: authAppCode })
      .then((response) => {
        if (response?.success) {
          saveTokensToStore(response.data);
          saveTokensToLocalStorage(response.data);
          navigate(routes.LoginRecoveryCodes.path(), { state: { canGenerateRecoveryCodes: true } });
        }
      })
      .catch(() => {
        navigate(routes.Login.path());
      });
  };

  const submitButton = (
    <Button
      type="submit"
      text="Continue"
      loading={submitting}
      disabled={submitting}
      className={styles.submitButton}
      maxWidth
    />
  );

  const backButton = (
    <Link to={routes.LoginAddAuthMethod.path()} className={clsx(styles.backLink, styles.alignCenter)}>
      Back
    </Link>
  );

  return (
    <div className={clsx(styles.root, styles.oneColumnLayout)}>
      <Topbar>
        <Breadcrumbs breadcrumbs={BREADCRUMBS} />
      </Topbar>
      <div className={styles.innerWrapper}>
        <TitleBlack>Set up an authenticator app</TitleBlack>

        <SetupAuthApp
          QRSecret={TOTP?.secret}
          QRValue={TOTP?.qr}
          form={<AuthAppForm onSubmit={onSubmit} submitButton={submitButton} />}
          backButton={backButton}
        />
      </div>
    </div>
  );
};

export default observer(LoginAddAuthApp);
