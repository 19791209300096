import { AssetWithKey } from './Asset.types';
import { Currency } from './Domain.types';
import { TransactionPrice } from './Transactions.types';

export enum InvoiceState {
  Accepted = 'Accepted',
  PendingBankDecision = 'PendingBankDecision',
  PendingUpload = 'PendingUpload',
  Rejected = 'Rejected',
  Paid = 'Paid',
}

export interface InvoiceStateLog {
  id?: string;
  invoiceId: string;
  state: InvoiceState;
  changedAt: Date;
  changedBy?: string;
}

export enum IssuerRole {
  Seller = 'Seller',
  SellerRepresentative = 'SellerRepresentative',
  ExtraSellerRepresentative = 'ExtraSellerRepresentative',
  BuyerRepresentative = 'BuyerRepresentative',
  ExtraBuyerRepresentative = 'ExtraBuyerRepresentative',
}

export interface InvoiceWithoutSides {
  id: string;
  issuerRole: string;
  amount: TransactionPrice;
  currency: Currency;
  state: InvoiceState;
  assetInfo: AssetWithKey;
  bankNote: string;
  stateChangeLog: InvoiceStateLog[];
  createdAt: Date;
  modifiedAt: Date;
  externalId: string;
}
