import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import { ContactConnection } from 'network/types';
import { getAddress } from 'network/utils';
import Avatar from 'theme/atoms/avatar';
import { Table } from 'theme/atoms/table/Table';
import TableDetailItem from 'theme/atoms/tableDetailItem';
import tableDetailItemStyles from 'theme/atoms/tableDetailItem/TableDetailItem.module.scss';
import { CategoryId } from '../types';
import styles from './NetworkTable.module.scss';
import NetworkActionModal from './components/NetworkActionModal';
import RightSideTds from './components/RightSideTds';

export type ContactToProceed = { action: 'accept' | 'ignore'; contact: ContactConnection };

const NetworkTable = observer((): JSX.Element => {
  const navigate = useNavigate();
  const { categoryId } = useParams<{ categoryId: CategoryId }>();
  const { listNetworkStore } = useStores();
  const { items } = listNetworkStore;
  const connections = items as ContactConnection[];

  const [contactToDelete, setContactToDelete] = useState<ContactConnection | null>(null);
  const [contactToProceed, setContactToProceed] = useState<{
    action: 'accept' | 'ignore';
    contact: ContactConnection;
  } | null>(null);

  if (!categoryId) return <></>;

  const handleRowClick = (event: React.MouseEvent<HTMLTableRowElement>, contactId: string): void => {
    const isActionBtnsClicked = (event.target as Element).closest('button');
    if (!isActionBtnsClicked) navigate(routes.ContactProfile.path(contactId));
  };

  return (
    <div className={styles.root}>
      <Table>
        <>
          {connections.map((el) => {
            const contactOrganizationAddress = getAddress(el.contactOrganizationAddress);
            return (
              <tr
                className={clsx(styles.row, { [styles.clickable]: categoryId === 'network' })}
                key={el.id}
                onClick={(e) => categoryId === 'network' && handleRowClick(e, el.id)}
              >
                <td className={styles.avatarTd}>
                  <div className={styles.avatar}>
                    <Avatar id={el.id} />
                    {/* TODO image if possible */}
                  </div>
                </td>
                <td className={styles.mainDataTd}>
                  <div className={styles.mainData}>
                    <TableDetailItem
                      text={el.contactOrganizationName}
                      searchText={listNetworkStore.searchQuery}
                      className={tableDetailItemStyles.bold}
                    />
                    <TableDetailItem className={tableDetailItemStyles.italic} text={el.contactOrganizationTitle} />
                    <TableDetailItem className={tableDetailItemStyles.normal} text={contactOrganizationAddress} />
                  </div>
                </td>
                <RightSideTds
                  categoryId={categoryId}
                  item={el}
                  setContactToProceed={setContactToProceed}
                  setContactToDelete={setContactToDelete}
                />
              </tr>
            );
          })}
        </>
      </Table>

      <NetworkActionModal
        categoryId={categoryId}
        contactToDelete={contactToDelete}
        contactToProceed={contactToProceed}
        setContactToProceed={setContactToProceed}
        setContactToDelete={setContactToDelete}
      />
    </div>
  );
});
export default NetworkTable;
