import styles from './Tiles.module.scss';
import Tile from './tile';

export type TileType = {
  id: string;
  label: string;
  icon: JSX.Element;
};

interface TilesProps {
  list: TileType[];
  selectedItem: string;
  setSelectedItem: (val) => void;
  tileClassName?: string;
  withCheckMark?: boolean;
}

const Tiles = ({ list, selectedItem, setSelectedItem, tileClassName = '', withCheckMark }: TilesProps): JSX.Element => {
  return (
    <div className={styles.root}>
      {list.map(({ id, label, icon }) => (
        <Tile
          key={id}
          title={label}
          icon={icon}
          onClick={() => setSelectedItem(id)}
          isSelected={selectedItem === id}
          className={tileClassName}
          withCheckMark={withCheckMark && selectedItem === id}
        />
      ))}
    </div>
  );
};

export default Tiles;
