import { MouseEvent, ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import Big from 'big.js';
import {
  Consignment,
  ConsignmentItem,
  ConsignmentSellArtwork,
  ConsignmentShowArtwork,
  ConsignmentStatus,
} from 'consignments/types';
import { SELL_ARTWORK_COPY } from 'domain/constants';
import { ReactComponent as TickSmall } from 'theme/assets/svg/tickSmall.svg';
import { ReactComponent as TickWow } from 'theme/assets/svg/tickWow.svg';
// import Checkbox from 'theme/atoms/Checkbox';
import Avatar from 'theme/atoms/avatar';
import ImageDraft from 'theme/atoms/imageDraft';
import { Table } from 'theme/atoms/table/Table';
import TableDetailItem from 'theme/atoms/tableDetailItem';
import tableDetailItemStyles from 'theme/atoms/tableDetailItem/TableDetailItem.module.scss';
import { formatDate } from 'utils/helpers';
import { formatPriceRange } from 'utils/helpers/formatRangePrice';
import styles from './ListConsignmentsTable.module.scss';

const ListConsignmentsTable = observer((): JSX.Element => {
  const navigate = useNavigate();
  const { consignmentsStore } = useStores();
  const {
    items,
    // selectedItemsIds, updateSelectedItemsIds
  } = consignmentsStore;
  const consignmentItems = items as ConsignmentItem[];
  const [highlightedRowInOuterTable, setHighlightedRowInOuterTable] = useState('');

  const handleRowClick = (
    e: MouseEvent<HTMLTableRowElement, globalThis.MouseEvent>,
    consignment: Consignment,
    consignmentItem: ConsignmentItem
  ): void => {
    if (e.target instanceof HTMLInputElement || e.target instanceof HTMLButtonElement) return;

    if (
      ![ConsignmentStatus.RejectedByOwner, ConsignmentStatus.RejectedByConsignee, ConsignmentStatus.Expired].includes(
        consignment.status
      ) &&
      !(consignment.isConsignee && consignment.status === ConsignmentStatus.PendingConsigneeAgreement)
    ) {
      navigate(routes.Artwork.path(consignmentItem.artwork.id), { state: { isStatusOpen: true } });
    } else {
      navigate(routes.Consignment.path(consignment.id));
    }
  };

  const exclusiveInfo = (isExclusive: keyof typeof ConsignmentShowArtwork): ReactNode => {
    return isExclusive === 'Exclusive' ? (
      <>
        exclusive
        <TickWow />
      </>
    ) : (
      'non-exclusive'
    );
  };

  const sellArtworkInfo = (sellArtwork: ConsignmentSellArtwork): ReactNode => {
    return sellArtwork ? (
      <>
        <TickSmall />
        {SELL_ARTWORK_COPY}
      </>
    ) : (
      ''
    );
  };

  const innerTable = (consignmentItem: ConsignmentItem): JSX.Element => {
    return (
      <Table>
        <>
          {consignmentItem.consignments.map((consignment) => {
            const consignmentContact = consignment.isConsignee
              ? consignment.consignorContact
              : consignment.consigneeContact;

            return (
              <tr
                className={styles.rowInnerTable}
                key={consignment.id}
                onClick={(e) => handleRowClick(e, consignment, consignmentItem)}
              >
                {/* <td className={styles.checkboxTd}>
                  <Checkbox
                    name="single"
                    checked={selectedItemsIds.includes(consignment.id)}
                    onChange={(e) => updateSelectedItemsIds(consignment.id, e.target.checked)}
                    iconType="primary"
                  />
                </td> */}
                <td className={styles.primaryContactTd}>
                  <span className={styles.direction}>{consignment.isConsignee ? 'From' : 'To'}:</span>
                  <Avatar id={consignmentContact?.contactConnectionId || consignmentContact?.organizationName || ''} />
                  <TableDetailItem
                    text={consignmentContact?.organizationName || 'Undisclosed'}
                    searchText={consignmentsStore.searchQuery}
                    className={tableDetailItemStyles.userWithAvatarSemibold}
                  />
                </td>
                <td className={styles.exclusiveTd}>{exclusiveInfo(consignment.showArtwork)}</td>
                <td className={styles.canSellTd}>{sellArtworkInfo(consignment.sellArtwork)}</td>
                <td className={styles.priceTd}>
                  {formatPriceRange(
                    consignment.askingPrice,
                    Big(consignment.askingPrice).plus(consignment.maxCommission),
                    consignment.currency
                  )}
                </td>

                <td className={styles.expiresTd}>
                  {consignment.expireAt ? 'Expires: ' + formatDate(consignment.expireAt) : null}
                </td>
              </tr>
            );
          })}
        </>
      </Table>
    );
  };

  const outerTable = (
    <Table>
      <>
        {consignmentItems.map((consignmentItem) => {
          return (
            <tr
              key={consignmentItem.id}
              className={clsx(styles.rowOuterTable, {
                [styles.highlightedRow]: highlightedRowInOuterTable === consignmentItem.id,
              })}
              onMouseEnter={() => setHighlightedRowInOuterTable(consignmentItem.id)}
              onMouseLeave={() => setHighlightedRowInOuterTable('')}
            >
              {/* <td className={styles.checkboxTd}>
                <Checkbox
                  name="single"
                  checked={selectedItemsIds.includes(consignmentItem.id)}
                  onChange={(e) => updateSelectedItemsIds(consignmentItem.id, e.target.checked)}
                  iconType="primary"
                />
              </td> */}
              <td className={styles.imageTd}>
                {consignmentItem.artwork.assets.imageUrls?.[0]?.presignedUrl ? (
                  <img
                    src={consignmentItem.artwork.assets.imageUrls?.[0]?.presignedUrl}
                    alt={consignmentItem.artwork.name}
                  />
                ) : (
                  <ImageDraft />
                )}
              </td>
              <td className={styles.moreDataTd}>
                <div className={styles.basicData}>
                  <TableDetailItem
                    text={consignmentItem.artwork.author?.name}
                    searchText={consignmentsStore.searchQuery}
                    className={tableDetailItemStyles.bold}
                  />
                  <TableDetailItem
                    nameYear={[consignmentItem.artwork.name, consignmentItem.artwork.year.toString()]}
                    searchText={consignmentsStore.searchQuery}
                  />
                </div>
                <div
                  className={styles.innerTableWrapper}
                  onMouseEnter={() => setHighlightedRowInOuterTable('')}
                  onMouseLeave={() => setHighlightedRowInOuterTable(consignmentItem.id)}
                >
                  {innerTable(consignmentItem)}
                </div>
              </td>
            </tr>
          );
        })}
      </>
    </Table>
  );

  return <div className={styles.root}>{outerTable}</div>;
});

export default ListConsignmentsTable;
