import { makeAutoObservable, runInAction } from 'mobx';
import { RootStore } from 'RootStore';
import { Artwork, ArtworkStatusEnum } from 'artworks/types';
import * as requestFromAddArtwork from '../addArtwork/requests';
import * as requests from './requests';

class ApproveArtworkStore {
  rootStore: RootStore;
  loadingArtwork = true;
  loading = false;
  artworkToApprove: Artwork | null = null;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  get iAmTheAuthor(): boolean {
    return Boolean(this.artworkToApprove?.author.id === this.rootStore.organizationStore.selectedOrganization?.id);
  }

  get lastModifiedAt(): Date | undefined {
    return this.artworkToApprove?.modifiedAt;
  }

  get shouldIApproveArtwork(): boolean {
    return Boolean(
      this.artworkToApprove &&
        ArtworkStatusEnum[this.artworkToApprove.exploringOrganizationArtworkStatus] ===
          ArtworkStatusEnum.DraftAwaitingApproval &&
        !this.artworkToApprove.isEditable &&
        (this.artworkToApprove?.isOwner || this.iAmTheAuthor)
    );
  }

  deleteArtwork = (id: string): Promise<void> => {
    const { addToast } = this.rootStore.toastsStore;

    if (!this.lastModifiedAt) {
      addToast('Failed to delete artwork.', 'error');
      return Promise.reject();
    }

    runInAction(() => (this.loading = true));

    return requestFromAddArtwork
      .deleteArtwork(id, this.lastModifiedAt)
      .then(() => {
        addToast('Artwork deleted successfully', 'success');
      })
      .catch(() => addToast('Failed to delete artwork.', 'error'))
      .finally(() => runInAction(() => (this.loading = false)));
  };

  approveArtwork = (id: string): Promise<void | string> => {
    const { addToast } = this.rootStore.toastsStore;

    if (!this.lastModifiedAt) {
      addToast('Failed to approve artwork.', 'error');
      return Promise.reject();
    }

    runInAction(() => (this.loading = true));

    // when the artist is also the owner => the approve as the owner will be automatically handle by BE
    const request = this.iAmTheAuthor
      ? requests.authorApproveArtwork(id, this.lastModifiedAt)
      : requests.ownerApproveArtwork(id, this.lastModifiedAt);

    return request
      .then(() => {
        addToast('Artwork approved successfully', 'success');
        this.fetchArtwork(id);
      })
      .catch(() => addToast('Failed to approve artwork.', 'error'))
      .finally(() => runInAction(() => (this.loading = false)));
  };

  rejectArtwork = (id: string): Promise<void | string> => {
    const { addToast } = this.rootStore.toastsStore;

    if (!this.lastModifiedAt) {
      addToast('Failed to reject artwork.', 'error');
      return Promise.reject();
    }

    runInAction(() => (this.loading = true));

    const request = this.iAmTheAuthor
      ? requests.authorRejectArtwork(id, this.lastModifiedAt)
      : requests.ownerRejectArtwork(id, this.lastModifiedAt);

    return request
      .then(() => {
        addToast('Artwork rejected successfully', 'success');
        this.fetchArtwork(id);
      })
      .catch(() => addToast('Failed to reject artwork.', 'error'))
      .finally(() => runInAction(() => (this.loading = false)));
  };

  fetchArtwork = (id: string): void => {
    runInAction(() => (this.loadingArtwork = true));
    this.rootStore.artworkStore
      .fetchArtwork(id)
      .then((artwork) => artwork && runInAction(() => (this.artworkToApprove = artwork)))
      .finally(() => runInAction(() => (this.loadingArtwork = false)));
  };

  resetAll = (): void => {
    runInAction(() => {
      this.artworkToApprove = null;
    });
  };
}

export default ApproveArtworkStore;
