import { Option } from 'theme/atoms/Select/Select';

export interface CountryItem extends Option {
  code: string;
}

export const COUNTRIES: CountryItem[] = [
  { label: 'Afghanistan', code: 'AFG', value: 'Afghanistan' },
  { label: 'Åland Islands', code: 'ALA', value: 'Åland Islands' },
  { label: 'Albania', code: 'ALB', value: 'Albania' },
  { label: 'Algeria', code: 'DZA', value: 'Algeria' },
  { label: 'American Samoa', code: 'ASM', value: 'American Samoa' },
  { label: 'Andorra', code: 'AND', value: 'Andorra' },
  { label: 'Angola', code: 'AGO', value: 'Angola' },
  { label: 'Anguilla', code: 'AIA', value: 'Anguilla' },
  { label: 'Antarctica', code: 'ATA', value: 'Antarctica' },
  { label: 'Antigua and Barbuda', code: 'ATG', value: 'Antigua and Barbuda' },
  { label: 'Argentina', code: 'ARG', value: 'Argentina' },
  { label: 'Armenia', code: 'ARM', value: 'Armenia' },
  { label: 'Aruba', code: 'ABW', value: 'Aruba' },
  { label: 'Australia', code: 'AUS', value: 'Australia' },
  { label: 'Austria', code: 'AUT', value: 'Austria' },
  { label: 'Azerbaijan', code: 'AZE', value: 'Azerbaijan' },
  { label: 'Bahamas', code: 'BHS', value: 'Bahamas' },
  { label: 'Bahrain', code: 'BHR', value: 'Bahrain' },
  { label: 'Bangladesh', code: 'BGD', value: 'Bangladesh' },
  { label: 'Barbados', code: 'BRB', value: 'Barbados' },
  { label: 'Belarus', code: 'BLR', value: 'Belarus' },
  { label: 'Belgium', code: 'BEL', value: 'Belgium' },
  { label: 'Belize', code: 'BLZ', value: 'Belize' },
  { label: 'Benin', code: 'BEN', value: 'Benin' },
  { label: 'Bermuda', code: 'BMU', value: 'Bermuda' },
  { label: 'Bhutan', code: 'BTN', value: 'Bhutan' },
  { label: 'Bolivia (Plurinational State of)', code: 'BOL', value: 'Bolivia (Plurinational State of)' },
  { label: 'Bonaire, Sint Eustatius and Saba', code: 'BES', value: 'Bonaire, Sint Eustatius and Saba' },
  { label: 'Bosnia and Herzegovina', code: 'BIH', value: 'Bosnia and Herzegovina' },
  { label: 'Botswana', code: 'BWA', value: 'Botswana' },
  { label: 'Bouvet Island', code: 'BVT', value: 'Bouvet Island' },
  { label: 'Brazil', code: 'BRA', value: 'Brazil' },
  { label: 'British Indian Ocean Territory', code: 'IOT', value: 'British Indian Ocean Territory' },
  { label: 'Brunei Darussalam', code: 'BRN', value: 'Brunei Darussalam' },
  { label: 'Bulgaria', code: 'BGR', value: 'Bulgaria' },
  { label: 'Burkina Faso', code: 'BFA', value: 'Burkina Faso' },
  { label: 'Burundi', code: 'BDI', value: 'Burundi' },
  { label: 'Cabo Verde', code: 'CPV', value: 'Cabo Verde' },
  { label: 'Cambodia', code: 'KHM', value: 'Cambodia' },
  { label: 'Cameroon', code: 'CMR', value: 'Cameroon' },
  { label: 'Canada', code: 'CAN', value: 'Canada' },
  { label: 'Cayman Islands', code: 'CYM', value: 'Cayman Islands' },
  { label: 'Central African Republic', code: 'CAF', value: 'Central African Republic' },
  { label: 'Chad', code: 'TCD', value: 'Chad' },
  { label: 'Chile', code: 'CHL', value: 'Chile' },
  { label: 'China', code: 'CHN', value: 'China' },
  { label: 'Christmas Island', code: 'CXR', value: 'Christmas Island' },
  { label: 'Cocos (Keeling) Islands', code: 'CCK', value: 'Cocos (Keeling) Islands' },
  { label: 'Colombia', code: 'COL', value: 'Colombia' },
  { label: 'Comoros', code: 'COM', value: 'Comoros' },
  { label: 'Congo', code: 'COG', value: 'Congo' },
  { label: 'Congo, Democratic Republic of the', code: 'COD', value: 'Congo, Democratic Republic of the' },
  { label: 'Cook Islands', code: 'COK', value: 'Cook Islands' },
  { label: 'Costa Rica', code: 'CRI', value: 'Costa Rica' },
  { label: 'Croatia', code: 'HRV', value: 'Croatia' },
  { label: 'Cuba', code: 'CUB', value: 'Cuba' },
  { label: 'Curaçao', code: 'CUW', value: 'Curaçao' },
  { label: 'Cyprus', code: 'CYP', value: 'Cyprus' },
  { label: 'Czech Republic', code: 'CZE', value: 'Czech Republic' },
  { label: 'Denmark', code: 'DNK', value: 'Denmark' },
  { label: 'Djibouti', code: 'DJI', value: 'Djibouti' },
  { label: 'Dominica', code: 'DMA', value: 'Dominica' },
  { label: 'Dominican Republic', code: 'DOM', value: 'Dominican Republic' },
  { label: 'Ecuador', code: 'ECU', value: 'Ecuador' },
  { label: 'Egypt', code: 'EGY', value: 'Egypt' },
  { label: 'El Salvador', code: 'SLV', value: 'El Salvador' },
  { label: 'Equatorial Guinea', code: 'GNQ', value: 'Equatorial Guinea' },
  { label: 'Eritrea', code: 'ERI', value: 'Eritrea' },
  { label: 'Estonia', code: 'EST', value: 'Estonia' },
  { label: 'Eswatini', code: 'SWZ', value: 'Eswatini' },
  { label: 'Ethiopia', code: 'ETH', value: 'Ethiopia' },
  { label: 'Falkland Islands (Malvinas)', code: 'FLK', value: 'Falkland Islands (Malvinas)' },
  { label: 'Faroe Islands', code: 'FRO', value: 'Faroe Islands' },
  { label: 'Fiji', code: 'FJI', value: 'Fiji' },
  { label: 'Finland', code: 'FIN', value: 'Finland' },
  { label: 'France', code: 'FRA', value: 'France' },
  { label: 'French Guiana', code: 'GUF', value: 'French Guiana' },
  { label: 'French Polynesia', code: 'PYF', value: 'French Polynesia' },
  { label: 'French Southern Territories', code: 'ATF', value: 'French Southern Territories' },
  { label: 'Gabon', code: 'GAB', value: 'Gabon' },
  { label: 'Gambia', code: 'GMB', value: 'Gambia' },
  { label: 'Georgia', code: 'GEO', value: 'Georgia' },
  { label: 'Germany', code: 'DEU', value: 'Germany' },
  { label: 'Ghana', code: 'GHA', value: 'Ghana' },
  { label: 'Gibraltar', code: 'GIB', value: 'Gibraltar' },
  { label: 'Greece', code: 'GRC', value: 'Greece' },
  { label: 'Greenland', code: 'GRL', value: 'Greenland' },
  { label: 'Grenada', code: 'GRD', value: 'Grenada' },
  { label: 'Guadeloupe', code: 'GLP', value: 'Guadeloupe' },
  { label: 'Guam', code: 'GUM', value: 'Guam' },
  { label: 'Guatemala', code: 'GTM', value: 'Guatemala' },
  { label: 'Guernsey', code: 'GGY', value: 'Guernsey' },
  { label: 'Guinea', code: 'GIN', value: 'Guinea' },
  { label: 'Guinea-Bissau', code: 'GNB', value: 'Guinea-Bissau' },
  { label: 'Guyana', code: 'GUY', value: 'Guyana' },
  { label: 'Haiti', code: 'HTI', value: 'Haiti' },
  { label: 'Heard Island and McDonald Islands', code: 'HMD', value: 'Heard Island and McDonald Islands' },
  { label: 'Holy See', code: 'VAT', value: 'Holy See' },
  { label: 'Honduras', code: 'HND', value: 'Honduras' },
  { label: 'Hong Kong', code: 'HKG', value: 'Hong Kong' },
  { label: 'Hungary', code: 'HUN', value: 'Hungary' },
  { label: 'Iceland', code: 'ISL', value: 'Iceland' },
  { label: 'India', code: 'IND', value: 'India' },
  { label: 'Indonesia', code: 'IDN', value: 'Indonesia' },
  { label: 'Iran (Islamic Republic of)', code: 'IRN', value: 'Iran (Islamic Republic of)' },
  { label: 'Iraq', code: 'IRQ', value: 'Iraq' },
  { label: 'Ireland', code: 'IRL', value: 'Ireland' },
  { label: 'Isle of Man', code: 'IMN', value: 'Isle of Man' },
  { label: 'Israel', code: 'ISR', value: 'Israel' },
  { label: 'Italy', code: 'ITA', value: 'Italy' },
  { label: 'Jamaica', code: 'JAM', value: 'Jamaica' },
  { label: 'Japan', code: 'JPN', value: 'Japan' },
  { label: 'Jersey', code: 'JEY', value: 'Jersey' },
  { label: 'Jordan', code: 'JOR', value: 'Jordan' },
  { label: 'Kazakhstan', code: 'KAZ', value: 'Kazakhstan' },
  { label: 'Kenya', code: 'KEN', value: 'Kenya' },
  { label: 'Kiribati', code: 'KIR', value: 'Kiribati' },
  { label: "Korea (Democratic People's Republic of)", code: 'PRK', value: "Korea (Democratic People's Republic of)" },
  { label: 'Korea (Republic of)', code: 'KOR', value: 'Korea (Republic of)' },
  { label: 'Kuwait', code: 'KWT', value: 'Kuwait' },
  { label: 'Kyrgyzstan', code: 'KGZ', value: 'Kyrgyzstan' },
  { label: "Lao People's Democratic Republic", code: 'LAO', value: "Lao People's Democratic Republic" },
  { label: 'Latvia', code: 'LVA', value: 'Latvia' },
  { label: 'Lebanon', code: 'LBN', value: 'Lebanon' },
  { label: 'Lesotho', code: 'LSO', value: 'Lesotho' },
  { label: 'Liberia', code: 'LBR', value: 'Liberia' },
  { label: 'Libya', code: 'LBY', value: 'Libya' },
  { label: 'Liechtenstein', code: 'LIE', value: 'Liechtenstein' },
  { label: 'Lithuania', code: 'LTU', value: 'Lithuania' },
  { label: 'Luxembourg', code: 'LUX', value: 'Luxembourg' },
  { label: 'Macao', code: 'MAC', value: 'Macao' },
  { label: 'Madagascar', code: 'MDG', value: 'Madagascar' },
  { label: 'Malawi', code: 'MWI', value: 'Malawi' },
  { label: 'Malaysia', code: 'MYS', value: 'Malaysia' },
  { label: 'Maldives', code: 'MDV', value: 'Maldives' },
  { label: 'Mali', code: 'MLI', value: 'Mali' },
  { label: 'Malta', code: 'MLT', value: 'Malta' },
  { label: 'Marshall Islands', code: 'MHL', value: 'Marshall Islands' },
  { label: 'Martinique', code: 'MTQ', value: 'Martinique' },
  { label: 'Mauritania', code: 'MRT', value: 'Mauritania' },
  { label: 'Mauritius', code: 'MUS', value: 'Mauritius' },
  { label: 'Mayotte', code: 'MYT', value: 'Mayotte' },
  { label: 'Mexico', code: 'MEX', value: 'Mexico' },
  { label: 'Micronesia (Federated States of)', code: 'FSM', value: 'Micronesia (Federated States of)' },
  { label: 'Moldova (Republic of)', code: 'MDA', value: 'Moldova (Republic of)' },
  { label: 'Monaco', code: 'MCO', value: 'Monaco' },
  { label: 'Mongolia', code: 'MNG', value: 'Mongolia' },
  { label: 'Montenegro', code: 'MNE', value: 'Montenegro' },
  { label: 'Montserrat', code: 'MSR', value: 'Montserrat' },
  { label: 'Morocco', code: 'MAR', value: 'Morocco' },
  { label: 'Mozambique', code: 'MOZ', value: 'Mozambique' },
  { label: 'Myanmar', code: 'MMR', value: 'Myanmar' },
  { label: 'Namibia', code: 'NAM', value: 'Namibia' },
  { label: 'Nauru', code: 'NRU', value: 'Nauru' },
  { label: 'Nepal', code: 'NPL', value: 'Nepal' },
  { label: 'Netherlands', code: 'NLD', value: 'Netherlands' },
  { label: 'New Caledonia', code: 'NCL', value: 'New Caledonia' },
  { label: 'New Zealand', code: 'NZL', value: 'New Zealand' },
  { label: 'Nicaragua', code: 'NIC', value: 'Nicaragua' },
  { label: 'Niger', code: 'NER', value: 'Niger' },
  { label: 'Nigeria', code: 'NGA', value: 'Nigeria' },
  { label: 'Niue', code: 'NIU', value: 'Niue' },
  { label: 'Norfolk Island', code: 'NFK', value: 'Norfolk Island' },
  { label: 'North Macedonia', code: 'MKD', value: 'North Macedonia' },
  { label: 'Northern Mariana Islands', code: 'MNP', value: 'Northern Mariana Islands' },
  { label: 'Norway', code: 'NOR', value: 'Norway' },
  { label: 'Oman', code: 'OMN', value: 'Oman' },
  { label: 'Pakistan', code: 'PAK', value: 'Pakistan' },
  { label: 'Palau', code: 'PLW', value: 'Palau' },
  { label: 'Palestine, State of', code: 'PSE', value: 'Palestine, State of' },
  { label: 'Panama', code: 'PAN', value: 'Panama' },
  { label: 'Papua New Guinea', code: 'PNG', value: 'Papua New Guinea' },
  { label: 'Paraguay', code: 'PRY', value: 'Paraguay' },
  { label: 'Peru', code: 'PER', value: 'Peru' },
  { label: 'Philippines', code: 'PHL', value: 'Philippines' },
  { label: 'Pitcairn', code: 'PCN', value: 'Pitcairn' },
  { label: 'Poland', code: 'POL', value: 'Poland' },
  { label: 'Portugal', code: 'PRT', value: 'Portugal' },
  { label: 'Puerto Rico', code: 'PRI', value: 'Puerto Rico' },
  { label: 'Qatar', code: 'QAT', value: 'Qatar' },
  { label: 'Réunion', code: 'REU', value: 'Réunion' },
  { label: 'Romania', code: 'ROU', value: 'Romania' },
  { label: 'Russian Federation', code: 'RUS', value: 'Russian Federation' },
  { label: 'Rwanda', code: 'RWA', value: 'Rwanda' },
  { label: 'Saint Barthélemy', code: 'BLM', value: 'Saint Barthélemy' },
  {
    label: 'Saint Helena, Ascension and Tristan da Cunha',
    code: 'SHN',
    value: 'Saint Helena, Ascension and Tristan da Cunha',
  },
  { label: 'Saint Kitts and Nevis', code: 'KNA', value: 'Saint Kitts and Nevis' },
  { label: 'Saint Lucia', code: 'LCA', value: 'Saint Lucia' },
  { label: 'Saint Martin (French part)', code: 'MAF', value: 'Saint Martin (French part)' },
  { label: 'Saint Pierre and Miquelon', code: 'SPM', value: 'Saint Pierre and Miquelon' },
  { label: 'Saint Vincent and the Grenadines', code: 'VCT', value: 'Saint Vincent and the Grenadines' },
  { label: 'Samoa', code: 'WSM', value: 'Samoa' },
  { label: 'San Marino', code: 'SMR', value: 'San Marino' },
  { label: 'Sao Tome and Principe', code: 'STP', value: 'Sao Tome and Principe' },
  { label: 'Saudi Arabia', code: 'SAU', value: 'Saudi Arabia' },
  { label: 'Senegal', code: 'SEN', value: 'Senegal' },
  { label: 'Serbia', code: 'SRB', value: 'Serbia' },
  { label: 'Seychelles', code: 'SYC', value: 'Seychelles' },
  { label: 'Sierra Leone', code: 'SLE', value: 'Sierra Leone' },
  { label: 'Singapore', code: 'SGP', value: 'Singapore' },
  { label: 'Sint Maarten (Dutch part)', code: 'SXM', value: 'Sint Maarten (Dutch part)' },
  { label: 'Slovakia', code: 'SVK', value: 'Slovakia' },
  { label: 'Slovenia', code: 'SVN', value: 'Slovenia' },
  { label: 'Solomon Islands', code: 'SLB', value: 'Solomon Islands' },
  { label: 'Somalia', code: 'SOM', value: 'Somalia' },
  { label: 'South Africa', code: 'ZAF', value: 'South Africa' },
  {
    label: 'South Georgia and the South Sandwich Islands',
    code: 'SGS',
    value: 'South Georgia and the South Sandwich Islands',
  },
  { label: 'South Sudan', code: 'SSD', value: 'South Sudan' },
  { label: 'Spain', code: 'ESP', value: 'Spain' },
  { label: 'Sri Lanka', code: 'LKA', value: 'Sri Lanka' },
  { label: 'Sudan', code: 'SDN', value: 'Sudan' },
  { label: 'Surilabel', code: 'SUR', value: 'Surilabel' },
  { label: 'Svalbard and Jan Mayen', code: 'SJM', value: 'Svalbard and Jan Mayen' },
  { label: 'Sweden', code: 'SWE', value: 'Sweden' },
  { label: 'Switzerland', code: 'CHE', value: 'Switzerland' },
  { label: 'Syrian Arab Republic', code: 'SYR', value: 'Syrian Arab Republic' },
  { label: 'Taiwan, Province of China', code: 'TWN', value: 'Taiwan, Province of China' },
  { label: 'Tajikistan', code: 'TJK', value: 'Tajikistan' },
  { label: 'Tanzania, United Republic of', code: 'TZA', value: 'Tanzania, United Republic of' },
  { label: 'Thailand', code: 'THA', value: 'Thailand' },
  { label: 'Timor-Leste', code: 'TLS', value: 'Timor-Leste' },
  { label: 'Togo', code: 'TGO', value: 'Togo' },
  { label: 'Tokelau', code: 'TKL', value: 'Tokelau' },
  { label: 'Tonga', code: 'TON', value: 'Tonga' },
  { label: 'Trinidad and Tobago', code: 'TTO', value: 'Trinidad and Tobago' },
  { label: 'Tunisia', code: 'TUN', value: 'Tunisia' },
  { label: 'Turkey', code: 'TUR', value: 'Turkey' },
  { label: 'Turkmenistan', code: 'TKM', value: 'Turkmenistan' },
  { label: 'Turks and Caicos Islands', code: 'TCA', value: 'Turks and Caicos Islands' },
  { label: 'Tuvalu', code: 'TUV', value: 'Tuvalu' },
  { label: 'Uganda', code: 'UGA', value: 'Uganda' },
  { label: 'Ukraine', code: 'UKR', value: 'Ukraine' },
  { label: 'United Arab Emirates', code: 'ARE', value: 'United Arab Emirates' },
  {
    label: 'United Kingdom of Great Britain and Northern Ireland',
    code: 'GBR',
    value: 'United Kingdom of Great Britain and Northern Ireland',
  },
  { label: 'United States of America', code: 'USA', value: 'United States of America' },
  { label: 'United States Minor Outlying Islands', code: 'UMI', value: 'United States Minor Outlying Islands' },
  { label: 'Uruguay', code: 'URY', value: 'Uruguay' },
  { label: 'Uzbekistan', code: 'UZB', value: 'Uzbekistan' },
  { label: 'Vanuatu', code: 'VUT', value: 'Vanuatu' },
  { label: 'Venezuela (Bolivarian Republic of)', code: 'VEN', value: 'Venezuela (Bolivarian Republic of)' },
  { label: 'Viet Nam', code: 'VNM', value: 'Viet Nam' },
  { label: 'Virgin Islands (British)', code: 'VGB', value: 'Virgin Islands (British)' },
  { label: 'Virgin Islands (U.S.)', code: 'VIR', value: 'Virgin Islands (U.S.)' },
  { label: 'Wallis and Futuna', code: 'WLF', value: 'Wallis and Futuna' },
  { label: 'Western Sahara', code: 'ESH', value: 'Western Sahara' },
  { label: 'Yemen', code: 'YEM', value: 'Yemen' },
  { label: 'Zambia', code: 'ZMB', value: 'Zambia' },
  { label: 'Zimbabwe', code: 'ZWE', value: 'Zimbabwe' },
];
