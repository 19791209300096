import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import SlidingPanel from 'theme/atoms/slidingPanel';
import AddressInfoCard from 'theme/cells/addressInfoCard';
import { Invoice } from 'transactions/requests.types';
import styles from './TransactionInvoicesInfo.module.scss';

interface TransactionInvoicesInfoProps {
  invoices: Invoice[] | undefined;
}

const TransactionInvoicesInfo = ({ invoices }: TransactionInvoicesInfoProps): JSX.Element => {
  const { invoicesStore } = useStores();
  const { isInvoicesInfoOpen, setIsInvoiceInfoOpen } = invoicesStore;

  useEffect(() => {
    return () => {
      setIsInvoiceInfoOpen(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SlidingPanel title="Invoices info" onClose={() => setIsInvoiceInfoOpen(false)} isOpen={isInvoicesInfoOpen}>
      <div className={styles.invoicesInfo}>
        <p>In order for the payment to be made, you must issue invoices to the following entities:</p>
        <div className={styles.invoicesPayersInfo}>
          {invoices &&
            invoices
              .filter((invoice) => !invoice.payerContactDto?.self)
              .map((invoice, i) => {
                return (
                  <AddressInfoCard
                    key={i}
                    data={invoice.payerContactDto}
                    label=" "
                    organizationId={invoice.payerContactDto?.contactConnectionId}
                  />
                );
              })}
        </div>
      </div>
    </SlidingPanel>
  );
};

export default observer(TransactionInvoicesInfo);
