import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import { DEFAULT_ARTWORK_DRAFT_CATEGORY } from 'artworks/artworkRegistration/constants';
import AssetsList from 'artworks/components/assetsList';
import ImagesList from 'artworks/components/imagesList';
import { ProductEnum } from 'billing/types';
import { getAddress } from 'network/utils';
import { Button, TitleBlack } from 'theme/atoms';
import SectionHeader from 'theme/atoms/Titles/SectionHeader';
import ApprovalFromPeople from 'theme/atoms/approvalFromPeople';
import Loader from 'theme/atoms/loader';
import UserCard from 'theme/atoms/userCard';
import { useGetPaidOrBlockedButtonDetails } from 'utils/hooks/useGetPaidOrBlockedButtonDetails';
import styles from '../AddArtwork.module.scss';
import { AddArtworkSteps, AddArtworkTopbar, SummaryArtworkDetails } from '../components';
import { ARTWORK_STEPS } from '../constants';
import NoArtworkFound from './components/NoArtworkFound';

const CURRENT_STEP = ARTWORK_STEPS[3];

const AddArtworkSummary = (): JSX.Element => {
  const { addArtworkStore } = useStores();
  const {
    fetching,
    artworkToEdit,
    artworkForm,
    authorToDisplay,
    ownersToDisplay,
    fetchArtworkAndSetItToEdition,
    saveDraft,
    saveAndConfirm,
    setArtworkToEdit,
  } = addArtworkStore;
  const navigate = useNavigate();
  const { artworkId } = useParams();

  const paidOrBlockedButtonDetails = useGetPaidOrBlockedButtonDetails(ProductEnum.ArtworkCertification);

  useEffect(() => {
    setArtworkToEdit(true);
    if (artworkId && artworkId !== 'new') fetchArtworkAndSetItToEdition(artworkId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artworkId]);

  if (!artworkToEdit) return <NoArtworkFound />;

  const ownersNotBeingSelfLength = (ownersToDisplay && ownersToDisplay?.filter((owner) => !owner.self).length) || 0;
  const authorNotBeingSelfOrOwnerLength =
    authorToDisplay?.self || ownersToDisplay?.find((owner) => owner.contactConnectionId === authorToDisplay?.id)
      ? 0
      : 1;
  const peopleToApprove = ownersNotBeingSelfLength + authorNotBeingSelfOrOwnerLength;

  const handleConfirm = (): void => {
    artworkId &&
      saveAndConfirm(artworkId).then(() => {
        navigate(routes.ArtworkRegistration.path(DEFAULT_ARTWORK_DRAFT_CATEGORY));
      });
  };

  const authorCard = authorToDisplay && (
    <UserCard id={authorToDisplay.id || ''}>
      <UserCard.Contact
        name={authorToDisplay.name || ''}
        title={authorToDisplay.title}
        address={authorToDisplay.address && getAddress(authorToDisplay.address)}
      />
    </UserCard>
  );

  const ownershipList = ownersToDisplay?.map((owner) => (
    <div className={styles.sectionWrapper} key={owner.organizationName + owner.contactConnectionId}>
      <UserCard id={owner.contactConnectionId || ''}>
        <UserCard.Contact
          name={owner.organizationName}
          title={owner.organizationTitle}
          address={owner.organizationAddress && getAddress(owner.organizationAddress)}
        />
      </UserCard>
      {ownersToDisplay.length > 1 && (
        <div>
          {artworkForm.owners
            .find((o) => owner.contactConnectionId === o.contactConnectionOwner || (owner.self && o.iAmTheOwner))
            ?.participationPercent?.toString()}
          %
        </div>
      )}
    </div>
  ));

  const buttons = (
    <div className={styles.buttonsWrapper}>
      <Button
        text="Save and send to verification"
        maxWidth
        onClick={handleConfirm}
        specialMarking={paidOrBlockedButtonDetails}
      />
      <Button
        text="Save for later"
        buttonType="secondary"
        maxWidth
        onClick={() => artworkId && saveDraft(artworkId, true)}
      />
      <Button
        text="Back"
        maxWidth
        onClick={() => artworkId && navigate(routes.AddArtworkOwnership.path(artworkId))}
        buttonType="secondary"
      />
    </div>
  );

  return (
    <>
      {fetching ? (
        <Loader minHeight={150} />
      ) : (
        <>
          <AddArtworkTopbar step={CURRENT_STEP.label} />
          <TitleBlack text="Register artwork" />
          <div className={styles.root}>
            <div className={styles.mainColumn}>
              <SectionHeader title="Author of the artwork" />
              {authorCard}
              <AssetsList assetsUrls={artworkForm.certificationUrls} />
              <SummaryArtworkDetails artwork={artworkForm} />
              <ImagesList imageUrls={artworkForm.imageUrls} artworkName={artworkForm.name} />
              <AssetsList assetsUrls={artworkForm.additionalDocUrls} />
              <SectionHeader title="Current ownership" />
              <div className={styles.ownersList}>{ownershipList}</div>
              <AssetsList assetsUrls={artworkForm.ownershipProofUrls} />
              <ApprovalFromPeople peopleNumber={peopleToApprove} variant={'artworkRegistration'} />
              {buttons}
              <div>Info about cost of the service will be here.</div>
            </div>
            <AddArtworkSteps step={CURRENT_STEP.label} />
          </div>
        </>
      )}
    </>
  );
};

export default observer(AddArtworkSummary);
