import { ReactNode, useState } from 'react';
import { NumberFormatValues } from 'react-number-format';
import { Currency } from 'domain/types/Domain.types';
import CurrencyInputWrapper from 'theme/atoms/currencyInputWrapper';
import UserCard from 'theme/atoms/userCard';
import { selectInputOnFocus } from 'utils/helpers';
import styles from '../SplitCommission.module.scss';

interface SplitCommissionRowProps {
  name: string;
  title?: string;
  value: string;
  thisAndTotalCommissionsToCompare: { thisC: Big; totalC: Big };
  onChange: (values: NumberFormatValues) => void;
  removeItemButton: ReactNode;
  onBlur: () => void;
  currency: Currency;
}

const SplitCommissionRow = ({
  name,
  title = '',
  value,
  thisAndTotalCommissionsToCompare,
  onChange,
  removeItemButton,
  onBlur,
  currency,
}: SplitCommissionRowProps): React.JSX.Element => {
  const [error, setError] = useState(false);

  return (
    <div className={styles.splitCommissionRowWrapper}>
      <div className={styles.splitCommissionRow}>
        <UserCard id={name}>
          <UserCard.NameAndTitle name={name} title={title} numberOfLinesInName={2} />
        </UserCard>
        <CurrencyInputWrapper
          name="splitCommission"
          onValueChange={onChange}
          value={value}
          onBlur={() => {
            onBlur();
            if (thisAndTotalCommissionsToCompare.thisC.gt(thisAndTotalCommissionsToCompare.totalC)) {
              setError(true);
              setTimeout(() => setError(false), 3000);
            }
          }}
          onFocus={selectInputOnFocus}
          placeholder="Amount"
          className={styles.input}
          currency={currency}
        />
        {removeItemButton}
      </div>
      {error && <div className={styles.error}>Partial commission cannot be greater than total commission.</div>}
    </div>
  );
};

export default SplitCommissionRow;
