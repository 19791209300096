import { OrganizationKind } from 'organization/types';
import { AuthMethodTileProps, OrganizationTileProps, UserIdentityType } from './types';

export const LOCAL_STORAGE_APP_NAME = 'art_network';

export const LOCAL_STORAGE_AUTH_NAME = {
  ACCESS_TOKEN: `${LOCAL_STORAGE_APP_NAME}__auth__access_token__${location.hostname}`,
  DEVICE_ID: `${LOCAL_STORAGE_APP_NAME}__auth__device_id__${location.hostname}`,
  REFRESH_TOKEN: `${LOCAL_STORAGE_APP_NAME}__auth__ref_token__${location.hostname}`,
  CONTEXT: `${LOCAL_STORAGE_APP_NAME}__context__${location.hostname}`,
  ORGANIZATION_TYPE: `${LOCAL_STORAGE_APP_NAME}__organization__type__${location.hostname}`,
};

export const authMethodTiles: AuthMethodTileProps[] = [
  {
    userIdentityType: UserIdentityType.TimeBasedOneTimePassword,
    title: 'Authenticator App',
    description: 'Get codes from an app like 1Password, Authy, Google Authenticator.',
    id: UserIdentityType.TimeBasedOneTimePassword,
  },
  {
    userIdentityType: UserIdentityType.Phone,
    title: 'Get a text message',
    description: 'Receive a unique code via SMS.',
    id: UserIdentityType.Phone,
  },
];

export const organizationTiles: OrganizationTileProps[] = [
  {
    organizationKind: OrganizationKind.Individual,
    title: 'For individuals',
    description: "Whether you're an artist, advisor, or art collector, our individual account is for you",
    id: OrganizationKind.Individual,
  },
  {
    organizationKind: OrganizationKind.Institutional,
    title: 'For institutions',
    description:
      'Tailored to the needs of businesses, ensuring efficient and structured organization and team management.',
    id: OrganizationKind.Institutional,
  },
];

export const userIdentityTypeToRouteMap: Partial<
  Record<UserIdentityType, { add: string; login: string; settings: string }>
> = {
  Phone: {
    add: 'LoginAddPhone',
    login: 'LoginWithPhone',
    settings: 'SettingsSecurityPhone',
  },
  TimeBasedOneTimePassword: {
    add: 'LoginAddAuthApp',
    login: 'LoginWithAuthApp',
    settings: 'SettingsSecurityAuthApp',
  },
};

export const AUTH_APP_CODE_LENGTH = 6;
export const PHONE_CODE_LENGTH = 6;
export const QR_SECRET_CHUNK_LENGTH = 4;
export const RECOVERY_CODE_LENGTH = 8;

export const USER_IDENTITY_TYPES_AVAILABLE_AS_MFA_METHODS: UserIdentityType[] = [
  UserIdentityType.Phone,
  UserIdentityType.TimeBasedOneTimePassword,
];

export const MINIMAL_AUTHENTICATION_LEVEL = 2;
