import { useState } from 'react';
import { z } from 'zod';

type FieldErrors<U> = {
  [key in keyof U]?: string[];
};

type UseZodFormValidationReturn<T extends z.ZodTypeAny> = {
  validateData: () => boolean;
  errors: FieldErrors<z.infer<T>>;
  clearErrors: () => void;
};

export const useZodFormValidation = <T extends z.ZodTypeAny>(
  schema: T,
  data: z.infer<T>
): UseZodFormValidationReturn<T> => {
  const [errors, setErrors] = useState<FieldErrors<T>>({});

  const validateData = (): boolean => {
    const result = schema.safeParse(data);

    if (!result.success) {
      setErrors(result.error.flatten().fieldErrors);
      return false;
    } else {
      setErrors({});
      return true;
    }
  };

  const clearErrors = (): void => setErrors({});

  return { validateData, errors, clearErrors };
};
