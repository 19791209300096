import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import Big from 'big.js';
import ArtistResellRightsRange from 'theme/atoms/artistResellRightsRange';
import Actions from '../actions/Actions';
import styles from './ConsignmentDetails.module.scss';
import Permissions from './components/Permissions';
import Pricing from './components/Pricing';
import Restrictions from './components/Restrictions';
import Timestamps from './components/Timestamps';
import TransactionNote from './components/TransactionNote';

interface ConsignmentDetailsProps {
  withoutActions?: boolean;
}

const ConsignmentDetails = ({ withoutActions }: ConsignmentDetailsProps): JSX.Element => {
  const { consignmentStore } = useStores();
  const { consignment, consignmentItem } = consignmentStore;

  if (!consignment) return <></>;

  return (
    <div className={styles.root}>
      <Permissions consignment={consignment} />
      <Restrictions salesRestrictions={consignment.salesRestrictions} />
      <Pricing consignment={consignment} />
      <Timestamps consignment={consignment} />
      {!withoutActions && <Actions />}
      <TransactionNote />
      {consignmentItem?.artwork.artistResellRightsPercent !== undefined &&
        consignmentItem.artwork.artistResellRightsPercent >= 0 && (
          <ArtistResellRightsRange
            artistResellRightsPercent={consignmentItem.artwork.artistResellRightsPercent}
            askingPrice={consignment.askingPrice}
            askingPriceWithCommissions={Big(consignment.askingPrice).plus(consignment.maxCommission)}
            currency={consignment.currency}
          />
        )}
    </div>
  );
};

export default observer(ConsignmentDetails);
