import { ReactComponent as ArtworkIcon } from 'theme/assets/svg/artwork.svg';
import { ReactComponent as IndividualIcon } from 'theme/assets/svg/person.svg';
import { Option } from 'theme/atoms/Select/Select';
import { TileType } from 'theme/atoms/tiles/Tiles';

export const GROUP_BY: TileType[] = [
  { id: 'artwork', label: 'Artwork', icon: <ArtworkIcon /> },
  { id: 'consignee', label: 'Consignee', icon: <IndividualIcon /> },
];

export const SORT_OPTIONS: Option[] = [
  {
    label: 'Author',
    value: 'artworkAuthor',
    category: 'artworks',
  },
  {
    label: 'Name',
    value: 'artworkName',
    category: 'artworks',
  },
  {
    label: 'Consignee',
    value: 'consignee',
    category: 'consignments',
  },
  // {
  //   // TODO not implemented on BE yet
  //   label: 'Price',
  //   value: 'price',
  //   category: 'consignments',
  // },
  {
    label: 'Expires At',
    value: 'expireAt',
    category: 'consignments',
  },
];

export const CATEGORIES = ['artworks', 'consignments'] as const;
export type CategoryItem = (typeof CATEGORIES)[number];
