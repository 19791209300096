import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import styles from 'auth/Auth.module.scss';
import { Button, Subtitle, TitleBlack } from 'theme/atoms';
import Breadcrumbs, { Breadcrumb } from 'theme/atoms/Breadcrumbs';
import Topbar from 'theme/atoms/Topbar';
import { useTitle } from 'utils/hooks';

const customerSupportEmail = 'contact@artnetwork.com'; // TODO update

const BREADCRUMBS: Breadcrumb[] = [
  {
    label: 'Email sent',
  },
];

function SignupVerifyEmailIssues(): JSX.Element {
  useTitle('Sign up - email sent');

  const { createUserStore } = useStores();
  const { userRegistrationData } = createUserStore;

  return (
    <div className={clsx(styles.root, styles.oneColumnLayout)}>
      <Topbar>
        <Breadcrumbs breadcrumbs={BREADCRUMBS} />
      </Topbar>
      <div className={styles.innerWrapper}>
        <TitleBlack text="Verification email has been sent again" />
        <Subtitle>
          <div>
            Still have problems?
            <div className={styles.verificationEmailOptionsList}>
              <div className={styles.verificationEmailOption}>
                <span className={styles.number}>1.</span>
                <span className={styles.text}>Check your spam folder.</span>
              </div>
              <div className={styles.verificationEmailOption}>
                <span className={styles.number}>2.</span>
                <span className={styles.text}>
                  Check that your email is the same as what you typed in: {userRegistrationData?.email}
                </span>
              </div>
              <div className={styles.verificationEmailOption}>
                <span className={styles.number}>3.</span>
                <span className={styles.text}>
                  <Button text="Change" buttonType="textButton" to={routes.SignupChangeEmail.path()} /> email address.
                </span>
              </div>
              <div className={styles.verificationEmailOption}>
                <span className={styles.number}>4.</span>
                <span className={styles.text}>
                  <Button text="Contact" buttonType="textButton" externalHref={`mailto:${customerSupportEmail}`} /> our
                  Customer Support.
                </span>
              </div>
            </div>
          </div>
        </Subtitle>
      </div>
    </div>
  );
}

export default observer(SignupVerifyEmailIssues);
