import { makeObservable } from 'mobx';
import { RootStore } from 'RootStore';
import SortAndFilterBasicStore, {
  SortAndFilterBasicStoreProps as BasicStoreProps,
} from 'domain/SortAndFilterBasicStore/SortAndFilterBasicStore';
import { InvoiceState } from 'domain/types/Invoices.types';
import { BANK_INVOICES_CATEGORIES_MAP } from '../constants';

class BankInvoicesSortAndFilterStore extends SortAndFilterBasicStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    super(rootStore);
    this.rootStore = rootStore;
    makeObservable(this, { ...BasicStoreProps });
  }

  get stateByCategoryId(): InvoiceState[] {
    if (this.categoryId === null) return [];

    return BANK_INVOICES_CATEGORIES_MAP[this.categoryId].condition;
  }
}

export default BankInvoicesSortAndFilterStore;
