import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { ButtonProps, Classes } from '../Button';
import styles from '../Button.module.scss';

interface LinkElementProps extends ButtonProps {
  classes: Classes;
}

const LinkElement = ({
  to = '',
  classes,
  onClick,
  iconStart,
  text,
  iconEnd,
  disabled,
  loading,
  light = false,
}: LinkElementProps): JSX.Element => (
  <Link to={to} {...classes} onClick={(event) => !(disabled || loading) && onClick?.(event)}>
    {iconStart}
    <span className={clsx(styles.text, { [styles.light]: light })}>{text}</span>
    {iconEnd}
  </Link>
);

export default LinkElement;
