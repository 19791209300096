import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { useStores } from 'RootStore';
import { ReactComponent as AmericanExpressIcon } from 'billing/assets/AmericanExpress.svg';
import { ReactComponent as DinersClubIcon } from 'billing/assets/DinersClub.svg';
import { ReactComponent as DiscoverIcon } from 'billing/assets/Discover.svg';
import { ReactComponent as JCBIcon } from 'billing/assets/JCB.svg';
import { ReactComponent as MastercardIcon } from 'billing/assets/Mastercard.svg';
import { ReactComponent as UnionPayIcon } from 'billing/assets/UnionPay.svg';
import { ReactComponent as VisaIcon } from 'billing/assets/Visa.svg';
import { Button } from 'theme/atoms';
import Loader from 'theme/atoms/loader/Loader';
import styles from './PaymentMethod.module.scss';

const CREDIT_CARDS = [
  { id: 'visa', label: 'Visa', icon: <VisaIcon /> },
  { id: 'mastercard', label: 'Mastercard', icon: <MastercardIcon /> },
  { id: 'amex', label: ' American Express', icon: <AmericanExpressIcon /> },
  { id: 'diners', label: ' Diners Club', icon: <DinersClubIcon /> },
  { id: 'discover', label: 'Discover', icon: <DiscoverIcon /> },
  { id: 'jbc', label: 'JCB', icon: <JCBIcon /> },
  { id: 'unionpay', label: 'Union Pay', icon: <UnionPayIcon /> },
];

const PaymentMethod = (): JSX.Element => {
  const { billingStore } = useStores();
  const { loading, paymentMethods, isPaymentMethodActive, openPortal, fetchPaymentMethods, portalUrl } = billingStore;
  const cardToDisplay = paymentMethods &&
    paymentMethods.length > 0 && {
      ...CREDIT_CARDS.find((card) => card.id === paymentMethods[0].card.brand),
      ...paymentMethods[0].card,
    };

  useEffect(() => {
    fetchPaymentMethods();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEditMethodPayment = (): void => {
    if (portalUrl) {
      window.open(portalUrl, '_blank', 'noreferrer');
    } else {
      openPortal().then((url) => {
        url && window.open(url, '_blank', 'noreferrer');
      });
    }
  };

  const loader = loading && <Loader small />;
  const card = !loading && cardToDisplay && (
    <>
      <div className={styles.image}>{cardToDisplay.icon}</div>
      <div>
        <div className={styles.card}>
          <span>{cardToDisplay.label}</span>
          <span>*{cardToDisplay.last4}</span>
        </div>
        <div className={clsx(styles.status, { [styles.active]: isPaymentMethodActive })}>
          {isPaymentMethodActive ? 'Active' : 'No payment method'}
        </div>
      </div>
    </>
  );

  const noCardToDisplay = !isPaymentMethodActive && !loading && <div className={styles.status}>No payment method</div>;

  return (
    <div className={styles.root}>
      <div className={clsx(styles.column, { [styles.noPaymentMethod]: loading || !isPaymentMethodActive })}>
        {loader}
        {card}
        {noCardToDisplay}
      </div>

      <Button text={`${isPaymentMethodActive ? 'Edit' : 'Add'} payment method`} onClick={handleEditMethodPayment} />
    </div>
  );
};

export default observer(PaymentMethod);
