import { IBAN_CODE_REGEX, SWIFT_CODE_REGEX } from 'domain/regex';
import { z } from 'zod';

const REQUIRED_FIELD_MESSAGE = 'Required';

export const bankAccountZodFormSchema = z.object({
  iban: z.string().trim().min(1, { message: REQUIRED_FIELD_MESSAGE }).regex(IBAN_CODE_REGEX, {
    message:
      'The IBAN must consist of a two-letter country code, two check digits, and 11 to 30 alphanumeric characters, varying by country.',
  }),
  bankName: z.string().trim().min(1, { message: REQUIRED_FIELD_MESSAGE }),
  bankAddress: z.string().trim().min(1, { message: REQUIRED_FIELD_MESSAGE }),
  swift: z.string().trim().min(1, { message: REQUIRED_FIELD_MESSAGE }).regex(SWIFT_CODE_REGEX, {
    message:
      'SWIFT code must consist of 8 or 11 alphanumeric characters, the first six of which are letters, and the remaining letters or numbers.',
  }),
});
