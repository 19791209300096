import { makeAutoObservable, runInAction } from 'mobx';
import { RootStore } from 'RootStore';
import * as authRequests from 'auth/requests';
import { getMessageFromApiErrorResponse } from 'domain/getMessageFromApiErrorResponse';
import * as userRequests from 'user/requests';
import { UserRegistrationData } from './types';

class CreateUserStore {
  rootStore: RootStore;

  submitting = false;
  userRegistrationData: UserRegistrationData | null = null;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  createUser = (changedEmail?: string): Promise<boolean | void> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    if (!this.userRegistrationData) return Promise.resolve();
    runInAction(() => (this.submitting = true));

    return userRequests
      .createUser(
        !changedEmail
          ? this.userRegistrationData
          : {
              ...this.userRegistrationData,
              email: changedEmail,
            }
      )
      .then(() => {
        return true;
      })
      .catch((err) => {
        if (changedEmail) {
          addToast(toastMessages.DEFAULT.ERROR, 'error');
        } else {
          addToast(getMessageFromApiErrorResponse(err.response?.data.type, toastMessages.USERS.CREATE_ERROR), 'error');
        }
      })
      .finally(() => runInAction(() => (this.submitting = false)));
  };

  updateUserRegistrationData = (data: UserRegistrationData): void => {
    this.userRegistrationData = { ...this.userRegistrationData, ...data };
  };

  sendNewLinkToVerifyEmail = (): Promise<void | boolean> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.submitting = true));

    return authRequests
      .getToken({ email: this.userRegistrationData?.email || this.rootStore.authStore.emailFromLogin })
      .then(() => {
        return true;
      })
      .catch(() => addToast(toastMessages.DEFAULT.ERROR, 'error'))
      .finally(() => runInAction(() => (this.submitting = false)));
  };

  resetAll = (): void => {
    runInAction(() => {
      this.userRegistrationData = null;
    });
  };
}

export default CreateUserStore;
