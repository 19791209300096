import { Fragment } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { ReactComponent as AddIcon } from 'theme/assets/svg/add.svg';
import { Button } from 'theme/atoms';
import { ArtworkFieldsIdType, DataFieldType, DraggedItem } from '../../../types';
import { arrayMove, getNumberOfCheckedFields } from '../../../utils';
import DataField from '../DataField';
import ListDropZone from '../listDropZone/ListDropZone';
import styles from './DataFieldList.module.scss';

const DRAGGABLE_FIELD_HEIGHT = 40;
const DRAGGABLE_NESTED_FIELD_HEIGHT = 96;
const DRAGGABLE_FIELDS_GAP = 8;

interface DataFieldsProps {
  fields: DataFieldType[];
  setFields: (newFields: DataFieldType[]) => void;
  showViewMoreButton: boolean;
  onViewMoreClick: () => void;
  initialVisibleDataFieldIds: ArtworkFieldsIdType[];
}

const DataFieldList = ({
  fields,
  setFields,
  showViewMoreButton,
  onViewMoreClick,
  initialVisibleDataFieldIds,
}: DataFieldsProps): JSX.Element => {
  const { artworksViewOptionsStore } = useStores();
  const { showUncheckingLastFieldError, setShowUncheckingLastFieldError } = artworksViewOptionsStore;

  const handleDrop = (item: DraggedItem, position: number): void => {
    if (item.isChild) {
      const child = fields.find((field) => field.id === item.parentId)?.child;

      const newFields = fields.map((field) => {
        if (field.id === item.parentId) {
          delete field.child;
          return field;
        }

        return field;
      });

      if (child) newFields.splice(position, 0, child);

      setFields(newFields);
    } else {
      const isDraggingDown = item.index < position;

      const fromPosition = item.index;
      const toPosition = position - (isDraggingDown ? 1 : 0);
      const newFields = arrayMove(fields, fromPosition, toPosition);

      setFields(newFields);
    }
  };

  const handleNestedDrop = (item: DraggedItem, position: number, zoneId: string): void => {
    if (item.isChild) {
      const child = fields.find((field) => field.id === item.parentId)?.child;

      const newFields = fields.map((field) => {
        if (child) {
          if (field.id === item.parentId) {
            delete field.child;
            return field;
          }

          if (field.id === zoneId)
            return {
              ...field,
              // mark field and child checked if field or child is checked
              isChecked: child.isChecked ? true : field.isChecked,
              child: {
                ...child,
                isChecked: field.isChecked ? true : child.isChecked,
              },
            };
        }
        return field;
      });

      setFields(newFields);
    } else {
      const fieldsCopy = fields.slice();

      const childIndex = fieldsCopy.findIndex((field) => field.id === item.id);
      const child = fieldsCopy.splice(childIndex, 1)[0];

      const isDraggingDown = item.index < position;

      const toPosition = position - (isDraggingDown ? 1 : 0);
      const newFields = fieldsCopy.map((field, index) =>
        index === toPosition
          ? {
              ...field,
              // mark field and child checked if field or child is checked
              isChecked: child.isChecked ? true : field.isChecked,
              child: {
                ...child,
                isChecked: field.isChecked ? true : child.isChecked,
              },
            }
          : field
      );

      setFields(newFields);
    }
  };

  const handleDragStart = (): void => {
    setShowUncheckingLastFieldError(false);
  };

  const handleUpdateFieldsIsCheckedValue = (fieldId: string, isChecked: boolean): void => {
    if (getNumberOfCheckedFields(fields) < 2 && isChecked === false) {
      setShowUncheckingLastFieldError(true);
      return;
    }

    if (showUncheckingLastFieldError) setShowUncheckingLastFieldError(false);

    const newFields = fields.map((field) => {
      if (field.id === fieldId) return { ...field, isChecked };
      if (field.child?.id === fieldId) return { ...field, child: { ...field.child, isChecked } };
      return field;
    });

    setFields(newFields);
  };

  const handleViewMoreClick = (): void => {
    setShowUncheckingLastFieldError(false);
    onViewMoreClick();
  };

  const getFieldsHeight = (): string => {
    if (!showViewMoreButton) return 'none';

    let heightInPx = DRAGGABLE_FIELDS_GAP;

    fields.forEach((field) => {
      if (!initialVisibleDataFieldIds.includes(field.id)) return;

      heightInPx =
        heightInPx + (field.child ? DRAGGABLE_NESTED_FIELD_HEIGHT : DRAGGABLE_FIELD_HEIGHT) + DRAGGABLE_FIELDS_GAP;
    });

    return heightInPx + 'px';
  };

  return (
    <div className={styles.root}>
      {showUncheckingLastFieldError ? <span className={styles.error}>At least one data field is required</span> : null}

      <div className={styles.fields} style={{ maxHeight: getFieldsHeight() }}>
        <DndProvider backend={HTML5Backend}>
          {fields.map((field, index) => (
            <Fragment key={field.id}>
              <ListDropZone position={index} onDrop={handleDrop} />
              <DataField
                index={index}
                field={field}
                onChange={handleUpdateFieldsIsCheckedValue}
                onDrop={handleNestedDrop}
                onDragStart={handleDragStart}
              />
            </Fragment>
          ))}
          <ListDropZone position={fields.length} onDrop={handleDrop} />
        </DndProvider>
      </div>

      {showViewMoreButton ? (
        <Button
          type="button"
          buttonType="textButton"
          className={styles.viewMoreButton}
          iconEnd={<AddIcon />}
          text="view more"
          onClick={handleViewMoreClick}
        />
      ) : null}
    </div>
  );
};

export default observer(DataFieldList);
