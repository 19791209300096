import styles from './Topbar.module.scss';

interface TopbarProps {
  children: React.ReactNode;
}

const Topbar = ({ children }: TopbarProps): JSX.Element => {
  return (
    <div className={styles.root}>
      <div className={styles.innerWrapper}>{children}</div>
    </div>
  );
};

export default Topbar;
