import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import Big from 'big.js';
import { ProductEnum } from 'billing/types';
import { FormValidation } from 'domain/types';
import { Contact } from 'domain/types/Contact.types';
import ContactPicker from 'network/contactPicker/ContactPicker';
import { ContactConnection } from 'network/types';
import { Button } from 'theme/atoms';
import SectionHeader from 'theme/atoms/Titles/SectionHeader';
import IconComponent, { icons } from 'theme/atoms/iconComponent';
import ShareholdersList from 'theme/atoms/shareholdersList/ShareholdersList';
import { useDebounceIsPanelOpen } from 'theme/atoms/slidingPanel/useDebounceIsPanelOpen';
import { useSplitAmount } from 'utils/hooks/useSplitAmount';
import styles from './PickOwner.module.scss';

interface PickOwnerProps {
  cleanValidationErrors: (keys: string[]) => void;
  validation: FormValidation;
}

const PickOwner = ({ cleanValidationErrors, validation }: PickOwnerProps): JSX.Element => {
  const { addArtworkStore } = useStores();
  const {
    ownersToDisplay,
    iAmTheOwner,
    artworkForm,
    contactsToExclude,
    setContactAsAuthorOrOwnerInStoreAndUpdateForm,
    deleteOwnerFromStoreAndUpdateForm,
    updateArtworkForm,
  } = addArtworkStore;

  const [contactPickerIsOpen, setContactPickerIsOpen] = useState(false);

  const debouncedContactPickerIsOpen = useDebounceIsPanelOpen(contactPickerIsOpen);

  const { amounts, onChange, onBlur, addItem, removeItem } = useSplitAmount({
    totalAmount: Big(100),
    initialAmounts: artworkForm.owners.map((owner) => owner.participationPercent) || [],
  });

  useEffect(() => {
    if (artworkForm.owners.length === amounts.length) {
      const updatedOwners = artworkForm.owners.map((owner, index) => {
        return {
          ...owner,
          participationPercent: amounts[index],
        };
      });
      updateArtworkForm('owners', updatedOwners);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amounts]);

  const handleOpenContactPicker = (): void => {
    debouncedContactPickerIsOpen && setContactPickerIsOpen(true);
    cleanValidationErrors(['ownerRequired', 'totalShares']);
  };

  const onPickOwner = (contact: ContactConnection): void => {
    setContactAsAuthorOrOwnerInStoreAndUpdateForm(false, 'owners', contact);
    setContactPickerIsOpen(false);
    addItem();
  };

  const handlePickMeAsOwner = (): void => {
    setContactAsAuthorOrOwnerInStoreAndUpdateForm(true, 'owners');
    setContactPickerIsOpen(false);
    addItem();
  };

  const handleDeleteOwner = (contact: Contact, index: number): void => {
    cleanValidationErrors(['totalShares']);
    deleteOwnerFromStoreAndUpdateForm(contact);
    removeItem(index);
  };

  const currentOwnershipHeader = (
    <div className={styles.sectionWrapper}>
      <SectionHeader title="Current ownership" />
      <Button
        text="Add"
        iconStart={<IconComponent icon={icons.add} />}
        buttonType="tertiary"
        onClick={handleOpenContactPicker}
      />
    </div>
  );

  const currentOwnershipInfo = ownersToDisplay.length === 0 && (
    <div className={styles.info}>
      Add current owner profile
      <span className={styles.error}> {validation.ownerRequired}</span>
    </div>
  );

  const currentOwnershipError = validation.totalShares ? (
    <div className={styles.error}>{validation.totalShares}</div>
  ) : (
    <></>
  );

  return (
    <>
      {currentOwnershipHeader}
      {currentOwnershipInfo}
      <ShareholdersList
        shareholders={ownersToDisplay}
        onDelete={handleDeleteOwner}
        onChange={onChange}
        errorMsg={validation.shareRequired}
        amounts={amounts}
        onBlur={onBlur}
      />
      {currentOwnershipError}
      <ContactPicker
        title="Add current ownership"
        isOpen={contactPickerIsOpen}
        onPickContact={onPickOwner}
        onClose={() => setContactPickerIsOpen(false)}
        onPickMeAsContact={handlePickMeAsOwner}
        contactsToExclude={contactsToExclude}
        withMeAsContact={!iAmTheOwner ? 'owner' : undefined}
        additionalInfo={{ field: ProductEnum.ArtworkCertificationExtraOwner, copy: 'per extra person' }}
      />
    </>
  );
};

export default observer(PickOwner);
