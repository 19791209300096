import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import Big from 'big.js';
import { CURRENCY_OPTIONS } from 'domain/constants';
import { Currency } from 'domain/types/Domain.types';
import Select from 'theme/atoms/Select';
import Billing from 'theme/atoms/billing';
import CurrencyInputWrapper from 'theme/atoms/currencyInputWrapper';
import { ValidationError } from 'transactions/transaction/transactionForm/TransactionFormStore';
import styles from '../TransactionBilling.module.scss';

interface InitialPriceProps {
  isSummaryView: boolean;
}

const SettingPrice = ({ isSummaryView }: InitialPriceProps): JSX.Element => {
  const [askingPrice, setAskingPrice] = useState<string>('');
  const [currency, setCurrency] = useState<Currency>('USD');
  const { transactionFormStore } = useStores();
  const { updateSellerPriceAndCurrency, removeValidationError, validationErrors } = transactionFormStore;

  const error = validationErrors.find((error) => error === ValidationError.NoPrice)
    ? 'Price must be greater than 0'
    : undefined;

  const handlePriceChange = (value: string): void => {
    setAskingPrice(value);
    removeValidationError(ValidationError.NoPrice);
  };

  const handleCurrencyChange = (optionLabel: Currency): void => {
    setCurrency(optionLabel);
    updateSellerPriceAndCurrency(Big(askingPrice || 0), optionLabel);
  };
  const notSummaryView = !isSummaryView && (
    <>
      <Billing.Row>
        <Billing.Cell>
          <div className={styles.priceLabel}>Asking price:</div>
        </Billing.Cell>
        <Billing.Cell>
          <CurrencyInputWrapper
            name="askingPrice"
            placeholder="Enter the price"
            className={styles.inputWrapper}
            currency={currency}
            value={askingPrice}
            onValueChange={(values) => handlePriceChange(values.value || '')}
            onBlur={() => updateSellerPriceAndCurrency(Big(askingPrice || 0), currency)}
            error={error}
          />
        </Billing.Cell>
      </Billing.Row>
      <Billing.Row>
        <Billing.Cell>
          <div className={styles.priceLabel}>Currency:</div>
        </Billing.Cell>
        <Billing.Cell>
          <div className={styles.currencySelectWrapper}>
            <Select
              className={styles.currency}
              options={CURRENCY_OPTIONS}
              value={{
                value: currency,
                label: currency,
              }}
              onChange={(option) => handleCurrencyChange(option.label as Currency)}
            />
          </div>
        </Billing.Cell>
      </Billing.Row>
    </>
  );

  return <>{notSummaryView}</>;
};

export default observer(SettingPrice);
