import { useEffect } from 'react';
import { MantineProvider } from '@mantine/core';
import '@mantine/core/styles.css';
import RootStoreContext, { rootStore } from 'RootStore';
import { saveDeviceId } from 'api';
import Toasts from 'toasts';
import Router from './routes/Router';

function App(): JSX.Element {
  useEffect(() => {
    saveDeviceId();
  }, []);

  return (
    <RootStoreContext.Provider value={rootStore}>
      <MantineProvider>
        <Router />
        <Toasts />
      </MantineProvider>
    </RootStoreContext.Provider>
  );
}

export default App;
