import { useStores } from 'RootStore';
import { organizationTiles } from 'auth/constants';
import { OrganizationKind } from 'organization/types';
import { Subtitle, TitleBlack } from 'theme/atoms';
import RadioTilesForm from 'theme/cells/radioTiles/radioTilesForm';
import styles from './CreateOrganization.module.scss';

const ChooseOrganization = (): React.JSX.Element => {
  const {
    organizationStore: { setCreatingOrganizationKind },
  } = useStores();

  const onSubmit = (organizationKind: OrganizationKind): void => {
    setCreatingOrganizationKind(organizationKind);
  };

  return (
    <div className={styles.innerWrapper}>
      <TitleBlack dontLimitWidth>Choose account type</TitleBlack>
      <Subtitle>Define the type of account you want to use</Subtitle>
      <RadioTilesForm tiles={organizationTiles} onSubmit={(val) => onSubmit(val as OrganizationKind)} />
    </div>
  );
};

export default ChooseOrganization;
