import { ReactNode } from 'react';
import { useDrop } from 'react-dnd';
import { DRAGGABLE_TYPES } from '../../../constants';
import { DraggedItem } from '../../../types';
import DropIndicator from '../dropIndicator';
import styles from './FieldDropZone.module.scss';

interface FieldDropZoneProps {
  position: number;
  zoneId: string;
  hasNestedField: boolean;
  children?: ReactNode;
  onDrop: (item: DraggedItem, position: number, zoneId: string) => void;
}

const FieldDropZone = ({ position, zoneId, hasNestedField, children, onDrop }: FieldDropZoneProps): JSX.Element => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: [DRAGGABLE_TYPES.FIELD, DRAGGABLE_TYPES.NESTED_FIELD],
    drop: (item) => {
      onDrop(item as DraggedItem, position, zoneId);
    },
    canDrop: (item) => {
      const { id, hasChild } = item as DraggedItem;
      return zoneId !== id && !hasChild && !hasNestedField;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = isOver && canDrop;

  return (
    <div ref={drop} className={styles.root}>
      {children}
      <div className={styles.dropIndicator}>
        <DropIndicator isActive={isActive} />
      </div>
    </div>
  );
};

export default FieldDropZone;
