import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { bankAccountZodFormSchema } from 'domain/bankAccountZodFormSchema';
import { RawBankAccount } from 'domain/types/BankAccount.types';
import { Input, Button } from 'theme/atoms';
import FormError from 'theme/atoms/formError';
import SlidingPanel from 'theme/atoms/slidingPanel';
import { useZodFormValidation } from 'utils/hooks';
import styles from './EscrowAccountForm.module.scss';

const EscrowAccountForm = observer((): JSX.Element => {
  const { escrowAccountFormStore } = useStores();
  const {
    escrowAccount,
    formData,
    setFormData,
    fetchedBankAccountData,
    escrowAccountFormIsOpen,
    setEscrowAccountFormIsOpen,
    submittingEscrowAccountDetails,
    updateEscrowAccount,
  } = escrowAccountFormStore;

  const { validateData, errors, clearErrors } = useZodFormValidation(
    bankAccountZodFormSchema,
    formData as RawBankAccount
  );

  useEffect(() => {
    setFormData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [escrowAccountFormIsOpen]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    clearErrors();
    formData && setFormData({ ...formData, [event.target.name]: event.target.value || '' });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    if (!validateData()) return;
    updateEscrowAccount().then((res) => res?.success && setEscrowAccountFormIsOpen(false));
  };

  const handleClose = (): void => {
    setEscrowAccountFormIsOpen(false);
    clearErrors();
  };

  const buttonAndFormTitle = `${escrowAccount ? 'Edit' : 'Add'} account`;

  const areThereAnyValidationErrors = Object.keys(errors).length > 0;

  return (
    <SlidingPanel title={buttonAndFormTitle} onClose={handleClose} isOpen={escrowAccountFormIsOpen} maxWidth>
      <form className={styles.form} onSubmit={handleSubmit}>
        {fetchedBankAccountData.map(({ name, label }) => {
          return (
            <Input
              key={name}
              value={formData ? formData[name] : ''}
              name={name}
              label={label || name}
              onChange={handleChange}
              error={errors[name]?.[0]}
              onBlur={() =>
                ['iban', 'swift'].includes(name) &&
                formData &&
                setFormData({ ...formData, [name]: formData[name].replaceAll(' ', '') })
              }
              className={errors[name]?.[0] ? styles.errorInput : ''}
            />
          );
        })}
        <div className={styles.submitButtonContainer}>
          <FormError showError={areThereAnyValidationErrors} />
          <Button
            text={buttonAndFormTitle}
            maxWidth
            type="submit"
            loading={submittingEscrowAccountDetails}
            disabled={areThereAnyValidationErrors}
          />
        </div>
      </form>
    </SlidingPanel>
  );
});

export default EscrowAccountForm;
