import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import { TransactionStatus } from 'domain/types/Transactions.types';
import { Button } from 'theme/atoms';
import Avatar from 'theme/atoms/avatar';
import IconButton from 'theme/atoms/iconButton';
import IconComponent, { icons } from 'theme/atoms/iconComponent';
import { useDebounceIsPanelOpen } from 'theme/atoms/slidingPanel/useDebounceIsPanelOpen';
import InvoiceDetails from 'transactions/invoices/components/invoiceDetails';
import { TransactionWrapper } from 'transactions/requests.types';
import TransactionInvoicesInfo from '../transactionInvoicesInfo';
import styles from './TransactionInvoices.module.scss';

const ACCEPTABLE_TRANSACTION_STATUSES_FOR_INVOICES = [
  TransactionStatus.Accepted,
  TransactionStatus.AcceptedEscrowAccountGranted,
  TransactionStatus.InvoiceVerification,
  TransactionStatus.WaitingForPayment,
  TransactionStatus.ReadyForExecution,
  TransactionStatus.Executing,
  TransactionStatus.SignificantPaymentsMade,
  TransactionStatus.Executed,
  TransactionStatus.Closed,
];

const TransactionInvoices = (): JSX.Element => {
  const { invoicesStore } = useStores();
  const { updateInvoiceUrl, setIsInvoiceInfoOpen, isInvoicesInfoOpen } = invoicesStore;
  const [isPayerOrganizationInfoAvailable, setIsPayerOrganizationInfoAvailable] = useState(false);
  const debouncedIsPanelOpen = useDebounceIsPanelOpen(isInvoicesInfoOpen);

  const navigate = useNavigate();

  const { transactionFormStore } = useStores();
  const transactionWrapper = transactionFormStore.transactionWrapper as TransactionWrapper;
  const transaction = transactionWrapper.transaction;
  const invoices = transaction.invoices;

  useEffect(() => {
    if (invoices) setIsPayerOrganizationInfoAvailable(invoices.some((invoice) => !invoice.payerContactDto?.self));
  }, [invoices]);

  const handleUpdateInvoiceUrl = (id: string, newUrl: string | null): void => {
    updateInvoiceUrl(id, newUrl);
  };

  if (!ACCEPTABLE_TRANSACTION_STATUSES_FOR_INVOICES.includes(transaction.status) || !invoices?.length) return <></>;

  return (
    <div className={styles.root}>
      <h2>
        Invoices
        {isPayerOrganizationInfoAvailable && (
          <IconButton icon={icons.questionMark} onClick={() => debouncedIsPanelOpen && setIsInvoiceInfoOpen(true)} />
        )}
      </h2>
      {invoices &&
        invoices.map((invoice, i) => {
          const isPayer = !!invoice.issuerContactDto?.self;
          const organization = isPayer ? invoice.payerContactDto : invoice.issuerContactDto;
          return (
            <div key={i} className={styles.row}>
              <div className={styles.firstLineWrapper} onClick={() => navigate(routes.Invoice.path(invoice.id))}>
                <div className={styles.organizationWrapper}>
                  <div className={styles.direction}>{isPayer ? 'For: ' : 'From: '}</div>
                  <Avatar id={organization?.contactConnectionId || organization?.organizationName || ''} />
                  <div className={styles.organizationName}>{organization?.organizationName || 'Undisclosed'}</div>
                </div>
                <Button text="Details" iconEnd={<IconComponent icon={icons.next} />} buttonType="tertiary" />
              </div>
              <div>
                <InvoiceDetails
                  assetFromParent={invoice.assetInfo || null}
                  isPayer={isPayer}
                  bankNote={invoice.bankNote}
                  state={invoice.state}
                  alwaysShowBg
                  onUpdateAsset={(asset) => handleUpdateInvoiceUrl(invoice.id, asset?.url || null)}
                  invoiceDocNameWidth={499}
                />
              </div>
            </div>
          );
        })}

      <TransactionInvoicesInfo invoices={invoices} />
    </div>
  );
};

export default observer(TransactionInvoices);
