import Big from 'big.js';
import Select, { Option } from 'theme/atoms/Select/Select';

const MAX_COMMISSION_PERCENT_PRECISION = 2;

interface CommissionSelectProps {
  askingPrice: Big;
  maxCommission: Big;
  setCommissionPercentOption: React.Dispatch<React.SetStateAction<Option | null>>;
  hideValue?: boolean;
  selectedPercentFromParent?: Option;
}

const CommissionPercentSelect = ({
  askingPrice,
  maxCommission,
  setCommissionPercentOption,
  hideValue,
  selectedPercentFromParent,
}: CommissionSelectProps): JSX.Element | null => {
  const maxCommissionPercent = Big(maxCommission).div(askingPrice).times(100);
  const maxCommissionPercentRounded = Big(maxCommissionPercent).round(0, Big.roundDown);

  const isMaxCommissionPercentRoundNumber = Big(maxCommissionPercent)
    .mod(1)
    .round(MAX_COMMISSION_PERCENT_PRECISION)
    .eq(0);

  const maxCommissionOption: Option = {
    value: `${maxCommissionPercent}`,
    label: `${maxCommissionPercent.toFixed(MAX_COMMISSION_PERCENT_PRECISION)}% (max)`,
  };

  const commissionOptions: Option[] = [
    ...(isMaxCommissionPercentRoundNumber ? [] : [maxCommissionOption]),
    ...Array.from(Array(maxCommissionPercentRounded.toNumber() + 1), (_, i) => {
      const reversedIndex = maxCommissionPercentRounded.toNumber() - i;
      const maxSuffix = isMaxCommissionPercentRoundNumber && i === 0 ? ' (max)' : '';
      return {
        value: `${reversedIndex}`,
        label: `${reversedIndex}%${maxSuffix}`,
      };
    }),
  ];

  const handleChange = (option: Option): void => {
    setCommissionPercentOption(option);
  };

  return (
    <Select
      value={
        !hideValue && selectedPercentFromParent
          ? commissionOptions.find((o) => o.value === selectedPercentFromParent.value) || commissionOptions[0]
          : { value: '', label: '%' }
      }
      options={commissionOptions}
      onChange={handleChange}
      isButtonAsText
    />
  );
};

export default CommissionPercentSelect;
