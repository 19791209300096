import React, { useRef, useState } from 'react';
import { useStores } from 'RootStore';
import { AssetWithKey } from 'domain/types/Asset.types';
import { ReactComponent as RemoveIcon } from 'theme/assets/svg/close.svg';
import Modal from 'theme/atoms/modal';
import { getUploadFailedInfo } from './AssetUploadStore';

type AssetWithKeyArrayOrSingle = AssetWithKey[] | AssetWithKey;

interface UseAssetUploadProps {
  onUploadSuccess: (assets: AssetWithKey[]) => void;
  onDeleteSuccess?: (asset: AssetWithKey) => void | Promise<void>;
  onFail?: () => void;
  assets: AssetWithKeyArrayOrSingle | null;
  extensions: string[];
  notMultiple?: boolean;
}

interface ReturnValue {
  isLoading: boolean;
  assetsToDisplay?: AssetWithKeyArrayOrSingle | null;
  triggerInput: () => Promise<void | boolean>;
  InputElement: JSX.Element;
  removeElement: (img: AssetWithKey) => JSX.Element | null;
  initiateFileUploadByDrop: (dataTransfer: DataTransfer) => void;
  fileExtensionError?: boolean;
}

interface HandleFileUploadProps {
  files: FileList;
  extensions: string[];
  onUploadSuccess: (assets: AssetWithKey[]) => void;
  onFail?: () => void;
}

const useAssetUpload = ({
  onUploadSuccess,
  onDeleteSuccess,
  onFail,
  assets,
  extensions,
  notMultiple,
}: UseAssetUploadProps): ReturnValue => {
  const { assetUploadStore, toastsStore } = useStores();
  const [fileExtensionError, setFileExtensionError] = useState(false);
  const { handleAddMultiple, handleDelete, isLoading } = assetUploadStore;
  const { addToast } = toastsStore;

  const [assetToDelete, setFileToDelete] = useState<AssetWithKey | null>(null);

  const uploadRef = useRef<HTMLInputElement>(null);
  const removeRefs = useRef<Array<HTMLDivElement | null>>(
    Array.isArray(assets) ? Array(assets.length).fill(null) : [null]
  );

  const triggerInput = async (): Promise<void> => {
    if (uploadRef.current) uploadRef.current.click();
  };

  const resetInput = (): void => {
    if (uploadRef.current?.value) uploadRef.current.value = '';
  };

  const handleFileUpload = async ({
    files,
    extensions,
    onUploadSuccess,
    onFail,
  }: HandleFileUploadProps): Promise<void> => {
    if (!files.length) return;

    handleAddMultiple(files, extensions)
      .then((addedAssets: AssetWithKey[]) => {
        if (addedAssets) {
          onUploadSuccess(addedAssets);
        } else {
          onFail?.();
        }
      })
      .catch(() => {
        onFail?.();
      });
  };

  const isValidFileFormat = (file: File): boolean => {
    const extension = file.name.split('.').pop()?.toLowerCase() || '';
    return extensions.includes(extension);
  };

  const initiateFileUploadByDrop = (dataTransfer: DataTransfer): void => {
    const files = dataTransfer.files;

    if (files && files.length > 0) {
      const invalidFiles = Array.from(files).filter((file) => !isValidFileFormat(file));
      if (invalidFiles.length > 0) {
        setFileExtensionError(true);
        addToast(
          `The dropped file has an unsupported format. Accepted files format: ${extensions.join(', ')}`,
          'error'
        );
        setTimeout(() => setFileExtensionError(false), 3000);
      } else {
        setFileExtensionError(false);
        handleFileUpload({ files, extensions, onUploadSuccess, onFail });
      }
    }
  };

  const onAddMultiple = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (e.target.files && e.target.files.length)
      await handleFileUpload({ files: e.target.files, extensions, onUploadSuccess, onFail }).finally(resetInput);
  };

  const onDelete = async (asset: AssetWithKey): Promise<void> => {
    try {
      const isSuccessfull = await handleDelete(asset);
      if (isSuccessfull) onDeleteSuccess && onDeleteSuccess(asset);
      else onFail?.();
    } catch (error) {
      onFail?.();
    }
  };

  const handleError = (): void => {
    addToast(getUploadFailedInfo(), 'error');
    resetInput();
  };

  const InputElement: JSX.Element = (
    <input
      ref={uploadRef}
      type="file"
      accept={extensions.map((ext) => '.' + ext).join(', ')}
      multiple={!notMultiple}
      onChange={(e) => onAddMultiple(e)}
      // eslint-disable-next-line react/no-unknown-property
      onError={handleError}
      style={{ display: 'none' }}
      alt="file upload"
    />
  );

  const removeElement = (img: AssetWithKey): JSX.Element | null => {
    if (!img.key) return null;
    return (
      <>
        <RemoveIcon ref={removeRefs[img.key]} onClick={() => setFileToDelete(img)} />
        <Modal
          isOpen={assetToDelete?.key === img.key}
          onClose={() => setFileToDelete(null)}
          title="Delete"
          subtitle="Are you sure you want to delete the file?"
          btnAbort={{ label: 'Cancel', function: () => setFileToDelete(null) }}
          btnConfirm={{
            label: 'Delete',
            function: () => {
              assetToDelete && onDelete(assetToDelete);
              setFileToDelete(null);
            },
          }}
        />
      </>
    );
  };

  return {
    isLoading,
    assetsToDisplay: assets,
    triggerInput,
    InputElement,
    removeElement,
    initiateFileUploadByDrop,
    fileExtensionError,
  };
};

export default useAssetUpload;
