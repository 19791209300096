import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { TitleBlack } from 'theme/atoms';
import ArtworkSummary from 'theme/atoms/artworkSummary';
import UserCard from 'theme/atoms/userCard';
import { TransactionDirection } from 'transactions/domain';
import { TransactionWrapper } from 'transactions/requests.types';
import { useTransactionRoles } from 'utils/hooks';

interface HeaderProps {
  isSummaryView: boolean;
}

const Header = ({ isSummaryView }: HeaderProps): JSX.Element | null => {
  const { transactionFormStore } = useStores();

  const transactionWrapper = transactionFormStore.transactionWrapper as TransactionWrapper;

  const { isBuyerRepresentative, isSellerSide } = useTransactionRoles({ transactionWrapper } as {
    transactionWrapper: TransactionWrapper;
  });

  const artwork = transactionWrapper.artwork;
  const chipLabel = isSellerSide ? TransactionDirection.Sale : TransactionDirection.Purchase;
  const representativeContact = isBuyerRepresentative
    ? transactionWrapper.transaction.sellerInfo?.representativeContact
    : transactionWrapper.transaction.buyerInfo?.representativeContact;
  const from = representativeContact && (
    <UserCard id={representativeContact.contactConnectionId || ''} isSmall>
      <UserCard.Name name={representativeContact.organizationName} />
    </UserCard>
  );

  if (isSellerSide && !isSummaryView) return <TitleBlack text="Fill in offer details" />;
  if (isSellerSide && isSummaryView)
    return <ArtworkSummary title="Offer summary" artwork={artwork} chipLabel={chipLabel} />;

  if (!isSellerSide) {
    return (
      <ArtworkSummary
        title={!isSummaryView ? 'Offer received' : 'Offer summary'}
        chipLabel={chipLabel}
        from={from}
        artwork={artwork}
      />
    );
  }

  return null;
};

export default observer(Header);
