import { useState } from 'react';
import styles from './UncutNameWrapper.module.scss';

interface UncutNameWrapperProps {
  children: JSX.Element;
  uncutName: JSX.Element;
}

const UncutNameWrapper = ({ children, uncutName }: UncutNameWrapperProps): JSX.Element => {
  const [showUncutName, setShowUncutName] = useState(false);

  return (
    <div
      className={styles.root}
      onMouseEnter={() => setShowUncutName(true)}
      onMouseLeave={() => setShowUncutName(false)}
    >
      {children}
      {showUncutName && <div className={styles.uncutName}>{uncutName}</div>}
    </div>
  );
};

export default UncutNameWrapper;
