import { action, computed, makeObservable } from 'mobx';
import { RootStore } from 'RootStore';
import {
  ARTWORK_DRAFT_CATEGORIES_WITH_STATUSES_AND_STATES,
  DEFAULT_ARTWORK_DRAFT_CATEGORY,
} from 'artworks/artworkRegistration/constants';
import { ArtworkState, ArtworkStatusEnum, FilterParams } from 'artworks/types';
import SortAndFilterBasicStore, {
  SortAndFilterBasicStoreProps as BasicStoreProps,
} from 'domain/SortAndFilterBasicStore/SortAndFilterBasicStore';

const ExtendedStoreProps = {
  artworkRegistrationCategoryStatuses: computed,
  artworkRegistrationCategoryStates: computed,
  filterByAsParams: action,
};

class ArtworkRegistrationSortAndFilterStore extends SortAndFilterBasicStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    super(rootStore);
    this.rootStore = rootStore;
    makeObservable(this, { ...BasicStoreProps, ...ExtendedStoreProps });
  }

  get artworkRegistrationCategoryStatuses(): readonly (keyof typeof ArtworkStatusEnum)[] {
    const categoryWithStatuses = ARTWORK_DRAFT_CATEGORIES_WITH_STATUSES_AND_STATES.find(
      (category) => category.id === this.categoryId
    );

    return categoryWithStatuses?.status || [];
  }

  get artworkRegistrationCategoryStates(): readonly ArtworkState[] {
    const categoryWithStates = ARTWORK_DRAFT_CATEGORIES_WITH_STATUSES_AND_STATES.find(
      (category) => category.id === this.categoryId
    );

    return categoryWithStates?.state || [];
  }

  filterByAsParams = (): FilterParams => {
    const filterParams = {
      registration: this.categoryId === DEFAULT_ARTWORK_DRAFT_CATEGORY,
      state: this.artworkRegistrationCategoryStates as ArtworkState[],
      status: this.artworkRegistrationCategoryStatuses as (keyof typeof ArtworkStatusEnum)[],
      creatorOrganizationId: this.artworkRegistrationCategoryStates.includes(ArtworkState.Certified)
        ? this.rootStore.organizationStore.selectedOrganization?.id
        : '',
    };

    Object.keys(filterParams).forEach((key) => {
      if (!filterParams[key]) delete filterParams[key];
    });

    return filterParams;
  };
}

export default ArtworkRegistrationSortAndFilterStore;
