import { ChangeEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { FormValidation } from 'domain/types';
import { Input } from 'theme/atoms';
import SectionHeader from 'theme/atoms/Titles/SectionHeader';
import CurrencyInputWrapper from 'theme/atoms/currencyInputWrapper/CurrencyInputWrapper';
import TextArea from 'theme/atoms/textArea';
import styles from './ArtworkDetailsForm.module.scss';

interface ArtworkDetailsFormProps {
  onChange: (name: string, value: string | number) => void;
  validation: FormValidation;
}

const ArtworkDetailsForm = ({ onChange, validation }: ArtworkDetailsFormProps): JSX.Element => {
  const { addArtworkStore } = useStores();
  const { artworkForm } = addArtworkStore;

  const renderInput = (field: string, label?: string, placeholder?: string): JSX.Element => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
      onChange(e.target.name, e.target.value);
    };

    return (
      <Input
        label={label || field.charAt(0).toUpperCase() + field.slice(1)}
        type="text"
        name={field}
        onChange={handleChange}
        value={artworkForm[field] || ''}
        error={validation[`${field}Required`]}
        placeholder={placeholder}
      />
    );
  };

  return (
    <>
      <SectionHeader title="Artwork details" />
      <div className={styles.root}>
        {renderInput('name', 'Title')}
        <div className={styles.sectionWrapper}>
          {renderInput('year', 'Year', 'YYYY')}
          {renderInput('executedYear', 'Executed year', 'YYYY')}
        </div>
        {renderInput('medium')}
        {renderInput('size')}
        {renderInput('editionNumber', 'Edition number')}
        {renderInput('weight', 'Weight (optional)')}
        {renderInput('carbonDating', 'Carbon dating (optional)')}

        <CurrencyInputWrapper
          label="Artist Resell Rights"
          name="artistResellRightsPercent"
          onValueChange={(values) => onChange('artistResellRightsPercent', values.value || '')}
          value={artworkForm.artistResellRightsPercent?.toString() || ''}
          suffix="%"
          error={validation.artistResellRightsPercentRequired || validation.artistResellRightsIsPercentages}
        />
        <TextArea
          label="Description (optional)"
          name="description"
          onChange={(e) => onChange(e.target.name, e.target.value)}
          value={artworkForm.description || ''}
          error={validation.descriptionRequired}
        />
      </div>
    </>
  );
};

export default observer(ArtworkDetailsForm);
