import { ReactNode } from 'react';
import clsx from 'clsx';
import styles from './TitleBlack.module.scss';

interface TitleBlackProps {
  text?: string;
  children?: ReactNode;
  dontLimitWidth?: boolean;
}

const TitleBlack = ({ text, children, dontLimitWidth }: TitleBlackProps): React.JSX.Element => {
  return <h1 className={clsx(styles.root, { [styles.dontLimitWidth]: dontLimitWidth })}>{text || children}</h1>;
};

export default TitleBlack;
