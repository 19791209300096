import { useState, FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import styles from 'auth/Auth.module.scss';
import { isPasswordInvalid } from 'auth/helpers';
import { FormValidation } from 'domain/types';
import { ReactComponent as HiddenIcon } from 'theme/assets/svg/hidden1.svg';
import { ReactComponent as ShowIcon } from 'theme/assets/svg/shown1.svg';
import { Button, Input, Subtitle, TitleBlack } from 'theme/atoms';
import Breadcrumbs, { Breadcrumb } from 'theme/atoms/Breadcrumbs';
import Topbar from 'theme/atoms/Topbar';
import { useFormValidation, useTitle } from 'utils/hooks';

const FORM_VALIDATIONS: FormValidation = {
  passwordRequired: '',
  passwordInvalid: '',
  repeatPasswordNotTheSame: '',
};

const BREADCRUMBS: Breadcrumb[] = [
  {
    label: 'Reset password',
  },
];

function ResetPasswordSetNewPassword(): JSX.Element {
  useTitle('Reset password');
  const navigate = useNavigate();
  const { authStore } = useStores();
  const { submitting, resetPassword } = authStore;
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const { cleanValidationErrors, formValidationMessages, handleValidation, validation } =
    useFormValidation(FORM_VALIDATIONS);

  const handlePasswordChange = (value: string): void => {
    cleanValidationErrors(['passwordRequired', 'passwordInvalid']);
    setPassword(value);
  };

  const handleRepeatPasswordChange = (value: string): void => {
    cleanValidationErrors(['repeatPasswordNotTheSame']);
    setRepeatPassword(value);
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>): void | undefined => {
    event.preventDefault();
    handleValidation('passwordRequired', formValidationMessages.PASSWORD_REQUIRED, !password);
    handleValidation('passwordInvalid', formValidationMessages.PASSWORD_INVALID, isPasswordInvalid(password));
    handleValidation(
      'repeatPasswordNotTheSame',
      formValidationMessages.PASSWORDS_NOT_THE_SAME,
      repeatPassword !== password
    );
    if (!password || isPasswordInvalid(password) || repeatPassword !== password) return;

    resetPassword(password, true).then((response) => {
      if (response?.success) {
        navigate(routes.ResetPasswordPasswordChanged.path());
      }
    });
  };

  return (
    <div className={clsx(styles.root, styles.oneColumnLayout)}>
      <Topbar>
        <Breadcrumbs breadcrumbs={BREADCRUMBS} />
      </Topbar>
      <div className={styles.innerWrapper}>
        <TitleBlack text="Reset password" />
        <Subtitle text="Please choose a new password" />

        <form onSubmit={onSubmit} className="form">
          <Input
            label="New password"
            name="password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => handlePasswordChange(e.target.value)}
            error={validation.passwordRequired || validation.passwordInvalid}
            type={isPasswordVisible ? 'text' : 'password'}
            endIcon={
              <div className={styles.passwordIcon} onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
                {isPasswordVisible ? <ShowIcon /> : <HiddenIcon />}
              </div>
            }
            className={clsx(styles.passwordInput, { [styles.passwordInputInvalid]: validation.passwordInvalid })}
          />

          <Input
            label="Repeat new password"
            name="password"
            placeholder="Enter your password"
            value={repeatPassword}
            onChange={(e) => handleRepeatPasswordChange(e.target.value)}
            error={validation.repeatPasswordNotTheSame}
            type={isPasswordVisible ? 'text' : 'password'}
            endIcon={
              <div className={styles.passwordIcon} onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
                {isPasswordVisible ? <ShowIcon /> : <HiddenIcon />}
              </div>
            }
          />

          <Button text="Change password" loading={submitting} type="submit" maxWidth />
        </form>
      </div>
    </div>
  );
}

export default observer(ResetPasswordSetNewPassword);
