import { makeAutoObservable, runInAction } from 'mobx';
import { RootStore } from 'RootStore';
import { BANK_ACCOUNT_FIELDS } from 'domain/constants';
import { RawBankAccount } from 'domain/types/BankAccount.types';
import { FieldDetail } from 'domain/types/FieldDetail';
import { StoreActionReturn } from 'domain/types/StoreActionReturn';
import * as requests from './requests';

class BankStore {
  rootStore: RootStore;
  formData: RawBankAccount | undefined = undefined;
  bankAccount: RawBankAccount | undefined = undefined;
  loading = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  get fetchedBankAccountData(): FieldDetail[] {
    return BANK_ACCOUNT_FIELDS.map((el) => ({ ...el, value: this.bankAccount?.[el.name] || '' }));
  }

  fetchBankAccountDetails = (): Promise<void> => {
    runInAction(() => (this.loading = true));

    return requests
      .fetchOrganizationBankAccountDetails()
      .then(({ data }) => {
        const { data: accountData, escrow } = data;
        runInAction(() => {
          this.bankAccount = {
            ...accountData,
            isEscrow: escrow,
          };
        });
      })
      .catch((err) => {
        if (
          err.response?.data.type ===
          'result:organization-bank-account-get:organization-not-found-or-bank-account-not-defined'
        ) {
          this.rootStore.organizationStore.setSelectedOrganizationAsBeingSetup();
        }
      })
      .finally(() => runInAction(() => (this.loading = false)));
  };

  setFormData = (formData?: RawBankAccount): void => {
    const fetchedBankData = this.fetchedBankAccountData.reduce(
      (acc, detail) => ({
        ...acc,
        [detail.name]: detail.value,
      }),
      {}
    );
    this.formData = formData || (fetchedBankData as RawBankAccount);
  };

  updateBankAccount = (): Promise<StoreActionReturn | void> => {
    if (!this.formData) return Promise.resolve();

    const { addToast, toastMessages } = this.rootStore.toastsStore;
    const isCreating = !this.bankAccount;

    runInAction(() => {
      if (!this.formData) return Promise.resolve();
      this.formData = {
        ...this.formData,
        iban: this.formData.iban.trim().toUpperCase(),
        swift: this.formData.swift.trim().toUpperCase(),
      };

      this.loading = true;
    });

    const requestMethod = isCreating ? requests.createOrganizationBankAccount : requests.editOrganizationBankAccount;

    const successMessage = isCreating
      ? toastMessages.ORGANIZATIONS.ACCOUNT_CREATE_SUCCESS
      : toastMessages.ORGANIZATIONS.ACCOUNT_EDIT_SUCCESS;
    const errorMessage = isCreating
      ? toastMessages.ORGANIZATIONS.ACCOUNT_CREATE_ERROR
      : toastMessages.ORGANIZATIONS.ACCOUNT_EDIT_ERROR;

    return requestMethod(this.formData)
      .then(() => {
        addToast(successMessage, 'success');
        runInAction(() => (this.bankAccount = this.formData));
        return {
          success: true,
        };
      })
      .catch(() => {
        addToast(errorMessage, 'error');
      })
      .finally(() => {
        runInAction(() => (this.loading = false));
      });
  };

  resetAllBankData = (): void => {
    runInAction(() => {
      this.bankAccount = undefined;
      this.formData = undefined;
    });
  };
}

export default BankStore;
