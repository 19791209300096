import { makeAutoObservable, runInAction } from 'mobx';
import { RootStore } from 'RootStore';
import { Consignment, ConsignmentItem } from 'consignments/types';
import * as requests from '../requests';

class ConsignmentStore {
  rootStore: RootStore;

  consignmentId = '';
  consignmentItem: ConsignmentItem | null = null;
  loaded = false;
  loading = false;
  isPanelOpen = false;
  shouldPanelBeBlockedFromClosing = false;

  loadingAccept = false;
  loadingReject = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  get consignmentLastModifiedAt(): Date | undefined {
    return this.consignmentItem?.consignments.find((el) => el.id === this.consignmentId)?.modifiedAt;
  }

  get consignment(): Consignment | null {
    return this.consignmentItem?.consignments.find((el) => el.id === this.consignmentId) || null;
  }

  setIsPanelOpen = (isOpen: boolean): void => {
    this.isPanelOpen = isOpen;
  };

  setClosingPanelShouldBeBlocked = (shouldBeBlocked: boolean): void => {
    this.shouldPanelBeBlockedFromClosing = shouldBeBlocked;
  };

  fetchConsignment = async (consignmentId: string, forceFetching?: boolean): Promise<void> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    if (!forceFetching && this.consignmentId === consignmentId) return Promise.resolve();
    this.consignmentId = consignmentId;
    this.loading = true;
    this.loaded = false;

    return requests
      .fetchConsignmentsGroupedByArtwork({ consignmentId })
      .then(({ data }) => {
        runInAction(() => {
          this.consignmentItem = data.results[0];
          this.loaded = true;
        });
      })
      .catch(() => {
        addToast(toastMessages.DEFAULT.ERROR, 'error');
        this.loaded = true;
      })
      .finally(() => {
        runInAction(() => {
          this.loading = false;
        });
      });
  };

  acceptConsignment = (who: 'consignee' | 'owner'): Promise<void> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.loadingAccept = true));
    if (!this.consignmentLastModifiedAt) return Promise.reject();

    const action = `${who}AcceptConsignment`;

    return requests[action](this.consignmentId, this.consignmentLastModifiedAt)
      .then(() => {
        addToast('The consignment was accepted.', 'success');
        this.fetchConsignment(this.consignmentId, true);
        this.consignmentItem && this.rootStore.artworkStore.fetchArtworkData(this.consignmentItem.artwork.id);
      })
      .catch(() => addToast(toastMessages.DEFAULT.ERROR, 'error'))
      .finally(() => runInAction(() => (this.loadingAccept = false)));
  };

  rejectConsignment = (who: 'consignee' | 'owner'): Promise<void> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.loadingReject = true));
    if (!this.consignmentLastModifiedAt) return Promise.reject();

    const action = `${who}RejectConsignment`;

    return requests[action](this.consignmentId, this.consignmentLastModifiedAt)
      .then(() => {
        addToast('The consignment was rejected.', 'success');
        this.fetchConsignment(this.consignmentId, true);
      })
      .catch(() => addToast(toastMessages.DEFAULT.ERROR, 'error'))
      .finally(() =>
        runInAction(() => {
          this.loadingReject = false;
          this.isPanelOpen = false;
        })
      );
  };

  resetAll = (): void => {
    runInAction(() => {
      this.consignmentId = '';
      this.consignmentItem = null;
      this.isPanelOpen = false;
      this.shouldPanelBeBlockedFromClosing = false;
    });
  };
}

export default ConsignmentStore;
