import axios, { AxiosResponse } from 'axios';
import api from 'api';
import { AssetWithKey } from 'domain/types/Asset.types';
import { AssetWithGeneratedUrl } from './types';

export async function getAssetPutURL(name: string): Promise<AxiosResponse<AssetWithGeneratedUrl>> {
  return api.post(`assets/generate-put-url`, { name });
}

export async function uploadAsset(file: File, putURL: string): Promise<AxiosResponse<void>> {
  return axios({
    method: 'put',
    url: putURL,
    data: file,
  });
}

export async function fetchAllAssets(): Promise<AxiosResponse<AssetWithKey[]>> {
  return api.get(`assets`);
}

export async function deleteAsset(objectKey: string): Promise<AxiosResponse<void>> {
  return api.delete(`assets/${objectKey}`);
}
