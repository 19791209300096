import { BuyerInfo, SellerInfo } from 'bankPerspective/bankTransactions/requests.types';
import { ContactBasicInfo, Address } from 'domain/types/Contact.types';

const DEFAULT_UNKNOWN_CONTACT_DETAILS = {
  organizationName: 'N/A',
  organizationTitle: 'N/A',
  organizationAddress: { country: 'N/A', city: 'N/A', addressLine: 'N/A' },
};

interface AddressInfo {
  organizationName: string;
  organizationAddress: Address;
}

export const extractAddressInfo = (data: ContactBasicInfo | BuyerInfo | SellerInfo): AddressInfo => {
  if (!data) return DEFAULT_UNKNOWN_CONTACT_DETAILS;

  if ('representative' in data) {
    return {
      organizationName: data.representative.organizationName,
      organizationAddress: data.representative.organizationAddress,
    };
  } else
    return {
      organizationName: data.organizationName,
      organizationAddress: data.organizationAddress,
    };
};
