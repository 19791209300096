import { makeAutoObservable, runInAction } from 'mobx';
import { RootStore } from 'RootStore';
import * as requests from 'bankPerspective/bankInvoices/requests';
import { RawBankInvoice } from 'bankPerspective/bankInvoices/requests.types';
import { InvoiceState } from 'domain/types/Invoices.types';

class BankInvoiceStore {
  rootStore: RootStore;

  bankNote = '';
  isInvoiceRejectionBankNoteModalOpen = false;
  loadingInvoiceDetails = false;
  selectedInvoice: RawBankInvoice | null = null;
  submittingInvoiceAcceptance = false;
  submittingInvoicePayment = false;
  submittingInvoiceRejection = false;
  submittingInvoiceRejectionWithoutNote = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  setIsInvoiceRejectionBankNoteModalOpen = (isOpen: boolean): void => {
    this.isInvoiceRejectionBankNoteModalOpen = isOpen;
  };

  setBankNote = (note: string): void => {
    this.bankNote = note;
  };

  resetInvoiceRejectionModal = (): void => {
    this.setBankNote('');
    this.setIsInvoiceRejectionBankNoteModalOpen(false);
  };

  fetchBankInvoice = (invoiceId: string): Promise<void | RawBankInvoice> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    const { fetchBankTransaction } = this.rootStore.bankTransactionStore;

    runInAction(() => (this.loadingInvoiceDetails = true));
    return requests
      .fetchBankInvoice(invoiceId)
      .then(({ data }) => {
        runInAction(() => {
          this.selectedInvoice = data.results[0];
        });

        if (this.selectedInvoice) fetchBankTransaction(this.selectedInvoice.externalId);
        return data.results[0] as RawBankInvoice;
      })
      .catch(() => addToast(toastMessages.BANK_INVOICES.DETAILS_FETCH_ERROR, 'error'))
      .finally(() => runInAction(() => (this.loadingInvoiceDetails = false)));
  };

  acceptInvoice = (invoiceId: string): Promise<void> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.submittingInvoiceAcceptance = true));

    if (!this.selectedInvoice) return Promise.resolve();

    return requests
      .acceptInvoice(invoiceId, this.selectedInvoice.modifiedAt)
      .then(() => {
        runInAction(() => {
          if (this.selectedInvoice) {
            this.selectedInvoice = {
              ...this.selectedInvoice,
              state: InvoiceState.Accepted,
            };
          }
        });
        addToast(toastMessages.BANK_INVOICES.SEND_TO_ACCEPTANCE_SUCCESS, 'success');
      })
      .catch(({ response }) => {
        if (response && response.data.title === 'Transaction wrong state')
          addToast(toastMessages.BANK_INVOICES.ACCEPTANCE_ERROR_INVALID_TRANSACTION_STATUS, 'error');
        else addToast(toastMessages.BANK_INVOICES.SEND_TO_ACCEPTANCE_ERROR, 'error');
      })
      .finally(() => runInAction(() => (this.submittingInvoiceAcceptance = false)));
  };

  markAsPaid = (invoiceId: string): Promise<void> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.submittingInvoicePayment = true));

    if (!this.selectedInvoice) return Promise.resolve();

    return requests
      .markAsPaid(invoiceId, this.selectedInvoice.modifiedAt)
      .then(() => {
        runInAction(() => {
          if (this.selectedInvoice) {
            const currentDate = new Date();
            const newLogEntry = {
              invoiceId: this.selectedInvoice.id,
              state: InvoiceState.Paid,
              changedAt: currentDate,
            };

            this.selectedInvoice = {
              ...this.selectedInvoice,
              state: InvoiceState.Paid,
              stateChangeLog: [...this.selectedInvoice.stateChangeLog, newLogEntry],
            };
          }
        });
        addToast(toastMessages.BANK_INVOICES.SEND_TO_PAYMENT_SUCCESS, 'success');
      })
      .catch(({ response }) => {
        if (response && response.data.title === 'Transaction wrong state')
          addToast(toastMessages.BANK_INVOICES.PAYMENT_ERROR_INVALID_TRANSACTION_STATUS, 'error');
        else addToast(toastMessages.BANK_INVOICES.SEND_TO_PAYMENT_ERROR, 'error');
      })
      .finally(() => runInAction(() => (this.submittingInvoicePayment = false)));
  };

  rejectInvoice = (invoiceId: string, bankNote?: boolean): Promise<void> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;

    runInAction(() => {
      if (bankNote) this.submittingInvoiceRejection = true;
      else this.submittingInvoiceRejectionWithoutNote = true;
    });

    if (!this.selectedInvoice) return Promise.resolve();

    const bankNoteToSend = bankNote ? this.bankNote : undefined;

    return requests
      .rejectInvoice(invoiceId, this.selectedInvoice.modifiedAt, bankNoteToSend)
      .then(() => {
        runInAction(() => {
          if (this.selectedInvoice) {
            this.selectedInvoice = {
              ...this.selectedInvoice,
              state: InvoiceState.Rejected,
              bankNote: this.bankNote,
            };
          }
          this.setIsInvoiceRejectionBankNoteModalOpen(false);
        });
        addToast(toastMessages.BANK_INVOICES.SEND_TO_REJECT_SUCCESS, 'success');
      })
      .catch(({ response }) => {
        if (response && response.data.title === 'Transaction wrong state')
          addToast(toastMessages.BANK_INVOICES.REJECT_ERROR_INVALID_TRANSACTION_STATUS, 'error');
        else addToast(toastMessages.BANK_INVOICES.SEND_TO_REJECT_ERROR, 'error');
      })
      .finally(() =>
        runInAction(() => {
          if (bankNote) this.submittingInvoiceRejection = false;
          else this.submittingInvoiceRejectionWithoutNote = false;
        })
      );
  };

  resetSelectedInvoice = (): void => {
    runInAction(() => (this.selectedInvoice = null));
  };

  resetAll = (): void => {
    runInAction(() => {
      this.bankNote = '';
      this.isInvoiceRejectionBankNoteModalOpen = false;
      this.loadingInvoiceDetails = false;
      this.selectedInvoice = null;
      this.submittingInvoiceAcceptance = false;
      this.submittingInvoicePayment = false;
      this.submittingInvoiceRejection = false;
      this.submittingInvoiceRejectionWithoutNote = false;
    });
  };
}

export default BankInvoiceStore;
