import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import styles from 'auth/Auth.module.scss';
import { Button, Subtitle } from 'theme/atoms';

function SendNewEmail(): JSX.Element {
  const navigate = useNavigate();

  const { authStore, createUserStore } = useStores();
  const { emailFromLogin } = authStore;
  const { sendNewLinkToVerifyEmail, userRegistrationData } = createUserStore;

  useEffect(() => {
    if (!userRegistrationData?.email && !emailFromLogin) {
      navigate(routes.Login.path());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSendNewLink = (): void => {
    sendNewLinkToVerifyEmail().then(
      (isSuccessfull) => isSuccessfull && navigate(routes.SignupVerifyEmailIssues.path())
    );
  };

  return (
    <div className={styles.sendNewEmail}>
      <Subtitle dontLimitWidth>
        <div>
          If you haven't received the email,{' '}
          <Button text="click here" buttonType="textButton" onClick={onSendNewLink} /> to send a new one.
        </div>
      </Subtitle>
    </div>
  );
}

export default SendNewEmail;
