import clsx from 'clsx';
import { ReactComponent as MoneyIcon } from 'theme/assets/svg/dollars.svg';
import { ReactComponent as WarningIcon } from 'theme/assets/svg/warn.svg';
import Spinner from 'theme/atoms/Spinner/Spinner';
import { ButtonProps, Classes } from '../Button';
import styles from '../Button.module.scss';

interface ButtonElementProps extends ButtonProps {
  classes: Classes;
  regularButton: boolean;
}

const ButtonElement = ({
  classes,
  type,
  onClick,
  iconStart,
  text,
  iconEnd,
  regularButton,
  buttonType,
  disabled,
  loading,
  light = false,
  specialMarking,
}: ButtonElementProps): JSX.Element => {
  const specialMarkingButton = specialMarking && (
    <div className={styles.additionalMessageWrapperOuter}>
      <div
        className={clsx(
          styles.additionalMessageWrapperInner,
          { [styles.whiteIcon]: specialMarking.kind === 'paid' },
          { [styles.pinkIcon]: specialMarking.kind === 'blocked' }
        )}
      >
        {specialMarking.kind === 'paid' ? <MoneyIcon /> : <WarningIcon />}
        <div className={styles.tooltipWrapper}>
          <div
            className={clsx(
              styles.tooltip,
              { [styles.pinkTooltip]: specialMarking.kind === 'paid' },
              { [styles.blackTooltip]: specialMarking.kind === 'blocked' }
            )}
            style={{ maxWidth: specialMarking.maxWidth }}
          >
            {specialMarking.tooltip}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <button
      {...classes}
      type={type}
      onClick={(event) => !(disabled || specialMarking?.kind === 'blocked' || loading) && onClick?.(event)}
      disabled={disabled || loading}
    >
      {specialMarkingButton}
      {iconStart}
      <span className={clsx(styles.text, { [styles.light]: light })}>{text}</span>
      {specialMarking?.suffix && <> ({specialMarking.suffix})</>}
      {iconEnd}
      {loading && regularButton && <Spinner small white={buttonType === 'primary'} className={styles.loader} />}
    </button>
  );
};

export default ButtonElement;
