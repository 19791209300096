import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import VerifyPhoneForm from 'auth/components/forms/VerifyPhoneForm';
import { UserIdentityType } from 'auth/types';
import { StoreActionReturn } from 'domain/types/StoreActionReturn';
import { Button, TitleBlack } from 'theme/atoms';
import styles from '../../../SettingsSecurity.module.scss';

const VerifyPhone = (): React.JSX.Element => {
  const navigate = useNavigate();

  const { authStore } = useStores();
  const {
    submitting,
    confirmUserIdentity,
    getUserIdentities,
    getMFAUserIdentityTypesToVerify,
    userIdentities,
    deleteUserIdentity,
    sendUserIdentityConfirmationCode,
    saveTokensToStore,
    saveTokensToLocalStorage,
  } = authStore;

  const userIdentity = userIdentities?.find((identity) => identity.type === UserIdentityType.Phone);

  useEffect(() => {
    getUserIdentities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (confirmationCode: string): Promise<void> => {
    const userIdentityToRemove = userIdentities?.find(
      (identity) => identity.type === getMFAUserIdentityTypesToVerify()[0]
    );

    if (userIdentityToRemove) {
      await deleteUserIdentity(userIdentityToRemove.id);
    }

    confirmUserIdentity(UserIdentityType.Phone, confirmationCode).then((response) => {
      if (response?.success) {
        saveTokensToStore(response.data);
        saveTokensToLocalStorage(response.data);
        navigate(routes.SettingsSecurity.path(), { state: { isAuthenticationMethodsOpen: true } });
      }
    });
  };

  const handleResendCode = (): Promise<StoreActionReturn | void> => {
    if (!userIdentity) return Promise.reject();
    return sendUserIdentityConfirmationCode(userIdentity.id);
  };

  return (
    <>
      <TitleBlack text="SMS authentication code" />
      <p className={styles.paragraph}>
        Enter your one-time security code we sent to your
        <br />
        phone number: <span className={styles.inlinePhoneNumber}>{userIdentity?.value}</span>
      </p>

      <div className={styles.formContainer}>
        <VerifyPhoneForm
          submitButton={
            <Button
              type="submit"
              text="Continue"
              className={styles.submitButton}
              loading={submitting}
              disabled={submitting}
            />
          }
          onSubmit={handleSubmit}
          onResendCode={handleResendCode}
        />
      </div>

      <Link
        onClick={() => navigate(-1)}
        to={''}
        state={{ isAuthenticationMethodsOpen: true }}
        className={styles.backLink}
      >
        Back
      </Link>
    </>
  );
};

export default observer(VerifyPhone);
