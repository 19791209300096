import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import styles from 'auth/Auth.module.scss';
import AddPhoneForm from 'auth/components/forms/AddPhoneForm';
import { UserIdentityType } from 'auth/types';
import { getMessageFromApiErrorResponse } from 'domain/getMessageFromApiErrorResponse';
import { Button, Subtitle, TitleBlack } from 'theme/atoms';
import Breadcrumbs, { Breadcrumb } from 'theme/atoms/Breadcrumbs';
import Topbar from 'theme/atoms/Topbar';
import { useIsAccessTokenFresh } from 'utils/hooks';

const BREADCRUMBS: Breadcrumb[] = [{ label: 'Authentication method' }, { label: 'Step 2: SMS Code' }];

const LoginAddPhone = (): React.JSX.Element => {
  const navigate = useNavigate();
  const isTokenFresh = useIsAccessTokenFresh();

  const {
    toastsStore: { addToast },
    authStore: { submitting, addUserIdentity },
  } = useStores();

  useEffect(() => {
    if (!isTokenFresh) navigate(routes.Login.path());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (phoneNumber: string): void => {
    addUserIdentity({ type: UserIdentityType.Phone, value: phoneNumber })
      .then((response) => {
        if (response?.success) {
          navigate(routes.LoginVerifyPhone.path());
        }
      })
      .catch((err) => {
        addToast(getMessageFromApiErrorResponse(err.response?.data.type), 'error');
      });
  };

  const submitButton = <Button type="submit" text="Continue" loading={submitting} disabled={submitting} maxWidth />;

  return (
    <div className={clsx(styles.root, styles.oneColumnLayout)}>
      <Topbar>
        <Breadcrumbs breadcrumbs={BREADCRUMBS} />
      </Topbar>

      <div className={styles.innerWrapper}>
        <TitleBlack>What is your phone number?</TitleBlack>
        <Subtitle>We will send you a SMS message to verify your phone number</Subtitle>

        <AddPhoneForm onSubmit={handleSubmit} submitButton={submitButton} />
      </div>
    </div>
  );
};

export default LoginAddPhone;
