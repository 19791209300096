import { ReactComponent as ConnectIcon } from 'theme/assets/svg/connect.svg';
import { ReactComponent as ConnectedIcon } from 'theme/assets/svg/connected.svg';
import { ReactComponent as InvitationSendIcon } from 'theme/assets/svg/invitationSend.svg';
import { Button } from 'theme/atoms';
import styles from './ContactActionSection.module.scss';

interface InvitationIconProps {
  status: string;
}

const InvitationIcon = ({ status }: InvitationIconProps): JSX.Element => {
  switch (status) {
    case 'Connected':
      return <ConnectedIcon />;
    case 'Requested':
      return <InvitationSendIcon />;
    default:
      return <ConnectIcon />;
  }
};

interface ContactActionSectionProps {
  status: string | null;
  onClick?: () => void;
  loading?: boolean;
}

const ContactActionSection = ({ onClick, loading, status }: ContactActionSectionProps): JSX.Element => {
  return !status ? (
    <Button onClick={onClick} iconStart={<ConnectIcon />} text="Connect" loading={loading} />
  ) : (
    <div className={styles.root}>
      <InvitationIcon status={status} />
      <span className={styles.text}>{status}</span>
    </div>
  );
};

export default ContactActionSection;
