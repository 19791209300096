import { Artwork, ArtworkStatusEnum } from 'artworks/types';
import { Consignment, ConsignmentStatus } from './types';

export enum ConsignmentRole {
  Consignor = 'Consignor',
  OwnerNotConsignor = 'OwnerNotConsignor',
  Consignee = 'Consignee',
}

export enum ConsignmentActions {
  Approve = 'Approve',
  Reject = 'Reject',
  MakeChanges = 'Make Changes',
}

export const isOwnerApprovalNeeded = (consignment: Consignment): boolean => {
  return consignment.status === ConsignmentStatus.PendingOwnersAgreement && !consignment.artworkOwnerAnswer;
};

export const isConsigneeApprovalNeeded = (consignment: Consignment): boolean => {
  return consignment.status === ConsignmentStatus.PendingConsigneeAgreement && consignment.isConsignee;
};

export const consignmentToRole = (consignment: Consignment, artwork: Artwork): ConsignmentRole[] => {
  const isConsignee = consignment.isConsignee;
  const isConsignor = consignment.consignorContact?.self;
  const ownerNotConsignor = !isConsignor && artwork.isOwner;

  return [
    ...(isConsignee ? [ConsignmentRole.Consignee] : []),
    ...(isConsignor ? [ConsignmentRole.Consignor] : []),
    ...(ownerNotConsignor ? [ConsignmentRole.OwnerNotConsignor] : []),
  ];
};

export const canUserPerformThisAction = (
  consignment: Consignment,
  action: ConsignmentActions,
  artwork: Artwork
): boolean => {
  const consignmentIsAlreadyRejected = [
    ConsignmentStatus.RejectedByOwner,
    ConsignmentStatus.RejectedByConsignee,
  ].includes(consignment.status);
  const artworkHasActiveTransaction = [
    ArtworkStatusEnum.OwnedTransactionAccepted,
    ArtworkStatusEnum.OwnedReserved,
    ArtworkStatusEnum.OnConsignmentReserved,
  ].includes(ArtworkStatusEnum[artwork.exploringOrganizationArtworkStatus]);

  const consignmentRole = consignmentToRole(consignment, artwork);

  const canOwnerRejectNow =
    consignmentRole.includes(ConsignmentRole.OwnerNotConsignor) &&
    !artworkHasActiveTransaction &&
    !consignmentIsAlreadyRejected;
  const canConsigneeRejectNow =
    consignmentRole.includes(ConsignmentRole.Consignee) &&
    !artworkHasActiveTransaction &&
    !consignmentIsAlreadyRejected;

  const approveAction =
    isOwnerApprovalNeeded(consignment) || isConsigneeApprovalNeeded(consignment) ? [ConsignmentActions.Approve] : [];

  const rejectAction =
    isOwnerApprovalNeeded(consignment) || canOwnerRejectNow || canConsigneeRejectNow ? [ConsignmentActions.Reject] : [];

  const makeChangesAction =
    consignmentRole.includes(ConsignmentRole.Consignor) && !consignmentIsAlreadyRejected && !artworkHasActiveTransaction
      ? [ConsignmentActions.MakeChanges]
      : [];

  const actions = [...approveAction, ...rejectAction, ...makeChangesAction];
  return actions.includes(action);
};
