import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { FieldDetail } from 'domain/types/FieldDetail';
import ContactAvatar from 'network/networkContact/components/contactAvatar';
import { Button, TitleBlack } from 'theme/atoms';
import Breadcrumbs, { Breadcrumb } from 'theme/atoms/Breadcrumbs';
import Topbar from 'theme/atoms/Topbar';
import { useDebounceIsPanelOpen } from 'theme/atoms/slidingPanel/useDebounceIsPanelOpen';
import styles from './SettingsMyAccount.module.scss';
import MyAccountEditPanel from './components/myAccountEditPanel';
import UserDetails from './components/userDetails';

const BREADCRUMBS: Breadcrumb[] = [{ label: 'Settings' }, { label: 'My account' }];

const SettingsMyAccount = (): React.JSX.Element => {
  const { userStore } = useStores();
  const { userDetails, updateUserProfile } = userStore;

  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const debouncedIsPanelOpen = useDebounceIsPanelOpen(isPanelOpen);

  const userDetailsMap: FieldDetail[] = [
    { name: 'firstname', label: 'First name', value: userDetails?.firstname || '' },
    { name: 'lastname', label: 'Last name', value: userDetails?.lastname || '' },
    { name: 'country', label: 'Country', value: userDetails?.country || '' },
    { name: 'city', label: 'City', value: userDetails?.city || '' },
  ];

  return (
    <>
      <div className={styles.root}>
        <Topbar>
          <Breadcrumbs breadcrumbs={BREADCRUMBS} />
        </Topbar>
        <TitleBlack text="My account" dontLimitWidth />
        <div className={styles.wrapper}>
          <div className={styles.userDetails}>
            <UserDetails userDetailsMap={userDetailsMap} />
            <Button
              text="Edit"
              onClick={() => debouncedIsPanelOpen && setIsPanelOpen(!isPanelOpen)}
              className={styles.button}
            />
          </div>
          <ContactAvatar id={userDetails?.id || ''} size="big" />
        </div>
      </div>
      <MyAccountEditPanel
        userDetailsMap={userDetailsMap}
        onClose={() => setIsPanelOpen(false)}
        isOpen={isPanelOpen}
        onSave={updateUserProfile}
      />
    </>
  );
};

export default observer(SettingsMyAccount);
