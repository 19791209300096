import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { RawBankTransaction } from 'bankPerspective/bankTransactions/requests.types';
import NoDataToDisplay from 'theme/atoms/noDataToDisplay';
import { Table } from 'theme/atoms/table/Table';
import styles from './BankTransactionsTable.module.scss';
import BankTransactionTableRow from './bankTransactionTableRow/';

export const BankTransactionsTable = (): JSX.Element => {
  const { bankTransactionsStore } = useStores();
  const { items } = bankTransactionsStore;
  const bankTransactions = items as RawBankTransaction[];

  const bankTransactionTableRowsDisplayed = bankTransactions.map((transaction) => (
    <BankTransactionTableRow key={transaction.id} transaction={transaction} />
  ));

  const transactionsTableDisplay =
    bankTransactions.length > 0 ? (
      <>{bankTransactionTableRowsDisplayed}</>
    ) : (
      <tr className={styles.rowTableNoData}>
        <td colSpan={7}>
          <NoDataToDisplay copy="No transactions to display." height={100} />
        </td>
      </tr>
    );

  return <Table className={styles.root}>{transactionsTableDisplay}</Table>;
};

export default observer(BankTransactionsTable);
