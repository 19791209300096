import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import { ARTWORK_STEPS } from 'artworks/addArtwork/constants';
import { Button } from 'theme/atoms';
import Modal from 'theme/atoms/modal';

interface CancelAddArtworkProps {
  artworkId: string;
  disabled?: boolean;
  currentStep?: (typeof ARTWORK_STEPS)[number]['id'];
}

const CancelAddArtwork = ({ artworkId, disabled, currentStep }: CancelAddArtworkProps): JSX.Element => {
  const { addArtworkStore } = useStores();
  const { updateArtworkForm, saveDraft } = addArtworkStore;
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const cancelAndNavigate = (): void => {
    // TODO: when page for edit be ready replace cancel with delete artwork
    setIsOpen(false);
    navigate(routes.Artworks.path());
  };

  const onSaveConfirm = (): void => {
    currentStep && updateArtworkForm('draftCreationStep', currentStep);
    setIsOpen(false);
    saveDraft(artworkId, true).then(() => setTimeout(() => navigate(routes.Artworks.path()), 2000));
  };

  return (
    <>
      <Button disabled={disabled} text="Cancel" buttonType="secondary" maxWidth onClick={() => setIsOpen(true)} />
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        subtitle={`Do you want to save your changes before leaving the page?`}
        btnAbort={{ label: 'No', function: cancelAndNavigate }}
        btnConfirm={{ label: 'Yes', function: onSaveConfirm }}
      />
    </>
  );
};

export default CancelAddArtwork;
