import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import styles from 'auth/Auth.module.scss';
import RecoveryCodes from 'auth/components/recoveryCodesPanel';
import { TitleBlack, Subtitle, Button } from 'theme/atoms';
import Breadcrumbs, { Breadcrumb } from 'theme/atoms/Breadcrumbs';
import Topbar from 'theme/atoms/Topbar';
import { useUserContext } from 'utils/hooks';

const BREADCRUMBS: Breadcrumb[] = [{ label: 'Authentication method' }, { label: 'Step 3: Recovery codes' }];

const LoginRecoveryCodes = (): React.JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const { homePath } = useUserContext();
  const {
    authStore: { fetchRecoveryCodes, recoveryCodes },
  } = useStores();

  useEffect(() => {
    if (!location.state?.canGenerateRecoveryCodes) {
      navigate(routes.Login.path());
      return;
    }

    fetchRecoveryCodes().catch(() => navigate(routes.Login.path()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (): void => {
    navigate(homePath);
  };

  return (
    <div className={clsx(styles.root, styles.oneColumnLayout)}>
      <Topbar>
        <Breadcrumbs breadcrumbs={BREADCRUMBS} />
      </Topbar>
      <div className={styles.innerWrapper}>
        <TitleBlack dontLimitWidth text="Authentication method successfully activated!" />
        <Subtitle>
          Please save the following recovery codes which you can use to sign in if you lose or can’t access your mobile
          device:
        </Subtitle>

        <RecoveryCodes codes={recoveryCodes} />

        <Button
          type="button"
          text="Continue"
          className={styles.recoveryCodesSubmitButton}
          maxWidth
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default observer(LoginRecoveryCodes);
