import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import Breadcrumbs, { Breadcrumb } from 'theme/atoms/Breadcrumbs';
import Topbar from 'theme/atoms/Topbar';
import Loader from 'theme/atoms/loader';
import NoDataToDisplay from 'theme/atoms/noDataToDisplay';
import SearchTable from 'theme/cells/searchTable/SearchTable';
import { SearchTableView } from 'theme/cells/searchTable/constants';
import { DEFAULT_TRANSACTION_CATEGORY } from 'transactions/transactions/types';
import styles from './Invoices.module.scss';
import InvoiceTable from './components/invoiceTable/InvoiceTable';
import { INVOICE_CATEGORIES_WITH_INVOICE_SIDES } from './constants';
import { InvoiceCategoryId } from './types';

const BREADCRUMBS: Breadcrumb[] = [
  { label: 'Transactions', route: 'Transactions', param: DEFAULT_TRANSACTION_CATEGORY },
  { label: 'Invoices' },
];

const Invoices = (): JSX.Element => {
  const navigate = useNavigate();
  const { categoryId } = useParams<{ categoryId: InvoiceCategoryId }>();
  const { invoicesStore, invoicesSortAndFilterStore } = useStores();
  const { loading, items } = invoicesStore;
  const [searchParams] = useSearchParams();

  const invoicesTable = loading ? (
    <Loader minHeight={150} />
  ) : items?.length > 0 ? (
    <InvoiceTable isPayer={categoryId !== INVOICE_CATEGORIES_WITH_INVOICE_SIDES[0].id} />
  ) : (
    <NoDataToDisplay copy="No invoices to display." height={100} />
  );

  const selectCategory = (categoryId: string): void => {
    navigate({ pathname: routes.Invoices.path(categoryId), search: searchParams.toString() });
  };

  return (
    <div className={styles.root}>
      <Topbar>
        <Breadcrumbs breadcrumbs={BREADCRUMBS} />
      </Topbar>
      <SearchTable
        view={SearchTableView.Invoices}
        store={invoicesStore}
        dontShowSortAndFilterPanel
        filterStore={invoicesSortAndFilterStore}
        dontShowSearchbox
      >
        <ul className={styles.categories}>
          {INVOICE_CATEGORIES_WITH_INVOICE_SIDES.map(({ id, label }) => (
            <li key={id} onClick={() => selectCategory(id)} className={clsx({ [styles.active]: id === categoryId })}>
              {label}
            </li>
          ))}
        </ul>
      </SearchTable>
      {invoicesTable}
    </div>
  );
};

export default observer(Invoices);
