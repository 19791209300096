import { makeAutoObservable, runInAction } from 'mobx';
import { RootStore } from 'RootStore';
import { AssetWithKey } from 'domain/types/Asset.types';
import { getUploadFileExtensionsInfo, isExtensionOk } from './extensions';
import * as assetUploadRequests from './requests';
import { AssetWithGeneratedUrl } from './types';
import { getMB } from './utils';

export const getUploadFailedInfo = (name?: string): string => {
  return `${name ? name : ' File(s)'} upload failed. Please try again.`;
};

const MAX_FILE_SIZE = 5242880;

class AssetUploadStore {
  rootStore: RootStore;
  isLoading = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  getAssetPutURL = (name: string): Promise<AssetWithGeneratedUrl> => {
    return assetUploadRequests.getAssetPutURL(name).then(({ data }) => {
      return data;
    });
  };

  uploadAsset = (name: string, file: File): Promise<AssetWithKey> => {
    return this.getAssetPutURL(name).then((addingAsset) => {
      if (!addingAsset) {
        runInAction(() => (this.isLoading = false));
        return Promise.reject();
      }

      return assetUploadRequests
        .uploadAsset(file, addingAsset.presignedUrl) // presignedUrl from getAssetPutURL is to call uploadAsset
        .then(() => {
          return {
            size: file.size,
            key: addingAsset.key,
            lastModified: new Date(file.lastModified),
            name: name,
            url: addingAsset.url, // url (from getAssetPutUR & from fetchAssets) is to add to artwork
            presignedUrl: addingAsset.getPresignedUrl, // getPresignedUrl from getAssetPutURL === presignedUrl from fetchAssets & is to display file in the app
          } as AssetWithKey;
        })
        .catch(() => {
          return Promise.reject();
        });
    });
  };

  handleAddIndividual = (file: File, extensions: string[]): Promise<AssetWithKey> => {
    const { addToast } = this.rootStore.toastsStore;

    if (!isExtensionOk(file, extensions)) {
      addToast(getUploadFileExtensionsInfo(extensions), 'error');
      runInAction(() => (this.isLoading = false));
      return Promise.reject();
    }

    if (file.size > MAX_FILE_SIZE) {
      addToast(
        `File ${file.name}'s is ${getMB(file.size).toFixed(2)} MB. It can be a maximum of ${getMB(MAX_FILE_SIZE)} MB.`,
        'error'
      );
      runInAction(() => (this.isLoading = false));
      return Promise.reject();
    }

    const name = file.name;
    // const name = `${file.name}-${dayjs().valueOf()}-${file.lastModified}`;

    return this.uploadAsset(name, file)
      .then((addedAsset) => {
        return addedAsset;
      })
      .catch(() => {
        return Promise.reject(true);
      });
  };

  handleAddMultiple = (files: FileList, extensions: string[]): Promise<AssetWithKey[]> => {
    const { addToast } = this.rootStore.toastsStore;
    runInAction(() => (this.isLoading = true));

    return Promise.all(Array.from(files).map((file: File) => this.handleAddIndividual(file, extensions)))
      .then((addedAssets) => {
        const hasAnythingUploaded = addedAssets.find((el) => el);
        if (!hasAnythingUploaded) return Promise.reject();

        return addedAssets;
      })
      .catch((showToast) => {
        showToast && addToast(getUploadFailedInfo(), 'error');
        return Promise.reject();
      })
      .finally(() => {
        runInAction(() => (this.isLoading = false));
      });
  };

  handleDelete = async (asset: AssetWithKey): Promise<void | boolean> => {
    const { addToast } = this.rootStore.toastsStore;
    runInAction(() => (this.isLoading = true));

    return assetUploadRequests
      .deleteAsset(asset.key)
      .then(() => {
        return true;
      })
      .catch(() => {
        addToast("Couldn't delete the file. Please try again.", 'error');
      })
      .finally(() => {
        runInAction(() => (this.isLoading = false));
      });
  };
}

export default AssetUploadStore;
