import { action, computed, observable, runInAction } from 'mobx';
import { RootStore } from 'RootStore';
import { SORT_OPTIONS as ARTWORKS_SORT_OPTIONS } from 'artworks/artworksComponents/sortAndFilter/constants';
import {
  CategoryItem,
  SORT_OPTIONS as CONSIGNMENTS_SORT_OPTIONS,
} from 'consignments/consignments/sortAndFilter/constants';
import { SORT_BY_STRING } from 'theme/cells/searchTable/constants';
import { SORT_OPTIONS as TRANSACTIONS_SORT_OPTIONS } from 'transactions/transactions/sortAndFilter/constants';
import { FilterItemType, SortItemType, DirType, CategoryId } from './types';

export const SortAndFilterBasicStoreProps = {
  loading: observable,
  defaultSortingItems: observable,
  selectedSortingItems: observable,
  confirmedSortingItems: observable,
  selectedFilteringItems: observable,
  confirmedFilteringItems: observable,
  categoryId: observable,
  validSelectedSortingItems: computed,
  validConfirmedSortingItems: computed,
  confirmedFilteringAndSortingItemsCount: computed,
  sortByAsParams: computed,
  overwriteConfirmedItemsWithSelectedItems: action,
  overwriteSelectedItemsWithConfirmedItems: action,
  resetConfirmedFilteringAndSortingItemsBeforeSettingThemFromUrl: action,
  resetSelectedFilteringAndSortingItems: action,
  addSelectedFilteringItem: action,
  addEmptySelectedSortingItem: action,
  removeSelectedSortingItem: action,
  updateSelectedFilteringItems: action,
  updateSelectedRangeItem: action,
  updateSelectedSortingItems: action,
  updateConfirmedFilteringItems: action,
};

class SortAndFilterBasicStore {
  rootStore: RootStore;

  loading = false;

  defaultSortingItems: SortItemType[] = [];
  selectedSortingItems: SortItemType[] = [];
  confirmedSortingItems: SortItemType[] = [];
  selectedFilteringItems: FilterItemType[] = [];
  confirmedFilteringItems: FilterItemType[] = [];
  categoryId: CategoryId | null = null;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  private get validConfirmedSortingItems(): SortItemType[] {
    return this.confirmedSortingItems.filter((sortItem) => sortItem.checked && sortItem.value);
  }

  get validSelectedSortingItems(): SortItemType[] {
    return this.selectedSortingItems.filter((sortItem) => sortItem.checked && sortItem.value);
  }

  get confirmedFilteringAndSortingItemsCount(): number {
    // unused
    return this.confirmedFilteringItems.length + this.validConfirmedSortingItems.length;
  }

  get confirmedFilteringAndSortingItemsWithoutDefaultsCount(): number {
    const filteringItemsWithoutDefaults = this.confirmedFilteringItems.filter((fi) => {
      const priceItem = fi as { key: string; value: number };
      const defaultPriceMin = fi.key === 'priceMin' && priceItem.value === 0;
      const dateKeys = ['createdAfter', 'createdBefore', 'expireAfter', 'expireBefore'];
      const defaultDate = dateKeys.includes(fi.key) && !fi.value;
      const isItPaginationDetail = fi.key === 'activePage' || fi.key === 'rowsPerPage';
      const isPhrase = fi.key === 'phrase';
      return !(defaultPriceMin || defaultDate || isItPaginationDetail || isPhrase);
    });
    const sortingCategories = this.validConfirmedSortingItems
      .map((item) => item.category)
      .filter((el, i, arr) => arr.indexOf(el) === i);
    const anyItemOfEverySortingCategory = sortingCategories
      .map((cat) => this.validConfirmedSortingItems.find((item) => item.category === cat))
      .filter((el) => el);
    const sortingItemsWithoutDefaultsLength =
      this.validConfirmedSortingItems.length - anyItemOfEverySortingCategory.length;
    return filteringItemsWithoutDefaults.length + sortingItemsWithoutDefaultsLength;
  }

  get sortByAsParams(): string[] {
    return this.validConfirmedSortingItems.map(({ value, dir }: SortItemType) => `${value}${dir}`);
  }

  overwriteConfirmedItemsWithSelectedItems = (): void => {
    // unused (because we put selectedItems into url, and then take them from url)
    this.confirmedSortingItems = this.selectedSortingItems;
    this.confirmedFilteringItems = this.selectedFilteringItems;
  };

  overwriteSelectedItemsWithConfirmedItems = (): void => {
    this.confirmedSortingItems = this.validConfirmedSortingItems;
    this.selectedSortingItems = this.confirmedSortingItems;
    this.selectedFilteringItems = this.confirmedFilteringItems;
  };

  updateConfirmedFilteringItems = (item: FilterItemType): void => {
    const booleanValuesAsString = ['true', 'false'];
    const parsedItem = (): FilterItemType => {
      if (typeof item.value === 'string' && booleanValuesAsString.includes(item.value)) {
        return { ...item, value: item.value === 'true' };
      } else {
        return item;
      }
    };
    runInAction(() => (this.confirmedFilteringItems = [...this.confirmedFilteringItems, parsedItem()]));
  };

  updateConfirmedSortingItems = (valueWithSortByText: string, dir: DirType): void => {
    const value = valueWithSortByText.split(SORT_BY_STRING)[1];
    const sortOption = [...CONSIGNMENTS_SORT_OPTIONS, ...ARTWORKS_SORT_OPTIONS, ...TRANSACTIONS_SORT_OPTIONS].find(
      (el) => el.value === value
    );
    if (!sortOption) return;

    const securedDir = dir === 'Asc' || dir === 'Desc' ? dir : 'Asc';
    const itemBeingUpdated = { ...sortOption, checked: true, dir: securedDir };
    if (
      this.confirmedSortingItems.find(
        (el) => el.category && itemBeingUpdated.category && el.category === itemBeingUpdated.category
      )
    ) {
      runInAction(() => {
        this.confirmedSortingItems = this.confirmedSortingItems.map((el) =>
          el.category === itemBeingUpdated.category ? itemBeingUpdated : el
        );
      });
    } else {
      runInAction(() => {
        this.confirmedSortingItems = [...this.confirmedSortingItems, itemBeingUpdated];
      });
    }
  };

  takeDefaultItemIfMissingUserChoice = (): void => {
    const categoriesOfDefaults = this.defaultSortingItems.map((el) => el.category).filter((el) => el);
    if (!categoriesOfDefaults.length && !this.confirmedSortingItems.length) {
      runInAction(() => (this.confirmedSortingItems = this.defaultSortingItems));
    } else if (categoriesOfDefaults.length) {
      categoriesOfDefaults.forEach((cat) => {
        if (!this.confirmedSortingItems.find((el) => el.category === cat)) {
          const replacementForMissingItem = this.defaultSortingItems.find((el) => el.category === cat);
          if (replacementForMissingItem) {
            runInAction(
              () => (this.confirmedSortingItems = [...this.confirmedSortingItems, replacementForMissingItem])
            );
          }
        }
      });
    }
  };

  resetConfirmedFilteringAndSortingItemsBeforeSettingThemFromUrl = (): void => {
    this.confirmedSortingItems = [];
    this.confirmedFilteringItems = [];
  };

  resetSelectedFilteringAndSortingItems = (): void => {
    this.selectedSortingItems = this.defaultSortingItems;
    this.selectedFilteringItems = [];
  };

  addSelectedFilteringItem = (item: FilterItemType): void => {
    this.updateSelectedFilteringItems([...this.selectedFilteringItems, item]);
  };

  addEmptySelectedSortingItem = (category?: CategoryItem): void => {
    this.selectedSortingItems = [
      ...this.selectedSortingItems,
      { value: '', label: '', dir: 'Asc', checked: false, category },
    ];
  };

  removeSelectedSortingItem = (removedItem: SortItemType): void => {
    this.selectedSortingItems = this.selectedSortingItems.filter((item) => item.value !== removedItem.value);
  };

  updateSelectedFilteringItems = (items: FilterItemType[]): void => {
    this.selectedFilteringItems = [...items];
  };

  updateSelectedRangeItem = (range: FilterItemType): void => {
    const doesRangeAlreadyExist = this.selectedFilteringItems.find((item) => item.key === range.key);
    if (doesRangeAlreadyExist) {
      this.updateSelectedFilteringItems(
        this.selectedFilteringItems.map((item) => (item.key === range.key ? range : item))
      );
    } else {
      this.addSelectedFilteringItem(range);
    }
  };

  updateSelectedSortingItems = (index: number, updatedItem: SortItemType): void => {
    if (!updatedItem.category) {
      this.selectedSortingItems = this.selectedSortingItems.map((item, i) => (i === index ? updatedItem : item));
    } else {
      this.selectedSortingItems = [
        ...this.selectedSortingItems.filter((el) => el.category !== updatedItem.category),
        updatedItem,
      ];
    }
  };

  setCategoryId = (category: CategoryId): void => {
    runInAction(() => (this.categoryId = category));
  };
}
export default SortAndFilterBasicStore;
