import { Outlet } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { Button } from 'theme/atoms';
import { useUserContext } from 'utils/hooks';
import styles from './AppWrapper.module.scss';
import { KycOrPaymentIssueBanner } from './KycOrPaymentIssueBanner';
import { Layout } from './Layout';

interface AppWrapperProps {
  isBackgroundColorful?: boolean;
}

export const AppWrapper = observer(({ isBackgroundColorful = false }: AppWrapperProps) => {
  const {
    authStore: { logout },
    userStore: { noPaymentMethods },
  } = useStores();

  const { organizationSituation, organizations, selectedOrganization } = useUserContext();

  const getError = (): React.JSX.Element | null => {
    return !organizations ? (
      <div className={styles.error}>
        <div className={styles.copy}>Couldn't communicate with the server. Please try again later.</div>
        <Button buttonType="secondary" text="Logout" type="submit" onClick={logout} />
      </div>
    ) : null;
  };

  const getAddOrganization = (): React.JSX.Element | null => {
    return organizations && organizationSituation.organizationIsNotSet ? (
      <div className={styles.addingFirstOrganization}>
        <Outlet />
      </div>
    ) : null;
  };

  const getLayout = (): React.JSX.Element | null => {
    return organizations && !organizationSituation.organizationIsNotSet ? (
      <Layout
        isBackgroundColorful={isBackgroundColorful}
        noPaymentMethods={noPaymentMethods}
        selectedOrganization={selectedOrganization}
      />
    ) : null;
  };

  return (
    <div className={styles.root}>
      <KycOrPaymentIssueBanner />
      {getError() || getAddOrganization() || getLayout()}
    </div>
  );
});
