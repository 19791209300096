import api from 'api';
import { UserRegistrationData } from 'auth/types';
import { UserAccountInfo } from 'domain/types/Domain.types';
import { UserReturn } from 'domain/types/User.types';

export const fetchUser = (userId: string): UserReturn => {
  return api.get(`/users/${userId}`);
};

export function createUser(data: UserRegistrationData): UserReturn {
  return api.post('/users', data);
}

export const updateUser = (userId: string, userInfo: Partial<UserAccountInfo>): UserReturn => {
  return api.patch(`/users/${userId}`, userInfo);
};
