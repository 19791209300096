import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.module.css';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { parseISO } from 'date-fns';
import { ReactComponent as CalendarIcon } from 'theme/assets/svg/calendar.svg';
import { ReactComponent as CloseIcon } from 'theme/assets/svg/close.svg';
import styles from './DatePickerWrapper.module.scss';

export const getCorrectDateWithYearThatMayBeTwoDigits = (year: string): Date => {
  const yearAsNumber = parseInt(year, 10);
  return new Date(new Date(yearAsNumber, 0, 1).setFullYear(yearAsNumber));
};

interface DatePickerWrapperProps {
  value: string | Date;
  onChange: (date: string) => void;
  label?: string;
  isLabelAbove?: boolean;
  placeholder?: string;
  error?: string;
  minDate?: Date;
  maxDate?: Date;
  showYearPicker?: boolean;
}

const DATE_PICKER_INPUT_CLASSNAME = 'datePickerInput';

const parseDate = (value: string | Date, showYearPicker: boolean): Date | undefined => {
  if (typeof value === 'string') {
    if (value.length > 0) {
      return showYearPicker ? getCorrectDateWithYearThatMayBeTwoDigits(value) : parseISO(value);
    } else {
      return undefined;
    }
  }
  return value;
};

const DatePickerWrapper = ({
  value,
  onChange,
  label,
  isLabelAbove,
  placeholder,
  error,
  minDate,
  maxDate,
  showYearPicker = false,
}: DatePickerWrapperProps): JSX.Element => {
  const [isHover, setIsHover] = useState(false);

  // const [resetValue, setResetValue] = useState(false);

  // const handleChangeRaw = (value: string): void => {
  //   const pattern = /^\d+\.?\d*$/;
  //   setResetValue(!pattern.test(value));
  // };

  const handleChange = (date: string): void => {
    // setResetValue(false);
    onChange(date);
    setIsHover(false);
  };

  useEffect(() => {
    Array.from(document.getElementsByClassName(DATE_PICKER_INPUT_CLASSNAME))?.forEach((el) =>
      el.setAttribute('readonly', 'true')
    );
  }, []);

  return (
    <div
      className={clsx(styles.root, { [styles.labelAbove]: isLabelAbove })}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {label ? <p className={styles.label}>{label}</p> : null}
      <DatePicker
        showIcon
        onChange={(date: string) => handleChange(date)}
        // onChangeRaw={(event) => handleChangeRaw(event.target.value)}
        selected={parseDate(value, showYearPicker)}
        // value={resetValue ? '' : parseDate(value, showYearPicker)}
        placeholderText={placeholder}
        className={clsx(
          styles.datePicker,
          { [styles.redBorder]: error, [styles.yearPicker]: showYearPicker },
          DATE_PICKER_INPUT_CLASSNAME
        )}
        icon={isHover && value ? <></> : <CalendarIcon />}
        minDate={minDate}
        maxDate={maxDate}
        showYearPicker={showYearPicker}
        dateFormat={showYearPicker ? 'u' : undefined}
        yearItemNumber={showYearPicker ? 50 : undefined}
      />
      {error && <span className={styles.error}>{error}</span>}
      {isHover && value && (
        <div onClick={() => handleChange('')} className={styles.removeIcon}>
          <CloseIcon />
        </div>
      )}
    </div>
  );
};

export default observer(DatePickerWrapper);
