import axios, { AxiosRequestConfig } from 'axios';
import dayjs from 'dayjs';
import qs from 'qs';
import { rootStore } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import { Auth } from 'auth/types';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import env from 'domain/env';
import { isAccessTokenValid } from 'utils/helpers';
import { getAuthDetailsFromLocalStorage, getDeviceId, getRefreshTokenFromLocalStorage } from './utils';

export const LEEWAY = 1;
const CONTEXT_HEADER_NAME = 'artnetwork-context-organization-id';

dayjs.extend(isSameOrBefore);

const api = axios.create({
  baseURL: env.backendUrl,
  paramsSerializer: function (params) {
    return qs.stringify(params, {
      arrayFormat: 'repeat',
    });
  },
});

api.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    return new Promise((resolve) => {
      const authCredentials = getAuthDetailsFromLocalStorage();
      const refreshToken = getRefreshTokenFromLocalStorage();
      const storeAccessToken = rootStore.authStore.accessToken;

      const parsedAuthCredentials: Auth | null = authCredentials
        ? (JSON.parse(authCredentials) as Auth)
        : storeAccessToken;

      const selectedOrganization = rootStore.organizationStore.selectedOrganization;
      const context = selectedOrganization && { organizationId: selectedOrganization.id };
      const contextHeader = context ? { [CONTEXT_HEADER_NAME]: context.organizationId } : {};

      const defaultHeaders = {
        ...config.headers,
        'device-id': getDeviceId(),
        ...contextHeader,
      };

      if (parsedAuthCredentials && isAccessTokenValid(parsedAuthCredentials)) {
        return resolve({
          ...config,
          headers: {
            ...defaultHeaders,
            Authorization: `Bearer ${parsedAuthCredentials.token}` || '',
          },
        });
      } else if (refreshToken) {
        rootStore.authStore.setQueuedRequests((accessToken) => {
          return resolve({
            ...config,
            headers: {
              ...defaultHeaders,
              Authorization: `Bearer ${accessToken}`,
            },
          });
        });

        if (!rootStore.authStore.submittingLoginWithRefreshToken) {
          return rootStore.authStore.loginWithRefreshToken(refreshToken);
        }
      } else {
        return resolve({
          ...config,
          headers: defaultHeaders,
        });
      }
    });
  },
  (err: unknown) => Promise.reject(err)
);

api.interceptors.response.use(
  (response) => Promise.resolve(response),
  (error) => {
    if (!error.config.url.startsWith('/auth') && error.response?.status === 401) {
      rootStore.authStore.logout();
      return window.location.replace(location.origin + routes.Login.path());
    } else return Promise.reject(error);
  }
);

export default api;
