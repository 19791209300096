import { observer } from 'mobx-react-lite';
import { ArtworkForm } from 'artworks/addArtwork/types';
import { Artwork } from 'artworks/types';
import SectionHeader from 'theme/atoms/Titles/SectionHeader';
import styles from './SummaryArtworkDetails.module.scss';

type Row = [string, string | null | number | undefined];

interface SummaryArtworkDetailsProps {
  artwork: Artwork | ArtworkForm;
}

const SummaryArtworkDetails = ({ artwork }: SummaryArtworkDetailsProps): JSX.Element => {
  const rowsMap: Row[] = [
    ['Title', artwork.name],
    ['Year', artwork.year],
    ['Executed year', artwork.executedYear],
    ['Size', artwork.size],
    ['Edition number', artwork.editionNumber],
    ['Weight', artwork.weight],
    ['Carbon dating', artwork.carbonDating],
    ['Artist Resell Rights', artwork.artistResellRightsPercent + '%'],
  ];

  return (
    <>
      <SectionHeader title="Artwork details" />
      <div className={styles.root}>
        {rowsMap.map((row): JSX.Element => {
          const [label, text] = row;
          return (
            <div className={styles.row} key={label}>
              <p className={styles.label}>{label}</p>
              <p className={styles.content}>{text}</p>
            </div>
          );
        })}
      </div>
      <div>
        <p className={styles.label}>Description</p>
        <div className={styles.description}>{artwork.description}</div>
      </div>
    </>
  );
};

export default observer(SummaryArtworkDetails);
