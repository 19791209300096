import { ReactNode } from 'react';
import clsx from 'clsx';
import styles from './Card.module.scss';

interface CardCommonProps {
  className?: string;
  children: ReactNode;
}

const Card = ({ className, children }: CardCommonProps): JSX.Element => {
  return <div className={clsx(styles.root, className)}>{children}</div>;
};

const CardHeader = ({ children, className }: CardCommonProps): JSX.Element => {
  return <div className={clsx(styles.header, className)}>{children}</div>;
};

const CardBody = ({ children, className }: CardCommonProps): JSX.Element => {
  return <div className={clsx(styles.body, className)}>{children}</div>;
};

interface CardDetailsListProps {
  children: ReactNode;
  className?: string;
}

const CardDetailsList = ({ children, className }: CardDetailsListProps): JSX.Element => {
  return (
    <Card.Body className={clsx(styles.detailsList, className)}>
      <dl>{children}</dl>
    </Card.Body>
  );
};

Card.Header = CardHeader;
Card.Body = CardBody;
Card.DetailsList = CardDetailsList;

export default Card;
