import { makeAutoObservable, runInAction } from 'mobx';
import { RootStore } from 'RootStore';
import { getArtworksViewOptions, saveArtworksViewOptions } from 'artworks/utils';
import { INITIAL_DATA_FIELDS, INITIAL_LAYOUT, INITIAL_SIZE_INDEX, LAYOUTS } from './constants';
import { DataFieldType, DataFieldsType, LayoutType, Sizes, TwoDataFieldArraysTuple, ViewOptionsData } from './types';

export const initialSizes = LAYOUTS.reduce(
  (sizes, layout) => ({ ...sizes, [layout]: INITIAL_SIZE_INDEX }),
  {}
) as Sizes;

export const initialDataFields = LAYOUTS.reduce(
  (dataFields, layout) => ({ ...dataFields, [layout]: INITIAL_DATA_FIELDS }),
  {}
) as DataFieldsType;

const localStorageViewOptionsData: ViewOptionsData | null = getArtworksViewOptions();

class ArtworksViewOptionsStore {
  rootStore: RootStore;

  layout: LayoutType = localStorageViewOptionsData?.layout || INITIAL_LAYOUT;
  sizes: Sizes = localStorageViewOptionsData?.sizes || initialSizes;
  dataFields: DataFieldsType = localStorageViewOptionsData?.dataFields || initialDataFields;

  showUncheckingLastFieldError = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  saveViewOptions = (layout: LayoutType, sizes: Sizes, dataFields: DataFieldsType): void => {
    const sortedDataFields = {} as DataFieldsType;

    Object.keys(dataFields).forEach((layoutType) => {
      const [checkedDataFields, uncheckedDataFields] = dataFields[layoutType].reduce(
        (accArray: TwoDataFieldArraysTuple, field: DataFieldType): TwoDataFieldArraysTuple => {
          if (field.child) {
            if (field.isChecked) {
              if (field.child.isChecked) {
                accArray = [[...accArray[0], field], accArray[1]];
              } else if (!field.child.isChecked) {
                const child = field.child;
                delete field.child;

                accArray = [
                  [...accArray[0], field],
                  [...accArray[1], child],
                ];
              }
            } else if (!field.isChecked) {
              const child = { ...field.child };
              delete field.child;

              accArray = [accArray[0], [...accArray[1], field]];

              if (child.isChecked) {
                accArray = [[...accArray[0], child], accArray[1]];
              } else if (!child.isChecked) {
                accArray = [accArray[0], [...accArray[1], child]];
              }
            }
          } else if (!field.child) {
            if (field.isChecked) {
              accArray = [[...accArray[0], field], accArray[1]];
            } else if (!field.isChecked) {
              accArray = [accArray[0], [...accArray[1], field]];
            }
          }

          return accArray;
        },
        [[], []] as TwoDataFieldArraysTuple
      );

      sortedDataFields[layoutType] = [...checkedDataFields, ...uncheckedDataFields];
    });
    runInAction(() => {
      this.layout = layout;
      this.sizes = sizes;
      this.dataFields = sortedDataFields;
    });

    saveArtworksViewOptions({ layout, sizes, dataFields: sortedDataFields });
  };

  setShowUncheckingLastFieldError = (showError: boolean): void => {
    runInAction(() => {
      this.showUncheckingLastFieldError = showError;
    });
  };

  resetAll = (): void => {
    runInAction(() => {
      this.layout = localStorageViewOptionsData?.layout || INITIAL_LAYOUT;
      this.sizes = localStorageViewOptionsData?.sizes || initialSizes;
      this.dataFields = localStorageViewOptionsData?.dataFields || initialDataFields;
    });
  };
}

export default ArtworksViewOptionsStore;
