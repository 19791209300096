import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { RawOrganization } from 'organization/types';
import { Button, Input } from 'theme/atoms';
import Avatar from 'theme/atoms/avatar';
import SlidingPanel from 'theme/atoms/slidingPanel';
import { useOrganizationKinds } from 'utils/hooks/useOrganizationKinds';
import ReadOnlyForm from '../readOnlyForm/ReadOnlyForm';
import styles from './OrganizationProfileEditPanel.module.scss';

const FIELDS_TO_UPDATE = ['profileDescription', 'url', 'title'];

interface OrganizationProfileEditPanelProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (form: RawOrganization) => Promise<void>;
}

const OrganizationProfileEditPanel = ({
  isOpen,
  onClose,
  onSave,
}: OrganizationProfileEditPanelProps): React.JSX.Element => {
  const { organizationStore } = useStores();
  const { selectedOrganization } = organizationStore;
  const detailsFromParent = FIELDS_TO_UPDATE.reduce(
    (acc, field) => ({
      ...acc,
      [field]: selectedOrganization?.[field],
    }),
    {} as RawOrganization
  );
  const [profileForm, setProfileForm] = useState<RawOrganization>(detailsFromParent);
  const { isInstitutional } = useOrganizationKinds();

  useEffect(() => {
    setProfileForm(detailsFromParent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>): void => {
    setProfileForm({ ...profileForm, [target.name]: target.value || '' });
  };

  const handleSave = (event: FormEvent): void => {
    event.preventDefault();
    onSave(profileForm).then(() => onClose());
  };

  const header = (
    <div className={styles.header}>
      <div>Profile picture</div>
      <div className={styles.picture}>
        <Avatar id={selectedOrganization?.id || ''} isInstitutional={isInstitutional} />
      </div>
    </div>
  );

  const institutionalElements = isInstitutional && (
    <div className={styles.section}>
      <Input
        label="WWW"
        name="url"
        placeholder="Enter  website address"
        value={profileForm.url || ''}
        onChange={handleChange}
      />
    </div>
  );

  const commonElements = (
    <div className={styles.section}>
      <Input
        label="Profile type"
        name="title"
        placeholder="Enter profile type e.g. Collector, Gallery"
        value={profileForm.title || ''}
        onChange={handleChange}
      />
      <Input
        label="Bio"
        name="profileDescription"
        placeholder="Describe organization's profile"
        value={profileForm.profileDescription || ''}
        onChange={handleChange}
      />
    </div>
  );

  return (
    <SlidingPanel title="Edit organization profile" onClose={onClose} isOpen={isOpen}>
      <div className={styles.root}>
        {header}
        <ReadOnlyForm />
        <form className={styles.form}>
          {institutionalElements}
          {commonElements}
          <Button text="Save" onClick={handleSave} maxWidth />
        </form>
      </div>
    </SlidingPanel>
  );
};

export default observer(OrganizationProfileEditPanel);
