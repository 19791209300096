import styles from './NoDataToDisplay.module.scss';

type NoDataToDisplayProps = {
  copy: string;
  height?: number;
};

const NoDataToDisplay = ({ copy, height }: NoDataToDisplayProps): JSX.Element => {
  return (
    <div className={styles.root} style={{ height: height + 'px' }}>
      <p>{copy}</p>
    </div>
  );
};

export default NoDataToDisplay;
