import { PropsWithChildren } from 'react';
import clsx from 'clsx';
import styles from './Chip.module.scss';

interface ChipProps extends PropsWithChildren, React.HTMLAttributes<HTMLDivElement> {
  variant?: 'success' | 'warning' | 'error';
}

const Chip = ({ variant, children, ...props }: ChipProps): React.JSX.Element => {
  return (
    <div className={clsx(styles.root, variant && styles[variant])} {...props}>
      {children}
    </div>
  );
};

export default Chip;
