import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import styles from 'auth/Auth.module.scss';
import { Subtitle } from 'theme/atoms';
import Breadcrumbs, { Breadcrumb } from 'theme/atoms/Breadcrumbs';
import Topbar from 'theme/atoms/Topbar';
import { useTitle } from 'utils/hooks';
import { ReactComponent as ExclamationMarkIcon } from '../../assets/exclamation-mark.svg';
import SendNewEmail from '../../components/SendNewEmail';

const BREADCRUMBS: Breadcrumb[] = [
  {
    label: 'Verify email address',
  },
];

function UnverifiedEmail(): JSX.Element {
  useTitle('Login - verify email address');

  return (
    <div className={clsx(styles.root, styles.oneColumnLayout)}>
      <Topbar>
        <Breadcrumbs breadcrumbs={BREADCRUMBS} />
      </Topbar>
      <div className={styles.verifyEmail}>
        <div className={styles.verifyEmailIconWrapper}>
          <ExclamationMarkIcon />
        </div>
        <div className={styles.instruction}>You need to verify your email address to activate your account</div>
        <Subtitle text="A verification email has been sent to you." dontLimitWidth />
        <SendNewEmail />
      </div>
    </div>
  );
}

export default observer(UnverifiedEmail);
