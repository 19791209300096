import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import Big from 'big.js';
import { Option } from 'theme/atoms/Select/Select';
import Billing from 'theme/atoms/billing';
import CurrencyInputWrapper from 'theme/atoms/currencyInputWrapper';
import { TransactionWrapper } from 'transactions/requests.types';
import { formatPrice, selectInputOnFocus } from 'utils/helpers';
import { useDebounce, useTransactionRoles } from 'utils/hooks';
import styles from '../TransactionBilling.module.scss';
import CommissionPercentSelect from './CommissionPercentSelect';

const DEBOUNCE_DELAY = 800;

interface CommissionProps {
  isSummaryView: boolean;
}

const Commission = ({ isSummaryView }: CommissionProps): JSX.Element | null => {
  const { transactionFormStore } = useStores();
  const transactionWrapper = transactionFormStore.transactionWrapper as TransactionWrapper;

  const { totalSellerCommission, totalBuyerCommission, updateTotalCommissionAndAssignItToRepresentative } =
    transactionFormStore;

  const {
    transaction: { currency, sellerInfo },
    consignment,
  } = transactionWrapper;

  const title = isSummaryView ? 'Commission total' : 'Commission';

  const [selectedCommissionPercentOption, setSelectedCommissionPercentOption] = useState<Option | null>(null);
  const [selectedCommissionAmount, setSelectedCommissionAmount] = useState<Big | null>(null);

  const [selectedBuyerRepresentativeCommission, setSelectedBuyerRepresentativeCommission] = useState<string>(
    transactionWrapper.transaction.buyerInfo?.totalCommission?.toString() || ''
  );
  const debouncedBuyerRepresentativeCommission = useDebounce(selectedBuyerRepresentativeCommission, DEBOUNCE_DELAY);
  const { isSellerSide, isSellerOwner } = useTransactionRoles({ transactionWrapper });
  const sellerRepAskForHigherCommission = Big(totalSellerCommission || 0).gt(consignment?.maxCommission || 0);

  useEffect(() => {
    if (totalSellerCommission && sellerInfo) {
      setSelectedCommissionAmount(totalSellerCommission);
      setSelectedCommissionPercentOption(
        consignment
          ? {
              value: Big(totalSellerCommission).times(100).div(sellerInfo.price).round(2).toString(),
              label: Big(totalSellerCommission).times(100).div(sellerInfo.price).round().toString() + '%',
            }
          : null
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalSellerCommission]);

  useEffect(() => {
    if (!selectedCommissionPercentOption || !sellerInfo?.price) return;

    setSelectedCommissionAmount(
      Big(selectedCommissionPercentOption.value)
        .times(sellerInfo?.price || 0)
        .div(100)
    );
  }, [sellerInfo, selectedCommissionPercentOption]);

  useEffect(() => {
    updateTotalCommissionAndAssignItToRepresentative('buyerInfo', Big(debouncedBuyerRepresentativeCommission || 0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedBuyerRepresentativeCommission]);

  const commissionPicker = (): JSX.Element => {
    return sellerInfo?.price && consignment?.maxCommission ? (
      <CommissionPercentSelect
        askingPrice={sellerInfo?.price}
        maxCommission={sellerRepAskForHigherCommission ? Big(totalSellerCommission || 0) : consignment.maxCommission}
        selectedPercentFromParent={selectedCommissionPercentOption || undefined}
        setCommissionPercentOption={(val) => {
          setSelectedCommissionPercentOption(val);
        }}
      />
    ) : (
      <></>
    );
  };

  const commissionPercent = isSummaryView || !isSellerSide ? <div /> : commissionPicker();
  const commissionAmountSellerOwner = sellerRepAskForHigherCommission
    ? sellerInfo?.totalCommission
    : consignment?.maxCommission;
  const commissionAmountSellerSide = isSellerOwner ? commissionAmountSellerOwner : selectedCommissionAmount;

  const commissionAmountElement = isSellerSide ? (
    <Billing.Cell isBold={isSummaryView}>{formatPrice(Big(commissionAmountSellerSide || 0), currency)}</Billing.Cell>
  ) : isSummaryView && totalBuyerCommission ? (
    <Billing.Cell isBold>{formatPrice(totalBuyerCommission, currency)}</Billing.Cell>
  ) : (
    <></>
  );

  const buyerRepresentativeCommissionInput = !isSellerSide && !isSummaryView && (
    <Billing.Row>
      <CurrencyInputWrapper
        name="buyerRepresentativeCommissionInput"
        onValueChange={(values) => setSelectedBuyerRepresentativeCommission(values.value || '')}
        value={selectedBuyerRepresentativeCommission}
        onFocus={selectInputOnFocus}
        placeholder="Type your commission"
        className={styles.commissionInput}
        currency={currency}
      />
    </Billing.Row>
  );

  return (
    <>
      <Billing.Row {...(isSellerSide ? { isLight: true } : { isDark: true })}>
        <Billing.Cell>{title}:</Billing.Cell>
        <Billing.Cell isSplit>
          {commissionPercent}
          {commissionAmountElement}
        </Billing.Cell>
      </Billing.Row>
      {buyerRepresentativeCommissionInput}
    </>
  );
};

export default observer(Commission);
