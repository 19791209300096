import { RawBankInvoice, BillingInfo } from 'bankPerspective/bankInvoices/requests.types';
import { RawBankTransaction } from 'bankPerspective/bankTransactions/requests.types';
import { SellerInfo, BuyerInfo } from 'bankPerspective/bankTransactions/requests.types';
import Big from 'big.js';
import { ContactBasicInfo } from 'domain/types/Contact.types';
import { Currency } from 'domain/types/Domain.types';
import { InvoiceState, InvoiceStateLog } from 'domain/types/Invoices.types';
import { TransactionStatus } from 'domain/types/Transactions.types';
import { Invoice as InvoiceType } from 'transactions/requests.types';

type GetTransactionDataReturn = {
  buyerOrPayerInfo: ContactBasicInfo;
  createdAt: Date;
  currency: Currency;
  id: string;
  sellerOrIssuerInfo: ContactBasicInfo;
  status: TransactionStatus;
  totalAmount: Big;
};

export const getTransactionData = (item: RawBankTransaction): GetTransactionDataReturn => {
  const { id, sellerInfo, buyerInfo, invoices, currency, status, createdAt } = item;

  const totalInvoiceAmount = invoices.reduce((accumulator, currentInvoice) => {
    return accumulator.plus(currentInvoice.amount);
  }, new Big(0));

  return {
    buyerOrPayerInfo: buyerInfo.representative,
    createdAt,
    currency,
    id,
    sellerOrIssuerInfo: sellerInfo.representative,
    status,
    totalAmount: totalInvoiceAmount,
  };
};

type GetInvoiceDataReturnType = {
  bankNote: string;
  buyerOrPayerInfo: BuyerInfo | ContactBasicInfo | undefined;
  createdAt: Date;
  currency: Currency;
  externalId: string;
  id: string;
  modifiedAt: Date;
  sellerOrIssuerInfo: SellerInfo | ContactBasicInfo | undefined;
  state: InvoiceState;
  stateChangeLog: InvoiceStateLog[];
  totalAmount: Big;
  billing?: BillingInfo;
};

const buildInvoiceData = (data: RawBankInvoice | InvoiceType, isBankInvoice: boolean): GetInvoiceDataReturnType => {
  const buyerOrPayerInfo = isBankInvoice
    ? (data as InvoiceType).payerContactDto
    : (data as RawBankInvoice).payerOrganizationDto;
  const sellerOrIssuerInfo = isBankInvoice
    ? (data as InvoiceType).issuerContactDto
    : (data as RawBankInvoice).issuerOrganizationDto;

  const { bankNote, createdAt, currency, externalId, id, modifiedAt, state, stateChangeLog } = data;

  const invoiceData: GetInvoiceDataReturnType = {
    bankNote,
    buyerOrPayerInfo,
    createdAt,
    currency,
    externalId,
    id,
    modifiedAt,
    sellerOrIssuerInfo,
    state,
    stateChangeLog,
    totalAmount: new Big(data.amount),
  };

  if (!isBankInvoice && 'billing' in data) {
    invoiceData.billing = {
      artworkPrice: data.billing?.artworkPrice ?? null,
      commission: data.billing?.commission ?? null,
      artistResellRights: data.billing?.artistResellRights ?? null,
      totalBuyerRepresentativeCommission: data.billing?.totalBuyerRepresentativeCommission ?? null,
    };
  }

  return invoiceData;
};
export const getInvoiceData = (item: RawBankInvoice | InvoiceType): GetInvoiceDataReturnType => {
  const isBankInvoice = 'payerContactDto' in item;
  return buildInvoiceData(item, isBankInvoice);
};
