import { Outlet } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { Organization } from 'organization/types';
import Sidebar from 'sidebar';
import styles from './AppWrapper.module.scss';

interface LayoutProps {
  isBackgroundColorful?: boolean;
  noPaymentMethods: boolean;
  selectedOrganization: Organization | null;
}

export const Layout = observer(({ isBackgroundColorful, noPaymentMethods, selectedOrganization }: LayoutProps) => {
  return (
    <>
      <Sidebar />
      <div
        className={clsx(
          styles.rightSideWrapper,
          { [styles.isBackgroundColorful]: isBackgroundColorful },
          { [styles.showingBanner]: noPaymentMethods }
        )}
      >
        <div className={clsx(styles.rightSide, { [styles.isBackgroundColorful]: isBackgroundColorful })}>
          {selectedOrganization?.id && <Outlet />}
        </div>
      </div>
    </>
  );
});
