import { Outlet } from 'react-router-dom';
import clsx from 'clsx';
import { useStores } from 'RootStore';
import { SettingsSidebar } from 'settings/settingsSidebar';
import styles from './SettingsLayout.module.scss';

const SettingsLayout = (): React.JSX.Element => {
  const {
    userStore: { noPaymentMethods },
  } = useStores();

  return (
    <div className={clsx(styles.root, { [styles.showingBanner]: noPaymentMethods })}>
      <SettingsSidebar />
      <div className={styles.rightSide}>
        <Outlet />
      </div>
    </div>
  );
};

export default SettingsLayout;
