import { FormEvent, ReactNode, useState } from 'react';
import PhoneInput, { isPossiblePhoneNumber, Value } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import clsx from 'clsx';
import styles from 'auth/Auth.module.scss';
import { formValidationMessages } from 'domain/formValidationMessages';
import { useZodFormValidation } from 'utils/hooks';
import { z } from 'zod';
import phoneInputStyles from './AddPhoneForm.module.scss';

const AddPhoneSchema = z.object({
  phoneNumber: z
    .string({ required_error: formValidationMessages.PHONE_REQUIRED })
    .min(1, { message: formValidationMessages.PHONE_REQUIRED })
    .refine((phoneNumber) => isPossiblePhoneNumber(phoneNumber), { message: formValidationMessages.PHONE_INVALID }),
});

interface AddPhoneFormProps {
  onSubmit: (phoneNumber: string) => void;
  submitButton: ReactNode;
}

const AddPhoneForm = ({ onSubmit, submitButton }: AddPhoneFormProps): JSX.Element => {
  const [phoneNumber, setPhoneNumber] = useState<Value | undefined>(undefined);
  const { validateData, errors, clearErrors } = useZodFormValidation(AddPhoneSchema, { phoneNumber: phoneNumber || '' });

  const handleChange = (value: Value): void => {
    clearErrors();
    setPhoneNumber(value);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    if (validateData() && phoneNumber) {
      onSubmit(phoneNumber.toString());
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label className={styles.formLabel}>Phone:</label>

      <div className={styles.inputWithError}>
        <PhoneInput
          placeholder="Enter phone number"
          value={phoneNumber}
          onChange={handleChange}
          className={clsx(phoneInputStyles.phoneInput, { [phoneInputStyles.error]: errors.phoneNumber })}
          autoFocus
        />
        {errors.phoneNumber && <span className={clsx(styles.error)}>{errors.phoneNumber[0]}</span>}
      </div>

      <div className={styles.formSubmitButton}>{submitButton}</div>
    </form>
  );
};

export default AddPhoneForm;
