import { useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import Big from 'big.js';
import Billing from 'theme/atoms/billing';
import { formatPriceRange } from 'utils/helpers/formatRangePrice';
import { useElementWidth } from 'utils/hooks';
import styles from './ArtworkPrice.module.scss';

const ArtworkPrice = (): JSX.Element => {
  const { consignmentFormStore } = useStores();
  const {
    consignmentForm: { currency, askingPrice },
    askingPriceWithCommissions,
  } = consignmentFormStore;

  const parentRef = useRef<HTMLDivElement | null>(null);
  const parentWidth = useElementWidth(parentRef);

  const artworkPriceRange = formatPriceRange(Big(askingPrice || 0), askingPriceWithCommissions, currency);

  const shouldDisplayInTwoLines =
    (parentWidth <= 510 && artworkPriceRange.length > 30) || (parentWidth <= 430 && artworkPriceRange.length > 20);

  return (
    <div className={styles.root} ref={parentRef}>
      <Billing.Divider />
      <Billing.Subheader isTwoLines={shouldDisplayInTwoLines}>
        <Billing.Cell isBold>Artwork price range:</Billing.Cell>
        <Billing.Cell>{artworkPriceRange}</Billing.Cell>
      </Billing.Subheader>
    </div>
  );
};

export default observer(ArtworkPrice);
