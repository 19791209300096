import { useLocation, Link } from 'react-router-dom';
import clsx from 'clsx';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import { SidebarItemType } from 'sidebar/types';
import { ReactComponent as LogoutIcon } from 'theme/assets/svg/logout.svg';
import styles from './SettingsSidebar.module.scss';

type SidebarSettingsItemType = SidebarItemType & { pageType: 'individual' | 'common' };

const SETTINGS_SUB_PAGES = (): SidebarSettingsItemType[] => [
  {
    name: 'My account',
    goTo: routes.SettingsMyAccount.path(),
    matchingRoutes: ['/settings/my-account'],
    pageType: 'individual',
  },
  {
    name: 'My account security',
    goTo: routes.SettingsSecurity.path(),
    matchingRoutes: ['/settings/security'],
    pageType: 'common',
  },
  {
    name: 'Organization profile',
    goTo: routes.SettingsOrganizationProfile.path(),
    matchingRoutes: ['/settings/organization-profile'],
    pageType: 'individual',
  },
  {
    name: 'Identity verification (KYC)',
    goTo: routes.SettingsKYC.path(),
    matchingRoutes: ['/settings/kyc'],
    pageType: 'individual',
  },
  {
    name: 'Billing',
    goTo: routes.Billing.path(),
    matchingRoutes: ['/wallet'],
    pageType: 'individual',
  },
];

const SettingsSidebar = (): JSX.Element => {
  const {
    authStore: { logout },
    organizationStore: { isOrganizationBank },
  } = useStores();
  const location = useLocation();

  const getSettingsSubPages = (): SidebarSettingsItemType[] => {
    if (isOrganizationBank) {
      return SETTINGS_SUB_PAGES().filter((item) => item.pageType !== 'individual');
    }
    return SETTINGS_SUB_PAGES();
  };

  const sidebarSettingsLinksToDisplay: SidebarItemType[] = [...getSettingsSubPages()];

  return (
    <div className={styles.root}>
      <div className={styles.background} />
      <div className={styles.content}>
        <h3 className={styles.title}>Settings</h3>
        <div className={styles.items}>
          {sidebarSettingsLinksToDisplay?.map((item) => (
            <Link
              key={item.goTo}
              className={clsx(styles.item, {
                [styles.activeItem]: location.pathname.match(new RegExp('^' + item.goTo)),
              })}
              to={item.goTo}
            >
              {item.name}
            </Link>
          ))}
          <div className={styles.line} />
          <button className={styles.logout} onClick={() => logout()}>
            <LogoutIcon />
            Log out
          </button>
        </div>
      </div>
    </div>
  );
};

export default SettingsSidebar;
