import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { ReactComponent as EditIcon } from 'theme/assets/svg/pencil.svg';
import ActionButtons from 'theme/atoms/ActionButtons';
import Breadcrumbs from 'theme/atoms/Breadcrumbs';
import Topbar from 'theme/atoms/Topbar';
import Loader from 'theme/atoms/loader';
import styles from './Billing.module.scss';
import BillingHistory from './components/billingHistory';
import CurrentPeriod from './components/currentPeriod';
import PaymentMethod from './components/paymentMethod';
import PendingInvoice from './components/pendingInvoice';

const BILLING_INFO_URL_FRAGMENT = '/customer/update';

const Billing = (): JSX.Element => {
  const { billingStore } = useStores();
  const { fetchInvoices, loadingInvoices, portalUrl, openPortal } = billingStore;

  useEffect(() => {
    fetchInvoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loader = loadingInvoices && <Loader />;

  const handleEditBillingInfo = (): void => {
    if (portalUrl) {
      window.open(portalUrl + BILLING_INFO_URL_FRAGMENT, '_blank', 'noreferrer');
    } else {
      openPortal().then((url) => {
        url && window.open(url + BILLING_INFO_URL_FRAGMENT, '_blank', 'noreferrer');
      });
    }
  };

  return (
    <>
      <Topbar>
        <Breadcrumbs />
        <ActionButtons
          buttons={[
            {
              text: 'Edit billing info',
              icon: <EditIcon />,
              onClick: () => handleEditBillingInfo(),
            },
          ]}
        />
      </Topbar>
      <div className={styles.root}>
        {loader || <PendingInvoice />}
        <PaymentMethod />
        <BillingHistory />
        <CurrentPeriod />
      </div>
    </>
  );
};

export default observer(Billing);
