import clsx from 'clsx';
import { RawBankInvoice } from 'bankPerspective/bankInvoices/requests.types';
import { InvoiceState, InvoiceStateLog } from 'domain/types/Invoices.types';
import InvoiceStateCheckIcon from 'theme/atoms/invoiceStateCheckIcon';
import { Invoice as InvoiceType } from 'transactions/requests.types';
import { formatDate } from 'utils/helpers';
import styles from './InvoiceStateTimeline.module.scss';

interface InvoiceStateTimelineProps {
  stateChangeLog: InvoiceStateLog[];
  invoice: RawBankInvoice | InvoiceType;
}

interface TimelineElementsReturn {
  leftItem: JSX.Element;
  rightItem: JSX.Element | undefined;
  isFirstInTimeline: boolean;
  isLastInTimeline: boolean;
}

const InvoiceStateTimeline = ({ stateChangeLog, invoice }: InvoiceStateTimelineProps): JSX.Element => {
  const grayLineWithDot = (isDouble = false): JSX.Element => {
    const singleGrayLineWithDot = (
      <div className={styles.timelineItem}>
        <div className={styles.timelineLineGray} />
        <div className={styles.timelineDotGray} />
      </div>
    );

    return (
      <>
        {singleGrayLineWithDot}
        {isDouble && singleGrayLineWithDot}
      </>
    );
  };

  const renderTimelineItem = (text: string, date: Date, isMiddle = false): JSX.Element => {
    const className = clsx(styles.timelineItemWithState, {
      [styles.timelineItemWithStateMiddle]: isMiddle,
    });

    return (
      <div className={className}>
        <div className={styles.timelineDot} />
        <div className={styles.timelineText}>
          <p>{text}</p>
          <span>{formatDate(date)}</span>
        </div>
        <div className={styles.timelineLine} />
      </div>
    );
  };

  const prepareTimelineItems = (): TimelineElementsReturn => {
    if (!stateChangeLog || stateChangeLog.length <= 1) {
      const invoiceUploaded = invoice.state !== InvoiceState.PendingUpload;

      return {
        leftItem: <>{invoiceUploaded && renderTimelineItem('Uploaded', invoice.createdAt)}</>,
        rightItem: invoice.state !== InvoiceState.Paid ? grayLineWithDot(!invoiceUploaded) : undefined,
        isFirstInTimeline: !invoiceUploaded,
        isLastInTimeline: invoice.state === InvoiceState.Paid,
      };
    } else {
      const firstLog = stateChangeLog[0];
      const lastLog = stateChangeLog[stateChangeLog.length - 1];
      const beforeLastLog = stateChangeLog[stateChangeLog.length - 2];
      const hasPaidState = stateChangeLog.some((log) => log.state === InvoiceState.Paid);
      const markAsUploadedStates = [InvoiceState.PendingUpload];
      const isMarkedAsUploaded = !markAsUploadedStates.includes(lastLog.state);

      return {
        leftItem: (
          <>
            {isMarkedAsUploaded && renderTimelineItem('Uploaded', firstLog.changedAt)}
            {hasPaidState && renderTimelineItem('Accepted', beforeLastLog.changedAt)}
          </>
        ),
        rightItem: !hasPaidState ? grayLineWithDot(!isMarkedAsUploaded) : undefined,
        isFirstInTimeline: !isMarkedAsUploaded,
        isLastInTimeline: lastLog.state === InvoiceState.Paid,
      };
    }
  };

  const { leftItem, rightItem, isFirstInTimeline, isLastInTimeline } = prepareTimelineItems();

  return (
    <InvoiceStateCheckIcon
      state={isLastInTimeline ? stateChangeLog[stateChangeLog.length - 1].state : invoice.state}
      createdDate={isLastInTimeline ? stateChangeLog[stateChangeLog.length - 1].changedAt : invoice.createdAt}
      withStateText
      isStateTextUnderIcon
      firstInTimeline={isFirstInTimeline}
      lastInTimeline={isLastInTimeline}
      timeLineLeftElement={leftItem}
      timeLineRightElement={rightItem}
      withoutTooltip
    />
  );
};

export default InvoiceStateTimeline;
