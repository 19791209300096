export const NETWORK_CATEGORIES_WITH_STATUSES = [
  {
    id: 'network',
    label: 'Network',
    status: ['Connected'],
  },
  {
    id: 'pending',
    label: 'Pending invites',
    status: ['Pending'],
  },
  {
    id: 'requested',
    label: 'Requested invites',
    status: ['Requested'],
  },
] as const;

export const DEFAULT_NETWORK_CATEGORY = NETWORK_CATEGORIES_WITH_STATUSES[0].id;
