import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import styles from 'auth/Auth.module.scss';
import VerifyPhoneForm from 'auth/components/forms/VerifyPhoneForm';
import { UserIdentityType } from 'auth/types';
import { StoreActionReturn } from 'domain/types/StoreActionReturn';
import { Button, Subtitle, TitleBlack } from 'theme/atoms';
import Breadcrumbs, { Breadcrumb } from 'theme/atoms/Breadcrumbs';
import Topbar from 'theme/atoms/Topbar';
import { useIsAccessTokenFresh, useUserContext } from 'utils/hooks';

const BREADCRUMBS: Breadcrumb[] = [{ label: 'Verification' }];

const LoginWithPhone = (): React.JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const redirectUrl = location.state?.redirectUrl;
  const isTokenFresh = useIsAccessTokenFresh();
  const { homePath } = useUserContext();
  const {
    authStore: { submitting, userIdentities, sendSMSCodeForLogin, saveTokensToLocalStorage, loginWithToken },
  } = useStores();

  const userIdentity = userIdentities?.find((identity) => identity.type === UserIdentityType.Phone);

  useEffect(() => {
    if (!isTokenFresh) {
      navigate(routes.Login.path());
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!userIdentity) return;
    sendSMSCodeForLogin(userIdentity.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIdentity]);

  const onSubmit = (confirmationCode: string): void => {
    loginWithToken({ token: confirmationCode, userIdentityId: userIdentity?.id }).then((response) => {
      if (response?.success) {
        if (redirectUrl) {
          navigate(redirectUrl);
        } else {
          saveTokensToLocalStorage();
          navigate(homePath);
        }
      }
    });
  };

  const handleResendCode = (): Promise<StoreActionReturn | void> => {
    if (!userIdentity) return Promise.reject();
    return sendSMSCodeForLogin(userIdentity.id);
  };

  return (
    <div className={clsx(styles.root, styles.oneColumnLayout)}>
      <Topbar>
        <Breadcrumbs breadcrumbs={BREADCRUMBS} />
      </Topbar>
      <div className={styles.innerWrapper}>
        <TitleBlack>SMS authentication code</TitleBlack>
        <Subtitle>
          Enter your one-time security code we sent to your
          <br />
          phone number: <span className={styles.inlinePhoneNumber}>{userIdentity?.value}</span>
        </Subtitle>

        <VerifyPhoneForm
          submitButton={<Button text="Continue" loading={submitting} disabled={submitting} type="submit" maxWidth />}
          onSubmit={onSubmit}
          onResendCode={handleResendCode}
        />

        <div className={styles.useRecoveryCode}>
          <span>Having trouble?</span>
          <Button
            buttonType="textButton"
            text="Use recovery code"
            to={routes.LoginAccessRecovery.path()}
            className={styles.recoveryCodesButton}
          />
        </div>
      </div>
    </div>
  );
};

export default observer(LoginWithPhone);
