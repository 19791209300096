import styles from './Slider.module.scss';

interface SliderProps {
  value: number;
  onChange: (number) => void;
  labels: string[];
  className?: string;
}

const Slider = ({ value, onChange, labels, className = '' }: SliderProps): JSX.Element => {
  return (
    <div className={className}>
      <input
        type="range"
        className={styles.input}
        min={0}
        max={labels.length - 1}
        value={value}
        onChange={(e) => onChange(e.target.valueAsNumber)}
      />

      <div className={styles.labels}>
        {labels.map((label) => (
          <div key={label} className={styles.label}>
            {label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Slider;
