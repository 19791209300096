import { TransactionRole, transactionToRoles } from 'transactions/domain';
import { TransactionWrapper } from 'transactions/requests.types';

interface UseTransactionRolesProps {
  transactionWrapper: TransactionWrapper;
}

type UseTransactionRolesReturn = {
  isSellerOwner: boolean;
  isSellerRepresentative: boolean;
  isSellerOwnerAsRepresentative: boolean;
  isSellerSide: boolean;
  isBuyerRepresentative: boolean;
  isBuyer: boolean;
  isBuyerSide: boolean;
};

export const useTransactionRoles = ({ transactionWrapper }: UseTransactionRolesProps): UseTransactionRolesReturn => {
  const transactionRoles = transactionToRoles(transactionWrapper);

  const isSellerOwner = transactionRoles.includes(TransactionRole.SellerOwner);
  const isSellerRepresentative = transactionRoles.includes(TransactionRole.SellerRepresentative);
  const isBuyerRepresentative = transactionRoles.includes(TransactionRole.BuyerRepresentative);
  const isBuyer = transactionRoles.includes(TransactionRole.BuyerOwner);

  return {
    isSellerOwner,
    isSellerRepresentative,
    isSellerOwnerAsRepresentative: isSellerOwner && isSellerRepresentative,
    isSellerSide: isSellerOwner || isSellerRepresentative,
    isBuyerRepresentative,
    isBuyer,
    isBuyerSide: isBuyerRepresentative || isBuyer,
  };
};
