import clsx from 'clsx';
import { getLayoutSizeClass } from 'artworks/artworksDisplaying/utils';
import { ArtworkStatusEnum } from 'artworks/types';
import StatusIcon from 'theme/atoms/statusIcon';
import styles from './Status.module.scss';

interface StatusComponentProps {
  status: ArtworkStatusEnum;
  size?: number;
  hasIcon?: boolean;
}

const Status = ({ status, size, hasIcon = false }: StatusComponentProps): JSX.Element => {
  const sizeClass = size !== undefined && styles[getLayoutSizeClass(size)];

  return (
    <div className={clsx(styles.root, sizeClass)}>
      {hasIcon && <StatusIcon status={status} />}
      {status}
    </div>
  );
};

export default Status;
