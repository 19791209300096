import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { RootStore } from 'RootStore';
import PaginationBasicStore, {
  PaginationBasicStoreProps as BasicStoreProps,
} from 'domain/PaginationBasicStore/PaginationBasicStore';
import * as networkRequests from 'network/requests';
import { ContactConnection } from 'network/types';

const ExtendedStoreProps = {
  hasMoreContacts: computed,
  fetchItems: action,
  getNonExcludedContacts: action,
  contacts: observable,
  fetchingMore: observable,
  resetAll: action,
};

class ContactPickerStore extends PaginationBasicStore {
  rootStore: RootStore;
  contacts: ContactConnection[] = [];
  fetchingMore = false;

  constructor(rootStore: RootStore) {
    super(rootStore);
    this.rootStore = rootStore;
    makeObservable(this, { ...BasicStoreProps, ...ExtendedStoreProps });
  }

  get hasMoreContacts(): boolean {
    return Boolean(this.allItemsCount > this.contacts.length);
  }

  fetchItems = async (): Promise<void> => {
    const isFirstPage = Boolean(this.activePage === 1);
    runInAction(() => (this.fetchingMore = !isFirstPage));

    this.handleFetchItems(networkRequests.fetchContactConnections, { status: 'Connected' })
      .then(() => {
        runInAction(
          () => (this.contacts = [...(isFirstPage ? [] : this.contacts), ...(this.items as ContactConnection[])])
        );
      })
      .finally(() => {
        runInAction(() => (this.fetchingMore = false));
      });
  };

  getNonExcludedContacts = (contactsToExclude: string[]): ContactConnection[] => {
    return this.contacts.filter((contact) => !contactsToExclude?.includes(contact.id));
  };

  resetAll = (): void => {
    runInAction(() => {
      this.contacts = [];
    });
  };
}

export default ContactPickerStore;
