import clsx from 'clsx';
import { ReactComponent as LocationIcon } from 'theme/assets/svg/location.svg';
import styles from './ContactInfo.module.scss';

interface ContactInfoProps {
  name: string;
  title: string;
  address?: string;
  withIcon?: boolean;
  size?: 'small' | 'big';
}

const ContactInfo = ({ name, title, address, withIcon = false, size = 'big' }: ContactInfoProps): JSX.Element => (
  <div className={clsx(styles.root, styles[size])}>
    <div className={styles.fullName}>{name}</div>
    <div className={styles.title}>{title}</div>
    {address && (
      <div className={styles.location}>
        {withIcon && <LocationIcon className={styles.icon} />}
        <span className={styles.address}>{address}</span>
      </div>
    )}
  </div>
);

export default ContactInfo;
