import { makeAutoObservable, runInAction } from 'mobx';
import dayjs from 'dayjs';
import { RootStore } from 'RootStore';
import { LOCAL_STORAGE_APP_NAME } from 'auth/constants';
import { AuthUser } from 'auth/types';
import { UserAccountInfo } from 'domain/types/Domain.types';
import * as requests from './requests';

const LOCAL_STORAGE_PAYMENT_METHODS_RECENTLY_CHECKED = `${LOCAL_STORAGE_APP_NAME}__user__payment_methods_checked_recently__${location.hostname}`;

class UserStore {
  rootStore: RootStore;

  loading = true;
  userDetails: UserAccountInfo | null = null;
  noPaymentMethods = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  getUserDetails = (): Promise<void> => {
    const { addToast } = this.rootStore.toastsStore;
    runInAction(() => (this.loading = true));

    if (!this.loggedInUser) return Promise.resolve();

    return requests
      .fetchUser(this.loggedInUser.id)
      .then(({ data }) => {
        runInAction(() => {
          this.userDetails = data;
        });
      })
      .catch(() => {
        addToast('Failed to fetch user details', 'error');
      })
      .finally(() => runInAction(() => (this.loading = false)));
  };

  get loggedInUser(): AuthUser | null {
    return this.rootStore.authStore.loggedInUser;
  }

  updateUserProfile = (userInfo: Partial<UserAccountInfo>, dontShowToast = false): Promise<void> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.loading = true));

    if (!this.userDetails) return Promise.resolve();

    return requests
      .updateUser(this.userDetails.id, userInfo)
      .then(({ data }) => {
        runInAction(() => (this.userDetails = data));
        !dontShowToast && addToast(toastMessages.USERS.UPDATE_SUCCESS, 'success');
      })
      .catch(() => addToast(toastMessages.DEFAULT.ERROR, 'error'))
      .finally(() => runInAction(() => (this.loading = false)));
  };

  checkonPaymentMethods = (dontMindRecentChecking = false): Promise<void> | void => {
    const paymentMethodsRecentlyChecked = localStorage.getItem(LOCAL_STORAGE_PAYMENT_METHODS_RECENTLY_CHECKED);

    if (
      dontMindRecentChecking ||
      !paymentMethodsRecentlyChecked ||
      dayjs().isAfter(dayjs(paymentMethodsRecentlyChecked).add(8, 'hour'))
    ) {
      return this.rootStore.billingStore.fetchPaymentMethods().then(() => {
        localStorage.setItem(LOCAL_STORAGE_PAYMENT_METHODS_RECENTLY_CHECKED, dayjs().toISOString());
      });
    }
  };

  checkIfUserHasNoPaymentMethods = (response: string): void => {
    if (!response) return;
    const problem = response.split(':')[2];
    if (problem === 'no-payment-methods') {
      this.noPaymentMethods = true;
    }
  };

  setNoPaymentMethods = (val: boolean): void => {
    this.noPaymentMethods = val;
  };

  resetAll = (): void => {
    runInAction(() => {
      this.userDetails = null;
      this.noPaymentMethods = false;
    });
  };

  resetAllInAllStoresExceptUserAndAuth = (): void => {
    this.rootStore.createUserStore.resetAll();
    this.rootStore.contactPickerStore.resetAll();
    this.rootStore.artworksStore.resetAll();
    this.rootStore.artworksViewOptionsStore.resetAll();
    this.rootStore.artworkStore.resetAll();
    this.rootStore.addArtworkStore.resetAll();
    this.rootStore.approveArtworkStore.resetAll();
    this.rootStore.consignmentsStore.resetAll();
    this.rootStore.consignmentsSortAndFilterStore.resetAll();
    this.rootStore.consignmentStore.resetAll();
    this.rootStore.consignmentFormStore.resetAll();
    this.rootStore.transactionsStore.resetAll();
    this.rootStore.transactionStore.resetAll();
    this.rootStore.transactionsSortAndFilterStore.resetAll();
    this.rootStore.transactionFormStore.resetAll();
    this.rootStore.editBillingPanelStore.resetAll();
    this.rootStore.listNetworkStore.resetAll();
    this.rootStore.networkManagingStore.resetAll();
    this.rootStore.contactInvitationStore.resetAll();
    this.rootStore.organizationProfileShareStore.resetAll();
    this.rootStore.billingStore.resetAll();
    this.rootStore.escrowAccountFormStore.resetAll();
    this.rootStore.bankTransactionsStore.resetAll();
    this.rootStore.bankTransactionStore.resetAll();
    this.rootStore.bankInvoiceStore.resetAll();
  };
}

export default UserStore;
