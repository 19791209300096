import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import Big from 'big.js';
import { Contact } from 'domain/types/Contact.types';
import ContactPicker from 'network/contactPicker/ContactPicker';
import { ContactConnection } from 'network/types';
import { Button } from 'theme/atoms';
import SectionHeader from 'theme/atoms/Titles/SectionHeader';
import IconComponent, { icons } from 'theme/atoms/iconComponent';
import ShareholdersList from 'theme/atoms/shareholdersList';
import { useDebounceIsPanelOpen } from 'theme/atoms/slidingPanel/useDebounceIsPanelOpen';
import { getContactsIdsToExcludeBasedOnRole } from 'transactions/domain';
import { TransactionWrapper } from 'transactions/requests.types';
import { ValidationError } from 'transactions/transaction/transactionForm/TransactionFormStore';
import { useSplitAmount } from 'utils/hooks/useSplitAmount';
import InstructionWithError from '../components/instructionWithError';
import styles from './Buyers.module.scss';

const getErrorCopy = (validationErrors: ValidationError[]): string => {
  return validationErrors.find((error) => error === ValidationError.BuyersPaying0)
    ? 'All buyers should pay more than 0.'
    : '';
};

const Buyers = (): JSX.Element => {
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const debouncedIsPickerOpen = useDebounceIsPanelOpen(isPickerOpen);

  const { transactionFormStore } = useStores();
  const { removeBuyer, addBuyer, updateAllBuyers, validationErrors } = transactionFormStore;
  const transactionWrapper = transactionFormStore.transactionWrapper as TransactionWrapper;
  const buyerInfo = transactionWrapper.transaction.buyerInfo;

  const { amounts, onChange, onBlur, addItem, removeItem } = useSplitAmount({
    totalAmount: Big(100),
    initialAmounts: buyerInfo?.buyers.map((buyer) => Big(buyer.participationPercent || 0)) || [],
  });

  useEffect(() => {
    if (buyerInfo) {
      const updatedBuyers = buyerInfo.buyers.map((buyer, index) => {
        return { contact: buyer.contact, participationPercent: Number(amounts[index]) };
      });
      updateAllBuyers(updatedBuyers);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amounts]);

  if (!buyerInfo) return <></>;

  const handleOpenContactPicker = (): void => {
    debouncedIsPickerOpen && setIsPickerOpen(true);
  };

  const handlePickBuyer = (contact?: ContactConnection): void => {
    addBuyer(contact);
    addItem();
  };

  const handleCloseContactPicker = (): void => {
    setIsPickerOpen(false);
  };

  const handleDeleteBuyer = (buyer: Contact, index: number): void => {
    removeBuyer(buyer);
    removeItem(index);
  };

  const buyersHeader = (
    <div className={styles.header}>
      <SectionHeader title={buyerInfo.buyers.length <= 1 ? 'Buyer' : 'Buyers'} />
      <Button
        text="Add"
        iconStart={<IconComponent icon={icons.add} />}
        buttonType="tertiary"
        onClick={handleOpenContactPicker}
      />
    </div>
  );

  const buyersList = buyerInfo.buyers.length > 0 && (
    <ShareholdersList
      onChange={onChange}
      onBlur={onBlur}
      onDelete={handleDeleteBuyer}
      shareholders={buyerInfo.buyers.map((buyer) => buyer.contact)}
      amounts={amounts}
    />
  );

  const instruction = (
    <InstructionWithError
      section={'buyers'}
      showCopy={buyerInfo.buyers && buyerInfo.buyers.length === 0}
      showError={
        !!validationErrors.find((error) => error === ValidationError.MissingBuyers) ||
        !!getErrorCopy(transactionFormStore.validationErrors)
      }
      errorCopy={getErrorCopy(transactionFormStore.validationErrors)}
    />
  );

  return (
    <div className={styles.root}>
      {buyersHeader}
      {buyersList}
      {instruction}

      <ContactPicker
        title="Add a buyer"
        isOpen={isPickerOpen}
        onPickContact={handlePickBuyer}
        onPickMeAsContact={buyerInfo.buyers.find((el) => el.contact.self) ? undefined : () => handlePickBuyer()}
        onClose={handleCloseContactPicker}
        contactsToExclude={getContactsIdsToExcludeBasedOnRole(transactionWrapper)}
      />
    </div>
  );
};

export default observer(Buyers);
