import { action, computed, observable, runInAction } from 'mobx';
import { RootStore } from 'RootStore';
import { Artwork } from 'artworks/types';
import { RawBankInvoice } from 'bankPerspective/bankInvoices/requests.types';
import { RawBankTransaction } from 'bankPerspective/bankTransactions/requests.types';
import { ConsignmentItem } from 'consignments/types';
import { ContactConnection } from 'network/types';
import { ROWS_PER_PAGE_SMALLEST_OPTION } from 'theme/atoms/paginationTopbar/PaginationTopbar';
// import { shouldThisOrgSeeTheTransaction } from 'transactions/domain';
import { Invoice, TransactionWrapper } from 'transactions/requests.types';
import { AdditionalPropsValType, FetchItemsParams, FetchItemsReturns } from './types';

type Item =
  | ContactConnection
  | TransactionWrapper
  | Invoice
  | ConsignmentItem
  | Artwork
  | RawBankTransaction
  | RawBankInvoice;

export const notFilterParams = ['pageNumber', 'pageSize', 'sortBy', 'state', 'creatorOrganizationId'];

export const PaginationBasicStoreProps = {
  loading: observable,
  searchQuery: observable,
  activePage: observable,
  activePageToBeReset: observable,
  rowsPerPage: observable,
  allItemsCount: observable,
  items: observable,
  selectedItemsIds: observable,
  allPagesCount: computed,
  setSearchQuery: action,
  handleFetchItems: action,
  setActivePage: action,
  setRowsPerPage: action,
  setItems: action,
  selectOrUnselectAllItems: action,
  resetActivePage: action,
  resetPaginationData: action,
  resetItems: action,
  resetAll: action,
};

class PaginationBasicStore {
  rootStore: RootStore;
  loading = false;

  searchQuery: string | undefined = undefined;
  activePage = 1;
  activePageToBeReset = false;
  rowsPerPage = ROWS_PER_PAGE_SMALLEST_OPTION;

  allItemsCount = 0;
  items: Item[] = [];
  selectedItemsIds: string[] = [];

  shouldSortAndFilterButtonBeDisabled: boolean | null = null;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  get allPagesCount(): number {
    return Math.ceil(this.allItemsCount / this.rowsPerPage);
  }

  handleFetchItems = async (
    req: (p: FetchItemsParams) => FetchItemsReturns,
    additionalProps?: { [k: string]: AdditionalPropsValType }, // sorting, filters, and status of ContactConnection
    shouldSetNumberOfAllItemsInCategory?: boolean
  ): Promise<void> => {
    if (this.activePage < 1) {
      return;
    }

    if (this.activePageToBeReset) {
      runInAction(() => {
        this.activePage = 1;
        this.activePageToBeReset = false;
      });
    }

    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.loading = true));

    const params = {
      pageSize: this.rowsPerPage,
      pageNumber: this.activePage,
      phrase: this.searchQuery,
      ...additionalProps,
    };
    Object.keys(params).forEach((key) => {
      if (!params[key] || (Array.isArray(params[key]) && !params[key].length)) delete params[key];
    });

    return req(params)
      .then(({ data }) => {
        runInAction(() => {
          this.items = data.results || [];
          this.allItemsCount = data.count;
        });
        if (shouldSetNumberOfAllItemsInCategory) {
          this.findIfSortAndFilterButtonShouldBeDisabled(data.count, params, req);
        }
      })
      .catch(() => addToast(toastMessages.DEFAULT.ERROR, 'error'))
      .finally(() => runInAction(() => (this.loading = false)));
  };

  private findIfSortAndFilterButtonShouldBeDisabled = (
    numberFromFirstRequest: number,
    params,
    req: (p: FetchItemsParams) => FetchItemsReturns
  ): Promise<void> | void => {
    const wereUsedExtraParams = Boolean(
      Object.keys(params).find((key) => {
        return !notFilterParams.includes(key) && params[key] && params[key].length;
      })
    );

    if (numberFromFirstRequest > 1) {
      runInAction(() => (this.shouldSortAndFilterButtonBeDisabled = false));
    } else if (!wereUsedExtraParams) {
      runInAction(() => (this.shouldSortAndFilterButtonBeDisabled = true));
    } else {
      // if count ===  0 || 1 because of extra params -> check for no extra params (case for reloading with preset extra params in url)
      Object.keys(params).forEach((key) => {
        if (!notFilterParams.includes(key)) delete params[key];
      });
      return req(params).then(({ data }) => {
        runInAction(() => (this.shouldSortAndFilterButtonBeDisabled = data.count < 2));
      });
    }
  };

  setSearchQuery = (value: string | undefined): void => {
    runInAction(() => {
      this.searchQuery = value;
      // this.handleResetActivePage();
    });
  };

  setActivePage = (page: number): void => {
    runInAction(() => {
      this.activePage = page;
    });
  };

  handleResetActivePage = (): void => {
    runInAction(() => {
      this.activePageToBeReset = true;
    });
  };

  setRowsPerPage = (number: number): void => {
    runInAction(() => {
      this.rowsPerPage = number;
      // this.activePage = 1;
    });
  };

  setItems = (newList: Item[]): void => {
    runInAction(() => {
      this.items = newList;
    });
  };

  updateSelectedArtworksIds = (id: string, newValue: boolean): void => {
    runInAction(() => {
      this.selectedItemsIds = newValue ? [...this.selectedItemsIds, id] : this.selectedItemsIds.filter((a) => a !== id);
    });
  };

  selectOrUnselectAllItems = (select: boolean): void => {
    // TODO: type error
    runInAction(() => {
      this.selectedItemsIds = select ? this.items.map((a) => '') : [];
      // this.selectedItemsIds = select ? this.items.map((a) => a.id) : [];
    });
  };

  resetActivePage = (): void => {
    runInAction(() => {
      this.activePage = 1;
    });
  };

  resetPaginationData = (): void => {
    runInAction(() => {
      this.rowsPerPage = ROWS_PER_PAGE_SMALLEST_OPTION;
      this.activePage = 1;
    });
  };

  resetItems = (): void => {
    runInAction(() => {
      this.allItemsCount = 0;
      this.items = [];
      this.selectedItemsIds = [];
    });
  };

  resetAll = (): void => {
    this.resetPaginationData();
    this.resetItems();
    runInAction(() => {
      this.searchQuery = undefined;
      this.activePageToBeReset = false;
      this.shouldSortAndFilterButtonBeDisabled = null;
    });
  };
}
export default PaginationBasicStore;
