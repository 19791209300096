import styles from './TitleWhite.module.scss';

interface TitleWhiteProps {
  text?: string;
  children?: JSX.Element;
}

const TitleWhite = ({ text, children }: TitleWhiteProps): JSX.Element => {
  return <h1 className={styles.root}>{text || children}</h1>;
};

export default TitleWhite;
