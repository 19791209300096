import api from 'api';

export const authorApproveArtwork = (id: string, artworkLastModifiedAt: Date): Promise<void> => {
  return api.post(`/artworks/${id}/author-approve`, { artworkLastModifiedAt });
};

export const authorRejectArtwork = (id: string, artworkLastModifiedAt: Date): Promise<void> => {
  return api.post(`/artworks/${id}/author-reject`, { artworkLastModifiedAt });
};

export const ownerApproveArtwork = (id: string, artworkLastModifiedAt: Date): Promise<void> => {
  return api.post(`/artworks/${id}/owner-approve`, { artworkLastModifiedAt });
};

export const ownerRejectArtwork = (id: string, artworkLastModifiedAt: Date): Promise<void> => {
  return api.post(`/artworks/${id}/owner-reject`, { artworkLastModifiedAt });
};
