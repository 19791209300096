import styles from './InstructionWithError.module.scss';

const instructionCopy = {
  buyers: 'Add at least one buyer',
  buyerRepresentative: "Add a person responsible for the transaction from the buyer's side",
  splitCommission: 'You can add people with whom you will share your commission',
};

interface InstructionWithErrorProps {
  section: 'buyers' | 'buyerRepresentative' | 'splitCommission';
  showCopy: boolean;
  showError: boolean;
  errorCopy?: string;
}

const InstructionWithError = ({ section, showCopy, showError, errorCopy }: InstructionWithErrorProps): JSX.Element => {
  return (
    <div className={styles.root}>
      {showCopy && instructionCopy[section]}
      {showError && <span className={styles.error}> {errorCopy || '(required)'}</span>}
    </div>
  );
};

export default InstructionWithError;
