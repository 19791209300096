import { DEFAULT_NETWORK_CATEGORY } from 'network/networkList/constants';
import { ReactComponent as CloseIcon } from 'theme/assets/svg/close.svg';
import { ReactComponent as EditIcon } from 'theme/assets/svg/pencil.svg';
import ActionButtons, { ActionButton } from 'theme/atoms/ActionButtons';
import Breadcrumbs, { Breadcrumb } from 'theme/atoms/Breadcrumbs';
import Topbar from 'theme/atoms/Topbar';

const BREADCRUMBS: Breadcrumb[] = [
  { label: 'Network', route: 'Network', param: DEFAULT_NETWORK_CATEGORY },
  { label: 'User Profile' },
];

interface ContactProfileTopbarProps {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsPanelOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ContactProfileTopbar = ({ setIsModalOpen, setIsPanelOpen }: ContactProfileTopbarProps): JSX.Element => {
  const actionButtons: ActionButton[] = [
    {
      text: 'Edit',
      icon: <EditIcon />,
      onClick: () => setIsPanelOpen(true),
    },
    {
      text: 'Remove member',
      icon: <CloseIcon />,
      onClick: () => setIsModalOpen(true),
    },
  ];

  return (
    <Topbar>
      <Breadcrumbs breadcrumbs={BREADCRUMBS} />
      <ActionButtons buttons={actionButtons} />
    </Topbar>
  );
};

export default ContactProfileTopbar;
