import { ReactNode } from 'react';
import clsx from 'clsx';
import styles from './Checkbox.module.scss';

interface CheckboxProps {
  checked: boolean;
  error?: string;
  iconType?: 'primary' | 'secondary';
  label?: ReactNode;
  labelClassName?: string;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Checkbox = ({
  checked,
  error = '',
  iconType = 'primary',
  label,
  labelClassName = '',
  name,
  onChange,
}: CheckboxProps): JSX.Element => {
  return (
    <label className={styles.root}>
      <input
        id={name}
        type="checkbox"
        className={clsx(styles.input, styles[iconType])}
        checked={checked}
        onChange={onChange}
      />
      {label && (
        <div className={clsx(styles.labelText, labelClassName)}>
          {label}
          {error && <span className={styles.error}>{error}</span>}
        </div>
      )}
    </label>
  );
};

export default Checkbox;
