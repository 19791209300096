import { makeAutoObservable, runInAction } from 'mobx';
import { RootStore } from 'RootStore';
import Big from 'big.js';
import {
  Consignment,
  ConsignmentBase,
  ConsignmentFormType,
  ConsignmentShowArtwork,
  NewConsignmentRequest,
} from 'consignments/types';
import { CURRENCIES } from 'domain/constants';
import { getMessageFromApiErrorResponse } from 'domain/getMessageFromApiErrorResponse';
import { Currency } from 'domain/types/Domain.types';
import { ContactConnection } from 'network/types';
import * as requests from '../requests';

const INIT_CONSIGNMENT: ConsignmentFormType = {
  artworkId: '',
  contact: null,
  sellArtwork: false,
  expireAt: undefined,
  showArtwork: 'NonExclusive',
  askingPrice: null,
  salesRestrictions: '',
  maxCommission: '',
  currency: CURRENCIES[0],
  consignmentLastModifiedAt: new Date(),
};

class ConsignmentFormStore {
  rootStore: RootStore;
  submitting = false;
  consignmentForm: ConsignmentFormType = INIT_CONSIGNMENT;
  consignmentFormIsOpen = false;
  consigneePickerIsOpen = false;
  consignmentId: string | null = null;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  get askingPriceWithCommissions(): Big {
    return Big(this.consignmentForm.askingPrice || 0).plus(this.getMaxCommissionAmount());
  }

  get isEditView(): boolean {
    return Boolean(this.consignmentId);
  }

  saveConsignment = (): void => {
    const consignment: ConsignmentBase = {
      showArtwork: this.consignmentForm.showArtwork,
      sellArtwork: this.consignmentForm.sellArtwork,
      salesRestrictions: this.consignmentForm.salesRestrictions,
      askingPrice: Big(this.consignmentForm.askingPrice || 0),
      currency: this.consignmentForm.currency,
      expireAt: this.consignmentForm.expireAt,
      maxCommission: this.getMaxCommissionAmount(),
      consignmentLastModifiedAt: this.consignmentForm.consignmentLastModifiedAt,
    };

    if (this.isEditView) {
      this.updateConsignment(consignment, this.consignmentForm.artworkId);
    } else {
      const newConsignment: NewConsignmentRequest = {
        ...consignment,
        artworkId: this.consignmentForm.artworkId,
        contactId: this.consignmentForm.contact?.contactConnectionId,
      } as NewConsignmentRequest;
      this.createConsignment(newConsignment);
    }
  };

  getMaxCommissionAmount = (): Big => {
    if (this.consignmentForm.maxCommission.includes('%')) {
      const maxCommissionAsNumber = Number(this.consignmentForm.maxCommission.replace('%', ''));
      const maxCommission = Big(maxCommissionAsNumber)
        .times(this.consignmentForm.askingPrice || 0)
        .div(100);
      return maxCommission;
    } else {
      return Big(this.consignmentForm.maxCommission || 0);
    }
  };

  initializeCreatingNewConsignment = (contact: ContactConnection, artworkId: string): void => {
    this.consignmentForm = {
      ...INIT_CONSIGNMENT,
      contact: {
        organizationName: contact.contactOrganizationName,
        organizationTitle: contact.contactOrganizationTitle,
        organizationAddress: contact.contactOrganizationAddress,
        contactConnectionId: contact.id,
      },
      artworkId: artworkId,
    };
    this.setConsignmentFormIsOpen(true);
  };

  startEditingConsignment = (consignment: Consignment, artworkId: string): void => {
    this.consignmentForm = {
      artworkId: artworkId,
      sellArtwork: consignment.sellArtwork,
      expireAt: consignment.expireAt,
      showArtwork: consignment.showArtwork,
      askingPrice: consignment.askingPrice,
      salesRestrictions: consignment.salesRestrictions,
      maxCommission: consignment.maxCommission.toString() || '',
      currency: consignment.currency,
      contact: consignment.consigneeContact,
      consignmentLastModifiedAt: consignment.modifiedAt,
    };
    this.consignmentId = consignment.id;
    this.rootStore.consignmentStore.setIsPanelOpen(false);
    this.setConsignmentFormIsOpen(true);
  };

  setConsignmentFormIsOpen = (isOpen: boolean): void => {
    this.consignmentFormIsOpen = isOpen;
  };

  setConsigneePickerIsOpen = (isOpen: boolean): void => {
    this.consigneePickerIsOpen = isOpen;
  };

  resetForm = (): void => {
    this.consignmentForm = INIT_CONSIGNMENT;
    this.setConsignmentFormIsOpen(false);
    this.consignmentId = null;
  };

  setSellArtwork = (sellArtwork: boolean): void => {
    this.updateForm({ sellArtwork: sellArtwork });
  };

  setExpireAt = (date: string): void => {
    if (date) {
      const endDate = new Date(date).setHours(23, 59, 59, 999);
      this.updateForm({ expireAt: new Date(endDate) });
    } else {
      this.updateForm({ expireAt: undefined });
    }
  };

  setShowArtwork = (showArtwork: keyof typeof ConsignmentShowArtwork): void => {
    this.updateForm({ showArtwork: showArtwork });
  };

  setAskingPrice = (amount: Big | null): void => {
    this.updateForm({ askingPrice: amount ? Big(amount) : null });
  };

  setSalesRestrictions = (text: string): void => {
    this.updateForm({ salesRestrictions: text });
  };

  setMaxCommission = (commission: string): void => {
    this.updateForm({ maxCommission: commission });
  };

  setCurrency = (currency: Currency): void => {
    this.consignmentForm = {
      ...this.consignmentForm,
      currency: currency,
    };
  };

  createConsignment = (consignment: NewConsignmentRequest): Promise<void> | void => {
    const { addToast } = this.rootStore.toastsStore;
    runInAction(() => (this.submitting = true));

    return requests
      .createConsignment(consignment)
      .then(({ data }) => {
        addToast('Consignment created successfully.', 'success');
        this.setConsignmentFormIsOpen(false);
        this.rootStore.artworkStore.fetchArtworkData(consignment.artworkId);
        this.consignmentForm.consignmentLastModifiedAt = data.modifiedAt;
      })
      .catch((err) => {
        this.rootStore.userStore.checkIfUserHasNoPaymentMethods(err.response?.data.type);
        addToast(getMessageFromApiErrorResponse(err.response?.data.type), 'error');
      })
      .finally(() => runInAction(() => (this.submitting = false)));
  };

  updateConsignment = (consignment: ConsignmentBase, artworkId: string): Promise<void> | void => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.submitting = true));

    if (!this.consignmentId) {
      addToast(toastMessages.DEFAULT.ERROR, 'error');
      return;
    }

    return requests
      .updateConsignment(this.consignmentId, consignment)
      .then(({ data }) => {
        addToast('Consignment saved successfully.', 'success');
        this.setConsignmentFormIsOpen(false);
        this.rootStore.artworkStore.fetchArtworkData(artworkId);
        this.consignmentForm.consignmentLastModifiedAt = data.modifiedAt;
      })
      .catch((error) => {
        if (error && error.response.status === 409) {
          addToast('You cannot change this consignment', 'error');
          this.setConsignmentFormIsOpen(false);
        } else {
          addToast(toastMessages.DEFAULT.ERROR, 'error');
        }
      })
      .finally(() => runInAction(() => (this.submitting = false)));
  };

  withdrawConsignment = (): Promise<void> | void => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.submitting = true));

    if (!this.consignmentId) {
      addToast(toastMessages.DEFAULT.ERROR, 'error');
      return;
    }

    return requests
      .deleteConsignment(this.consignmentId, this.consignmentForm.consignmentLastModifiedAt)
      .then(() => {
        addToast('Consignment withdrawn successfully.', 'success');
        this.setConsignmentFormIsOpen(false);
        this.rootStore.artworkStore.fetchArtworkData(this.consignmentForm.artworkId);
      })
      .catch(() => addToast(toastMessages.DEFAULT.ERROR, 'error'))
      .finally(() => runInAction(() => (this.submitting = false)));
  };

  private updateForm = (dataToUpdate: Partial<ConsignmentFormType>): void => {
    this.consignmentForm = {
      ...this.consignmentForm,
      ...dataToUpdate,
    };
  };

  resetAll = (): void => {
    runInAction(() => {
      this.consignmentForm = INIT_CONSIGNMENT;
      this.consignmentFormIsOpen = false;
      this.consigneePickerIsOpen = false;
      this.consignmentId = null;
    });
  };
}

export default ConsignmentFormStore;
