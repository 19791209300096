import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { RootStore } from 'RootStore';
import SortAndFilterBasicStore, {
  SortAndFilterBasicStoreProps as BasicStoreProps,
} from 'domain/SortAndFilterBasicStore/SortAndFilterBasicStore';
import { FilterItemType } from 'domain/SortAndFilterBasicStore/types';
import { TransactionStatus } from 'domain/types/Transactions.types';
import { getTimeItemValue } from 'domain/utils';
import * as requests from '../../requests';
import { TRANSACTION_CATEGORIES_WITH_STATUSES, TransactionCounts, TransactionSide } from '../types';
import { DEFAULT_SORTING_ITEM } from './constants';
import { FilterParams } from './types';

const ExtendedStoreProps = {
  counts: observable,
  filterByAsParams: action,
  fetchGroupCounts: action,
  resetActivePage: action,
  transactionCategoryStatuses: computed,
  resetAll: action,
};

class TransactionsSortAndFilterStore extends SortAndFilterBasicStore {
  rootStore: RootStore;

  counts: TransactionCounts | null = null;

  constructor(rootStore: RootStore) {
    super(rootStore);
    this.rootStore = rootStore;
    makeObservable(this, { ...BasicStoreProps, ...ExtendedStoreProps });

    this.defaultSortingItems = [DEFAULT_SORTING_ITEM];
    this.selectedSortingItems = this.defaultSortingItems;
    this.confirmedSortingItems = this.defaultSortingItems;
  }

  resetActivePage = (): void => {
    this.rootStore.transactionsStore.handleResetActivePage();
  };

  private calculateStatuses = (filteringItems: FilterItemType[]): TransactionStatus[] => {
    const statusesClickedInPanel = filteringItems.filter((el) => el.key === 'status');
    if (!statusesClickedInPanel.length) {
      return this.transactionCategoryStatuses as TransactionStatus[];
    } else {
      return this.transactionCategoryStatuses.filter((status) =>
        statusesClickedInPanel.some((el) => el.value === status)
      );
    }
  };

  filterByAsParams = (filteringItems: FilterItemType[], takeOnlyClickedByUser?: boolean): FilterParams => {
    const filterParams = {
      transactionSide: filteringItems
        .filter((el) => el.key === 'transactionSide')
        .map((el) => {
          const transactionSide = Object.keys(TransactionSide).find((k) => k === el.value);
          return (transactionSide as TransactionSide) || '';
        }),
      representativeContactConnectionId: filteringItems
        .filter((el) => el.key === 'representativeContactConnectionId')
        .map((el) => el.value as string),
      status: takeOnlyClickedByUser
        ? filteringItems
            .filter((el) => el.key === 'status')
            .map((el) => {
              const status = Object.keys(TransactionStatus).find((k) => k === el.value);
              return (status as TransactionStatus) || '';
            })
        : this.calculateStatuses(filteringItems),
      createdAfter: getTimeItemValue(filteringItems, 'createdAfter'),
      createdBefore: getTimeItemValue(filteringItems, 'createdBefore'),
    };
    Object.keys(filterParams).forEach((key) => {
      if (!filterParams[key]) delete filterParams[key];
    });
    return filterParams;
  };

  fetchGroupCounts = (): Promise<void> => {
    return requests.fetchGroupCounts(this.transactionCategoryStatuses as TransactionStatus[]).then(({ data }) => {
      runInAction(() => {
        this.counts = data;
      });
    });
  };

  get transactionCategoryStatuses(): readonly TransactionStatus[] {
    const categoryWithStatus = TRANSACTION_CATEGORIES_WITH_STATUSES.find((category) => category.id === this.categoryId);

    return categoryWithStatus?.statuses || [];
  }

  resetAll = (): void => {
    runInAction(() => {
      this.counts = null;
    });
  };
}

export default TransactionsSortAndFilterStore;
