import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { routes } from 'app/routes/paths.const';
import { ArtworkFieldsIdType } from 'artworks/artworksComponents/viewOptions/types';
import { getLayoutSizeClass } from 'artworks/artworksDisplaying/utils';
import { Artwork } from 'artworks/types';
// import { ReactComponent as Edit } from 'theme/assets/svg/edit.svg';
// import { ReactComponent as EmptyHeart } from 'theme/assets/svg/emptyHeart.svg';
// import { ReactComponent as FullHeart } from 'theme/assets/svg/fullHeart.svg';
// import { ReactComponent as Share } from 'theme/assets/svg/share.svg';
// import Checkbox from 'theme/atoms/Checkbox';
// import IconButton from 'theme/atoms/iconButton';
import ArtworkDetailItem from '../artworkDetailItem/ArtworkDetailItem';
import styles from './Grid.module.scss';

interface GridProps {
  size: number;
  artworksToDisplay: Artwork[];
  updateSelectedArtworksIds: (id: string, value: boolean) => void;
  selectedArtworksIds: string[];
  favouriteHandler: (id: string, value: boolean) => void;
  checkedFieldsIds: (ArtworkFieldsIdType | [ArtworkFieldsIdType, ArtworkFieldsIdType])[];
}

export const Grid = observer(
  ({
    size,
    artworksToDisplay,
    updateSelectedArtworksIds,
    selectedArtworksIds,
    favouriteHandler,
    checkedFieldsIds,
  }: GridProps): JSX.Element => {
    const navigate = useNavigate();

    const handleTileClick = (artworkId: string): void => {
      navigate(routes.Artwork.path(artworkId));
    };

    return (
      <div className={clsx(styles.root, styles[getLayoutSizeClass(size)])}>
        {artworksToDisplay.map((artwork) => {
          const checked = selectedArtworksIds.includes(artwork.id);
          return (
            <div
              className={clsx(styles.tile, { [styles.isSelected]: checked })}
              key={artwork.id}
              onClick={() => handleTileClick(artwork.id)}
            >
              <div className={styles.content}>
                {checkedFieldsIds.map((fieldIds) => {
                  const fieldId = Array.isArray(fieldIds) ? fieldIds[0] : fieldIds;
                  const childId = Array.isArray(fieldIds) ? fieldIds[1] : null;

                  return (
                    <Fragment key={fieldId}>
                      <ArtworkDetailItem size={size} layout="grid" fieldId={fieldId} artwork={artwork} />
                      {childId ? (
                        <ArtworkDetailItem size={size} layout="grid" fieldId={childId} artwork={artwork} />
                      ) : null}
                    </Fragment>
                  );
                })}
              </div>
              {/* <div className={styles.hiddenElementsWrapper}> // TODO uncomment when ready
                <div className={styles.hiddenElements}>
                  <Checkbox
                    name="single"
                    checked={checked}
                    onChange={(e) => updateSelectedArtworksIds(artwork.id, e.target.checked)}
                    iconType="primary"
                  />
                  <div className={styles.actionBtns}>
                    <IconButton icon={Share} onClick={() => console.log('click share')} />
                    <IconButton icon={Edit} onClick={() => console.log('click edit')} />
                    <IconButton
                      icon={artwork.favourite ? FullHeart : EmptyHeart}
                      onClick={() => favouriteHandler(artwork.id, artwork.favourite)}
                    />
                  </div>
                </div>
              </div> */}
            </div>
          );
        })}
      </div>
    );
  }
);
