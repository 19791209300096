import { useStores } from 'RootStore';
import { Button } from 'theme/atoms';
import Loader from 'theme/atoms/loader';
import styles from './RecoveryCodesPanel.module.scss';

const Column = ({ splitCodeArray }: { splitCodeArray: string[] }): React.JSX.Element => {
  return (
    <div>
      {splitCodeArray.map((splitCode) => (
        <div key={splitCode} className={styles.code}>
          {splitCode}
        </div>
      ))}
    </div>
  );
};

const Block = ({ codeBlock }: { codeBlock: string[] }): React.JSX.Element => {
  const left: string[] = [];
  const right: string[] = [];

  codeBlock.forEach((code) => {
    const codeLengthHalfPoint = Math.ceil(code.length / 2);
    left.push(code.slice(0, codeLengthHalfPoint));
    right.push(code.slice(codeLengthHalfPoint, code.length));
  });

  return (
    <div className={styles.block}>
      <Column splitCodeArray={left} />
      <Column splitCodeArray={right} />
    </div>
  );
};

interface RecoveryCodesProps {
  codes: string[] | null;
}

const RecoveryCodes = ({ codes }: RecoveryCodesProps): React.JSX.Element => {
  const {
    toastsStore: { addToast },
  } = useStores();

  const codesUnavailable = !codes?.length;

  const codesLengthHalfPoint = codes && Math.ceil(codes.length / 2);

  const codeBlocks =
    codes && codesLengthHalfPoint
      ? [codes.slice(0, codesLengthHalfPoint), codes.slice(codesLengthHalfPoint, codes.length)]
      : [];

  const handleCopy = (): void => {
    navigator.clipboard.writeText((codes || []).join('\n'));
    addToast('Codes copied to clipboard', 'success');
  };

  return (
    <div className={styles.root}>
      {codesUnavailable && <Loader minHeight={160} />}
      <div className={styles.blocks}>
        {codeBlocks.map((codeBlock, i) => (
          <Block key={codeBlock[0] + i} codeBlock={codeBlock} />
        ))}
      </div>
      <div className={styles.buttons}>
        {/* <Button type="button" buttonType="secondary" text="Download" maxWidth onClick={onDownload} /> */}
        {/* <Button type="button" buttonType="secondary" text="Print" maxWidth onClick={onPrint} /> */}
        <Button
          type="button"
          buttonType="primary"
          text="Copy"
          maxWidth
          onClick={handleCopy}
          disabled={codesUnavailable}
        />
      </div>
    </div>
  );
};

export default RecoveryCodes;
