import { makeAutoObservable, runInAction } from 'mobx';
import { RootStore } from 'RootStore';
import { getMessageFromApiErrorResponse } from 'domain/getMessageFromApiErrorResponse';
import * as requests from './requests';
import { Invoice, PaymentMethod, ProductsPerUnit } from './types';

class BillingStore {
  rootStore: RootStore;
  paymentMethods: PaymentMethod[] | null = null;
  recentInvoices: Invoice[] | null = null;
  upcomingInvoices: Invoice | null = null;
  portalUrl: string | null = null;
  products: ProductsPerUnit | undefined = undefined;

  loading = false;
  loadingInvoices = true;
  loadingProducts = false;
  loadedProducts = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  get isPaymentMethodActive(): boolean {
    return Boolean(this.paymentMethods !== null && this.paymentMethods.length > 0);
  }

  fetchPaymentMethods = (dontShowError?: boolean): Promise<void | boolean> => {
    const { addToast } = this.rootStore.toastsStore;
    runInAction(() => (this.loading = true));

    return requests
      .fetchPaymentMethods()
      .then(({ data }) => {
        runInAction(() => (this.paymentMethods = data));
        this.rootStore.userStore.setNoPaymentMethods(!data.length);
        return Boolean(data !== null && data.length > 0);
      })
      .catch((err) => {
        if (!dontShowError) {
          addToast(getMessageFromApiErrorResponse(err.response?.data.type), 'error');
        }
      })
      .finally(() => runInAction(() => (this.loading = false)));
  };

  openPortal = (): Promise<void | string> => {
    const { addToast } = this.rootStore.toastsStore;
    runInAction(() => (this.loading = true));

    return requests
      .createSubscriptionPortal()
      .then(({ data }) => {
        this.portalUrl = data.url;
        return data.url;
      })
      .catch((err) => addToast(getMessageFromApiErrorResponse(err.response?.data.type), 'error'))
      .finally(() => runInAction(() => (this.loading = false)));
  };

  fetchInvoices = (): Promise<void> => {
    const { addToast } = this.rootStore.toastsStore;
    runInAction(() => (this.loadingInvoices = true));

    return requests
      .fetchInvoices()
      .then(({ data }) => {
        runInAction(() => {
          this.recentInvoices = data.recentInvoices;
          this.upcomingInvoices = data.upcomingInvoices;
        });
      })
      .catch((err) => addToast(getMessageFromApiErrorResponse(err.response?.data.type), 'error'))
      .finally(() => runInAction(() => (this.loadingInvoices = false)));
  };

  fetchProducts = (showError?: boolean): Promise<void> => {
    const { addToast } = this.rootStore.toastsStore;
    runInAction(() => (this.loadingProducts = true));

    return requests
      .fetchProducts()
      .then(({ data }) => {
        runInAction(() => {
          this.products = data
            .filter((el) => el.item.price.billing_scheme === 'per_unit')
            .reduce((accumulator, currentValue) => {
              return {
                ...accumulator,
                [currentValue.product]: {
                  amount: currentValue.item.price.unit_amount,
                  currency: currentValue.item.price.currency,
                },
              };
            }, {});
        });
      })
      .catch((err) => {
        if (showError) addToast(getMessageFromApiErrorResponse(err.response?.data.type), 'error');
      })
      .finally(() =>
        runInAction(() => {
          this.loadingProducts = false;
          this.loadedProducts = true;
        })
      );
  };

  resetAll = (): void => {
    runInAction(() => {
      this.paymentMethods = null;
      this.recentInvoices = null;
      this.upcomingInvoices = null;
      this.portalUrl = null;
      this.products = undefined;
    });
  };
}

export default BillingStore;
