import { formValidationMessages } from 'domain/formValidationMessages';
import { toastMessages } from 'toasts/toastMessages';

const ERROR_MESSAGES = {
  'login-by-token': {
    'invalid-credentials': 'The link you are using is invalid.',
    'user-or-login-token-not-found': 'The link you are using is no longer valid.',
    'token-expired': 'The link you are using is no longer valid.',
  },
  'password-set': {
    'password-not-changed': formValidationMessages.NEW_PASSWORD_MUST_DIFFER_FROM_OLD_ONE,
    'invalid-password': formValidationMessages.PASSWORD_INVALID,
  },
  'user-create': {
    'invalid-password': formValidationMessages.PASSWORD_INVALID,
  },
  'consignment-create': {
    'conflicting-consignment': 'Another consignment cannot be created for the same consignee.',
  },
  'organization-invoice-list': {
    'no-access-to-organization': 'Failed to fetch invoices due to insufficient permissions.',
    'organization-not-found': 'Invoices not available. Failed to locate organization information.',
  },
  'organization-billing-portal-session': {
    'no-access-to-organization': 'Permission denied. You are unable to perform this action.',
    'organization-not-found': 'Unable to access payment method editing.',
  },
  'organization-payment-method-list': {
    'no-access-to-organization': 'Failed to fetch payment method status due to insufficient permissions.',
    'organization-not-found': 'Unable to retrieve payment method status information.',
  },
  'user-identity-create': {
    'identity-value-already-exist': 'The phone number is associated with another account.',
  },
  'bank-transaction-bank-account-escrow-get': {
    'transaction-not-found-or-bank-account-not-defined': toastMessages.BANK_TRANSACTIONS.ACCOUNT_DOESNT_EXIST,
  },
  'organization-bank-account-get': {
    'organization-not-found-or-bank-account-not-defined': toastMessages.ORGANIZATIONS.ACCOUNT_DOESNT_EXIST,
  },
};

export const DEFAULT_ERROR_MESSAGE = toastMessages.DEFAULT.ERROR;

export const getMessageFromApiErrorResponse = (response: string, alternativeDefaultErrorMessage?: string): string => {
  // response can be either `result:${operation}:${problem}` or `rejection:${problem}` or `error:${problem}`;
  // result is for wrong user action like unauthorised access; rejection is for FE bug like missing mandatory field; error is for internal server error 5xx
  const defaultErrorMessage = alternativeDefaultErrorMessage || DEFAULT_ERROR_MESSAGE;

  if (!response) return defaultErrorMessage;

  const responseElements = response.split(':');
  const kind = responseElements[0] as 'error' | 'rejection' | 'result';
  const operation = responseElements[1];
  const problem = responseElements[2];
  if (kind === 'error' || !operation || !problem) {
    return defaultErrorMessage;
  }
  return ERROR_MESSAGES[operation]?.[problem] || defaultErrorMessage;
};
