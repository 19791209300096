import { AxiosResponse } from 'axios';
import api from 'api';
import { ListResult } from 'api/types';
import { Artwork } from 'artworks/types';
import { BankAccountResult } from 'domain/types/BankAccount.types';
import { TransactionId, TransactionStatus } from 'domain/types/Transactions.types';
import {
  TransactionCreateRequest,
  TransactionWrapper,
  TransactionUpdateRequest,
  Transaction,
  Invoice,
} from './requests.types';
import { TransactionCountsReturn } from './transactions/types';

export interface FetchTransactionsParams {
  id?: string;
  pageSize?: number;
  pageNumber?: number;
}

export interface FetchTransactionsByArtworkParams {
  artworkId?: string;
  status: TransactionStatus[];
}

export const fetchTransactions = (
  params: FetchTransactionsParams | FetchTransactionsByArtworkParams
): ListResult<TransactionWrapper> => {
  return api.get('/transactions', { params });
};

export const fetchTransaction = (transactionId: TransactionId): ListResult<TransactionWrapper> => {
  return api.get(`/transactions`, { params: { id: transactionId } });
};

export const fetchTransactionArtwork = (transactionId: TransactionId): Promise<AxiosResponse<Artwork>> => {
  return api.get(`/transactions/${transactionId}/artwork-view`);
};

export const createTransaction = (transaction: TransactionCreateRequest): Promise<Transaction> => {
  return api.post(`/transactions`, transaction);
};

export const patchTransaction = (
  transactionId: TransactionId,
  transaction: TransactionUpdateRequest
): Promise<Transaction> => {
  return api.patch(`/transactions/${transactionId}`, transaction);
};

export const buyerAgrees = (transactionId: string, transactionLastModifiedAt: Date): Promise<Transaction> => {
  return api.post(`/transactions/${transactionId}/buyer-agree`, { transactionLastModifiedAt });
};

export const buyerDisagrees = (transactionId: string, transactionLastModifiedAt: Date): Promise<Transaction> => {
  return api.post(`/transactions/${transactionId}/buyer-disagree`, { transactionLastModifiedAt });
};

export const ownerAgrees = (transactionId: string, transactionLastModifiedAt: Date): Promise<Transaction> => {
  return api.post(`/transactions/${transactionId}/owner-agree`, { transactionLastModifiedAt });
};

export const ownerDisagrees = (transactionId: string, transactionLastModifiedAt: Date): Promise<Transaction> => {
  return api.post(`/transactions/${transactionId}/owner-disagree`, { transactionLastModifiedAt });
};

export const buyerRepresentativeDisagrees = (
  transactionId: string,
  transactionLastModifiedAt: Date
): Promise<Transaction> => {
  return api.post(`/transactions/${transactionId}/buyer-representative-disagree`, { transactionLastModifiedAt });
};

export const sellerRepresentativeDisagrees = (
  transactionId: string,
  transactionLastModifiedAt: Date
): Promise<Transaction> => {
  return api.post(`/transactions/${transactionId}/seller-representative-disagree`, { transactionLastModifiedAt });
};

export const fetchGroupCounts = (status: TransactionStatus[]): TransactionCountsReturn => {
  const params = { status };
  return api.get('/transactions/group-counts', { params });
};

export const fetchInvoices = (params: FetchTransactionsParams): ListResult<Invoice> => {
  return api.get('/invoices', { params });
};

export const fetchInvoice = (invoiceId: string): ListResult<Invoice> => {
  return api.get('/invoices', { params: { id: invoiceId } });
};

export const updateInvoiceUrl = (invoiceId: string, fileUrl: string | null): Promise<void> => {
  return api.patch(`/invoices/${invoiceId}`, { fileUrl });
};

export const fetchEscrowAccountDetails = (transactionId: string): Promise<AxiosResponse<BankAccountResult>> => {
  return api.get(`/transactions/${transactionId}/escrow-account`);
};
