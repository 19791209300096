import { useStores } from 'RootStore';
import { OrganizationKind } from 'organization/types';

type UseOrganizationKindsReturn = {
  isIndividual: boolean;
  isInstitutional: boolean;
  isBank: boolean;
};

export const useOrganizationKinds = (): UseOrganizationKindsReturn => {
  const { organizationStore } = useStores();
  const { selectedOrganization } = organizationStore;
  const isIndividual = selectedOrganization?.type === OrganizationKind.Individual;
  const isInstitutional = selectedOrganization?.type === OrganizationKind.Institutional;
  const isBank = selectedOrganization?.type === OrganizationKind.Bank;

  return {
    isIndividual,
    isInstitutional,
    isBank,
  };
};
