import { FormEvent, useState } from 'react';
import styles from 'auth/Auth.module.scss';
import { formValidationMessages } from 'domain/formValidationMessages';
import { RECOVERY_CODE_REGEX } from 'domain/regex';
import { Button, Input } from 'theme/atoms';
import { useZodFormValidation } from 'utils/hooks';
import { z } from 'zod';

const recoveryCodeSchema = z.object({
  recoveryCode: z
    .string()
    .min(1, { message: formValidationMessages.RECOVERY_CODE_REQUIRED })
    .regex(RECOVERY_CODE_REGEX, { message: formValidationMessages.RECOVERY_CODE_INVALID }),
});

interface RecoveryCodeFormProps {
  onSubmit: (recoveryCode: string) => void;
}

const RecoveryCodeForm = ({ onSubmit }: RecoveryCodeFormProps): React.JSX.Element => {
  const [recoveryCode, setRecoveryCode] = useState<string>('');
  const { validateData, errors, clearErrors } = useZodFormValidation(recoveryCodeSchema, { recoveryCode });

  const handleChange = (value: string): void => {
    clearErrors();
    setRecoveryCode(value);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    if (validateData()) {
      setRecoveryCode('');
      onSubmit(recoveryCode);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Input
        type="number"
        name="verificationCode"
        label="Recovery code"
        placeholder="Enter recovery code"
        value={recoveryCode}
        onChange={(e) => handleChange(e.target.value)}
        error={errors.recoveryCode && errors.recoveryCode[0]}
        className={styles.authAppCodeInput}
        autoFocus
      />
      <Button type="submit" text="Continue" />
    </form>
  );
};

export default RecoveryCodeForm;
