import { BuyerInfo, SellerInfo } from 'bankPerspective/bankTransactions/requests.types';
import { ORGANIZATION_DATA_UNKNOWN } from 'domain/constants';
import { ContactBasicInfo } from 'domain/types/Contact.types';
import InfoCardDetailsList from 'theme/atoms/card/infoCardDetailsList';
import InfoCard from 'theme/atoms/infoCard';
import { extractAddressInfo } from 'utils/helpers';

interface AddressInfoCardProps {
  className?: string;
  data: ContactBasicInfo | BuyerInfo | SellerInfo | undefined;
  label: string;
  organizationId?: string;
}

const AddressInfoCard = ({ label, data, className, organizationId = '' }: AddressInfoCardProps): JSX.Element => {
  if (!data) return <></>;

  const { organizationName, organizationAddress } = extractAddressInfo(data);

  const addressFieldsMap = {
    'Company name': { value: organizationName ?? ORGANIZATION_DATA_UNKNOWN },
    Address: { value: organizationAddress?.addressLine ?? ORGANIZATION_DATA_UNKNOWN },
    City: { value: organizationAddress?.city ?? ORGANIZATION_DATA_UNKNOWN },
    Country: { value: organizationAddress?.country ?? ORGANIZATION_DATA_UNKNOWN },
  };

  const detailsElement = <InfoCardDetailsList fieldsMap={addressFieldsMap} />;

  return (
    <InfoCard
      organizationId={organizationId}
      className={className}
      details={detailsElement}
      label={label}
      labelWithSiteAvatar={organizationName}
    />
  );
};

export default AddressInfoCard;
