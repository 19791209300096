import clsx from 'clsx';
import styles from './Spinner.module.scss';
import { ReactComponent as SpinnerIcon } from './spinner.svg';

interface SpinnerProps {
  className?: string;
  small?: boolean;
  white?: boolean;
  blue?: boolean;
}

function Spinner({ className = '', small, white, blue }: SpinnerProps): JSX.Element {
  return (
    <SpinnerIcon
      className={clsx(
        `${className}`,
        white && styles.white,
        blue && styles.blue,
        small && styles.small,
        styles.spinner
      )}
    />
  );
}

export default Spinner;
