import { ReactNode } from 'react';
import { Artwork } from 'artworks/types';
import Chip from '../chip';
import styles from './ArtworkSummary.module.scss';

interface ArtworkSummaryProps {
  title: string;
  artwork: Artwork;
  chipLabel?: string;
  from?: ReactNode;
}

const ArtworkSummary = ({ title, artwork, chipLabel, from = null }: ArtworkSummaryProps): JSX.Element => {
  const chipRender = chipLabel && <Chip>{chipLabel}</Chip>;
  const fromRender = from && (
    <div className={styles.from}>
      From:
      {from}
    </div>
  );

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div>
          <div className={styles.header}>
            <h1 className={styles.title}>{title}</h1>
            {chipRender}
          </div>
          {fromRender}
        </div>

        <div className={styles.description}>
          <h2 className={styles.author}>{artwork.author?.name}</h2>
          <div className={styles.nameYear}>
            <span className={styles.name}>{artwork.name}</span>, {artwork.year}
          </div>
          <div className={styles.details}>
            <p>{artwork.medium}</p>
            <p>{artwork.size}</p>
            <p>Edition {artwork.editionNumber}</p>
          </div>
          <div className={styles.id}>{artwork.id}</div>
        </div>
      </div>
      {artwork.assets.imageUrls?.[0]?.presignedUrl ? (
        <img src={artwork.assets.imageUrls?.[0]?.presignedUrl} alt={artwork.name} className={styles.image} />
      ) : null}
    </div>
  );
};

export default ArtworkSummary;
