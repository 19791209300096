import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { CategoryId, SortItemType } from 'domain/SortAndFilterBasicStore/types';
import { TransactionStatus } from 'domain/types/Transactions.types';
import { addSpaceBeforeUppercase } from 'domain/utils';
import { ReactComponent as AddIcon } from 'theme/assets/svg/add.svg';
import { Button } from 'theme/atoms';
import Accordion from 'theme/atoms/Accordion';
import DatePickerRange from 'theme/atoms/datePickerRange';
import { Kind, KindDates, RangeEndMax, RangeEndMin } from 'theme/atoms/datePickerRange/types';
import FilterChecklist from 'theme/atoms/filterChecklist';
import SlidingPanel from 'theme/atoms/slidingPanel';
import SortAndFilterAccordionHeader, {
  defineItemsAndGetStringToDisplay,
} from 'theme/atoms/sortAndFilterAccordionHeader/SortAndFilterAccordionHeader';
import SortItem from 'theme/atoms/sortItem';
import SortAndFilterAccordionForBEBasedList from 'theme/cells/sortAndFilterAccordionForBEBasedList';
import { TRANSACTION_CATEGORIES_WITH_STATUSES, TransactionSide } from '../types';
import styles from './SortAndFilter.module.scss';
import { DEFAULT_SORTING_ITEM, SORT_OPTIONS } from './constants';

const itemsOpenByDefault = ['sortBy'];

interface SortAndFilterPanelProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  updateSelectedSortingItems: (index: number, updatedItem: SortItemType) => void;
  removeSelectedSortingItem: (updatedItem: SortItemType) => void;
  categoryId?: CategoryId;
}

const SortAndFilterPanel = ({
  isOpen,
  onClose,
  onSubmit,
  updateSelectedSortingItems,
  removeSelectedSortingItem,
  categoryId,
}: SortAndFilterPanelProps): JSX.Element => {
  const { transactionsSortAndFilterStore } = useStores();
  const {
    counts,
    selectedSortingItems,
    selectedFilteringItems,
    resetSelectedFilteringAndSortingItems,
    resetActivePage,
    addEmptySelectedSortingItem,
  } = transactionsSortAndFilterStore;

  const [openAccordionItems, setOpenAccordionItems] = useState<string[]>(itemsOpenByDefault);

  const getSortByElements = (): JSX.Element => {
    return (
      <Accordion.Item
        id="sortBy"
        header={
          <SortAndFilterAccordionHeader
            label="Sort by"
            showItems={!openAccordionItems.includes('sortBy')}
            items={selectedSortingItems.filter((el) => el.checked).map((el) => el.label)}
          />
        }
      >
        {selectedSortingItems.map((item, index) => {
          return (
            <SortItem
              key={item.value}
              item={item}
              onChange={(item) => updateSelectedSortingItems(index, item)}
              onRemove={() => removeSelectedSortingItem(item)}
              options={SORT_OPTIONS}
              store={transactionsSortAndFilterStore}
              defaultItem={DEFAULT_SORTING_ITEM}
            />
          );
        })}
        <Button
          text="add sort"
          onClick={() => addEmptySelectedSortingItem()}
          disabled={
            Boolean(selectedSortingItems.find((s) => s.value === '')) ||
            selectedSortingItems.length >= SORT_OPTIONS.length
          }
          buttonType="tertiary"
          iconStart={<AddIcon />}
          uppercase={false}
        />
      </Accordion.Item>
    );
  };

  const transactionSideElement = counts?.transactionSideCounts && counts.transactionSideCounts.length > 1 && (
    <Accordion.Item
      id="type"
      header={
        <SortAndFilterAccordionHeader
          label="Type"
          showItems={!openAccordionItems.includes('type')}
          items={Object.entries(TransactionSide)
            .filter(([key]) => Boolean(selectedFilteringItems.find((f) => f.value === key)))
            .map(([_key, value]) => value)}
        />
      }
    >
      <FilterChecklist
        name="transactionSide"
        listItems={Object.entries(TransactionSide).map(([key, value]) => ({
          value: key,
          label: value,
          count: counts?.transactionSideCounts.find((c) => c.transactionSide === key)?.count,
        }))}
        store={transactionsSortAndFilterStore}
      />
    </Accordion.Item>
  );

  const statusItems = Object.entries(TransactionStatus)
    .filter(([key]) => key !== 'Draft')
    .filter(([_key, value]) => {
      if (!categoryId) return true;
      const statusesToDisplay = TRANSACTION_CATEGORIES_WITH_STATUSES.find((el) => el.id === categoryId)?.statuses;
      return !statusesToDisplay || statusesToDisplay.includes(value);
    });

  const statusElement = counts?.transactionStatusCounts && counts.transactionStatusCounts.length > 1 && (
    <Accordion.Item
      id="status"
      header={
        <SortAndFilterAccordionHeader
          label="Status"
          showItems={!openAccordionItems.includes('status')}
          items={statusItems
            .filter(([key]) => Boolean(selectedFilteringItems.find((f) => f.value === key)))
            .map(([_key, value]) => addSpaceBeforeUppercase(value))}
        />
      }
    >
      <FilterChecklist
        name="status"
        listItems={statusItems.map(([key, value]) => ({
          value: key,
          label: addSpaceBeforeUppercase(value),
          count: counts?.transactionStatusCounts.find((c) => c.transactionStatus === key)?.count,
        }))}
        store={transactionsSortAndFilterStore}
      />
    </Accordion.Item>
  );

  const creationDateElement = (): JSX.Element => {
    const datesToDislpay = defineItemsAndGetStringToDisplay({
      key: 'created',
      selectedFilteringItems,
      isDate: true,
    });
    return (
      <Accordion.Item
        id="creationDate"
        header={
          <SortAndFilterAccordionHeader
            label="Creation Date"
            showItems={!openAccordionItems.includes('creationDate')}
            stringToDisplay={datesToDislpay}
          />
        }
      >
        <DatePickerRange
          kind={KindDates.created as Kind}
          store={transactionsSortAndFilterStore}
          minLabel={RangeEndMin.After}
          maxLabel={RangeEndMax.Before}
        />
      </Accordion.Item>
    );
  };

  return (
    <SlidingPanel
      title="Sort and filter"
      onClose={onClose}
      onReset={() => {
        resetSelectedFilteringAndSortingItems();
        resetActivePage();
      }}
      isOpen={isOpen}
      onSubmit={onSubmit}
      btnSubmitText="Show results"
    >
      <div className={styles.root}>
        <Accordion itemsOpenByDefault={itemsOpenByDefault} onChange={setOpenAccordionItems}>
          {getSortByElements()}
          {transactionSideElement}
          <SortAndFilterAccordionForBEBasedList
            field="transactionRepresentative"
            label="Contact involved"
            name="representativeContactConnectionId"
            counts={counts}
            openAccordionItems={openAccordionItems}
            store={transactionsSortAndFilterStore}
          />
          {statusElement}
          {creationDateElement()}
        </Accordion>
      </div>
    </SlidingPanel>
  );
};

export default observer(SortAndFilterPanel);
