import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import { userIdentityTypeToRouteMap } from 'auth/constants';
import { authMethodTiles } from 'auth/constants';
import { AuthMethodAction, UserIdentityType } from 'auth/types';
import { Button, Subtitle } from 'theme/atoms';
import Accordion from 'theme/atoms/Accordion';
import RadioTilesList from 'theme/cells/radioTiles/radioTilesList';
import styles from '../SettingsSecurity.module.scss';

const AuthMethod = (): React.JSX.Element => {
  const navigate = useNavigate();

  const {
    authStore: { getUserIdentities, getMFAUserIdentityTypesToVerify, userIdentities },
  } = useStores();

  useEffect(() => {
    getUserIdentities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (userIdentityType: UserIdentityType): void => {
    const addAuthMethodRoute = userIdentityTypeToRouteMap[userIdentityType]?.settings;
    if (!addAuthMethodRoute) return;

    navigate(routes.SettingsSecurityVerification.path(), {
      state: {
        redirectUrl: routes[addAuthMethodRoute].path(),
        action: AuthMethodAction.Add,
      },
    });
  };

  const selectedUserIdentityType = getMFAUserIdentityTypesToVerify(userIdentities || undefined)[0];

  return (
    <Accordion.Item id="authenticationMethod" header="Authentication method">
      <Subtitle text="Choose methods:" />

      <div className={styles.authMethodTiles}>
        <RadioTilesList
          tiles={authMethodTiles}
          selectedTile={selectedUserIdentityType}
          onChange={(val) => handleChange(val as UserIdentityType)}
        />
      </div>

      <Button
        buttonType="textButton"
        text="Recovery codes"
        to={routes.SettingsSecurityRecoveryCodes.path()}
        className={styles.recoveryCodesButton}
      />
    </Accordion.Item>
  );
};

export default observer(AuthMethod);
