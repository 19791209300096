import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import { TransactionStatus } from 'domain/types/Transactions.types';
import { getAddress } from 'network/utils';
import { ReactComponent as WarnIcon } from 'theme/assets/svg/warn.svg';
import { Button } from 'theme/atoms';
import SectionHeader from 'theme/atoms/Titles/SectionHeader';
import UserCard from 'theme/atoms/userCard';
import styles from './ArtworkTransaction.module.scss';

const ArtworkTransaction = (): JSX.Element => {
  const { artworkStore } = useStores();
  const { artworkTransaction, artwork } = artworkStore;
  const navigate = useNavigate();

  if (!artworkTransaction || !artworkTransaction.buyerInfo) return <></>;

  const ownerVotes = artworkTransaction.sellerInfo?.ownersVotes.filter((vote) => vote.agreement).length;

  const pendingSaleOffer = (
    <>
      <div className={styles.row}>
        <SectionHeader title="Pending sale offer" />
        <span className={styles.transactionId}>{artworkTransaction.id}</span>
      </div>

      <div className={styles.for}>
        For:
        <UserCard id={artworkTransaction.buyerInfo?.representativeContact?.contactConnectionId || ''} isSmall>
          <UserCard.Name name={artworkTransaction.buyerInfo?.representativeContact?.organizationName || ''} />
        </UserCard>
      </div>
    </>
  );

  const activeOffer = artworkTransaction.buyerInfo.representativeContact && (
    <>
      <SectionHeader title="Offer send to" />
      <UserCard
        id={
          artworkTransaction.buyerInfo.representativeContact.contactConnectionId ||
          artworkTransaction.buyerInfo.representativeContact.organizationName
        }
      >
        <UserCard.Contact
          name={artworkTransaction.buyerInfo.representativeContact.organizationName}
          title={artworkTransaction.buyerInfo.representativeContact?.organizationTitle}
          address={getAddress(artworkTransaction.buyerInfo.representativeContact.organizationAddress)}
        />
      </UserCard>
    </>
  );

  const awaitingApproval = (
    <div className={styles.status}>
      <WarnIcon />
      {artwork && artworkTransaction.sellerInfo && `Awaiting approval (${ownerVotes}/${artwork?.owners.length})`}
    </div>
  );

  const button = (
    <Button
      text="Offer details"
      onClick={() => navigate(routes.Transaction.path(artworkTransaction.id))}
      buttonType="primary"
    />
  );

  return (
    <div className={styles.root}>
      {artworkTransaction.status === TransactionStatus.PendingOwnersAgreement ? pendingSaleOffer : activeOffer}

      <div className={styles.buttonWrapper}>
        {button}
        {artworkTransaction.status === TransactionStatus.PendingOwnersAgreement && awaitingApproval}
      </div>
    </div>
  );
};
export default observer(ArtworkTransaction);
