import clsx from 'clsx';
import Big from 'big.js';
import { FormValidation } from 'domain/types';
import { Currency } from 'domain/types/Domain.types';
import SectionHeader from 'theme/atoms/Titles/SectionHeader';
import Billing from 'theme/atoms/billing';
import CurrencyInputWrapper from 'theme/atoms/currencyInputWrapper';
import { EditBillingHandlers, SelectedBillingDetails } from '../../types';
import styles from './EditBilling.module.scss';

interface EditBillingProps {
  currency?: Currency;
  validation: FormValidation;
  handlers: EditBillingHandlers;
  selectedBillingDetails: SelectedBillingDetails;
}

const EditBilling = ({ validation, handlers, selectedBillingDetails, currency }: EditBillingProps): JSX.Element => {
  const { selectedArtworkPrice, selectedCommissionAmount, selectedCommissionPercent } = selectedBillingDetails;
  return (
    <div className={styles.root}>
      <SectionHeader title="Edit billing" />
      <Billing.Row>
        <div className={styles.commissionLabel}>Commission:</div>
        <Billing.Cell>
          <div
            className={clsx(styles.row, {
              [styles.withError]:
                validation.commissionPercentMustBeLessThanMax ||
                validation.commissionPercentRequired ||
                validation.commissionAmountMustBeLessThanMax ||
                validation.commissionAmountRequired,
            })}
          >
            <CurrencyInputWrapper
              name="commissionPercent"
              onValueChange={handlers.handleChangeCommissionPercent}
              onBlur={handlers.handleChangeCommissionPercentOnBlur}
              value={selectedCommissionPercent ? Big(selectedCommissionPercent).round(2).toString() : ''}
              placeholder="Percent"
              className={styles.commissionInputPercent}
              errorClassName={styles.inputError}
              suffix="%"
              error={validation.commissionPercentRequired}
            />
            <CurrencyInputWrapper
              name="commissionAmount"
              onValueChange={handlers.handleChangeCommissionAmount}
              onBlur={handlers.handleChangeCommissionAmountOnBlur}
              value={selectedCommissionAmount ? Big(selectedCommissionAmount).round().toString() : ''}
              placeholder="Amount"
              className={styles.commissionInput}
              errorClassName={styles.inputError}
              error={validation.commissionAmountRequired}
              currency={currency}
            />
          </div>

          {(validation.commissionAmountMustBeLessThanMax || validation.commissionPercentMustBeLessThanMax) && (
            <div className={styles.commissionError}>
              {validation.commissionAmountMustBeLessThanMax || validation.commissionPercentMustBeLessThanMax}
            </div>
          )}
        </Billing.Cell>
      </Billing.Row>
      <Billing.Divider />
      <Billing.Row>
        <div className={styles.priceLabel}>Artwork price:</div>
        <Billing.Cell>
          <CurrencyInputWrapper
            name="artworkPrice"
            onValueChange={handlers.handleChangeArtworkPrice}
            onBlur={handlers.handleChangeArtworkPriceOnBlur}
            value={selectedArtworkPrice ? Big(selectedArtworkPrice).round().toString() : ''}
            placeholder="Amount"
            className={clsx(styles.priceInput, {
              [styles.withError]:
                validation.artworkPriceMustBeLessThanMax ||
                validation.artworkPriceRequired ||
                validation.artworkPriceCannotBeSmallerThanAskingPrice,
            })}
            error={
              validation.artworkPriceMustBeLessThanMax ||
              validation.artworkPriceRequired ||
              validation.artworkPriceCannotBeSmallerThanAskingPrice
            }
            errorClassName={styles.inputError}
            currency={currency}
          />
        </Billing.Cell>
      </Billing.Row>
    </div>
  );
};

export default EditBilling;
