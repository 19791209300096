import RadioTile from 'theme/cells/radioTiles/radioTile';
import { RadioTileType } from '../types';
import styles from './RadioTilesList.module.scss';

interface RadioTilesListProps {
  tiles: RadioTileType[];
  selectedTile: string;
  onChange: (id: string) => void;
}

const RadioTilesList = ({ tiles, selectedTile, onChange }: RadioTilesListProps): React.JSX.Element => {
  return (
    <div className={styles.root}>
      {tiles.map(({ title, description, id }) => {
        return (
          <RadioTile
            key={id}
            id={id}
            title={title}
            description={description}
            checked={id === selectedTile}
            onChange={(id) => onChange(id)}
          />
        );
      })}
    </div>
  );
};

export default RadioTilesList;
