import { FormEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import Big from 'big.js';
import TransactionNote from 'consignments/consignment/components/consignmentDetails/components/TransactionNote';
import { FormValidation } from 'domain/types';
import { getAddress } from 'network/utils';
import ArtistResellRightsRange from 'theme/atoms/artistResellRightsRange';
import SlidingPanel from 'theme/atoms/slidingPanel';
import UserCard from 'theme/atoms/userCard';
import { useFormValidation } from 'utils/hooks';
import styles from './ConsignmentForm.module.scss';
import {
  AskingPrice,
  ExpirationDate,
  MaxCommission,
  SalesRestrictions,
  Permissions,
  ActionButtons,
  ArtworkPrice,
} from './components';

const FORM_VALIDATIONS: FormValidation = {
  askingPriceRequired: '',
  askingPriceCannotBe0: '',
  expireAtRequired: '',
  maxCommissionRequired: '',
};

const ConsignmentForm = (): JSX.Element => {
  const { consignmentFormStore, artworkStore } = useStores();
  const {
    consignmentForm,
    consignmentFormIsOpen,
    isEditView,
    resetForm,
    withdrawConsignment,
    saveConsignment,
    submitting,
    askingPriceWithCommissions,
  } = consignmentFormStore;
  const { contact, askingPrice } = consignmentForm;
  const { artwork } = artworkStore;

  const { cleanValidationErrors, formValidationMessages, handleValidation, validation } =
    useFormValidation(FORM_VALIDATIONS);

  const onClosePanel = (): void => {
    resetForm();
    cleanValidationErrors(['expireAtRequired', 'askingPriceRequired', 'maxCommissionRequired']);
  };

  const handleFormSubmission = (event: FormEvent<HTMLFormElement>): void | undefined => {
    event.preventDefault();
    handleValidation('askingPriceRequired', formValidationMessages.ASKING_PRICE_REQUIRED, !consignmentForm.askingPrice);
    handleValidation(
      'askingPriceCannotBe0',
      'Asking price should be higher than 0',
      consignmentForm.askingPrice !== null && consignmentForm.askingPrice.eq(0)
    );
    handleValidation('expireAtRequired', formValidationMessages.EXPIRE_AT_REQUIRED, !consignmentForm.expireAt);
    handleValidation(
      'maxCommissionRequired',
      formValidationMessages.MAX_COMMISSION_REQUIRED,
      !consignmentForm.maxCommission
    );

    if (
      !consignmentForm.askingPrice ||
      !consignmentForm.expireAt ||
      !consignmentForm.maxCommission ||
      consignmentForm.askingPrice.eq(0)
    )
      return;

    saveConsignment();
  };

  return (
    <SlidingPanel
      title={`${!isEditView ? 'Create' : 'Edit'} consignment`}
      onClose={onClosePanel}
      isOpen={consignmentFormIsOpen}
      maxWidth
    >
      {contact && (
        <div className={styles.consignee}>
          <UserCard id={contact.contactConnectionId || ''}>
            <UserCard.Contact
              name={contact?.organizationName}
              title={contact.organizationTitle}
              address={getAddress(contact.organizationAddress)}
            />
          </UserCard>
        </div>
      )}
      <div className={styles.formWrapper}>
        <form className={styles.form}>
          <Permissions />
          <ExpirationDate
            error={validation.expireAtRequired}
            onChange={() => cleanValidationErrors(['expireAtRequired'])}
          />

          <div className={styles.innerWrapper}>
            <SalesRestrictions />
            <AskingPrice
              askingPriceError={validation.askingPriceRequired || validation.askingPriceCannotBe0}
              onChange={() => cleanValidationErrors(['askingPriceRequired', "askingPriceCannotBe0"])}
            />
            <MaxCommission
              maxCommissionRequireError={validation.maxCommissionRequired}
              onChange={() => cleanValidationErrors(['maxCommissionRequired'])}
            />
            <ArtworkPrice />
          </div>

          <ActionButtons
            isEditView={isEditView}
            onClosePanel={onClosePanel}
            withdrawConsignment={withdrawConsignment}
            onSubmit={handleFormSubmission}
            submitting={submitting}
          />
          <div>
            <TransactionNote />
            {artwork?.artistResellRightsPercent !== undefined && artwork.artistResellRightsPercent !== null && (
              <ArtistResellRightsRange
                artistResellRightsPercent={artwork.artistResellRightsPercent}
                askingPrice={Big(askingPrice || 0)}
                askingPriceWithCommissions={askingPriceWithCommissions}
                currency={consignmentForm.currency}
              />
            )}
          </div>
        </form>
      </div>
    </SlidingPanel>
  );
};

export default observer(ConsignmentForm);
