import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import styles from 'auth/Auth.module.scss';
import AuthAppForm from 'auth/components/forms/AuthAppForm';
import { Button, Subtitle, TitleBlack } from 'theme/atoms';
import Breadcrumbs, { Breadcrumb } from 'theme/atoms/Breadcrumbs';
import Topbar from 'theme/atoms/Topbar';
import { useIsAccessTokenFresh, useUserContext } from 'utils/hooks';

const BREADCRUMBS: Breadcrumb[] = [{ label: 'Verification' }];

const LoginWithAuthApp = (): React.JSX.Element => {
  const navigate = useNavigate();
  const isTokenFresh = useIsAccessTokenFresh();
  const location = useLocation();
  const redirectUrl = location.state?.redirectUrl;
  const { homePath } = useUserContext();
  const {
    authStore: { submitting, loginWithTOTPCode, saveTokensToLocalStorage },
  } = useStores();

  useEffect(() => {
    if (!isTokenFresh) {
      navigate(routes.Login.path());
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (authAppCode: string): void => {
    loginWithTOTPCode(authAppCode).then((response) => {
      if (response?.success) {
        saveTokensToLocalStorage();
        navigate(redirectUrl || homePath);
      }
    });
  };

  const submitButton = (
    <Button
      type="submit"
      text="Continue"
      loading={submitting}
      disabled={submitting}
      className={styles.submitButton}
      maxWidth
    />
  );

  return (
    <div className={clsx(styles.root, styles.oneColumnLayout)}>
      <Topbar>
        <Breadcrumbs breadcrumbs={BREADCRUMBS} />
      </Topbar>
      <div className={styles.innerWrapper}>
        <TitleBlack>Approve sign in request</TitleBlack>
        <Subtitle>Open your Authenticator app, and enter the number shown to sign in.</Subtitle>

        <AuthAppForm onSubmit={onSubmit} submitButton={submitButton} />

        <div className={styles.useRecoveryCode}>
          <span>Having trouble?</span>
          <Button
            buttonType="textButton"
            text="Use recovery code"
            to={routes.LoginAccessRecovery.path()}
            className={styles.recoveryCodesButton}
          />
        </div>
      </div>
    </div>
  );
};

export default LoginWithAuthApp;
