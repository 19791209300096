import { AxiosResponse } from 'axios';
import { Contact } from 'domain/types/Contact.types';
import { TransactionStatus } from 'domain/types/Transactions.types';

export const CATEGORIES_IDS = ['pending', 'awaitingPayment', 'closed'];

export type TransactionCategoryId = (typeof CATEGORIES_IDS)[number];

export interface TransactionCategory {
  id: TransactionCategoryId;
  label: string;
  statuses: TransactionStatus[];
}

export const TRANSACTION_CATEGORIES_WITH_STATUSES: TransactionCategory[] = [
  {
    id: 'pending',
    label: 'Pending',
    statuses: [
      TransactionStatus.PendingOwnersAgreement,
      TransactionStatus.SentToBuyerRepresentative,
      TransactionStatus.PendingBuyersAgreement,
    ],
  },
  {
    id: 'sentToBank',
    label: 'Execution',
    statuses: [
      TransactionStatus.Accepted,
      TransactionStatus.AcceptedEscrowAccountGranted,
      TransactionStatus.InvoiceVerification,
      TransactionStatus.WaitingForPayment,
      TransactionStatus.ReadyForExecution,
      TransactionStatus.Executing,
      TransactionStatus.Executed,
      TransactionStatus.SignificantPaymentsMade,
    ],
  },
  {
    id: 'closed',
    label: 'Closed',
    statuses: [
      TransactionStatus.RejectedByOwner,
      TransactionStatus.RejectedByBuyer,
      TransactionStatus.RejectedBySellerRepresentative,
      TransactionStatus.RejectedByBuyerRepresentative,
      TransactionStatus.Closed,
    ],
  },
];

export const DEFAULT_TRANSACTION_CATEGORY = TRANSACTION_CATEGORIES_WITH_STATUSES[0].id;

export enum TransactionSide {
  SellerSide = 'Sale',
  BuyerSide = 'Purchase',
}

interface TransactionSideCounts {
  transactionSide: TransactionSide;
  count: number;
}

interface TransactionRepresentativeCounts {
  transactionRepresentative: Contact;
  count: number;
}

interface TransactionStatusCounts {
  transactionStatus: TransactionStatus;
  count: number;
}

export interface TransactionCounts {
  transactionSideCounts: TransactionSideCounts[];
  transactionRepresentativeCounts: TransactionRepresentativeCounts[];
  transactionStatusCounts: TransactionStatusCounts[];
}

export type TransactionCountsReturn = Promise<AxiosResponse<TransactionCounts>>;
