import { ReactElement } from 'react';
import { Route } from 'react-router-dom';
import { AppWrapper } from 'AppWrapper/AppWrapper';
import AuthWrapper from 'auth/AuthWrapper/AuthWrapper';
import { SettingsLayout } from 'settings/settingsLayout';
import { routes } from '../paths.const';

export const getCommonRoutesForAuthenticatedUser = (): ReactElement => {
  return (
    <Route>
      <Route element={<AppWrapper />}>
        <Route element={<SettingsLayout />}>
          <Route path={routes.SettingsSecurity.path()} element={routes.SettingsSecurity.component}></Route>
          <Route path={routes.SettingsKYC.path()} element={routes.SettingsKYC.component}></Route>
          <Route
            path={routes.SettingsSecurityVerification.path()}
            element={routes.SettingsSecurityVerification.component}
          />
          <Route path={routes.SettingsSecurityAuthApp.path()} element={routes.SettingsSecurityAuthApp.component} />
          <Route path={routes.SettingsSecurityPhone.path()} element={routes.SettingsSecurityPhone.component} />
          <Route
            path={routes.SettingsSecurityRecoveryCodes.path()}
            element={routes.SettingsSecurityRecoveryCodes.component}
          />
        </Route>
      </Route>
      <Route path={routes.ResetPasswordSetNewPassword.path()} element={routes.ResetPasswordSetNewPassword.component} />
      <Route element={<AuthWrapper />}>
        <Route path={routes.ResetPasswordGetEmail.path()} element={routes.ResetPasswordGetEmail.component} />
        <Route path={routes.ResetPasswordEmailSent.path()} element={routes.ResetPasswordEmailSent.component} />
      </Route>
    </Route>
  );
};
