import { ChangeEvent } from 'react';
import clsx from 'clsx';
import styles from './Input.module.scss';

type InputType = 'text' | 'password' | 'search' | 'tel' | 'number' | 'date';

interface InputProps {
  name: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  value: string | number;
  className?: string;
  error?: string;
  errorClassName?: string;
  label?: string;
  placeholder?: string;
  readOnly?: boolean;
  type?: InputType;
  endIcon?: JSX.Element;
  endIconClassName?: string;
  onEnter?: () => void;
  onBlur?: (event?) => void;
  onFocus?: (event?) => void;
  onKeyDown?: (event?) => void;
  min?: number;
  max?: number;
  autoFocus?: boolean;
  noAutocomplete?: boolean;
}

const Input = ({
  className = '',
  error = '',
  errorClassName,
  label,
  name,
  onChange,
  placeholder,
  readOnly = false,
  type = 'text',
  value,
  endIcon,
  endIconClassName,
  onEnter,
  onBlur,
  onFocus,
  onKeyDown,
  min,
  max,
  autoFocus,
  noAutocomplete,
}: InputProps): JSX.Element => {
  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter' && onEnter) onEnter();
  };
  return (
    <div className={clsx(styles.root, className)}>
      {label && (
        <label htmlFor={name} className={styles.label}>
          {label}
        </label>
      )}
      <input
        min={min}
        max={max}
        name={name}
        placeholder={placeholder}
        type={type}
        value={value}
        onChange={(e) => onChange && onChange(e)}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyUp={handleKeyUp}
        onKeyDown={onKeyDown}
        readOnly={readOnly}
        autoComplete={noAutocomplete ? 'nope' : name} // https://stackoverflow.com/questions/12374442/chrome-ignores-autocomplete-off
        className={clsx(styles.input, { [styles.inputError]: error })}
        autoFocus={autoFocus}
        onWheel={(event) => event.currentTarget.blur()}
      />
      {endIcon && <div className={clsx(styles.endIcon, endIconClassName)}>{endIcon}</div>}
      {error && <span className={clsx(styles.error, errorClassName)}>{error}</span>}
    </div>
  );
};

export default Input;
