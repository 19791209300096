import { FieldDetail } from 'domain/types/FieldDetail';
import styles from './UserDetails.module.scss';

interface UserDetailsProps {
  userDetailsMap: FieldDetail[];
}

const UserDetails = ({ userDetailsMap }: UserDetailsProps): React.JSX.Element => {
  return (
    <>
      {userDetailsMap.map(({ label, name, value }) => {
        return (
          <div className={styles.root} key={name}>
            <p className={styles.label}>{label}</p>
            <p className={styles.content}>{value}</p>
          </div>
        );
      })}
    </>
  );
};

export default UserDetails;
