import { RawBankInvoice } from 'bankPerspective/bankInvoices/requests.types';
import BankInvoicesTable from 'bankPerspective/components/bankInvoicesTable';
import styles from './BankTransactionInvoicesTable.module.scss';

interface BankTransactionInvoicesTableProps {
  invoices: RawBankInvoice[];
}

const BankTransactionInvoicesTable = ({ invoices }: BankTransactionInvoicesTableProps): JSX.Element => {
  return (
    <div className={styles.root}>
      <h2 className={styles.title}>Invoices</h2>
      <BankInvoicesTable invoices={invoices} className={styles.invoicesTable} />
    </div>
  );
};

export default BankTransactionInvoicesTable;
