import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import styles from './Table.module.scss';

interface TableProps {
  children: JSX.Element;
  className?: string;
}

export const Table = observer(({ children, className }: TableProps): JSX.Element => {
  return (
    <table className={clsx(styles.root, className)}>
      <tbody>{children}</tbody>
    </table>
  );
});

export default Table;
