import { action, makeObservable } from 'mobx';
import { RootStore } from 'RootStore';
import PaginationBasicStore, {
  PaginationBasicStoreProps as BasicStoreProps,
} from 'domain/PaginationBasicStore/PaginationBasicStore';
import * as requests from '../requests';

const ExtendedStoreProps = {
  fetchItems: action,
};

class ConsignmentsStore extends PaginationBasicStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    super(rootStore);
    this.rootStore = rootStore;
    makeObservable(this, { ...BasicStoreProps, ...ExtendedStoreProps });
  }

  fetchItems = async (shouldSetNumberOfAllItemsInCategory?: boolean): Promise<void> => {
    const req =
      this.rootStore.consignmentsSortAndFilterStore.groupBy === 'artwork'
        ? requests.fetchConsignmentsGroupedByArtwork
        : requests.fetchConsignmentsGroupedByConsignee;
    this.handleFetchItems(
      req,
      {
        sortBy: this.rootStore.consignmentsSortAndFilterStore.sortByAsParams,
        ...this.rootStore.consignmentsSortAndFilterStore.filterByAsParams(
          this.rootStore.consignmentsSortAndFilterStore.confirmedFilteringItems
        ),
      },
      shouldSetNumberOfAllItemsInCategory
    );
  };
}

export default ConsignmentsStore;
