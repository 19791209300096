import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { addMeToOrganizationName } from 'domain/utils';
import ContactPicker from 'network/contactPicker/ContactPicker';
import { ContactConnection } from 'network/types';
import { getAddress } from 'network/utils';
import { Button } from 'theme/atoms';
import SectionHeader from 'theme/atoms/Titles/SectionHeader';
import IconButton from 'theme/atoms/iconButton';
import IconComponent, { icons } from 'theme/atoms/iconComponent';
import { useDebounceIsPanelOpen } from 'theme/atoms/slidingPanel/useDebounceIsPanelOpen';
import UserCard from 'theme/atoms/userCard';
import styles from './PickAuthor.module.scss';

interface PickAuthorProps {
  error: string;
  cleanError: () => void;
}

const PickAuthor = ({ error, cleanError }: PickAuthorProps): JSX.Element => {
  const { addArtworkStore } = useStores();
  const {
    artworkForm,
    authorToDisplay,
    updateArtworkForm,
    setContactAsAuthorOrOwnerInStoreAndUpdateForm,
    removeAuthorFromStore,
  } = addArtworkStore;

  const [contactPickerIsOpen, setContactPickerIsOpen] = useState(false);

  const debouncedContactPickerIsOpen = useDebounceIsPanelOpen(contactPickerIsOpen);

  const handlePickAuthor = (contact: ContactConnection): void => {
    setContactAsAuthorOrOwnerInStoreAndUpdateForm(false, 'author', contact);
    setContactPickerIsOpen(false);
  };

  const handlePickMeAsAuthor = (): void => {
    setContactAsAuthorOrOwnerInStoreAndUpdateForm(true, 'author');
    setContactPickerIsOpen(false);
  };

  const handleRemoveAuthor = (): void => {
    updateArtworkForm('author', { iAmTheAuthor: false });
    removeAuthorFromStore();
  };

  const handleOpenContactPicker = (): void => {
    debouncedContactPickerIsOpen && setContactPickerIsOpen(true);
    cleanError();
  };

  const addButton = !authorToDisplay && (
    <Button
      text="Add"
      iconStart={<IconComponent icon={icons.add} />}
      buttonType="tertiary"
      onClick={handleOpenContactPicker}
    />
  );

  const instruction = !authorToDisplay && (
    <div className={styles.instruction}>
      Add artist or artist's estate profile
      {error && <span className={styles.error}>{error}</span>}
    </div>
  );

  const authorCard = authorToDisplay && (
    <div className={styles.sectionWrapper}>
      <UserCard id={authorToDisplay.id || ''}>
        <UserCard.Contact
          name={addMeToOrganizationName({ organizationName: authorToDisplay.name }, artworkForm.author?.iAmTheAuthor)}
          title={authorToDisplay.title}
          address={authorToDisplay.address && getAddress(authorToDisplay.address)}
        />
      </UserCard>
      <IconButton onClick={handleRemoveAuthor} icon={icons.close} />
    </div>
  );
  return (
    <>
      <div className={styles.sectionWrapper}>
        <SectionHeader title="Author of the artwork" />
        {addButton}
      </div>
      {instruction}
      {authorCard}
      <ContactPicker
        title="Add author"
        isOpen={contactPickerIsOpen}
        onPickContact={handlePickAuthor}
        onClose={() => setContactPickerIsOpen(false)}
        onPickMeAsContact={handlePickMeAsAuthor}
        withMeAsContact="author"
      />
    </>
  );
};

export default observer(PickAuthor);
