import { makeObservable } from 'mobx';
import { RootStore } from 'RootStore';
import SortAndFilterBasicStore, {
  SortAndFilterBasicStoreProps as BasicStoreProps,
} from 'domain/SortAndFilterBasicStore/SortAndFilterBasicStore';
import { INVOICE_CATEGORIES_WITH_INVOICE_SIDES } from 'transactions/invoices/constants';
import { FilterParams, InvoiceSide } from 'transactions/invoices/types';

class InvoicesSortAndFilterStore extends SortAndFilterBasicStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    super(rootStore);
    this.rootStore = rootStore;
    makeObservable(this, { ...BasicStoreProps });
  }

  private get invoiceSides(): readonly (keyof typeof InvoiceSide)[] {
    const categoryWithStatuses = INVOICE_CATEGORIES_WITH_INVOICE_SIDES.find(
      (category) => category.id === this.categoryId
    );
    return categoryWithStatuses?.invoiceSide || [];
  }

  filterByAsParams = (): FilterParams => {
    const filterParams = {
      invoiceSide: this.invoiceSides as InvoiceSide[],
    };

    Object.keys(filterParams).forEach((key) => {
      if (!filterParams[key]) delete filterParams[key];
    });

    return filterParams;
  };
}

export default InvoicesSortAndFilterStore;
