import Big from 'big.js';
import { Contact } from 'domain/types/Contact.types';
import { addMeToOrganizationName } from 'domain/utils';
import { getAddress } from 'network/utils';
import IconButton from 'theme/atoms/iconButton';
import { icons } from 'theme/atoms/iconComponent';
import UserCard from 'theme/atoms/userCard';
import CurrencyInputWrapper from '../currencyInputWrapper';
import styles from './ShareholdersList.module.scss';

interface ShareholdersListProps {
  shareholders: Contact[];
  onDelete: (shareholder: Contact, index: number) => void;
  onChange: (index: number, value: string) => void;
  onBlur: (index: number) => void;
  amounts: Array<Big | null>;
  errorMsg?: string;
}

const ShareholdersList = ({
  shareholders,
  amounts,
  errorMsg,
  onDelete,
  onChange,
  onBlur,
}: ShareholdersListProps): JSX.Element => {
  return (
    <div className={styles.root}>
      {shareholders.map((shareholder, index, shareholders) => {
        return (
          <div className={styles.shareholder} key={shareholder.contactConnectionId + shareholder.organizationName}>
            <UserCard id={shareholder.contactConnectionId || ''}>
              <UserCard.Contact
                numberOfLinesInName={2}
                name={addMeToOrganizationName(shareholder)}
                title={shareholder.organizationTitle}
                address={shareholder.organizationAddress && getAddress(shareholder.organizationAddress)}
              />
            </UserCard>
            {shareholders.length > 1 && (
              <CurrencyInputWrapper
                className={styles.shareInput}
                placeholder="Share %"
                suffix="%"
                onValueChange={(values) => onChange(index, values.value || '')}
                onBlur={() => onBlur(index)}
                value={amounts[index] === null ? '' : Big(amounts[index] || 0).toString()}
                name={`share ${shareholder.organizationName}`}
                error={amounts[index] !== null && Big(amounts[index] || 0).eq(0) ? errorMsg : ''}
              />
            )}
            <IconButton onClick={() => onDelete(shareholder, index)} icon={icons.close} />
          </div>
        );
      })}
    </div>
  );
};

export default ShareholdersList;
