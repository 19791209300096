import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import Loader from 'theme/atoms/loader';
import NoDataToDisplay from 'theme/atoms/noDataToDisplay';
import { TransactionForm } from './transactionForm/TransactionForm';

export const Transaction = (): JSX.Element => {
  const {
    transactionStore: { setTransactionId, fetchOrLoadTransaction, setArtworkId, setConsignmentId, loaded },
    transactionFormStore: { transactionWrapper },
  } = useStores();

  const { transactionId } = useParams();
  const [searchParams] = useSearchParams();
  const consignmentId = searchParams.get('consignmentId');
  const artworkId = searchParams.get('artworkId');

  useEffect(() => {
    transactionId && setTransactionId(transactionId);
    consignmentId && setConsignmentId(consignmentId);
    artworkId && setArtworkId(artworkId);
    fetchOrLoadTransaction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionId, consignmentId, artworkId]);

  const loader = !loaded && <Loader />;
  const noTransaction = (!transactionWrapper || !transactionWrapper.transaction) && (
    <NoDataToDisplay copy="No transaction to display." height={100} />
  );
  const transactionForm = transactionWrapper && <TransactionForm />;

  return <>{loader || noTransaction || transactionForm}</>;
};

export default observer(Transaction);
