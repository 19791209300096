import { ReactNode } from 'react';
import styles from './Banner.module.scss';

interface BannerProps {
  show: boolean;
  text: ReactNode | string;
}

const Banner = ({ show, text }: BannerProps): JSX.Element => {
  if (show) {
    return (
      <div className={styles.root}>
        <div className={styles.outer}>
          <div className={styles.inner}>{text}</div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default Banner;
