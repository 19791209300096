import { ExternalExploringVisibility } from 'artworks/types';
import { ReactComponent as GroupIcon } from '../../assets/svg/group.svg';
import { ReactComponent as PrivateIcon } from '../../assets/svg/private.svg';
import { ReactComponent as PublicIcon } from '../../assets/svg/public.svg';
import styles from './VisibilityIcon.module.scss';

interface VisibilityIconProps {
  visibility: keyof typeof ExternalExploringVisibility;
}

const VisibilityIcon = ({ visibility }: VisibilityIconProps): JSX.Element => {
  switch (visibility) {
    case 'Public':
      return <PublicIcon className={styles.root} />;
    case 'Private':
      return <PrivateIcon className={styles.root} />;
    case 'LimitedToContactList':
      return <GroupIcon className={styles.root} />;
  }
};

export default VisibilityIcon;
