import { action, makeObservable, observable } from 'mobx';
import { RootStore } from 'RootStore';
import PaginationBasicStore, {
  PaginationBasicStoreProps as BasicStoreProps,
} from 'domain/PaginationBasicStore/PaginationBasicStore';
import * as transactionRequests from 'transactions/requests';

const ExtendedStoreProps = {
  isInvoicesInfoOpen: observable,
  fetchItems: action,
  setIsInvoiceInfoOpen: action,
};

class InvoicesStore extends PaginationBasicStore {
  rootStore: RootStore;

  isInvoicesInfoOpen = false;

  constructor(rootStore: RootStore) {
    super(rootStore);
    this.rootStore = rootStore;
    makeObservable(this, { ...BasicStoreProps, ...ExtendedStoreProps });
  }

  fetchItems = async (): Promise<void> => {
    this.handleFetchItems(transactionRequests.fetchInvoices, {
      ...this.rootStore.invoicesSortAndFilterStore.filterByAsParams(),
    });
  };

  updateInvoiceUrl = async (invoiceId: string, fileUrl: string | null): Promise<void> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;

    return transactionRequests
      .updateInvoiceUrl(invoiceId, fileUrl)
      .catch(() => addToast(toastMessages.DEFAULT.ERROR, 'error'));
  };

  setIsInvoiceInfoOpen = (isOpen: boolean): void => {
    this.isInvoicesInfoOpen = isOpen;
  };
}

export default InvoicesStore;
