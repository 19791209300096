import { action, makeObservable } from 'mobx';
import { RootStore } from 'RootStore';
import * as artworksRequests from 'artworks/requests';
import PaginationBasicStore, {
  PaginationBasicStoreProps as BasicStoreProps,
} from 'domain/PaginationBasicStore/PaginationBasicStore';

const ExtendedStoreProps = {
  fetchItems: action,
};

class ArtworkRegistrationStore extends PaginationBasicStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    super(rootStore);
    this.rootStore = rootStore;
    makeObservable(this, { ...BasicStoreProps, ...ExtendedStoreProps });
  }

  fetchItems = async (): Promise<void> => {
    this.handleFetchItems(artworksRequests.fetchArtworks, {
      ...this.rootStore.artworkRegistrationSortAndFilterStore.filterByAsParams(),
    });
  };
}

export default ArtworkRegistrationStore;
