import { MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { routes } from 'app/routes/paths.const';
import { ArtworkFieldsIdType } from 'artworks/artworksComponents/viewOptions/types';
import { Artwork } from 'artworks/types';
// import { ReactComponent as Edit } from 'theme/assets/svg/edit.svg';
// import { ReactComponent as EmptyHeart } from 'theme/assets/svg/emptyHeart.svg';
// import { ReactComponent as FullHeart } from 'theme/assets/svg/fullHeart.svg';
// import { ReactComponent as Share } from 'theme/assets/svg/share.svg';
// import Checkbox from 'theme/atoms/Checkbox';
// import IconButton from 'theme/atoms/iconButton';
import ArtworkDetailItem from '../artworkDetailItem/ArtworkDetailItem';
import { getLayoutSizeClass } from '../utils';
import styles from './List.module.scss';

interface ListProps {
  size: number;
  artworksToDisplay: Artwork[];
  updateSelectedArtworksIds: (id: string, value: boolean) => void;
  selectedArtworksIds: string[];
  favouriteHandler: (id: string, value: boolean) => void;
  checkedFieldsIds: (ArtworkFieldsIdType | [ArtworkFieldsIdType, ArtworkFieldsIdType])[];
}

export const List = observer(
  ({
    size,
    artworksToDisplay,
    updateSelectedArtworksIds,
    selectedArtworksIds,
    // favouriteHandler,
    checkedFieldsIds,
  }: ListProps): JSX.Element => {
    const navigate = useNavigate();

    const handleRowClick = (e: MouseEvent, artworkId: string): void => {
      if (e.target instanceof HTMLInputElement) return;
      navigate(routes.Artwork.path(artworkId));
    };

    return (
      <table className={styles.root}>
        <tbody>
          {artworksToDisplay.map((artwork) => {
            // const checked = selectedArtworksIds.includes(artwork.id);

            return (
              <tr
                className={clsx(styles.row, styles[getLayoutSizeClass(size)])}
                key={artwork.id}
                onClick={(e) => handleRowClick(e, artwork.id)}
              >
                {/* <td className={styles.checkboxTd}>
                  <Checkbox
                    name="single"
                    checked={checked}
                    onChange={(e) => updateSelectedArtworksIds(artwork.id, e.target.checked)}
                    iconType="primary"
                  />
                </td> */}
                {checkedFieldsIds.map((fieldIds, i) => {
                  const [fieldId, childId = null] = typeof fieldIds === 'string' ? [fieldIds] : fieldIds;

                  return (
                    <td key={fieldId} className={clsx(styles[getLayoutSizeClass(size)], styles[fieldId])}>
                      <ArtworkDetailItem size={size} layout="list" fieldId={fieldId} artwork={artwork} />
                      {childId ? (
                        <ArtworkDetailItem size={size} layout="list" fieldId={childId} artwork={artwork} />
                      ) : null}
                    </td>
                  );
                })}
                {/* <td className={styles.actionsTd}> // TODO uncomment when ready
                  <div className={styles.actionBtns}>
                    <IconButton
                      icon={artwork.favourite ? FullHeart : EmptyHeart}
                      onClick={() => favouriteHandler(artwork.id, artwork.favourite)}
                    />
                    <div className={styles.hiddenBtns}>
                      <IconButton icon={Share} onClick={() => console.log('click share')} />
                      <IconButton icon={Edit} onClick={() => console.log('click edit')} />
                    </div>
                  </div>
                </td> */}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
);
