import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { ORGANIZATION_DATA_UNKNOWN } from 'domain/constants';
import { Button, Spinner } from 'theme/atoms';
import Accordion from 'theme/atoms/Accordion';
import Card from 'theme/atoms/card';
import InfoCardDetailsList from 'theme/atoms/card/infoCardDetailsList';
import { useDebounceIsPanelOpen } from 'theme/atoms/slidingPanel/useDebounceIsPanelOpen';
import { formatIBAN } from 'utils/helpers';
import styles from './BankAccount.module.scss';
import BankAccountEditPanel from './components/bankAccountEditPanel';

const BankAccount = (): JSX.Element => {
  const { organizationStore, bankStore } = useStores();
  const { selectedOrganization, loading } = organizationStore;
  const { fetchBankAccountDetails, fetchedBankAccountData, bankAccount: bankAccountData } = bankStore;

  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const debouncedIsPanelOpen = useDebounceIsPanelOpen(isPanelOpen);

  useEffect(() => {
    !bankAccountData && fetchBankAccountDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchBankAccountDetails]);

  const bankAccountDetailsWithOrgNameAsObject = fetchedBankAccountData.reduce(
    (acc, { name, label, value }) => {
      const formattedIbanValue = name === 'iban' && value && formatIBAN(value);
      return {
        ...acc,
        [label]: { value: formattedIbanValue || value || ORGANIZATION_DATA_UNKNOWN },
      };
    },
    { 'Account holder name': { value: selectedOrganization?.name || ORGANIZATION_DATA_UNKNOWN } }
  );

  const accountDetailsDisplay = loading ? (
    <Spinner small />
  ) : (
    <div className={styles.details}>
      <Card.DetailsList className={styles.accountDetails}>
        <InfoCardDetailsList fieldsMap={bankAccountDetailsWithOrgNameAsObject} />
      </Card.DetailsList>
      <Button text="Edit data" onClick={() => debouncedIsPanelOpen && setIsPanelOpen(!isPanelOpen)} />
    </div>
  );

  return (
    <>
      <Accordion.Item id="bankAccount" header={<div className={styles.accordionItemLabel}>Bank account</div>}>
        {accountDetailsDisplay}
      </Accordion.Item>

      <BankAccountEditPanel onClose={() => setIsPanelOpen(false)} isOpen={isPanelOpen} />
    </>
  );
};

export default observer(BankAccount);
