import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import Breadcrumbs, { Breadcrumb } from 'theme/atoms/Breadcrumbs';
import Topbar from 'theme/atoms/Topbar';
import Loader from 'theme/atoms/loader';
import BillingAndAddressesCard from 'theme/cells/billingAndAddressesCard';
import { DEFAULT_INVOICE_CATEGORY } from 'transactions/invoices/constants';
import { Invoice as InvoiceType } from 'transactions/requests.types';
import { useTitle } from 'utils/hooks';
import styles from './Invoice.module.scss';
import InvoiceEditOrAdd from './components/invoiceEditOrAdd';

const BREADCRUMBS: Breadcrumb[] = [
  { label: 'Invoices', route: 'Invoices', param: DEFAULT_INVOICE_CATEGORY },
  { label: 'Invoice details' },
];

const Invoice = (): JSX.Element | null => {
  const { invoiceId } = useParams();
  const { invoiceStore } = useStores();
  const { fetchInvoice, loadingInvoiceDetails, resetSelectedInvoice, selectedInvoice } = invoiceStore;

  const invoice = selectedInvoice as InvoiceType;

  useTitle('Invoice details');

  useEffect(() => {
    if (invoiceId) fetchInvoice(invoiceId);

    return () => {
      resetSelectedInvoice();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!invoice || loadingInvoiceDetails) return <Loader minHeight={150} />;

  const goToRelatedTransaction = routes.Transaction.path(invoice.externalId);

  const isUserSelectedInvoicePayer = Boolean(invoice.payerContactDto?.self);

  const invoiceDetails = (
    <>
      <BillingAndAddressesCard
        fromLabel="From"
        item={invoice}
        title="Invoice overview"
        showBankAccount={!isUserSelectedInvoicePayer}
        toLabel="To"
        goToRelatedTransactionLink={goToRelatedTransaction}
      />
      <div className={styles.invoicePreview}>
        <InvoiceEditOrAdd invoice={invoice} />
      </div>
    </>
  );

  return (
    <>
      <Topbar>
        <Breadcrumbs breadcrumbs={BREADCRUMBS} />
      </Topbar>
      {invoiceDetails}
    </>
  );
};

export default observer(Invoice);
