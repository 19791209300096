import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import styles from 'artworks/artwork/Artwork.module.scss';
import ArtworkConsignments from 'artworks/artwork/components/artworkConsignments';
import ArtworkDetails from 'artworks/artwork/components/artworkDetails';
import ArtworkOwnership from 'artworks/artwork/components/artworkOwnership';
import ArtworkTransaction from 'artworks/artwork/components/artworkTransaction';
import { ArtworkStatusEnum } from 'artworks/types';
import Accordion from 'theme/atoms/Accordion';
import VisibilityIcon from 'theme/atoms/VisibilityIcon';
import Loader from 'theme/atoms/loader';
import NoDataToDisplay from 'theme/atoms/noDataToDisplay';
import Status from 'theme/atoms/status';

interface ArtworkDataProps {
  artworkId?: string;
  transactionId?: string;
}

const ArtworkData = ({ artworkId, transactionId }: ArtworkDataProps): JSX.Element => {
  const { artworkStore } = useStores();
  const { fetchArtworkData, fetchTransactionArtwork, resetAll, artwork, loading, status } = artworkStore;

  const location = useLocation();
  const isStatusOpen = location.state?.isStatusOpen;

  useEffect(() => {
    if (artworkId) {
      fetchArtworkData(artworkId);
    } else if (transactionId) {
      fetchTransactionArtwork(transactionId);
    }

    return () => {
      resetAll();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artworkId]);

  const noArtworkRender = loading ? (
    <Loader minHeight={200} />
  ) : (
    <NoDataToDisplay copy="No artwork to display." height={100} />
  );

  if (!artwork) return noArtworkRender;

  const sectionTitle = (
    <div className={styles.sectionTitle}>
      <div className={styles.author}>{artwork.author?.name}</div>
      <div className={styles.name}>
        {artwork.name}, <span className={styles.year}>{artwork.year}</span>
      </div>
    </div>
  );

  const sectionImage = (
    <div className={styles.sectionImage}>
      {artwork.assets.imageUrls?.[0]?.presignedUrl ? (
        <img src={artwork.assets.imageUrls?.[0]?.presignedUrl} alt={artwork.name} className={styles.image} />
      ) : null}
    </div>
  );

  const sectionDescription = (
    <div className={styles.sectionDescription}>
      <div className={styles.description}>
        <p>{artwork.medium}</p>
        <p>{artwork.size}</p>
        <p>Edition {artwork.editionNumber}</p>
      </div>
      <div className={styles.id}>{artwork.id}</div>
      <div className={styles.visibility}>
        <span className={styles.visibilityLabel}>visibility:</span>
        <VisibilityIcon visibility={artwork.externalExploringVisibility} />
        <span className={styles.visibilityStatus}>{artwork.externalExploringVisibility}</span>
      </div>
    </div>
  );

  const consignmentsAndTransaction = artworkId && (
    <>
      <ArtworkConsignments />
      <ArtworkTransaction />
    </>
  );

  return (
    <>
      <div className={styles.content}>
        {sectionTitle}
        {sectionImage}
        {sectionDescription}
      </div>

      <div className={styles.details}>
        <Accordion itemsOpenByDefault={[...(isStatusOpen ? ['status'] : [])]}>
          <Accordion.Item id="details" header="Details">
            <ArtworkDetails artwork={artwork} />
          </Accordion.Item>

          <Accordion.Item id="ownership" header="Ownership">
            <ArtworkOwnership />
          </Accordion.Item>

          <Accordion.Item id="status" header="Status">
            <div className={styles.status}>
              <Status status={status || ArtworkStatusEnum.ThirdParty} hasIcon />
              {/* BE returns null instead of '3rd party' here, so '|| ArtworkStatusEnum.ThirdParty' lets us display '3rd party' for this case */}
            </div>
            {consignmentsAndTransaction}
          </Accordion.Item>
        </Accordion>
      </div>
    </>
  );
};

export default observer(ArtworkData);
