import Button, { ButtonType } from 'theme/atoms/Button';
import styles from './ButtonGroup.module.scss';

export type ButtonGroupType = {
  buttonAction?: (() => void) | (() => Promise<void>) | null;
  disabled?: boolean;
  label: string;
  loader?: boolean;
  type?: ButtonType;
  maxWidth?: boolean;
};

interface ButtonGroupProps {
  actionButtons: ButtonGroupType[];
}

const ButtonGroup = ({ actionButtons }: ButtonGroupProps): JSX.Element => {
  return (
    <div className={styles.root}>
      {actionButtons.map(({ label, buttonAction, type = 'primary', loader, disabled, maxWidth = false }, index) => {
        const handleOnClick = (): void => {
          buttonAction && buttonAction();
        };

        return (
          <Button
            buttonType={type}
            disabled={disabled}
            key={index}
            loading={loader}
            maxWidth={maxWidth}
            onClick={handleOnClick}
            text={label}
          />
        );
      })}
    </div>
  );
};

export default ButtonGroup;
