import { useStores } from 'RootStore';
import Big from 'big.js';
import { ProductEnum } from 'billing/types';
import { SpecialMarking, SpecialMarkingKind } from 'theme/atoms/Button/Button';
import { formatPrice } from '../helpers/formatValues';

const BLOCKED_BUTTON_TOOLTIP = 'Resolve payment issues before proceeding. See Billing page in Settings.';
const PAID_BUTTON_TOOLTIP_WHEN_WE_HAVE_PRICE =
  ' fee will be charged in accordance with the price list. See Billing page in Settings for details.';
const PAID_BUTTON_TOOLTIP_WHEN_WE_DONT_HAVE_PRICE =
  'You will be charged in accordance with the price list. See Billing page in Settings for details.';

export const useGetPaidOrBlockedButtonDetails = (field: ProductEnum): SpecialMarking => {
  const { userStore, billingStore } = useStores();

  if (userStore.noPaymentMethods) {
    const blockedButtonDetails = {
      kind: 'blocked' as SpecialMarkingKind,
      tooltip: BLOCKED_BUTTON_TOOLTIP,
    };
    return blockedButtonDetails;
  } else {
    const product = billingStore.products?.[field];
    const price = product && formatPrice(Big(product.amount).div(100), product.currency, 2);
    const paidButtonDetails = {
      kind: 'paid' as SpecialMarkingKind,
      tooltip: price ? price + PAID_BUTTON_TOOLTIP_WHEN_WE_HAVE_PRICE : PAID_BUTTON_TOOLTIP_WHEN_WE_DONT_HAVE_PRICE,
      suffix: price,
      maxWidth: 412,
    };
    return paidButtonDetails;
  }
};
