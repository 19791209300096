import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useStores } from 'RootStore';

const AuthWrapper = (): JSX.Element => {
  const location = useLocation();
  const { authStore } = useStores();
  const { resetSubmitting } = authStore;

  useEffect(() => {
    resetSubmitting();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <div>
      <Outlet />
    </div>
  );
};

export default AuthWrapper;
