export const REQUIRED_FORM_FIELDS = [
  'name',
  'year',
  'executedYear',
  'medium',
  'size',
  'editionNumber',
  'artistResellRightsPercent',
];

export const ASSETS_FIELDS = ['additionalDocUrls', 'certificationUrls', 'imageUrls', 'ownershipProofUrls'];

const OPTIONAL_FORM_FIELDS = ['weight', 'carbonDating', 'description', ...ASSETS_FIELDS];

export const FORM_FIELDS = [
  ...REQUIRED_FORM_FIELDS,
  ...OPTIONAL_FORM_FIELDS,
  'author',
  'owners',
  'existedBefore',
  'artworkLastModifiedAt',
];

export const ARTWORK_STEPS = [
  { id: 1, label: 'Author', path: 'AddArtworkAuthor' },
  { id: 2, label: 'Artwork details', path: 'AddArtworkDetails' },
  { id: 3, label: 'Ownership', path: 'AddArtworkOwnership' },
  { id: 4, label: 'Summary', path: 'AddArtworkSummary' },
  { id: 5, label: 'Payment', path: 'AddArtworkPayment' },
] as const;
