import { ReactNode } from 'react';
import clsx from 'clsx';
import styles from './SectionHeader.module.scss';

interface HeaderProps {
  title?: string;
  children?: ReactNode;
  className?: string;
}
const SectionHeader = ({ title, children, className }: HeaderProps): JSX.Element => {
  return <h2 className={clsx(styles.root, className)}>{title || children}</h2>;
};

export default SectionHeader;
