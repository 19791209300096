import { ChangeEvent } from 'react';
import clsx from 'clsx';
import styles from './TextArea.module.scss';

export interface TextAreaProps {
  label: string;
  name: string;
  value: string;
  placeholder?: string;
  maxLength?: number;
  error?: string;
  disabled?: boolean;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}

const TextArea = ({
  value,
  label,
  name,
  error,
  placeholder,
  maxLength,
  disabled,
  onChange,
}: TextAreaProps): JSX.Element => {
  return (
    <div className={styles.root}>
      {label && (
        <label htmlFor={name} className={styles.label}>
          {label}
        </label>
      )}
      <textarea
        id={name}
        name={name}
        className={clsx(styles.textarea, { [styles.withError]: error })}
        placeholder={placeholder}
        spellCheck={false}
        maxLength={maxLength}
        disabled={disabled}
        onChange={onChange}
        value={value}
      />
      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
};

export default TextArea;
