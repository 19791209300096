import { ReactNode } from 'react';
import { Accordion as MantineAccordion } from '@mantine/core';
import IconComponent, { icons } from 'theme/atoms/iconComponent';
import styles from './Accordion.module.scss';

type AccordionProps = {
  children: ReactNode;
  itemsOpenByDefault?: string[];
  onChange?: (value: string[]) => void;
};

const Accordion = ({ children, itemsOpenByDefault, onChange }: AccordionProps): React.JSX.Element => {
  return (
    <MantineAccordion
      unstyled
      chevron={
        <div className={styles.chevronContainer}>
          <IconComponent icon={icons.expand} />
        </div>
      }
      classNames={styles}
      multiple
      defaultValue={itemsOpenByDefault}
      onChange={onChange}
    >
      {children}
    </MantineAccordion>
  );
};

interface AccordionItemProps {
  id: string;
  header: ReactNode;
  children: ReactNode;
}

const AccordionItem = ({ id, header, children }: AccordionItemProps): React.JSX.Element => (
  <MantineAccordion.Item value={id}>
    <MantineAccordion.Control>{header}</MantineAccordion.Control>
    <MantineAccordion.Panel>{children}</MantineAccordion.Panel>
  </MantineAccordion.Item>
);

Accordion.Item = AccordionItem;

export default Accordion;
