import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import Big from 'big.js';
import { ProductEnum } from 'billing/types';
import { Contact } from 'domain/types/Contact.types';
import { TransactionStatus } from 'domain/types/Transactions.types';
import { addMeToOrganizationName } from 'domain/utils';
import { SpecialMarking } from 'theme/atoms/Button/Button';
import FormError from 'theme/atoms/formError';
import Modal from 'theme/atoms/modal';
import {
  TransactionActions,
  shouldThisOrgEditTheTransactionNow,
  shouldThisOrgHavePermissionToEditTheTransaction,
  transactionStatusesAllowingRejection,
} from 'transactions/domain';
import { TransactionDtoVote, TransactionWrapper } from 'transactions/requests.types';
import Approval, {
  ApprovalAction,
  ApprovalProps,
  OtherApprovalsNeeded,
} from 'transactions/transaction/components/approval/Approval';
import { ValidationError } from 'transactions/transaction/transactionForm/TransactionFormStore';
import { DEFAULT_TRANSACTION_CATEGORY } from 'transactions/transactions/types';
import { useTransactionRoles } from 'utils/hooks';
import { useGetPaidOrBlockedButtonDetails } from 'utils/hooks/useGetPaidOrBlockedButtonDetails';

const FormErrorEl = observer(({ validationErrors }: { validationErrors: ValidationError[] }): JSX.Element => {
  if (!validationErrors.length) return <></>;
  return <FormError showError={true} />;
});

interface ActionsProps {
  isSummaryView: boolean;
  setIsSummaryView: (val: boolean) => void;
}

const Actions = ({ isSummaryView, setIsSummaryView }: ActionsProps): JSX.Element => {
  const { transactionFormStore } = useStores();
  const transactionWrapper = transactionFormStore.transactionWrapper as TransactionWrapper;
  const { validationErrors } = transactionFormStore;
  const { isSellerOwnerAsRepresentative, isSellerSide } = useTransactionRoles({ transactionWrapper });

  const navigate = useNavigate();
  const [confirmationModalDetails, setConfirmationModalDetails] = useState<{
    label: string;
    action: () => Promise<boolean | void>;
  } | null>(null);
  const [loadingItems, setLoadingItems] = useState<string[]>([]);

  const getActionDetails = (
    label: string,
    action: () => Promise<boolean | void>,
    specialMarking?: SpecialMarking
  ): ApprovalAction => {
    return {
      label: label,
      onClick: () =>
        setConfirmationModalDetails({
          label: label,
          action: () => handleAction(label, action),
        }),
      loading: loadingItems.includes(label),
      specialMarking,
    };
  };

  const handleAction = (label: string, action: () => Promise<boolean | void>): Promise<boolean | void> => {
    setLoadingItems([...loadingItems, label]);
    return action().then((isSuccessfull) => {
      if (isSuccessfull) navigate(routes.Transactions.path(DEFAULT_TRANSACTION_CATEGORY));
      setLoadingItems(loadingItems.filter((item) => item !== label));
    });
  };

  const paidOrBlockedButtonDetailsForCreateTransaction = useGetPaidOrBlockedButtonDetails(ProductEnum.Transaction);

  const approvalProps = (): ApprovalProps => {
    const { canUserPerformAction, updateVote, createTransaction, updateTransaction, checkValidations } =
      transactionFormStore;
    const { transaction, consignment, artwork } = transactionWrapper;
    const shouldEditNow = shouldThisOrgEditTheTransactionNow(transactionWrapper);
    const shouldHavePermissionToEdit = shouldThisOrgHavePermissionToEditTheTransaction(transactionWrapper);
    const canTransactionBeRejected = transactionStatusesAllowingRejection.includes(transaction.status);
    const wasSellerOwnerApprovalRequired =
      consignment?.currency !== transaction.currency ||
      !Big(transaction.sellerInfo?.price || 0).eq(consignment.askingPrice) ||
      Big(transaction.sellerInfo?.totalCommission || 0).gt(consignment?.maxCommission);

    const getVotesData = (
      votes: TransactionDtoVote[],
      contacts: Contact[],
      isSellerSide?: boolean
    ): OtherApprovalsNeeded[] => {
      return contacts?.map((contact) => {
        // when the owner creates an offer and has not sent it yet (FE does not have agreements yet)
        const isOwnerCreatingOfferNow = transaction.status === TransactionStatus.Draft && contact.self;
        const titleForSellerSide = contacts.length > 1 ? 'Co-owner' : 'Owner';
        return {
          name: addMeToOrganizationName(contact),
          title: isSellerSide ? titleForSellerSide : contact.organizationTitle,
          approved:
            isOwnerCreatingOfferNow ||
            votes.find((vote) =>
              contact.self ? vote.contact.self : vote.contact.contactConnectionId === contact?.contactConnectionId
            )?.agreement,
        } as OtherApprovalsNeeded;
      });
    };

    const getOwnersApprovals = (): OtherApprovalsNeeded[] | void => {
      if (
        wasSellerOwnerApprovalRequired &&
        [TransactionStatus.Draft, TransactionStatus.PendingOwnersAgreement].includes(transaction.status)
      )
        return getVotesData(
          transaction.sellerInfo?.ownersVotes || [],
          artwork.owners.map((owner) => owner.contact as Contact),
          true
        );
    };

    const getBuyersApprovals = (): OtherApprovalsNeeded[] | void => {
      if (TransactionStatus.PendingBuyersAgreement === transaction.status && transaction.buyerInfo)
        return getVotesData(
          transaction.buyerInfo.buyersVotes || [],
          transaction.buyerInfo.buyers?.map((buyer) => buyer.contact as Contact)
        );
    };

    const actionCases = [
      {
        condition:
          canUserPerformAction(TransactionActions.RejectAsSellerRepresentative) &&
          isSummaryView &&
          canTransactionBeRejected,
        actions: [getActionDetails('Reject', () => updateVote('sellerRepresentative', false))],
        otherApprovalsNeeded: getOwnersApprovals(),
      },
      {
        condition: canUserPerformAction(TransactionActions.SeeSellerSide) && !isSummaryView,
        actions: [
          {
            label: 'Go to summary',
            onClick: () => {
              const validationErrs = checkValidations('sellerRepresentative');
              if (!validationErrs.length) setIsSummaryView(true);
            },
          },
          { label: 'Cancel', onClick: () => navigate(routes.Transactions.path(DEFAULT_TRANSACTION_CATEGORY)) },
        ],
      },
      {
        condition: canUserPerformAction(TransactionActions.Publish) && shouldEditNow && isSummaryView,
        actions: [
          getActionDetails('Confirm and send', createTransaction, paidOrBlockedButtonDetailsForCreateTransaction),
          { label: 'Back to editing', onClick: () => setIsSummaryView(false) },
        ],
        otherApprovalsNeeded: getOwnersApprovals(),
      },
      {
        condition:
          canUserPerformAction(TransactionActions.ApproveAsSellerOwner) && isSummaryView && shouldHavePermissionToEdit,
        actions: [
          getActionDetails('Approve', () => updateVote('owner', true)),
          getActionDetails('Reject', () => updateVote('owner', false)),
        ],
        otherApprovalsNeeded: getOwnersApprovals(),
      },
      {
        condition:
          canUserPerformAction(TransactionActions.RejectAsSellerOwner) &&
          isSummaryView &&
          canTransactionBeRejected &&
          (wasSellerOwnerApprovalRequired || isSellerOwnerAsRepresentative),
        actions: [getActionDetails('Reject', () => updateVote('owner', false))],
        otherApprovalsNeeded: getOwnersApprovals(),
      },
      {
        condition: canUserPerformAction(TransactionActions.SeeBuyerSide) && !isSummaryView,
        actions: [
          {
            label: 'Go to summary',
            onClick: () => {
              const validationErrs = checkValidations('buyerRepresentative');
              if (!validationErrs.length) setIsSummaryView(true);
            },
          },
          getActionDetails('Reject', () => updateVote('buyerRepresentative', false)),
        ],
      },

      {
        condition: canUserPerformAction(TransactionActions.EditBuyerSide) && isSummaryView && shouldEditNow,
        actions: [
          getActionDetails('Confirm and send', updateTransaction),
          { label: 'Back to editing', onClick: () => setIsSummaryView(false) },
        ],
      },
      {
        condition:
          canUserPerformAction(TransactionActions.RejectAsBuyerRepresentative) &&
          isSummaryView &&
          canTransactionBeRejected,
        actions: [getActionDetails('Reject', () => updateVote('buyerRepresentative', false))],
      },
      {
        condition:
          canUserPerformAction(TransactionActions.ApproveAsBuyer) && isSummaryView && shouldHavePermissionToEdit,
        actions: [
          getActionDetails('Approve', () => updateVote('buyer', true)),
          getActionDetails('Reject', () => updateVote('buyer', false)),
        ],
        otherApprovalsNeeded: getBuyersApprovals(),
      },
      {
        condition: canUserPerformAction(TransactionActions.RejectAsBuyer) && isSummaryView && canTransactionBeRejected,
        actions: [getActionDetails('Reject', () => updateVote('buyer', false))],
        otherApprovalsNeeded: getBuyersApprovals(),
      },
    ];
    const matchedCase = actionCases.find((action) => action.condition);

    return {
      actions: matchedCase?.actions || [],
      otherApprovalsNeeded: matchedCase?.otherApprovalsNeeded || undefined,
      isSellerSide: isSellerSide,
    };
  };

  return (
    <>
      <Approval {...approvalProps()}>
        <FormErrorEl validationErrors={validationErrors} />
      </Approval>
      <Modal
        isOpen={!!confirmationModalDetails}
        onClose={() => setConfirmationModalDetails(null)}
        title={`${confirmationModalDetails?.label}?`}
        subtitle={`Are you sure you want to ${confirmationModalDetails?.label.toLowerCase()} this offer?`}
        btnAbort={{ label: 'Cancel', function: () => setConfirmationModalDetails(null) }}
        btnConfirm={{
          label: 'Confirm',
          function: () => {
            confirmationModalDetails?.action();
            setConfirmationModalDetails(null);
          },
        }}
      />
    </>
  );
};

export default observer(Actions);
