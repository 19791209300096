import { NumberFormatBase, NumericFormat, NumericFormatProps } from 'react-number-format';
import clsx from 'clsx';
import { Currency } from 'domain/types/Domain.types';
import { USER_LANGUAGE } from 'utils/helpers';
import styles from './CurrencyInputWrapper.module.scss';

interface CurrencyInputWrapperProps extends NumericFormatProps {
  error?: string;
  errorClassName?: string;
  label?: string;
  endIcon?: JSX.Element;
  endIconClassName?: string;
  currency?: Currency;
}

const CurrencyInputWrapper = ({
  error = '',
  errorClassName,
  endIcon,
  endIconClassName,
  allowNegative = false,
  className,
  currency,
  ...props
}: CurrencyInputWrapperProps): JSX.Element => {
  const getFormattedCurrencyValue = (value: string): string => {
    if (!value) return '';

    return new Intl.NumberFormat(USER_LANGUAGE, {
      style: 'currency',
      currency: currency,
      maximumFractionDigits: 0,
    }).format(BigInt(value));
  };

  return (
    <div className={clsx(styles.root, className)}>
      {props.label && (
        <label htmlFor={props.name} className={styles.label}>
          {props.label}
        </label>
      )}
      {currency ? (
        <NumberFormatBase
          className={clsx(styles.input, { [styles.inputError]: error })}
          {...props}
          format={getFormattedCurrencyValue}
        />
      ) : (
        <NumericFormat
          allowNegative={allowNegative}
          className={clsx(styles.input, { [styles.inputError]: error })}
          {...props}
        />
      )}

      {endIcon && <div className={clsx(styles.endIcon, endIconClassName)}>{endIcon}</div>}
      {error && <span className={clsx(styles.error, errorClassName)}>{error}</span>}
    </div>
  );
};

export default CurrencyInputWrapper;
