import { makeAutoObservable, runInAction } from 'mobx';
import { RootStore } from 'RootStore';
import * as requests from 'bankPerspective/bankTransactions/requests';
import { BANK_ACCOUNT_FIELDS } from 'domain/constants';
import { getMessageFromApiErrorResponse } from 'domain/getMessageFromApiErrorResponse';
import { RawBankAccount } from 'domain/types/BankAccount.types';
import { FieldDetail } from 'domain/types/FieldDetail';
import { StoreActionReturn } from 'domain/types/StoreActionReturn';
import { TransactionStatus } from 'domain/types/Transactions.types';

class EscrowAccountFormStore {
  rootStore: RootStore;

  escrowAccount: RawBankAccount | undefined = undefined;
  formData: RawBankAccount | undefined = undefined;
  escrowAccountFormIsOpen = false;
  loadingEscrowAccountDetails = false;
  submittingEscrowAccountDetails = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  get fetchedBankAccountData(): FieldDetail[] {
    return BANK_ACCOUNT_FIELDS.map((el) => ({ ...el, value: this.escrowAccount?.[el.name] || '' }));
  }

  setFormData = (formData?: RawBankAccount): void => {
    const fetchedBankData = this.fetchedBankAccountData.reduce(
      (acc, detail) => ({
        ...acc,
        [detail.name]: detail.value,
      }),
      {}
    );
    this.formData = formData || (fetchedBankData as RawBankAccount);
  };

  setEscrowAccountFormIsOpen = (isOpen: boolean): void => {
    this.escrowAccountFormIsOpen = isOpen;
  };

  fetchEscrowAccountDetails = (transactionId: string): Promise<void> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.loadingEscrowAccountDetails = true));

    return requests
      .fetchEscrowAccountDetails(transactionId)
      .then(({ data }) => {
        const { data: accountData, escrow } = data;
        runInAction(() => {
          this.escrowAccount = {
            ...accountData,
            isEscrow: escrow,
          };
        });
      })
      .catch(({ response }) => {
        addToast(
          getMessageFromApiErrorResponse(
            response?.data.type,
            toastMessages.BANK_TRANSACTIONS.ACCOUNT_DETAILS_FETCH_ERROR
          ),
          'error'
        );
      })
      .finally(() => runInAction(() => (this.loadingEscrowAccountDetails = false)));
  };

  updateEscrowAccount = (): Promise<StoreActionReturn | void> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    const { selectedTransaction, setSelectedTransactionStatus } = this.rootStore.bankTransactionStore;

    if (!this.formData || !selectedTransaction) return Promise.resolve();

    const isCreating = !this.escrowAccount;

    runInAction(() => {
      if (!this.formData) return Promise.resolve();
      this.formData = {
        ...this.formData,
        iban: this.formData.iban.trim().toUpperCase(),
        swift: this.formData.swift.trim().toUpperCase(),
      };

      this.submittingEscrowAccountDetails = true;
    });

    const requestMethod = isCreating ? requests.createEscrowAccount : requests.editEscrowAccount;
    const successMessage = isCreating
      ? toastMessages.BANK_TRANSACTIONS.ACCOUNT_CREATE_SUCCESS
      : toastMessages.BANK_TRANSACTIONS.ACCOUNT_EDIT_SUCCESS;
    const errorMessage = isCreating
      ? toastMessages.BANK_TRANSACTIONS.ACCOUNT_CREATE_ERROR
      : toastMessages.BANK_TRANSACTIONS.ACCOUNT_EDIT_ERROR;

    return requestMethod(selectedTransaction.id, this.formData)
      .then(() => {
        addToast(successMessage, 'success');
        runInAction(() => (this.escrowAccount = this.formData));

        if (isCreating) setSelectedTransactionStatus(TransactionStatus.AcceptedEscrowAccountGranted);

        return {
          success: true,
        };
      })
      .catch(() => {
        addToast(errorMessage, 'error');
      })
      .finally(() => {
        runInAction(() => (this.submittingEscrowAccountDetails = false));
      });
  };

  resetEscrowAccount = (): void => {
    runInAction(() => (this.escrowAccount = undefined));
  };

  resetAll = (): void => {
    runInAction(() => {
      this.escrowAccount = undefined;
      this.formData = undefined;
      this.escrowAccountFormIsOpen = false;
    });
  };
}

export default EscrowAccountFormStore;
