import { AxiosResponse } from 'axios';
import { TimestampsAndTraces } from 'api/types';
import Big from 'big.js';
import { Asset } from 'domain/types/Asset.types';
import { Contact, Address } from 'domain/types/Contact.types';
import { OrganizationKind } from 'organization/types';
import { ARTWORK_STEPS, ASSETS_FIELDS } from './addArtwork/constants';

export enum ExternalExploringVisibility {
  Public = 'public',
  Private = 'private',
  LimitedToContactList = 'limited to contact list',
}

export enum ArtworkStatusEnum {
  Owned = 'owned - available',
  OwnedReserved = 'owned - reserved',
  OwnedConsigned = 'owned - consigned',
  OwnedConsignedAwaitingConsigning = 'owned - consigned - awaiting consigning',
  OwnedTransactionAccepted = 'owned - transaction accepted',
  OnConsignment = 'on consignment',
  OnConsignmentReserved = 'on consignment - reserved',
  ThirdParty = '3rd party',
  ThirdPartyAwaitingConsigning = '3rd party - awaiting consigning',
  Draft = 'Draft',
  DraftAwaitingApproval = 'Awaiting approval',
  DraftRejected = 'Rejected',
  DraftApproved = 'Approved',
}

export type ArtworkId = string;
export enum ArtworkState {
  Certified = 'Certified',
  Draft = 'Draft',
  DraftAwaitingApproval = 'DraftAwaitingApproval',
  DraftRejected = 'DraftRejected',
}

export type CategoryId = 'main' | 'all' | 'faved';

export interface Category {
  id: CategoryId;
  label: string;
}

export interface FilterParams {
  creatorOrganizationId?: string;
  externalExploringVisibility?: string[];
  tag?: string[];
  state?: string[];
  status?: string[];
  yearLike?: string;
  yearFrom?: number;
  yearTo?: number;
  executedYearLike?: string;
  executedYearFrom?: number;
  executedYearTo?: number;
  sizeNumber1From?: number;
  sizeNumber1To?: number;
  sizeNumber2From?: number;
  sizeNumber2To?: number;
  weightNumberFrom?: number;
  weightNumberTo?: number;
}

export interface ArtworkDetails {
  executedYear?: number;
  carbonDating?: string;
  editionNumber?: string;
  weight?: string;
  size?: string;
  medium?: string;
  description?: string;
  name: string;
  artistResellRightsPercent?: number;
  year: number;
}

export type ArtworkOwner = {
  // TODO sprawdzić, czy ten Contact jest taki sam dla transakcji i dla artworka
  // chodzi o isRequired pól (na ten moment jest rozbieżność)
  contact?: Contact;
  participationPercent: number;
  startDate: Date;
};

export interface Artwork extends TimestampsAndTraces, ArtworkDetails {
  id: ArtworkId;
  externalExploringVisibility: keyof typeof ExternalExploringVisibility;
  tags: string[];
  authorOrganizationId: string;
  author: Author;
  assets: { [key in (typeof ASSETS_FIELDS)[number]]: Asset[] };
  state: ArtworkState;
  price?: Big;
  exploringOrganizationArtworkStatus: ArtworkStatusEnum;
  owners: ArtworkOwner[];
  draftCreationStep?: (typeof ARTWORK_STEPS)[number]['id'];
  existedBefore: boolean;
  isOwner: boolean;
  artworkAwaitingApprovals: { awaiting: number; total: number };
  isEditable: boolean;
}

export interface Author {
  name: string;
  id?: string;
  address?: Address;
  title?: string;
  type?: OrganizationKind;
  self?: boolean;
}

export type TagsListReturn = Promise<AxiosResponse<TagsListResponse>>;
export interface TagsListResponse {
  count: number;
  tags: string[];
}

export interface ArtworksRequestParams extends FilterParams {
  id?: ArtworkId;
  organizationIdProfile?: string;
  phrase?: string;
  artworkFolderId?: string;
  favourite?: boolean;
  priceMin?: number;
  priceMax?: number;
  sortBy?: string[];
  pageSize?: number;
  pageNumber?: number;
}
