import { ConsignmentStatus } from 'consignments/types';
import { TransactionStatus } from 'domain/types/Transactions.types';

export const CONSIGNMENT_STATUSES_VISIBLE_IN_ARTWORK_DETAILS: ConsignmentStatus[] = [
  ConsignmentStatus.Accepted,
  ConsignmentStatus.PendingConsigneeAgreement,
  ConsignmentStatus.PendingOwnersAgreement,
];
export const TRANSACTION_STATUSES_VISIBLE_IN_ARTWORK_DETAILS: TransactionStatus[] = [
  TransactionStatus.AcceptedEscrowAccountGranted,
  TransactionStatus.Executing,
  TransactionStatus.InvoiceVerification,
  TransactionStatus.PendingBuyersAgreement,
  TransactionStatus.PendingOwnersAgreement,
  TransactionStatus.ReadyForExecution,
  TransactionStatus.SentToBuyerRepresentative,
  TransactionStatus.SignificantPaymentsMade,
  TransactionStatus.WaitingForPayment,
];
