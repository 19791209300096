import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import Big from 'big.js';
import { Button, Subtitle, TitleBlack } from 'theme/atoms';
import { formatPrice } from 'utils/helpers';
import styles from './CreateOrganization.module.scss';

const VerifyAccount = (): JSX.Element => {
  const { billingStore, toastsStore } = useStores();
  const { fetchProducts, loadingProducts, loadedProducts, products, openPortal, portalUrl, fetchPaymentMethods } =
    billingStore;
  const { addToast } = toastsStore;

  const kycPrice = products?.KYC && formatPrice(Big(products.KYC.amount).div(100), products.KYC.currency, 2);

  const [portalHasBeenOpen, setPortalHasBeenOpen] = useState(false);

  useEffect(() => {
    fetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEditMethodPayment = (): void => {
    if (portalUrl) {
      setPortalHasBeenOpen(true);
      window.open(portalUrl, '_blank', 'noreferrer');
    } else {
      openPortal().then((url) => {
        if (url) {
          setPortalHasBeenOpen(true);
          url && window.open(url, '_blank', 'noreferrer');
        }
      });
    }
  };

  const checkPaymentMethodsAndContinue = (): void => {
    fetchPaymentMethods(true).then((isPaymentMethodActive) => {
      if (!isPaymentMethodActive) {
        addToast('Cannot find payment method. Please add again.', 'error');
        setPortalHasBeenOpen(false);
      }
    });
  };

  const elementsBeforePortalWasOpen = (
    <>
      <Subtitle>Tap below to verify your account and complete your registration.</Subtitle>
      <Button text="Setup billing method" onClick={handleEditMethodPayment} maxWidth />
    </>
  );

  const elementsAfterPortalWasOpen = (
    <>
      <div className={styles.succeededQuestion}>Succeeded?</div>
      <Button text="Continue" onClick={() => checkPaymentMethodsAndContinue()} maxWidth />
      <Button
        text="Open billing method setup again"
        onClick={handleEditMethodPayment}
        buttonType="textButton"
        maxWidth
        className={styles.textButtonAsSecond}
      />
    </>
  );

  const kycPriceElement =
    !kycPrice && loadedProducts ? (
      <Button text="Fetch price" onClick={() => fetchProducts(true)} loading={loadingProducts} />
    ) : (
      kycPrice
    );

  return (
    <div className={styles.centerContent}>
      <div className={styles.innerWrapper}>
        <div className={styles.centerContent}>
          <TitleBlack text="Secure Your Account" dontLimitWidth />
          <Subtitle>Every user in our system is verified, ensuring a secure and trustworthy environment.</Subtitle>
          <div className={styles.verificationFeeTitle}>There is a one-time fee for account verification:</div>
          <div className={styles.verificationFeeAmount}>{kycPriceElement}</div>
          {!portalHasBeenOpen ? elementsBeforePortalWasOpen : elementsAfterPortalWasOpen}
        </div>
      </div>
    </div>
  );
};
export default observer(VerifyAccount);
