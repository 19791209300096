import { routes } from 'app/routes/paths.const';
import { DEFAULT_ARTWORK_DRAFT_CATEGORY } from 'artworks/artworkRegistration/constants';
import { Button } from 'theme/atoms';
import styles from '../../AddArtwork.module.scss';

const NoArtworkFound = (): JSX.Element => {
  return (
    <div className={styles.noArtworkFound}>
      No artwork found.
      <Button
        text="Go to artwork registration"
        to={routes.ArtworkRegistration.path(DEFAULT_ARTWORK_DRAFT_CATEGORY)}
        buttonType="secondary"
      />
    </div>
  );
};

export default NoArtworkFound;
