import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { bankAccountZodFormSchema } from 'domain/bankAccountZodFormSchema';
import { RawBankAccount } from 'domain/types/BankAccount.types';
import { Button, Input, Subtitle, TitleBlack } from 'theme/atoms';
import FormError from 'theme/atoms/formError';
import { useZodFormValidation } from 'utils/hooks';
import styles from './CreateOrganization.module.scss';

const BankAccount = (): JSX.Element => {
  const { bankStore } = useStores();
  const { loading, updateBankAccount, fetchedBankAccountData, formData, setFormData } = bankStore;

  const { validateData, errors, clearErrors } = useZodFormValidation(
    bankAccountZodFormSchema,
    formData as RawBankAccount
  );

  useEffect(() => {
    setFormData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    clearErrors();
    formData && setFormData({ ...formData, [event.target.name]: event.target.value || '' });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    if (!validateData()) return;
    updateBankAccount();
  };

  const areThereAnyValidationErrors = Object.keys(errors).length > 0;

  return (
    <div className={styles.innerWrapper}>
      <TitleBlack text="Provide your bank account information" dontLimitWidth />
      <Subtitle>Fill in your bank account information to receive payments.</Subtitle>
      <form className={styles.form} onSubmit={handleSubmit}>
        {fetchedBankAccountData.map(({ name, label }) => {
          return (
            <Input
              key={name}
              value={formData ? formData[name] : ''}
              name={name}
              label={label || name}
              onChange={handleChange}
              error={errors[name]?.[0]}
              onBlur={() =>
                name === 'iban' && formData && setFormData({ ...formData, iban: formData.iban.replaceAll(' ', '') })
              }
              className={errors[name]?.[0] ? styles.errorIbanInput : ''}
            />
          );
        })}
        <div className={styles.submitButtonContainerBank}>
          <FormError showError={areThereAnyValidationErrors} />
          <Button text="Continue" maxWidth type="submit" loading={loading} disabled={areThereAnyValidationErrors} />
        </div>
      </form>
    </div>
  );
};
export default observer(BankAccount);
