import { makeAutoObservable, runInAction } from 'mobx';
import { RootStore } from 'RootStore';
import { CategoryId } from './networkList/types';
import * as requests from './requests';
import { ContactConnection } from './types';

class NetworkManagingStore {
  rootStore: RootStore;

  contactConnection: ContactConnection | undefined = undefined;
  fetchingContactConnection = false;
  loadingDelete = false;
  loadingUpdate = false;
  loadingAccept = false;
  loadingIgnore = false;
  loadingExpire = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  getContactConnection = (connectionId: string): Promise<void> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.fetchingContactConnection = true));

    return requests
      .fetchContactConnection(connectionId)
      .then(({ data }) => {
        this.contactConnection = data.results[0];
      })
      .catch(() => addToast(toastMessages.DEFAULT.ERROR, 'error'))
      .finally(() => runInAction(() => (this.fetchingContactConnection = false)));
  };

  deleteContactConnection = (connectionId: string, list: CategoryId | undefined, cb?: () => void): Promise<void> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.loadingDelete = true));

    return requests
      .deleteContactConnection(connectionId)
      .then(() => {
        if (list === 'network' || list === 'requested') {
          const listNetwork = this.rootStore.listNetworkStore.items as ContactConnection[];
          const newListNetwork = listNetwork.filter((el) => el.id !== connectionId);
          this.rootStore.listNetworkStore.setItems(newListNetwork);
        }
        addToast('The contact was successfully deleted.', 'success');
      })
      .then(() => {
        if (cb) cb();
      })
      .catch(() => addToast(toastMessages.DEFAULT.ERROR, 'error'))
      .finally(() => runInAction(() => (this.loadingDelete = false)));
  };

  updateContactConnection = (connectionId: string, primaryContactId?: string, note?: string): Promise<void> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.loadingUpdate = true));

    return requests
      .updatePrimaryContactInContactConnection(connectionId, primaryContactId, note)
      .then(({ data }) => {
        this.contactConnection = data;
        addToast('The contact was successfully updated.', 'success');
      })
      .catch(() => addToast(toastMessages.DEFAULT.ERROR, 'error'))
      .finally(() => runInAction(() => (this.loadingUpdate = false)));
  };

  acceptContactConnection = (connectionId: string, list): Promise<void> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.loadingAccept = true));

    return requests
      .acceptContactConnection(connectionId)
      .then(({ data }) => {
        if (list === 'network') {
          const newListNetwork = [data, ...this.rootStore.listNetworkStore.items];
          this.rootStore.listNetworkStore.setItems(newListNetwork);
        } else if (list === 'pending') {
          const listPendingInvitations = this.rootStore.listNetworkStore.items as ContactConnection[];
          const newListPendingInvitations = listPendingInvitations.filter((el) => el.id !== connectionId);
          this.rootStore.listNetworkStore.setItems(newListPendingInvitations);
        }
        addToast('The contact was accepted.', 'success');
      })
      .catch(() => addToast(toastMessages.DEFAULT.ERROR, 'error'))
      .finally(() => runInAction(() => (this.loadingAccept = false)));
  };

  ignoreContactConnection = (connectionId: string, list): Promise<void> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.loadingIgnore = true));

    return requests
      .ignoreContactConnection(connectionId)
      .then(() => {
        if (list === 'pending') {
          const listPendingInvitations = this.rootStore.listNetworkStore.items as ContactConnection[];
          const newListPendingInvitations = listPendingInvitations.filter((el) => el.id !== connectionId);
          this.rootStore.listNetworkStore.setItems(newListPendingInvitations);
        }
        addToast('The contact was ignored.', 'success');
      })
      .catch(() => addToast(toastMessages.DEFAULT.ERROR, 'error'))
      .finally(() => runInAction(() => (this.loadingIgnore = false)));
  };

  expireContactInvitation = (invitationId: string): Promise<void> => {
    // unused
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.loadingExpire = true));

    return requests
      .expireContactInvitation(invitationId)
      .then(() => {
        // delete from relevant list
        addToast('The invitation was set to expired.', 'success');
      })
      .catch(() => addToast(toastMessages.DEFAULT.ERROR, 'error'))
      .finally(() => runInAction(() => (this.loadingExpire = false)));
  };

  resetAll = (): void => {
    runInAction(() => {
      this.contactConnection = undefined;
    });
  };
}

export default NetworkManagingStore;
