import clsx from 'clsx';
import Avatar from 'theme/atoms/avatar';
import styles from './ContactAvatar.module.scss';

interface ContactAvatarProps {
  id: string;
  size?: 'small' | 'big';
}

const ContactAvatar = ({ id, size = 'big' }: ContactAvatarProps): JSX.Element => {
  return (
    <div className={clsx(styles.root, styles[size])}>
      <Avatar id={id} />
    </div>
  );
};

export default ContactAvatar;
