import { Address } from 'domain/types/Contact.types';
import { ContactConnection } from './types';

export const getAddress = (address: Address): string | undefined => {
  if (!address) return;
  const { country, city } = address;

  if (country && city) return `${city}, ${country}`;
};

export const isContactConnection = (obj: unknown): obj is ContactConnection => {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    'id' in obj &&
    'contactOrganizationName' in obj &&
    'contactOrganizationTitle' in obj &&
    'contactOrganizationAddress' in obj &&
    'status' in obj
  );
};
