import { action, makeObservable } from 'mobx';
import { RootStore } from 'RootStore';
import PaginationBasicStore, {
  PaginationBasicStoreProps as BasicStoreProps,
} from 'domain/PaginationBasicStore/PaginationBasicStore';
import * as networkRequests from 'network/requests';

const ExtendedStoreProps = {
  fetchItems: action,
};

class NetworkStore extends PaginationBasicStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    super(rootStore);
    this.rootStore = rootStore;
    makeObservable(this, { ...BasicStoreProps, ...ExtendedStoreProps });
  }

  fetchItems = async (): Promise<void> => {
    this.handleFetchItems(networkRequests.fetchContactConnections, {
      ...this.rootStore.networkSortAndFilterStore.filterByAsParams(),
    });
  };
}

export default NetworkStore;
