import { ReactNode } from 'react';
import { Tooltip as MantineTooltip } from '@mantine/core';
import styles from './Tooltip.module.scss';

interface TooltipProps {
  children: ReactNode;
  contentText: string;
  isDisabled?: boolean;
}

const Tooltip = ({ children, contentText, isDisabled }: TooltipProps): JSX.Element => {
  return (
    <MantineTooltip
      label={contentText}
      offset={12}
      withArrow
      arrowSize={7}
      multiline
      maw={320}
      openDelay={100}
      closeDelay={100}
      classNames={styles}
      disabled={isDisabled}
    >
      {children}
    </MantineTooltip>
  );
};

export default Tooltip;
