import { useState, FormEvent } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import { EMAIL_REGEX } from 'domain/regex';
import { FormValidation } from 'domain/types';
import { ReactComponent as HiddenIcon } from 'theme/assets/svg/hidden1.svg';
import { ReactComponent as ShowIcon } from 'theme/assets/svg/shown1.svg';
import { Button, Input, Subtitle, TitleWhite } from 'theme/atoms';
import Breadcrumbs, { Breadcrumb } from 'theme/atoms/Breadcrumbs';
import Topbar from 'theme/atoms/Topbar';
import { useFormValidation, useTitle } from 'utils/hooks';
import styles from './Auth.module.scss';

const FORM_VALIDATIONS: FormValidation = {
  emailRequired: '',
  emailInvalid: '',
  passwordRequired: '',
};

const BREADCRUMBS: Breadcrumb[] = [
  {
    label: 'Login',
  },
];

function AuthStart(): JSX.Element {
  const navigate = useNavigate();
  const { authStore } = useStores();
  const { login, submitting, submittingLogout } = authStore;

  const location = useLocation();
  const redirectUrl = location.state as string | undefined;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  useTitle('Login');

  const { cleanValidationErrors, formValidationMessages, handleValidation, validation } =
    useFormValidation(FORM_VALIDATIONS);

  const handleEmailChange = (value: string): void => {
    cleanValidationErrors(['emailRequired', 'emailInvalid']);
    setEmail(value);
  };

  const handlePasswordChange = (value: string): void => {
    cleanValidationErrors(['passwordRequired']);
    setPassword(value);
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>): void | undefined => {
    const trimmedEmail = email.trim();
    event.preventDefault();
    handleValidation('emailRequired', formValidationMessages.EMAIL_REQUIRED, !email);
    handleValidation('emailInvalid', formValidationMessages.EMAIL_INVALID, !!email && !EMAIL_REGEX.test(trimmedEmail));
    handleValidation('passwordRequired', formValidationMessages.PASSWORD_REQUIRED, !password);

    if (submittingLogout || !email || !EMAIL_REGEX.test(trimmedEmail) || !password) return;

    login(trimmedEmail, password).then((response) => {
      if (redirectUrl) navigate(redirectUrl);
      else if (response?.redirectUrl) navigate(response.redirectUrl, { state: { success: response.success } });
    });
  };

  return (
    <div className={clsx(styles.root, styles.twoColumnLayout)}>
      <div className={styles.headerWrapper}>
        <Topbar>
          <Breadcrumbs breadcrumbs={BREADCRUMBS} />
        </Topbar>
        <TitleWhite text="Welcome back!" />
      </div>
      <div className={clsx('card', styles.formWrapper)}>
        <div className={styles.logo}></div>

        <form onSubmit={onSubmit} className={clsx('form', styles.form)}>
          <Subtitle>
            <>
              <p>Log in to continue.</p>
              <p>
                Don't have an account?{' '}
                <Button to={routes.SignupCredentials.path()} text="Register" buttonType="textButton" />
              </p>
            </>
          </Subtitle>
          <Input
            label="Email"
            name="email"
            onChange={(e) => handleEmailChange(e.target.value)}
            placeholder="Enter your email"
            value={email}
            error={validation.emailRequired || validation.emailInvalid}
          />
          <Input
            label="Password"
            name="password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => handlePasswordChange(e.target.value)}
            error={validation.passwordRequired}
            type={isPasswordVisible ? 'text' : 'password'}
            endIcon={
              <div className={styles.passwordIcon} onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
                {isPasswordVisible ? <ShowIcon /> : <HiddenIcon />}
              </div>
            }
          />
          <Button maxWidth text="Login" loading={submitting} type="submit" disabled={submittingLogout} />
        </form>
        <Subtitle center>
          <Button to={routes.ResetPasswordGetEmail.path()} text="Forgot your password?" buttonType="textButton" />
        </Subtitle>
      </div>
    </div>
  );
}

export default observer(AuthStart);
