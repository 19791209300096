import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import { ReactComponent as AddIcon } from 'theme/assets/svg/add.svg';
import ActionButtons, { ActionButton } from 'theme/atoms/ActionButtons';
import Breadcrumbs, { Breadcrumb } from 'theme/atoms/Breadcrumbs';
import Topbar from 'theme/atoms/Topbar';
import SlidingPanel from 'theme/atoms/slidingPanel';
import { ReactComponent as OptionsIcon } from '../assets/options.svg';
import ViewOptions, { ViewOptionsRef } from './viewOptions/ViewOptions';

const BREADCRUMBS: Breadcrumb[] = [{ label: 'Catalog', route: 'Artworks' }];

const ArtworksTopbar = (): JSX.Element => {
  const viewOptionsRef = useRef<ViewOptionsRef>(null);
  const navigate = useNavigate();

  const { artworksViewOptionsStore, addArtworkStore } = useStores();
  const { setShowUncheckingLastFieldError } = artworksViewOptionsStore;
  const { initializeCreatingNewArtwork } = addArtworkStore;

  const [isViewOptionsPanelOpen, setIsViewOptionsPanelOpen] = useState<boolean>(false);

  const actionButtons: ActionButton[] = [
    {
      text: 'Register artwork',
      icon: <AddIcon />,
      onClick: () => {
        navigate(routes.AddArtworkAuthor.path('new'));
        initializeCreatingNewArtwork();
      },
    },
    {
      text: 'View options',
      icon: <OptionsIcon />,
      onClick: () => {
        setShowUncheckingLastFieldError(false);
        viewOptionsRef.current?.initializeStateWithStoreData();
        setIsViewOptionsPanelOpen(true);
      },
    },
  ];

  const handleClosePanel = (): void => {
    setIsViewOptionsPanelOpen(false);
  };

  return (
    <Topbar>
      <Breadcrumbs breadcrumbs={BREADCRUMBS} />
      <ActionButtons buttons={actionButtons} />

      <SlidingPanel
        title="View options"
        onClose={handleClosePanel}
        onReset={() => viewOptionsRef.current?.resetViewOptions()}
        isOpen={isViewOptionsPanelOpen}
      >
        <ViewOptions ref={viewOptionsRef} closePanel={handleClosePanel} />
      </SlidingPanel>
    </Topbar>
  );
};

export default observer(ArtworksTopbar);
