import { AxiosResponse } from 'axios';
import api from 'api';
import { ListResult } from 'api/types';
import {
  Consignment,
  ConsignmentBase,
  ConsignmentCountsReturn,
  ConsignmentItem,
  ConsignmentReturn,
  ConsignmentStatus,
  NewConsignmentRequest,
} from './types';

export interface FetchConsignmentsGroupedByConsigneeParams {
  pageSize?: number;
  pageNumber?: number;
  sortBy?: string[];
  status?: string[];
  phrase?: string;
}

export interface FetchConsignmentsGroupedByArtworkParams extends FetchConsignmentsGroupedByConsigneeParams {
  artworkId?: string;
  consignmentId?: string;
}

export function fetchConsignmentsGroupedByConsignee(
  params: FetchConsignmentsGroupedByConsigneeParams
): ListResult<ConsignmentItem> {
  Object.keys(params).forEach((key) => {
    if (!params[key]) delete params[key];
  });
  return api.get(`/consignments/by-artwork`, { params }); // TODO update url
}

export function fetchConsignmentsGroupedByArtwork(
  params: FetchConsignmentsGroupedByArtworkParams
): ListResult<ConsignmentItem> {
  Object.keys(params).forEach((key) => {
    if (!params[key]) delete params[key];
  });
  return api.get(`/consignments/by-artwork`, { params });
}

export const createConsignment = (consignment: NewConsignmentRequest): Promise<AxiosResponse<Consignment>> => {
  return api.post(`/consignments`, consignment);
};

export const deleteConsignment = (consignmentId: string, consignmentLastModifiedAt: Date): ConsignmentReturn => {
  const params = { consignmentLastModifiedAt };
  return api.delete(`/consignments/${consignmentId}`, { params });
};

export const updateConsignment = (
  consignmentId: string,
  consignment: ConsignmentBase
): Promise<AxiosResponse<Consignment>> => {
  return api.patch(`/consignments/${consignmentId}`, consignment);
};

export const consigneeAcceptConsignment = (
  consignmentId: string,
  consignmentLastModifiedAt: Date
): ConsignmentReturn => {
  return api.post(`/consignments/${consignmentId}/consignee-accept`, { consignmentLastModifiedAt });
};

export const consigneeRejectConsignment = (
  consignmentId: string,
  consignmentLastModifiedAt: Date
): ConsignmentReturn => {
  return api.post(`/consignments/${consignmentId}/consignee-reject`, { consignmentLastModifiedAt });
};

export const ownerAcceptConsignment = (consignmentId: string, consignmentLastModifiedAt: Date): ConsignmentReturn => {
  return api.post(`/consignments/${consignmentId}/owner-agree`, { consignmentLastModifiedAt });
};

export const ownerRejectConsignment = (consignmentId: string, consignmentLastModifiedAt: Date): ConsignmentReturn => {
  return api.post(`/consignments/${consignmentId}/owner-disagree`, { consignmentLastModifiedAt });
};

export const fetchGroupCounts = (status: ConsignmentStatus[], onlyExpired: boolean): ConsignmentCountsReturn => {
  const params = { status, onlyExpired };
  return api.get('/consignments/group-counts', { params });
};
