import { useParams } from 'react-router-dom';
import ArtworkData from 'theme/cells/artworkData';
import ArtworkTopbar from './components/ArtworkTopbar';

const Artwork = (): JSX.Element => {
  const { artworkId } = useParams();

  return (
    <>
      <ArtworkTopbar />
      <ArtworkData artworkId={artworkId} />
    </>
  );
};

export default Artwork;
