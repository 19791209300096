import { TransactionStatus } from 'domain/types/Transactions.types';

export const BANK_TRANSACTIONS_CATEGORIES_MAP = {
  open: {
    id: 'open',
    label: 'Open',
    condition: [
      TransactionStatus.Accepted,
      TransactionStatus.AcceptedEscrowAccountGranted,
      TransactionStatus.Executed,
      TransactionStatus.Executing,
      TransactionStatus.InvoiceVerification,
      TransactionStatus.ReadyForExecution,
      TransactionStatus.SignificantPaymentsMade,
      TransactionStatus.WaitingForPayment,
    ],
  },
  closed: {
    id: 'closed',
    label: 'Closed',
    condition: [TransactionStatus.Closed],
  },
} as const;

export const DEFAULT_BANK_TRANSACTIONS_CATEGORY = BANK_TRANSACTIONS_CATEGORIES_MAP.open.id;

export const STATUS_WITH_LABEL = [
  { status: TransactionStatus.Accepted, label: 'Waiting For Account' },
  { status: TransactionStatus.ReadyForExecution, label: 'Ready For Execution' },
  { status: TransactionStatus.InvoiceVerification, label: 'Invoice Verification' },
  { status: TransactionStatus.WaitingForPayment, label: 'Waiting For Payment' },
  { status: TransactionStatus.AcceptedEscrowAccountGranted, label: 'Account Granted' },
  { status: TransactionStatus.Executing, label: 'Executing' },
  { status: TransactionStatus.SignificantPaymentsMade, label: 'Significant Payments Made' },
  { status: TransactionStatus.Executed, label: 'Executed' },
  { status: TransactionStatus.Closed, label: 'Closed' },
];
