import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useStores } from 'RootStore';
import SlidingPanel from 'theme/atoms/slidingPanel';
import TextArea from 'theme/atoms/textArea';
import styles from './ContactProfile.module.scss';

interface ContactProfilePanelProps {
  isOpen: boolean;
  onClose: () => void;
}

const ContactProfilePanel = ({ isOpen, onClose }: ContactProfilePanelProps): JSX.Element => {
  const { networkManagingStore } = useStores();
  const { contactConnection, updateContactConnection } = networkManagingStore;
  const { contactId: contactConnectionId } = useParams();
  const [note, setNote] = useState<string>('');

  useEffect(() => {
    if (!contactConnection || !contactConnection.note) return;

    setNote(contactConnection.note);
  }, [contactConnection]);

  const handleSubmit = (): void => {
    if (!contactConnectionId) return;
    updateContactConnection(contactConnectionId, undefined, note);
  };

  return (
    <SlidingPanel
      title="Edit user profile"
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit}
      btnSubmitText="Save changes"
    >
      <div className={styles.panelContent}>
        <TextArea
          label="Notes"
          name="contactNote"
          value={note}
          onChange={(e) => setNote(e.target.value)}
          placeholder="Type your note about the user"
        />
      </div>
    </SlidingPanel>
  );
};

export default ContactProfilePanel;
