import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import SumsubWebSdk from '@sumsub/websdk-react';
import { useStores } from 'RootStore';
import { Button, Subtitle, TitleBlack } from 'theme/atoms';
import { useUserContext } from 'utils/hooks';
import styles from './CreateOrganization.module.scss';

interface VerifyAccountProps {
  onKycVerificationCompleted: () => void;
}

const VerifyAccount = ({ onKycVerificationCompleted }: VerifyAccountProps): JSX.Element => {
  const navigate = useNavigate();
  const { homePath } = useUserContext();

  const { organizationStore } = useStores();
  const { finishOrganizationSetup, fetchKycToken, kycToken, loadingKycToken, loadedKycToken } = organizationStore;

  const [showWebsdk, setShowWebsdk] = useState(false);

  useEffect(() => {
    fetchKycToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSkipFormNow = (): void => {
    finishOrganizationSetup();
    navigate(homePath);
  };

  const accessTokenExpirationHandler = async (): Promise<string | undefined> => {
    const data = await fetchKycToken();
    return data?.token;
  };

  const handleMessage = (type: string, payload): void => {
    console.log(type, payload);
    if (type === 'idCheck.onApplicantSubmitted') {
      onKycVerificationCompleted();
    }
  };

  const sdkElement =
    !kycToken && loadedKycToken ? (
      <Button
        text="Fetch your verification id"
        onClick={() => fetchKycToken(true)}
        maxWidth
        className={styles.submitBtn}
        loading={loadingKycToken}
        buttonType="secondary"
      />
    ) : kycToken && !showWebsdk ? (
      <Button text="Continue" onClick={() => setShowWebsdk(true)} maxWidth className={styles.submitBtn} />
    ) : (
      kycToken &&
      showWebsdk && (
        <SumsubWebSdk
          accessToken={kycToken.token}
          config={{ theme: 'light' }}
          expirationHandler={accessTokenExpirationHandler}
          onMessage={handleMessage}
        />
      )
    );

  return (
    <div className={styles.innerWrapper}>
      <TitleBlack text="Verify your identity" dontLimitWidth />
      <Subtitle>
        To ensure the security of your account and comply with regulatory requirements, we need to verify your identity.
      </Subtitle>
      {sdkElement}
      <div className={styles.centerContent}>
        <Button
          text="Skip for now"
          onClick={() => handleSkipFormNow()}
          buttonType="textButton"
          maxWidth
          className={styles.submitBtn}
        />
      </div>
    </div>
  );
};
export default observer(VerifyAccount);
