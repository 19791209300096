import { ReactNode, useRef } from 'react';
import ReactDOM from 'react-dom';
import { RemoveScroll } from 'react-remove-scroll';
import clsx from 'clsx';
import IconComponent, { iconSizes, icons } from 'theme/atoms/iconComponent';
import { useOnClickOutside } from 'utils/hooks';
import Button from '../Button';
import styles from './Modal.module.scss';

interface ModalProps {
  children?: ReactNode;
  className?: string;
  onClose?: () => void;
  isOpen: boolean;
  title?: string;
  subtitle?: string;
  btnAbort?: {
    label: string;
    function: () => void;
    loading?: boolean;
  };
  btnConfirm?: {
    label: string;
    function: (() => void) | (() => Promise<void>);
    loading?: boolean;
  };
  withoutUseOnClickOutside?: boolean;
}

function Modal({
  btnAbort,
  btnConfirm,
  children,
  className = '',
  isOpen,
  onClose,
  subtitle,
  title,
  withoutUseOnClickOutside = false,
}: ModalProps): JSX.Element | null {
  const modalRef = useRef<HTMLDivElement>(null);
  useOnClickOutside({
    ref: modalRef,
    handler: withoutUseOnClickOutside ? null : onClose || null,
  });

  if (isOpen) {
    const component = (
      <RemoveScroll>
        <div className={styles.overlay}>
          <div className={clsx(styles.modal, className)} ref={modalRef} aria-modal="true" role="dialog">
            {title && <h2 className={styles.title}>{title}</h2>}
            {onClose && (
              <button onClick={onClose} className={styles.closeBtn}>
                <IconComponent icon={icons.close} size={iconSizes.small} />
              </button>
            )}
            {subtitle && <div className={styles.subtitle}>{subtitle}</div>}

            {children}

            {(btnAbort || btnConfirm) && (
              <div className={styles.actions}>
                {btnAbort && (
                  <Button
                    text={btnAbort.label}
                    onClick={btnAbort.function}
                    buttonType="secondary"
                    loading={btnAbort.loading}
                  />
                )}
                {btnConfirm && (
                  <Button
                    text={btnConfirm.label}
                    onClick={btnConfirm.function}
                    buttonType="primary"
                    loading={btnConfirm.loading}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </RemoveScroll>
    );

    return ReactDOM.createPortal(component, document.body);
  }

  return null;
}

export default Modal;
