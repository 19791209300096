import { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import Big from 'big.js';
import { addMeToOrganizationName } from 'domain/utils';
import Billing from 'theme/atoms/billing';
import UserCard from 'theme/atoms/userCard';
import { TransactionWrapper } from 'transactions/requests.types';
import { formatPrice } from 'utils/helpers';
import { useTransactionPrices, useTransactionRoles } from 'utils/hooks';
import styles from '../TransactionBilling.module.scss';

interface PaymentSubject {
  contactId: string;
  organizationName: string;
  participationPercent: number;
  title: string;
}

interface AmountToPayProps {
  isSummaryView: boolean;
}

const AmountToPay = ({ isSummaryView }: AmountToPayProps): JSX.Element | null => {
  const { transactionFormStore } = useStores();
  const transactionWrapper = transactionFormStore.transactionWrapper as TransactionWrapper;

  const {
    transaction: { currency, buyerInfo, sellerInfo },
  } = transactionWrapper;

  const { artworkPrice, askingPrice, artistResellRightsAmount, totalBuyerCommission } = useTransactionPrices();
  const { isSellerSide, isBuyerRepresentative, isSellerOwner, isSellerOwnerAsRepresentative } = useTransactionRoles({
    transactionWrapper,
  });

  const endPriceSellerSide = isSellerOwner ? Big(askingPrice) : Big(askingPrice).plus(sellerInfo?.totalCommission || 0);

  const endPrice = isSellerSide
    ? endPriceSellerSide
    : Big(artworkPrice || 0)
        .plus(artistResellRightsAmount || 0)
        .plus(totalBuyerCommission || 0);

  const titleForNotSummaryView = isSellerSide ? 'Artwork price' : 'Total price';
  const title = isSummaryView ? 'Amount to pay and payers' : titleForNotSummaryView;

  const paymentSubjects = useMemo(() => {
    const getSellerSidePaymentSubjects = (): PaymentSubject[] | undefined => {
      if (isSellerSide) {
        const representativeContact =
          isSellerOwner && !isSellerOwnerAsRepresentative
            ? sellerInfo?.representativeContact
            : buyerInfo?.representativeContact;
        return [
          {
            contactId: representativeContact?.contactConnectionId || '',
            organizationName: representativeContact?.organizationName || 'Undisclosed',
            participationPercent: 100,
            title: representativeContact?.organizationTitle || '',
          },
        ];
      }
    };

    const getBuyerRepresentativePaymentSubjects = (): PaymentSubject[] | undefined => {
      if (isBuyerRepresentative && buyerInfo?.buyers) {
        const buyers = buyerInfo?.buyers;
        return buyers.map((buyer) => {
          const contact = buyer.contact;
          return {
            contactId: contact?.contactConnectionId || '',
            organizationName: contact ? addMeToOrganizationName(contact) : '',
            participationPercent: buyer.participationPercent || 0,
            title: contact?.organizationTitle || '',
          };
        });
      }
    };

    const getBuyerPaymentSubjects = (): PaymentSubject[] | undefined => {
      if (buyerInfo?.buyers) {
        const buyers = buyerInfo.buyers;
        return buyers.map((buyer) => ({
          contactId: '',
          organizationName: buyer.contact ? addMeToOrganizationName(buyer.contact) : 'Undisclosed',
          participationPercent: buyer.participationPercent || 0,
          title: buyer.contact?.organizationTitle || '',
        }));
      }
    };

    return getSellerSidePaymentSubjects() || getBuyerRepresentativePaymentSubjects() || getBuyerPaymentSubjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyerInfo?.buyers, buyerInfo?.representativeContact, isBuyerRepresentative, isSellerSide]);

  const displaySubjectRow = (subject: PaymentSubject): JSX.Element => {
    return (
      <Billing.Row key={subject.contactId || subject.organizationName}>
        <Billing.Cell>
          <UserCard id={subject.contactId}>
            <UserCard.NameAndTitle name={subject.organizationName} title={subject.title} />
          </UserCard>
        </Billing.Cell>
        <Billing.Cell>
          <div className={styles.subjectAmount}>
            <div className={styles.amount}>
              {formatPrice(Big(endPrice).times(subject.participationPercent).div(100), currency)}
            </div>
            <div>({subject.participationPercent}%)</div>
            {/* maybe this % only for buyerRep and buyer? cause sellerRep always see 100% not divided by any subjects */}
          </div>
        </Billing.Cell>
      </Billing.Row>
    );
  };

  const displayNoBuyersInfo = (): JSX.Element => {
    return (
      <Billing.Row>
        <Billing.Cell>
          <div className={styles.subjectAmount}>
            <div className={styles.amount}>{formatPrice(Big(endPrice), currency)}</div>
          </div>
        </Billing.Cell>
      </Billing.Row>
    );
  };

  const priceDetails = paymentSubjects?.length
    ? paymentSubjects.map((subject) => displaySubjectRow(subject))
    : displayNoBuyersInfo();

  return (
    <>
      <Billing.Subheader>
        <Billing.Cell>{title}:</Billing.Cell>
        {!isSummaryView && <Billing.Cell>{formatPrice(endPrice, currency)}</Billing.Cell>}
      </Billing.Subheader>
      {isSummaryView && priceDetails}
    </>
  );
};

export default observer(AmountToPay);
