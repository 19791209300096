import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { routes } from 'app/routes/paths.const';
import styles from 'auth/Auth.module.scss';
import { userIdentityTypeToRouteMap } from 'auth/constants';
import { authMethodTiles } from 'auth/constants';
import { UserIdentityType } from 'auth/types';
import { Subtitle, TitleBlack } from 'theme/atoms';
import Breadcrumbs, { Breadcrumb } from 'theme/atoms/Breadcrumbs';
import Topbar from 'theme/atoms/Topbar';
import RadioTilesForm from 'theme/cells/radioTiles/radioTilesForm';

const BREADCRUMBS: Breadcrumb[] = [{ label: 'Authentication method' }, { label: 'Step 1: Choose method' }];

const AddAuthMethod = (): React.JSX.Element => {
  const navigate = useNavigate();

  const onSubmit = (userIdentityType: UserIdentityType): void => {
    const addAuthMethodRoute = userIdentityTypeToRouteMap[userIdentityType]?.add;
    if (!addAuthMethodRoute) return;
    navigate(routes[addAuthMethodRoute].path());
  };

  return (
    <div className={clsx(styles.root, styles.oneColumnLayout)}>
      <Topbar>
        <Breadcrumbs breadcrumbs={BREADCRUMBS} />
      </Topbar>

      <div className={styles.innerWrapper}>
        <TitleBlack dontLimitWidth>Choose an authentication method</TitleBlack>
        <Subtitle>Please choose one of the following forms of multi-factor authentication.</Subtitle>
        <RadioTilesForm tiles={authMethodTiles} onSubmit={(val) => onSubmit(val as UserIdentityType)} />
      </div>
    </div>
  );
};

export default AddAuthMethod;
