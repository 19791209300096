import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { Input } from 'theme/atoms';
import styles from './SalesRestrictions.module.scss';

const SalesRestrictions = (): JSX.Element => {
  const { consignmentFormStore } = useStores();
  const { consignmentForm, setSalesRestrictions } = consignmentFormStore;

  return (
    <Input
      name="salesRestrictions"
      label="Sales restrictions"
      placeholder="Describe restrictions"
      onChange={(e) => setSalesRestrictions(e.target.value)}
      value={consignmentForm.salesRestrictions || ''}
      className={styles.root}
      type="text"
    />
  );
};

export default observer(SalesRestrictions);
