import { AxiosResponse } from 'axios';
import api from 'api';
import { ListResult } from 'api/types';
import { ContactConnection, ContactConnectionReturn, ContactInvitationReturn, ContactInvitationsReturn } from './types';

export interface FetchContactConnectionsParams {
  status?: string[];
  pageSize?: number;
  pageNumber?: number;
}

export const fetchContactConnections = (params: FetchContactConnectionsParams): ListResult<ContactConnection> => {
  return api.get('/contacts/connections', { params });
};

export const fetchContactConnection = (id: string): ListResult<ContactConnection> => {
  return api.get('/contacts/connections', { params: { id } });
};

export const createContactConnection = (
  // 3, user B
  contactInvitationId: string
): ContactConnectionReturn => {
  return api.post('/contacts/connections', { contactInvitationId });
};

export function deleteContactConnection(connectionId: string): Promise<AxiosResponse> {
  return api.delete(`/contacts/connections/${connectionId}`);
}

export function updatePrimaryContactInContactConnection(
  connectionId: string,
  primaryContactId?: string,
  note?: string
): ContactConnectionReturn {
  return api.patch(`/contacts/connections/${connectionId}`, {
    primaryContactId,
    note,
  });
}

export const acceptContactConnection = (connectionId: string): ContactConnectionReturn => {
  // 4, user A
  return api.post(`/contacts/connections/${connectionId}/accept`);
};

export const ignoreContactConnection = (connectionId: string): ContactConnectionReturn => {
  return api.post(`/contacts/connections/${connectionId}/ignore`);
};

export const fetchContactInvitations = (): ContactInvitationsReturn => {
  return api.get('/contacts/invitations');
};

export const createContactInvitation = (): ContactInvitationReturn => {
  // 1, user A
  return api.post('/contacts/invitations');
};

export const fetchContactInvitation = (invitationId: string): ContactInvitationReturn => {
  // 2, user B
  return api.get(`/contacts/invitations/${invitationId}`);
};

export const expireContactInvitation = (invitationId: string): ContactInvitationReturn => {
  return api.post(`/contacts/invitations/${invitationId}/expire`);
};
