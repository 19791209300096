import { ReactElement } from 'react';
import { Navigate, Route } from 'react-router-dom';
import { AppWrapper } from 'AppWrapper/AppWrapper';
import { routes } from '../paths.const';

export const getRoutesForAuthenticatedWithoutOrganization = (): ReactElement => {
  return (
    <Route>
      <Route element={<AppWrapper />}>
        <Route path={routes.CreateOrganizationWrapper.path()} element={routes.CreateOrganizationWrapper.component} />
        <Route path={routes.LoginRecoveryCodes.path()} element={routes.LoginRecoveryCodes.component} />
      </Route>

      <Route path="*" element={<Navigate to={routes.CreateOrganizationWrapper.path()} replace />} />
    </Route>
  );
};
