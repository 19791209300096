import { useState, useCallback, useMemo } from 'react';
import { formValidationMessages } from 'domain/formValidationMessages';
import { FormValidation } from 'domain/types';

interface UseFormValidationReturn {
  areThereAnyValidationErrors: boolean;
  cleanValidationErrors: (keys: string[]) => void;
  formValidationMessages: Record<string, string>;
  handleValidation: (key: string, message: string, condition: boolean) => void;
  resetValidation: () => void;
  validation: FormValidation;
}

export function useFormValidation(validations: FormValidation): UseFormValidationReturn {
  const [validation, setValidation] = useState<FormValidation>(validations);

  const cleanValidationErrors = (keys: string[]): void => {
    setValidation(
      (prev): FormValidation => ({
        ...prev,
        ...keys.reduce((a, b) => ({ ...a, [b]: '' }), {}),
      })
    );
  };

  const handleValidation = (key: string, message: string, condition: boolean): void => {
    if (condition) setValidation((prev) => ({ ...prev, [key]: message }));
  };

  const resetValidation = useCallback((): void => {
    setValidation(validations);
  }, [validations]);

  const areThereAnyValidationErrors = useMemo(() => !!Object.values(validation).filter(Boolean).length, [validation]);

  return {
    areThereAnyValidationErrors,
    cleanValidationErrors,
    formValidationMessages,
    handleValidation,
    resetValidation,
    validation,
  };
}
