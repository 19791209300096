import axios, { AxiosResponse } from 'axios';
import api, { getDeviceId } from 'api';
import env from 'domain/env';
import { UserReturn } from 'domain/types/User.types';
import {
  AuthUserIdentity,
  LoginReturn,
  LoginToTOTPReturn,
  LoginWithTokenParams,
  UserIdentityParams,
  UserIdentityReturn,
} from './types';

export function getToken(params: {
  email?: string;
  phoneNumber?: string;
  userIdentityId?: string;
}): Promise<AxiosResponse<void>> {
  return api.post('/auth/get-token', params);
}

export function login(email: string, password: string): LoginReturn {
  return api.post('/auth/login', { email, password });
}

export function resetPassword(userIdentityId: string, newPassword: string): UserReturn {
  return api.patch('/auth/password', { userIdentityId, newPassword });
}

export function loginWithToken(params: LoginWithTokenParams): LoginReturn {
  return api.post('/auth/token', params);
}

export function loginWithRefreshToken(refreshToken: string): LoginReturn {
  return axios.post(
    `${env.backendUrl}/auth/refresh-token`,
    { refreshTokenJwt: refreshToken },
    {
      headers: {
        'device-id': getDeviceId(),
      },
    }
  );
}

export const loginToTOTP = (): LoginToTOTPReturn => {
  return api.get('/auth/totp');
};

export function loginWithTOTPCode(userIdentityId: string, password: string): LoginReturn {
  return api.post('/auth/totp', { userIdentityId, password });
}

export function invalidateRefreshToken(): Promise<AxiosResponse> {
  return api.delete(`/auth/refresh-tokens/${getDeviceId()}`);
}

export const fetchUserIdentities = (): Promise<AxiosResponse<AuthUserIdentity[]>> => {
  return api.get('/users/identities');
};

export const addUserIdentity = (params: UserIdentityParams): UserIdentityReturn => {
  return api.post('/users/identities', params);
};

export const confirmUserIdentity = (userIdentityId: string, confirmationCode: string): UserIdentityReturn => {
  return api.patch(`/users/identities/${userIdentityId}`, { confirmationCode });
};

export const sendUserIdentityConfirmationCode = (userIdentityId: string): Promise<AxiosResponse<void>> => {
  return api.get(`/users/identities/${userIdentityId}/confirm-code`);
};

export const deleteUserIdentity = (userIdentityId: string): Promise<AxiosResponse> => {
  return api.delete(`/users/identities/${userIdentityId}`);
};

export const fetchRecoveryCodes = (): Promise<AxiosResponse<string[]>> => {
  return api.post('/auth/recovery-codes');
};
