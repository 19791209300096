import styles from './ApprovalFromPeople.module.scss';

interface ApprovalFromPeopleProps {
  peopleNumber: number;
  variant?: 'artworkRegistration' | 'transactionSellerSide' | 'noAdditionalInfo';
  onClick?: () => void;
}

const ApprovalFromPeople = ({ peopleNumber, variant, onClick }: ApprovalFromPeopleProps): JSX.Element => {
  const additionalCopy =
    variant === 'artworkRegistration'
      ? ' before the artwork is registered'
      : variant === 'transactionSellerSide'
      ? ' before the offer is sent to the buyer side'
      : '';

  const peopleNumberElement = onClick ? (
    <button onClick={() => onClick()}>{peopleNumber} people</button>
  ) : (
    <>{peopleNumber} people</>
  );

  if (peopleNumber === 0) return <></>;

  return (
    <div className={styles.root}>
      Approval from {peopleNumberElement} is required{additionalCopy}.
    </div>
  );
};

export default ApprovalFromPeople;
