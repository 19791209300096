import { FormEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import { isPasswordInvalid } from 'auth/helpers';
import { FormValidation } from 'domain/types';
import { ReactComponent as HiddenIcon } from 'theme/assets/svg/hidden1.svg';
import { ReactComponent as ShowIcon } from 'theme/assets/svg/shown1.svg';
import { Button, Input, Subtitle } from 'theme/atoms';
import Accordion from 'theme/atoms/Accordion';
import { useFormValidation } from 'utils/hooks';
import styles from '../SettingsSecurity.module.scss';

const FORM_VALIDATIONS: FormValidation = {
  currentPasswordRequired: '',
  newPasswordRequired: '',
  newPasswordInvalid: '',
  newPasswordSameAsOld: '',
  repeatPasswordRequired: '',
  repeatPasswordNotTheSame: '',
};

const ChangePassword = observer(() => {
  const navigate = useNavigate();
  const { authStore } = useStores();
  const { submitting, reloginAndResetPassword } = authStore;
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const { cleanValidationErrors, formValidationMessages, handleValidation, validation } =
    useFormValidation(FORM_VALIDATIONS);

  const handleCurrentPasswordChange = (value: string): void => {
    cleanValidationErrors(['currentPasswordRequired']);
    setCurrentPassword(value);
  };

  const handleNewPasswordChange = (value: string): void => {
    cleanValidationErrors([
      'newPasswordRequired',
      'newPasswordInvalid',
      'newPasswordSameAsOld',
      'repeatPasswordRequired',
      'repeatPasswordNotTheSame',
    ]);
    handleValidation(
      'repeatPasswordNotTheSame',
      formValidationMessages.PASSWORDS_NOT_THE_SAME,
      !!repeatPassword && repeatPassword !== value
    );
    setNewPassword(value);
  };

  const handleRepeatPasswordChange = (value: string): void => {
    cleanValidationErrors(['repeatPasswordRequired', 'repeatPasswordNotTheSame']);
    setRepeatPassword(value);
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>): void | undefined => {
    event.preventDefault();
    handleValidation('currentPasswordRequired', formValidationMessages.REQUIRED, !currentPassword);
    handleValidation('newPasswordRequired', formValidationMessages.REQUIRED, !newPassword);
    handleValidation('newPasswordInvalid', formValidationMessages.PASSWORD_INVALID, isPasswordInvalid(newPassword));
    handleValidation(
      'newPasswordSameAsOld',
      formValidationMessages.NEW_PASSWORD_MUST_DIFFER_FROM_OLD_ONE,
      newPassword === currentPassword
    );
    handleValidation('repeatPasswordRequired', formValidationMessages.REQUIRED, !newPassword && !repeatPassword);
    handleValidation(
      'repeatPasswordNotTheSame',
      formValidationMessages.PASSWORDS_NOT_THE_SAME,
      repeatPassword !== newPassword
    );

    if (
      !currentPassword ||
      !newPassword ||
      !repeatPassword ||
      isPasswordInvalid(newPassword) ||
      newPassword === currentPassword ||
      repeatPassword !== newPassword
    ) {
      return;
    }

    reloginAndResetPassword(currentPassword, newPassword).then((isSuccessfull) => {
      if (isSuccessfull) {
        setCurrentPassword('');
        setNewPassword('');
        setRepeatPassword('');
        setIsPasswordVisible(false);
      }
    });
  };

  return (
    <Accordion.Item id="changePassword" header="Change password">
      <Subtitle text="You must provide your current password in order to change it." />
      <div className={styles.forgot}>
        <Button
          text="Forgot your password?"
          buttonType="textButton"
          onClick={() => navigate(routes.ResetPasswordGetEmail.path())}
        />
      </div>
      <form onSubmit={onSubmit} className="form">
        <Input
          label="Current password"
          name="password"
          placeholder="Enter your password"
          value={currentPassword}
          onChange={(e) => handleCurrentPasswordChange(e.target.value)}
          error={validation.currentPasswordRequired}
          type={isPasswordVisible ? 'text' : 'password'}
          endIcon={
            <div className={styles.passwordIcon} onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
              {isPasswordVisible ? <ShowIcon /> : <HiddenIcon />}
            </div>
          }
        />
        <Input
          label="New password"
          name="password"
          placeholder="Enter your password"
          value={newPassword}
          onChange={(e) => handleNewPasswordChange(e.target.value)}
          error={validation.newPasswordRequired || validation.newPasswordInvalid || validation.newPasswordSameAsOld}
          type={isPasswordVisible ? 'text' : 'password'}
          endIcon={
            <div className={styles.passwordIcon} onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
              {isPasswordVisible ? <ShowIcon /> : <HiddenIcon />}
            </div>
          }
          className={clsx(styles.newPasswordInput, { [styles.newPasswordInputInvalid]: validation.newPasswordInvalid })}
        />
        <Input
          label="Repeat new password"
          name="password"
          placeholder="Enter your password"
          value={repeatPassword}
          onChange={(e) => handleRepeatPasswordChange(e.target.value)}
          error={validation.repeatPasswordRequired || validation.repeatPasswordNotTheSame}
          type={isPasswordVisible ? 'text' : 'password'}
          endIcon={
            <div className={styles.passwordIcon} onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
              {isPasswordVisible ? <ShowIcon /> : <HiddenIcon />}
            </div>
          }
        />
        <Button text="Change password" loading={submitting} type="submit" />
      </form>
    </Accordion.Item>
  );
});

export default ChangePassword;
