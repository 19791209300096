export const formValidationMessages: Record<string, string> = {
  EMAIL_INVALID: 'Invalid email format',
  EMAIL_SAME_AS_BEFORE: "It's the same email as before",
  EMAIL_REQUIRED: 'Email is required',
  NAME_REQUIRED: 'Name is required',
  PASSWORD_REQUIRED: 'Password is required',
  PHONE_REQUIRED: 'Phone is required',
  PHONE_INVALID: 'Invalid phone format',
  CODE_REQUIRED: 'Security code is required',
  FIRSTNAME_REQUIRED: 'First name is required',
  LASTNAME_REQUIRED: 'Last name is required',
  COUNTRY_REQUIRED: 'Country is required',
  CITY_REQUIRED: 'City is required',
  PASSWORD_INVALID:
    'Password should contain min. 12 and max. 64 characters, and at least one character of each: an upper letter, a lower letter, and a digit.',
  NEW_PASSWORD_MUST_DIFFER_FROM_OLD_ONE: 'New password should be different from the old one.',
  PASSWORDS_NOT_THE_SAME: 'Password are not the same',
  ASKING_PRICE_REQUIRED: 'Asking price is required',
  EXPIRE_AT_REQUIRED: 'Expiration date is required',
  MAX_COMMISSION_REQUIRED: 'Max commission is required',
  REQUIRED: 'Required',
  AUTH_APP_CODE_REQUIRED: 'Authenticator app code is required',
  AUTH_APP_CODE_INVALID: 'Invalid authenticator app code format',
  RECOVERY_CODE_REQUIRED: 'Recovery code is required',
  RECOVERY_CODE_INVALID: `Invalid recovery code format`,
  PHONE_CODE_REQUIRED: 'SMS code is required',
  PHONE_CODE_INVALID: `Invalid SMS code format`,
};
