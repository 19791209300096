import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { AUTH_APP_CODE_LENGTH } from 'auth/constants';
import { QR_SECRET_CHUNK_REGEX } from 'domain/regex';
import { QRCodeSVG } from 'qrcode.react';
import SectionHeader from 'theme/atoms/Titles/SectionHeader';
import Loader from 'theme/atoms/loader';
import styles from './SetupAuthApp.module.scss';

const AUTH_APPS = [
  {
    name: 'Google Authenticator',
    url: 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2',
  },
  { name: '1Password', url: 'https://1password.com' },
  { name: 'Authy', url: 'https://authy.com' },
];

interface SetupAuthAppProps {
  QRSecret?: string;
  QRValue?: string;
  form: ReactNode;
  backButton: ReactNode;
}

const SetupAuthApp = ({ QRSecret, QRValue, form, backButton }: SetupAuthAppProps): React.JSX.Element => {
  const formattedQRSecret = QRSecret
    ? QRSecret.match(QR_SECRET_CHUNK_REGEX)?.map((chunk) => (
        <span key={chunk} className={styles.QRSecretChunk}>
          {chunk}
        </span>
      ))
    : '';

  return (
    <>
      <SectionHeader title="1. Download an authenticator app" className={styles.sectionHeader} />
      <p className={styles.paragraph}>
        Download and install authenticator application to your mobile device. Here are the recommended app:
      </p>
      <ul className={styles.authAppLinks}>
        {AUTH_APPS.map((app) => (
          <Link key={app.name} to={app.url} target="_blank" className={styles.authAppLink}>
            {app.name}
          </Link>
        ))}
      </ul>

      <SectionHeader title="2. Scan the QR code" className={styles.sectionHeader} />
      <p className={styles.paragraph}>
        Open your authenticator application and scan the QR code below or use the key to enter manually:
      </p>
      <div className={styles.QRCodeContainer}>
        <p className={styles.QRSecret}>{formattedQRSecret}</p>
        <div className={styles.QRCode}>
          {!QRValue ? <Loader minHeight={180} /> : <QRCodeSVG value={QRValue} size={180} />}
        </div>
      </div>

      <SectionHeader title="3. Verify your authenticator app" className={styles.sectionHeader} />
      <p className={styles.paragraph}>
        Enter the {AUTH_APP_CODE_LENGTH}-digit verification code that was generated in the application:
      </p>

      <div className={styles.formContainer}>{form}</div>

      <div className={styles.backButtonContainer}>{backButton}</div>
    </>
  );
};

export default SetupAuthApp;
