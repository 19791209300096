import ArtworkRegistrationStore from 'artworks/artworkRegistration/ArtworkRegistrationStore';
import ArtworkRegistrationSortAndFilterStore from 'artworks/artworkRegistration/components/artworkRegistrationSortAndFilter/ArtworkRegistrationSortAndFilterStore';
import ArtworksStore from 'artworks/artworks/ArtworksStore';
import ArtworksSortAndFilterStore from 'artworks/artworksComponents/sortAndFilter/ArtworksSortAndFilterStore';
import BankInvoicesStore from 'bankPerspective/bankInvoices/BankInvoicesStore';
import BankInvoicesSortAndFilterStore from 'bankPerspective/bankInvoices/sortAndFilter/BankInvoicesSortAndFilterStore';
import BankTransactionsStore from 'bankPerspective/bankTransactions/BankTransactionsStore';
import BankTransactionsSortAndFilterStore from 'bankPerspective/bankTransactions/sortAndFilter';
import ConsignmentsStore from 'consignments/consignments/ConsignmentsStore';
import ConsignmentsSortAndFilterStore from 'consignments/consignments/sortAndFilter/ConsignmentsSortAndFilterStore';
import NetworkSortAndFilterStore from 'network/networkList/NetworkSortAndFilterStore';
import InvoicesStore from 'transactions/invoices/InvoicesStore';
import InvoicesSortAndFilterStore from 'transactions/invoices/components/invoicesSortAndFilter/InvoicesSortAndFilterStore';
import TransactionsStore from 'transactions/transactions/TransactionsStore';
import TransactionsSortAndFilterStore from 'transactions/transactions/sortAndFilter/TransactionsSortAndFilterStore';

export enum SearchTableView {
  ArtworkRegistration = 'ArtworkRegistration',
  Artworks = 'Artworks',
  BankInvoices = 'BankInvoices',
  BankTransactions = 'BankTransactions',
  Consignments = 'Consignments',
  Invoices = 'Invoices',
  Transactions = 'Transactions',
  Network = 'Network',
}

export type FilterStore =
  | ArtworkRegistrationSortAndFilterStore
  | ArtworksSortAndFilterStore
  | ConsignmentsSortAndFilterStore
  | InvoicesSortAndFilterStore
  | BankTransactionsSortAndFilterStore
  | BankInvoicesSortAndFilterStore
  | TransactionsSortAndFilterStore
  | NetworkSortAndFilterStore;

export type RegularStore =
  | ArtworksStore
  | ArtworkRegistrationStore
  | ConsignmentsStore
  | InvoicesStore
  | BankTransactionsStore
  | BankInvoicesStore
  | TransactionsStore;

export type StoreWithFilters =
  | ArtworkRegistrationSortAndFilterStore
  | ArtworksSortAndFilterStore
  | ConsignmentsSortAndFilterStore
  | TransactionsSortAndFilterStore;

export type StoreWithCategory =
  | ArtworkRegistrationSortAndFilterStore
  | ConsignmentsSortAndFilterStore
  | TransactionsSortAndFilterStore
  | InvoicesSortAndFilterStore
  | BankTransactionsSortAndFilterStore
  | BankInvoicesSortAndFilterStore
  | NetworkSortAndFilterStore;

export const viewsWithCategory = [
  SearchTableView.ArtworkRegistration,
  SearchTableView.Consignments,
  SearchTableView.Transactions,
  SearchTableView.Invoices,
  SearchTableView.BankTransactions,
  SearchTableView.BankInvoices,
  SearchTableView.Network,
];

export type StoreWithGroupCounts = ConsignmentsSortAndFilterStore | TransactionsSortAndFilterStore;

export const viewsWithGroupCounts = [SearchTableView.Consignments, SearchTableView.Transactions];

export const SORT_BY_STRING = 'sortBy';
