import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import Billing from 'theme/atoms/billing';
import UserCard from 'theme/atoms/userCard';
import { TransactionWrapper } from 'transactions/requests.types';
import { formatPrice } from 'utils/helpers';
import { useTransactionPrices, useTransactionRoles } from 'utils/hooks';

interface AskingPriceProps {
  isSummaryView?: boolean;
}

const ArtistResellRights = ({ isSummaryView }: AskingPriceProps): JSX.Element | null => {
  const { transactionFormStore } = useStores();
  const transactionWrapper = transactionFormStore.transactionWrapper as TransactionWrapper;
  const author = transactionWrapper.artwork.author;
  const { currency, artistResellRightsPercent, artistResellRightsAmount } = useTransactionPrices();
  const { isSellerSide } = useTransactionRoles({ transactionWrapper });

  if (!artistResellRightsPercent || !artistResellRightsAmount) return null;

  return (
    <>
      <Billing.Row {...(isSellerSide ? { isDark: true } : { isLight: true })}>
        <Billing.Cell>Artist Resell Rights:</Billing.Cell>
        <Billing.Cell isSplit>
          <div>{Number(artistResellRightsPercent.toFixed(2))}%</div>
          <Billing.Cell isBold={!isSellerSide && isSummaryView}>
            {formatPrice(artistResellRightsAmount, currency)}
          </Billing.Cell>
        </Billing.Cell>
      </Billing.Row>
      {isSummaryView && (
        <Billing.Row isIndented key={author.name + author.id}>
          <UserCard isSmall id={author.name}>
            <UserCard.Name name={author.name} />
          </UserCard>
          {formatPrice(artistResellRightsAmount, currency)}
        </Billing.Row>
      )}
    </>
  );
};

export default observer(ArtistResellRights);
