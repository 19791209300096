import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { useStores } from 'RootStore';
import { ORGANIZATION_DATA_UNKNOWN } from 'domain/constants';
import { addSpaceBeforeUppercase } from 'domain/utils';
import { KycStatus } from 'organization/types';
import { Button } from 'theme/atoms';
import Accordion from 'theme/atoms/Accordion';
import Card from 'theme/atoms/card';
import InfoCardDetailsList from 'theme/atoms/card/infoCardDetailsList';
import Chip from 'theme/atoms/chip';
import Websdk from '../websdk';
import styles from './LegalEntity.module.scss';

const getCopyAndButtonsToDisplay = (kycStatus: KycStatus, setShowWebsdk: (val: boolean) => void): JSX.Element => {
  switch (true) {
    case kycStatus === KycStatus.Verified:
      return (
        <>
          <div className={styles.kycVerificationInfo}>
            A change in data results in the need for a repeat of KYC verification.
          </div>
          <Button text="Edit data" onClick={() => setShowWebsdk(true)} />
        </>
      );
    case kycStatus === KycStatus.Rejected:
      return (
        <div className={styles.kycVerificationInfo}>
          Your organization has been rejected for the identity verification process. You cannot use the app.{' '}
        </div>
      );
    case kycStatus === KycStatus.Unverified:
      return (
        <>
          <div className={styles.kycVerificationInfo}>Please proceed with the verification process.</div>
          <Button text="Start verification" onClick={() => setShowWebsdk(true)} />
        </>
      );
    case kycStatus === KycStatus.Pending:
      return <div className={styles.kycVerificationInfo}>The verification process is in progress. Please wait.</div>;
    case kycStatus === KycStatus.TemporaryRejected:
      return (
        <>
          <div className={styles.kycVerificationInfo}>
            Some verification checks have failed - please proceed with the re-verification process.
          </div>
          <Button text="Start re-verification" onClick={() => setShowWebsdk(true)} />
        </>
      );
    default:
      return <></>;
  }
};

const LegalEntity = (): JSX.Element => {
  const { organizationStore } = useStores();
  const { selectedOrganization } = organizationStore;
  const [showWebsdk, setShowWebsdk] = useState(false);

  if (!selectedOrganization) return <></>;

  const { kycStatus } = selectedOrganization;

  const isVerified = kycStatus === KycStatus.Verified;
  const isNotVerified = [KycStatus.Unverified, KycStatus.Pending, KycStatus.TemporaryRejected].includes(kycStatus);
  const isRejected = kycStatus === KycStatus.Rejected;

  const stylesForIDDocument = clsx(
    { [styles.verifiedId]: isVerified },
    { [styles.notVerifiedId]: isNotVerified },
    { [styles.rejectedId]: isRejected }
  );

  const KYCDetailsMap = {
    Name: { value: selectedOrganization.name || ORGANIZATION_DATA_UNKNOWN },
    Country: { value: selectedOrganization.address.country || ORGANIZATION_DATA_UNKNOWN },
    City: { value: selectedOrganization.address.city || ORGANIZATION_DATA_UNKNOWN },
    'ID Document': {
      value: addSpaceBeforeUppercase(kycStatus),
      listValueOptionalStyles: stylesForIDDocument,
    },
    'Tax ID': { value: selectedOrganization.taxId || ORGANIZATION_DATA_UNKNOWN },
  };

  return (
    <Accordion.Item
      id="legalEntity"
      header={
        <div className={styles.accordionItemLabel}>
          Legal entity
          <Chip variant={isVerified ? 'success' : isNotVerified ? 'warning' : 'error'}>
            {addSpaceBeforeUppercase(kycStatus)}
          </Chip>
        </div>
      }
    >
      <div className={styles.details}>
        <Card.DetailsList className={styles.kycDetails}>
          <InfoCardDetailsList fieldsMap={KYCDetailsMap} />
        </Card.DetailsList>
      </div>
      {getCopyAndButtonsToDisplay(kycStatus, setShowWebsdk)}
      {[KycStatus.Verified, KycStatus.Unverified, KycStatus.TemporaryRejected].includes(kycStatus) && (
        <Websdk onClose={() => setShowWebsdk(false)} isOpen={showWebsdk} kycStatus={kycStatus} />
      )}
    </Accordion.Item>
  );
};

export default observer(LegalEntity);
