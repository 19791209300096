import { FormEvent } from 'react';
import { ProductEnum } from 'billing/types';
import { Button } from 'theme/atoms';
import { useGetPaidOrBlockedButtonDetails } from 'utils/hooks/useGetPaidOrBlockedButtonDetails';
import styles from './ActionButtons.module.scss';

interface ActionButtonsProps {
  isEditView: boolean;
  onClosePanel: () => void;
  withdrawConsignment: () => void;
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
  submitting?: boolean;
}

const ActionButtons = ({
  isEditView,
  onClosePanel,
  withdrawConsignment,
  onSubmit,
  submitting,
}: ActionButtonsProps): JSX.Element => {
  const paidOrBlockedButtonDetails = useGetPaidOrBlockedButtonDetails(ProductEnum.Consignment);

  if (isEditView) {
    return (
      <div className={styles.root}>
        <Button text="Save changes" maxWidth onClick={onSubmit} loading={submitting} />
        <Button text="Cancel" maxWidth buttonType="secondary" onClick={onClosePanel} />
        <Button
          text="Withdraw consignment"
          maxWidth
          buttonType="secondary"
          onClick={withdrawConsignment}
          loading={submitting}
        />
      </div>
    );
  } else {
    return (
      <Button
        text="Send consignment"
        maxWidth
        onClick={onSubmit}
        loading={submitting}
        specialMarking={paidOrBlockedButtonDetails}
      />
    );
  }
};

export default ActionButtons;
