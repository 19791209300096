import { ReactNode, useState } from 'react';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import { ArtworkFieldsIdType, LayoutType } from 'artworks/artworksComponents/viewOptions/types';
import { getLayoutSizeClass } from 'artworks/artworksDisplaying/utils';
import { Artwork, ArtworkStatusEnum } from 'artworks/types';
import { highlightText } from 'domain/utils';
import VisibilityIcon from 'theme/atoms/VisibilityIcon';
import ImageDraft from 'theme/atoms/imageDraft';
import Status from 'theme/atoms/status';
import StatusIcon from 'theme/atoms/statusIcon';
import styles from './ArtworkDetailItem.module.scss';

const onlyInLineItems: ArtworkFieldsIdType[] = ['author'];
const dontShowUncutContentItems: ArtworkFieldsIdType[] = ['image', 'visibilityIcon', 'statusIcon'];

interface ArtworkDetailItemProps {
  size: number;
  layout: LayoutType;
  fieldId: ArtworkFieldsIdType;
  artwork: Artwork;
}

// TODO: highlight other fields when searching will be ready on BE
const getFieldElement = (
  field: string,
  artwork: Artwork,
  searchString: string,
  size: number,
  layout: LayoutType
): ReactNode => {
  const elements = {
    image: artwork.assets.imageUrls?.[0]?.presignedUrl ? (
      <img src={artwork.assets.imageUrls?.[0]?.presignedUrl} className={styles.img} alt={artwork.name} />
    ) : (
      <ImageDraft layout={layout} size={size} className={styles.img} />
    ),
    author: artwork.author?.name ? highlightText(artwork.author?.name, searchString) : 'no author specified',
    nameYear: (
      <>
        {artwork.name ? <span>{highlightText(artwork.name, searchString)}</span> : 'no name specified'}
        {', '}
        {artwork.year || 'no year specified'}
      </>
    ),
    id: artwork.id,
    tags: artwork.tags.join(', '),
    visibilityIcon: <VisibilityIcon visibility={artwork.externalExploringVisibility} />,
    status: <Status status={ArtworkStatusEnum[artwork.exploringOrganizationArtworkStatus]} size={size} />,
    statusIcon: <StatusIcon status={ArtworkStatusEnum[artwork.exploringOrganizationArtworkStatus]} />,
  };
  return elements[field];
};

const ArtworkDetailItem = ({ size, layout, fieldId, artwork }: ArtworkDetailItemProps): JSX.Element => {
  const [showUncutContent, setShowUncutContent] = useState(false);
  const location = useLocation();
  const { artworksStore } = useStores();
  const { searchQuery } = artworksStore;
  const searchString = location.pathname === routes.Artworks.path() ? searchQuery : '';
  const elementToDisplay = getFieldElement(fieldId, artwork, searchString || '', size, layout);

  return (
    <div
      id={fieldId}
      className={clsx(
        styles.root,
        styles[fieldId],
        {
          [styles.onlyItemInLine]: onlyInLineItems.find((el) => el === fieldId),
        },
        styles[getLayoutSizeClass(size, layout)]
      )}
      onMouseEnter={() => layout === 'list' && setShowUncutContent(true)}
      onMouseLeave={() => layout === 'list' && setShowUncutContent(false)}
    >
      {layout === 'list' ? (
        <>
          <div className={styles.cutContentWrapper}>{elementToDisplay}</div>
          {showUncutContent && !dontShowUncutContentItems.find((el) => el === fieldId) && (
            <div className={styles.uncutContentWrapper}>{elementToDisplay}</div>
          )}
        </>
      ) : (
        <>{elementToDisplay}</>
      )}
    </div>
  );
};

export default ArtworkDetailItem;
