import { Currency } from 'domain/types/Domain.types';

export interface Invoices {
  recentInvoices: Invoice[];
  upcomingInvoices: Invoice;
}

export interface Invoice {
  id: string;
  number: string;
  paid: boolean;
  due_date: number;
  currency: Currency;
  amount_due: number;
  amount_paid: number;
  period_start: number;
  period_end: number;
  invoice_pdf: string;
  lines: InvoiceLine[];
  created: number;
}
export interface InvoiceLine {
  id: string;
  product: ProductEnum;
  amount: number;
  currency: Currency;
  description: string;
  price: ProductPrice;
  quantity: number;
}

export interface ProductPrice {
  id: string;
  currency: Currency;
  billing_scheme: BillingScheme;
  recurring: Recurring;
  unit_amount: number;
}

export enum ProductEnum {
  Transaction = 'Transaction',
  TransactionExtraRepresentative = 'TransactionExtraRepresentative',
  ArtworkCertification = 'ArtworkCertification',
  ArtworkCertificationExtraOwner = 'ArtworkCertificationExtraOwner',
  Consignment = 'Consignment',
  KYC = 'KYC',
}

export enum BillingScheme {
  PerUnit = 'per_unit',
  Tiered = 'tiered',
}

export interface Recurring {
  interval: Interval;
  interval_count: number;
  usage_type: UsageType;
}

export type Interval = 'day' | 'month' | 'week' | 'year';
export type UsageType = 'licensed' | 'metered';

export interface SubscriptionPortal {
  customer: string;
  url: string;
}

export interface PaymentMethod {
  id: string;
  created: number;
  type: string;
  card: PaymentCard;
}

export interface PaymentCard {
  brand: string;
  country: string;
  exp_month: number;
  exp_year: number;
  fingerprint: string;
  three_d_secure_usage: boolean;
  last4: string;
}

export interface ProductStripe {
  product: ProductEnum;
  item: {
    id: string;
    price: {
      id: string;
      currency: Currency;
      billing_scheme: BillingScheme;
      recurring: Recurring;
      unit_amount: number;
    };
  };
}

export type ProductsPerUnit = {
  [k in ProductEnum]?: {
    amount: number;
    currency: Currency;
  };
};
