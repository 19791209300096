import { action, makeObservable, observable, runInAction } from 'mobx';
import { RootStore } from 'RootStore';
import { CONSIGNMENT_CATEGORIES_WITH_STATUSES } from 'consignments/constants';
import * as requests from 'consignments/requests';
import { ConsignmentCounts, ConsignmentShowArtwork, ConsignmentStatus } from 'consignments/types';
import SortAndFilterBasicStore, {
  SortAndFilterBasicStoreProps as BasicStoreProps,
} from 'domain/SortAndFilterBasicStore/SortAndFilterBasicStore';
import { FilterItemType } from 'domain/SortAndFilterBasicStore/types';
import { getTimeItemValue } from 'domain/utils';
import { getDefaultSortingItem } from './SortAndFilterPanel';
import { CATEGORIES, GROUP_BY } from './constants';
import { FilterParams } from './types';

const GROUP_BY_OPTIONS = GROUP_BY.map((el) => el.id);
const DEFAULT_GROUP_BY = GROUP_BY[0].id;
type GroupByType = (typeof GROUP_BY_OPTIONS)[number];

const ExtendedStoreProps = {
  groupBy: observable,
  counts: observable,
  filterByAsParams: action,
  fetchGroupCounts: action,
  setGroupBy: action,
  resetGroupBy: action,
  resetActivePage: action,
};

class ConsignmentsSortAndFilterStore extends SortAndFilterBasicStore {
  rootStore: RootStore;

  groupBy: GroupByType = DEFAULT_GROUP_BY;
  counts: ConsignmentCounts | null = null;

  constructor(rootStore: RootStore) {
    super(rootStore);
    this.rootStore = rootStore;
    makeObservable(this, { ...BasicStoreProps, ...ExtendedStoreProps });

    this.defaultSortingItems = CATEGORIES.map(getDefaultSortingItem);
    this.selectedSortingItems = this.defaultSortingItems;
    this.confirmedSortingItems = this.defaultSortingItems;
  }

  filterByAsParams = (filteringItems: FilterItemType[], takeOnlyClickedByUser?: boolean): FilterParams => {
    const filterParams = {
      consigneeContactId: filteringItems
        .filter((el) => el.key === 'consigneeContactId')
        .map((el) => el.value as string),
      consignorContactId: filteringItems
        .filter((el) => el.key === 'consignorContactId')
        .map((el) => el.value as string),
      showArtwork: filteringItems
        .filter((el) => el.key === 'showArtwork')
        .map((el) => {
          const showArtworkKey = Object.keys(ConsignmentShowArtwork).find((k) => k === el.value);
          return showArtworkKey || '';
        }),
      sellArtwork: filteringItems.filter((el) => el.key === 'sellArtwork').map((el) => el.value as boolean),
      priceMin: filteringItems.find((el) => el.key === 'priceMin')?.value as number,
      priceMax: filteringItems.find((el) => el.key === 'priceMax')?.value as number,
      createdAfter: getTimeItemValue(filteringItems, 'createdAfter'),
      createdBefore: getTimeItemValue(filteringItems, 'createdBefore'),
      expireAfter: getTimeItemValue(filteringItems, 'expireAfter'),
      expireBefore: getTimeItemValue(filteringItems, 'expireBefore'),
      status: takeOnlyClickedByUser ? [] : (this.consignmentCategoryStatuses as ConsignmentStatus[]),
    };
    Object.keys(filterParams).forEach((key) => {
      if (!filterParams[key]) delete filterParams[key];
    });
    return filterParams;
  };

  fetchGroupCounts = (): Promise<void> => {
    // docelowo w BasicSortAndFilterStore?
    return requests
      .fetchGroupCounts(this.consignmentCategoryStatuses as ConsignmentStatus[], false)
      .then(({ data }) => {
        runInAction(() => {
          this.counts = data;
        });
      });
  };

  setGroupBy = (val: GroupByType): void => {
    this.rootStore.consignmentsStore.handleResetActivePage();
    this.groupBy = val;
  };

  get consignmentCategoryStatuses(): readonly ConsignmentStatus[] {
    const categoryWithStatus = CONSIGNMENT_CATEGORIES_WITH_STATUSES.find((category) => category.id === this.categoryId);

    return categoryWithStatus?.statuses || [];
  }

  resetGroupBy = (): void => {
    this.groupBy = DEFAULT_GROUP_BY;
  };

  resetActivePage = (): void => {
    this.rootStore.consignmentsStore.handleResetActivePage();
  };

  resetAll = (): void => {
    runInAction(() => {
      this.groupBy = DEFAULT_GROUP_BY;
      this.counts = null;
    });
  };
}

export default ConsignmentsSortAndFilterStore;
