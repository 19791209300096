import { makeAutoObservable, runInAction } from 'mobx';
import { RootStore } from 'RootStore';
import * as requests from 'network/requests';
import { ContactInvitation } from 'network/types';

class OrganizationProfileShareStore {
  rootStore: RootStore;

  loading = false;
  contactInvitation: ContactInvitation | null = null;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  createContactInvitation = (): Promise<void> => {
    const { addToast } = this.rootStore.toastsStore;
    runInAction(() => (this.loading = true));

    return requests
      .createContactInvitation()
      .then(({ data }) => {
        runInAction(() => {
          this.contactInvitation = data;
        });
      })
      .catch(() => addToast('Failed to create contact invitation', 'error'))
      .finally(() => runInAction(() => (this.loading = false)));
  };

  resetAll = (): void => {
    this.contactInvitation = null;
  };
}

export default OrganizationProfileShareStore;
