import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { TRANSACTION_NOTE } from 'domain/constants';
import Billing from 'theme/atoms/billing';
import styles from '../TransactionBilling.module.scss';
import AmountToPay from './AmountToPay';
import ArtistResellRights from './ArtistResellRights';
import SettingPrice from './SettingPrice';

interface BillingProps {
  isSummaryView?: boolean;
}

const TransactionBillingWhenBeingCreatedByArtwork = ({ isSummaryView = false }: BillingProps): JSX.Element => {
  const { editBillingPanelStore } = useStores();
  const { setInitialBillingDetailsWhenTransactionCreatedByArtwork } = editBillingPanelStore;

  useEffect(() => {
    setInitialBillingDetailsWhenTransactionCreatedByArtwork();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={styles.root}>
        <Billing>
          <div className={styles.billingHeader}>
            <Billing.Header>Billing</Billing.Header>
          </div>
          <SettingPrice isSummaryView={isSummaryView} />
          {!isSummaryView && <Billing.Divider />}
          <AmountToPay isSummaryView={isSummaryView} />
          <p className={styles.note}>{TRANSACTION_NOTE}</p>
          {!isSummaryView && <ArtistResellRights isSummaryView={isSummaryView} />}
        </Billing>
      </div>
    </>
  );
};

export default observer(TransactionBillingWhenBeingCreatedByArtwork);
