import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { TransactionStatus } from 'domain/types/Transactions.types';
import IconButton from 'theme/atoms/iconButton';
import { icons } from 'theme/atoms/iconComponent';
import SlidingPanel from 'theme/atoms/slidingPanel';
import { useDebounceIsPanelOpen } from 'theme/atoms/slidingPanel/useDebounceIsPanelOpen';
import BankAccountInfoCard from 'theme/cells/bankAccountInfoCard';
import { TransactionWrapper } from 'transactions/requests.types';
import { useTransactionRoles } from 'utils/hooks';
import styles from './BankAccountForBuyer.module.scss';

const sliderContent =
  'An escrow account is a secure way to handle transactions, where a neutral third party (usually a bank or escrow service) holds the funds until all terms are met. The buyer deposits the money into the escrow account, and once the buyer and seller fulfill their obligations, the funds are verified and released to the seller.';

const transactionsAfterEscrowAccountGranted = [
  TransactionStatus.AcceptedEscrowAccountGranted,
  TransactionStatus.Executed,
  TransactionStatus.Executing,
  TransactionStatus.InvoiceVerification,
  TransactionStatus.ReadyForExecution,
  TransactionStatus.SignificantPaymentsMade,
  TransactionStatus.WaitingForPayment,
];

const BankAccountForBuyer = (): JSX.Element => {
  const { transactionFormStore, transactionStore } = useStores();
  const { fetchEscrowAccountDetails, escrowAccount } = transactionStore;
  const transactionWrapper = transactionFormStore.transactionWrapper as TransactionWrapper;

  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const debouncedIsPanelOpen = useDebounceIsPanelOpen(isPanelOpen);
  const transactionStatus = transactionWrapper.transaction.status;
  const { isBuyer } = useTransactionRoles({ transactionWrapper });

  useEffect(() => {
    if (isBuyer && transactionsAfterEscrowAccountGranted.includes(transactionStatus)) {
      fetchEscrowAccountDetails(transactionWrapper.transaction.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionStatus]);

  if (!escrowAccount) return <></>;

  return (
    <div className={styles.root}>
      <h2 className={styles.header}>
        Account for payment
        <IconButton icon={icons.questionMark} onClick={() => debouncedIsPanelOpen && setIsPanelOpen(true)} />
      </h2>
      <BankAccountInfoCard data={escrowAccount} />
      <SlidingPanel title="Escrow account" onClose={() => setIsPanelOpen(false)} isOpen={isPanelOpen}>
        <div className={styles.sliderContent}>{sliderContent}</div>
      </SlidingPanel>
    </div>
  );
};

export default observer(BankAccountForBuyer);
