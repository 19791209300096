import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { RawBankInvoice } from 'bankPerspective/bankInvoices/requests.types';
import { InvoiceState } from 'domain/types/Invoices.types';
import { Spinner } from 'theme/atoms';
import BillingCard from 'theme/atoms/billingCard';
import ButtonGroup, { ButtonGroupType } from 'theme/atoms/buttonGroup';
import AddressInfoCard from 'theme/cells/addressInfoCard';
import BankAccountInfoCard from 'theme/cells/bankAccountInfoCard';
import InvoiceStateTimeline from 'theme/cells/invoiceStateTimeline';
import { Invoice as InvoiceType } from 'transactions/requests.types';
import { getInvoiceData, formatDate } from 'utils/helpers';
import styles from './BillingAndAddressesCard.module.scss';

interface BillingAndAddressesCardProps {
  actionButtons?: ButtonGroupType[];
  actionButtonsInfo?: string;
  className?: string;
  fromLabel: string;
  goToRelatedTransactionLink?: string;
  isBankPerspective?: boolean;
  item: RawBankInvoice | InvoiceType;
  showBankAccount?: boolean;
  title: string;
  toLabel: string;
}

export const BillingAndAddressesCard = ({
  actionButtons,
  actionButtonsInfo,
  fromLabel,
  goToRelatedTransactionLink,
  isBankPerspective = false,
  item,
  showBankAccount = true,
  title,
  toLabel,
}: BillingAndAddressesCardProps): JSX.Element => {
  const {
    bankStore: { fetchBankAccountDetails, bankAccount: organizationBankAccount, loading },
    escrowAccountFormStore: { fetchEscrowAccountDetails, escrowAccount, loadingEscrowAccountDetails },
    bankTransactionStore: { loadingTransactionDetails },
  } = useStores();
  const {
    sellerOrIssuerInfo,
    buyerOrPayerInfo,
    totalAmount,
    currency,
    externalId,
    state,
    stateChangeLog,
    bankNote,
    modifiedAt,
    billing,
  } = getInvoiceData(item);

  useEffect(() => {
    if (showBankAccount) {
      if (isBankPerspective) fetchEscrowAccountDetails(item.externalId);
      else !organizationBankAccount && fetchBankAccountDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const bankAccount = isBankPerspective ? escrowAccount : organizationBankAccount;

  const bankAccountDetailsDisplay =
    loadingEscrowAccountDetails || loading ? (
      <Spinner small />
    ) : bankAccount ? (
      <BankAccountInfoCard data={bankAccount} />
    ) : (
      <p className={styles.noAccountInfo}>
        NOTE: <span>Bank account details hasn't been added yet.</span>
      </p>
    );

  const relatedTransactionIdDisplay = (
    <BillingCard.TransactionID id={externalId} navigateTo={goToRelatedTransactionLink} />
  );

  const bankPerspectiveBilling = (
    <div className={styles.billingContainer}>
      <BillingCard.SummaryRow
        label="Asking price:"
        value={billing?.artworkPrice}
        currency={currency}
        className={styles.artworkPriceWrapper}
      />
      <BillingCard.SummaryRow label="Commission total:" value={billing?.commission} currency={currency} />
      <BillingCard.SummaryRow label="Artist resell rights:" value={billing?.artistResellRights} currency={currency} />
      <BillingCard.SummaryRow
        label="Total buyer representative commission:"
        value={billing?.totalBuyerRepresentativeCommission}
        currency={currency}
      />
    </div>
  );

  return (
    <BillingCard>
      <BillingCard.Header title={title}>
        {externalId && <div className={styles.transactionIdSmallScreen}>{relatedTransactionIdDisplay}</div>}
        {state && (
          <div className={styles.stateTimeline}>
            <InvoiceStateTimeline stateChangeLog={stateChangeLog} invoice={item} />
          </div>
        )}
      </BillingCard.Header>
      {externalId && <div className={styles.transactionId}>{relatedTransactionIdDisplay}</div>}

      <BillingCard.Body>
        {actionButtons && (
          <>
            <ButtonGroup actionButtons={actionButtons} />
            {actionButtonsInfo && (
              <p className={styles.actionButtonsInfo}>
                {loadingTransactionDetails ? <Spinner small /> : actionButtonsInfo}
              </p>
            )}
          </>
        )}
      </BillingCard.Body>

      <BillingCard.Body className={styles.transactionSitesAddresses}>
        {sellerOrIssuerInfo && <AddressInfoCard label={fromLabel} data={sellerOrIssuerInfo} />}
        <div className={styles.accountDetailsSmallScreen}>{showBankAccount && bankAccountDetailsDisplay}</div>
        {buyerOrPayerInfo && <AddressInfoCard label={toLabel} data={buyerOrPayerInfo} />}
      </BillingCard.Body>

      <BillingCard.Body className={styles.accountDetails}>
        {showBankAccount && bankAccountDetailsDisplay}
      </BillingCard.Body>

      <BillingCard.Wrapper
        className={styles.billingWrapper}
        amount={totalAmount}
        currency={currency}
        label="Billing"
        sumupTitle="Total"
        withPaidIcon={state === InvoiceState.Paid}
      >
        {isBankPerspective && bankPerspectiveBilling}
      </BillingCard.Wrapper>

      <BillingCard.Body>
        {bankNote && (
          <div className={styles.bankNoteSection}>
            <h3 className={styles.sectionTitle}>Comments:</h3>
            <p>{formatDate(modifiedAt)}</p>
            <section>{bankNote}</section>
          </div>
        )}
      </BillingCard.Body>
    </BillingCard>
  );
};

export default observer(BillingAndAddressesCard);
