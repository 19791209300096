import { makeAutoObservable, runInAction } from 'mobx';
import { RootStore } from 'RootStore';
import * as requests from 'bankPerspective/bankTransactions/requests';
import { RawBankTransaction } from 'bankPerspective/bankTransactions/requests.types';
import { TransactionStatus } from 'domain/types/Transactions.types';

class BankTransactionStore {
  rootStore: RootStore;

  selectedTransaction: RawBankTransaction | null = null;
  loadingTransactionDetails = true;

  submittingTransactionToExecution = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  fetchBankTransaction = (transactionId: string): Promise<void | RawBankTransaction> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.loadingTransactionDetails = true));

    return requests
      .fetchBankTransaction(transactionId)
      .then(({ data }) => {
        runInAction(() => {
          this.selectedTransaction = data.results[0];
        });
        return data.results[0] as RawBankTransaction;
      })
      .catch(() => addToast(toastMessages.BANK_TRANSACTIONS.DETAILS_FETCH_ERROR, 'error'))
      .finally(() => runInAction(() => (this.loadingTransactionDetails = false)));
  };

  sendTransactionToExecution = (transactionId: string): Promise<void> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.submittingTransactionToExecution = true));

    return requests
      .sendTransactionToExecution(transactionId)
      .then(() => {
        runInAction(() => {
          if (this.selectedTransaction) {
            this.setSelectedTransactionStatus(TransactionStatus.Executing);
          }
        });
        addToast(toastMessages.BANK_TRANSACTIONS.SEND_TO_EXECUTION_SUCCESS, 'success');
      })
      .catch(() => addToast(toastMessages.BANK_TRANSACTIONS.SEND_TO_EXECUTION_ERROR, 'error'))
      .finally(() => runInAction(() => (this.submittingTransactionToExecution = false)));
  };

  setSelectedTransactionStatus = (status: TransactionStatus): void => {
    if (!this.selectedTransaction) return;
    this.selectedTransaction = {
      ...this.selectedTransaction,
      status,
    };
  };

  resetSelectedTransaction = (): void => {
    runInAction(() => (this.selectedTransaction = null));
  };

  resetAll = (): void => {
    runInAction(() => {
      this.selectedTransaction = null;
      this.loadingTransactionDetails = true;
      this.submittingTransactionToExecution = false;
    });
  };
}

export default BankTransactionStore;
