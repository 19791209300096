import { DataFieldType } from './types';

/**
 * Move an array item to a different position. Returns a new array with the item moved to the new position.
 */
export function arrayMove<T>(array: T[], from: number, to: number): T[] {
  const newArray = array.slice();
  newArray.splice(to < 0 ? newArray.length + to : to, 0, newArray.splice(from, 1)[0]);

  return newArray;
}

export function getNumberOfCheckedFields(dataFields: DataFieldType[]): number {
  let count = 0;
  dataFields.forEach((field) => {
    if (field.isChecked) count++;
    if (field.child?.isChecked) count++;
  });

  return count;
}
