import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { ContactConnection } from 'network/types';
import Modal from 'theme/atoms/modal';
import DeleteContactModal from '../../components';
import { CategoryId } from '../../types';
import { ContactToProceed } from '../NetworkTable';

interface NetworkActionModalProps {
  categoryId: CategoryId;
  contactToDelete: ContactConnection | null;
  contactToProceed: ContactToProceed | null;
  setContactToProceed: (contact: ContactToProceed | null) => void;
  setContactToDelete: (contact: ContactConnection | null) => void;
}

const NetworkActionModal = observer(
  ({
    categoryId,
    contactToProceed,
    contactToDelete,
    setContactToProceed,
    setContactToDelete,
  }: NetworkActionModalProps): JSX.Element => {
    const { networkManagingStore } = useStores();
    const { deleteContactConnection, acceptContactConnection, ignoreContactConnection } = networkManagingStore;

    const onDeletionConfirm = (): void => {
      contactToDelete && deleteContactConnection(contactToDelete?.id, categoryId);
      setContactToDelete(null);
    };

    const onActionConfirm = (): void => {
      if (contactToProceed) {
        if (contactToProceed.action === 'accept') {
          acceptContactConnection(contactToProceed.contact.id, categoryId);
        } else {
          ignoreContactConnection(contactToProceed.contact.id, categoryId);
        }
        setContactToProceed(null);
      }
    };

    const networkModal = (
      <DeleteContactModal
        isOpen={!!contactToDelete}
        onClose={() => setContactToDelete(null)}
        onDeletionConfirm={onDeletionConfirm}
        contactName={contactToDelete?.contactOrganizationName || ''}
      />
    );

    const pendingInvModal = (
      <Modal
        isOpen={!!contactToProceed}
        onClose={() => setContactToProceed(null)}
        title={`${contactToProceed?.action === 'accept' ? 'Accept' : 'Ignore'} ${
          contactToProceed?.contact.contactOrganizationName
        }?`}
        subtitle={`Are you sure you want to ${contactToProceed?.action} invitation from ${contactToProceed?.contact.contactOrganizationName}?`}
        btnAbort={{ label: 'Cancel', function: () => setContactToProceed(null) }}
        btnConfirm={{ label: 'Confirm', function: () => onActionConfirm() }}
      />
    );

    const requestedInvModal = (
      <Modal
        isOpen={!!contactToDelete}
        onClose={() => setContactToDelete(null)}
        title={`Delete invitation form ${contactToDelete?.contactOrganizationName}?`}
        subtitle={`Are you sure you want to delete invitation from ${contactToDelete?.contactOrganizationName}?`}
        btnAbort={{ label: 'Cancel', function: () => setContactToDelete(null) }}
        btnConfirm={{ label: 'Delete', function: () => onDeletionConfirm() }}
      />
    );

    return (
      <>
        {(categoryId === 'network' && networkModal) ||
          (categoryId === 'pending' && pendingInvModal) ||
          (categoryId === 'requested' && requestedInvModal)}
      </>
    );
  }
);
export default NetworkActionModal;
