import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { Button, Subtitle, TitleBlack } from 'theme/atoms';
import styles from './CreateOrganization.module.scss';

const VerificationCompleted = (): JSX.Element => {
  const { organizationStore } = useStores();
  const { finishOrganizationSetup } = organizationStore;

  return (
    <div className={styles.innerWrapper}>
      <TitleBlack text="Verification process completed!" dontLimitWidth />
      <Subtitle>You will get an email once your documents have been approved.</Subtitle>
      <Subtitle>We appreciate your patience.</Subtitle>
      <Button text="Continue" onClick={() => finishOrganizationSetup()} maxWidth className={styles.submitBtn} />
    </div>
  );
};
export default observer(VerificationCompleted);
