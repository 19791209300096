import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
// import { ReactComponent as ConnectIcon } from 'theme/assets/svg/connect.svg';
// import { ReactComponent as ManageFilesIcon } from 'theme/assets/svg/manageFiles.svg';
import { ReactComponent as QRCodeIcon } from 'theme/assets/svg/qrCode.svg';
import ActionButtons, { ActionButton } from 'theme/atoms/ActionButtons';
import Breadcrumbs, { Breadcrumb } from 'theme/atoms/Breadcrumbs';
import Topbar from 'theme/atoms/Topbar';
import Loader from 'theme/atoms/loader';
import NoDataToDisplay from 'theme/atoms/noDataToDisplay';
import SearchTable from 'theme/cells/searchTable';
import { SearchTableView } from 'theme/cells/searchTable/constants';
import styles from './NetworkList.module.scss';
import { NETWORK_CATEGORIES_WITH_STATUSES } from './constants';
import ListNetworkTable from './networkTable/NetworkTable';
import { CategoryId } from './types';

// commented code is for selecting invitations by dropdown instead of tabs

// const INVITATION_KINDS = ['requested', 'pending'];
// type InvitationKind = (typeof INVITATION_KINDS)[number];

// const INVITATIONS_OPTIONS: { value: InvitationKind; label: string }[] = [
//   { value: 'requested', label: 'Requested' },
//   { value: 'pending', label: 'Pending' },
// ];

const BREADCRUMBS: Breadcrumb[] = [{ label: 'Network' }];

const NetworkList = observer((): JSX.Element => {
  const { categoryId } = useParams<{ categoryId: CategoryId }>();
  const navigate = useNavigate();
  const { listNetworkStore, networkSortAndFilterStore } = useStores();
  const { loading, items } = listNetworkStore;
  const [searchParams] = useSearchParams();

  const actionButtons: ActionButton[] = [
    {
      text: 'My QR',
      icon: <QRCodeIcon />,
      onClick: () => navigate(routes.OrganizationProfileShare.path()),
    },
  ];

  const selectCategory = (categoryId: string): void => {
    // const goTo = categoryId === 'invitations' ? 'invitations?kind=requested' : categoryId;
    // navigate(routes.Network.path(goTo));
    navigate({ pathname: routes.Network.path(categoryId), search: searchParams.toString() });
  };

  // const [selectedValue, setSelectedValue] = useState<InvitationKind>('requested');

  // useEffect(() => {
  //   const searchParamsKind = searchParams.get('kind');
  //   const kind = searchParamsKind && INVITATION_KINDS.includes(searchParamsKind) ? searchParamsKind : 'requested';
  //   setSelectedValue(kind);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [searchParams]);

  // const select = (
  //   <Select
  //     options={INVITATIONS_OPTIONS}
  //     value={{
  //       value: selectedValue,
  //       label: INVITATIONS_OPTIONS.find((el) => el.value === selectedValue)?.label || 'requested',
  //     }}
  //     onChange={(option) => navigate(routes.Network.path(`invitations?kind=${option.value}`))}
  //     className={styles.select}
  //   />
  // );

  const networkTable = loading ? (
    <Loader minHeight={150} />
  ) : items?.length > 0 ? (
    <ListNetworkTable />
  ) : (
    <NoDataToDisplay copy="No items to display." height={100} />
  );

  return (
    <div className={styles.root}>
      <Topbar>
        <Breadcrumbs breadcrumbs={BREADCRUMBS} />
        <ActionButtons buttons={actionButtons} />
      </Topbar>

      <SearchTable
        dontShowSortAndFilterPanel
        view={SearchTableView.Network}
        store={listNetworkStore}
        filterStore={networkSortAndFilterStore}
      >
        <ul className={styles.categoriesWrapper}>
          {NETWORK_CATEGORIES_WITH_STATUSES.map(({ id, label }) => (
            <li key={id} onClick={() => selectCategory(id)} className={clsx({ [styles.active]: id === categoryId })}>
              {label}
            </li>
          ))}
        </ul>
      </SearchTable>

      {/* {categoryId !== 'network' ? select : null} */}
      <div>{networkTable}</div>
    </div>
  );
});
export default NetworkList;
