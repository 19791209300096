import { makeAutoObservable, runInAction } from 'mobx';
import { RootStore } from 'RootStore';
import Big from 'big.js';
import { CURRENCIES } from 'domain/constants';
import { TransactionSellerSide, TransactionWrapper } from 'transactions/requests.types';
import { SelectedBillingDetails, SelectedConsignmentDetails } from './types';

class EditBillingPanelStore {
  rootStore: RootStore;
  selectedBillingDetails: SelectedBillingDetails | null = null;
  selectedConsignmentDetails: SelectedConsignmentDetails | null = null;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  get isCommissionAmountHigherThanMax(): boolean {
    return Boolean(
      Big(this.selectedBillingDetails?.selectedCommissionAmount || 0).gt(
        Big(this.selectedConsignmentDetails?.selectedMaxCommissionPercent || 0)
          .times(this.selectedConsignmentDetails?.selectedAskingPrice || 0)
          .div(100)
      )
    );
  }

  get isArtworkPriceHigherThanMax(): boolean {
    return Boolean(
      Big(this.selectedBillingDetails?.selectedArtworkPrice || 0).gt(
        Big(this.selectedConsignmentDetails?.selectedAskingPrice || 0).add(
          Big(this.selectedConsignmentDetails?.selectedMaxCommissionPercent || 0)
            .div(100)
            .times(this.selectedConsignmentDetails?.selectedAskingPrice || 0)
        )
      )
    );
  }

  get isArtworkPriceSmallerThanAskingPrice(): boolean {
    return Boolean(
      this.selectedBillingDetails?.selectedArtworkPrice &&
        Big(this.selectedConsignmentDetails?.selectedAskingPrice || 0).gt(
          this.selectedBillingDetails?.selectedArtworkPrice || 0
        )
    );
  }

  get isCommissionPercentHigherThanMax(): boolean {
    return Boolean(
      Big(this.selectedBillingDetails?.selectedCommissionPercent || 0).gt(
        Big(this.selectedConsignmentDetails?.selectedMaxCommissionPercent || 0)
      )
    );
  }

  get isMaxCommissionHigherThanSelectedCommissionPercent(): boolean {
    return Big(this.selectedConsignmentDetails?.selectedMaxCommissionPercent || 0).gt(
      Big(this.selectedBillingDetails?.selectedCommissionPercent || 0)
    );
  }

  setInitialBillingDetailsWhenTransactionCreatedByConsignment = (sellerInfo: TransactionSellerSide): void => {
    runInAction(() => {
      this.selectedBillingDetails = {
        selectedCommissionAmount: sellerInfo?.totalCommission ? Big(sellerInfo.totalCommission) : null,
        selectedCommissionPercent: sellerInfo?.totalCommission
          ? Big(sellerInfo.totalCommission).times(100).div(sellerInfo.price)
          : null,
        selectedArtworkPrice: sellerInfo?.totalCommission
          ? Big(sellerInfo?.totalCommission).add(sellerInfo.price)
          : null,
      };
    });
  };

  setInitialBillingDetailsWhenTransactionCreatedByArtwork = (): void => {
    runInAction(() => {
      this.selectedBillingDetails = {
        selectedCommissionAmount: null,
        selectedCommissionPercent: null,
        selectedArtworkPrice: null,
      };
    });
  };

  setInitialConsignmentDetails = (transactionWrapper: TransactionWrapper): void => {
    const { transaction, consignment } = transactionWrapper;
    const initialMaxCommissionPercent = Big(transaction.sellerInfo?.totalCommission || 0).gt(
      consignment?.maxCommission || 0
    )
      ? Big(transaction.sellerInfo?.totalCommission || 0)
          .times(100)
          .div(transaction.sellerInfo?.price || 1)
      : Big(consignment?.maxCommission || 0)
          .times(100)
          .div(transaction.sellerInfo?.price || 1);

    runInAction(() => {
      this.selectedConsignmentDetails = {
        selectedAskingPrice: transaction.sellerInfo ? Big(transaction.sellerInfo.price) : null,
        selectedCurrency: transaction.currency || CURRENCIES[0],
        selectedMaxCommissionPercent: initialMaxCommissionPercent || null,
      };
    });
  };

  recalculateCommissionAmountAndArtworkPrice = (): void => {
    if (
      !this.selectedBillingDetails?.selectedCommissionPercent ||
      !this.selectedConsignmentDetails?.selectedAskingPrice
    )
      return;
    const newCommissionAmount = Big(this.selectedBillingDetails.selectedCommissionPercent)
      .times(this.selectedConsignmentDetails.selectedAskingPrice)
      .div(100);

    this.updateSelectedBillingDetails({
      ...this.selectedBillingDetails,
      selectedCommissionAmount: newCommissionAmount,
      selectedArtworkPrice: Big(newCommissionAmount).add(this.selectedConsignmentDetails.selectedAskingPrice),
    });
  };

  recalculateCommissionPercentAndArtworkPrice = (): void => {
    if (!this.selectedBillingDetails || !this.selectedConsignmentDetails) return;
    this.updateSelectedBillingDetails({
      ...this.selectedBillingDetails,
      selectedCommissionPercent: Big(this.selectedBillingDetails.selectedCommissionAmount || 0)
        .times(100)
        .div(this.selectedConsignmentDetails.selectedAskingPrice || 1),
      selectedArtworkPrice: Big(this.selectedBillingDetails.selectedCommissionAmount || 0).add(
        this.selectedConsignmentDetails.selectedAskingPrice || 0
      ),
    });
  };

  recalculateCommissionAmountAndCommissionPercent = (): void => {
    const newCommissionAmount = Big(this.selectedBillingDetails?.selectedArtworkPrice || 0).minus(
      Big(this.selectedConsignmentDetails?.selectedAskingPrice || 0)
    );
    const newCommissionPercent = Big(newCommissionAmount)
      .times(100)
      .div(this.selectedConsignmentDetails?.selectedAskingPrice || 1);

    this.selectedBillingDetails &&
      this.updateSelectedBillingDetails({
        ...this.selectedBillingDetails,
        selectedCommissionAmount: newCommissionAmount.gte(Big(0)) ? newCommissionAmount : Big(0),
        selectedCommissionPercent: newCommissionPercent.gte(Big(0)) ? newCommissionPercent : Big(0),
      });
  };

  updateSelectedBillingDetails = (billingDetails: SelectedBillingDetails): void => {
    runInAction(() => {
      this.selectedBillingDetails = billingDetails;
    });
  };

  updateSelectedConsignmentDetails = (consignmentDetails: SelectedConsignmentDetails): void => {
    runInAction(() => {
      this.selectedConsignmentDetails = consignmentDetails;
    });
  };

  resetAll = (): void => {
    runInAction(() => {
      this.selectedBillingDetails = null;
      this.selectedConsignmentDetails = null;
    });
  };
}

export default EditBillingPanelStore;
