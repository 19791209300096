import { TransactionStatus } from 'domain/types/Transactions.types';
import { ReactComponent as Accepted } from 'theme/assets/svg/checkMark.svg';
import { ReactComponent as Rejected } from 'theme/assets/svg/close.svg';
import { ReactComponent as Edit } from 'theme/assets/svg/edit.svg';
import { ReactComponent as Envelope } from 'theme/assets/svg/envelope.svg';
import { ReactComponent as Exclamation } from 'theme/assets/svg/exclamationMarkInTriangle.svg';
import { ReactComponent as Pending } from 'theme/assets/svg/hourglass.svg';
import { ReactComponent as Closed } from 'theme/assets/svg/padlock.svg';
import { ReactComponent as Paid } from 'theme/assets/svg/paidTransaction.svg';

export const statusIcon = (status: TransactionStatus): JSX.Element => {
  switch (status) {
    case TransactionStatus.PendingOwnersAgreement:
    case TransactionStatus.PendingBuyersAgreement:
    case TransactionStatus.InvoiceVerification:
    case TransactionStatus.WaitingForPayment:
      return <Exclamation />;
    case TransactionStatus.Accepted:
      return <Accepted />;
    case TransactionStatus.RejectedByOwner:
    case TransactionStatus.RejectedByBuyer:
    case TransactionStatus.RejectedByBuyerRepresentative:
    case TransactionStatus.RejectedBySellerRepresentative:
      return <Rejected />;
    case TransactionStatus.SentToBuyerRepresentative:
      return <Envelope />;
    case TransactionStatus.Executing:
    case TransactionStatus.ReadyForExecution:
    case TransactionStatus.AcceptedEscrowAccountGranted:
      return <Pending />;
    case TransactionStatus.Draft:
      return <Edit />;
    case TransactionStatus.SignificantPaymentsMade:
    case TransactionStatus.Executed:
      return <Paid />;
    case TransactionStatus.Closed:
      return <Closed />;
    default:
      return <Exclamation />;
  }
};
