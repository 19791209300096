import { ReactElement } from 'react';
import { Navigate, Route } from 'react-router-dom';
import { AppWrapper } from 'AppWrapper/AppWrapper';
import AuthWrapper from 'auth/AuthWrapper/AuthWrapper';
import { SettingsLayout } from 'settings/settingsLayout';
import { URL_PARAM, routes } from '../paths.const';
import { getCommonRoutesForAuthenticatedUser } from './getCommonRoutesForAuthenticatedUser';

export const getRoutesForAuthenticatedIndividualUser = (): ReactElement => {
  return (
    <Route>
      <Route element={<AuthWrapper />}>
        {Object.entries(routes)
          .filter(([_key, view]) => view.pageType === 'publicAndPrivate')
          .map(([key, view]) => (
            <Route key={key} path={view.path()} element={view.component} />
          ))}
      </Route>
      <Route element={<AppWrapper />}>
        <Route path={routes.Artworks.path()} element={routes.Artworks.component} />
        <Route path={routes.Artwork.path(URL_PARAM.artworkId)} element={routes.Artwork.component} />
        <Route path={routes.AddArtworkAuthor.path(URL_PARAM.artworkId)} element={routes.AddArtworkAuthor.component} />
        <Route path={routes.AddArtworkDetails.path(URL_PARAM.artworkId)} element={routes.AddArtworkDetails.component} />
        <Route
          path={routes.AddArtworkOwnership.path(URL_PARAM.artworkId)}
          element={routes.AddArtworkOwnership.component}
        />
        <Route path={routes.AddArtworkSummary.path(URL_PARAM.artworkId)} element={routes.AddArtworkSummary.component} />
        <Route
          path={routes.ArtworkRegistration.path(URL_PARAM.categoryId)}
          element={routes.ArtworkRegistration.component}
        />
        <Route path={routes.EditArtwork.path(URL_PARAM.artworkId)} element={routes.EditArtwork.component} />
        <Route path={routes.ArtworkDraft.path(URL_PARAM.artworkId)} element={routes.ArtworkDraft.component} />
        <Route path={routes.Consignments.path(URL_PARAM.categoryId)} element={routes.Consignments.component} />
        <Route path={routes.Consignment.path(URL_PARAM.consignmentId)} element={routes.Consignment.component} />
        <Route path={routes.Transactions.path(URL_PARAM.categoryId)} element={routes.Transactions.component} />
        <Route path={routes.Transaction.path(URL_PARAM.transactionId)} element={routes.Transaction.component} />
        <Route
          path={routes.TransactionArtwork.path(URL_PARAM.transactionId)}
          element={routes.TransactionArtwork.component}
        />
        <Route path={routes.Invoices.path(URL_PARAM.categoryId)} element={routes.Invoices.component} />
        <Route path={routes.Invoice.path(URL_PARAM.invoiceId)} element={routes.Invoice.component} />
        <Route path={routes.Network.path(URL_PARAM.categoryId)} element={routes.Network.component} />
        <Route
          path={routes.ContactInvitation.path(URL_PARAM.invitationId)}
          element={routes.ContactInvitation.component}
        />
        <Route path={routes.ContactProfile.path(URL_PARAM.contactId)} element={routes.ContactProfile.component} />
        <Route path={routes.Billing.path()} element={routes.Billing.component} />
        <Route element={<SettingsLayout />}>
          <Route path={routes.SettingsMyAccount.path()} element={routes.SettingsMyAccount.component} />
          <Route
            path={routes.SettingsOrganizationProfile.path()}
            element={routes.SettingsOrganizationProfile.component}
          />
        </Route>
      </Route>
      <Route element={<AppWrapper isBackgroundColorful />}>
        <Route path={routes.OrganizationProfileShare.path()} element={routes.OrganizationProfileShare.component} />
      </Route>

      {getCommonRoutesForAuthenticatedUser()}
      <Route path="*" element={<Navigate to={routes.Artworks.path()} replace />} />
    </Route>
  );
};
