import { ConsignmentStatus } from './types';

export const CONSIGNMENT_CATEGORIES_WITH_STATUSES = [
  {
    id: 'active',
    label: 'Active',
    statuses: [ConsignmentStatus.Accepted],
  },
  {
    id: 'awaiting-approval',
    label: 'Awaiting approval',
    statuses: [ConsignmentStatus.PendingOwnersAgreement, ConsignmentStatus.PendingConsigneeAgreement],
  },
  {
    id: 'rejected',
    label: 'Rejected',
    statuses: [ConsignmentStatus.RejectedByOwner, ConsignmentStatus.RejectedByConsignee],
  },
  {
    id: 'expired',
    label: 'Expired',
    statuses: [ConsignmentStatus.Expired],
  },
] as const;

export const DEFAULT_CONSIGNMENT_CATEGORY = CONSIGNMENT_CATEGORIES_WITH_STATUSES[0].id;
