import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { ARTWORK_STEPS } from 'artworks/addArtwork/constants';
import styles from './AddArtworkSteps.module.scss';

interface AddArtworkStepsProps {
  step: (typeof ARTWORK_STEPS)[number]['label'];
}

const AddArtworkSteps = ({ step }: AddArtworkStepsProps): JSX.Element => {
  const activeStep: number = ARTWORK_STEPS.findIndex((item) => item.label === step);

  return (
    <div className={styles.root}>
      {ARTWORK_STEPS.map(({ label }, index) => (
        <div
          key={label}
          className={clsx(
            styles.step,
            { [styles.accomplished]: index < activeStep },
            { [styles.active]: index === activeStep },
            { [styles.notAccomplished]: index > activeStep }
          )}
        >
          <div className={styles.symbol} />
          <div className={styles.label}>{label}</div>
        </div>
      ))}
    </div>
  );
};

export default observer(AddArtworkSteps);
