import { Button } from 'theme/atoms';
import styles from './ActionButtons.module.scss';

export interface ActionButton {
  text: string;
  icon: JSX.Element;
  onClick: () => void;
}

interface ActionButtonsProps {
  buttons: ActionButton[];
}

const ActionButtons = ({ buttons }: ActionButtonsProps): JSX.Element => {
  return (
    <div className={styles.root}>
      {buttons.map(({ text, icon, onClick }: ActionButton, index): JSX.Element => {
        return (
          <Button
            key={text + index}
            buttonType="tertiary"
            text={text}
            iconStart={icon}
            type="button"
            onClick={onClick}
            uppercase={false}
            light
          />
        );
      })}
    </div>
  );
};

export default ActionButtons;
