import SectionHeader from 'theme/atoms/Titles/SectionHeader';
import styles from './Restrictions.module.scss';

interface RestrictionsProps {
  salesRestrictions: string;
}

const Restrictions = ({ salesRestrictions }: RestrictionsProps): JSX.Element => {
  return (
    <>
      <SectionHeader title="Restrictions" className={styles.header} />
      <div className={styles.content}>{salesRestrictions || 'none'}</div>
    </>
  );
};

export default Restrictions;
