/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactNode, useEffect } from 'react';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import { getEmptyLayoutRoute } from 'app/routes/routesByUser/getEmptyLayoutRoute';
import { getRoutesForAuthenticatedBankUser } from 'app/routes/routesByUser/getRoutesForAuthenticatedBankUser';
import { getRoutesForAuthenticatedIndividualUser } from 'app/routes/routesByUser/getRoutesForAuthenticatedIndividualUser';
import { getRoutesForAuthenticatedNotVerifiedIndividualUser } from 'app/routes/routesByUser/getRoutesForAuthenticatedNotVerifiedIndividualUser';
import { getRoutesForAuthenticatedWithoutOrganization } from 'app/routes/routesByUser/getRoutesForAuthenticatedWithoutOrganization';
import { getRoutesForUnauthenticatedUser } from 'app/routes/routesByUser/getRoutesForUnauthenticatedUser';
import { DEFAULT_BANK_TRANSACTIONS_CATEGORY } from 'bankPerspective/bankTransactions/constants';
import { UserAccountInfo } from 'domain/types/Domain.types';
import { Organization, KycStatus } from 'organization/types';

type UseUserContextReturn = {
  availableRoutes: ReactNode;
  homePath: string;
  isFetchingOrganizationsFinished: boolean;
  isOrganizationBank: boolean;
  organizations: Organization[] | null;
  selectedOrganization: Organization | null;
  userDetails: UserAccountInfo | null;
  isOrganizationSettingFinished: boolean;
  organizationSituation;
};

export const useUserContext = (): UseUserContextReturn => {
  const {
    authStore: { isUserAuthenticated },
    userStore: { userDetails, getUserDetails, checkonPaymentMethods, resetAllInAllStoresExceptUserAndAuth },
    organizationStore: {
      fetchOrganizations,
      isFetchingOrganizationsFinished,
      isOrganizationBank,
      organizations,
      selectedOrganization,
      isOrganizationSettingFinished,
    },
    billingStore: { fetchProducts: fetchBillingProducts },
  } = useStores();

  useEffect(() => {
    if (!isUserAuthenticated) return;

    if (!userDetails) getUserDetails();

    if (organizations === null) {
      fetchOrganizations().then((response) => {
        if (response?.success) {
          resetAllInAllStoresExceptUserAndAuth();

          if (!response.isOrganizationBank) {
            checkonPaymentMethods(true);
            fetchBillingProducts();
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserAuthenticated]);

  const organizationSituation = {
    userNotAuthenticated: !isUserAuthenticated,
    stillFetchingData: !isFetchingOrganizationsFinished,
    organizationIsBank: isOrganizationBank,
    organizationIsNotSet: !isOrganizationSettingFinished,
    organizationIsNotVerified: !isOrganizationBank && selectedOrganization?.kycStatus !== KycStatus.Verified,
  };

  const orgSit = organizationSituation;

  function getAvailableRoutes(): ReactNode | null {
    if (orgSit.userNotAuthenticated) return getRoutesForUnauthenticatedUser();
    if (orgSit.stillFetchingData) return getEmptyLayoutRoute();
    if (orgSit.organizationIsBank) return getRoutesForAuthenticatedBankUser();
    if (orgSit.organizationIsNotSet) return getRoutesForAuthenticatedWithoutOrganization();
    if (orgSit.organizationIsNotVerified) return getRoutesForAuthenticatedNotVerifiedIndividualUser();
    return getRoutesForAuthenticatedIndividualUser();
  }

  function getHomePath(): string {
    return orgSit.userNotAuthenticated
      ? routes.Login.path()
      : orgSit.organizationIsBank
      ? routes.BankTransactions.path(DEFAULT_BANK_TRANSACTIONS_CATEGORY)
      : orgSit.organizationIsNotSet
      ? routes.CreateOrganizationWrapper.path()
      : orgSit.organizationIsNotVerified
      ? routes.SettingsKYC.path()
      : routes.Artworks.path();
  }

  return {
    availableRoutes: getAvailableRoutes(),
    homePath: getHomePath(),
    isFetchingOrganizationsFinished,
    isOrganizationBank,
    organizations,
    selectedOrganization,
    userDetails,
    isOrganizationSettingFinished,
    organizationSituation,
  };
};
