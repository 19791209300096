import { makeAutoObservable, runInAction } from 'mobx';
import { RootStore } from 'RootStore';
import { OrganizationCreateDto } from 'createOrganization/types';
import { StoreActionReturn } from 'domain/types/StoreActionReturn';
import * as requests from './requests';
import { KycToken, Organization, OrganizationKind, RawOrganization } from './types';

class OrganizationStore {
  rootStore: RootStore;

  loading = false;
  organizations: Organization[] | null = null;
  selectedOrganization: Organization | null = null;
  isFetchingOrganizationsFinished = false;
  creatingOrganizationKind: OrganizationKind | undefined = undefined;
  organizationBeingSetupId: string | undefined = undefined;

  loadingKycToken = false;
  loadedKycToken = false;
  kycToken: KycToken | undefined = undefined;

  resetingKycStatus = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  get isOrganizationBank(): boolean {
    return this.selectedOrganization?.type === OrganizationKind.Bank;
  }

  get isOrganizationSettingFinished(): boolean {
    return Boolean(this.selectedOrganization && this.selectedOrganization.id !== this.organizationBeingSetupId);
  }

  resetAllOrganizationsData = (): void => {
    runInAction(() => {
      this.organizations = null;
      this.selectedOrganization = null;
      this.isFetchingOrganizationsFinished = false;
      this.creatingOrganizationKind = undefined;
      this.organizationBeingSetupId = undefined;
      this.kycToken = undefined;
    });
  };

  fetchOrganizations = (): Promise<{ success: boolean; isOrganizationBank: boolean } | void> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.loading = true));

    return requests
      .fetchOrganizations()
      .then(({ data: organizations }: { data: Organization[] }) => {
        const sortedOrganizationsFromNewest = organizations.sort((a, b) =>
          a.createdAt && b.createdAt && a.createdAt > b.createdAt ? -1 : 1
        );
        runInAction(() => {
          this.organizations = sortedOrganizationsFromNewest;
        });

        if (sortedOrganizationsFromNewest.length > 0) {
          const selectedOrganization = sortedOrganizationsFromNewest[0];
          runInAction(() => {
            this.selectedOrganization = selectedOrganization;
            // setting newest organization as selectedOrganization by default is crutial to handle case when user refreshes the window while adding new organization
            // replace sorting here by calling GET /organizations with sort parameter (when supported by BE)
          });

          if (selectedOrganization?.type !== OrganizationKind.Bank) {
            this.rootStore.bankStore.fetchBankAccountDetails();
          }

          // dodać metodę na wybór organizacji i wtedy wołać this.rootStore.userStore.resetAllInAllStoresExceptUserAndAuth();
          return { success: true, isOrganizationBank: selectedOrganization?.type === OrganizationKind.Bank };
        }
      })
      .catch(() => {
        addToast(toastMessages.DEFAULT.ERROR, 'error');
      })
      .finally(() =>
        runInAction(() => {
          this.loading = false;
          this.isFetchingOrganizationsFinished = true;
        })
      );
  };

  setCreatingOrganizationKind = (type: OrganizationKind): void => {
    runInAction(() => {
      this.creatingOrganizationKind = type;
    });
  };

  createOrganization = (data: OrganizationCreateDto): Promise<StoreActionReturn | void> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.loading = true));

    return requests
      .createOrganization(data)
      .then(({ data: createdOrganization }: { data: Organization }) => {
        runInAction(() => {
          this.organizations = [...(this.organizations || []), createdOrganization];
          this.creatingOrganizationKind = undefined;
          this.selectedOrganization = createdOrganization;
          this.organizationBeingSetupId = createdOrganization.id;
        });
        return {
          success: true,
        };
      })
      .catch(() => {
        addToast(toastMessages.DEFAULT.ERROR, 'error');
      })
      .finally(() => runInAction(() => (this.loading = false)));
  };

  updateOrganization = (data: Partial<RawOrganization>): Promise<void> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    if (!this.selectedOrganization) return Promise.resolve();

    return requests
      .updateOrganization(this.selectedOrganization.id, data)
      .then(({ data: updatedOrganization }: { data: Organization }) => {
        runInAction(() => {
          this.organizations = this.organizations?.map((org) =>
            org.id === updatedOrganization.id ? updatedOrganization : org
          ) || [updatedOrganization];
          this.selectedOrganization = updatedOrganization;
        });
        addToast('Your account has been updated successfully');
      })
      .catch(() => {
        addToast(toastMessages.DEFAULT.ERROR, 'error');
      });
  };

  setSelectedOrganizationAsBeingSetup = (): void => {
    runInAction(() => (this.organizationBeingSetupId = this.selectedOrganization?.id));
  };

  finishOrganizationSetup = (): void => {
    runInAction(() => (this.organizationBeingSetupId = undefined));
  };

  fetchKycToken = (showMsg?: boolean): Promise<KycToken | void> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.loadingKycToken = true));

    return requests
      .fetchKycToken()
      .then(({ data }) => {
        runInAction(() => {
          this.kycToken = data;
        });
        if (showMsg) addToast('Verification id fetched successfully', 'success');
        return data;
      })
      .catch(() => {
        if (showMsg) addToast(toastMessages.DEFAULT.ERROR, 'error');
      })
      .finally(() =>
        runInAction(() => {
          this.loadingKycToken = false;
          this.loadedKycToken = true;
        })
      );
  };

  fetchOrganizationKycRepresentatives = (): Promise<{ success: boolean; isOrganizationBank: boolean } | void> => {
    // unused ATM
    const { addToast, toastMessages } = this.rootStore.toastsStore;

    return requests
      .fetchOrganizationKycRepresentatives()
      .then(({ data }) => {
        // console.log(data);
      })
      .catch(() => {
        addToast(toastMessages.DEFAULT.ERROR, 'error');
      });
  };

  resetKycStatus = (applicantId: string): Promise<void> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.resetingKycStatus = true));

    return requests
      .resetKycToken(applicantId)
      .then(() => {
        this.fetchOrganizations().then(() => {
          runInAction(() => (this.resetingKycStatus = false));
        });
      })
      .catch(() => {
        addToast(toastMessages.DEFAULT.ERROR, 'error');
        runInAction(() => (this.resetingKycStatus = false));
      });
  };
}

export default OrganizationStore;
