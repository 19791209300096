import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { FormValidation } from 'domain/types';
import { UserAccountInfo } from 'domain/types/Domain.types';
import { FieldDetail } from 'domain/types/FieldDetail';
import ContactAvatar from 'network/networkContact/components/contactAvatar';
import { Button, Input } from 'theme/atoms';
import SlidingPanel from 'theme/atoms/slidingPanel';
import { useFormValidation } from 'utils/hooks';
import styles from './MyAccountEditPanel.module.scss';

const REQUIRED_FORM_FIELDS = ['firstname', 'lastname', 'country', 'city'];

const FORM_VALIDATIONS: FormValidation = REQUIRED_FORM_FIELDS.reduce((prevFields, currentField) => {
  return { ...prevFields, [`${currentField}Required`]: '' };
}, {});

interface MyAccountEditPanelProps {
  userDetailsMap: FieldDetail[];
  isOpen: boolean;
  onClose: () => void;
  onSave: (userInfo: Partial<UserAccountInfo>) => Promise<void>;
}

const MyAccountEditPanel = ({
  userDetailsMap,
  isOpen,
  onClose,
  onSave,
}: MyAccountEditPanelProps): React.JSX.Element => {
  const detailsFromParent = userDetailsMap.reduce(
    (acc, detail) => ({
      ...acc,
      [detail.name]: detail.value,
    }),
    {} as UserAccountInfo
  );
  const [accountForm, setAccountForm] = useState<UserAccountInfo>(detailsFromParent);

  useEffect(() => {
    setAccountForm(detailsFromParent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const { cleanValidationErrors, formValidationMessages, handleValidation, validation } =
    useFormValidation(FORM_VALIDATIONS);
  const isFormValid = (): boolean => {
    const isValid = REQUIRED_FORM_FIELDS.every((field) => {
      const hasValue = !!accountForm[field];
      handleValidation(`${field}Required`, formValidationMessages.REQUIRED, !hasValue);
      return hasValue;
    });

    return isValid;
  };

  const handleSave = (event: FormEvent): void => {
    event.preventDefault();
    if (isFormValid()) onSave(accountForm).then(() => onClose());
  };
  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>): void => {
    cleanValidationErrors([`${target.name}Required`]);
    setAccountForm({ ...accountForm, [target.name]: target.value || '' });
  };

  return (
    <SlidingPanel title="Edit my account" onClose={onClose} isOpen={isOpen}>
      <div className={styles.root}>
        <div className={styles.wrapper}>
          <div>My account picture</div>
          <div className={styles.picture}>
            <ContactAvatar id={accountForm.id || ''} size="big" />
          </div>
        </div>

        <form className={styles.form}>
          {userDetailsMap.map(({ name, label }) => {
            return (
              <Input
                key={name}
                value={accountForm ? accountForm[name] : ''}
                name={name}
                label={label}
                onChange={handleChange}
                error={validation[`${name}Required`]}
              />
            );
          })}
          <Button text="Save" onClick={handleSave} maxWidth className={styles.button} />
        </form>
      </div>
    </SlidingPanel>
  );
};

export default MyAccountEditPanel;
