import { ContactConnection } from 'network/types';
import { getAddress } from 'network/utils';
import { Button } from 'theme/atoms';
import NoDataToDisplay from 'theme/atoms/noDataToDisplay/NoDataToDisplay';
import UserCard from 'theme/atoms/userCard';
import styles from './ContactPickerNetworkList.module.scss';

interface ContactPickerNetworkListProps {
  contacts: ContactConnection[];
  btnText: string;
  onPickContact: (contact: ContactConnection) => void;
}

const ContactPickerNetworkList = ({ contacts, btnText, onPickContact }: ContactPickerNetworkListProps): JSX.Element => {
  return (
    <>
      <h4 className={styles.header}>My network:</h4>
      {contacts.length === 0 && <NoDataToDisplay copy="No network to display." height={100} />}
      {contacts.map((contact) => {
        return (
          <div key={contact.id} className={styles.row}>
            <div>
              <UserCard id={contact.id}>
                <UserCard.Contact
                  numberOfLinesInName={2}
                  name={contact.contactOrganizationName}
                  title={contact.contactOrganizationTitle}
                  address={getAddress(contact.contactOrganizationAddress)}
                />
              </UserCard>
            </div>
            <Button text={btnText} onClick={() => onPickContact(contact)} />
          </div>
        );
      })}
    </>
  );
};

export default ContactPickerNetworkList;
