import clsx from 'clsx';
import Spinner from 'theme/atoms/Spinner/Spinner';
import { ButtonProps, Classes } from '../Button';
import styles from '../Button.module.scss';

interface ExternalLinkElementProps extends ButtonProps {
  classes: Classes;
  regularButton: boolean;
}

const ExternalLinkElement = ({
  classes,
  type,
  externalHref,
  loading,
  regularButton,
  buttonType,
  text,
  light = false,
  iconStart,
  iconEnd,
}: ExternalLinkElementProps): JSX.Element => (
  <a {...classes} type={type} href={externalHref} target="_blank" rel="noreferrer noopener">
    {iconStart}
    <span className={clsx(styles.text, { [styles.light]: light })}>{text}</span>
    {loading && regularButton && <Spinner small white={buttonType === 'primary'} className={styles.loader} />}
    {iconEnd}
  </a>
);

export default ExternalLinkElement;
