import { makeAutoObservable, runInAction } from 'mobx';
import { RootStore } from 'RootStore';
import * as artworksRequests from 'artworks/requests';
import { Artwork, ArtworkStatusEnum } from 'artworks/types';
import * as consignmentsRequests from 'consignments/requests';
import { Consignment, ConsignmentStatus } from 'consignments/types';
import * as transactionsRequests from 'transactions/requests';
import { Transaction } from 'transactions/requests.types';
import {
  CONSIGNMENT_STATUSES_VISIBLE_IN_ARTWORK_DETAILS,
  TRANSACTION_STATUSES_VISIBLE_IN_ARTWORK_DETAILS,
} from './constants';

class ArtworkStore {
  rootStore: RootStore;

  loading = false;
  artwork: Artwork | null = null;
  artworkConsignments: Consignment[] = [];
  artworkTransaction: Transaction | undefined = undefined;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  get status(): ArtworkStatusEnum {
    return (
      this.artwork?.exploringOrganizationArtworkStatus &&
      ArtworkStatusEnum[this.artwork.exploringOrganizationArtworkStatus]
    );
  }

  get consigneesIdsWithActiveConsignment(): string[] {
    return this.artworkConsignments
      .filter(
        (consignment) =>
          consignment.status === ConsignmentStatus.Accepted ||
          consignment.status === ConsignmentStatus.PendingConsigneeAgreement ||
          consignment.status === ConsignmentStatus.PendingOwnersAgreement
      )
      .map((consignment) => consignment.consigneeContact?.contactConnectionId || '');
  }

  get artworkOwnersContactIds(): string[] {
    return this.artwork?.owners.map((owner) => owner.contact?.contactConnectionId || '') || [];
  }

  fetchArtworkData = async (artworkId: string): Promise<void | void[]> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.loading = true));
    const artwork = await this.fetchArtwork(artworkId);
    const status = artwork && ArtworkStatusEnum[artwork.exploringOrganizationArtworkStatus];

    const { shouldFetchConsignments, shouldFetchTransaction } = this.shouldFetchTransactionAndConsignments(status);

    if (shouldFetchConsignments || shouldFetchTransaction) {
      return Promise.all([
        ...(shouldFetchConsignments ? [this.fetchConsignmentsByArtworkId(artworkId)] : []),
        ...(shouldFetchTransaction ? [this.fetchTransactionByArtworkId(artworkId)] : []),
      ])
        .catch(() => addToast(toastMessages.ARTWORKS.FETCH_SINGLE_ERROR, 'error'))
        .finally(() => runInAction(() => (this.loading = false)));
    } else {
      runInAction(() => (this.loading = false));
    }
  };

  fetchArtwork = (artworkId: string): Promise<void | Artwork> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    return artworksRequests
      .fetchArtwork(artworkId)
      .then(({ data }) => {
        runInAction(() => {
          this.artwork = data.results[0];
        });
        return data.results[0] as Artwork;
      })
      .catch(() => addToast(toastMessages.ARTWORKS.FETCH_SINGLE_ERROR, 'error'));
  };

  fetchTransactionArtwork = (transactionId: string): Promise<void> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.loading = true));

    return transactionsRequests
      .fetchTransactionArtwork(transactionId)
      .then(({ data }) => {
        runInAction(() => {
          this.artwork = data;
        });
      })
      .catch(() => addToast(toastMessages.ARTWORKS.FETCH_SINGLE_ERROR, 'error'))
      .finally(() => runInAction(() => (this.loading = false)));
  };

  fetchConsignmentsByArtworkId = (artworkId: string): Promise<void> => {
    return consignmentsRequests
      .fetchConsignmentsGroupedByArtwork({
        artworkId,
        status: CONSIGNMENT_STATUSES_VISIBLE_IN_ARTWORK_DETAILS,
      })
      .then(({ data }) => {
        runInAction(() => {
          this.artworkConsignments = data.results[0]?.consignments || [];
        });
      });
  };

  fetchTransactionByArtworkId = (artworkId: string): Promise<void> => {
    return transactionsRequests
      .fetchTransactions({
        artworkId,
        status: TRANSACTION_STATUSES_VISIBLE_IN_ARTWORK_DETAILS,
      })
      .then(({ data }) => {
        runInAction(() => {
          this.artworkTransaction = data.results[0]?.transaction;
        });
      });
  };

  resetAll = (): void => {
    this.artwork = null;
    this.artworkConsignments = [];
    this.artworkTransaction = undefined;
  };

  private shouldFetchTransactionAndConsignments = (
    status: ArtworkStatusEnum
  ): { shouldFetchTransaction: boolean; shouldFetchConsignments: boolean } => {
    const shouldFetchTransaction = [
      ArtworkStatusEnum.OnConsignmentReserved,
      ArtworkStatusEnum.OwnedReserved,
      ArtworkStatusEnum.OwnedTransactionAccepted,
    ].includes(status);
    const shouldFetchConsignments =
      shouldFetchTransaction ||
      [
        ArtworkStatusEnum.OnConsignment,
        ArtworkStatusEnum.OwnedConsignedAwaitingConsigning,
        ArtworkStatusEnum.OwnedConsigned,
        ArtworkStatusEnum.ThirdPartyAwaitingConsigning,
      ].includes(status);
    return { shouldFetchTransaction, shouldFetchConsignments };
  };
}

export default ArtworkStore;
