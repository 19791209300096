import { TimestampsAndTraces } from 'api/types';
import { Currency } from './Domain.types';

export type TransactionPrice = Big;

export type TransactionId = string;

export interface BasicTransaction extends TimestampsAndTraces {
  id: TransactionId;
  currency: Currency;
}

export enum TransactionStatus {
  Draft = 'Draft', // on FE only
  PendingOwnersAgreement = 'PendingOwnersAgreement',
  RejectedBySellerRepresentative = 'RejectedBySellerRepresentative',
  RejectedByOwner = 'RejectedByOwner',
  SentToBuyerRepresentative = 'SentToBuyerRepresentative',
  RejectedByBuyerRepresentative = 'RejectedByBuyerRepresentative',
  PendingBuyersAgreement = 'PendingBuyersAgreement',
  RejectedByBuyer = 'RejectedByBuyer',
  Accepted = 'Accepted',
  AcceptedEscrowAccountGranted = 'AcceptedEscrowAccountGranted',
  InvoiceVerification = 'InvoiceVerification',
  WaitingForPayment = 'WaitingForPayment',
  ReadyForExecution = 'ReadyForExecution',
  Executing = 'Executing',
  SignificantPaymentsMade = 'SignificantPaymentsMade',
  Executed = 'Executed',
  Closed = 'Closed',
}
