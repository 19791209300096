import { AxiosResponse } from 'axios';
import { TimestampsAndTraces } from 'api/types';
import { Artwork } from 'artworks/types';
import { Address, Contact } from 'domain/types/Contact.types';
import { Currency } from 'domain/types/Domain.types';
import { TransactionPrice } from 'domain/types/Transactions.types';
import { Organization, OrganizationId, OrganizationKind } from 'organization/types';

export type ConsignmentSellArtwork = boolean;
export type ConsignmentArtistResellRights = boolean;
export type ConsignmentId = string;

export enum ConsignmentShowArtwork {
  Exclusive = 'exclusive',
  NonExclusive = 'non-exclusive',
}

export interface ConsignmentBase<MaxCommissionType = TransactionPrice, AskingPriceType = TransactionPrice> {
  showArtwork: keyof typeof ConsignmentShowArtwork;
  sellArtwork: ConsignmentSellArtwork;
  expireAt?: Date;
  acceptedAt?: Date;
  salesRestrictions: string;
  askingPrice: AskingPriceType;
  currency: Currency;
  maxCommission: MaxCommissionType;
  consignmentLastModifiedAt: Date;
}

export interface NewConsignmentRequest extends ConsignmentBase {
  artworkId: string;
  contactId: string;
}

export interface ConsignmentFormType extends ConsignmentBase<string, TransactionPrice | null> {
  artworkId: string;
  contact: Contact | null;
}

export interface ConsignmentArtworkAuthor extends TimestampsAndTraces {
  id: string;
  type: OrganizationKind;
  name: string;
  title: string;
  address: Address;
}

export type ArtworkOwnershipDto = {
  organizationId: OrganizationId;
  organization: Organization;
  participationPercent: number;
  startDate: Date;
};

export interface ConsignmentArtwork extends Artwork {
  owners: ArtworkOwnershipDto[];
}

export enum ConsignmentStatus {
  Accepted = 'Accepted',
  Cancelled = 'Cancelled',
  Expired = 'Expired',
  PendingOwnersAgreement = 'PendingOwnersAgreement',
  RejectedByOwner = 'RejectedByOwner',
  PendingConsigneeAgreement = 'PendingConsigneeAgreement',
  RejectedByConsignee = 'RejectedByConsignee',
}

export interface Consignment extends TimestampsAndTraces, ConsignmentBase {
  id: ConsignmentId;
  consigneeContact: Contact;
  consignorContact: Contact;
  isConsignee: boolean;
  status: ConsignmentStatus;
  artworkOwnersAwaitingApprovals?: { awaiting: number; total: number };
  artworkOwnerAnswer?: {
    agreement: boolean;
    createdAt: Date;
  };
}

export type ConsignmentItem = {
  id: string;
  artwork: ConsignmentArtwork;
  consignments: Consignment[];
};

export type ConsignmentReturn = Promise<AxiosResponse<Consignment>>;

interface ConsigneeCounts {
  consignee: Contact;
  count: number;
}

interface ConsignorCounts {
  consignor: Contact;
  count: number;
}

interface SellArtworkCounts {
  sellArtwork: boolean;
  count: number;
}

interface ShowArtworkCounts {
  showArtwork: keyof ConsignmentShowArtwork;
  count: number;
}

export interface ConsignmentCounts {
  consigneeCounts: ConsigneeCounts[];
  consignorCounts: ConsignorCounts[];
  sellArtworkCounts: SellArtworkCounts[];
  showArtworkCounts: ShowArtworkCounts[];
}

export type ConsignmentCountsReturn = Promise<AxiosResponse<ConsignmentCounts>>;
