import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import { ConsignmentActions, canUserPerformThisAction } from 'consignments/domain';
import Modal from 'theme/atoms/modal';
import Approval from 'transactions/transaction/components/approval';
import styles from './Actions.module.scss';

const Actions = (): JSX.Element => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { consignmentStore, consignmentFormStore } = useStores();
  const {
    consignmentItem,
    consignment,
    acceptConsignment,
    rejectConsignment,
    loadingAccept,
    loadingReject,
    setClosingPanelShouldBeBlocked,
  } = consignmentStore;
  const { startEditingConsignment } = consignmentFormStore;

  useEffect(() => {
    setClosingPanelShouldBeBlocked(isModalOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  if (!consignmentItem || !consignment) return <></>;

  const role = consignment.isConsignee ? 'consignee' : 'owner';

  const acceptOrRejectConsignment = (shouldAccept: boolean, who: 'owner' | 'consignee'): void => {
    if (shouldAccept) {
      acceptConsignment(who).then(() =>
        navigate(routes.Artwork.path(consignmentItem.artwork.id), { state: { isStatusOpen: true } })
      );
    } else {
      rejectConsignment(who).then(() => navigate(routes.Consignment.path(consignment.id)));
    }
  };

  const actions = [
    {
      label: ConsignmentActions.Approve,
      onClick: () => acceptOrRejectConsignment(true, role),
      loading: loadingAccept,
    },
    { label: ConsignmentActions.Reject, onClick: () => setIsModalOpen(true), loading: loadingReject },
    {
      label: ConsignmentActions.MakeChanges,
      onClick: () => startEditingConsignment(consignment, consignmentItem.artwork.id),
    },
    // TODO: uncomment when ready
    // { label: 'Request changes', onClick: () => null, loading: false },
  ];

  return (
    <>
      <div className={styles.root}>
        <Approval
          actions={actions.filter((action) =>
            canUserPerformThisAction(consignment, action.label, consignmentItem.artwork)
          )}
        />
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Reject?"
        subtitle="Are you sure you want to reject this consignment?"
        btnAbort={{ label: 'Cancel', function: () => setIsModalOpen(false) }}
        btnConfirm={{
          label: 'Confirm',
          function: () => {
            acceptOrRejectConsignment(false, role);
            setIsModalOpen(false);
          },
        }}
      />
    </>
  );
};

export default observer(Actions);
