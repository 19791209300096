import Modal from 'theme/atoms/modal';

interface DeleteContactModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDeletionConfirm: () => void;
  contactName: string;
}

const DeleteContactModal = ({
  isOpen,
  onClose,
  onDeletionConfirm,
  contactName,
}: DeleteContactModalProps): JSX.Element => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`Delete ${contactName}'s contact?`}
      subtitle={`Are you sure you want to delete ${contactName}'s contact from your network?`}
      btnAbort={{ label: 'Cancel', function: onClose }}
      btnConfirm={{ label: 'Delete', function: () => onDeletionConfirm() }}
    />
  );
};

export default DeleteContactModal;
