import { makeAutoObservable, runInAction } from 'mobx';
import { RootStore } from 'RootStore';
import { InvoiceState } from 'domain/types/Invoices.types';
import * as transactionRequests from 'transactions/requests';
import { Invoice } from 'transactions/requests.types';

class InvoiceStore {
  rootStore: RootStore;

  loadingInvoiceDetails = false;
  selectedInvoice: Invoice | null = null;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  fetchInvoice = (invoiceId: string): Promise<void | Invoice> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.loadingInvoiceDetails = true));
    return transactionRequests
      .fetchInvoice(invoiceId)
      .then(({ data }) => {
        runInAction(() => {
          this.selectedInvoice = data.results[0];
        });
      })
      .catch(() => addToast(toastMessages.TRANSACTIONS.INVOICE_DETAILS_FETCH_ERROR, 'error'))
      .finally(() => runInAction(() => (this.loadingInvoiceDetails = false)));
  };

  setSelectedInvoiceState = (state: InvoiceState): void => {
    if (!this.selectedInvoice) return;
    this.selectedInvoice = {
      ...this.selectedInvoice,
      state,
    };
  };

  resetSelectedInvoice = (): void => {
    runInAction(() => (this.selectedInvoice = null));
  };
}

export default InvoiceStore;
