import dayjs from 'dayjs';
import { FilterItemType } from './SortAndFilterBasicStore/types';
import { Contact } from './types/Contact.types';

export const highlightText = (text: string, searchString: string): React.ReactNode => {
  if (!searchString) {
    return text;
  }

  const parts = text.split(new RegExp(`(${searchString})`, 'gi'));

  return parts.map((part, index) => {
    const shouldBeHighlighted = part.toLowerCase() === searchString.toLowerCase();
    return (
      <span key={index} style={shouldBeHighlighted ? { backgroundColor: '#e9e33c' } : {}}>
        {part}
      </span>
    );
  });
};

export const addSpaceBeforeUppercase = (text: string): string => {
  if (!text) return '';
  return text
    .split('')
    .map((character) => {
      return character.match(/[A-Z]/g) ? ` ${character}` : character;
    })
    .join('');
};

export const getTimeItemValue = (filteringItems: FilterItemType[], field): string | undefined => {
  const value = filteringItems.find((el) => el.key === field)?.value;
  return (value && dayjs(value as string).toISOString()) || undefined;
};

export const addMeToOrganizationName = (contact: Partial<Contact>, addMe?: boolean): string => {
  return `${contact.organizationName}${contact.self || addMe ? ' (me)' : ''}`;
};
