import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { ReactComponent as LogoutIcon } from 'theme/assets/svg/logout.svg';
import ActionButtons, { ActionButton } from 'theme/atoms/ActionButtons';
import Breadcrumbs from 'theme/atoms/Breadcrumbs';
import Topbar from 'theme/atoms/Topbar';
import ChooseOrganization from './1chooseOrganization';
import OrganizationDetails from './2organizationDetails';
import BankAccount from './3bankAccount';
import VerifyAccount from './4verifyAccount';
import VerifyIdentity from './5verifyIdentity';
import VerificationCompleted from './6verificationCompleted';
import styles from './CreateOrganization.module.scss';

const CreateOrganizationWrapper = (): React.JSX.Element => {
  const {
    organizationStore: { creatingOrganizationKind, selectedOrganization, organizationBeingSetupId },
    bankStore: { bankAccount },
    billingStore: { isPaymentMethodActive },
    authStore: { logout },
  } = useStores();

  const [kycVerificationCompleted, setKycVerificationCompleted] = useState(false);

  const startingOrgSetup = !creatingOrganizationKind && !selectedOrganization;
  const orgTypeSelectedAndFillingOrgDetails = !!creatingOrganizationKind && !selectedOrganization;
  const orgCreatedButOtherDetailsStillBeingSetup = selectedOrganization?.id === organizationBeingSetupId;
  const orgCreatedButNoBankAccount = orgCreatedButOtherDetailsStillBeingSetup && !bankAccount;
  const orgCreatedWithBankAccountButNoPaymentMethod =
    orgCreatedButOtherDetailsStillBeingSetup && !!bankAccount && !isPaymentMethodActive;
  const orgCreatedWithBankAccountAndPaymentMethodThusStartingKYC =
    orgCreatedButOtherDetailsStillBeingSetup && !!bankAccount && !!isPaymentMethodActive && !kycVerificationCompleted;
  const orgCreatedWithBankAccountAndPaymentMethodAndCompletedKYC =
    orgCreatedButOtherDetailsStillBeingSetup && !!bankAccount && !!isPaymentMethodActive && kycVerificationCompleted;

  const getView = (): JSX.Element => {
    switch (true) {
      case startingOrgSetup:
        return <ChooseOrganization />;
      case orgTypeSelectedAndFillingOrgDetails:
        return <OrganizationDetails />;
      case orgCreatedButNoBankAccount:
        return <BankAccount />;
      case orgCreatedWithBankAccountButNoPaymentMethod:
        return <VerifyAccount />;
      case orgCreatedWithBankAccountAndPaymentMethodThusStartingKYC:
        return <VerifyIdentity onKycVerificationCompleted={() => setKycVerificationCompleted(true)} />;
      case orgCreatedWithBankAccountAndPaymentMethodAndCompletedKYC:
        return <VerificationCompleted />;
      default:
        return <ChooseOrganization />;
    }
  };

  const getLastBreadcrumbLabel = (): string => {
    switch (true) {
      case startingOrgSetup:
        return 'Choose account';
      case orgTypeSelectedAndFillingOrgDetails:
        return 'Account details';
      case orgCreatedButNoBankAccount:
        return 'Account number';
      case orgCreatedWithBankAccountButNoPaymentMethod:
        return 'Checkout';
      case orgCreatedWithBankAccountAndPaymentMethodThusStartingKYC:
      case orgCreatedWithBankAccountAndPaymentMethodAndCompletedKYC:
        return 'KYC';
      default:
        return 'Create organization';
    }
  };

  const actionButtons: ActionButton[] = [
    {
      text: 'Log out',
      icon: <LogoutIcon />,
      onClick: logout,
    },
  ];

  return (
    <div className={styles.root}>
      <Topbar>
        <Breadcrumbs breadcrumbs={[{ label: 'Sign up' }, { label: getLastBreadcrumbLabel() }]} />
        <ActionButtons buttons={actionButtons} />
      </Topbar>
      {getView()}
    </div>
  );
};

export default observer(CreateOrganizationWrapper);
