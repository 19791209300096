import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { ContactConnection } from 'network/types';
import { ReactComponent as Remove } from 'theme/assets/svg/close.svg';
import { ReactComponent as ConnectIcon } from 'theme/assets/svg/connect.svg';
import { Button } from 'theme/atoms';
// import { ReactComponent as Share } from 'theme/assets/svg/share.svg';
import Avatar from 'theme/atoms/avatar';
import IconButton from 'theme/atoms/iconButton';
import TableDetailItem from 'theme/atoms/tableDetailItem';
import tableDetailItemStyles from 'theme/atoms/tableDetailItem/TableDetailItem.module.scss';
import { CategoryId } from '../../types';
import { ContactToProceed } from '../NetworkTable';
import styles from '../NetworkTable.module.scss';

interface RightSideTdsProps {
  categoryId: CategoryId;
  item: ContactConnection;
  setContactToProceed: (contact: ContactToProceed | null) => void;
  setContactToDelete: (contact: ContactConnection | null) => void;
}

const RightSideTds = observer(
  ({ categoryId, item, setContactToProceed, setContactToDelete }: RightSideTdsProps): JSX.Element => {
    const { networkManagingStore } = useStores();
    const { loadingAccept, loadingIgnore } = networkManagingStore;

    const networkTds = (el: ContactConnection): JSX.Element => (
      <>
        <td className={styles.primaryContactTd}>
          {el.primaryContactLastName || el.primaryContactLastName ? (
            <>
              <Avatar id={el.primaryContactLastName} />
              {/* TODO image if possible */}
              <TableDetailItem
                className={tableDetailItemStyles.userWithAvatarSemibold}
                text={`${el.primaryContactFirstName || ''} ${el.primaryContactLastName || ''}`}
              />
            </>
          ) : null}
        </td>
        <td className={styles.networkActionsTd}>
          <div className={styles.actionBtns}>
            <div className={styles.hiddenBtns}>
              {/* <IconButton icon={Share} onClick={() => console.log('click share')} /> // TODO when BE is ready */}
              <IconButton icon={Remove} onClick={() => setContactToDelete(el)} />
            </div>
          </div>
        </td>
      </>
    );

    const pendingTds = (el: ContactConnection): JSX.Element => (
      <td className={styles.pendingActionsTd}>
        <div className={styles.actionBtns}>
          <Button
            text="Ignore"
            loading={loadingIgnore}
            disabled={loadingAccept}
            buttonType="secondary"
            onClick={() => setContactToProceed({ contact: el, action: 'ignore' })}
          />
          <Button
            text="Accept"
            iconStart={<ConnectIcon />}
            loading={loadingAccept}
            disabled={loadingIgnore}
            onClick={() => setContactToProceed({ contact: el, action: 'accept' })}
          />
        </div>
      </td>
    );

    const requestedTds = (el: ContactConnection): JSX.Element => (
      <td className={styles.requestedActionsTd}>
        <div className={styles.actionBtns}>
          <Button
            text="Delete"
            loading={loadingAccept}
            disabled={loadingIgnore}
            onClick={() => setContactToDelete(el)}
          />
        </div>
      </td>
    );

    return (
      <>
        {(categoryId === 'network' && networkTds(item)) ||
          (categoryId === 'pending' && pendingTds(item)) ||
          (categoryId === 'requested' && requestedTds(item))}
      </>
    );
  }
);
export default RightSideTds;
