import { useState, FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import styles from 'auth/Auth.module.scss';
import { EMAIL_REGEX } from 'domain/regex';
import { FormValidation } from 'domain/types';
import { Button, Input, Subtitle, TitleBlack } from 'theme/atoms';
import Breadcrumbs, { Breadcrumb } from 'theme/atoms/Breadcrumbs';
import Topbar from 'theme/atoms/Topbar';
import { useFormValidation, useTitle } from 'utils/hooks';

const FORM_VALIDATIONS: FormValidation = {
  emailRequired: '',
  emailInvalid: '',
};

function ResetPasswordGetEmail(): JSX.Element {
  useTitle('Reset password');
  const navigate = useNavigate();
  const { authStore } = useStores();
  const { submitting, sendLinkToResetPassword, accessToken } = authStore;
  const [email, setEmail] = useState('');

  const BREADCRUMBS: Breadcrumb[] = accessToken
    ? [
        {
          label: 'Settings',
          route: 'SettingsSecurity',
        },
        {
          label: 'Reset password',
        },
      ]
    : [
        {
          label: 'Reset password',
        },
      ];

  const { cleanValidationErrors, formValidationMessages, handleValidation, validation } =
    useFormValidation(FORM_VALIDATIONS);

  const handleEmailChange = (value: string): void => {
    cleanValidationErrors(['emailRequired', 'emailInvalid']);
    setEmail(value);
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>): void | undefined => {
    event.preventDefault();
    const trimmedEmail = email.trim();
    handleValidation('emailRequired', formValidationMessages.EMAIL_REQUIRED, !trimmedEmail);
    handleValidation(
      'emailInvalid',
      formValidationMessages.EMAIL_INVALID,
      !!trimmedEmail && !EMAIL_REGEX.test(trimmedEmail)
    );
    if (!trimmedEmail || !EMAIL_REGEX.test(trimmedEmail)) return;

    sendLinkToResetPassword(trimmedEmail).then((isSuccessfull) => {
      isSuccessfull && navigate(routes.ResetPasswordEmailSent.path());
    });
  };

  return (
    <div className={clsx(styles.root, styles.oneColumnLayout)}>
      <Topbar>
        <Breadcrumbs breadcrumbs={BREADCRUMBS} />
      </Topbar>
      <div className={styles.innerWrapper}>
        <TitleBlack text="Forgot password?" />
        <Subtitle text="Enter your email and we'll send you a link to reset your password" />

        <form onSubmit={onSubmit} className={'form'}>
          <Input
            label="Email"
            name="email"
            onChange={(e) => handleEmailChange(e.target.value)}
            placeholder="Enter your email address"
            value={email}
            error={validation.emailRequired || validation.emailInvalid}
            autoFocus
          />

          <Button text="Request a reset link" loading={submitting} type="submit" maxWidth />
        </form>
      </div>
    </div>
  );
}

export default observer(ResetPasswordGetEmail);
