import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import Big from 'big.js';
import { addMeToOrganizationName } from 'domain/utils';
import Billing from 'theme/atoms/billing';
import UserCard from 'theme/atoms/userCard';
import { TransactionCommissionReceiver, TransactionWrapper } from 'transactions/requests.types';
import { formatPrice } from 'utils/helpers';
import { useTransactionRoles } from 'utils/hooks';

interface CommissionReceiversProps {
  isSummaryView: boolean;
}

const CommissionReceivers = ({ isSummaryView }: CommissionReceiversProps): JSX.Element | null => {
  const { transactionFormStore } = useStores();
  const transactionWrapper = transactionFormStore.transactionWrapper as TransactionWrapper;
  const { totalBuyerCommission } = transactionFormStore;
  const { transaction } = transactionWrapper;
  const { isSellerSide, isBuyerRepresentative } = useTransactionRoles({ transactionWrapper });

  const getSellerCommissionReceivers = (): TransactionCommissionReceiver[] | undefined => {
    const { sellerInfo } = transaction;
    if (isSellerSide && sellerInfo && sellerInfo.commissionReceivers?.length) {
      return sellerInfo.commissionReceivers;
    }
  };

  const getBuyerCommissionReceivers = (): TransactionCommissionReceiver[] | undefined => {
    const { buyerInfo } = transaction;
    if (!isSellerSide && isBuyerRepresentative && buyerInfo?.commissionReceivers?.length) {
      return buyerInfo.commissionReceivers;
    }
  };

  const getBuyerRepresentativeWithCommissionAsArray = (): TransactionCommissionReceiver[] | undefined => {
    const { buyerInfo } = transaction;
    if (!isSellerSide && totalBuyerCommission && buyerInfo?.representativeContact) {
      return [
        {
          contact: buyerInfo.representativeContact,
          commission: totalBuyerCommission,
        },
      ];
    }
  };

  const commissionReceivers =
    getSellerCommissionReceivers() ||
    getBuyerCommissionReceivers() ||
    getBuyerRepresentativeWithCommissionAsArray() ||
    [];

  if (!isSummaryView || !commissionReceivers) return null;

  return (
    <>
      {commissionReceivers.map((receiver) => (
        <Billing.Row isIndented key={receiver.contact.contactConnectionId || receiver.contact.organizationName}>
          <UserCard isSmall id={receiver.contact.contactConnectionId || ''}>
            <UserCard.Name name={addMeToOrganizationName(receiver.contact)} />
          </UserCard>
          {formatPrice(Big(receiver.commission || 0), transaction.currency)}
        </Billing.Row>
      ))}
    </>
  );
};

export default observer(CommissionReceivers);
