import { ToastMessages } from './types';

export const toastMessages: ToastMessages = {
  DEFAULT: {
    ERROR: 'Something went wrong. Please try again later.',
  },
  AUTH: {
    LOGIN_ERROR: 'Invalid username or password.',
  },
  USERS: {
    CREATE_ERROR: 'Failed to create user.',
    UPDATE_SUCCESS: 'User updated successfully.',
  },
  ARTWORKS: {
    FETCH_SINGLE_ERROR: 'Failed to fetch artwork.',
    UPDATE_ERROR: 'Failed to update artwork.',
    UPDATE_SUCCESS: 'Artwork updated successfully.',
  },
  RESET_PASSWORD: {
    UPDATE_SUCCESS: 'Password set successfully.',
  },
  BANK_TRANSACTIONS: {
    ACCOUNT_DETAILS_FETCH_ERROR: 'Failed to fetch escrow account details.',
    ACCOUNT_DOESNT_EXIST: "Escrow account for the related transaction hasn't been added yet.",
    ACCOUNT_CREATE_SUCCESS: 'Escrow account details successfully added.',
    ACCOUNT_CREATE_ERROR: 'Failed to add escrow account details.',
    ACCOUNT_EDIT_SUCCESS: 'Escrow account details successfully edited.',
    ACCOUNT_EDIT_ERROR: 'Failed to edit escrow account details.',
    SEND_TO_EXECUTION_SUCCESS: 'Transaction successfully sent to execution.',
    SEND_TO_EXECUTION_ERROR: 'Failed to send this transaction to execution.',
    DETAILS_FETCH_ERROR: 'Failed to fetch this transaction details.',
  },
  BANK_INVOICES: {
    ACCEPTANCE_ERROR_INVALID_TRANSACTION_STATUS:
      'Failed to send this invoice to acceptance - invalid related transaction status.',
    PAYMENT_ERROR_INVALID_TRANSACTION_STATUS:
      'Failed to send this invoice to payment - invalid related transaction status.',
    REJECT_ERROR_INVALID_TRANSACTION_STATUS: 'Failed to reject this invoice - invalid related transaction status.',
    SEND_TO_ACCEPTANCE_SUCCESS: 'Invoice successfully accepted.',
    SEND_TO_ACCEPTANCE_ERROR: 'Failed to send this invoice to acceptance.',
    SEND_TO_PAYMENT_SUCCESS: 'Invoice successfully sent to payment.',
    SEND_TO_PAYMENT_ERROR: 'Failed to send this invoice to payment.',
    SEND_TO_REJECT_SUCCESS: 'Invoice successfully rejected.',
    SEND_TO_REJECT_ERROR: 'Failed to reject this invoice.',
    DETAILS_FETCH_ERROR: 'Failed to fetch this invoice details.',
  },
  TRANSACTIONS: {
    INVOICE_DETAILS_FETCH_ERROR: 'Failed to fetch this invoice details.',
  },
  ORGANIZATIONS: {
    ACCOUNT_DOESNT_EXIST: "Bank account for the organization hasn't been added yet.",
    ACCOUNT_DETAILS_FETCH_ERROR: 'Failed to fetch bank account details.',
    ACCOUNT_CREATE_SUCCESS: 'Bank account details successfully added.',
    ACCOUNT_CREATE_ERROR: 'Failed to add bank account details.',
    ACCOUNT_EDIT_SUCCESS: 'Bank account details successfully edited.',
    ACCOUNT_EDIT_ERROR: 'Failed to edit bank account details.',
  },
};
