type PasswordRequirements = {
  minimumLength: number | boolean;
  maximumLength: number | boolean;
  includeDigit: boolean;
  includeLowerLetter: boolean;
  includeUpperLetter: boolean;
  includeSpecialChar: boolean;
};

const containsSpecialChars = (password: string): boolean => {
  const specialChars = `\`!@#$%^&*()_+-=[]{};':"\\|,.<>/?~`;
  return specialChars.split('').some((specialChar) => password.includes(specialChar));
};

const passwordRequirementsDescription = (password: string, val: boolean | number): PasswordRequirements => {
  return {
    minimumLength: password.length >= (val as number),
    maximumLength: password.length <= (val as number),
    includeDigit: (val as boolean) ? /[0-9]/.test(password) : true,
    includeLowerLetter: (val as boolean) ? /[a-z]/.test(password) : true,
    includeUpperLetter: (val as boolean) ? /[A-Z]/.test(password) : true,
    includeSpecialChar: (val as boolean) ? containsSpecialChars(password) : true,
  };
};

const passwordRequirements: PasswordRequirements = {
  minimumLength: 12,
  maximumLength: 64,
  includeDigit: true,
  includeLowerLetter: true,
  includeUpperLetter: true,
  includeSpecialChar: false,
};

export const isPasswordInvalid = (password: string): boolean => {
  if (!password) return false;

  const meetingRequirements = Object.entries(passwordRequirements).reduce((acc, [key, val]) => {
    return { ...acc, [key]: passwordRequirementsDescription(password, val)[key] };
  }, {});
  const anyRequirementNotMet = Object.values(meetingRequirements).some((val) => !val);
  return anyRequirementNotMet;
};
