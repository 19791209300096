import { ReactComponent as RemoveIcon } from 'theme/assets/svg/close.svg';
import { ReactComponent as SearchIcon } from '../assets/search.svg';

interface SearchbarIconButtonProps {
  isSearchQuery: boolean;
  onRemove: () => void;
}

export const SearchbarIconButton = ({ isSearchQuery, onRemove }: SearchbarIconButtonProps): JSX.Element => {
  return isSearchQuery ? (
    <button onClick={onRemove}>
      <RemoveIcon />
    </button>
  ) : (
    <div>
      <SearchIcon />
    </div>
  );
};
