import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { routes } from 'app/routes/paths.const';
import { ReactComponent as TransactionsIcon } from 'theme/assets/svg/transactions.svg';
import ActionButtons, { ActionButton } from 'theme/atoms/ActionButtons';
import Breadcrumbs, { Breadcrumb } from 'theme/atoms/Breadcrumbs';
import Topbar from 'theme/atoms/Topbar';
import ArtworkData from 'theme/cells/artworkData';
import { DEFAULT_TRANSACTION_CATEGORY } from 'transactions/transactions/types';

export const TransactionArtwork = (): JSX.Element => {
  const { transactionId } = useParams();
  const navigate = useNavigate();

  if (!transactionId) return <></>;

  const BREADCRUMBS: Breadcrumb[] = [
    { label: 'Transactions', route: 'Transactions', param: DEFAULT_TRANSACTION_CATEGORY },
    { label: `Offer ${transactionId.slice(0, 8)}...`, route: 'Transaction', param: transactionId },
    { label: 'Artwork' },
  ];

  const ACTION_BUTTONS: ActionButton[] = [
    {
      text: 'Transaction details',
      icon: <TransactionsIcon />,
      onClick: () => navigate(routes.Transaction.path(transactionId)),
    },
  ];

  return (
    <>
      <Topbar>
        <Breadcrumbs breadcrumbs={BREADCRUMBS} />
        <ActionButtons buttons={ACTION_BUTTONS} />
      </Topbar>
      <ArtworkData transactionId={transactionId} />
    </>
  );
};

export default observer(TransactionArtwork);
