import { LayoutType } from 'artworks/artworksComponents/viewOptions/types';

export const getLayoutSizeClass = (size: number, layout?: LayoutType): string => {
  switch (true) {
    case !layout && size === 0:
      return 'isSmall';
    case !layout && size === 1:
      return 'isMedium';
    case !layout && size === 2:
      return 'isBig';
    case layout === 'grid' && size === 0:
      return 'gridSmall';
    case layout === 'grid' && size === 1:
      return 'gridMedium';
    case layout === 'grid' && size === 2:
      return 'gridBig';
    case layout === 'list' && size === 0:
      return 'listSmall';
    case layout === 'list' && size === 1:
      return 'listMedium';
    case layout === 'list' && size === 2:
      return 'listBig';
    default:
      return 'listMedium';
  }
};
