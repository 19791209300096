import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { routes } from 'app/routes/paths.const';
import { ArtworkStatusEnum } from 'artworks/types';
import { Consignment, ConsignmentStatus } from 'consignments/types';
import { addMeToOrganizationName } from 'domain/utils';
import { Button } from 'theme/atoms';
import IconComponent, { icons } from 'theme/atoms/iconComponent';
import UserCard from 'theme/atoms/userCard';
import { Transaction } from 'transactions/requests.types';
import styles from './StatusOnConsignmentOrThirdPartyAwaitingView.module.scss';

interface StatusOnConsignmentOrThirdPartyAwaitingViewProps {
  setSelectedConsignment: (consignment: Consignment) => void;
  consignments?: Consignment[];
  isConsignmentSelected: boolean;
  transaction?: Transaction;
  status: ArtworkStatusEnum;
}

const StatusOnConsignmentOrThirdPartyAwaitingView = ({
  consignments,
  setSelectedConsignment,
  transaction,
  status,
}: StatusOnConsignmentOrThirdPartyAwaitingViewProps): JSX.Element => {
  const navigate = useNavigate();

  const displayActionsForPendingConsigneeAgreement = (consignment: Consignment): JSX.Element | null => {
    return consignment.status === ConsignmentStatus.PendingConsigneeAgreement ? (
      <Button
        text="Answer consignment"
        onClick={() => {
          navigate(routes.Consignment.path(consignment.id));
        }}
      />
    ) : null;
  };

  const displayActionsForAccepted = (consignment: Consignment): JSX.Element | null => {
    return consignment.status === ConsignmentStatus.Accepted ? (
      <div className={styles.buttonsWrapper}>
        {status !== ArtworkStatusEnum.OnConsignmentReserved && consignment.sellArtwork && !transaction && (
          <Button text="Create offer sale" onClick={() => navigate(routes.Transaction.path('new', consignment.id))} />
        )}
        <Button text="Consignment details" onClick={() => setSelectedConsignment(consignment)} buttonType="secondary" />
      </div>
    ) : null;
  };

  const displayConsignmentDetails = (consignment: Consignment): JSX.Element => {
    return (
      <div key={consignment.id} className={styles.itemWrapper}>
        <div className={styles.rowWrapper} onClick={() => setSelectedConsignment(consignment)}>
          <UserCard id={consignment.id}>
            <UserCard.Consignment
              name={addMeToOrganizationName(consignment.consigneeContact, true)}
              isExclusive={consignment.showArtwork === 'Exclusive'}
              hasMandateToSell={consignment.sellArtwork}
            />
          </UserCard>

          <IconComponent icon={icons.next} />
        </div>
        {displayActionsForPendingConsigneeAgreement(consignment)}
        {displayActionsForAccepted(consignment)}
      </div>
    );
  };

  const getConsignmentsToDisplay = (): JSX.Element[] | undefined => {
    return consignments?.map(displayConsignmentDetails);
  };

  return <div className={styles.root}>{getConsignmentsToDisplay()}</div>;
};

export default observer(StatusOnConsignmentOrThirdPartyAwaitingView);
