import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { Contact } from 'domain/types/Contact.types';
import ContactPicker from 'network/contactPicker/ContactPicker';
import ContactAvatar from 'network/networkContact/components/contactAvatar';
import ContactInfo from 'network/networkContact/components/contactInfo';
import { getAddress } from 'network/utils';
import { Button } from 'theme/atoms';
import SectionHeader from 'theme/atoms/Titles/SectionHeader';
import IconButton from 'theme/atoms/iconButton';
import IconComponent, { icons } from 'theme/atoms/iconComponent';
import { useDebounceIsPanelOpen } from 'theme/atoms/slidingPanel/useDebounceIsPanelOpen';
import { getContactsIdsToExcludeBasedOnRole } from 'transactions/domain';
import { TransactionWrapper } from 'transactions/requests.types';
import { ValidationError } from 'transactions/transaction/transactionForm/TransactionFormStore';
import InstructionWithError from '../components/instructionWithError';
import styles from './BuyerRepresentative.module.scss';

interface BuyerRepresentativeInfoProps {
  contact: Contact;
  onRemove: () => void;
}

const BuyerRepresentativeInfo = ({ contact, onRemove }: BuyerRepresentativeInfoProps): JSX.Element => {
  return (
    <>
      <div className={styles.wrapper} key={contact.contactConnectionId}>
        <div className={styles.innerWrapper}>
          <ContactAvatar id={contact.contactConnectionId || ''} size="small" />
          <ContactInfo
            size="small"
            name={contact.organizationName}
            title={contact.organizationTitle || ''}
            address={getAddress(contact.organizationAddress)}
          />
        </div>
        <IconButton onClick={onRemove} className={styles.closeBtn} icon={icons.close} />
      </div>
    </>
  );
};

const BuyerRepresentative = (): JSX.Element => {
  const { transactionFormStore } = useStores();
  const { setOrRemoveBuyerRepresentative, validationErrors } = transactionFormStore;
  const transactionWrapper = transactionFormStore.transactionWrapper as TransactionWrapper;

  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const debouncedIsPickerOpen = useDebounceIsPanelOpen(isPickerOpen);

  const buyerInfo = transactionWrapper.transaction.buyerInfo;

  const onAddRepresentative = (): void => {
    debouncedIsPickerOpen && setIsPickerOpen(true);
  };

  const onPickContact = (contact): void => {
    setOrRemoveBuyerRepresentative(contact);
    onClosePicker();
  };
  const onClosePicker = (): void => {
    setIsPickerOpen(false);
  };

  return (
    <>
      <div className={styles.wrapper}>
        <SectionHeader title="Buyer side" />

        {!buyerInfo?.representativeContact && (
          <Button
            text="Add"
            iconStart={<IconComponent icon={icons.add} />}
            buttonType="tertiary"
            onClick={onAddRepresentative}
          />
        )}
      </div>

      <InstructionWithError
        section={'buyerRepresentative'}
        showCopy={!buyerInfo?.representativeContact}
        showError={!!validationErrors.find((error) => error === ValidationError.MissingBuyerRepresentative)}
      />

      {buyerInfo?.representativeContact && (
        <BuyerRepresentativeInfo
          onRemove={() => setOrRemoveBuyerRepresentative()}
          contact={buyerInfo.representativeContact}
        />
      )}

      <ContactPicker
        title="Add a representative"
        isOpen={isPickerOpen}
        onPickContact={onPickContact}
        onClose={onClosePicker}
        contactsToExclude={getContactsIdsToExcludeBasedOnRole(transactionWrapper)}
      />
    </>
  );
};

export default observer(BuyerRepresentative);
