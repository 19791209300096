import { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import styles from 'auth/Auth.module.scss';
import AuthAppForm from 'auth/components/forms/AuthAppForm';
import SetupAuthApp from 'auth/flows/FirstTimeLogin/authApp/components/SetupAuthApp';
import { AuthMethodAction, UserIdentityType } from 'auth/types';
import { Button, TitleBlack } from 'theme/atoms';
import Breadcrumbs, { Breadcrumb } from 'theme/atoms/Breadcrumbs';
import Topbar from 'theme/atoms/Topbar';
import Loader from 'theme/atoms/loader';
import { useUserIdentityByType } from 'utils/hooks';
import settingsStyles from '../../SettingsSecurity.module.scss';

const BREADCRUMBS: Breadcrumb[] = [
  { label: 'Settings', route: 'SettingsSecurity' },
  { label: 'Security', route: 'SettingsSecurity' },
  { label: 'Authenticator App' },
];

const SettingsSecurityAuthApp = (): JSX.Element => {
  const navigate = useNavigate();

  const {
    toastsStore: { addToast },
    authStore,
  } = useStores();
  const {
    submitting,
    userNeedsVerification,
    setUserNeedsVerification,
    deleteUserIdentity,
    TOTP,
    generateAuthAppQRCode,
    addUserIdentity,
    userIdentities,
    getMFAUserIdentityTypesToVerify,
    saveTokensToStore,
    saveTokensToLocalStorage,
  } = authStore;

  const location = useLocation();
  const shouldDeleteAuthMethod = location.state?.action === AuthMethodAction.Delete;

  const userIdentity = useUserIdentityByType(UserIdentityType.TimeBasedOneTimePassword);

  useEffect(() => {
    if (userNeedsVerification) {
      navigate(routes.SettingsSecurity.path(), { state: { isAuthenticationMethodsOpen: true } });
      return;
    }

    setUserNeedsVerification(true);

    if (shouldDeleteAuthMethod) {
      if (!userIdentity) return;
      deleteUserIdentity(userIdentity.id).then(() => {
        navigate(routes.SettingsSecurity.path(), { state: { isAuthenticationMethodsOpen: true } });
      });
    } else {
      generateAuthAppQRCode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (authAppCode: string): Promise<void> => {
    if (!TOTP) return;

    const userIdentityToRemove = userIdentities?.find(
      (identity) => identity.type === getMFAUserIdentityTypesToVerify()[0]
    );

    if (userIdentityToRemove) {
      await deleteUserIdentity(userIdentityToRemove.id);
    }

    addUserIdentity({ type: UserIdentityType.TimeBasedOneTimePassword, value: TOTP.secret, verifyToken: authAppCode })
      .then((response) => {
        if (response?.success) {
          saveTokensToStore(response.data);
          saveTokensToLocalStorage(response.data);
          addToast('Authentication method successfully added', 'success');
          navigate(routes.SettingsSecurity.path(), { state: { isAuthenticationMethodsOpen: true } });
        }
      })
      .catch(() => {
        navigate(routes.SettingsSecurityVerification.path(), {
          state: { redirectUrl: routes.SettingsSecurityAuthApp.path() },
        });
      });
  };

  const submitButton = (
    <Button type="submit" text="Continue" loading={submitting} disabled={submitting} className={styles.submitButton} />
  );

  const backButton = (
    <Link to={routes.LoginAddAuthMethod.path()} className={clsx(styles.backLink)}>
      Back
    </Link>
  );

  if (shouldDeleteAuthMethod) return <Loader minHeight={300} />;

  return (
    <div className={settingsStyles.pageContainer}>
      <Topbar>
        <Breadcrumbs breadcrumbs={BREADCRUMBS} />
      </Topbar>
      <TitleBlack text="Set up an authenticator app" />

      <SetupAuthApp
        QRSecret={TOTP?.secret}
        QRValue={TOTP?.qr}
        form={<AuthAppForm onSubmit={onSubmit} submitButton={submitButton} />}
        backButton={backButton}
      />
    </div>
  );
};

export default observer(SettingsSecurityAuthApp);
