import { FormEvent, ReactNode, useState } from 'react';
import OTPInput from 'react-otp-input';
import clsx from 'clsx';
import { useStores } from 'RootStore';
import styles from 'auth/Auth.module.scss';
import { PHONE_CODE_LENGTH } from 'auth/constants';
import { formValidationMessages } from 'domain/formValidationMessages';
import { PHONE_CODE_REGEX } from 'domain/regex';
import { StoreActionReturn } from 'domain/types/StoreActionReturn';
import { Button } from 'theme/atoms';
import { useZodFormValidation } from 'utils/hooks/useZodFormValidation';
import { z } from 'zod';

const VerifyPhoneSchema = z.object({
  code: z
    .string()
    .min(1, { message: formValidationMessages.PHONE_CODE_REQUIRED })
    .regex(PHONE_CODE_REGEX, { message: formValidationMessages.PHONE_CODE_INVALID }),
});

interface VerifyPhoneFormProps {
  submitButton: ReactNode;
  onSubmit: (confirmationCode: string) => void;
  onResendCode: () => Promise<StoreActionReturn | void>;
}

const VerifyPhoneForm = ({ onSubmit, submitButton, onResendCode }: VerifyPhoneFormProps): JSX.Element => {
  const {
    toastsStore: { addToast },
  } = useStores();

  const [code, setCode] = useState('');
  const { validateData, errors, clearErrors } = useZodFormValidation(VerifyPhoneSchema, { code });

  const handleChange = (value: string): void => {
    clearErrors();
    setCode(value);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    if (validateData()) onSubmit(code);
  };

  const handleResendCode = (): void => {
    onResendCode().then((response) => {
      if (response?.success) {
        addToast('SMS code has been sent.', 'success');
      }
    });
  };

  return (
    <form onSubmit={handleSubmit} className="form">
      <div className={styles.phoneFormWrapper}>
        <div className={styles.inputWrapper}>
          <div className={styles.inputWithError}>
            <OTPInput
              value={code}
              onChange={handleChange}
              numInputs={PHONE_CODE_LENGTH}
              renderInput={(props) => <input {...props} />}
              inputType="number"
              containerStyle={styles.otpContainer}
              inputStyle={styles.otpInput}
              shouldAutoFocus
              skipDefaultStyles
            />
            {errors.code && <span className={clsx(styles.error)}>{errors.code[0]}</span>}
          </div>
        </div>

        <div className={styles.sendCodes}>
          <span>Didn’t receive the code?</span>
          <Button
            type="button"
            buttonType="textButton"
            text="Resend"
            className={styles.sendCodesButton}
            onClick={handleResendCode}
          />
        </div>
      </div>

      <div className={styles.formSubmitButton}>{submitButton}</div>
    </form>
  );
};

export default VerifyPhoneForm;
