import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { ArtworkStatusEnum } from 'artworks/types';
import ConsignmentForm from 'consignments/consignmentForm';
import ConsigneePicker from 'consignments/consignmentsComponents/consigneePicker';
import ConsignmentPanel from 'consignments/consignmentsComponents/consignmentPanel';
import { Consignment } from 'consignments/types';
import { useDebounceIsPanelOpen } from 'theme/atoms/slidingPanel/useDebounceIsPanelOpen';
import styles from './ArtworkConsignments.module.scss';
import {
  StatusOwnedConsignedView,
  StatusOwnedView,
  StatusOnConsignmentOrThirdPartyAwaitingView,
  StatusThirdPartyView,
} from './components';

export const ArtworkConsignments = (): JSX.Element => {
  const { artworkStore, consignmentStore } = useStores();
  const { artwork, artworkConsignments, artworkTransaction, status } = artworkStore;
  const { isPanelOpen, setIsPanelOpen, fetchConsignment } = consignmentStore;

  const [selectedConsignment, setSelectedConsignment] = useState<Consignment | null>(null);

  const debouncedIsPanelOpen = useDebounceIsPanelOpen(isPanelOpen);

  useEffect(() => {
    if (selectedConsignment) {
      fetchConsignment(selectedConsignment.id);
      debouncedIsPanelOpen && setIsPanelOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConsignment]);

  if (!artwork) return <></>;

  return (
    <div className={styles.root}>
      {
        status === ArtworkStatusEnum.Owned && <StatusOwnedView artworkId={artwork.id} /> // can create offer sale
      }
      {[
        ArtworkStatusEnum.OwnedConsignedAwaitingConsigning,
        ArtworkStatusEnum.OwnedConsigned,
        ArtworkStatusEnum.OwnedReserved,
        ArtworkStatusEnum.OwnedTransactionAccepted,
      ].includes(status) && (
        <StatusOwnedConsignedView
          consignments={artworkConsignments}
          setSelectedConsignment={setSelectedConsignment}
          isConsignmentSelected={!!selectedConsignment}
          hasAcceptedTransaction={status === ArtworkStatusEnum.OwnedTransactionAccepted}
        />
      )}
      {status === ArtworkStatusEnum.ThirdParty && <StatusThirdPartyView />}
      {[
        ArtworkStatusEnum.ThirdPartyAwaitingConsigning, // can create offer sale
        ArtworkStatusEnum.OnConsignment, // can create offer sale
        ArtworkStatusEnum.OnConsignmentReserved,
      ].includes(status) && (
        <StatusOnConsignmentOrThirdPartyAwaitingView
          consignments={artworkConsignments}
          setSelectedConsignment={setSelectedConsignment}
          isConsignmentSelected={!!selectedConsignment}
          transaction={artworkTransaction}
          status={status}
        />
      )}

      <ConsigneePicker />
      <ConsignmentForm />
      <ConsignmentPanel
        isOpen={isPanelOpen}
        onClose={() => {
          setSelectedConsignment(null);
          setIsPanelOpen(false);
        }}
      />
    </div>
  );
};

export default observer(ArtworkConsignments);
