import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import { FormValidation } from 'domain/types';
import { ReactComponent as AddArtwork } from 'theme/assets/svg/addArtwork.svg';
import { ReactComponent as EditArtwork } from 'theme/assets/svg/editArtwork.svg';
import { Button, TitleBlack } from 'theme/atoms';
import FormError from 'theme/atoms/formError';
import Loader from 'theme/atoms/loader';
import Tiles, { TileType } from 'theme/atoms/tiles/Tiles';
import { useFormValidation } from 'utils/hooks';
import styles from '../AddArtwork.module.scss';
import { AddArtworkSteps, AddArtworkTopbar, CancelAddArtwork, Certificate, PickAuthor } from '../components';
import { ARTWORK_STEPS } from '../constants';
import NoArtworkFound from './components/NoArtworkFound';

const FORM_VALIDATIONS: FormValidation = { authorRequired: '', certificateRequired: '' };

type TileId = (typeof TILES)[number]['id'];

const TILES: TileType[] = [
  {
    id: 'new',
    label: 'Certify new artwork',
    icon: <AddArtwork />,
  },
  {
    id: 'existing',
    label: 'Existing artwork',
    icon: <EditArtwork />,
  },
];

const CURRENT_STEP = ARTWORK_STEPS[0];
const NEXT_STEP = ARTWORK_STEPS[1];

const AddArtworkAuthor = (): JSX.Element => {
  const { addArtworkStore, userStore } = useStores();
  const {
    fetching,
    artworkToEdit,
    artworkForm,
    authorToDisplay,
    updateArtworkForm,
    saveDraft,
    fetchArtworkAndSetItToEdition,
    setArtworkToEdit,
  } = addArtworkStore;

  const navigate = useNavigate();
  const { artworkId } = useParams();
  const [selectedTile, setSelectedTile] = useState<TileId>('');

  useEffect(() => {
    artworkForm.existedBefore !== null && setSelectedTile(artworkForm.existedBefore ? 'existing' : 'new');
  }, [artworkForm.existedBefore]);

  useEffect(() => {
    setArtworkToEdit(true);
    if (artworkId && artworkId !== 'new') fetchArtworkAndSetItToEdition(artworkId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artworkId]);

  const formIsNotValid =
    !authorToDisplay || (artworkForm.certificationUrls.length === 0 && selectedTile === 'existing');

  const { cleanValidationErrors, handleValidation, validation, areThereAnyValidationErrors } =
    useFormValidation(FORM_VALIDATIONS);

  if (!artworkToEdit) return <NoArtworkFound />;

  const handleSelectedTile = (id: string): void => {
    setSelectedTile(id);
    updateArtworkForm('existedBefore', id === 'existing');
    id === 'new' && updateArtworkForm('certificationUrls', []);
  };

  const handleSaveAndNavigate = (route: (typeof ARTWORK_STEPS)[number]['path'], showToastOnSuccess = false): void => {
    if (artworkId) {
      saveDraft(artworkId, showToastOnSuccess).then((id) => {
        if (id) navigate(routes[route].path(id));
      });
    }
  };

  const handleGoToNextStep = (): void => {
    handleValidation(`authorRequired`, ' (required)', !authorToDisplay);
    if (selectedTile === 'existing')
      handleValidation(`certificateRequired`, ' (required)', artworkForm.certificationUrls.length === 0);
    if (formIsNotValid) {
      return;
    } else {
      updateArtworkForm('draftCreationStep', NEXT_STEP.id);
      handleSaveAndNavigate(NEXT_STEP.path);
    }
  };

  const buttons = (
    <div className={styles.buttonsWrapper}>
      <div className={styles.formError}>
        <FormError showError={areThereAnyValidationErrors} />
      </div>
      <Button
        text="Continue to artwork details"
        onClick={handleGoToNextStep}
        disabled={!selectedTile && !userStore.noPaymentMethods}
        maxWidth
      />
      <CancelAddArtwork
        disabled={!selectedTile || !authorToDisplay}
        artworkId={artworkId || 'new'}
        currentStep={formIsNotValid ? CURRENT_STEP.id : undefined}
      />
    </div>
  );

  return (
    <>
      {fetching ? (
        <Loader minHeight={150} />
      ) : (
        <>
          <AddArtworkTopbar step={CURRENT_STEP.label} />
          <TitleBlack text="Register artwork" />
          <div className={styles.root}>
            <div className={styles.mainColumn}>
              <Tiles
                list={TILES}
                selectedItem={selectedTile}
                setSelectedItem={(id) => handleSelectedTile(id)}
                tileClassName={styles.tile}
                withCheckMark
              />
              {selectedTile && (
                <PickAuthor
                  error={validation.authorRequired}
                  cleanError={() => cleanValidationErrors(['authorRequired'])}
                />
              )}
              {selectedTile === 'existing' && (
                <Certificate
                  onAdd={() => cleanValidationErrors(['certificateRequired'])}
                  error={validation.certificateRequired}
                />
              )}
              {buttons}
              <div className={styles.info}>Info about cost of the service will be here.</div>
            </div>
            <AddArtworkSteps step={CURRENT_STEP.label} />
          </div>
        </>
      )}
    </>
  );
};

export default observer(AddArtworkAuthor);
