import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import RecoveryCodesPanel from 'auth/components/recoveryCodesPanel';
import { Button, TitleBlack } from 'theme/atoms';
import Breadcrumbs, { Breadcrumb } from 'theme/atoms/Breadcrumbs';
import Topbar from 'theme/atoms/Topbar';
import { useIsAccessTokenFresh } from 'utils/hooks';
import styles from '../../SettingsSecurity.module.scss';

const BREADCRUMBS: Breadcrumb[] = [
  { label: 'Settings', route: 'SettingsSecurity' },
  { label: 'Security', route: 'SettingsSecurity' },
  { label: 'Regenerate recovery codes' },
];

const SettingsSecurityRecoveryCodes = (): React.JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const { authStore } = useStores();
  const { fetchRecoveryCodes, recoveryCodes, clearRecoveryCodes } = authStore;

  const isTokenFresh = useIsAccessTokenFresh();

  const [showCodes, setShowCodes] = useState<boolean>(location.state?.showCodes || false);

  useEffect(() => {
    if (!showCodes) return;

    if (!isTokenFresh) {
      navigate(routes.SettingsSecurityVerification.path(), {
        state: { redirectUrl: routes.SettingsSecurityRecoveryCodes.path(), showCodes },
      });
      return;
    }

    fetchRecoveryCodes().catch(() =>
      navigate(routes.SettingsSecurity.path(), { state: { isAuthenticationMethodsOpen: true } })
    );

    return clearRecoveryCodes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCodes]);

  const title = showCodes ? 'Recovery codes' : 'Regenerate recovery codes';
  const paragraph = showCodes
    ? 'Please save the following recovery codes which you can use to sign in:'
    : 'If you create new recovery codes, unused old codes will no longer work.';
  const body = showCodes ? (
    <RecoveryCodesPanel codes={recoveryCodes} />
  ) : (
    <Button text="Continue" onClick={() => setShowCodes(true)} className={styles.recoveryCodesButton} />
  );

  return (
    <div className={styles.pageContainer}>
      <Topbar>
        <Breadcrumbs breadcrumbs={BREADCRUMBS} />
      </Topbar>

      <TitleBlack>{title}</TitleBlack>
      <p className={styles.paragraph}>{paragraph}</p>
      <div className={styles.formContainer}>{body}</div>

      <Link
        to={routes.SettingsSecurity.path()}
        state={{ isAuthenticationMethodsOpen: true }}
        className={styles.backLink}
      >
        Back
      </Link>
    </div>
  );
};

export default observer(SettingsSecurityRecoveryCodes);
