import React from 'react';
import Spinner from 'theme/atoms/Spinner/Spinner';

export type FieldMapValue = {
  value: string | undefined;
  listValueOptionalStyles?: string;
};

export type DetailsListFieldsMap = Record<string, FieldMapValue>;

interface InfoCardDetailsListProps {
  fieldsMap: DetailsListFieldsMap;
  loadingData?: boolean;
}

const InfoCardDetailsList = ({ fieldsMap, loadingData = false }: InfoCardDetailsListProps): JSX.Element => {
  const infoCardDetailsDisplay = loadingData ? (
    <Spinner small />
  ) : (
    <>
      {Object.entries(fieldsMap).map(([key, { value, listValueOptionalStyles }]) => (
        <React.Fragment key={key}>
          <dt>{key}</dt>
          <dd className={listValueOptionalStyles}>{value}</dd>
        </React.Fragment>
      ))}
    </>
  );

  return <>{infoCardDetailsDisplay}</>;
};

export default InfoCardDetailsList;
