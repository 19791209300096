import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import ActionButtons, { ActionButton } from 'theme/atoms/ActionButtons';
import Breadcrumbs, { Breadcrumb } from 'theme/atoms/Breadcrumbs';
import Topbar from 'theme/atoms/Topbar';
import IconComponent, { icons } from 'theme/atoms/iconComponent';
import Loader from 'theme/atoms/loader';
import NoDataToDisplay from 'theme/atoms/noDataToDisplay';
import SearchTable from 'theme/cells/searchTable/SearchTable';
import { SearchTableView } from 'theme/cells/searchTable/constants';
import styles from './ArtworkRegistration.module.scss';
import ArtworkRegistrationTable from './components/artworkRegistrationTable';
import { ARTWORK_DRAFT_CATEGORIES_WITH_STATUSES_AND_STATES } from './constants';
import { ArtworkRegistrationCategoryId } from './types';

const BREADCRUMBS: Breadcrumb[] = [{ label: 'Catalog', route: 'Artworks' }, { label: 'Artwork registration' }];

const ArtworkRegistration = (): JSX.Element => {
  const navigate = useNavigate();
  const { categoryId } = useParams<{ categoryId: ArtworkRegistrationCategoryId }>();
  const { artworkRegistrationStore, artworkRegistrationSortAndFilterStore, addArtworkStore } = useStores();
  const { loading, items } = artworkRegistrationStore;
  const { initializeCreatingNewArtwork } = addArtworkStore;

  const [searchParams] = useSearchParams();

  const actionButtons: ActionButton[] = [
    {
      text: 'Register artwork',
      icon: <IconComponent icon={icons.add} />,
      onClick: () => {
        navigate(routes.AddArtworkAuthor.path('new'));
        initializeCreatingNewArtwork();
      },
    },
  ];

  const artworkRegistrationTable = loading ? (
    <Loader minHeight={150} />
  ) : items?.length > 0 ? (
    <ArtworkRegistrationTable />
  ) : (
    <NoDataToDisplay copy="No artworks to display." height={100} />
  );

  const selectCategory = (categoryId: string): void => {
    navigate({ pathname: routes.ArtworkRegistration.path(categoryId), search: searchParams.toString() });
  };

  return (
    <div className={styles.root}>
      <Topbar>
        <Breadcrumbs breadcrumbs={BREADCRUMBS} />
        <ActionButtons buttons={actionButtons} />
      </Topbar>
      <SearchTable
        view={SearchTableView.ArtworkRegistration}
        store={artworkRegistrationStore}
        dontShowSortAndFilterPanel
        filterStore={artworkRegistrationSortAndFilterStore}
      >
        <ul className={styles.categories}>
          {ARTWORK_DRAFT_CATEGORIES_WITH_STATUSES_AND_STATES.map(({ id, label }) => (
            <li key={id} onClick={() => selectCategory(id)} className={clsx({ [styles.active]: id === categoryId })}>
              {label}
            </li>
          ))}
        </ul>
      </SearchTable>
      {artworkRegistrationTable}
    </div>
  );
};

export default observer(ArtworkRegistration);
