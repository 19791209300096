import { useEffect, ReactNode } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import clsx from 'clsx';
import { routes } from 'app/routes/paths.const';
import { BankPerspectiveLayoutCategoriesMap } from 'bankPerspective/BankPerspective.types';
import BankInvoicesStore from 'bankPerspective/bankInvoices/BankInvoicesStore';
import BankInvoicesSortAndFilterStore from 'bankPerspective/bankInvoices/sortAndFilter/BankInvoicesSortAndFilterStore';
import { BankInvoiceCategoryId } from 'bankPerspective/bankInvoices/types';
import BankTransactionsStore from 'bankPerspective/bankTransactions/BankTransactionsStore';
import { BANK_TRANSACTIONS_CATEGORIES_MAP } from 'bankPerspective/bankTransactions/constants';
import BankTransactionsSortAndFilterStore from 'bankPerspective/bankTransactions/sortAndFilter/BankTransactionsSortAndFilterStore';
import { BankTransactionCategoryId } from 'bankPerspective/bankTransactions/types';
import Breadcrumbs, { Breadcrumb } from 'theme/atoms/Breadcrumbs';
import Topbar from 'theme/atoms/Topbar';
import Loader from 'theme/atoms/loader';
import SearchTable from 'theme/cells/searchTable';
import { SearchTableView } from 'theme/cells/searchTable/constants';
import styles from './BankPerspectiveLayout.module.scss';

interface BankPerspectiveLayoutProps {
  breadcrumbs: Breadcrumb[];
  categoriesMap: BankPerspectiveLayoutCategoriesMap;
  categoryId: BankInvoiceCategoryId | BankTransactionCategoryId | undefined;
  children: ReactNode;
  dontShowFilterPanel?: boolean;
  dontShowSearchBar?: boolean;
  fetchItems: () => void;
  loading: boolean;
  sortAndFilterStore: BankTransactionsSortAndFilterStore | BankInvoicesSortAndFilterStore;
  store: BankTransactionsStore | BankInvoicesStore;
}

const BankPerspectiveLayout = ({
  breadcrumbs,
  categoriesMap,
  categoryId,
  children,
  dontShowFilterPanel = true,
  dontShowSearchBar = true,
  fetchItems,
  loading,
  sortAndFilterStore,
  store,
}: BankPerspectiveLayoutProps): JSX.Element => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  const selectCategory = (category: string): void => {
    const isTransactionCategory = category in BANK_TRANSACTIONS_CATEGORIES_MAP;

    const checkPathname = (): string | undefined => {
      if (isTransactionCategory) return routes.BankTransactions.path(category);
      else return routes.BankInvoices.path(category);
    };

    navigate({
      pathname: checkPathname(),
      search: searchParams.toString(),
    });
  };

  const content = loading ? <Loader minHeight={150} /> : children;

  const selectView =
    categoryId && categoryId in BANK_TRANSACTIONS_CATEGORIES_MAP
      ? SearchTableView.BankTransactions
      : SearchTableView.BankInvoices;

  return (
    <>
      <Topbar>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      </Topbar>
      <SearchTable
        view={selectView}
        store={store}
        dontShowSearchbox={dontShowSearchBar}
        dontShowSortAndFilterPanel={dontShowFilterPanel}
        filterStore={sortAndFilterStore}
      >
        <ul className={styles.categories}>
          {Object.entries(categoriesMap).map(([id, category]) => {
            return (
              <li key={id} onClick={() => selectCategory(id)} className={clsx({ [styles.active]: id === categoryId })}>
                {category.label}
              </li>
            );
          })}
        </ul>
      </SearchTable>
      {content}
    </>
  );
};

export default BankPerspectiveLayout;
