import { FunctionComponent, MouseEvent, SVGProps } from 'react';
import clsx from 'clsx';
import IconComponent, { iconSizes } from 'theme/atoms/iconComponent';
import styles from './IconButton.module.scss';

interface IconButtonProps {
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  ariaLabel?: string;
  className?: string;
  onClick: ((event: MouseEvent<HTMLElement>) => void) | (() => void);
  title?: string;
  size?: (typeof iconSizes)[keyof typeof iconSizes];
}

const IconButton = ({ icon, ariaLabel, className, onClick, size, title }: IconButtonProps): JSX.Element => (
  <button onClick={onClick} aria-label={ariaLabel} className={clsx(styles.root, className)} title={title}>
    <IconComponent icon={icon} size={size} />
  </button>
);

export default IconButton;
