import { AxiosResponse } from 'axios';
import api from 'api';
import { OrganizationCreateDto } from 'createOrganization/types';
import { RawBankAccount } from 'domain/types/BankAccount.types';
import { OrganizationsReturn, OrganizationReturn, RawOrganization, KycTokenReturn } from 'organization/types';

export const fetchOrganizations = (): OrganizationsReturn => {
  return api.get('/organizations');
};

export const createOrganization = (organizationData: OrganizationCreateDto): OrganizationReturn => {
  return api.post('/organizations', organizationData);
};

export const updateOrganization = (id: string, data: Partial<RawOrganization>): OrganizationReturn => {
  return api.patch(`/organizations/${id}`, data);
};

export const fetchOrganizationBankAccountDetails = (): Promise<AxiosResponse> => {
  return api.get(`/organizations/bank-account`);
};

export const createOrganizationBankAccount = (
  accountDetails: RawBankAccount
): Promise<AxiosResponse<RawBankAccount>> => {
  return api.post(`/organizations/bank-account`, accountDetails);
};

export const editOrganizationBankAccount = (accountDetails: RawBankAccount): Promise<AxiosResponse<RawBankAccount>> => {
  return api.patch(`/organizations/bank-account`, accountDetails);
};

export const fetchKycToken = (): KycTokenReturn => {
  return api.post('/organizations/kyc/access-token');
};

export const fetchOrganizationKycRepresentatives = (): Promise<AxiosResponse> => {
  return api.get(`/organizations/kyc/representatives`);
};

export const resetKycToken = (applicantId: string): KycTokenReturn => {
  return api.post(`/organizations/kyc/representatives/${applicantId}/reset`);
};
