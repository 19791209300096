import dayjs from 'dayjs';
import { LEEWAY } from 'api/Api';
import { Auth } from 'auth/types';

export const isAccessTokenValid = (accessToken: Auth | null): boolean => {
  if (!accessToken) return false;

  const currentTimeInMs = new Date().getTime();
  const accessTokenExpTimeInMs = accessToken.user.exp
    ? dayjs(accessToken.user.exp * 1000).subtract(LEEWAY, 'minute')
    : 0;
  return dayjs(currentTimeInMs).isSameOrBefore(accessTokenExpTimeInMs, 'minute');
};
