import { useState } from 'react';
import { ReactNode } from 'react';
import clsx from 'clsx';
import { highlightText } from 'domain/utils';
import styles from './TableDetailItem.module.scss';

interface TableDetailItemProps {
  text?: string;
  nameYear?: string[];
  className?: string;
  bgColor?: string;
  searchText?: string;
  maxWidth?: number;
}

const getElementToDisplay = (
  searchText: string | undefined,
  text: string | undefined,
  nameYear: string[] | undefined
): ReactNode => {
  const searchString = searchText || '';
  if (nameYear) {
    return (
      <>
        <span>{highlightText(nameYear?.[0], searchString)}</span>, {nameYear?.[1]}
      </>
    );
  } else {
    return highlightText(text || '', searchString);
  }
};

const TableDetailItem = ({
  text,
  nameYear,
  className = '',
  searchText,
  bgColor,
  maxWidth,
}: TableDetailItemProps): JSX.Element => {
  const [showUncutContent, setShowUncutContent] = useState(false);
  const elementToDisplay = getElementToDisplay(searchText, text, nameYear);

  return (
    <div
      className={clsx(styles.root, className, { [styles.nameYear]: nameYear })}
      onMouseEnter={() => setShowUncutContent(true)}
      onMouseLeave={() => setShowUncutContent(false)}
    >
      <div className={styles.cutContentWrapper}>{elementToDisplay}</div>
      {showUncutContent && (
        <div className={styles.uncutContentWrapper} style={{ backgroundColor: bgColor, maxWidth }}>
          {elementToDisplay}
        </div>
      )}
    </div>
  );
};

export default TableDetailItem;
