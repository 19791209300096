import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import { addSpaceBeforeUppercase } from 'domain/utils';
import Avatar from 'theme/atoms/avatar';
import Chip from 'theme/atoms/chip';
import ImageDraft from 'theme/atoms/imageDraft';
import { Table } from 'theme/atoms/table/Table';
import TableDetailItem from 'theme/atoms/tableDetailItem';
import tableDetailItemStyles from 'theme/atoms/tableDetailItem/TableDetailItem.module.scss';
import { TransactionDirection } from 'transactions/domain';
import { TransactionWrapper } from 'transactions/requests.types';
import { formatDate } from 'utils/helpers';
import { useTransactionRoles } from 'utils/hooks';
import styles from './ListTransactionsTable.module.scss';
import { statusIcon } from './constants';

const ListTransactionsTable = observer((): JSX.Element => {
  const navigate = useNavigate();
  const { transactionsStore } = useStores();
  const { items } = transactionsStore;
  const transactionsWrappers = items as TransactionWrapper[];

  const handleRowClick = (transactionId: string): void => {
    navigate(routes.Transaction.path(transactionId));
  };

  const table = (
    <div className={styles.root}>
      <Table>
        <>
          {transactionsWrappers
            .filter((transactionWrapper) => transactionWrapper.artwork)
            .map((transactionWrapper) => {
              const { isSellerSide, isBuyerSide } = useTransactionRoles({ transactionWrapper });

              const representativeName = isSellerSide
                ? transactionWrapper.transaction.sellerInfo?.representativeContact?.organizationName || 'Undisclosed'
                : transactionWrapper.transaction.buyerInfo?.representativeContact?.organizationName || 'Undisclosed';

              return (
                <tr
                  className={styles.row}
                  key={transactionWrapper.transaction.id}
                  onClick={() => handleRowClick(transactionWrapper.transaction.id)}
                >
                  <td className={styles.chipTd}>
                    {isSellerSide && <Chip>{TransactionDirection.Sale}</Chip>}
                    {isBuyerSide && <Chip>{TransactionDirection.Purchase}</Chip>}
                  </td>
                  <td className={styles.imageTd}>
                    {transactionWrapper.artwork.assets.imageUrls?.[0]?.presignedUrl ? (
                      <img
                        src={transactionWrapper.artwork.assets.imageUrls?.[0]?.presignedUrl}
                        alt={transactionWrapper.artwork.name}
                      />
                    ) : (
                      <ImageDraft />
                    )}
                  </td>
                  <td className={styles.mainDataTd}>
                    <Fragment>
                      <TableDetailItem
                        text={transactionWrapper.artwork.author.name}
                        searchText={transactionsStore.searchQuery}
                        className={tableDetailItemStyles.bold}
                      />
                      <TableDetailItem
                        nameYear={[transactionWrapper.artwork.name, transactionWrapper.artwork.year.toString()]}
                        searchText={transactionsStore.searchQuery}
                      />
                    </Fragment>
                  </td>
                  <td className={styles.idTd}>
                    <TableDetailItem
                      text={transactionWrapper.transaction.id}
                      className={tableDetailItemStyles.normalSecondary}
                    />
                  </td>
                  <td className={styles.dateTd}>
                    <TableDetailItem
                      text={formatDate(transactionWrapper.transaction.createdAt)}
                      className={tableDetailItemStyles.normal}
                    />
                  </td>
                  <td className={styles.primaryContactTd}>
                    {representativeName ? (
                      <>
                        <Avatar id={representativeName} />
                        <TableDetailItem
                          text={representativeName}
                          searchText={transactionsStore.searchQuery}
                          className={tableDetailItemStyles.userWithAvatarSemibold}
                        />
                      </>
                    ) : null}
                  </td>
                  <td className={styles.statusTd}>
                    {statusIcon(transactionWrapper.transaction.status)}
                    {addSpaceBeforeUppercase(transactionWrapper.transaction.status)}
                  </td>
                </tr>
              );
            })}
        </>
      </Table>
    </div>
  );
  return table;
});

export default ListTransactionsTable;
