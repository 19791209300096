import { ReactNode } from 'react';
import clsx from 'clsx';
import { ReactComponent as DragIndicatorIcon } from 'theme/assets/svg/dragging-dots.svg';
import styles from './DraggableField.module.scss';

interface DraggableFieldProps {
  elementDragRef: (element: HTMLElement | null) => void;
  handleRef: (element: HTMLElement | null) => void;
  children: ReactNode;
  className?: string;
}

const DraggableField = ({ elementDragRef, handleRef, children, className = '' }: DraggableFieldProps): JSX.Element => {
  return (
    <div ref={elementDragRef} className={clsx(styles.root, className)}>
      <div ref={handleRef} className={styles.dragHandle}>
        <DragIndicatorIcon className={styles.icon} />
      </div>
      <div className={styles.children}>{children}</div>
    </div>
  );
};

export default DraggableField;
