import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { routes } from 'app/routes/paths.const';
import { RawBankInvoice } from 'bankPerspective/bankInvoices/requests.types';
import Big from 'big.js';
import { InvoiceState } from 'domain/types/Invoices.types';
import { ReactComponent as PaidIcon } from 'theme/assets/svg/paidTransaction.svg';
import BillingCard from 'theme/atoms/billingCard';
import InvoiceStateCheckIcon from 'theme/atoms/invoiceStateCheckIcon';
import { formatPrice } from 'utils/helpers';
import styles from './BankInvoicesTable.module.scss';

interface BankInvoiceTableRowProps {
  invoice: RawBankInvoice;
}

const BankInvoiceTableRow = ({ invoice }: BankInvoiceTableRowProps): JSX.Element => {
  const { id, amount, currency, state, issuerOrganizationDto, payerOrganizationDto, createdAt } = invoice;

  const navigate = useNavigate();

  const handleDetailsClick = (): void => {
    navigate(routes.BankInvoice.path(id));
  };

  const isPaid = state === InvoiceState.Paid;

  return (
    <tr className={styles.rowTable} key={invoice.id} onClick={handleDetailsClick}>
      <td className={styles.invoiceStateTd}>
        <InvoiceStateCheckIcon
          className={styles.stateIcon}
          state={state}
          withStateText
          createdDate={createdAt}
          withoutTooltip
        />
      </td>
      <td className={clsx(styles.transactionSiteTd, styles.firstLeftMargin)}>
        <BillingCard.TransactionSiteAvatar
          className={styles.personWrapper}
          label="From:"
          organizationId={id}
          organizationName={issuerOrganizationDto.organizationName}
        />
      </td>
      <td className={styles.transactionSiteTd}>
        <BillingCard.TransactionSiteAvatar
          className={styles.personWrapper}
          label="To:"
          organizationId={id}
          organizationName={payerOrganizationDto.organizationName}
        />
      </td>
      <td className={styles.invoiceAmountTd}>
        <div className={styles.priceWrapper}>
          {formatPrice(Big(amount), currency, 2)}
          {isPaid && <PaidIcon />}
        </div>
      </td>
    </tr>
  );
};

export default BankInvoiceTableRow;
