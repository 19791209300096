import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import Billing from 'theme/atoms/billing';
import UserCard from 'theme/atoms/userCard';
import { TransactionWrapper } from 'transactions/requests.types';
import { formatPrice } from 'utils/helpers';
import { useTransactionPrices, useTransactionRoles } from 'utils/hooks';

interface InitialPriceProps {
  isSummaryView: boolean;
}

const InitialPrice = ({ isSummaryView }: InitialPriceProps): JSX.Element => {
  const { transactionFormStore } = useStores();
  const transactionWrapper = transactionFormStore.transactionWrapper as TransactionWrapper;
  const {
    transaction: { currency, sellerInfo, buyerInfo },
    artwork: { owners },
  } = transactionWrapper;

  const { askingPrice, artworkPrice } = useTransactionPrices();
  const { isSellerSide, isBuyerRepresentative, isSellerOwner } = useTransactionRoles({ transactionWrapper });

  const title = isSellerSide ? 'Asking price' : 'Artwork price';
  const initialPrice = isSellerSide ? askingPrice : artworkPrice;
  const representative =
    isBuyerRepresentative && sellerInfo ? sellerInfo.representativeContact : buyerInfo?.representativeContact;
  const receivers = isSellerSide ? owners : [{ contact: representative, participationPercent: 100 }];
  const shouldDisplayReceivers = !isSellerOwner && isSummaryView;

  const receiversList = receivers.map((receiver) => {
    const partialPrice = initialPrice.times(receiver.participationPercent).div(100);
    return (
      <Billing.Row isIndented key={receiver.contact?.contactConnectionId || receiver.contact?.organizationName}>
        <UserCard isSmall id={receiver.contact?.organizationName}>
          <UserCard.Name name={receiver.contact?.organizationName || 'Undisclosed'} />
        </UserCard>
        {formatPrice(partialPrice, currency)}
      </Billing.Row>
    );
  });

  return (
    <>
      <Billing.Row isDark>
        <Billing.Cell>{title}:</Billing.Cell>
        <Billing.Cell isBold={isSummaryView}>{formatPrice(initialPrice, currency)}</Billing.Cell>
      </Billing.Row>
      {shouldDisplayReceivers && receiversList}
    </>
  );
};

export default observer(InitialPrice);
