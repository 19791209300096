import { AxiosResponse } from 'axios';
import api from 'api';
import { Invoices, PaymentMethod, ProductStripe, SubscriptionPortal } from './types';

export const fetchInvoices = (): Promise<AxiosResponse<Invoices>> => {
  return api.get('/subscriptions/invoices');
};

export const fetchPaymentMethods = (): Promise<AxiosResponse<PaymentMethod[]>> => {
  return api.get('/subscriptions/payment-methods');
};

export const createSubscriptionPortal = (): Promise<AxiosResponse<SubscriptionPortal>> => {
  return api.post('/subscriptions/portal');
};

export const fetchProducts = (): Promise<AxiosResponse<ProductStripe[]>> => {
  return api.get('/subscriptions/products');
};
