import { observer } from 'mobx-react-lite';
import dayjs from 'dayjs';
import { useStores } from 'RootStore';
import { FilterItemType, SortAndFilterStoresWithPanel } from 'domain/SortAndFilterBasicStore/types';
import DatePickerWrapper from 'theme/atoms/datePickerWrapper';
import { getCorrectDateWithYearThatMayBeTwoDigits } from '../datePickerWrapper/DatePickerWrapper';
import styles from './DatePickerRange.module.scss';
import { Kind, RangeEnd, RangeEndMax, RangeEndMin } from './types';

interface DatePickerRangeProps {
  kind: Kind;
  minLabel: RangeEndMin;
  maxLabel: RangeEndMax;
  showYearPicker?: boolean;
  store: SortAndFilterStoresWithPanel;
}

const DatePickerRange = ({
  kind,
  store,
  showYearPicker = false,
  minLabel,
  maxLabel,
}: DatePickerRangeProps): JSX.Element => {
  const { toastsStore } = useStores();
  const { addToast } = toastsStore;
  const { updateSelectedFilteringItems, selectedFilteringItems, resetActivePage } = store;

  const dateStartFromStore = selectedFilteringItems.find((i) => i.key === kind + minLabel);
  const dateEndFromStore = selectedFilteringItems.find((i) => i.key === kind + maxLabel);

  const dateStart = dateStartFromStore?.value ? dateStartFromStore : { key: kind + minLabel, value: '' };
  const dateEnd = dateEndFromStore?.value ? dateEndFromStore : { key: kind + maxLabel, value: '' };

  const getErrorMessage = (rangeEnd: RangeEnd): string => {
    return `${rangeEnd === maxLabel ? 'Start' : 'End'} date needs to be ${
      rangeEnd === maxLabel ? 'earlier' : 'later'
    } or the same as ${rangeEnd === maxLabel ? 'end' : 'start'} date`;
  };

  const handleDateChange = (value: string | null, rangeEnd: RangeEnd): void => {
    const formattedValue = showYearPicker && value ? new Date(value).getFullYear().toString() : value;
    const otherRangeEndValue = rangeEnd === minLabel ? dateEnd.value : dateStart.value;

    const isNewValueFineWithOtherValue = (): boolean => {
      if (!value) {
        return true;
      }
      if (showYearPicker) {
        const dateStartAsDate = getCorrectDateWithYearThatMayBeTwoDigits(dateStart.value as string);
        const dateEndAsDate = getCorrectDateWithYearThatMayBeTwoDigits(dateEnd.value as string);
        return rangeEnd === minLabel ? new Date(value) <= dateEndAsDate : dateStartAsDate <= new Date(value);
      } else {
        return rangeEnd === minLabel
          ? dayjs(value).isSameOrBefore(dayjs(dateEnd.value as string))
          : dayjs(dateStart.value as string).isSameOrBefore(dayjs(value));
      }
    };

    if (value && otherRangeEndValue && !isNewValueFineWithOtherValue()) {
      addToast(getErrorMessage(rangeEnd), 'error', 2500);
    }

    const valueIfFineWithOtherValue = !otherRangeEndValue || isNewValueFineWithOtherValue() ? formattedValue : '';
    const field = kind + rangeEnd;

    const newItemsIfThisFieldIsOnTheFilteringList =
      valueIfFineWithOtherValue &&
      selectedFilteringItems.find((el) => (el.key as Kind) === field) &&
      selectedFilteringItems.map((el) =>
        (el.key as Kind) === field ? { key: field, value: valueIfFineWithOtherValue } : el
      );
    const newItemsIfThisFieldIsntOnTheFilteringListYet = valueIfFineWithOtherValue &&
      !selectedFilteringItems.find((el) => (el.key as Kind) === field) && [
        ...selectedFilteringItems,
        { key: field, value: valueIfFineWithOtherValue },
      ];
    const newItemsIfNoValue =
      !valueIfFineWithOtherValue && selectedFilteringItems.filter((f) => (f.key as Kind) !== field);

    const newItems =
      newItemsIfThisFieldIsOnTheFilteringList || newItemsIfThisFieldIsntOnTheFilteringListYet || newItemsIfNoValue;

    updateSelectedFilteringItems(newItems as FilterItemType[]);
    resetActivePage();
  };

  return (
    <div className={styles.root}>
      <DatePickerWrapper
        value={(dateStart.value as string) || ''}
        onChange={(date) => handleDateChange(date, minLabel as RangeEnd)}
        label="from:"
        showYearPicker={showYearPicker}
      />
      <DatePickerWrapper
        value={(dateEnd.value as string) || ''}
        onChange={(date) => handleDateChange(date, maxLabel as RangeEnd)}
        label="to:"
        showYearPicker={showYearPicker}
      />
    </div>
  );
};

export default observer(DatePickerRange);
