import { FormEvent, useState } from 'react';
import { Button } from 'theme/atoms';
import RadioTilesList from 'theme/cells/radioTiles/radioTilesList';
import { RadioTileType } from '../types';
import styles from './RadioTilesForm.module.scss';

interface RadioTilesFormProps {
  tiles: RadioTileType[];
  onSubmit: (selectedTile: string) => void;
}

const RadioTilesForm = ({ tiles, onSubmit }: RadioTilesFormProps): React.JSX.Element => {
  const [selectedTile, setSelectedTile] = useState<string>(tiles[0].id);

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    onSubmit(selectedTile);
  };

  return (
    <form onSubmit={handleSubmit}>
      <RadioTilesList tiles={tiles} selectedTile={selectedTile} onChange={setSelectedTile} />
      <Button type="submit" buttonType="primary" text="Continue" maxWidth className={styles.submitButton} />
    </form>
  );
};

export default RadioTilesForm;
