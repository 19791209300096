import { ARTWORK_STEPS } from 'artworks/addArtwork/constants';
import Breadcrumbs, { Breadcrumb } from 'theme/atoms/Breadcrumbs';
import Topbar from 'theme/atoms/Topbar';

interface AddArtworkTopbarProps {
  step: (typeof ARTWORK_STEPS)[number]['label'];
}

const AddArtworkTopbar = ({ step }: AddArtworkTopbarProps): JSX.Element => {
  const activeStep: number = ARTWORK_STEPS.findIndex((item) => item.label === step) + 1;
  const breadcrumbs: Breadcrumb[] = [
    { label: 'Catalog', route: 'Artworks' },
    { label: `Add artwork - step ${activeStep}` },
  ];

  return (
    <Topbar>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
    </Topbar>
  );
};

export default AddArtworkTopbar;
