import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import { Button, Input } from 'theme/atoms';
import { useOrganizationKinds } from 'utils/hooks/useOrganizationKinds';
import styles from './ReadOnlyForm.module.scss';

export interface OrganizationDetail {
  key: string;
  label: string;
  parentKey?: string;
}

const COMMON_ORGANIZATION_DETAILS_MAP: OrganizationDetail[] = [
  { parentKey: 'address', key: 'country', label: 'Country' },
  { parentKey: 'address', key: 'city', label: 'City' },
];

// TODO: GET /organizations return only name, but individual org should has firstname and lastname
const INDIVIDUAL_ORGANIZATION_DETAILS_MAP: OrganizationDetail[] = [
  { key: 'name', label: 'Name' },
  //   { key: 'firstname', label: 'First name' },
  //   { key: 'lastname', label: 'Last name' },
];

const INSTITUTIONAL_ORGANIZATION_DETAILS_MAP: OrganizationDetail[] = [{ key: 'name', label: 'Company name' }];

const ReadOnlyForm = (): React.JSX.Element => {
  const { organizationStore } = useStores();
  const { selectedOrganization } = organizationStore;
  const { isIndividual, isInstitutional } = useOrganizationKinds();

  const organizationDetailsMap: OrganizationDetail[] = [
    ...(isIndividual ? INDIVIDUAL_ORGANIZATION_DETAILS_MAP : []),
    ...(isInstitutional ? INSTITUTIONAL_ORGANIZATION_DETAILS_MAP : []),
    ...COMMON_ORGANIZATION_DETAILS_MAP,
  ];

  return (
    <div className={styles.root}>
      {organizationDetailsMap.map(({ key, label, parentKey }) => {
        return (
          <Input
            readOnly
            label={label}
            name={key}
            value={parentKey ? selectedOrganization?.[parentKey][key] || '' : selectedOrganization?.[key] || ''}
            key={key}
          />
        );
      })}
      <div className={styles.instruction}>
        Above information can be changed under{' '}
        <Button to={routes.SettingsKYC.path()} text="KYC section" buttonType="textButton" />
      </div>
    </div>
  );
};

export default observer(ReadOnlyForm);
