import { ReactElement } from 'react';
import { Navigate, Route } from 'react-router-dom';
import { AppWrapper } from 'AppWrapper/AppWrapper';
import AuthWrapper from 'auth/AuthWrapper/AuthWrapper';
import { DEFAULT_BANK_TRANSACTIONS_CATEGORY } from 'bankPerspective/bankTransactions/constants';
import { URL_PARAM, routes } from '../paths.const';
import { getCommonRoutesForAuthenticatedUser } from './getCommonRoutesForAuthenticatedUser';

export const getRoutesForAuthenticatedBankUser = (): ReactElement => {
  return (
    <Route>
      <Route element={<AuthWrapper />}>
        {Object.entries(routes)
          .filter(([_key, view]) => view.pageType === 'publicAndPrivate')
          .map(([key, view]) => (
            <Route key={key} path={view.path()} element={view.component} />
          ))}
      </Route>
      <Route element={<AppWrapper />}>
        <Route path={routes.BankTransactions.path(URL_PARAM.categoryId)} element={routes.BankTransactions.component} />
        <Route path={routes.BankTransaction.path(URL_PARAM.transactionId)} element={routes.BankTransaction.component} />
        <Route path={routes.BankInvoices.path(URL_PARAM.categoryId)} element={routes.BankInvoices.component} />
        <Route path={routes.BankInvoice.path(URL_PARAM.invoiceId)} element={routes.BankInvoice.component} />
      </Route>

      {getCommonRoutesForAuthenticatedUser()}
      <Route
        path="*"
        element={<Navigate to={routes.BankTransactions.path(DEFAULT_BANK_TRANSACTIONS_CATEGORY)} replace />}
      />
    </Route>
  );
};
