import { makeAutoObservable, runInAction } from 'mobx';
import { RootStore } from 'RootStore';
import * as artworksRequests from 'artworks/requests';
import { ArtworkId } from 'artworks/types';
import Big from 'big.js';
import { fetchConsignmentsGroupedByArtwork } from 'consignments/requests';
import { Consignment, ConsignmentId } from 'consignments/types';
import { getMessageFromApiErrorResponse } from 'domain/getMessageFromApiErrorResponse';
import { RawBankAccount } from 'domain/types/BankAccount.types';
import { Currency } from 'domain/types/Domain.types';
import { TransactionId } from 'domain/types/Transactions.types';
import { TransactionStatus } from 'domain/types/Transactions.types';
import { Organization } from 'organization/types';
import * as requests from 'transactions/requests';
import { TransactionCreateRequest, TransactionUpdateRequest, Transaction } from 'transactions/requests.types';

class TransactionStore {
  rootStore: RootStore;

  transactionId: TransactionId | null = null;
  artworkId: ArtworkId | null = null;
  consignmentId: ConsignmentId | null = null;

  loaded = false;
  escrowAccount: RawBankAccount | null = null;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  setTransactionId = (id: string): void => {
    runInAction(() => {
      this.transactionId = id;
    });
  };

  setArtworkId = (id: string): void => {
    runInAction(() => {
      this.artworkId = id;
    });
  };

  setConsignmentId = (id: string): void => {
    runInAction(() => {
      this.consignmentId = id;
    });
  };

  loadConsignmentAndInitNewTransaction = async (): Promise<void> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    this.rootStore.transactionFormStore.setTransactionWrapper(null);
    runInAction(() => (this.loaded = false));

    if (!this.consignmentId) {
      addToast(toastMessages.DEFAULT.ERROR, 'error');
    } else {
      return fetchConsignmentsGroupedByArtwork({ consignmentId: this.consignmentId })
        .then(({ data }) => {
          runInAction(() => (this.loaded = true));
          const consignmentItem = data.results[0];

          if (!consignmentItem) {
            addToast(toastMessages.DEFAULT.ERROR, 'error');
            return;
          }

          const consignment: Consignment = consignmentItem.consignments[0];
          const transaction = {
            id: 'new',
            artworkId: consignmentItem.artwork.id,
            consignmentId: consignmentItem.consignments.find((item) => item.isConsignee && item.status === 'Accepted')
              ?.id,
            status: TransactionStatus.Draft,
            currency: consignment.currency,
            sellerInfo: {
              representativeContact: {
                organizationName: (this.rootStore.organizationStore.selectedOrganization as Organization).name,
                organizationTitle: (this.rootStore.organizationStore.selectedOrganization as Organization).title,
                organizationAddress: (this.rootStore.organizationStore.selectedOrganization as Organization).address,
                self: true,
              },
              price: consignment.askingPrice,
              ownersVotes: [],
              totalCommission: consignment.maxCommission,
              commissionReceivers: [
                {
                  contact: {
                    organizationName: (this.rootStore.organizationStore.selectedOrganization as Organization).name,
                    organizationTitle: (this.rootStore.organizationStore.selectedOrganization as Organization).title,
                    organizationAddress: (this.rootStore.organizationStore.selectedOrganization as Organization)
                      .address,
                    self: true,
                  },
                  commission: consignment.maxCommission,
                },
              ],
            },
            modifiedAt: new Date(),
            modifiedBy: '',
            createdAt: new Date(),
            createdBy: '',
          };
          this.rootStore.transactionFormStore.setTransactionWrapper({
            transaction,
            consignment,
            artwork: consignmentItem.artwork,
          });
        })
        .catch(() => {
          runInAction(() => (this.loaded = true));
          addToast(toastMessages.DEFAULT.ERROR, 'error');
        });
    }
  };

  loadArtworkAndInitNewTransaction = async (): Promise<void> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    this.rootStore.transactionFormStore.setTransactionWrapper(null);
    runInAction(() => (this.loaded = false));

    if (!this.artworkId) {
      addToast(toastMessages.DEFAULT.ERROR, 'error');
    } else {
      return artworksRequests
        .fetchArtwork(this.artworkId)
        .then(({ data }) => {
          runInAction(() => (this.loaded = true));

          const artwork = data.results[0];
          const transaction = {
            id: 'new',
            artworkId: artwork.id,
            status: TransactionStatus.Draft,
            currency: 'USD' as Currency,
            sellerInfo: {
              representativeContact: {
                organizationName: (this.rootStore.organizationStore.selectedOrganization as Organization).name,
                organizationTitle: (this.rootStore.organizationStore.selectedOrganization as Organization).title,
                organizationAddress: (this.rootStore.organizationStore.selectedOrganization as Organization).address,
                self: true,
              },

              price: Big(0),
              totalCommission: Big(0),
              ownersVotes: [],
              commissionReceivers: [
                {
                  contact: {
                    organizationName: (this.rootStore.organizationStore.selectedOrganization as Organization).name,
                    organizationTitle: (this.rootStore.organizationStore.selectedOrganization as Organization).title,
                    organizationAddress: (this.rootStore.organizationStore.selectedOrganization as Organization)
                      .address,
                    self: true,
                  },
                  commission: Big(0),
                },
              ],
            },
            modifiedAt: new Date(),
            modifiedBy: '',
            createdAt: new Date(),
            createdBy: '',
          };

          this.rootStore.transactionFormStore.setTransactionWrapper({
            transaction,
            artwork,
          });
        })
        .catch(() => {
          runInAction(() => (this.loaded = true));
          addToast(toastMessages.DEFAULT.ERROR, 'error');
        });
    }
  };

  fetchOrLoadTransaction = (): void => {
    if (this.transactionId === 'new') {
      if (this.consignmentId) {
        this.loadConsignmentAndInitNewTransaction();
      } else if (this.artworkId) {
        this.loadArtworkAndInitNewTransaction();
      } else {
        const { addToast, toastMessages } = this.rootStore.toastsStore;
        addToast(toastMessages.DEFAULT.ERROR, 'error');
        runInAction(() => (this.loaded = true));
      }
    } else {
      this.fetchTransaction();
    }
  };

  fetchTransaction = async (): Promise<void> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;

    runInAction(() => (this.loaded = false));

    if (!this.transactionId) {
      addToast(toastMessages.DEFAULT.ERROR, 'error');
    } else {
      return requests
        .fetchTransaction(this.transactionId)
        .then(({ data: { results } }) => {
          this.rootStore.transactionFormStore.setTransactionWrapper({ ...results[0] });
          runInAction(() => {
            this.loaded = true;
          });
        })
        .catch(() => {
          addToast(toastMessages.DEFAULT.ERROR, 'error');
          runInAction(() => {
            this.loaded = true;
          });
        });
    }
  };

  createTransaction = async (req: TransactionCreateRequest): Promise<void | boolean> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;

    return requests
      .createTransaction(req)
      .then((res: Transaction) => {
        this.rootStore.transactionFormStore.transactionWrapper &&
          this.rootStore.transactionFormStore.setTransactionWrapper({
            ...this.rootStore.transactionFormStore.transactionWrapper,
            transaction: {
              ...this.rootStore.transactionFormStore.transactionWrapper?.transaction,
              ...res,
            },
          });

        this.rootStore.transactionsStore.fetchItems();
        addToast('Transaction created successfully.', 'success');
        return true;
      })
      .catch((err) => {
        this.rootStore.userStore.checkIfUserHasNoPaymentMethods(err.response?.data.type);
        addToast(toastMessages.DEFAULT.ERROR, 'error');
      });
  };

  patchTransaction = async (req: TransactionUpdateRequest): Promise<void | boolean> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;

    if (!this.transactionId) {
      addToast(toastMessages.DEFAULT.ERROR, 'error');
      return;
    }

    return requests
      .patchTransaction(this.transactionId, req)
      .then((res: Transaction) => {
        this.rootStore.transactionFormStore.transactionWrapper &&
          this.rootStore.transactionFormStore.setTransactionWrapper({
            ...this.rootStore.transactionFormStore.transactionWrapper,
            transaction: {
              ...this.rootStore.transactionFormStore.transactionWrapper?.transaction,
              ...res,
            },
          });

        this.rootStore.transactionsStore.fetchItems();
        addToast('Saved successfully.', 'success');
        return true;
      })
      .catch(() => {
        addToast(toastMessages.DEFAULT.ERROR, 'error');
      });
  };

  updateVote = (
    who: 'owner' | 'buyer' | 'buyerRepresentative' | 'sellerRepresentative',
    agrees: boolean
  ): Promise<void | boolean> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    const vote = agrees ? 'Agrees' : 'Disagrees';

    const req = who + vote;

    if (
      !req ||
      !this.rootStore.transactionFormStore.transactionWrapper ||
      (['buyerRepresentative', 'sellerRepresentative'].includes(who) && agrees)
    ) {
      return Promise.reject();
    }

    const transactionLastModifiedAt = this.rootStore.transactionFormStore.transactionWrapper.transaction.modifiedAt;

    return requests[req](this.transactionId, transactionLastModifiedAt as Date)
      .then(() => {
        this.rootStore.transactionsStore.fetchItems();
        addToast('Saved successfully.', 'success');
        return true;
      })
      .catch(() => {
        addToast(toastMessages.DEFAULT.ERROR, 'error');
      });
  };

  fetchEscrowAccountDetails = (transactionId: string): Promise<void> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;

    return requests
      .fetchEscrowAccountDetails(transactionId)
      .then(({ data }) => {
        const { data: accountData, escrow } = data;
        runInAction(() => {
          this.escrowAccount = {
            ...accountData,
            isEscrow: escrow,
          };
        });
      })
      .catch(({ response }) => {
        addToast(
          getMessageFromApiErrorResponse(
            response?.data.type,
            toastMessages.BANK_TRANSACTIONS.ACCOUNT_DETAILS_FETCH_ERROR
          ),
          'error'
        );
      });
  };

  resetAll = (): void => {
    runInAction(() => {
      this.transactionId = null;
      this.artworkId = null;
      this.consignmentId = null;
      this.escrowAccount = null;
    });
  };
}

export default TransactionStore;
