import { observer } from 'mobx-react-lite';
// import Checkbox from 'theme/atoms/Checkbox';
import Select from 'theme/atoms/Select';
import Pagination, { PaginationProps } from 'theme/atoms/pagination';
import styles from './PaginationTopbar.module.scss';

interface PaginationTopbarProps extends PaginationProps {
  rowsPerPage: number;
  allRows: number;
  isAllSelected?: boolean;
  onChangeSelectAll?: (value: boolean) => void;
  onChangeRowsPerPage: (value: number) => void;
}

export const ROWS_PER_PAGE_OPTIONS = [
  { value: '10', label: '10' },
  { value: '20', label: '20' },
  { value: '50', label: '50' },
];

export const ROWS_PER_PAGE_SMALLEST_OPTION = Math.min(...ROWS_PER_PAGE_OPTIONS.map((item) => parseInt(item.value, 10)));

export const PaginationTopbar = observer(
  ({
    rowsPerPage,
    allRows,
    isAllSelected,
    onChangeRowsPerPage,
    onChangeSelectAll,
    ...paginationProps
  }: PaginationTopbarProps): JSX.Element => {
    return (
      <div className={styles.root}>
        <div className={styles.leftSide}>
          {/* {onChangeSelectAll?<Checkbox // TODO uncomment when ready
            name="all"
            label="Select all"
            checked={isAllSelected}
            onChange={(e) => onChangeSelectAll(e.target.checked)}
            iconType="secondary"
          />:null} */}

          {allRows > ROWS_PER_PAGE_SMALLEST_OPTION && (
            <div className={styles.rowsPerPage}>
              <p className={styles.text}>Show</p>
              <Select
                options={ROWS_PER_PAGE_OPTIONS}
                value={{
                  value: rowsPerPage.toString(),
                  label: rowsPerPage.toString(),
                }}
                onChange={(option) => onChangeRowsPerPage(+option.value)}
                className={styles.select}
              />
              <p className={styles.text}>
                {' '}
                of <span className={styles.number}>{allRows}</span> results
              </p>
            </div>
          )}
        </div>
        <div>{paginationProps.pages > 1 && <Pagination {...paginationProps} />}</div>
      </div>
    );
  }
);
