import { ReactNode } from 'react';
import dayjs from 'dayjs';
import { Consignment } from 'consignments/types';
import IconComponent, { icons } from 'theme/atoms/iconComponent';
import { formatDate } from 'utils/helpers';
import styles from './Timestamps.module.scss';

interface TimestampsProps {
  consignment: Consignment;
}

const getEventElement = (title: ReactNode, date: Date): JSX.Element => {
  return (
    <div className={styles.event}>
      {title}
      <span className={styles.date}>{formatDate(date)}</span>
    </div>
  );
};

const Timestamps = ({ consignment }: TimestampsProps): JSX.Element => {
  const { acceptedAt, expireAt, modifiedAt, status } = consignment;

  const isExpired = dayjs().isAfter(expireAt) || status === 'Expired';
  const expiration = expireAt && getEventElement(isExpired ? 'Expired' : 'Contract expiration date', expireAt);

  const isAccepted = status === 'Accepted';
  const isRejected = status === 'RejectedByOwner' || status === 'RejectedByConsignee';
  const rejected = (
    <div className={styles.rejected}>
      <IconComponent icon={icons.close} />
      Rejected
    </div>
  );
  const acceptance =
    (isAccepted && acceptedAt && getEventElement('Accepted', acceptedAt)) ||
    (isRejected && modifiedAt && getEventElement(rejected, modifiedAt));

  const isModified = !isAccepted && !isRejected;
  const modification = isModified && modifiedAt ? getEventElement('Last modified', modifiedAt) : null;

  return (
    <div className={styles.root}>
      {expiration}
      <div className={styles.rightColumn}>
        {modification}
        {acceptance}
      </div>
    </div>
  );
};

export default Timestamps;
