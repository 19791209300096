import clsx from 'clsx';
import { RawBankInvoice } from 'bankPerspective/bankInvoices/requests.types';
import NoDataToDisplay from 'theme/atoms/noDataToDisplay';
import { Table } from 'theme/atoms/table/Table';
import BankInvoiceTableRow from './BankInvoiceTableRow';
import styles from './BankInvoicesTable.module.scss';

interface BankInvoicesTableProps {
  invoices: RawBankInvoice[];
  className?: string;
}

export const BankInvoicesTable = ({ invoices, className }: BankInvoicesTableProps): JSX.Element => {
  const filteredInvoicesDisplayed = (
    <>
      {invoices.map((invoice) => (
        <BankInvoiceTableRow key={invoice.id} invoice={invoice} />
      ))}
    </>
  );

  const invoicesTableDisplay =
    invoices.length > 0 ? (
      filteredInvoicesDisplayed
    ) : (
      <tr className={styles.rowTableNoData}>
        <td colSpan={7}>
          <NoDataToDisplay copy="No Invoices to display." height={100} />
        </td>
      </tr>
    );

  return <Table className={clsx(styles.root, className)}>{invoicesTableDisplay}</Table>;
};

export default BankInvoicesTable;
