import { useNavigate } from 'react-router-dom';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import { Button } from 'theme/atoms';
import styles from './StatusOwnedView.module.scss';

interface StatusOwnedViewProps {
  artworkId: string;
}

const StatusOwnedView = ({ artworkId }: StatusOwnedViewProps): JSX.Element => {
  const { consignmentFormStore } = useStores();
  const { setConsigneePickerIsOpen } = consignmentFormStore;
  const navigate = useNavigate();

  return (
    <>
      <Button text="Consign to representative" onClick={() => setConsigneePickerIsOpen(true)} />
      <div className={styles.createOfferWrapper}>
        <Button
          text="Create offer sale"
          onClick={() => navigate(routes.Transaction.path('new', '', artworkId))}
          buttonType="secondary"
        />
      </div>
    </>
  );
};

export default StatusOwnedView;
