import { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { AssetWithKey } from 'domain/types/Asset.types';
import { getKeyFromUrl } from 'theme/cells/assetUpload/utils';
import AssetsUpload from '../assetsUpload';
import styles from './OwnershipProof.module.scss';

interface OwnershipProofProps {
  onAdd: () => void;
  error: string;
}

const OwnershipProof = ({ onAdd, error }: OwnershipProofProps): JSX.Element => {
  const { addArtworkStore } = useStores();
  const { artworkForm, updateArtworkForm } = addArtworkStore;

  const assetsFromParent = useMemo(() => {
    return artworkForm.ownershipProofUrls?.map((file) => ({
      ...file,
      key: getKeyFromUrl(file.presignedUrl),
    }));
  }, [artworkForm.ownershipProofUrls]);

  return (
    <AssetsUpload
      assetsFromParent={assetsFromParent}
      onUpdateAssets={(files: AssetWithKey[]) => updateArtworkForm('ownershipProofUrls', files)}
      instruction="Add ownership documents"
      title="Upload ownership proof"
      onClickAdd={onAdd}
      validationError={<span className={styles.error}>{error}</span>}
      assetsType="document"
      showAsList
    />
  );
};

export default observer(OwnershipProof);
