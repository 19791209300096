import { useState } from 'react';
import clsx from 'clsx';
import Big from 'big.js';
import { Consignment } from 'consignments/types';
import { CURRENCY_OPTIONS } from 'domain/constants';
import { Currency } from 'domain/types/Domain.types';
import { ReactComponent as PadlockIcon } from 'theme/assets/svg/padlock.svg';
import { ReactComponent as PadlockOpenIcon } from 'theme/assets/svg/padlockOpen.svg';
import Select from 'theme/atoms/Select';
import SectionHeader from 'theme/atoms/Titles/SectionHeader';
import Billing from 'theme/atoms/billing';
import CurrencyInputWrapper from 'theme/atoms/currencyInputWrapper';
import { ConsignmentDetailsHandlers, SelectedConsignmentDetails } from '../../types';
import styles from './ConsignmentDetails.module.scss';

interface ConsignmentDetailsProps {
  currency?: Currency;
  handlers: ConsignmentDetailsHandlers;
  selectedConsignmentDetails: SelectedConsignmentDetails;
  consignmentFromTransaction?: Consignment;
}

const ConsignmentDetails = ({
  currency,
  handlers,
  selectedConsignmentDetails,
  consignmentFromTransaction,
}: ConsignmentDetailsProps): JSX.Element => {
  const [showWarning, setShowWarning] = useState(false);
  const [activeInputs, setActiveInputs] = useState<string[]>([]);

  if (!consignmentFromTransaction) return <></>;

  const { selectedAskingPrice, selectedCurrency, selectedMaxCommissionPercent } = selectedConsignmentDetails;

  const handleOnBlur = (targetName: string): void => {
    setActiveInputs([...activeInputs.filter((name) => name !== targetName)]);
    checkIsWarningNeeded();
  };

  const handleOnFocus = (targetName: string): void => {
    setActiveInputs([...activeInputs, targetName]);
  };

  const checkIsWarningNeeded = (currency?: Currency): void => {
    if (
      selectedAskingPrice &&
      selectedMaxCommissionPercent &&
      consignmentFromTransaction.currency === selectedCurrency &&
      Big(selectedAskingPrice).eq(consignmentFromTransaction.askingPrice) &&
      selectedMaxCommissionPercent.eq(
        Big(consignmentFromTransaction.maxCommission).times(100).div(consignmentFromTransaction.askingPrice)
      )
    ) {
      setShowWarning(false);
    } else setShowWarning(true);
  };

  const getIcon = (targetName: string): JSX.Element => {
    return activeInputs.includes(targetName) ? <PadlockOpenIcon /> : <PadlockIcon />;
  };

  return (
    <div className={styles.root}>
      <SectionHeader title="Consignment details" />
      <Billing.Row>
        <div className={styles.row}>
          <CurrencyInputWrapper
            name="askingPrice"
            label="Asking price"
            placeholder="Enter the price"
            value={selectedAskingPrice ? Big(selectedAskingPrice).round().toString() : ''}
            endIcon={getIcon('askingPrice')}
            onFocus={() => handleOnFocus('askingPrice')}
            onBlur={() => {
              handleOnBlur('askingPrice');
              handlers.handleChangeAskingPriceOnBlur();
            }}
            onValueChange={(values) => {
              handlers.handleChangeAskingPrice(values);
              setShowWarning(true);
            }}
            currency={currency}
          />

          <Select
            label="Currency"
            className={styles.currencySelect}
            options={CURRENCY_OPTIONS}
            value={{
              value: selectedCurrency || '',
              label: selectedCurrency || '',
            }}
            onChange={(option) => {
              handlers.handleChangeCurrency(option.label as Currency);
            }}
            endIcon={getIcon('currency')}
            onFocus={() => handleOnFocus('currency')}
            onBlur={() => handleOnBlur('currency')}
          />
        </div>
      </Billing.Row>
      <CurrencyInputWrapper
        className={styles.maxCommissionInput}
        name="maxCommission"
        placeholder="Percent"
        label="Max commission"
        value={selectedMaxCommissionPercent ? Big(selectedMaxCommissionPercent).round(2).toString() : ''}
        suffix="%"
        endIcon={getIcon('maxCommission')}
        onFocus={() => handleOnFocus('maxCommission')}
        onBlur={() => {
          handleOnBlur('maxCommission');
          handlers.handleChangeMaxCommissionPercentOnBlur();
        }}
        onValueChange={(values) => {
          handlers.handleChangeMaxCommissionPercent(values);
          setShowWarning(true);
        }}
      />
      <div className={clsx(styles.warning, { [styles.showWarning]: showWarning })}>
        You are editing data from consignment. Changes will require the owner's approval.
      </div>
    </div>
  );
};

export default ConsignmentDetails;
