import clsx from 'clsx';
import { ReactComponent as TickIcon } from 'theme/assets/svg/tick.svg';
import styles from './Tile.module.scss';

interface TileProps {
  title: string;
  icon: JSX.Element;
  onClick: () => void;
  isSelected?: boolean;
  className?: string;
  withCheckMark?: boolean;
}

const Tile = ({ title, icon, onClick, isSelected = false, className = '', withCheckMark }: TileProps): JSX.Element => {
  return (
    <div className={clsx(styles.root, className, { [styles.isSelected]: isSelected })} onClick={onClick}>
      {icon}
      {withCheckMark && <TickIcon className={styles.tickIcon} />}
      <div className={styles.title}>{title}</div>
    </div>
  );
};

export default Tile;
