import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { useStores } from 'RootStore';
import styles from 'auth/Auth.module.scss';
import { Subtitle, TitleBlack } from 'theme/atoms';
import Breadcrumbs, { Breadcrumb } from 'theme/atoms/Breadcrumbs';
import Topbar from 'theme/atoms/Topbar';
import { useTitle } from 'utils/hooks';

function ResetPasswordEmailSent(): JSX.Element {
  useTitle('Reset password - email was sent');
  const { authStore } = useStores();
  const { accessToken } = authStore;

  const BREADCRUMBS: Breadcrumb[] = accessToken
    ? [
        {
          label: 'Settings',
          route: 'SettingsSecurity',
        },
        {
          label: 'Reset password',
        },
      ]
    : [
        {
          label: 'Reset password',
        },
      ];

  return (
    <div className={clsx(styles.root, styles.oneColumnLayout)}>
      <Topbar>
        <Breadcrumbs breadcrumbs={BREADCRUMBS} />
      </Topbar>
      <div className={styles.innerWrapper}>
        <TitleBlack text="Email sent" />
        <Subtitle text="Check your email and open the link we sent to continue." />
      </div>
    </div>
  );
}

export default observer(ResetPasswordEmailSent);
