import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import { AuthMethodAction, UserIdentityType } from 'auth/types';
import Breadcrumbs, { Breadcrumb } from 'theme/atoms/Breadcrumbs';
import Topbar from 'theme/atoms/Topbar';
import Loader from 'theme/atoms/loader';
import styles from '../../SettingsSecurity.module.scss';
import AddPhone from './components/AddPhone';
import VerifyPhone from './components/VerifyPhone';

const BREADCRUMBS: Breadcrumb[] = [
  { label: 'Settings', route: 'SettingsSecurity' },
  { label: 'Security', route: 'SettingsSecurity' },
  { label: 'SMS Code' },
];

const SettingsSecurityPhone = (): JSX.Element => {
  const navigate = useNavigate();
  const { authStore } = useStores();
  const { userNeedsVerification, setUserNeedsVerification, userIdentities, deleteUserIdentity } = authStore;

  const location = useLocation();
  const shouldDeleteAuthMethod = location.state?.action === AuthMethodAction.Delete;

  const [isPhoneAdded, setIsPhoneAdded] = useState<boolean>(location.state?.action === AuthMethodAction.Verify);

  useEffect(() => {
    if (userNeedsVerification) {
      navigate(routes.SettingsSecurity.path(), { state: { isAuthenticationMethodsOpen: true } });
      return;
    }

    setUserNeedsVerification(true);

    if (location.state?.action === AuthMethodAction.Delete) {
      const phoneUserIdentity = userIdentities?.find((identity) => identity.type === UserIdentityType.Phone);
      if (!phoneUserIdentity) return;
      deleteUserIdentity(phoneUserIdentity.id).then(() => {
        navigate(routes.SettingsSecurity.path(), { state: { isAuthenticationMethodsOpen: true } });
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (shouldDeleteAuthMethod) return <Loader minHeight={300} />;

  const formElement = !isPhoneAdded ? <AddPhone setIsPhoneAdded={setIsPhoneAdded} /> : <VerifyPhone />;

  return (
    <div className={styles.pageContainer}>
      <Topbar>
        <Breadcrumbs breadcrumbs={BREADCRUMBS} />
      </Topbar>
      {formElement}
    </div>
  );
};

export default observer(SettingsSecurityPhone);
