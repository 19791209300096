import { useRef } from 'react';
import Big from 'big.js';
import { Currency } from 'domain/types/Domain.types';
import { calculateArtistResellRightsAmount } from 'utils/helpers';
import { formatPriceRange } from 'utils/helpers/formatRangePrice';
import { useElementWidth } from 'utils/hooks';
import Billing from '../billing';
import styles from './ArtistResellRightsRange.module.scss';

interface ArtistResellRightsProps {
  artistResellRightsPercent: number;
  askingPrice: Big;
  askingPriceWithCommissions: Big;
  currency: Currency;
}

const ArtistResellRights = ({
  artistResellRightsPercent,
  askingPriceWithCommissions,
  askingPrice,
  currency,
}: ArtistResellRightsProps): JSX.Element => {
  const parentRef = useRef<HTMLDivElement | null>(null);
  const parentWidth = useElementWidth(parentRef);

  const minResellRightsAmount = artistResellRightsPercent
    ? calculateArtistResellRightsAmount(artistResellRightsPercent, askingPrice)
    : Big(0);
  const maxResellRightsAmount = artistResellRightsPercent
    ? calculateArtistResellRightsAmount(artistResellRightsPercent, askingPriceWithCommissions)
    : Big(0);
  const artistResellRightsRange = formatPriceRange(minResellRightsAmount, maxResellRightsAmount, currency);

  const shouldDisplayInTwoLines = parentWidth <= 535 && artistResellRightsRange.length > 15;

  return (
    <div className={styles.root} ref={parentRef}>
      <Billing.Row isDark isTwoLines={shouldDisplayInTwoLines}>
        <Billing.Cell>Artist Resell Rights:</Billing.Cell>
        <Billing.Cell isSplit={artistResellRightsPercent !== 0}>
          <div>{artistResellRightsPercent?.toFixed(2)}%</div>
          {artistResellRightsPercent !== 0 && <div>{artistResellRightsRange}</div>}
        </Billing.Cell>
      </Billing.Row>
    </div>
  );
};

export default ArtistResellRights;
