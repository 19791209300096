import { rootStore } from 'RootStore';
import { UUID_REGEX } from 'domain/regex';

export const getKeyFromUrl = (url: string): string => {
  if (!url) return '';

  const key = new URL(url).pathname.split('/')[2];
  const isKeyValid = UUID_REGEX.test(key);
  const isntKeyEqualToOrganizationId = rootStore.organizationStore.selectedOrganization?.id !== key;

  return isKeyValid && isntKeyEqualToOrganizationId ? key : '';
};

export const getMB = (fileSize: number): number => fileSize / 1024 / 1024;
