import { observer } from 'mobx-react-lite';
import { ProductEnum } from 'billing/types';
import { ReactComponent as MoneyIcon } from 'theme/assets/svg/dollars.svg';
import { useGetPaidProductPrice } from 'utils/hooks/useGetPaidProductPrice';
import styles from './ContactPickerAdditionalInfo.module.scss';

export interface ContactPickerAdditionalInfoType {
  field: ProductEnum;
  copy: string;
}

interface ContactPickerAdditionalInfoProps {
  additionalInfo: ContactPickerAdditionalInfoType;
}

const ContactPickerAdditionalInfo = ({ additionalInfo }: ContactPickerAdditionalInfoProps): JSX.Element => {
  const getPaidProductPrice = useGetPaidProductPrice(additionalInfo.field);
  if (!getPaidProductPrice || !additionalInfo.copy) return <></>;

  return (
    <div className={styles.root}>
      <MoneyIcon />
      {getPaidProductPrice} {additionalInfo.copy}
    </div>
  );
};

export default observer(ContactPickerAdditionalInfo);
