import { ReactNode } from 'react';
import clsx from 'clsx';
import styles from './Subtitle.module.scss';

interface SubtitleProps {
  text?: string;
  className?: string;
  children?: ReactNode;
  center?: boolean;
  dontLimitWidth?: boolean;
}

const Subtitle = ({ text, children, center, className = '', dontLimitWidth }: SubtitleProps): React.JSX.Element => {
  return (
    <div className={clsx(styles.root, className, center && styles.center, dontLimitWidth && styles.dontLimitWidth)}>
      {text || children}
    </div>
  );
};

export default Subtitle;
