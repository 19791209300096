import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import Big from 'big.js';
import { formatPrice } from 'utils/helpers';
import styles from './PendingInvoice.module.scss';

const PendingInvoice = (): JSX.Element => {
  const { billingStore } = useStores();
  const { upcomingInvoices, loadingInvoices } = billingStore;

  const priceAsParts =
    upcomingInvoices && formatPrice(Big(upcomingInvoices.amount_due).div(100), upcomingInvoices.currency, 2, true);

  const price = upcomingInvoices && formatPrice(Big(upcomingInvoices.amount_due), upcomingInvoices.currency, 2);

  if (loadingInvoices || !price) return <></>;

  return (
    <div className={styles.root}>
      <div className={styles.price}>Pending invoice:</div>
      <div className={styles.price}>
        {priceAsParts &&
          priceAsParts.map((part, index) =>
            part.type === 'fraction' ? (
              <span key={part.type + index} className={styles.fraction}>
                {part.value}
              </span>
            ) : (
              part.value
            )
          )}
      </div>
    </div>
  );
};

export default observer(PendingInvoice);
