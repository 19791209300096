import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { Input } from 'theme/atoms';
import { useDebounce } from 'utils/hooks';
import styles from './ContactPickerSearchbar.module.scss';

const DEBOUNCE_DELAY = 300;

const ContactPickerSearchbar = (): JSX.Element => {
  const { contactPickerStore } = useStores();
  const { searchQuery, setSearchQuery } = contactPickerStore;
  const [localSearchQuery, setLocalSearchQuery] = useState(searchQuery);
  const debouncedLocalSearchQuery = useDebounce(localSearchQuery, DEBOUNCE_DELAY);

  useEffect(() => {
    setSearchQuery(localSearchQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedLocalSearchQuery]);

  useEffect(() => {
    !searchQuery && localSearchQuery && setLocalSearchQuery(searchQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  return (
    <div className={styles.root}>
      <Input
        label="Search"
        name="searchbox"
        type="text"
        placeholder="Search name"
        value={localSearchQuery || ''}
        onChange={(e) => setLocalSearchQuery(e.target.value)}
      />
    </div>
  );
};

export default observer(ContactPickerSearchbar);
