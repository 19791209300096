import { useStores } from 'RootStore';
import Big from 'big.js';
import { ProductEnum } from 'billing/types';
import { formatPrice } from '../helpers/formatValues';

export const useGetPaidProductPrice = (field: ProductEnum): string | undefined => {
  const { billingStore } = useStores();
  const product = billingStore.products?.[field];
  const price = product && formatPrice(Big(product.amount).div(100), product.currency, 2);
  return price;
};
