import { useState, FormEvent, ChangeEvent, useRef } from 'react';
// import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { useStores } from 'RootStore';
import styles from 'auth/Auth.module.scss';
// import { FormValidation } from 'domain/types';
import { Button, Subtitle, TitleBlack } from 'theme/atoms';
import Breadcrumbs, { Breadcrumb } from 'theme/atoms/Breadcrumbs';
import Topbar from 'theme/atoms/Topbar';
import { useTitle } from 'utils/hooks';

// import { useFormValidation } from 'utils/hooks';

const NUMBER_OF_DIGITS_IN_CODE = 5;

// const FORM_VALIDATIONS: FormValidation = {
//   codeRequired: '',
// };

const BREADCRUMBS: Breadcrumb[] = [
  { label: 'Add phone number', route: 'SignupAddPhoneNumber' },
  { label: 'Verify phone number' },
];

function SignupVerifyPhoneNumber(): JSX.Element {
  // const navigate = useNavigate();
  const { authStore } = useStores();
  const { submitting } = authStore;

  const [code, setCode] = useState<string[]>(new Array(NUMBER_OF_DIGITS_IN_CODE).fill(''));
  const inputRefs = useRef<Array<HTMLInputElement | null>>(Array(NUMBER_OF_DIGITS_IN_CODE).fill(null));

  useTitle('Sign up - verify phone number');

  // const { cleanValidationErrors, formValidationMessages, handleValidation, validation } =
  // useFormValidation(FORM_VALIDATIONS);

  const onChangeCodeHandler = (index: number, e: ChangeEvent<HTMLInputElement>): void => {
    if (/^[0-9]$/.test(e.target.value)) {
      setCode((prevCode) => {
        const newCode = [...prevCode];
        newCode[index] = e.target.value;

        if (e.target.value && index < NUMBER_OF_DIGITS_IN_CODE - 1) {
          inputRefs.current[index + 1]?.focus();
        }

        return newCode;
      });
    } else if (!e.target.value && index >= 0) {
      index !== 0 && inputRefs.current[index - 1]?.focus();

      setCode((prevCode) => {
        const newCode = [...prevCode];
        newCode[index] = '';
        return newCode;
      });
    }
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>): void | undefined => {
    event.preventDefault();

    //TODO:Add form validation

    // checkSecurityCode(code).then(() => {
    //   navigate(routes.SignupProfile.path()); // deleted
    // });
  };

  return (
    <div className={clsx(styles.root, styles.oneColumnLayout)}>
      <Topbar>
        <Breadcrumbs breadcrumbs={BREADCRUMBS} />
      </Topbar>
      <div className={styles.innerWrapper}>
        <TitleBlack text="Verify your phone&nbsp;number" />
        <Subtitle>
          <>
            <div>Enter your one-time security code we sent to your</div>
            {/* <div>phone number: {formattedUserPhone}</div> */}
          </>
        </Subtitle>

        <form onSubmit={onSubmit} className="form">
          <div className={styles.inputWrapper}>
            {code.map((value: string, index: number) => (
              <input
                inputMode="numeric"
                pattern="\d{1}"
                key={`index-${index}`}
                value={value}
                type="text"
                maxLength={1}
                onChange={(e) => onChangeCodeHandler(index, e)}
                className={styles.input}
                name="code"
                autoComplete="off"
                ref={(input) => (inputRefs.current[index] = input)}
              />
            ))}
          </div>
          <Subtitle>
            <span>
              Didn’t receive the code?{' '}
              {/* <Button onClick={() => sendSecurityCode()} text="Resend" buttonType="textButton" /> */}
            </span>
          </Subtitle>
          <Button text="Continue" loading={submitting} type="submit" maxWidth />
        </form>
      </div>
    </div>
  );
}

export default observer(SignupVerifyPhoneNumber);
