import { useStores } from 'RootStore';
import Big from 'big.js';
import { Currency } from 'domain/types/Domain.types';
import { transactionToRoles, TransactionRole } from 'transactions/domain';
import { TransactionWrapper } from 'transactions/requests.types';

type UseTransactionPricesReturn = {
  currency: Currency;
  askingPrice: Big;
  artworkPrice: Big;
  artistResellRightsPercent: number | undefined;
  artistResellRightsAmount: Big | undefined;
  totalBuyerCommission: Big;
};

export const useTransactionPrices = (): UseTransactionPricesReturn => {
  const { transactionFormStore } = useStores();
  const transactionWrapper = transactionFormStore.transactionWrapper as TransactionWrapper;
  const { artwork, transaction } = transactionWrapper;

  const { artistResellRightsPercent } = artwork;
  const { currency, sellerInfo, buyerInfo } = transaction;

  const transactionRoles = transactionToRoles(transactionWrapper);
  const isSellerRepresentative = transactionRoles.includes(TransactionRole.SellerRepresentative);
  const isBuyerRepresentative = transactionRoles.includes(TransactionRole.BuyerRepresentative);
  const isBuyer = transactionRoles.includes(TransactionRole.BuyerOwner);

  const getCurrency = (): Currency => currency;

  const getAskingPrice = (): Big => Big(sellerInfo?.price || 0);

  const getArtworkPrice = (): Big => Big(buyerInfo?.artworkPrice || 0);

  const getArtistResellRightsPercent = (): number | undefined => artistResellRightsPercent;

  const getArtistResellRightsAmount = (): Big | undefined => {
    if (!artistResellRightsPercent) return undefined;

    if (isSellerRepresentative && sellerInfo) {
      const askingPriceAndSellerTotalCommission = Big(sellerInfo.price).plus(sellerInfo.totalCommission || 0);
      return Big(artistResellRightsPercent).times(askingPriceAndSellerTotalCommission).div(100);
    }
    if ((isBuyerRepresentative || isBuyer) && buyerInfo) {
      return Big(buyerInfo.artistResellRightsAmount || 0);
    }

    return Big(0);
  };

  const getTotalBuyerCommission = (): Big => {
    return Big(buyerInfo?.totalCommission || 0);
  };

  return {
    currency: getCurrency(),
    askingPrice: getAskingPrice(),
    artworkPrice: getArtworkPrice(),
    artistResellRightsPercent: getArtistResellRightsPercent(),
    artistResellRightsAmount: getArtistResellRightsAmount(),
    totalBuyerCommission: getTotalBuyerCommission(),
  };
};
