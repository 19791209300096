import { ReactElement } from 'react';
import { Navigate, Route } from 'react-router-dom';
import { AppWrapper } from 'AppWrapper/AppWrapper';
import AuthWrapper from 'auth/AuthWrapper/AuthWrapper';
import { SettingsLayout } from 'settings/settingsLayout';
import { routes } from '../paths.const';
import { getCommonRoutesForAuthenticatedUser } from './getCommonRoutesForAuthenticatedUser';

export const getRoutesForAuthenticatedNotVerifiedIndividualUser = (): ReactElement => {
  return (
    <Route>
      <Route element={<AuthWrapper />}>
        {Object.entries(routes)
          .filter(([_key, view]) => view.pageType === 'publicAndPrivate')
          .map(([key, view]) => (
            <Route key={key} path={view.path()} element={view.component} />
          ))}
      </Route>
      <Route element={<AppWrapper />}>
        <Route path={routes.Billing.path()} element={routes.Billing.component} />
        <Route element={<SettingsLayout />}>
          <Route path={routes.SettingsMyAccount.path()} element={routes.SettingsMyAccount.component} />
          <Route
            path={routes.SettingsOrganizationProfile.path()}
            element={routes.SettingsOrganizationProfile.component}
          />
        </Route>
      </Route>

      {getCommonRoutesForAuthenticatedUser()}
      <Route path="*" element={<Navigate to={routes.SettingsKYC.path()} replace />} />
    </Route>
  );
};
