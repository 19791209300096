import { RawBankInvoice } from 'bankPerspective/bankInvoices/requests.types';
import InvoiceStateCheckIcon from 'theme/atoms/invoiceStateCheckIcon';
import styles from './InvoicesStatusDiagram.module.scss';

interface InvoicesStatusDiagramProps {
  invoices: RawBankInvoice[];
}

const InvoicesStatusDiagram = ({ invoices }: InvoicesStatusDiagramProps): JSX.Element => {
  return (
    <div className={styles.root}>
      {invoices.map((invoice) => (
        <InvoiceStateCheckIcon key={invoice.id} state={invoice.state} />
      ))}
    </div>
  );
};

export default InvoicesStatusDiagram;
