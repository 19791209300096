import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'RootStore';
import { RawBankInvoice } from 'bankPerspective/bankInvoices/requests.types';
import BankPerspectiveLayout from 'bankPerspective/components/bankPerspectiveLayout';
import { Breadcrumb } from 'theme/atoms/Breadcrumbs';
import { useTitle } from 'utils/hooks';
import BankInvoicesTable from '../components/bankInvoicesTable';
import { BANK_INVOICES_CATEGORIES_MAP, DEFAULT_BANK_INVOICES_CATEGORY } from './constants';
import { BankInvoiceCategoryId } from './types';

const BREADCRUMBS: Breadcrumb[] = [{ label: 'Invoices', route: 'BankInvoices', param: DEFAULT_BANK_INVOICES_CATEGORY }];

const BankInvoices = (): JSX.Element => {
  const { categoryId } = useParams<{ categoryId: BankInvoiceCategoryId }>();
  const { bankInvoicesStore, bankInvoicesSortAndFilterStore } = useStores();
  const { items } = bankInvoicesStore;
  const bankInvoices = items as RawBankInvoice[];

  useTitle('Invoices');

  return (
    <BankPerspectiveLayout
      breadcrumbs={BREADCRUMBS}
      categoriesMap={BANK_INVOICES_CATEGORIES_MAP}
      categoryId={categoryId}
      fetchItems={bankInvoicesStore.fetchItems}
      loading={bankInvoicesStore.loading}
      store={bankInvoicesStore}
      sortAndFilterStore={bankInvoicesSortAndFilterStore}
    >
      <BankInvoicesTable invoices={bankInvoices} />
    </BankPerspectiveLayout>
  );
};

export default observer(BankInvoices);
