import { createContext, useContext } from 'react';
import AddArtworkStore from 'artworks/addArtwork/AddArtworkStore';
import ApproveArtworkStore from 'artworks/approveArtwork/ApproveArtworkStore';
import ArtworkStore from 'artworks/artwork/ArtworkStore';
import ArtworkRegistrationStore from 'artworks/artworkRegistration/ArtworkRegistrationStore';
import ArtworkRegistrationSortAndFilterStore from 'artworks/artworkRegistration/components/artworkRegistrationSortAndFilter/ArtworkRegistrationSortAndFilterStore';
import ArtworksStore from 'artworks/artworks/ArtworksStore';
import ArtworksSortAndFilterStore from 'artworks/artworksComponents/sortAndFilter/ArtworksSortAndFilterStore';
import ArtworksViewOptionsStore from 'artworks/artworksComponents/viewOptions/ArtworksViewOptionsStore';
import { AuthStore } from 'auth';
import CreateUserStore from 'auth/CreateUserStore';
import BankInvoicesStore from 'bankPerspective/bankInvoices/BankInvoicesStore';
import BankInvoiceStore from 'bankPerspective/bankInvoices/components/bankInvoice/BankInvoiceStore';
import BankInvoicesSortAndFilterStore from 'bankPerspective/bankInvoices/sortAndFilter/BankInvoicesSortAndFilterStore';
import BankTransactionsStore from 'bankPerspective/bankTransactions/BankTransactionsStore';
import BankTransactionStore from 'bankPerspective/bankTransactions/components/bankTransaction/BankTransactionStore';
import EscrowAccountFormStore from 'bankPerspective/bankTransactions/components/bankTransaction/escrowAccountForm/EscrowAccountFormStore';
import BankTransactionsSortAndFilterStore from 'bankPerspective/bankTransactions/sortAndFilter/BankTransactionsSortAndFilterStore';
import BillingStore from 'billing/BillingStore';
import ConsignmentStore from 'consignments/consignment/ConsignmentStore';
import ConsignmentFormStore from 'consignments/consignmentForm/ConsignmentFormStore';
import ConsignmentsStore from 'consignments/consignments/ConsignmentsStore';
import ConsignmentsSortAndFilterStore from 'consignments/consignments/sortAndFilter/ConsignmentsSortAndFilterStore';
import { NetworkManagingStore } from 'network';
import ContactPickerStore from 'network/contactPicker/ContactPickerStore';
import ContactInvitationStore from 'network/networkContact/contactInvitation/ContactInvitationStore';
import NetworkSortAndFilterStore from 'network/networkList/NetworkSortAndFilterStore';
import NetworkStore from 'network/networkList/NetworkStore';
import BankStore from 'organization/BankStore';
import OrganizationStore from 'organization/OrganizationStore';
import OrganizationProfileShareStore from 'organization/profile/share/OrganizationProfileShareStore';
import AssetUploadStore from 'theme/cells/assetUpload/AssetUploadStore';
import { ToastsStore } from 'toasts';
import InvoiceStore from 'transactions/invoice/InvoiceStore';
import InvoicesStore from 'transactions/invoices/InvoicesStore';
import InvoicesSortAndFilterStore from 'transactions/invoices/components/invoicesSortAndFilter/InvoicesSortAndFilterStore';
import TransactionStore from 'transactions/transaction/TransactionStore';
import EditBillingPanelStore from 'transactions/transaction/components/editBillingPanel/EditBillingPanelStore';
import TransactionFormStore from 'transactions/transaction/transactionForm/TransactionFormStore';
import TransactionsStore from 'transactions/transactions/TransactionsStore';
import TransactionsSortAndFilterStore from 'transactions/transactions/sortAndFilter/TransactionsSortAndFilterStore';
import { UserStore } from 'user';

export class RootStore {
  addArtworkStore: AddArtworkStore;
  approveArtworkStore: ApproveArtworkStore;
  artworkRegistrationSortAndFilterStore: ArtworkRegistrationSortAndFilterStore;
  artworkRegistrationStore: ArtworkRegistrationStore;
  artworksSortAndFilterStore: ArtworksSortAndFilterStore;
  artworksStore: ArtworksStore;
  artworkStore: ArtworkStore;
  artworksViewOptionsStore: ArtworksViewOptionsStore;
  assetUploadStore: AssetUploadStore;
  authStore: AuthStore;
  bankInvoicesSortAndFilterStore: BankInvoicesSortAndFilterStore;
  bankTransactionsSortAndFilterStore: BankTransactionsSortAndFilterStore;
  bankInvoiceStore: BankInvoiceStore;
  bankInvoicesStore: BankInvoicesStore;
  bankTransactionStore: BankTransactionStore;
  bankTransactionsStore: BankTransactionsStore;
  billingStore: BillingStore;
  consignmentFormStore: ConsignmentFormStore;
  consignmentsSortAndFilterStore: ConsignmentsSortAndFilterStore;
  consignmentsStore: ConsignmentsStore;
  consignmentStore: ConsignmentStore;
  contactInvitationStore: ContactInvitationStore;
  escrowAccountFormStore: EscrowAccountFormStore;
  invoicesSortAndFilterStore: InvoicesSortAndFilterStore;
  contactPickerStore: ContactPickerStore;
  transactionsSortAndFilterStore: TransactionsSortAndFilterStore;
  invoiceStore: InvoiceStore;
  invoicesStore: InvoicesStore;
  listNetworkStore: NetworkStore;
  networkManagingStore: NetworkManagingStore;
  organizationStore: OrganizationStore;
  bankStore: BankStore;
  toastsStore: ToastsStore;
  transactionsStore: TransactionsStore;
  editBillingPanelStore: EditBillingPanelStore;
  organizationProfileShareStore: OrganizationProfileShareStore;
  userStore: UserStore;
  createUserStore: CreateUserStore;
  networkSortAndFilterStore: NetworkSortAndFilterStore;
  transactionStore: TransactionStore;
  transactionFormStore: TransactionFormStore;

  constructor() {
    this.addArtworkStore = new AddArtworkStore(this);
    this.approveArtworkStore = new ApproveArtworkStore(this);
    this.artworkRegistrationSortAndFilterStore = new ArtworkRegistrationSortAndFilterStore(this);
    this.artworkRegistrationStore = new ArtworkRegistrationStore(this);
    this.artworksSortAndFilterStore = new ArtworksSortAndFilterStore(this);
    this.artworksStore = new ArtworksStore(this);
    this.artworkStore = new ArtworkStore(this);
    this.artworksViewOptionsStore = new ArtworksViewOptionsStore(this);
    this.assetUploadStore = new AssetUploadStore(this);
    this.authStore = new AuthStore(this);
    this.bankInvoiceStore = new BankInvoiceStore(this);
    this.bankInvoicesStore = new BankInvoicesStore(this);
    this.bankInvoicesSortAndFilterStore = new BankInvoicesSortAndFilterStore(this);
    this.bankTransactionsSortAndFilterStore = new BankTransactionsSortAndFilterStore(this);
    this.bankTransactionStore = new BankTransactionStore(this);
    this.bankTransactionsStore = new BankTransactionsStore(this);
    this.billingStore = new BillingStore(this);
    this.consignmentFormStore = new ConsignmentFormStore(this);
    this.consignmentsSortAndFilterStore = new ConsignmentsSortAndFilterStore(this);
    this.consignmentsStore = new ConsignmentsStore(this);
    this.consignmentStore = new ConsignmentStore(this);
    this.contactInvitationStore = new ContactInvitationStore(this);
    this.escrowAccountFormStore = new EscrowAccountFormStore(this);
    this.contactPickerStore = new ContactPickerStore(this);
    this.invoicesSortAndFilterStore = new InvoicesSortAndFilterStore(this);
    this.transactionsSortAndFilterStore = new TransactionsSortAndFilterStore(this);
    this.invoiceStore = new InvoiceStore(this);
    this.invoicesStore = new InvoicesStore(this);
    this.listNetworkStore = new NetworkStore(this);
    this.networkManagingStore = new NetworkManagingStore(this);
    this.organizationStore = new OrganizationStore(this);
    this.bankStore = new BankStore(this);
    this.toastsStore = new ToastsStore(this);
    this.transactionsStore = new TransactionsStore(this);
    this.transactionStore = new TransactionStore(this);
    this.transactionFormStore = new TransactionFormStore(this);
    this.editBillingPanelStore = new EditBillingPanelStore(this);
    this.organizationProfileShareStore = new OrganizationProfileShareStore(this);
    this.userStore = new UserStore(this);
    this.createUserStore = new CreateUserStore(this);
    this.networkSortAndFilterStore = new NetworkSortAndFilterStore(this);
  }
}

export const rootStore = new RootStore();

const RootStoreContext = createContext(rootStore);

export function useStores(): RootStore {
  const context = useContext(RootStoreContext);
  if (context === undefined) throw new Error('useRootStore must be used within RootStoreProvider');

  return context;
}

export default RootStoreContext;
