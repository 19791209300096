import { ReactNode, useRef } from 'react';
import clsx from 'clsx';
import { useOnClickOutside } from 'utils/hooks';
import Button from '../Button';
import IconButton from '../iconButton';
import { iconSizes, icons } from '../iconComponent';
import styles from './SlidingPanel.module.scss';

interface SlidingPanelProps {
  children: ReactNode;
  title: string;
  onClose: () => void;
  onReset?: () => void;
  isOpen: boolean;
  blockClosing?: boolean;
  onSubmit?: () => void;
  btnSubmitText?: string;
  maxWidth?: boolean;
}

function SlidingPanel({
  children,
  title,
  isOpen,
  onClose,
  onReset,
  blockClosing,
  onSubmit,
  btnSubmitText = 'close',
  maxWidth,
}: SlidingPanelProps): JSX.Element {
  const panelRef = useRef<HTMLDivElement>(null);
  useOnClickOutside({
    ref: panelRef,
    handler: !blockClosing ? onClose : null,
  });

  const onSubmitHandler = (): void => {
    onSubmit && onSubmit();
    onClose();
  };

  return (
    <div className={clsx({ [styles.overlay]: blockClosing && isOpen })}>
      <div className={clsx(styles.slidingPanel, { [styles.open]: isOpen })} ref={panelRef}>
        <div className={styles.header}>
          <h3 className={styles.title}>{title}</h3>
          <div className={styles.actionBtns}>
            <IconButton onClick={onClose} size={iconSizes.small} icon={icons.close} />
            {onReset && <Button text="Reset all" onClick={onReset} buttonType="tertiary" uppercase={false} />}
          </div>
        </div>

        <div className={clsx(styles.wrapper, { [styles.maxWidth]: maxWidth })}>
          {children}
          {onSubmit && <Button maxWidth text={btnSubmitText} onClick={onSubmitHandler} />}
        </div>
      </div>
    </div>
  );
}

export default SlidingPanel;
