import { useEffect, useState, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { AssetWithKey } from 'domain/types/Asset.types';
import { InvoiceState } from 'domain/types/Invoices.types';
import { ReactComponent as AddIcon } from 'theme/assets/svg/add.svg';
import { ReactComponent as DocumentIcon } from 'theme/assets/svg/document.svg';
import { ReactComponent as MenuIcon } from 'theme/assets/svg/next.svg';
import { Button, Spinner } from 'theme/atoms';
import TableDetailItem from 'theme/atoms/tableDetailItem';
import tableDetailItemStyles from 'theme/atoms/tableDetailItem/TableDetailItem.module.scss';
import useAssetUpload from 'theme/cells/assetUpload';
import { EXTENSIONS_DOCUMENTS } from 'theme/cells/assetUpload/extensions';
import { getMB } from 'theme/cells/assetUpload/utils';
import { formatDate } from 'utils/helpers';
import { useOnClickOutside } from 'utils/hooks';
import styles from './InvoiceDetails.module.scss';

interface InvoiceDetailsProps {
  alwaysShowBg?: boolean;
  assetFromParent: AssetWithKey | null;
  bankNote: string | undefined;
  includeBankNote?: boolean;
  isPayer: boolean;
  onUpdateAsset?: (assets: AssetWithKey | null) => void;
  onRemoveAsset?: () => void;
  state: InvoiceState;
  triggerAdding?: boolean;
  invoiceDocNameWidth?: number;
}

function InvoiceDetails({
  alwaysShowBg,
  assetFromParent,
  bankNote,
  includeBankNote = true,
  isPayer,
  onUpdateAsset,
  onRemoveAsset,
  state,
  triggerAdding,
  invoiceDocNameWidth,
}: InvoiceDetailsProps): JSX.Element {
  const [asset, setAsset] = useState<AssetWithKey | null>(assetFromParent);
  const [isBankNoteOpen, setIsBankNoteOpen] = useState(false);
  const [hasBeenClicked, setHasBeenClicked] = useState<boolean>(false);

  const toggleBankNoteOpen = (): void => {
    setIsBankNoteOpen(!isBankNoteOpen);
  };

  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = useCallback(() => {
    if (isBankNoteOpen) setIsBankNoteOpen(false);
  }, [isBankNoteOpen]);

  useOnClickOutside({
    ref: dropdownRef,
    handler: handleClickOutside,
  });

  useEffect(() => {
    setAsset(assetFromParent);
  }, [assetFromParent]);

  useEffect(() => {
    if (triggerAdding) handleAddDocument();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerAdding]);

  const updateAsset = (asset: AssetWithKey | null): void => {
    setAsset(asset);
    onUpdateAsset && onUpdateAsset(asset);
    setHasBeenClicked(false);
  };

  const handleAssetsUploadSuccess = (addedAsset: AssetWithKey[]): void => {
    updateAsset(addedAsset[0]);
  };

  const handleAssetUploadDelete = (): void => {
    updateAsset(null);
    onRemoveAsset && onRemoveAsset();
  };

  const handleFail = (): void => {
    setHasBeenClicked(false);
  };

  const { isLoading, triggerInput, InputElement, removeElement } = useAssetUpload({
    onUploadSuccess: handleAssetsUploadSuccess,
    onDeleteSuccess: handleAssetUploadDelete,
    assets: asset,
    extensions: EXTENSIONS_DOCUMENTS,
    notMultiple: true,
    onFail: handleFail,
  });

  const loader = (): JSX.Element => {
    return hasBeenClicked && isLoading ? (
      <div className={styles.spinnerWrapper}>
        <Spinner small />
      </div>
    ) : (
      <></>
    );
  };

  const handleAddDocument = (): void => {
    setHasBeenClicked(true);
    triggerInput();
  };

  const handleRemoveDocument = (asset: AssetWithKey): void => {
    setHasBeenClicked(true);
    removeElement(asset);
  };

  const getDetails = (): JSX.Element | null => {
    if (!asset) return null;

    return (
      <div className={styles.detailsWrapper} ref={dropdownRef}>
        <div className={styles.detailsAndRemoveContainer}>
          <div className={clsx(styles.details, { [styles.isError]: bankNote })}>
            <div className={styles.downloadWrapper}>
              <Link to={asset.presignedUrl} className={styles.download} onClick={(e) => e.stopPropagation()}>
                <DocumentIcon />
                <div className={styles.documentName}>
                  <TableDetailItem
                    text={asset.name}
                    className={tableDetailItemStyles.semibold}
                    bgColor={bankNote ? '#f0ccd7' : ''}
                    maxWidth={invoiceDocNameWidth}
                  />
                </div>
              </Link>
            </div>
            <div>{formatDate(asset.lastModified)}</div>
            <div>{getMB(asset.size).toFixed(2)} MB</div>
          </div>
          {!isPayer && state !== InvoiceState.Accepted && removeElement(asset) && (
            <button className={styles.remove} onClick={() => handleRemoveDocument(asset)}>
              {removeElement(asset)}
            </button>
          )}
        </div>

        {includeBankNote && bankNote && (
          <div className={styles.rejected}>
            <p className={styles.rejectedInfo} onClick={toggleBankNoteOpen}>
              Invoice rejected by bank
              <MenuIcon className={clsx(styles.menuIcon, { [styles.menuIconOpen]: isBankNoteOpen })} />
            </p>
            {isBankNoteOpen && (
              <div className={styles.bankNoteContent}>
                <p>Comment from a bank employee:</p>
                <section>{bankNote}</section>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  const getDocumentOptions = (): JSX.Element | null => {
    return asset ? (
      getDetails()
    ) : !isPayer ? (
      <div className={clsx({ [styles.showBg]: alwaysShowBg })}>Awaiting document</div>
    ) : (
      <div className={clsx(styles.addDocumentWrapper, { [styles.showBg]: alwaysShowBg })}>
        {triggerAdding !== undefined ? (
          'Add document'
        ) : (
          <Button
            text="Add document"
            onClick={() => handleAddDocument()}
            buttonType="tertiary"
            iconStart={<AddIcon />}
            uppercase={false}
          />
        )}
      </div>
    );
  };

  return (
    <>
      <div className={styles.root}>
        {loader()}
        {getDocumentOptions()}
      </div>
      {InputElement}
    </>
  );
}

export default observer(InvoiceDetails);
