import { ReactNode, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { useStores } from 'RootStore';
import Big from 'big.js';
import { ProductEnum } from 'billing/types';
import { addMeToOrganizationName } from 'domain/utils';
import ContactPicker from 'network/contactPicker/ContactPicker';
import { ContactConnection } from 'network/types';
import { ReactComponent as RemoveIcon } from 'theme/assets/svg/close.svg';
import { Button } from 'theme/atoms';
import SectionHeader from 'theme/atoms/Titles/SectionHeader';
import IconComponent, { icons } from 'theme/atoms/iconComponent';
import { useDebounceIsPanelOpen } from 'theme/atoms/slidingPanel/useDebounceIsPanelOpen';
import { getContactsIdsToExcludeBasedOnRole } from 'transactions/domain';
import { TransactionCommissionReceiver, TransactionWrapper } from 'transactions/requests.types';
import { ValidationError } from 'transactions/transaction/transactionForm/TransactionFormStore';
import { useSplitAmount, useTransactionRoles } from 'utils/hooks';
import InstructionWithError from '../components/instructionWithError';
import styles from './SplitCommission.module.scss';
import SplitCommissionRow from './components/SplitCommissionRow';

const getCommissionReceiversValues = (
  commissionReceiver: TransactionCommissionReceiver,
  index: number,
  amounts: Array<Big | null>,
  handleRemove: (index: number, contactConnectionId: string | undefined) => void
): { name: string; title: string; value: string; removeItemButton: ReactNode } => {
  const name = addMeToOrganizationName(commissionReceiver.contact);
  const title = commissionReceiver.contact.organizationTitle || '';
  const value =
    amounts[index] !== null
      ? Big(amounts[index] || 0)
          .round()
          .toString()
      : '';
  const removeItemButton =
    index !== 0 ? (
      <RemoveIcon
        className={styles.removeIcon}
        onClick={() => handleRemove(index, commissionReceiver.contact.contactConnectionId)}
      />
    ) : (
      <RemoveIcon className={clsx(styles.removeIcon, styles.disabled)} />
    );

  return { name, title, value, removeItemButton };
};

const getErrorCopy = (validationErrors: ValidationError[]): string => {
  let errorCopy = '';
  validationErrors.forEach((error) => {
    if (error === ValidationError.CommissionReceiversAreAddedAlthoughTotalCommissionIs0) {
      errorCopy = 'Total commission is 0 thus you cannot split it. Remove commission receivers.';
    } else if (error === ValidationError.CommissionReceiversReceiving0) {
      errorCopy = 'All commission receivers should receive a commission greater than 0.';
    }
  });
  return errorCopy;
};

const SplitCommission = (): JSX.Element => {
  const [triggerRecalculatingCommission, setTriggerRecalculatingCommission] = useState(1);
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const debouncedIsPickerOpen = useDebounceIsPanelOpen(isPickerOpen);
  const { transactionFormStore } = useStores();

  const { setSellerCommissionReceivers, setBuyerCommissionReceivers } = transactionFormStore;
  const transactionWrapper = transactionFormStore.transactionWrapper as TransactionWrapper;
  const transaction = transactionWrapper.transaction;

  const { isSellerSide } = useTransactionRoles({ transactionWrapper });

  const totalCommission =
    (isSellerSide ? transaction.sellerInfo?.totalCommission : transaction.buyerInfo?.totalCommission) || Big(0);
  const commissionReceivers =
    (isSellerSide ? transaction.sellerInfo?.commissionReceivers : transaction.buyerInfo?.commissionReceivers) || [];
  const setCommissionReceivers = isSellerSide ? setSellerCommissionReceivers : setBuyerCommissionReceivers;

  useEffect(() => {
    setTriggerRecalculatingCommission(triggerRecalculatingCommission + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCommission]);

  const { amounts, onChange, onBlur, addItem, removeItem } = useSplitAmount({
    totalAmount: totalCommission,
    initialAmounts: commissionReceivers.map((commissionReceiver) => commissionReceiver.commission),
    triggerRecalculatingCommission,
  });

  const onAddCommissionReceiver = (): void => {
    debouncedIsPickerOpen && setIsPickerOpen(true);
  };

  const onPickContact = (contact: ContactConnection): void => {
    setCommissionReceivers([
      ...commissionReceivers,
      {
        contact: {
          self: false,
          contactConnectionId: contact.id,
          organizationName: contact.contactOrganizationName,
          organizationTitle: contact.contactOrganizationTitle,
          organizationAddress: contact.contactOrganizationAddress,
        },
        commission: Big(0),
      },
    ]);
    addItem();
    onClosePicker();
  };

  const onClosePicker = (): void => {
    setIsPickerOpen(false);
  };

  const handleRemove = (index: number, contactConnectionId: string | undefined): void => {
    if (!contactConnectionId) return;

    setCommissionReceivers(
      commissionReceivers.filter(
        (commissionReceiver) => commissionReceiver.contact.contactConnectionId !== contactConnectionId
      )
    );
    removeItem(index);
  };

  useEffect(() => {
    if (!commissionReceivers) return;
    const buyerRepresentativeContact = transactionWrapper.transaction.buyerInfo?.representativeContact;
    if (!isSellerSide && buyerRepresentativeContact && !commissionReceivers.find((el) => el.contact.self)) {
      setCommissionReceivers([
        {
          contact: {
            organizationName: buyerRepresentativeContact.organizationName,
            organizationTitle: buyerRepresentativeContact.organizationTitle,
            organizationAddress: buyerRepresentativeContact.organizationAddress,
            self: true,
          },
          commission: null,
        },
        ...commissionReceivers.map((commissionReceiver, i) => ({
          contact: commissionReceiver.contact,
          commission: amounts[i],
        })),
      ]);
    } else {
      setCommissionReceivers(
        commissionReceivers?.map((commissionReceiver, i) => ({
          contact: commissionReceiver.contact,
          commission: amounts[i],
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amounts]);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <SectionHeader title="Split commission" />
        <Button
          text="Add"
          iconStart={<IconComponent icon={icons.add} />}
          buttonType="tertiary"
          onClick={onAddCommissionReceiver}
        />
      </div>

      {commissionReceivers?.length > 1 &&
        commissionReceivers.map((commissionReceiver, index) => {
          const { name, title, value, removeItemButton } = getCommissionReceiversValues(
            commissionReceiver,
            index,
            amounts,
            handleRemove
          );

          return (
            <SplitCommissionRow
              key={name + index}
              name={name}
              title={title}
              value={value}
              thisAndTotalCommissionsToCompare={{ thisC: Big(amounts[index] || 0), totalC: totalCommission }}
              onChange={(values) => onChange(index, values.value || '')}
              removeItemButton={removeItemButton}
              onBlur={() => onBlur(index)}
              currency={transaction.currency}
            />
          );
        })}

      <InstructionWithError
        section="splitCommission"
        showCopy={commissionReceivers && commissionReceivers.length === 1}
        showError={!!getErrorCopy(transactionFormStore.validationErrors)}
        errorCopy={getErrorCopy(transactionFormStore.validationErrors)}
      />

      <ContactPicker
        title="Split commission"
        isOpen={isPickerOpen}
        onPickContact={onPickContact}
        onClose={onClosePicker}
        contactsToExclude={getContactsIdsToExcludeBasedOnRole(transactionWrapper)}
        additionalInfo={{ field: ProductEnum.TransactionExtraRepresentative, copy: 'per person' }}
      />
    </div>
  );
};

export default observer(SplitCommission);
