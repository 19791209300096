import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import styles from 'auth/Auth.module.scss';
import { userIdentityTypeToRouteMap } from 'auth/constants';
import { DEFAULT_ERROR_MESSAGE } from 'domain/getMessageFromApiErrorResponse';
import { Button } from 'theme/atoms';
import Loader from 'theme/atoms/loader';
import { useUserContext } from 'utils/hooks';

const REDIRECTS_WITHOUT_2FA = ['ResetPasswordSetNewPassword'];

function LoginWithEmailToken(): JSX.Element {
  const { homePath } = useUserContext();
  const {
    authStore: { loginWithToken, submitting, accessToken: tokenFromStore, logout },
  } = useStores();

  const [searchParams] = useSearchParams();
  const tokenFromUrl = searchParams.get('token');
  const emailFromUrl = searchParams.get('email');
  const redirectFromUrl = searchParams.get('redirect');

  const navigate = useNavigate();
  const [error, setError] = useState<string | undefined>(undefined);

  const handleLoginWithToken = (): void => {
    const authenticatedComponents = Object.entries(routes)
      .filter(([key, view]) => view.pageType !== 'public')
      .map(([key]) => key);
    const componentFromUrl = redirectFromUrl && authenticatedComponents.find((el) => el === redirectFromUrl);
    const redirectUrl = componentFromUrl ? routes[componentFromUrl].path() : homePath;

    tokenFromUrl &&
      emailFromUrl &&
      loginWithToken({ token: tokenFromUrl, email: emailFromUrl }).then((response) => {
        if (!response) return;

        if (!response.success) {
          setError(response.message);
          return;
        }

        if (redirectFromUrl && REDIRECTS_WITHOUT_2FA.includes(redirectFromUrl)) {
          navigate(redirectUrl);
          return;
        }

        if (response.MFAUserIdentityTypesToVerify)
          navigate(
            response.MFAUserIdentityTypesToVerify.length > 0
              ? routes[userIdentityTypeToRouteMap[response.MFAUserIdentityTypesToVerify[0]]?.login || 'Login'].path()
              : routes.LoginAddAuthMethod.path(),
            { state: { redirectUrl } }
          );
      });
  };

  useEffect(() => {
    if (tokenFromStore) logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleLoginWithToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenFromUrl]);

  const waitingForRequest = (): JSX.Element | false => {
    return (
      submitting && (
        <>
          <div className={styles.loginByTokenErrorCopy}>Please wait...</div>
          <Loader minHeight={100} />
        </>
      )
    );
  };

  const getErrorCopyAndAdditionalButton = (): { copy: string; tryAgainButton?: boolean } | undefined => {
    if (!tokenFromUrl || !emailFromUrl) {
      return { copy: 'The link you are using is invalid.' };
    } else if (error) {
      return { copy: error, tryAgainButton: error === DEFAULT_ERROR_MESSAGE };
    }
  };

  const errorInstruction = (): JSX.Element | null => {
    const errorCopyAndAdditionalButton = getErrorCopyAndAdditionalButton();
    if (!errorCopyAndAdditionalButton) return null;

    const errorCopy = errorCopyAndAdditionalButton.copy;
    const tryAgainButton = errorCopyAndAdditionalButton.tryAgainButton;
    return (
      <>
        <div className={styles.loginByTokenErrorCopy}>{errorCopy}</div>
        <div className={styles.loginByTokenErrorButtonsWrapper}>
          {tryAgainButton ? (
            <>
              <Button text="Try again" onClick={() => handleLoginWithToken()} />
              or
            </>
          ) : null}
          <Button to={routes.ResetPasswordGetEmail.path()} text="Request new link" />
        </div>
      </>
    );
  };

  return <div className={clsx(styles.root, styles.oneColumnLayout)}>{waitingForRequest() || errorInstruction()}</div>;
}

export default observer(LoginWithEmailToken);
