import { action, makeObservable, observable, runInAction } from 'mobx';
import { RootStore } from 'RootStore';
import * as artworksRequests from 'artworks/requests';
import { ArtworkState, ExternalExploringVisibility, FilterParams } from 'artworks/types';
import SortAndFilterBasicStore, {
  SortAndFilterBasicStoreProps as BasicStoreProps,
} from 'domain/SortAndFilterBasicStore/SortAndFilterBasicStore';
import { FilterItemType } from 'domain/SortAndFilterBasicStore/types';
import { DEFAULT_SORTING_ITEM, SIZE_MAX_VALUE, SIZE_MIN_VALUE, WEIGHT_MAX_VALUE, WEIGHT_MIN_VALUE } from './constants';

const ExtendedStoreProps = {
  tags: observable,
  filterByAsParams: action,
  getTagsList: action,
  resetActivePage: action,
};

class ArtworksSortAndFilterStore extends SortAndFilterBasicStore {
  rootStore: RootStore;
  tags: string[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore);
    this.rootStore = rootStore;
    makeObservable(this, { ...BasicStoreProps, ...ExtendedStoreProps });

    this.defaultSortingItems = [DEFAULT_SORTING_ITEM];
    this.selectedSortingItems = this.defaultSortingItems;
    this.confirmedSortingItems = this.defaultSortingItems;
  }

  filterByAsParams = (filteringItems: FilterItemType[]): FilterParams => {
    const sizeNumber1From = filteringItems.find((el) => el.key === 'sizeNumber1From')?.value as number;
    const sizeNumber1To = filteringItems.find((el) => el.key === 'sizeNumber1To')?.value as number;
    const sizeNumber2From = filteringItems.find((el) => el.key === 'sizeNumber2From')?.value as number;
    const sizeNumber2To = filteringItems.find((el) => el.key === 'sizeNumber2To')?.value as number;
    const weightNumberFrom = filteringItems.find((el) => el.key === 'weightNumberFrom')?.value as number;
    const weightNumberTo = filteringItems.find((el) => el.key === 'weightNumberTo')?.value as number;
    const filterParams = {
      state: [ArtworkState.Certified],
      status: filteringItems.filter((el) => el.key === 'status').map((el) => el.value as string),
      tag: filteringItems.filter((i) => i.key === 'tag').map((i) => i.value as string),
      externalExploringVisibility: filteringItems
        .filter((i) => i.key === 'externalExploringVisibility')
        .map((i) => Object.keys(ExternalExploringVisibility).find((visibilityKey) => visibilityKey === i.value) || ''),
      yearLike: filteringItems.find((el) => el.key === 'yearLike')?.value as string,
      yearFrom: filteringItems.find((el) => el.key === 'yearFrom')?.value as number,
      yearTo: filteringItems.find((el) => el.key === 'yearTo')?.value as number,
      executedYearLike: filteringItems.find((el) => el.key === 'executedYearLike')?.value as string,
      executedYearFrom: filteringItems.find((el) => el.key === 'executedYearFrom')?.value as number,
      executedYearTo: filteringItems.find((el) => el.key === 'executedYearTo')?.value as number,
      sizeNumber1From: sizeNumber1From && sizeNumber1From > SIZE_MIN_VALUE ? sizeNumber1From : undefined,
      sizeNumber1To: sizeNumber1To && sizeNumber1To < SIZE_MAX_VALUE ? sizeNumber1To : undefined,
      sizeNumber2From: sizeNumber2From && sizeNumber2From > SIZE_MIN_VALUE ? sizeNumber2From : undefined,
      sizeNumber2To: sizeNumber2To && sizeNumber2To < SIZE_MAX_VALUE ? sizeNumber2To : undefined,
      weightNumberFrom: weightNumberFrom && weightNumberFrom > WEIGHT_MIN_VALUE ? weightNumberFrom : undefined,
      weightNumberTo: weightNumberTo && weightNumberTo < WEIGHT_MAX_VALUE ? weightNumberTo : undefined,
    };
    Object.keys(filterParams).forEach((key) => {
      if (!filterParams[key]) delete filterParams[key];
    });
    return filterParams;
  };

  getTagsList = (): Promise<void> => {
    const { addToast, toastMessages } = this.rootStore.toastsStore;
    runInAction(() => (this.loading = true));

    return artworksRequests
      .fetchTagsList()
      .then(({ data }) => {
        runInAction(() => {
          this.tags = data.tags;
        });
      })
      .catch(() => addToast(toastMessages.DEFAULT.ERROR, 'error'))
      .finally(() => runInAction(() => (this.loading = false)));
  };

  resetActivePage = (): void => {
    this.rootStore.artworksStore.handleResetActivePage();
  };
}

export default ArtworksSortAndFilterStore;
