import { ReactNode } from 'react';
import clsx from 'clsx';
import Card from 'theme/atoms/card';
import BillingCard from '../billingCard/';
import styles from './InfoCard.module.scss';

interface InfoCardProps {
  details: JSX.Element;
  className?: string;
  label?: string;
  labelWithSiteAvatar?: string;
  children?: ReactNode;
  organizationId?: string;
}

const InfoCard = ({
  details,
  className,
  children,
  label,
  labelWithSiteAvatar,
  organizationId,
}: InfoCardProps): JSX.Element => {
  return (
    <div className={clsx(styles.root, className)}>
      <Card className={styles.infoCard}>
        {label && (
          <Card.Header className={styles.labelHeader}>
            {label}
            {labelWithSiteAvatar && (
              <BillingCard.TransactionSiteAvatar
                organizationId={organizationId || labelWithSiteAvatar}
                organizationName={labelWithSiteAvatar}
              />
            )}
          </Card.Header>
        )}
        {!children && <Card.DetailsList>{details}</Card.DetailsList>}
      </Card>
      {children}
    </div>
  );
};

export default InfoCard;
