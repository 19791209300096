export const INVOICE_CATEGORIES_WITH_INVOICE_SIDES = [
  {
    id: 'received',
    label: 'Invoices received',
    invoiceSide: ['Payer'],
  },
  {
    id: 'sent',
    label: 'Invoices sent',
    invoiceSide: ['Issuer'],
  },
] as const;

export const DEFAULT_INVOICE_CATEGORY = INVOICE_CATEGORIES_WITH_INVOICE_SIDES[0].id;
