import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { useStores } from 'RootStore';
import { routes } from 'app/routes/paths.const';
import { ARTWORK_STEPS } from 'artworks/addArtwork/constants';
import { Artwork, ArtworkState, ArtworkStatusEnum } from 'artworks/types';
import Avatar from 'theme/atoms/avatar';
import IconComponent, { icons } from 'theme/atoms/iconComponent';
import ImageDraft from 'theme/atoms/imageDraft';
import Status from 'theme/atoms/status';
import { Table } from 'theme/atoms/table/Table';
import TableDetailItem from 'theme/atoms/tableDetailItem';
import tableDetailItemStyles from 'theme/atoms/tableDetailItem/TableDetailItem.module.scss';
import styles from './ArtworkRegistrationTable.module.scss';

export const ArtworkRegistrationTable = (): JSX.Element => {
  const navigate = useNavigate();
  const { artworkRegistrationStore } = useStores();
  const { items } = artworkRegistrationStore;
  const artworks = items as Artwork[];

  const handleRowClick = (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>, artwork: Artwork): void => {
    if (e.target instanceof HTMLInputElement) return;

    if (ArtworkStatusEnum[artwork.exploringOrganizationArtworkStatus] === ArtworkStatusEnum.Draft) {
      const draftCreationStepOfArtwork = artworks.find((a) => a.id === artwork.id)?.draftCreationStep || 1;
      const draftCreationStepName =
        ARTWORK_STEPS.find((step) => step.id === draftCreationStepOfArtwork) || ARTWORK_STEPS[0];
      navigate(routes[draftCreationStepName.path].path(artwork.id));
    } else if (ArtworkState.Certified === artwork.state) {
      navigate(routes.Artwork.path(artwork.id));
    } else navigate(routes.ArtworkDraft.path(artwork.id));
  };

  return (
    <Table className={styles.root}>
      <>
        {artworks.map((artwork) => {
          const artworkAuthorName = artwork?.author?.name || 'no author specified';
          const artworkName = artwork?.name || 'no title specified';
          const artworkYear = artwork?.year?.toString() || 'no year specified';
          return (
            <tr key={artwork.id} onClick={(e) => handleRowClick(e, artwork)} className={styles.rowTable}>
              <td className={styles.imageTd}>
                {artwork.assets.imageUrls?.[0]?.presignedUrl ? (
                  <img src={artwork.assets.imageUrls?.[0]?.presignedUrl} className={styles.img} alt={artwork.name} />
                ) : (
                  <ImageDraft className={styles.img} />
                )}
              </td>
              <td>
                <TableDetailItem
                  text={artworkAuthorName}
                  className={tableDetailItemStyles.userWithAvatarSemibold}
                  searchText={artworkRegistrationStore.searchQuery}
                />
                <TableDetailItem
                  text={artworkName}
                  nameYear={[artworkName, artworkYear]}
                  className={tableDetailItemStyles.italic}
                  searchText={artworkRegistrationStore.searchQuery}
                />
              </td>
              <td>
                {artwork.author && (
                  <div className={clsx(styles.userCard, styles.author)}>
                    <span className={styles.contactInfo}>Artist Estate:</span>
                    <Avatar id={artwork.authorOrganizationId} />
                    <TableDetailItem
                      text={artworkAuthorName}
                      className={tableDetailItemStyles.userWithAvatarSemibold}
                      searchText={artworkRegistrationStore.searchQuery}
                    />
                  </div>
                )}
              </td>
              <td className={clsx(styles.userTd, { [styles.asList]: artwork.owners.length > 1 })}>
                {artwork.owners.map((owner, index) => {
                  return (
                    <div key={owner.contact?.contactConnectionId || index} className={styles.userCard}>
                      <span className={styles.contactInfo}>{index === 0 ? 'Owner:' : ''}</span>
                      <Avatar id={owner.contact?.contactConnectionId || ''} />
                      <TableDetailItem
                        text={owner.contact?.organizationName}
                        className={tableDetailItemStyles.userWithAvatarSemibold}
                      />
                    </div>
                  );
                })}
              </td>
              <td className={styles.statusTd}>
                {artwork.state === ArtworkState.Certified ? (
                  <div className={styles.status}>
                    <IconComponent icon={icons.tick} />
                    <span>Registered</span>
                  </div>
                ) : (
                  <Status status={ArtworkStatusEnum[artwork.exploringOrganizationArtworkStatus]} size={0} hasIcon />
                )}
              </td>
            </tr>
          );
        })}
      </>
    </Table>
  );
};

export default observer(ArtworkRegistrationTable);
