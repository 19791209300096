import { useState, DragEvent, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { useStores } from 'RootStore';
import { AssetWithKey } from 'domain/types/Asset.types';
import { InvoiceState } from 'domain/types/Invoices.types';
import { ReactComponent as FileIcon } from 'theme/assets/svg/file.svg';
import { Button, Spinner } from 'theme/atoms';
import NoDataToDisplay from 'theme/atoms/noDataToDisplay';
import PDFViewer from 'theme/atoms/pdfViewer';
import useAssetUpload from 'theme/cells/assetUpload';
import { EXTENSIONS_DOCUMENTS } from 'theme/cells/assetUpload/extensions';
import InvoiceDetails from 'transactions/invoices/components/invoiceDetails/InvoiceDetails';
import { Invoice as InvoiceType } from 'transactions/requests.types';
import styles from './InvoiceEditOrAdd.module.scss';

interface InvoiceEditOrAddProps {
  invoice: InvoiceType;
}

const InvoiceEditOrAdd = ({ invoice }: InvoiceEditOrAddProps): JSX.Element => {
  const { invoicesStore, invoiceStore } = useStores();
  const { updateInvoiceUrl } = invoicesStore;
  const { setSelectedInvoiceState } = invoiceStore;
  const [dragOver, setDragOver] = useState(false);
  const [asset, setAsset] = useState<AssetWithKey | null>(invoice.assetInfo || null);

  const dragTimeout = useRef<NodeJS.Timeout | null>(null);

  const handleDragEvent = (e: DragEvent<HTMLDivElement>): void => {
    e.preventDefault();

    switch (e.type) {
      case 'dragover':
      case 'dragenter':
        if (dragTimeout.current) clearTimeout(dragTimeout.current);
        setDragOver(true);
        break;
      case 'dragleave':
        dragTimeout.current = setTimeout(() => {
          setDragOver(false);
        }, 50);
        break;
      case 'drop':
        if (dragTimeout.current) clearTimeout(dragTimeout.current);

        setDragOver(false);
        if (!isLoading) {
          const dataTransfer = e.dataTransfer;
          if (dataTransfer.files && dataTransfer.files.length > 0) initiateFileUploadByDrop(dataTransfer);
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    return () => {
      if (dragTimeout.current) clearTimeout(dragTimeout.current);
    };
  }, []);

  const handleAssetsUploadSuccess = (addedAssets: AssetWithKey[]): void => {
    setAsset(addedAssets[0]);
    updateInvoiceUrl(invoice.id, addedAssets[0].url || null);
    setSelectedInvoiceState(InvoiceState.PendingBankDecision);
  };

  const { isLoading, triggerInput, InputElement, initiateFileUploadByDrop, fileExtensionError } = useAssetUpload({
    onUploadSuccess: handleAssetsUploadSuccess,
    assets: asset,
    extensions: EXTENSIONS_DOCUMENTS,
    notMultiple: true,
  });

  const dropZoneDisplay = isLoading ? (
    <Spinner blue />
  ) : (
    <>
      {!asset && (
        <>
          <FileIcon className={styles.fileIcon} />
          <div className={styles.dragBoxInfo}>
            {!dragOver ? (
              <p>
                Drag & drop your invoice here or
                <Button
                  className={styles.chooseButton}
                  buttonType="textButton"
                  text="choose file"
                  onClick={() => triggerInput()}
                />
              </p>
            ) : (
              <>DROP HERE</>
            )}
          </div>
        </>
      )}
      {InputElement}
    </>
  );

  const isRejectedInvoice = invoice.state === InvoiceState.Rejected;
  const DOWNLOAD_INVOICE_FILE_NAME = `Invoice_id_${invoice.id}.pdf`;

  const isUserInvoicePayer = Boolean(invoice.payerContactDto?.self);

  const droppedInvoiceOrInvoiceDropZone = asset ? (
    <>
      <InvoiceDetails
        assetFromParent={asset}
        isPayer={Boolean(invoice.payerContactDto?.self)}
        includeBankNote={false}
        bankNote={invoice.bankNote}
        onUpdateAsset={(asset) => {
          updateInvoiceUrl(invoice.id, asset?.url || null);
        }}
        onRemoveAsset={() => {
          setAsset(null);
          setSelectedInvoiceState(InvoiceState.PendingUpload);
        }}
        state={invoice.state}
      />
      <PDFViewer
        className={styles.pdfViewer}
        url={asset.presignedUrl}
        isPDFRejected={isRejectedInvoice}
        downloadFileName={DOWNLOAD_INVOICE_FILE_NAME}
      />
    </>
  ) : !isUserInvoicePayer ? (
    <div
      className={clsx(
        styles.root,
        { [styles.dragOver]: dragOver },
        { [styles.dropError]: fileExtensionError },
        { [styles.assetsUploading]: isLoading }
      )}
      onDragOver={handleDragEvent}
      onDragEnter={handleDragEvent}
      onDragLeave={handleDragEvent}
      onDrop={handleDragEvent}
    >
      {dropZoneDisplay}
    </div>
  ) : (
    <NoDataToDisplay copy="The invoice file has not yet been uploaded." height={100} />
  );

  return <>{droppedInvoiceOrInvoiceDropZone}</>;
};

export default observer(InvoiceEditOrAdd);
