export enum RangeEndMin {
  Min = 'Min',
  From = 'From',
}
export enum RangeEndMax {
  Max = 'Max',
  To = 'To',
}
export type RangeEnd = RangeEndMin & RangeEndMax;

export enum Kind {
  sizeNumber1 = 'sizeNumber1',
  sizeNumber2 = 'sizeNumber2',
  weightNumber = 'weightNumber',
}
