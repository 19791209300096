import { useEffect, useState } from 'react';
import Big from 'big.js';

type UseSplitAmountProps = {
  totalAmount: Big;
  initialAmounts: Array<Big | null>;
  triggerRecalculatingCommission?: number;
};

type UseSplitAmountReturn = {
  amounts: Array<Big | null>;
  onChange: (index: number, amount: string) => void;
  onBlur: (index: number) => void;
  addItem: () => void;
  removeItem: (index: number) => void;
};

export const useSplitAmount = ({
  totalAmount,
  initialAmounts,
  triggerRecalculatingCommission,
}: UseSplitAmountProps): UseSplitAmountReturn => {
  const [amounts, setAmounts] = useState<Array<Big | null>>(initialAmounts);

  useEffect(() => {
    setAmounts(initialAmounts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerRecalculatingCommission]);

  const addItem = (): void => {
    if (amounts.length === 0) {
      setAmounts([totalAmount]);
    } else {
      setAmounts((prevAmounts) => [...prevAmounts, null]);
    }
  };

  const removeItem = (itemIndex: number): void => {
    if (amounts.length === 2) {
      setAmounts([totalAmount]);
    } else {
      setAmounts((prevAmounts) => {
        return prevAmounts.reduce((acc, amount, index) => {
          if (index === itemIndex) return acc;
          if (index === getNextItemIndex(itemIndex))
            return [...acc, Big(amount || 0).plus(prevAmounts[itemIndex] || 0)];
          return [...acc, amount];
        }, [] as Array<Big | null>);
      });
    }
  };

  const onChange = (changedAmountIndex: number, amount: string): void => {
    const changedAmount = amount === '' ? null : Big(amount || 0);

    setAmounts((prevAmounts) =>
      prevAmounts.map((amount, index) => (index === changedAmountIndex ? changedAmount : amount))
    );
  };

  const onBlur = (changedIndex: number): void => {
    const sumOfAmounts = amounts.reduce((acc, amount) => Big(acc || 0).plus(amount || 0), Big(0));

    setAmounts((prevAmounts) => {
      const newAmounts = [...prevAmounts];

      let delta = Big(sumOfAmounts || 0).minus(totalAmount);
      let currentIndex = changedIndex;

      while (Big(delta).cmp(0)) {
        const nextItemIndex = getNextItemIndex(currentIndex);
        const nextItemAmount = newAmounts[nextItemIndex];

        if (Big(nextItemAmount || 0).gte(delta)) {
          newAmounts[nextItemIndex] = Big(nextItemAmount || 0).minus(delta);
          delta = Big(0);
        } else {
          delta = Big(delta).minus(nextItemAmount || 0);
          newAmounts[nextItemIndex] = Big(0);
          currentIndex = nextItemIndex;
        }
      }

      return newAmounts;
    });
  };

  const getNextItemIndex = (index: number): number => {
    return index < amounts.length - 1 ? index + 1 : 0;
  };

  return { amounts, onChange, onBlur, addItem, removeItem };
};
