import { action, makeObservable } from 'mobx';
import { RootStore } from 'RootStore';
import PaginationBasicStore, {
  PaginationBasicStoreProps as BasicStoreProps,
} from 'domain/PaginationBasicStore/PaginationBasicStore';
import * as requests from '../requests';

const ExtendedStoreProps = {
  fetchItems: action,
};

class TransactionsStore extends PaginationBasicStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    super(rootStore);
    this.rootStore = rootStore;
    makeObservable(this, { ...BasicStoreProps, ...ExtendedStoreProps });
  }

  fetchItems = async (): Promise<void> => {
    this.handleFetchItems(
      requests.fetchTransactions,
      {
        sortBy: this.rootStore.transactionsSortAndFilterStore.sortByAsParams,
        ...this.rootStore.transactionsSortAndFilterStore.filterByAsParams(
          this.rootStore.transactionsSortAndFilterStore.confirmedFilteringItems
        ),
      }
      // shouldSetNumberOfAllItemsInCategory // TODO when tabs are implemented
    );
  };
}

export default TransactionsStore;
