import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import ArtworksSortAndFilterStore from 'artworks/artworksComponents/sortAndFilter/ArtworksSortAndFilterStore';
import ConsignmentsSortAndFilterStore from 'consignments/consignments/sortAndFilter/ConsignmentsSortAndFilterStore';
import styles from './RangeSlider.module.scss';
import { Kind, RangeEnd, RangeEndMax, RangeEndMin } from './types';

export interface RangeSliderProps {
  store: ArtworksSortAndFilterStore | ConsignmentsSortAndFilterStore;
  kind: Kind;
  minLabel: RangeEndMin;
  maxLabel: RangeEndMax;
  minValue?: number;
  maxValue: number;
  gap: number;
  step: number;
  placeholder?: string;
  symbol?: string;
}

const RangeSlider = ({
  store,
  kind,
  minLabel = 'Min' as RangeEndMin,
  maxLabel = 'Max' as RangeEndMax,
  placeholder,
  minValue = 0,
  maxValue,
  gap,
  step,
  symbol,
}: RangeSliderProps): JSX.Element => {
  const { updateSelectedRangeItem, selectedFilteringItems, resetActivePage } = store;
  const [emptyValue, setEmptyValue] = useState({ [minLabel]: false, [maxLabel]: false });
  const itemFromStoreMinNotNecessarlyAsNumber = selectedFilteringItems.find((el) => el.key === kind + minLabel)?.value;
  const itemFromStoreMin =
    (itemFromStoreMinNotNecessarlyAsNumber &&
      typeof itemFromStoreMinNotNecessarlyAsNumber === 'string' &&
      parseInt(itemFromStoreMinNotNecessarlyAsNumber, 10)) ||
    (itemFromStoreMinNotNecessarlyAsNumber as number) ||
    minValue;
  const itemFromStoreMaxNotNecessarlyAsNumber = selectedFilteringItems.find((el) => el.key === kind + maxLabel)
    ?.value as number;
  const itemFromStoreMax =
    (itemFromStoreMaxNotNecessarlyAsNumber &&
      typeof itemFromStoreMaxNotNecessarlyAsNumber === 'string' &&
      parseInt(itemFromStoreMaxNotNecessarlyAsNumber, 10)) ||
    (itemFromStoreMaxNotNecessarlyAsNumber as number) ||
    maxValue;
  const [itemMin, setItemMin] = useState<number | undefined>(itemFromStoreMin);
  const [itemMax, setItemMax] = useState<number | undefined>(itemFromStoreMax);

  useEffect(() => {
    setItemMin(itemFromStoreMin);
  }, [itemFromStoreMin]);

  useEffect(() => {
    setItemMax(itemFromStoreMax);
  }, [itemFromStoreMax]);

  const progressLeft: number = (itemFromStoreMin / maxValue) * 100;
  const progressRight: number = 100 - (itemFromStoreMax / maxValue) * 100;

  const getNewValConsideringGapAndMaxValue = (
    rangeEnd: RangeEnd,
    itemFromStoreMin: number,
    itemFromStoreMax: number,
    newVal: number
  ): number => {
    const isGapKept = rangeEnd === minLabel ? itemFromStoreMax - newVal > gap : newVal - itemFromStoreMin > gap;
    switch (true) {
      case rangeEnd === maxLabel && newVal > maxValue:
        return maxValue;
      case isGapKept:
        return newVal;
      case rangeEnd === minLabel:
        return itemFromStoreMax - gap;
      case rangeEnd === maxLabel:
        return itemFromStoreMin + gap;
      default:
        return newVal;
    }
  };

  const handleValueChange = (rangeEnd: RangeEnd, newVal: number | undefined, updateLocally?: boolean): void => {
    const updatedValue = getNewValConsideringGapAndMaxValue(
      rangeEnd,
      itemFromStoreMin,
      itemFromStoreMax,
      newVal || minValue
    );
    if (updateLocally) {
      rangeEnd === minLabel ? setItemMin(updatedValue) : setItemMax(updatedValue);
    }
    updateSelectedRangeItem({ key: `${kind}${rangeEnd}`, value: updatedValue });
    resetActivePage();
  };

  const handleOnInputChange = (rangeEnd: RangeEnd, newVal: number | undefined): void => {
    const defaultValWhenNoValWasSet = !newVal && (rangeEnd === minLabel ? minValue : maxValue);
    const val = defaultValWhenNoValWasSet || newVal;
    handleValueChange(rangeEnd, val);
    rangeEnd === minLabel ? setItemMin(val) : setItemMax(val);
    setEmptyValue({ ...emptyValue, [rangeEnd]: newVal || newVal === 0 ? false : true });
  };

  const handleOnBlurChange = (rangeEnd: RangeEnd, newVal: number): void => {
    const valForMin = rangeEnd === minLabel && newVal;
    const defaultValForMaxWhenNoValWasSet = rangeEnd === maxLabel && !newVal && maxValue;
    const valForMaxWhenValWasSet = rangeEnd === maxLabel && newVal && newVal > maxValue ? maxValue : newVal;
    const val = valForMin || defaultValForMaxWhenNoValWasSet || valForMaxWhenValWasSet;

    handleValueChange(rangeEnd, val, true);
  };

  const handleOnSliderChange = (rangeEnd: RangeEnd, newVal: number): void => {
    handleValueChange(rangeEnd, newVal, true);
    setEmptyValue({ ...emptyValue, [rangeEnd]: false });
  };

  return (
    <>
      <div className={styles.progressWrapper}>
        <div className={styles.progress} style={{ left: `${progressLeft}%`, right: `${progressRight}%` }}></div>
      </div>
      <div className={styles.line}>
        <input
          type="range"
          min={minValue}
          max={maxValue}
          value={itemFromStoreMin}
          className={styles.rangeInput}
          id="min"
          step={step}
          onChange={(e) => handleOnSliderChange(minLabel as RangeEnd, +e.target.value)}
        />
        <input
          type="range"
          min={minValue}
          max={maxValue}
          value={itemFromStoreMax}
          className={styles.rangeInput}
          id="max"
          step={step}
          onChange={(e) => handleOnSliderChange(maxLabel as RangeEnd, +e.target.value)}
        />
      </div>
      <div className={styles.inputs}>
        <div className={styles.inputWrapper}>
          {symbol && !emptyValue[minLabel] && <div className={styles.dollar}>{symbol}</div>}
          <input
            name="min"
            placeholder={'min ' + (placeholder ? placeholder : 'value')}
            onChange={(e) =>
              handleOnInputChange(minLabel as RangeEnd, e.target.value === '' ? undefined : +e.target.value)
            }
            onBlur={(e) => handleOnBlurChange(minLabel as RangeEnd, +e.target.value)}
            value={emptyValue[minLabel] ? '' : itemMin}
            className={styles.input}
          />
        </div>
        <div className={styles.inputWrapper}>
          {!emptyValue[maxLabel] && itemMax === maxValue && (
            <div className={styles.moreThan} style={{ left: 24 + maxValue.toString().length * 11 + 'px' }}>
              +
            </div>
          )}
          {symbol && !emptyValue[maxLabel] && <div className={styles.dollar}>{symbol}</div>}
          <input
            name="max"
            placeholder={'max ' + (placeholder || 'value')}
            onChange={(e) => handleOnInputChange(maxLabel as RangeEnd, +e.target.value)}
            onBlur={(e) => handleOnBlurChange(maxLabel as RangeEnd, +e.target.value)}
            value={emptyValue[maxLabel] ? '' : itemMax}
            className={styles.input}
          />
        </div>
      </div>
    </>
  );
};

export default observer(RangeSlider);
