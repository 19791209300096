import { useNavigate } from 'react-router-dom';
import { routes } from 'app/routes/paths.const';
import InvoicesStatusDiagram from 'bankPerspective/bankTransactions/components/invoicesStatusDiagram';
import { STATUS_WITH_LABEL } from 'bankPerspective/bankTransactions/constants';
import { RawBankTransaction } from 'bankPerspective/bankTransactions/requests.types';
import Big from 'big.js';
import { TransactionStatus } from 'domain/types/Transactions.types';
import { ReactComponent as CheckedIcon } from 'theme/assets/svg/checkMark.svg';
import BillingCard from 'theme/atoms/billingCard';
import TableDetailItem from 'theme/atoms/tableDetailItem';
import tableDetailItemStyles from 'theme/atoms/tableDetailItem/TableDetailItem.module.scss';
import { formatDate, formatPrice } from 'utils/helpers';
import styles from '../BankTransactionsTable.module.scss';

interface BankTransactionTableRowProps {
  transaction: RawBankTransaction;
}

const BankTransactionTableRow = ({ transaction }: BankTransactionTableRowProps): JSX.Element => {
  const { id, createdAt, sellerInfo, buyerInfo, invoices, currency, status } = transaction;

  const navigate = useNavigate();

  const handleDetailsClick = (): void => {
    navigate(routes.BankTransaction.path(id));
  };

  const totalInvoiceAmount = invoices.reduce((accumulator, currentInvoice) => {
    return accumulator.plus(currentInvoice.amount);
  }, new Big(0));

  const readyForExecution = status === TransactionStatus.ReadyForExecution;

  const statusLabel = STATUS_WITH_LABEL.find((detail) => detail.status === status)?.label || 'Unknown Status';

  return (
    <tr key={id} className={styles.rowTable} onClick={handleDetailsClick}>
      <td className={styles.idTd}>
        <TableDetailItem text={id} className={tableDetailItemStyles.normalSecondary} />
      </td>
      <td className={styles.createdAtTd}>
        <TableDetailItem text={formatDate(createdAt)} className={tableDetailItemStyles.normal} />
      </td>
      <td className={styles.transactionSumTd}>
        <div className={styles.priceWrapper}>{formatPrice(Big(totalInvoiceAmount), currency, 2)}</div>
        {readyForExecution && <CheckedIcon />}
      </td>
      <td className={styles.transactionSiteTd}>
        <BillingCard.TransactionSiteAvatar
          className={styles.personWrapper}
          label="Seller:"
          organizationId={id}
          organizationName={sellerInfo.representative.organizationName}
        />
        <BillingCard.TransactionSiteAvatar
          className={styles.personWrapper}
          label="Buyer:"
          organizationId={id}
          organizationName={buyerInfo.representative.organizationName}
        />
      </td>
      <td className={styles.progressTd}>
        <InvoicesStatusDiagram invoices={invoices} />
      </td>
      <td className={styles.transactionStatusTd}>
        <p>{statusLabel}</p>
      </td>
    </tr>
  );
};

export default BankTransactionTableRow;
